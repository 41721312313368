import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import SelectTipoVehiculo from "../../TipoVehiculo/components/SelectTipoVehiculo";
import SelectTipoCombustible from "../../TipoCombustible/components/SelectTipoCombustible";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import InputDecimal from "../../../../compartido/components/input/InputDecimal";
import InputBase from "../../../../compartido/components/input/InputBase";
import { CANALES_WEB_SOCKET, TipoCombustibleGet, TipoCombustibleService, TipoVehiculoGet, TipoVehiculoService, VehiculoGet, VehiculoPost, VehiculoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  vehiculoId: number;
  ocultar: Function;
}

interface State {
  vehiculo: VehiculoGet;
  tiposVehiculo: Array<TipoVehiculoGet>;
  tiposCombustible: Array<TipoCombustibleGet>;
  alerta: any;
}

export default class VehiculoActualizar extends Component<Props, State> {


  private tipoCombustibleService:TipoCombustibleService;
  private tipoVehiculoService:TipoVehiculoService;
  private vehiculoService:VehiculoService;


  constructor(props: Props) {
    super(props);
    this.state = {
      vehiculo: this.crearVehiculoVacio(),
      tiposVehiculo: [],
      tiposCombustible: [],
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_VEHICULOS,
      funcionCallback: this.cargarTiposVehiculo,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_COMBUSTIBLES,
      funcionCallback: this.cargarTiposCombustible,
    });

    this.tipoCombustibleService = new TipoCombustibleService(Global.UsuarioService.getToken()!);
    this.tipoVehiculoService = new TipoVehiculoService(Global.UsuarioService.getToken()!);
    this.vehiculoService = new VehiculoService(Global.UsuarioService.getToken()!);







  }

  componentDidMount() {
    this.cargarVehiculo(this.props.vehiculoId);
    this.cargarTiposVehiculo();
    this.cargarTiposCombustible();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarVehiculo(this.props.vehiculoId);
    }
  }

  crearVehiculoVacio(): VehiculoGet {
    const tipoCombustible: TipoCombustibleGet = {
      id: 0,
      tipo: "",
    };
    const tipoVehiculo: TipoVehiculoGet = {
      id: 0,
      tipo: "",
    };
    const vehiculo: VehiculoGet = {
      id: 0,
      patente: "",
      nombre: "",
      marca: "",
      tipo_combustible: tipoCombustible,
      tipo_vehiculo: tipoVehiculo,
      descripcion: "",
      capacidad_carga: 0,
      proveedores: [],
    };
    return vehiculo;
  }

  cargarVehiculo = async (id: number) => {
    const resVehiculo = await this.vehiculoService.get(id).catch((error) => {
      this.vehiculoService.manejarErrorHTTP(error, "Vehículo");
      return null;
    });
    if (resVehiculo != null) {
      this.setState({
        vehiculo: resVehiculo,
      });
    }
  };

  cargarTiposVehiculo = async () => {
    const resTiposVehiculo = await this.tipoVehiculoService.listAll().catch(
      (error) => {
        this.tipoVehiculoService.manejarErrorHTTP(error, "Tipo Vehículo");
        return null;
      }
    );
    if (resTiposVehiculo != null) {
      this.setState({
        tiposVehiculo: resTiposVehiculo,
      });
    }
  };

  cargarTiposCombustible = async () => {
    const resTiposCombustible = await this.tipoCombustibleService.listAll().catch(
      (error) => {
        this.tipoCombustibleService.manejarErrorHTTP(error, "Tipo Combustible");
        return null;
      }
    );
    if (resTiposCombustible != null) {
      this.setState({
        tiposCombustible: resTiposCombustible,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  setearPatente = (patente: string) => {
    let vehiculo = this.state.vehiculo;
    vehiculo.patente = patente;
    this.setState({ vehiculo: vehiculo });
  };

  setearNombre = (nombre: string) => {
    let vehiculo = this.state.vehiculo;
    vehiculo.nombre = nombre;
    this.setState({ vehiculo: vehiculo });
  };

  setearTipoVehiculo = (tipoVehiculo: TipoVehiculoGet) => {
    let vehiculo = this.state.vehiculo;
    vehiculo.tipo_vehiculo = tipoVehiculo;
    this.setState({ vehiculo: vehiculo });
  };

  setearTipoCombustible = (tipoCombustible: TipoCombustibleGet) => {
    let vehiculo = this.state.vehiculo;
    vehiculo.tipo_combustible = tipoCombustible;
    this.setState({ vehiculo: vehiculo });
  };

  setearMarca = (marca: string) => {
    let vehiculo = this.state.vehiculo;
    vehiculo.marca = marca;
    this.setState({ vehiculo: vehiculo });
  };

  setearCapacidadCarga = (capacidad_carga: number) => {
    let vehiculo = this.state.vehiculo;
    vehiculo.capacidad_carga = capacidad_carga;
    this.setState({ vehiculo: vehiculo });
  };

  setearDescripcion = (descripcion: string) => {
    let vehiculo = this.state.vehiculo;
    vehiculo.descripcion = descripcion;
    this.setState({ vehiculo: vehiculo });
  };

  handleUpdate() {
    const vehiculoActualizar: VehiculoPost = {
      patente: this.state.vehiculo.patente,
      nombre: this.state.vehiculo.nombre,
      tipo_vehiculo: this.state.vehiculo.tipo_vehiculo.id,
      tipo_combustible: this.state.vehiculo.tipo_combustible.id,
      capacidad_carga: this.state.vehiculo.capacidad_carga,
      marca: this.state.vehiculo.marca,
      descripcion: this.state.vehiculo.descripcion,
    };
    this.vehiculoService.update(vehiculoActualizar, this.state.vehiculo.id)
      .then((result) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_VEHICULOS, {
          vehiculoId: this.state.vehiculo.id,
        });
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Vehiculo Actualizado!",
            titulo: "Atención",
            estado: "block",
          },
        });
      })
      .catch((error) => {
        this.vehiculoService.manejarErrorHTTP(error, "Vehículo");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.vehiculo.patente &&
      this.state.vehiculo.nombre &&
      this.state.vehiculo.tipo_vehiculo &&
      this.state.vehiculo.tipo_combustible &&
      this.state.vehiculo.marca &&
      this.state.vehiculo.capacidad_carga != 0 &&
      this.state.vehiculo.descripcion
      ? false
      : true;
  };

  render() {
    if (
      this.state.vehiculo.id != 0 &&
      this.state.tiposCombustible.length > 0 &&
      this.state.tiposVehiculo.length > 0
    ) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            <div className={"row"}>
              <div className={"column"}>
                <form
                  className={"form-horizontal"}
                  onSubmit={this.handleSubmit}
                >
                  <div className={"form-group"}>
                    <label htmlFor="patente">Patente</label>
                    <InputBase
                      id="patente"
                      name="patente"
                      onChange={this.setearPatente}
                      value={this.state.vehiculo.patente}
                      placeholder="Ingrese Patente de Vehiculo"
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="nombre">Nombre</label>
                    <InputCaracteres
                      id="nombre"
                      name="nombre"
                      onChange={this.setearNombre}
                      value={this.state.vehiculo.nombre}
                      placeholder="Ingrese Nombre de Vehiculo"
                    />
                  </div>

                  <div className={"form-group"}>
                    <SelectTipoVehiculo
                      tiposVehiculos={this.state.tiposVehiculo}
                      seleccionado={this.state.vehiculo.tipo_vehiculo}
                      callbackParent={this.setearTipoVehiculo}
                    />
                  </div>

                  <div className={"form-group"}>
                    <SelectTipoCombustible
                      tiposCombustibles={this.state.tiposCombustible}
                      seleccionado={this.state.vehiculo.tipo_combustible}
                      callbackParent={this.setearTipoCombustible}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label htmlFor="marca">Marca</label>
                    <InputCaracteres
                      id="marca"
                      name="marca"
                      onChange={this.setearMarca}
                      value={this.state.vehiculo.marca}
                      placeholder="Ingrese Marca"
                    />
                  </div>

                  <div className={"form-group"}>
                    <label htmlFor="capacidad_carga">Capacidad de Carga</label>{" "}
                    <InputDecimal
                      id="capacidad_carga"
                      name="capacidad_carga"
                      onChange={this.setearCapacidadCarga}
                      value={this.state.vehiculo.capacidad_carga}
                      cantEnteros={3}
                      cantDecimales={3}
                      placeholder={"Ingrese la Capacidad de Carga"}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label htmlFor="descripcion">Descripcion</label>
                    <InputCaracteres
                      id="descripcion"
                      name="descripcion"
                      onChange={this.setearDescripcion}
                      value={this.state.vehiculo.descripcion}
                      placeholder="Ingrese Descripcion"
                    />
                  </div>

                  <div
                    className={"form-group"}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button
                      ref="boton"
                      type="submit"
                      className={"btn btn-info"}
                      disabled={this.disableButton()}
                    >
                      {"Actualizar"}
                    </button>
                    <button
                      className={"btn btn-outline-secondary"}
                      onClick={this.ocultar}
                    >
                      Ocultar
                    </button>
                  </div>
                  <div className={"form-group"}>
                    <Alerta
                      tipo={this.state.alerta.tipo}
                      titulo={this.state.alerta.titulo}
                      mensaje={this.state.alerta.mensaje}
                      estado={this.state.alerta.estado}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
