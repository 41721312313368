import React, { Component } from "react";
import { PropsSelectGenerico } from "../../../../../compartido/components/select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../../../../../compartido/components/select/SelectBusqueda";
import SelectComponent from "../../../../../compartido/components/select/SelectComponent";


import {DireccionDTO, CANALES_WEB_SOCKET,Reparto,  ClienteService,  WebSocketService, RepartoService, Recorrido } from "serviciossaintmichel";
import { Global } from "../../../../../Global";


export interface Props {
  idCliente: number;
  version: Version;
  propsSelect?: PropsSelectGenerico<DireccionDTO> & PropsSelectGeneral;
  filtros?: Array<Function>; // lista de filtros sobre los datos (solo sirve para el select)
}

export enum Version {
  SELECT,
}

interface State {
  direcciones: Array<DireccionDTO> | null;
}

export default class DireccionesClienteShow extends Component<Props, State> {

  private clienteService:ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      direcciones: null,
    };

    this.clienteService =  new ClienteService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_DIRECCIONES_CLIENTES,
      funcionCallback: this.onChannelMessage,
    });
    this.setDireccionesCliente();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.idCliente !== this.props.idCliente) {
      this.setDireccionesCliente();
    }
  }

  onChannelMessage = async (e: MessageEvent) => {
    const message = JSON.parse(e.data).message;
    if (message.clienteId === this.props.idCliente) {
      this.setDireccionesCliente();
    }
  };

  setDireccionesCliente = async () => {
    try {
      const direccionesCliente = await this.clienteService.getDirecciones(this.props.idCliente);
      this.setState({ direcciones: direccionesCliente });
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error);
    }
  };

  render() {
    const { direcciones } = this.state;
    const { propsSelect, version, filtros } = this.props;

    if (direcciones === null) {
      return null;
    }

    // si hay filtros, chequea aquellos combos que cumplen con todos los filtros
    const items = filtros ? direcciones.filter((dir) => filtros.every((fnc) => fnc(dir))) : direcciones;

    return version === Version.SELECT && propsSelect ? (
      <SelectComponent
        {...propsSelect}
        items={items}
        callbackParent={propsSelect.callbackParent}
        seleccionado={propsSelect.seleccionado ? propsSelect.seleccionado : null}
        labelGetter={(obj: DireccionDTO) => `${obj.calle} e/ ${obj.entre_1} y ${obj.entre_2}`}
      />
    ) : null;
  }
}
