import React, { Component } from "react";
import { DescuentoEscritura } from "serviciossaintmichel";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import { Checkbox, NumberInput, Radio, TextInput } from "@mantine/core";

type TipoDescuento = "porcentual" | "monto-fijo";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  descuento: DescuentoEscritura;
}

interface State {
  descuento: DescuentoEscritura;
  tipoDescuento: TipoDescuento;
  validation: boolean;
}

export default class DescuentoLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      descuento: this.props.descuento,
      validation: false,
      tipoDescuento: this.props.descuento.monto !== null ? "monto-fijo" : "porcentual",
    };
  }

  componentDidMount = () => {
    this.updateValidation(this.state.descuento);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevProps !== this.props) {
      this.setState({ descuento: this.props.descuento });
    }

    if (prevState.descuento !== this.state.descuento) {
      this.updateValidation(this.state.descuento);
      this.props.onChange(this.state.descuento);
    }

    if (prevState.validation !== this.state.validation) {
      this.props.onValidationChange(this.state.validation);
    }
  };

  isValid = (descuento: DescuentoEscritura) => {
    return !!(
      descuento.nombre.length > 0 &&
      ((descuento.monto === null && descuento.porcentaje) || (descuento.porcentaje === null && descuento.monto))
    );
  };

  updateValidation = (descuento: DescuentoEscritura) => {
    const validacion = this.isValid(descuento);
    this.setState({ validation: validacion });
  };

  setNombre = (nombre: string) => {
    this.setState((prevState) => ({
      descuento: {
        ...prevState.descuento,
        nombre: nombre,
      },
    }));
  };

  setTipoDescuento = (tipoDescuento: TipoDescuento) => {
    this.setState((prevState) => ({
      tipoDescuento: tipoDescuento,
      descuento: {
        ...prevState.descuento,
        monto: tipoDescuento === "monto-fijo" ? 1 : null,
        porcentaje: tipoDescuento === "porcentual" ? 1 : null,
      },
    }));
  };

  setMonto = (monto: number) => {
    this.setState((prevState) => ({
      descuento: {
        ...prevState.descuento,
        monto: monto,
      },
    }));
  };

  setPorcentaje = (porcentaje: number) => {
    this.setState((prevState) => ({
      descuento: {
        ...prevState.descuento,
        porcentaje: porcentaje,
      },
    }));
  };

  setAcumulable = (acumulable: boolean) => {
    this.setState((prevState) => ({
      descuento: {
        ...prevState.descuento,
        acumulable: acumulable,
      },
    }));
  };

  render() {
    const { descuento, tipoDescuento } = this.state;

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" gap="1rem">
        <TextInput
          value={descuento.nombre}
          label={"Nombre"}
          placeholder={"Ingrese nombre"}
          onChange={(event) => this.setNombre(event.currentTarget.value)}
        />
        <Radio.Group
          name="tipoDescuento"
          label="Tipo de aplicación"
          value={tipoDescuento}
          onChange={this.setTipoDescuento}
        >
          <Radio value="porcentual" label="Porcentual" />
          <Radio value="monto-fijo" label="Monto fijo" />
        </Radio.Group>
        {tipoDescuento === "monto-fijo" ? (
          <NumberInput
            label="Monto de descuento"
            description="Minimo $1"
            placeholder="Ingrese monto"
            min={1}
            value={descuento.monto !== null ? descuento.monto : undefined}
            onChange={this.setMonto}
          />
        ) : (
          <NumberInput
            label="Porcentaje de descuento"
            description="Minimo 1% - Maximo 100%"
            placeholder="Ingrese porcentaje"
            max={100}
            min={1}
            value={descuento.porcentaje !== null ? descuento.porcentaje : undefined}
            onChange={this.setPorcentaje}
          />
        )}
        <Checkbox
          checked={descuento.acumulable}
          label="Es acumulable"
          onChange={(event) => this.setAcumulable(event.currentTarget.checked)}
        />
      </Flex>
    );
  }
}
