import React, { Component } from "react";
import MaquinaServicioFisicaActualizar from "./MaquinaServicioFisicaActualizar";
import MaquinaServicioFisicaLista from "./MaquinaServicioFisicaLista";
import MaquinaServicioFisicaNuevo from "./MaquinaServicioFisicaNuevo";
import MaquinaServicioFisicaVerDetalle from "./MaquinaServicioFisicaVerDetalle";
import Flex from "../../../../compartido/components/flex/Flex";
import { Tabs, Tab } from "react-bootstrap";
import { Global } from "../../../../Global";

interface Props {}

interface State {
  tabVisible: any;
  disabledActualizar: boolean;
  disabledVer: boolean;
  responsive: boolean;
  maquinaServicioFisicaId: number | null;
}

export default class MaquinasServicioFisica extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista",
      disabledActualizar: true,
      disabledVer: true,
      responsive: window.innerWidth < 700 ? true : false,
      maquinaServicioFisicaId: null,
    };
    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  actualizar = (maquinaServicioFisicaId: number) => {
    this.setState({
      tabVisible: "actualizar",
      disabledActualizar: false,
      maquinaServicioFisicaId: maquinaServicioFisicaId,
    });
  };

  ver = (maquinaServicioFisicaId: number) => {
    this.setState({
      tabVisible: "ver",
      disabledVer: false,
      maquinaServicioFisicaId: maquinaServicioFisicaId,
    });
  };

  ocultarActualizar = () => {
    this.setState({
      tabVisible: "lista",
      disabledActualizar: true,
      maquinaServicioFisicaId: null,
    });
  };

  ocultarNuevo = () => {
    this.setState({
      tabVisible: "lista",
    });
  };

  ocultarVer = () => {
    this.setState({
      tabVisible: "lista",
      disabledVer: true,
      maquinaServicioFisicaId: null,
    });
  };

  renderizarNuevo = () => {
    if (
      Global.UsuarioService.getPermisos().Elementos.Maquinas.MaquinaServicioFisica.canAdd()
    ) {
      return (
        <Tab eventKey="nuevo" title="Nuevo">
          {this.state.tabVisible === "nuevo" ? (
            <MaquinaServicioFisicaNuevo ocultar={this.ocultarNuevo} />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarLista = () => {
    if (
      Global.UsuarioService.getPermisos().Elementos.Maquinas.MaquinaServicioFisica.canView()
    ) {
      return (
        <Tab eventKey="lista" title="Lista">
          <MaquinaServicioFisicaLista
            ver={this.ver}
            actualizar={this.actualizar}
          />
        </Tab>
      );
    }
  };

  renderizarActualizar = () => {
    if (
      Global.UsuarioService.getPermisos().Elementos.Maquinas.MaquinaServicioFisica.canChange()
    ) {
      return (
        <Tab
          eventKey="actualizar"
          title="Actualizar"
          disabled={this.state.disabledActualizar}
        >
          {this.state.maquinaServicioFisicaId &&
          this.state.tabVisible === "actualizar" ? (
            <MaquinaServicioFisicaActualizar
              maquinaServicioFisicaId={this.state.maquinaServicioFisicaId}
              ocultar={this.ocultarActualizar}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarVerDetalle = () => {
    if (
      Global.UsuarioService.getPermisos().Elementos.Maquinas.MaquinaServicioFisica.canView()
    ) {
      return (
        <Tab
          eventKey="ver"
          title="Ver Detalle"
          disabled={this.state.disabledVer}
        >
          {this.state.maquinaServicioFisicaId &&
          this.state.tabVisible === "ver" ? (
            <MaquinaServicioFisicaVerDetalle
              maquinaServicioFisicaId={this.state.maquinaServicioFisicaId}
              ocultar={this.ocultarVer}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey="lista"
      transition={false}
      activeKey={this.state.tabVisible}
      onSelect={this.onSelect}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "center" : "",
      }}
      unmountOnExit={true}
    >
      {this.renderizarNuevo()}
      {this.renderizarLista()}
      {this.renderizarActualizar()}
      {this.renderizarVerDetalle()}
    </Tabs>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {this.renderizar(this.state.responsive)}
      </Flex>
    );
  }
}
