import { Select } from "@mantine/core";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { CargaProductoLectura, DatosProducto, EstadoProducto } from "serviciossaintmichel";
import ProductosShow, { Version } from "../../../../elementos/productos/Producto/components/show/ProductosShow";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";

export interface Props {
  onSubmitCallback?: Function;
  excluirProductos?: Array<number>;
}

interface State {
  nuevaCargaProducto: CargaProductoLectura;
  productoSeleccionado: DatosProducto | null;
}

export default class CargaProductoLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getEstadoInicial();
  }

  productoNuevo = () => {
    return { id: -1, nombre: "" };
  };

  getEstadoInicial = () => ({
    nuevaCargaProducto: {
      producto: this.productoNuevo(),
      cantidad: 0,
      estado_producto: EstadoProducto.LLENO,
    },
    productoSeleccionado: null,
  });

  setProducto = (prod: DatosProducto | null) => {
    const producto = prod !== null ? { id: prod.id, nombre: prod.nombre } : this.productoNuevo();
    this.setState((prevState) => ({
      nuevaCargaProducto: {
        ...prevState.nuevaCargaProducto,
        producto: prod ? producto : this.state.nuevaCargaProducto.producto,
      },
      productoSeleccionado: prod,
    }));
  };

  setEstadoProducto = (valor: string) => {
    this.setState({ nuevaCargaProducto: { ...this.state.nuevaCargaProducto, estado_producto: valor } });
  };

  onSubmit = () => {
    const { onSubmitCallback = () => {} } = this.props;
    onSubmitCallback(this.state.nuevaCargaProducto);
    const estadoInicial = this.getEstadoInicial();
    this.setState(estadoInicial);
  };

  validarCarga = (cargaProducto: CargaProductoLectura) => {
    return cargaProducto.producto.id > 0 && cargaProducto.cantidad > 0;
  };

  render() {
    const { nuevaCargaProducto, productoSeleccionado } = this.state;
    return (
      <Flex borderRadius="5px" border="1px solid black" gap="1rem" padding="1rem" alignItems="center">
        <ProductosShow
          version={Version.SELECT}
          productosExcluidos={this.props.excluirProductos}
          propsSelect={{
            callbackParent: this.setProducto,
            seleccionado: productoSeleccionado,
          }}
        />
        <div>
          <span>Cantidad</span>
          <Input
            type={InputTypes.Number}
            data={nuevaCargaProducto.cantidad}
            onChange={(data: number) => {
              this.setState((prevState) => ({
                nuevaCargaProducto: { ...prevState.nuevaCargaProducto, cantidad: data },
              }));
            }}
          />
        </div>

        <div>
          <span>Estado del producto</span>
          <Select
            value={nuevaCargaProducto.estado_producto}
            onChange={this.setEstadoProducto}
            data={[EstadoProducto.LLENO, EstadoProducto.VACIO, EstadoProducto.ROTO]}
          />
        </div>

        <Button onClick={this.onSubmit} disabled={!this.validarCarga(nuevaCargaProducto)}>
          Añadir
        </Button>
      </Flex>
    );
  }
}
