import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import MaquinasSimbolicasShow, {
  Version,
} from "../../../../../../elementos/maquinas/MaquinaServicioSimbolica/components/show/MaquinasSimbolicasShow";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../../../libreria/data/input/Input";
import { MaquinaSimbolicaAlquiler,MaquinaServicioSimbolicaGet as MaquinaSimbolica  } from "serviciossaintmichel";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  maquina: MaquinaSimbolicaAlquiler;
}

interface State {
  maquina: MaquinaSimbolicaAlquiler;
  maquinaSeleccionada: MaquinaSimbolica | null;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class MaquinaSimbolicaAlquilerLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      maquina: this.props.maquina,
      error: "",
      valid: this.isValid(this.props.maquina),
      screen: Screen.getType(window.innerWidth, window.innerHeight),
      maquinaSeleccionada: null,
    };
    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  isValid = (maquina: MaquinaSimbolicaAlquiler): boolean => {
    return maquina.maquina_simbolica > 0 && maquina.cantidad > 0 ? true : false;
  };

  onChangeCantidad = (cantidad: string) => {
    let maquina = this.state.maquina;
    maquina.cantidad = parseInt(cantidad);
    let valid = this.isValid(maquina);
    this.setState({ maquina: maquina, valid: valid });
    this.props.onChange(maquina);
    if (this.props.onValidationChange) this.props.onValidationChange(valid);
  };

  onChangeMaquina = (maquinaSimbolica: MaquinaSimbolica | null) => {
    if (maquinaSimbolica === null) {
      return;
    }

    let maquina = this.state.maquina;
    maquina.maquina_simbolica = maquinaSimbolica.id;
    let valid = this.isValid(maquina);
    this.setState({ maquina: maquina, valid: valid, maquinaSeleccionada: maquinaSimbolica });
    this.props.onChange(maquina);
    if (this.props.onValidationChange) this.props.onValidationChange(valid);
  };

  render() {
    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" width="100%" alignItems="flex-start" justifyContent="center" marginTop="20px">
          <Form.Label> Seleccione Máquina Simbólica</Form.Label>
          <MaquinasSimbolicasShow
            version={Version.SELECT}
            propsSelect={{ callbackParent: this.onChangeMaquina, seleccionado: this.state.maquinaSeleccionada }}
          />
        </Flex>

        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
          <Input
            data={this.state.maquina.cantidad}
            onChange={this.onChangeCantidad}
            type={InputTypes.Number}
            min={1}
            label="Cantidad"
            placeholder="Cantidad"
          />
        </Flex>
      </Flex>
    );
  }
}
