import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import { CANALES_WEB_SOCKET, RepuestoGet, RepuestoPost, RepuestoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  repuestoId: number;
  ocultar: Function;
}

interface State {
  repuesto: RepuestoGet;
  alerta: any;
}

export default class RepuestoActualizar extends Component<Props, State> {


  private repuestoService:RepuestoService;


  constructor(props: Props) {
    super(props);
    this.state = {
      repuesto: this.crearRepuestoVacio(),
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };


    this.repuestoService = new RepuestoService(Global.UsuarioService.getToken()!);


  }
  componentDidMount() {
    this.cargarRepuesto(this.props.repuestoId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarRepuesto(this.props.repuestoId);
    }
  }

  crearRepuestoVacio(): RepuestoGet {
    const repuesto: RepuestoGet = {
      id: 0,
      nombre: "",
      marca: "",
      descripcion: "",
      proveedores: [],
    };
    return repuesto;
  }

  cargarRepuesto = async (id: number) => {
    const resRepuesto = await this.repuestoService.get(id).catch((error) => {
      this.repuestoService.manejarErrorHTTP(error, "Repuesto");
      return null;
    });
    if (resRepuesto != null) {
      this.setState({
        repuesto: resRepuesto,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  setearNombre = (nombre: string) => {
    let repuesto = this.state.repuesto;
    repuesto.nombre = nombre;
    this.setState({ repuesto: repuesto });
  };
  setearMarca = (marca: string) => {
    let repuesto = this.state.repuesto;
    repuesto.marca = marca;
    this.setState({ repuesto: repuesto });
  };
  setearDescripcion = (descripcion: string) => {
    let repuesto = this.state.repuesto;
    repuesto.descripcion = descripcion;
    this.setState({ repuesto: repuesto });
  };

  handleUpdate() {
    const repuestoActualizar: RepuestoPost = {
      nombre: this.state.repuesto.nombre,
      marca: this.state.repuesto.marca,
      descripcion: this.state.repuesto.descripcion,
    };
    this.repuestoService.update(repuestoActualizar, this.state.repuesto.id)
      .then((result) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_REPUESTOS, {
          repuestoId: this.state.repuesto.id,
        });
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Repuesto Actualizado!",
            titulo: "Atención",
            estado: "block",
          },
        });
      })
      .catch((error) => {
        this.repuestoService.manejarErrorHTTP(error, "Repuesto");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.repuesto.nombre &&
      this.state.repuesto.marca &&
      this.state.repuesto.descripcion
      ? false
      : true;
  };

  render() {
    if (this.state.repuesto.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            <div className={"row"}>
              <div className={"column"}>
                <form
                  className={"form-horizontal"}
                  onSubmit={this.handleSubmit}
                >
                  <div className={"form-group"}>
                    <label htmlFor="nombre">Nombre</label>
                    <InputCaracteres
                      id="nombre"
                      name="nombre"
                      onChange={this.setearNombre}
                      value={this.state.repuesto.nombre}
                      placeholder="Ingrese Nombre de Repuesto"
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="marca">Marca</label>
                    <InputCaracteres
                      id="marca"
                      name="marca"
                      onChange={this.setearMarca}
                      value={this.state.repuesto.marca}
                      placeholder="Ingrese Marca"
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="descripcion">Descripcion</label>
                    <InputCaracteres
                      id="descripcion"
                      name="descripcion"
                      onChange={this.setearDescripcion}
                      value={this.state.repuesto.descripcion}
                      placeholder="Ingrese Descripcion"
                    />
                  </div>
                  <div
                    className={"form-group"}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button
                      ref="boton"
                      type="submit"
                      className={"btn btn-info"}
                      disabled={this.disableButton()}
                    >
                      {"Actualizar"}
                    </button>
                    <button
                      className={"btn btn-outline-secondary"}
                      onClick={this.ocultar}
                    >
                      Ocultar
                    </button>
                  </div>
                  <div className={"form-group"}>
                    <Alerta
                      tipo={this.state.alerta.tipo}
                      titulo={this.state.alerta.titulo}
                      mensaje={this.state.alerta.mensaje}
                      estado={this.state.alerta.estado}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
