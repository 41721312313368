import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Global } from "./Global";


export default class PrivateRoute extends React.Component {
  render() {
    if (Global.UsuarioService.getToken() != null) {
      return <Route component={this.props.component} path={this.props.path} />;
    } else {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: this.props.location },
          }}
        />
      );
    }
  }
}
