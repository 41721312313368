import React, { Component } from "react";
import { Badge, Modal, Select } from "@mantine/core";
import { Table, Button, Form, Col } from "react-bootstrap";
import ResumenVisitaRepartoDiario from "../update/ResumenVisitaRepartoDiario";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import RequestButton from "../../../../libreria/action/request-button/RequestButton";
import * as Icon from "react-bootstrap-icons";

import {
  CANALES_WEB_SOCKET,
  getColorEstadoVisita,
  getColorPrioridadVisita,
  OrdenVisitaRepartoDiarioLectura,
  RepartoDiarioService,
  WebSocketService,
  EstadoVisita,
  PrioridadVisita,
} from "serviciossaintmichel";
import { Global } from "../../../../Global";
import ImagenesURLs from "../../../../compartido/utils/ImagenesURLs";

export interface Props {
  idRepartoDiario: number;
}

interface State {
  ordenVisitas: Array<OrdenVisitaRepartoDiarioLectura> | null;
  visitaIdResumen: number | null;
  filtroCliente: string;
  filtroDireccionCalle: string;
  filtroDireccionNumero: string;
  filtroPrioridad: PrioridadVisita | null;
  filtroEstadoActual: EstadoVisita | null;
}

export default class RepartoDiarioVisitasShow extends Component<Props, State> {
  private webSockets: Array<WebSocket>;
  private repartoDiarioService: RepartoDiarioService;

  constructor(props: Props) {
    super(props);
    this.state = {
      ordenVisitas: null,
      visitaIdResumen: null,
      filtroCliente: "",
      filtroDireccionCalle: "",
      filtroDireccionNumero: "",
      filtroPrioridad: null,
      filtroEstadoActual: null,
    };
    this.webSockets = [];
    this.repartoDiarioService = new RepartoDiarioService(
      Global.UsuarioService.getToken()!
    );
  }

  componentDidMount = async () => {
    this.setOrdenesVisita();
    this.webSockets.push(
      WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
        funcionCallback: this.onChannelMessage,
      })
    );
  };

  componentWillUnmount = () => {
    this.webSockets.forEach((ws: WebSocket) => ws.close());
  };

  onChannelMessage = async (message: MessageEvent) => {
    const dato = JSON.parse(message.data);
    if (dato.message.idRepartoDiario !== this.props.idRepartoDiario) {
      return;
    }
    this.setOrdenesVisita();
  };

  getQueryParams = () => {
    const filtros = {
      visita_cliente: this.state.filtroCliente,
      visita_direccion_calle: this.state.filtroDireccionCalle,
      visita_direccion_numero: this.state.filtroDireccionNumero,
      visita_prioridad: this.state.filtroPrioridad,
      visita_estado_actual: this.state.filtroEstadoActual,
    };

    const resultado = new URLSearchParams();

    for (const [claveFiltro, valorFiltro] of Object.entries(filtros)) {
      if (valorFiltro) {
        resultado.set(claveFiltro, valorFiltro);
      }
    }

    return resultado;
  };

  async setOrdenesVisita() {
    const queryParams = this.getQueryParams();
    const ordenesVisita = await this.repartoDiarioService.getOrdenesVisita(
      this.props.idRepartoDiario,
      queryParams
    );
    ordenesVisita.sort((a, b) => a.orden - b.orden);
    this.setState({ ordenVisitas: ordenesVisita });
  }

  renderModalResumenVisita = (visitaIdResumen: number | null) => {
    return (
      <Modal
        centered
        transitionDuration={0}
        opened={visitaIdResumen !== null}
        onClose={() => {
          this.setState({ visitaIdResumen: null });
        }}
        styles={{
          modal: {
            backgroundImage: ImagenesURLs.urlFondoModal(),
          },
        }}
        title="Resumen de la visita"
        overflow="inside"
      >
        {visitaIdResumen !== null ? (
          <ResumenVisitaRepartoDiario
            visitaId={visitaIdResumen}
            repartoDiarioId={this.props.idRepartoDiario}
          />
        ) : null}
      </Modal>
    );
  };

  renderTablaVisitas = (
    ordenVisitas: Array<OrdenVisitaRepartoDiarioLectura>
  ) => {
    if (ordenVisitas.length === 0) {
      return <div>No se encontraron visitas</div>;
    }

    const filas = ordenVisitas.map((ordenVisita) => (
      <tr key={ordenVisita.visita.id}>
        <td>{ordenVisita.visita.id}</td>
        <td>{ordenVisita.orden}</td>
        <td>{ordenVisita.visita.cliente.razon_social}</td>
        <td>{`${ordenVisita.visita.direccion.calle} N° ${ordenVisita.visita.direccion.numero} - ${ordenVisita.visita.direccion.localidad.nombre}`}</td>
        <td>
          <Badge
            color={getColorEstadoVisita(ordenVisita.visita.estado_actual)}
            variant="filled"
          >
            {ordenVisita.visita.estado_actual}
          </Badge>
        </td>
        <td>
          <Badge
            color={getColorPrioridadVisita(ordenVisita.visita.prioridad)}
            variant="filled"
          >
            {ordenVisita.visita.prioridad}
          </Badge>
        </td>
        <td>
          <Button
            onClick={() => {
              this.setState({ visitaIdResumen: ordenVisita.visita.id });
            }}
          >
            Ver resumen
          </Button>
        </td>
      </tr>
    ));

    return (
      <Table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Orden</th>
            <th>Cliente</th>
            <th>Dirección</th>
            <th>Estado actual</th>
            <th>Prioridad</th>
            <th>Resumen</th>
          </tr>
        </thead>
        <tbody>{filas}</tbody>
      </Table>
    );
  };

  renderPanelBusqueda = () => {
    const minColWidth = "250px";
    const {
      filtroCliente,
      filtroEstadoActual,
      filtroPrioridad,
      filtroDireccionCalle,
      filtroDireccionNumero,
    } = this.state;

    return (
      <Form
        style={{
          padding: "1rem",
          backgroundColor: "gainsboro",
          borderRadius: "5px",
          marginBottom: "1rem",
        }}
      >
        <Form.Row className="align-items-center" style={{ rowGap: "1rem" }}>
          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <Form.Label as="strong">Cliente</Form.Label>
              <Input
                type={InputTypes.Text}
                data={filtroCliente}
                onChange={(data: string) =>
                  this.setState({ filtroCliente: data })
                }
                placeholder="Ingrese razon social del cliente"
              />
            </Flex>
          </Col>

          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <Form.Label as="strong">Direccion</Form.Label>
              <Input
                type={InputTypes.Text}
                data={filtroDireccionCalle}
                onChange={(data: string) =>
                  this.setState({ filtroDireccionCalle: data })
                }
                placeholder="Ingrese calle"
              />
              <Input
                type={InputTypes.Text}
                data={filtroDireccionNumero}
                onChange={(data: string) =>
                  this.setState({ filtroDireccionNumero: data })
                }
                placeholder="Ingrese numero"
              />
            </Flex>
          </Col>

          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <Form.Label as="strong">Estado actual</Form.Label>
              <Select
                placeholder="Seleccione estado"
                value={filtroEstadoActual}
                clearable
                data={[
                  {
                    value: EstadoVisita.EN_ESPERA,
                    label: EstadoVisita.EN_ESPERA,
                  },
                  {
                    value: EstadoVisita.EN_CAMINO,
                    label: EstadoVisita.EN_CAMINO,
                  },
                  {
                    value: EstadoVisita.NO_ENCONTRADO,
                    label: EstadoVisita.NO_ENCONTRADO,
                  },
                  {
                    value: EstadoVisita.NO_VISITADO,
                    label: EstadoVisita.NO_VISITADO,
                  },
                  {
                    value: EstadoVisita.VISITADO,
                    label: EstadoVisita.VISITADO,
                  },
                ]}
                onChange={(estadoSeleccionado: EstadoVisita | null) => {
                  this.setState({ filtroEstadoActual: estadoSeleccionado });
                }}
              />
            </Flex>
          </Col>

          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <Form.Label as="strong">Prioridad</Form.Label>
              <Select
                placeholder="Seleccione prioridad"
                value={filtroPrioridad}
                clearable
                data={[
                  { value: PrioridadVisita.BAJA, label: PrioridadVisita.BAJA },
                  {
                    value: PrioridadVisita.MEDIA,
                    label: PrioridadVisita.MEDIA,
                  },
                  { value: PrioridadVisita.ALTA, label: PrioridadVisita.ALTA },
                  {
                    value: PrioridadVisita.URGENTE,
                    label: PrioridadVisita.URGENTE,
                  },
                ]}
                onChange={(prioridad: PrioridadVisita | null) => {
                  this.setState({ filtroPrioridad: prioridad });
                }}
              />
            </Flex>
          </Col>
          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <RequestButton
                onClick={() => this.setOrdenesVisita()}
                texto="Buscar"
                icon={<Icon.Search />}
              />
            </Flex>
          </Col>
        </Form.Row>
      </Form>
    );
  };

  render() {
    const { ordenVisitas, visitaIdResumen } = this.state;

    if (ordenVisitas === null) {
      return <div>Cargando...</div>;
    }

    return (
      <>
        {this.renderPanelBusqueda()}
        {this.renderTablaVisitas(ordenVisitas)}
        {this.renderModalResumenVisita(visitaIdResumen)}
      </>
    );
  }
}
