import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { EntregaProgramacionMaquina, EstadoLogisticaPaquete, MaquinaServicioSimbolicaGet } from "serviciossaintmichel";
import MaquinasSimbolicasShow, {
  Version,
} from "../../../../elementos/maquinas/MaquinaServicioSimbolica/components/show/MaquinasSimbolicasShow";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";

export interface Props {
  onSubmitCallback?: Function;
  excluirMaquinas?: Array<number>;
}

interface State {
  nuevaEntregaMaquina: EntregaProgramacionMaquina;
  maquinaSeleccionada: MaquinaServicioSimbolicaGet | null;
}

export default class EntregaMaquinaProgramacionEntregaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getEstadoInicial();
  }

  maquinaNueva = (): MaquinaServicioSimbolicaGet => {
    return { id: -1, nombre: "", productos: [], proveedores: [] };
  };

  getEstadoInicial = () => ({
    nuevaEntregaMaquina: {
      id: -1,
      paquete: {
        id: -1,
        maquina: this.maquinaNueva(),
        cantidad: 0,
      },
      estado: EstadoLogisticaPaquete.PENDIENTE_DE_REPARTO,
    },
    maquinaSeleccionada: null,
  });

  setMaquina = (maq: MaquinaServicioSimbolicaGet | null) => {
    const maquinaSimbolica: MaquinaServicioSimbolicaGet =
      maq !== null ? { ...maq, productos: [], proveedores: [] } : this.maquinaNueva();
    this.setState((prevState) => ({
      nuevaEntregaMaquina: {
        ...prevState.nuevaEntregaMaquina,
        maquina: maq ? maquinaSimbolica : this.state.nuevaEntregaMaquina.paquete.maquina,
      },
      maquinaSeleccionada: maq,
    }));
  };

  onSubmit = () => {
    const { onSubmitCallback = () => {} } = this.props;
    onSubmitCallback(this.state.nuevaEntregaMaquina);
    const estadoInicial = this.getEstadoInicial();
    this.setState(estadoInicial);
  };

  validarEntrega = (entregaMaquina: EntregaProgramacionMaquina) => {
    const { paquete } = entregaMaquina;
    return paquete.maquina.id > 0 && paquete.cantidad > 0;
  };

  render() {
    const { nuevaEntregaMaquina, maquinaSeleccionada } = this.state;

    return (
      <Flex borderRadius="5px" border="1px solid black" gap="1rem" padding="1rem" alignItems="center">
        <MaquinasSimbolicasShow
          version={Version.SELECT}
          excluirIds={this.props.excluirMaquinas}
          propsSelect={{
            label: "Máquinas",
            placeholder: "Seleccine una máquina",
            seleccionado: maquinaSeleccionada,
            callbackParent: this.setMaquina,
          }}
        />

        <div>
          <span>Cantidad</span>
          <Input
            type={InputTypes.Number}
            data={nuevaEntregaMaquina.paquete.cantidad}
            onChange={(data: number) => {
              this.setState((prevState) => ({
                nuevaEntregaMaquina: { ...prevState.nuevaEntregaMaquina, cantidad: data },
              }));
            }}
            min={1}
          />
        </div>

        <Button onClick={this.onSubmit} disabled={!this.validarEntrega(nuevaEntregaMaquina)}>
          Añadir
        </Button>
      </Flex>
    );
  }
}
