import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Visibilizador from "../../../../../../libreria/action/visibilizador/Visibilizador";
import { BultoProductoPromocion } from "serviciossaintmichel";

export interface Props {
  bultoProductos: Array<BultoProductoPromocion>;
}
interface State {
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
}

export default class BultoProductosPromocionShow extends Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      cargando: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {};

  renderBultoProductos = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      {this.props.bultoProductos.length > 0
        ? this.renderizarTabla()
        : this.renderSinBultoProductos()}
    </Flex>
  );

  renderSinBultoProductos = () => {
    return "No posee Bulto Productos";
  };

  renderizarFilas = () => {
    return this.props.bultoProductos.map((relacion, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{relacion.bulto_producto.nombre}</td>
          <td className="text-center">{relacion.cantidad_cobrable}</td>
          <td className="text-center">{relacion.cantidad_bonificada}</td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Bulto Producto</th>
            <th className="text-center">Cantidad</th>
            <th className="text-center">Bonificación</th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  renderLoading = () => "Cargando Bulto Productos";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="20px"
      >
        <Visibilizador
          onChange={this.onChangeVisibilizador}
          label={"Bulto Productos"}
          labelAlignment={"flex-start"}
        >
          {this.state.cargando
            ? this.renderLoading()
            : this.renderBultoProductos()}
        </Visibilizador>
      </Flex>
    );
  }
}
