import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Visibilizador from "../../../../../../libreria/action/visibilizador/Visibilizador";
import { VentaMaquinaPedido } from "serviciossaintmichel";

export interface Props {
  ventaMaquinas: Array<VentaMaquinaPedido>;
}
interface State {
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
}

export default class VentaMaquinasPedidoShow extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: true,
      cargando: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {};

  renderVentaMaquinas = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      {this.props.ventaMaquinas.length > 0
        ? this.renderizarTabla()
        : this.renderSinVentaMaquinas()}
    </Flex>
  );

  renderSinVentaMaquinas = () => {
    return "No posee Ventas de Maquinas";
  };

  renderizarFilas = () => {
    return this.props.ventaMaquinas.map((relacion, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{relacion.maquina.nombre}</td>
          <td className="text-center">{relacion.cantidad}</td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Maquina</th>
            <th className="text-center">Cantidad</th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  renderLoading = () => "Cargando Venta de Maquinas";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    if (this.props.ventaMaquinas.length > 0)
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          padding="20px"
          paddingBottom="0px"
        >
          <Visibilizador
            onChange={this.onChangeVisibilizador}
            label={"Venta de Maquinas"}
            labelAlignment={"flex-start"}
            visible={this.state.visible}
          >
            {this.state.cargando
              ? this.renderLoading()
              : this.renderVentaMaquinas()}
          </Visibilizador>
        </Flex>
      );
    else return null;
  }
}
