import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { DatosProducto, EntregaProgramacionProducto, EstadoLogisticaPaquete, ProductoGet } from "serviciossaintmichel";
import ProductosShow, { Version } from "../../../../elementos/productos/Producto/components/show/ProductosShow";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";

export interface Props {
  onSubmitCallback?: Function;
  excluirProductos?: Array<number>;
}

interface State {
  nuevaEntregaProducto: EntregaProgramacionProducto;
  productoSeleccionado: DatosProducto | null;
}

export default class EntregaProductoProgramacionEntregaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getEstadoInicial();
  }

  productoNuevo = (): ProductoGet => {
    return { id: -1, nombre: "", volumen: 0, tipo_producto: { id: 1, tipo: "" }, insumos: [] };
  };

  getEstadoInicial = () => ({
    nuevaEntregaProducto: {
      id: -1,
      paquete: {
        id: -1,
        producto: this.productoNuevo(),
        cantidad: 0,
      },
      estado: EstadoLogisticaPaquete.PENDIENTE_DE_REPARTO,
    },
    productoSeleccionado: null,
  });

  setProducto = (prod: DatosProducto | null) => {
    const producto: ProductoGet = prod !== null ? { ...prod, insumos: [] } : this.productoNuevo();
    this.setState((prevState) => ({
      nuevaEntregaProducto: {
        ...prevState.nuevaEntregaProducto,
        producto: prod ? producto : this.state.nuevaEntregaProducto.paquete.producto,
      },
      productoSeleccionado: prod,
    }));
  };

  onSubmit = () => {
    const { onSubmitCallback = () => {} } = this.props;
    onSubmitCallback(this.state.nuevaEntregaProducto);
    const estadoInicial = this.getEstadoInicial();
    this.setState(estadoInicial);
  };

  validarEntrega = (entregaProducto: EntregaProgramacionProducto) => {
    return entregaProducto.paquete.producto.id > 0 && entregaProducto.paquete.cantidad > 0;
  };

  render() {
    const { nuevaEntregaProducto, productoSeleccionado } = this.state;
    return (
      <Flex borderRadius="5px" border="1px solid black" gap="1rem" padding="1rem" alignItems="center">
        <ProductosShow
          version={Version.SELECT}
          productosExcluidos={this.props.excluirProductos}
          propsSelect={{
            callbackParent: this.setProducto,
            seleccionado: productoSeleccionado,
          }}
        />
        <div>
          <span>Cantidad</span>
          <Input
            type={InputTypes.Number}
            data={nuevaEntregaProducto.paquete.cantidad}
            onChange={(data: number) => {
              this.setState((prevState) => ({
                nuevaEntregaProducto: { ...prevState.nuevaEntregaProducto, cantidad: data },
              }));
            }}
            min={1}
          />
        </div>
        <Button onClick={this.onSubmit} disabled={!this.validarEntrega(nuevaEntregaProducto)}>
          Añadir
        </Button>
      </Flex>
    );
  }
}
