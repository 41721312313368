import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Visibilizador from "../../../../../../libreria/action/visibilizador/Visibilizador";
import Flex from "../../../../../../libreria/appearance/flex/Flex";


import {  AlquilerService, CANALES_WEB_SOCKET, PrecioHistoricoAlquiler, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";



export interface Props {
  id: number;
}
interface State {
  precios: Array<PrecioHistoricoAlquiler>;
  cargando: boolean;
  screen: ScreenType;
}

export default class PreciosHistoricosAlquilerShow extends Component<
  Props,
  State
> {


  private alquilerService:AlquilerService;

  constructor(props: Props) {


    super(props);
    this.state = {
      precios: [],
      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES_PRECIOS,
      funcionCallback: this.onChannelMesagge,
    });

    this.alquilerService =  new AlquilerService(Global.UsuarioService.getToken()!);

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {
    const precios = await this.alquilerService.getPreciosHistoricos(this.props.id);
    this.setState({ precios: precios, cargando: false });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    this.setState({ cargando: true });
    const precios = await this.alquilerService.getPreciosHistoricos(this.props.id);
    this.setState({ precios: precios, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({ cargando: true });
      const precios = await this.alquilerService.getPreciosHistoricos(this.props.id);
      this.setState({ precios: precios, cargando: false });
    }
  };

  renderPrecios = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      {this.renderizarTabla()}
    </Flex>
  );

  renderizarFilas = () => {
    return this.state.precios.map((precio, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{precio.precio}</td>
          <td className="text-center">
            {new Date(precio.fecha_inicio).toLocaleDateString()}
          </td>
          <td className="text-center">
            {precio.fecha_fin
              ? new Date(precio.fecha_fin).toLocaleDateString()
              : ""}
          </td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Precio</th>
            <th className="text-center">Fecha Inicio</th>
            <th className="text-center">Fecha Fin</th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  renderLoading = () => "Cargando Precios";

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Visibilizador
          visible={true}
          label={"Precios Históricos"}
          labelAlignment={"flex-start"}
        >
          {this.state.cargando ? this.renderLoading() : this.renderPrecios()}
        </Visibilizador>
      </Flex>
    );
  }
}
