import { Badge, MultiSelect, Radio } from "@mantine/core";
import React, { Component } from "react";
import { Button, Table, Form, Col } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import BotonOrden from "../../../../../administracion/seccion-clientes/clientes/components/BotonOrden";
import { CriterioOrden } from "../../../../../compartido/models/CriterioOrden";
import FechaUtils from "../../../../../compartido/utils/FechaUtils";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../../libreria/data/input/Input";

import {
  CANALES_WEB_SOCKET,
  WebSocketService,
  Pedido,
  PedidoService,
  getColorEstado,
  EstadosPedido,
  OpcionEstado,
  EstadoPedido,
} from "serviciossaintmichel";
import { Global } from "../../../../../Global";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
}

interface State {
  pedido: Array<Pedido> | null;
  desde: Date | null;
  hasta: Date | null;
  cliente: string | null;
  cuit: string | null;
  estados: Array<EstadosPedido>;
  opcionEstado: OpcionEstado;
  criterioOrden: CriterioOrden | null;
  limite: number;
}

const ordenInicial: CriterioOrden = { campo: "fecha", modo: "DESC" };

export default class PedidosShowTable extends Component<Props, State> {
  private pedidoService: PedidoService;

  private webSocket: WebSocket | null;
  constructor(props: Props) {
    super(props);
    this.state = {
      pedido: null,
      desde: null,
      hasta: null,
      cliente: null,
      cuit: null,
      estados: [],
      opcionEstado: OpcionEstado.OPCION_O,
      criterioOrden: ordenInicial,
      limite: 5,
    };
    this.webSocket = null;

    this.pedidoService = new PedidoService(Global.UsuarioService.getToken()!);
  }

  componentWillUnmount = () => {
    this.webSocket?.close();
  };

  componentDidMount = () => {
    this.setPedidos();
    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PEDIDOS,
      funcionCallback: this.setPedidos,
    });
  };

  setPedidos = async () => {
    const {
      desde,
      hasta,
      cliente,
      cuit,
      estados,
      opcionEstado,
      criterioOrden,
      limite,
    } = this.state;

    try {
      let orden = criterioOrden !== null ? criterioOrden : undefined;

      const filtros = {
        desde: desde,
        hasta: hasta,
        cliente: cliente,
        clienteId: null,
        cuit: cuit,
        estados: estados,
        opcionEstado: opcionEstado,
      };
      const pedidos: Array<Pedido> = await this.pedidoService.getPedidos(
        filtros,
        orden,
        limite
      );

      this.setState({ pedido: pedidos });
    } catch (error) {
      this.pedidoService.manejarErrorHTTP(error, "Pedidos");
    }
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      prevState.criterioOrden !== this.state.criterioOrden ||
      prevState.limite !== this.state.limite
    ) {
      this.setPedidos();
    }
  };

  criteriosOrdenIguales = (
    criterioA: CriterioOrden | null,
    criterioB: CriterioOrden | null
  ): boolean => {
    if (criterioA === null || criterioB === null) {
      return false;
    }
    return (
      criterioA.campo === criterioB.campo && criterioA.modo === criterioB.modo
    );
  };

  seleccionarCriterio = (criterio: CriterioOrden | null) => {
    const { criterioOrden } = this.state;

    if (
      criterioOrden === null ||
      !this.criteriosOrdenIguales(criterioOrden, criterio)
    ) {
      this.setState({ criterioOrden: criterio });
    } else {
      this.setState({ criterioOrden: null });
    }
  };

  getCriterioHandler = (campo: string) => {
    return (modo: "ASC" | "DESC" | null) => {
      const criterioOrden = modo ? { modo: modo, campo: campo } : null;
      this.seleccionarCriterio(criterioOrden);
    };
  };

  getControlCriterio = (campo: string) =>
    this.state.criterioOrden?.campo === campo
      ? this.state.criterioOrden.modo
      : null;

  manejadorScroll = () => {
    const divTabla = document.getElementById("contenedor-scroll");
    if (divTabla === null) {
      return;
    }
    let movimientoTope = divTabla.scrollHeight - divTabla.clientHeight;

    if (movimientoTope === divTabla.scrollTop) {
      this.aumentarLimite();
    }
  };

  aumentarLimite = () => {
    this.setState((prevState) => ({ limite: prevState.limite + 5 }));
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };

  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  renderEstados = (estados: Array<EstadoPedido>) => {
    return (
      <Flex flexDirection="column" alignItems="center">
        {estados.map((estado, idx) => (
          <Badge
            color={getColorEstado(estado.estado)}
            variant="filled"
            fullWidth
          >
            {estado.estado}
          </Badge>
        ))}
      </Flex>
    );
  };

  render = () => {
    const { pedido, cliente, cuit, desde, hasta, estados, opcionEstado } =
      this.state;
    const minColWidth = "250px";

    const arregloEstados = [
      EstadosPedido.CON_PROGRAMACIONES_PENDIENTES,
      EstadosPedido.CON_PROGRAMACIONES_SIN_ASIGNAR,
      EstadosPedido.CON_PROGRAMACIONES_EN_ESPERA_DE_ENTREGA,
      EstadosPedido.FINALIZADO,
      EstadosPedido.PARCIALMENTE_FINALIZADO,
      EstadosPedido.PAGADO,
    ];
    if (pedido === null) {
      return <div>Cargando...</div>;
    }

    return (
      <>
        <Form
          style={{
            padding: "1rem",
            backgroundColor: "gainsboro",
            borderRadius: "5px",
            marginBottom: "1rem",
          }}
        >
          <Form.Row className="align-items-center" style={{ rowGap: "1rem" }}>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <Form.Label as="strong">Cliente</Form.Label>
                <Input
                  data={cliente}
                  placeholder={"Ingrese nombre / apellido / razón social"}
                  onChange={(cliente: string) => {
                    this.setState({ cliente: cliente });
                  }}
                  type={InputTypes.Text}
                />
                <Input
                  data={cuit}
                  placeholder={"Ingrese cuit / cuil"}
                  onChange={(cuit: string) => {
                    this.setState({ cuit: cuit });
                  }}
                  type={InputTypes.Number}
                />
              </Flex>
            </Col>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <Form.Label as="strong">Desde</Form.Label>
                <DatePicker
                  placeholderText="Ingrese fecha"
                  onChange={(fecha: Date) => {
                    this.setState({ desde: fecha });
                  }}
                  selected={desde}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                />
              </Flex>
              <Flex flexDirection="column" alignItems="center">
                <Form.Label as="strong">Hasta</Form.Label>
                <DatePicker
                  placeholderText="Ingrese fecha"
                  onChange={(fecha: Date) => {
                    this.setState({ hasta: fecha });
                  }}
                  selected={hasta}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                />
              </Flex>
            </Col>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <Form.Label as="strong">Estados</Form.Label>
                <Flex flexDirection="column" alignItems="center">
                  <MultiSelect
                    style={{ flex: "auto" }}
                    placeholder="Seleccione Estados"
                    clearable
                    value={estados}
                    onChange={(estados: Array<EstadosPedido>) => {
                      this.setState({ estados: estados });
                    }}
                    data={arregloEstados}
                  />
                  <Radio.Group
                    style={{ flex: "auto" }}
                    label="Operador para búsqueda de estados"
                    value={opcionEstado}
                    onChange={(value: OpcionEstado) => {
                      if (value == OpcionEstado.OPCION_Y)
                        this.setState({ opcionEstado: OpcionEstado.OPCION_Y });
                      else
                        this.setState({ opcionEstado: OpcionEstado.OPCION_O });
                    }}
                  >
                    <Radio
                      value={OpcionEstado.OPCION_Y}
                      label="En todos los estados"
                    />
                    <Radio
                      value={OpcionEstado.OPCION_O}
                      label="En alguno de los estados"
                    />
                  </Radio.Group>
                </Flex>
              </Flex>
            </Col>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <RequestButton
                  onClick={() => this.setPedidos()}
                  texto="Buscar"
                  icon={<Icon.Search />}
                />
              </Flex>
            </Col>
          </Form.Row>
        </Form>

        <div
          style={{ maxHeight: "350px", overflowY: "scroll" }}
          onScroll={this.manejadorScroll}
          id="contenedor-scroll"
        >
          <Table striped bordered>
            <thead>
              <tr>
                <th
                  className="text-center"
                  style={{ position: "sticky", top: "0" }}
                >
                  #
                </th>
                <th
                  className="text-center"
                  style={{ position: "sticky", top: "0" }}
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="nowrap"
                    gap="0.5rem"
                  >
                    <span>Id </span>
                    <BotonOrden
                      criterio={this.getControlCriterio("id")}
                      onCriterioClicked={this.getCriterioHandler("id")}
                    />
                  </Flex>
                </th>
                <th
                  className="text-center"
                  style={{ position: "sticky", top: "0" }}
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="nowrap"
                    gap="0.5rem"
                  >
                    <span>Cliente</span>
                    <BotonOrden
                      criterio={this.getControlCriterio("cliente")}
                      onCriterioClicked={this.getCriterioHandler("cliente")}
                    />
                  </Flex>
                </th>
                <th
                  className="text-center"
                  style={{ position: "sticky", top: "0" }}
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="nowrap"
                    gap="0.5rem"
                  >
                    <span>Fecha </span>
                    <BotonOrden
                      criterio={this.getControlCriterio("fecha")}
                      onCriterioClicked={this.getCriterioHandler("fecha")}
                    />
                  </Flex>
                </th>
                <th
                  className="text-center"
                  style={{ position: "sticky", top: "0" }}
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="nowrap"
                    gap="0.5rem"
                  >
                    <span>Estados</span>
                  </Flex>
                </th>
                <th
                  className="text-center"
                  style={{ position: "sticky", top: "0" }}
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="nowrap"
                    gap="0.5rem"
                  >
                    <span>Monto</span>
                  </Flex>
                </th>
                <th
                  className="text-center"
                  style={{ position: "sticky", top: "0" }}
                ></th>
                <th
                  className="text-center"
                  style={{ position: "sticky", top: "0" }}
                ></th>
              </tr>
            </thead>

            <tbody>
              {pedido.map((pedido, idx) => (
                <tr>
                  <td className="text-center">{idx + 1}</td>
                  <td className="text-center">{pedido.id}</td>
                  <td className="text-center">{pedido.cliente.razon_social}</td>
                  <td className="text-center">
                    {pedido.fuente.fecha
                      ? FechaUtils.normalizarFecha(
                          pedido.fuente.fecha
                        ).toLocaleDateString("es-AR")
                      : "-"}
                  </td>
                  <td className="text-center">
                    {this.renderEstados(pedido.estados_pedido)}
                  </td>
                  <td className="text-center">{pedido.monto + " $"}</td>
                  <td className="text-center">
                    <Button
                      onClick={() => {
                        this.onView(pedido.id);
                      }}
                    >
                      Ver detalle
                    </Button>
                  </td>
                  <td className="text-center">
                    <Button
                      onClick={() => {
                        this.onUpdate(pedido.id);
                      }}
                    >
                      Actualizar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  };
}
