import React, { Component } from "react";
import "../template.css";
import Swal from "sweetalert2";
import Flex from "../../libreria/appearance/flex/Flex";
import * as Icon from "react-bootstrap-icons";
import { Global } from "../../Global";
import "./header.css";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  collapseToogle() {
    this.setState({
      active: true,
    });
  }

  cerrarSesion = async () => {
    let cancelar = false;
    await Swal.fire({
      icon: "question",
      title: "Desea cerrar su sesion?",
      showDenyButton: true,
      confirmButtonText: "Confirmar",
      denyButtonText: "Cancelar",
    }).then((result) => {
      if (result.isDenied) {
        cancelar = true;
      }
    });
    if (cancelar) {
      return;
    }
    if (await Global.UsuarioService.deslogearUsuario()) this.props.history.replace(this.props.history.location);
  };

  render() {
    return (
      <Flex
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="85%"
        height="120px"
        marginTop="10px"
      >
        <Flex flexDirection="column" justifyContent="center" alignItems="center" width="25%"></Flex>

        <Flex flexDirection="column" justifyContent="center" alignItems="center" width="50%">
          <div className="logocentral"></div>
        </Flex>

        <Flex flexDirection="column" justifyContent="flex-end" alignItems="flex-end" width="25%">
          <Flex
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            border="2px solid #204399"
            borderRadius="7px"
            borderColor="#1f90d1"
            width="220px"
            height="50px"
          >
            <strong>
              {this.props.usuario != null ? "Usuario: " + this.props.usuario.username : "Usuario: " + "Invitado"}
            </strong>

            <a href="#">
              <Icon.PersonFill size={22} style={{ marginLeft: "7px", marginBottom: "2px", color: "#204399" }} />
            </a>
            <a onClick={this.cerrarSesion} href="#">
              <Icon.ArrowLeftSquare size={22} style={{ marginLeft: "10px", marginBottom: "2px", color: "#204399" }} />
            </a>
          </Flex>
        </Flex>
      </Flex>
    );
  }
}
