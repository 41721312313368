import React, { Component } from "react";

import Flex from "../../../../compartido/components/flex/Flex";
import Box from "../../../../compartido/components/box/Box";
import { ListGroup, Image, Button } from "react-bootstrap";
import { API_URL } from "serviciossaintmichel";

import {
  ArchivoImpresion,
  ArticuloGraficoGet,
  ArticuloGraficoService,
  CANALES_WEB_SOCKET,
  ImagenArticuloGraficoService,
  WebSocketService,
} from "serviciossaintmichel";
import { Global } from "../../../../Global";
export interface Props {
  articuloGraficoId: number;
  ocultar: Function;
}

interface State {
  articuloGrafico: ArticuloGraficoGet;
  anchoBox: string;
}

export default class ArticuloGraficoVerDetalle extends Component<Props, State> {
  private articuloGraficoService: ArticuloGraficoService;
  private imagenArticuloGraficoService: ImagenArticuloGraficoService;

  constructor(props: Props) {
    super(props);

    this.state = {
      articuloGrafico: this.crearArticuloGraficoVacio(),
      anchoBox: window.innerWidth < 750 ? "90%" : "50%",
    };

    this.articuloGraficoService = new ArticuloGraficoService(Global.UsuarioService.getToken()!);
    this.imagenArticuloGraficoService = new ImagenArticuloGraficoService(Global.UsuarioService.getToken()!);

    window.addEventListener("resize", this.actualizarDimensiones);
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_ARTICULOS_GRAFICO,
      funcionCallback: this.cargarArticuloGraficoCanal,
    });
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 750) {
      this.setState({ anchoBox: "90%" });
    } else {
      this.setState({ anchoBox: "50%" });
    }
  };

  componentDidMount() {
    this.cargarArticuloGrafico(this.props.articuloGraficoId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarArticuloGrafico(this.props.articuloGraficoId);
    }
  }

  crearArticuloGraficoVacio(): ArticuloGraficoGet {
    const archivo_impresion: ArchivoImpresion = {
      imagen_impresion: "",
    };
    const articuloGrafico: ArticuloGraficoGet = {
      id: 0,
      nombre: "",
      agencia_diseño: "",
      archivo_impresion: archivo_impresion,
      descripcion: "",
      proveedores: [],
    };
    return articuloGrafico;
  }

  cargarArticuloGraficoCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (this.state.articuloGrafico && dato.message.articuloGraficoId == this.state.articuloGrafico.id) {
      this.cargarArticuloGrafico(this.state.articuloGrafico.id);
    }
  };

  cargarArticuloGrafico = async (id: number) => {
    const resArticuloGrafico = await this.articuloGraficoService.get(id).catch((error) => {
      this.articuloGraficoService.manejarErrorHTTP(error, "Árticulo Gráfico");
      return null;
    });
    if (resArticuloGrafico != null) {
      this.setState({
        articuloGrafico: resArticuloGrafico,
      });
    }
  };

  descargarArchivo = () => {
    this.imagenArticuloGraficoService
      .descargarArchivo(this.state.articuloGrafico.id, this.state.articuloGrafico.archivo_impresion.imagen_impresion)
      .catch((error) => {
        this.imagenArticuloGraficoService.manejarErrorHTTP(error, "Imagen Árticulo Gráfico");
      });
  };

  ocultar = () => {
    this.props.ocultar();
  };

  render() {
    if (this.state.articuloGrafico.id != 0) {
      return (
        <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <Box paddingHorizontal={"10rem"} paddingVertical={"2rem"}>
            <Flex container flexDirection="column">
              <Flex container flexDirection="row" alignItems="center" justifyContent="center">
                <div>
                  <h5>Nombre: {this.state.articuloGrafico.nombre}</h5>
                  <h5>Marca: {this.state.articuloGrafico.agencia_diseño}</h5>
                  <h5>Descripcion: {this.state.articuloGrafico.descripcion}</h5>
                  <h5>Imagen: </h5>
                  {this.state.articuloGrafico.archivo_impresion.imagen_impresion ? (
                    <Flex container flexDirection="column" alignItems="center">
                      {!this.state.articuloGrafico.archivo_impresion.imagen_impresion.endsWith(".pdf") ? (
                        <Image src={API_URL + this.state.articuloGrafico.archivo_impresion.imagen_impresion} fluid />
                      ) : (
                        <div>
                          <p>
                            Abrir pdf en{" "}
                            <a href={API_URL + this.state.articuloGrafico.archivo_impresion.imagen_impresion}>
                              nueva pestaña
                            </a>
                            .
                          </p>
                          <iframe
                            src={API_URL + this.state.articuloGrafico.archivo_impresion.imagen_impresion + "#toolbar=0"}
                            width="100%"
                            height="500px"
                          ></iframe>
                        </div>
                      )}
                      <Button variant="success" style={{ marginTop: "0.5rem" }} onClick={this.descargarArchivo}>
                        Descargar
                      </Button>
                    </Flex>
                  ) : (
                    <>No tiene</>
                  )}
                </div>
              </Flex>
            </Flex>

            <Flex container flexDirection="row" alignItems="center" justifyContent="center">
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>

            <Flex container flexDirection="column">
              <Flex container flexDirection="row" alignItems="center" justifyContent="center">
                <div>
                  <h4>
                    Proveedores Actuales
                    {this.state.articuloGrafico.proveedores.length == 0 ? (
                      " : No tiene"
                    ) : (
                      <Flex container flexDirection="row" alignItems="center" justifyContent="center" paddingTop="10px">
                        <ListGroup>
                          {this.state.articuloGrafico.proveedores.map((relacion) => (
                            <ListGroup.Item style={{ textAlign: "center" }}>
                              {relacion.proveedor.nombre +
                                " - Cantidad mínima que vende: " +
                                relacion.cantidad_minima +
                                " - A: " +
                                relacion.precio +
                                "$"}
                              <Button
                                style={{
                                  marginLeft: "1rem",
                                }}
                              >
                                Ver Historial
                              </Button>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Flex>
                    )}
                  </h4>
                </div>
              </Flex>
            </Flex>
            <Flex container flexDirection="row" alignItems="center" justifyContent="center">
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>
            <Flex container flexDirection="row" alignItems="center" justifyContent="center">
              <button className={"btn btn-outline-secondary"} onClick={this.ocultar} style={{ width: "150px" }}>
                Salir
              </button>
            </Flex>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
