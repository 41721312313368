import { Loader, Modal, Checkbox } from "@mantine/core";
import React, { Component } from "react";
import { Button, Col, Form } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import {
  RepartoDiarioService,
  ProgramacionEntregaService,
  Reparto,
  TipoReparto,
  WebSocketService,
  CANALES_WEB_SOCKET,
  ProgramacionEntrega,
  RepartoDiarioLectura,
} from "serviciossaintmichel";
import { Global } from "../../../Global";
import RepartoDiarioNew from "../../repartos-diarios/components/new/RepartoDiarioNew";
import ResumenProgramacionesEntrega from "./ResumenProgramacionesEntrega";
import TablaLogisticaRepartosDiarios from "./TablaLogisticaRepartosDiarios";
import {
  ResumenProgramacionesEntrega as ResumenProgramacionesEntregaI,
  RepartoDiarioResumenLogisticaPedido,
  ResumenesLogisticaPedidoParams,
} from "serviciossaintmichel";
import Input, { InputTypes } from "../../../libreria/data/input/Input";
import RepartosShow, { Version } from "../../repartos/components/show/RepartosShow";
import RequestButton from "../../../libreria/action/request-button/RequestButton";
import TablaProgramaciones from "./TablaProgramaciones";
import RepartoDiarioOrdenVisitasUpdate from "../../repartos-diarios/components/update/RepartoDiarioOrdenVisitasUpdate";
import ManejadorErroresHTTP from "../../../compartido/utils/ManejadorErroresHTTP";
import { formatISO } from "date-fns";
import ImagenesURLs from "../../../compartido/utils/ImagenesURLs";

export interface Props {
  fecha: Date;
}

interface State {
  nuevoRepartoDiarioModal: boolean;
  resumenProgramaciones: ResumenProgramacionesEntregaI | null;
  programacionesRepartoDiario: Array<ProgramacionEntrega> | null;
  organizarRecorridoRepartoDiarioId: number | null;
  resumenesRepartosDiarios: Array<RepartoDiarioResumenLogisticaPedido> | null;
  filtroBusqueda: ResumenesLogisticaPedidoParams;
  repartoSeleccionado: Reparto | null;
}

const TIPOS_REPARTO: Array<TipoReparto> = ["Comercio", "Mixto", "Domicilio"];

export default class LogisticaPedidosRepartosDiarios extends Component<Props, State> {
  private programacionEntregaService: ProgramacionEntregaService;
  private repartoDiarioService: RepartoDiarioService;
  private webSockets: Array<WebSocket>;

  constructor(props: Props) {
    super(props);
    this.state = {
      nuevoRepartoDiarioModal: false,
      resumenProgramaciones: null,
      resumenesRepartosDiarios: null,
      filtroBusqueda: {
        fechaRealizacion: props.fecha,
        viajeNumero: undefined,
        repartoId: undefined,
        tiposReparto: ["Comercio", "Mixto"],
      },
      repartoSeleccionado: null,
      programacionesRepartoDiario: null,
      organizarRecorridoRepartoDiarioId: null,
    };
    const token = Global.UsuarioService.getToken()!;
    this.repartoDiarioService = new RepartoDiarioService(token);
    this.programacionEntregaService = new ProgramacionEntregaService(token);
    this.webSockets = [];
  }

  componentDidMount() {
    this.setData();
    this.webSockets.push(
      WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.VENTAS_PROGRAMACIONES_ENTREGA,
        funcionCallback: () => {
          this.setData();
        },
      })
    );
    this.webSockets.push(
      WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
        funcionCallback: async () => {
          this.setData();
        },
      })
    );
  }

  componentWillUnmount(): void {
    this.webSockets.forEach((ws) => ws.close());
  }

  setData = async () => {
    const { fecha } = this.props;
    const { filtroBusqueda } = this.state;
    const resumenes = await this.repartoDiarioService.getResumenesLogisticaPedido(filtroBusqueda);
    let idProgramaciones: Array<number> = [];
    resumenes.forEach((resumen) => {
      idProgramaciones = idProgramaciones.concat(resumen.resumen_programaciones.id_programaciones);
    });
    const resumenTotal = await this.programacionEntregaService.generarResumen(fecha, idProgramaciones);
    this.setState({
      resumenProgramaciones: resumenTotal,
      resumenesRepartosDiarios: resumenes,
    });
  };

  renderTipoRepartoCheckbox = () => {
    const { tiposReparto } = this.state.filtroBusqueda;

    return (
      <Checkbox.Group
        value={tiposReparto}
        onChange={(seleccionados: Array<string>) => {
          this.setState((prevState: State) => ({
            ...prevState,
            filtroBusqueda: {
              ...prevState.filtroBusqueda,
              tiposReparto: seleccionados,
            },
          }));
        }}
      >
        {TIPOS_REPARTO.map((tipo) => (
          <Checkbox value={tipo} label={tipo} />
        ))}
      </Checkbox.Group>
    );
  };

  setProgramacionesEntregaRepartoDiario = async (repartoDiarioId: number) => {
    const programaciones = await this.repartoDiarioService.getProgramacionesEntrega(repartoDiarioId);
    this.setState({ programacionesRepartoDiario: programaciones });
  };

  descargarPDFRepartoDiario = async (
    repartoDiario: Pick<RepartoDiarioLectura, "id" | "reparto" | "fecha_realizacion" | "viaje_numero">
  ) => {
    const fechaStr = formatISO(this.props.fecha, { representation: "date" });
    const nombreArchivo = `${repartoDiario.reparto.nombre} - ${fechaStr} - Viaje ${repartoDiario.viaje_numero}.pdf`;
    try {
      await this.repartoDiarioService.descargarArchivo(repartoDiario.id, nombreArchivo, "generar-pdf");
    } catch (error) {
      ManejadorErroresHTTP.evaluarError(error, "Reparto Diario");
    }
  };

  render() {
    const {
      nuevoRepartoDiarioModal,
      resumenProgramaciones,
      resumenesRepartosDiarios,
      filtroBusqueda,
      repartoSeleccionado,
      programacionesRepartoDiario,
      organizarRecorridoRepartoDiarioId,
    } = this.state;

    if (resumenProgramaciones === null || resumenesRepartosDiarios === null) {
      return <Loader />;
    }

    return (
      <>
        <Form
          style={{
            padding: "1rem",
            backgroundColor: "gainsboro",
            borderRadius: "5px",
            marginBottom: "1rem",
          }}
        >
          <Form.Row className="align-items-center" style={{ rowGap: "1rem" }}>
            <Col md>
              <Form.Label as="strong">Numero de viaje</Form.Label>
              <Input
                data={filtroBusqueda.viajeNumero}
                placeholder={"Ingrese numero de viaje"}
                onChange={(numeroViaje: number) => {
                  this.setState((prevState: State) => ({
                    ...prevState,
                    filtroBusqueda: {
                      ...prevState.filtroBusqueda,
                      viajeNumero: numeroViaje,
                    },
                  }));
                }}
                type={InputTypes.Number}
              />
            </Col>
            <Col md>
              <Form.Label as="strong">Reparto</Form.Label>
              <RepartosShow
                version={Version.SELECT}
                propsSelect={{
                  callbackParent: (reparto: Reparto | null) => {
                    this.setState((prevState: State) => ({
                      ...prevState,
                      filtroBusqueda: {
                        ...prevState.filtroBusqueda,
                        repartoId: reparto !== null ? reparto.id : undefined,
                      },
                      repartoSeleccionado: reparto,
                    }));
                  },
                  seleccionado: repartoSeleccionado ? repartoSeleccionado.id : null,
                  clearable: true,
                }}
              />
            </Col>
            <Col md>
              <Form.Label as="strong">Tipo de Reparto</Form.Label>
              {this.renderTipoRepartoCheckbox()}
            </Col>

            <Col md>
              <RequestButton
                onClick={() => {
                  this.setData();
                }}
                texto="Buscar"
                icon={<Icon.Search />}
              />
            </Col>
          </Form.Row>
        </Form>
        <TablaLogisticaRepartosDiarios
          resumenesRepartosDiarios={resumenesRepartosDiarios}
          accionesFila={[
            {
              nombre: "Ver programaciones asignadas",
              manejador: (
                repartoDiario: Pick<RepartoDiarioLectura, "id" | "reparto" | "fecha_realizacion" | "viaje_numero">
              ) => {
                this.setProgramacionesEntregaRepartoDiario(repartoDiario.id);
              },
            },
            {
              nombre: "Organizar recorrido",
              manejador: (
                repartoDiario: Pick<RepartoDiarioLectura, "id" | "reparto" | "fecha_realizacion" | "viaje_numero">
              ) => {
                this.setState({
                  organizarRecorridoRepartoDiarioId: repartoDiario.id,
                });
              },
            },
            {
              nombre: "Generar PDF",
              manejador: (
                repartoDiario: Pick<RepartoDiarioLectura, "id" | "reparto" | "fecha_realizacion" | "viaje_numero">
              ) => {
                this.descargarPDFRepartoDiario(repartoDiario);
              },
            },
          ]}
        />
        <ResumenProgramacionesEntrega
          resumenProgramaciones={resumenProgramaciones}
          titulo={`Resumen de las programaciones asociadas a repartos diarios`}
        />
        <Button
          onClick={() => {
            this.setState({ nuevoRepartoDiarioModal: true });
          }}
          variant="success"
          style={{ marginBottom: "1rem" }}
        >
          Crear reparto diario
        </Button>
        <Modal
          centered
          transitionDuration={0}
          opened={nuevoRepartoDiarioModal}
          title="Crear reparto diario"
          overflow="inside"
          size="40%"
          onClose={() => {
            this.setState({ nuevoRepartoDiarioModal: false });
          }}
          styles={{
            modal: {
              backgroundImage: ImagenesURLs.urlFondoModal(),
            },
          }}
        >
          {
            <div style={{ margin: "1rem 0" }}>
              <RepartoDiarioNew
                onSaveSuccess={() => {
                  this.setState({ nuevoRepartoDiarioModal: false });
                }}
              />
            </div>
          }
        </Modal>
        <Modal
          centered
          transitionDuration={0}
          opened={programacionesRepartoDiario !== null}
          title="Programaciones de entrega asociadas"
          overflow="inside"
          size="70%"
          onClose={() => {
            this.setState({ programacionesRepartoDiario: null });
          }}
          styles={{
            modal: {
              backgroundImage: ImagenesURLs.urlFondoModal(),
            },
          }}
        >
          <div style={{ margin: "1rem 0" }}>
            <TablaProgramaciones
              data={programacionesRepartoDiario ?? []}
              permitirSeleccion={false}
              permitirVerDetalle={false}
            />
          </div>
        </Modal>
        <Modal
          centered
          transitionDuration={0}
          opened={organizarRecorridoRepartoDiarioId !== null}
          title="Organizar recorrido"
          overflow="inside"
          size="70%"
          onClose={() => {
            this.setState({ organizarRecorridoRepartoDiarioId: null });
          }}
          styles={{
            modal: {
              backgroundImage: ImagenesURLs.urlFondoModal(),
            },
          }}
        >
          {organizarRecorridoRepartoDiarioId !== null ? (
            <RepartoDiarioOrdenVisitasUpdate idRepartoDiario={organizarRecorridoRepartoDiarioId} />
          ) : null}
        </Modal>
      </>
    );
  }
}
