import { Divider } from "@mantine/core";
import _ from "lodash";
import Swal from "sweetalert2";
import React, { Component } from "react";
import { Table, Button } from "react-bootstrap";
import FechaDisplay from "../../../../compartido/components/fecha-display/FechaDisplay";
import RequestButton from "../../../../libreria/action/request-button/RequestButton";
import Box from "../../../../libreria/containers/box/Box";
import ManejadorErroresHTTP from "../../../../compartido/utils/ManejadorErroresHTTP";
import EntregaVisitaLoad from "../load/EntregaVisitaLoad";
import H from "../../../../libreria/message/title/H";
import Flex from "../../../../libreria/appearance/flex/Flex";


import { CANALES_WEB_SOCKET,          CargamentoLectura,          EntregaBultoProductoLectura,          EntregaComboRetornableLectura,          EntregaMaquinaLectura,          EntregaProductoLectura,          EntregaVisitaEscritura,          EntregaVisitaLectura,          RepartoDiarioService,   VisitaService,   WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  visitaId: number;
  repartoDiarioId: number;
}

interface State {
  entrega: EntregaVisitaLectura | null;
  entregaFormulario: EntregaVisitaLectura | null;
  entregaFormularioValida: boolean;
  cargando: boolean;
}

export default class EntregaVisitaUpdate extends Component<Props, State> {
  private webSockets: Array<WebSocket>;
  private repartoDiarioService:RepartoDiarioService;
  private visitaService:VisitaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      entrega: null,
      entregaFormulario: null,
      entregaFormularioValida: false,
      cargando: true,
    };
    this.webSockets = [];
    this.repartoDiarioService =  new RepartoDiarioService(Global.UsuarioService.getToken()!);
    this.visitaService =  new VisitaService(Global.UsuarioService.getToken()!);

  }

  cargarVisita = async () => {
    try {
      const { entrega } = await this.repartoDiarioService.getVisita(this.props.repartoDiarioId, this.props.visitaId);
      this.setState({ entrega: entrega });
    } catch (error) {
      this.visitaService.manejarErrorHTTP(error);
    } finally {
      this.setState({ cargando: false });
    }
  };

  componentDidMount = async () => {
    await this.cargarVisita();
    this.actualizarValidezEntregaFormulario();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!_.isEqual(prevState.entregaFormulario, this.state.entregaFormulario)) {
      this.actualizarValidezEntregaFormulario();
    }
  };

  componentWillUnmount = () => {
    this.webSockets.forEach((ws: WebSocket) => ws.close());
  };

  renderEntregasProductos = (productosEntregados: Array<EntregaProductoLectura>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Producto</th>
            <th>Entregados</th>
            <th>Vacíos</th>
          </tr>
        </thead>
        <tbody>
          {productosEntregados.map((entregaProducto) => (
            <tr key={entregaProducto.producto.id}>
              <td>{entregaProducto.producto.nombre}</td>
              <td>{entregaProducto.entregados}</td>
              <td>{entregaProducto.vacios}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderEntregasBultosProductos = (bultosProductosEntregados: Array<EntregaBultoProductoLectura>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Bulto Producto</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>
          {bultosProductosEntregados.map((entregaBultoProducto) => (
            <tr key={entregaBultoProducto.bulto_producto.id}>
              <td>{entregaBultoProducto.bulto_producto.nombre}</td>
              <td>{entregaBultoProducto.cantidad}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderVentasCombosRetornables = (combosRetornablesEntregados: Array<EntregaComboRetornableLectura>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Combo Retornable</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>
          {combosRetornablesEntregados.map((entregaComboRetornable) => (
            <tr key={entregaComboRetornable.combo_retornable.id}>
              <td>{entregaComboRetornable.combo_retornable.nombre}</td>
              <td>{entregaComboRetornable.cantidad}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderEntregasMaquinas = (maquinasEntregadas: Array<EntregaMaquinaLectura>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Máquina</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>
          {maquinasEntregadas.map((entregaMaquina) => (
            <tr key={entregaMaquina.maquina_simbolica.id}>
              <td>{entregaMaquina.maquina_simbolica.nombre}</td>
              <td>{entregaMaquina.cantidad}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderEntrega = (entrega: EntregaVisitaLectura) => {
    return (
      <div>
        <Box padding="1rem">
          <Flex justifyContent="space-between">
            <H size={3} texto="Entrega" />
            <Button
              onClick={() => {
                this.setState({ entregaFormulario: _.cloneDeep(entrega) });
              }}
            >
              Modificar
            </Button>
          </Flex>
          <div>
            <span>Creada: </span>
            <FechaDisplay fecha={entrega.fecha_creacion} incluirTiempo />
          </div>
          <div>
            <span>Última modificación: </span>
            <FechaDisplay fecha={entrega.fecha_modificacion} incluirTiempo />
          </div>

          <Divider label="Productos" labelPosition="center" variant="dashed" />
          {this.renderEntregasProductos(entrega.productos_entregados)}

          <Divider label="Bultos de productos" labelPosition="center" variant="dashed" />
          {this.renderEntregasBultosProductos(entrega.bultos_producto_entregados)}

          <Divider label="Combos retornables" labelPosition="center" variant="dashed" />
          {this.renderVentasCombosRetornables(entrega.combos_retornables_entregados)}

          <Divider label="Máquinas" labelPosition="center" variant="dashed" />
          {this.renderEntregasMaquinas(entrega.maquinas_entregadas)}
        </Box>
      </div>
    );
  };

  buildEntrega = (): EntregaVisitaLectura => {
    return {
      id: -1,
      remito: null,
      fecha_creacion: new Date(),
      fecha_modificacion: new Date(),
      productos_entregados: [],
      bultos_producto_entregados: [],
      combos_retornables_entregados: [],
      maquinas_entregadas: [],
    };
  };

  actualizarValidezEntregaFormulario = () => {
    if (this.state.entregaFormulario === null) {
      return;
    }

    const esValida = this.entregaEsValida(this.state.entregaFormulario);
    this.setState({ entregaFormularioValida: esValida });
  };

  onVentaLoadChange = (entregaLoad: EntregaVisitaLectura) => {
    this.setState({ entregaFormulario: entregaLoad });
  };

  entregaEsValida = (entrega: EntregaVisitaLectura) => {
    return (
      entrega.productos_entregados.length +
        entrega.bultos_producto_entregados.length +
        entrega.combos_retornables_entregados.length +
        entrega.maquinas_entregadas.length >
        0 &&
      entrega.productos_entregados.every((ep) => ep.entregados > 0) &&
      entrega.bultos_producto_entregados.every((ebp) => ebp.cantidad > 0) &&
      entrega.combos_retornables_entregados.every((ec) => ec.cantidad > 0) &&
      entrega.maquinas_entregadas.every((em) => em.cantidad > 0)
    );
  };

  guardarEntrega = async () => {
    const entrega = this.state.entregaFormulario;

    if (entrega === null) {
      return;
    }

    const entregaEscritura: EntregaVisitaEscritura = {
      id: entrega.id,
      remito: entrega.remito,
      productos_entregados: entrega.productos_entregados.map((ep) => ({ ...ep, producto: ep.producto.id })),
      bultos_producto_entregados: entrega.bultos_producto_entregados.map((ebp) => ({
        ...ebp,
        bulto_producto: ebp.bulto_producto.id,
      })),
      combos_retornables_entregados: entrega.combos_retornables_entregados.map((ec) => ({
        ...ec,
        combo_retornable: ec.combo_retornable.id,
      })),
      maquinas_entregadas: entrega.maquinas_entregadas.map((em) => ({
        ...em,
        maquina_simbolica: em.maquina_simbolica.id,
      })),
    };

    try {
      const metodoService = entrega.id > 0 ? this.visitaService.actualizarEntrega : this.visitaService.crearEntrega;
      await metodoService(this.props.visitaId, entregaEscritura);
      Swal.fire({
        icon: "success",
        text: "Datos guardados con éxito",
      });
      await this.cargarVisita();
      this.setState({ entregaFormulario: this.state.entrega });
    } catch (error) {
      const msjError = ManejadorErroresHTTP.getMensajeError(error);
      Swal.fire({
        icon: "error",
        text: "Ocurrió un error - " + msjError,
      });
    }
  };

  render() {
    const { entrega, entregaFormulario, cargando, entregaFormularioValida } = this.state;

    if (cargando) {
      return <div>Cargando...</div>;
    }

    if (entregaFormulario !== null) {
      return (
        <Box
          padding="1rem"
          showCloseBtn
          onCloseCallback={() => {
            this.setState({ entregaFormulario: null });
          }}
          title={entregaFormulario.id > 0 ? `Modificación de entrega` : "Registro de entrega"}
        >
          <EntregaVisitaLoad entregaVisita={entregaFormulario} onChange={this.onVentaLoadChange} />

          <RequestButton
            propsBoton={{ style: { marginTop: "1rem" }, variant: "success", disabled: !entregaFormularioValida }}
            texto={"Guardar"}
            onClick={this.guardarEntrega}
          />
        </Box>
      );
    }

    if (entrega !== null) {
      return this.renderEntrega(entrega);
    }

    return (
      <>
        <div>La visita no posee una entrega registrada</div>
        <Button
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
          variant="success"
          onClick={() => {
            this.setState({ entregaFormulario: this.buildEntrega() });
          }}
        >
          Registrar entrega
        </Button>
      </>
    );
  }
}
