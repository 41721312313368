import React, { Component } from "react";
import Flex from "../../../../compartido/components/flex/Flex";
import Box from "../../../../compartido/components/box/Box";
import { Button, ListGroup } from "react-bootstrap";



import { CANALES_WEB_SOCKET, MaquinaServicioSimbolicaGet, MaquinaServicioSimbolicaPost, MaquinaServicioSimbolicaService, ProductoGet, ProductoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  maquinaServicioSimbolicaId: number;
  ocultar: Function;
}

interface State {
  maquinaServicioSimbolica: MaquinaServicioSimbolicaGet;
  anchoBox: string;
}

export default class MaquinaServicioSimbolicaVerDetalle extends Component<
  Props,
  State
> {

  private maquinaServicioSimbolicaService:MaquinaServicioSimbolicaService;

  constructor(props: Props) {
    super(props);

    this.state = {
      maquinaServicioSimbolica: this.crearMaquinaServicioSimbolicaVacio(),
      anchoBox: window.innerWidth < 750 ? "90%" : "50%",
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_SIMBOLICA,
      funcionCallback: this.cargarMaquinaServicioSimbolicaCanal,
    });

    this.maquinaServicioSimbolicaService = new MaquinaServicioSimbolicaService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 750) {
      this.setState({ anchoBox: "90%" });
    } else {
      this.setState({ anchoBox: "50%" });
    }
  };

  componentDidMount() {
    this.cargarMaquinaServicioSimbolica(this.props.maquinaServicioSimbolicaId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarMaquinaServicioSimbolica(
        this.props.maquinaServicioSimbolicaId
      );
    }
  }

  crearMaquinaServicioSimbolicaVacio(): MaquinaServicioSimbolicaGet {
    const maquinaServicioSimbolica: MaquinaServicioSimbolicaGet = {
      id: 0,
      nombre: "",
      productos: [],
      proveedores: [],
    };
    return maquinaServicioSimbolica;
  }

  cargarMaquinaServicioSimbolicaCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.maquinaServicioSimbolica &&
      dato.message.maquinaServicioSimbolicaId ==
        this.state.maquinaServicioSimbolica.id
    ) {
      this.cargarMaquinaServicioSimbolica(
        this.state.maquinaServicioSimbolica.id
      );
    }
  };

  cargarMaquinaServicioSimbolica = async (id: number) => {
    const resMaquinaServicioSimbolica =
      await this.maquinaServicioSimbolicaService.get(id).catch((error) => {
        this.maquinaServicioSimbolicaService.manejarErrorHTTP(
          error,
          "Máquina Servicio Simbólica"
        );
        return null;
      });
    if (resMaquinaServicioSimbolica != null) {
      this.setState({
        maquinaServicioSimbolica: resMaquinaServicioSimbolica,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  render() {
    if (this.state.maquinaServicioSimbolica.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal={"10rem"} paddingVertical={"2rem"}>
            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h5>Nombre: {this.state.maquinaServicioSimbolica.nombre}</h5>
                </div>
              </Flex>
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>

            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h4>
                    Productos Asociados
                    {this.state.maquinaServicioSimbolica.productos.length == 0
                      ? " : No tiene"
                      : null}
                  </h4>
                </div>
              </Flex>

              {this.state.maquinaServicioSimbolica.productos.length > 0 ? (
                <Flex
                  container
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  paddingTop="10px"
                >
                  <ListGroup>
                    {this.state.maquinaServicioSimbolica.productos.map((x) => (
                      <ListGroup.Item style={{ textAlign: "center" }}>
                        {x.nombre}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Flex>
              ) : null}
            </Flex>

            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>

            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h4>
                    Proveedores Actuales
                    {this.state.maquinaServicioSimbolica.proveedores.length == 0
                      ? " : No tiene"
                      : null}
                  </h4>
                </div>
              </Flex>

              {this.state.maquinaServicioSimbolica.proveedores.length > 0 ? (
                <Flex
                  container
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  paddingTop="10px"
                >
                  <ListGroup>
                    {this.state.maquinaServicioSimbolica.proveedores.map(
                      (relacion) => (
                        <ListGroup.Item style={{ textAlign: "center" }}>
                          {relacion.proveedor.nombre +
                            " - Cantidad mínima que vende: " +
                            relacion.cantidad_minima +
                            " - A: " +
                            relacion.precio +
                            "$"}
                          <Button
                            style={{
                              marginLeft: "1rem",
                            }}
                          >
                            Ver Historial
                          </Button>
                        </ListGroup.Item>
                      )
                    )}
                  </ListGroup>
                </Flex>
              ) : null}
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <button
                className={"btn btn-outline-secondary"}
                onClick={this.ocultar}
                style={{ width: "150px" }}
              >
                Salir
              </button>
            </Flex>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
