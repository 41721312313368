/*



.box {
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12)
  ;
  
    
    
    min-height: 200px;
    width: 50vw;
    margin: 100px auto;
    background: white;
    border-radius: 5px;
  }
*/

import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Flex from "../flex/Flex";
import { X } from "react-bootstrap-icons";

interface Props {
  width?: string;
  height?: string;
  paddingHorizontal?: string;
  paddingVertical?: string;
  padding?: string;
  showCloseBtn?: boolean;
}

interface State {}

export class Box extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          width: this.props.width || "100%",
          height: this.props.height || "100%",
          padding: this.props.padding || "0px",
          paddingRight: this.props.paddingHorizontal || "0px",
          paddingLeft: this.props.paddingHorizontal || "0px",
          paddingTop: this.props.paddingVertical || "0px",
          paddingBottom: this.props.paddingVertical || "0px",
          //boxShadow: "1px 3px 1px #9E9E9E,20px 2px 10px 5px #9E9ffE",
          boxShadow: "1px 1px 3px 1px #9E9E9E",
          //boxShadow:" 0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12) ",
          background: "white",
          borderRadius: "5px",
        }}
      >
        {this.props.showCloseBtn && (
          <Flex container justifyContent={"flex-end"}>
            <Button variant={"danger"}>
              <X color="white" />
            </Button>
          </Flex>
        )}
        {this.props.children}
      </div>
    );
  }
}
export default Box;
