import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { addMonths, endOfMonth, startOfMonth } from "date-fns/esm";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import FechaUtils from "../../../../../../../compartido/utils/FechaUtils";
import { startOfDay } from "date-fns";
import OrdenesCompraProductosLoad from "./ordenes-compra-productos/OrdenesCompraProductosLoad";
import MaquinasSimbolicasOrdenCompraLoad from "./ordenes-compra-maquinas/MaquinasSimbolicasOrdenCompraLoad";
import { OrdenCompraMaquinaSimbolica, OrdenCompraProducto,OrdenCompra, } from "serviciossaintmichel";

export enum Inputs {
  FechaInicio = "FechaInicio",
  FechaFin = "FechaFin",
  FechaFinEfectiva = "FechaFinEfectiva",
}

export interface Props {
  ordenCompra: OrdenCompra | null;
  clienteId: number;
  onChange: Function;
  onValidationChange: Function;
  fechaInicioMin?: Date;
  fechaFinMax?: Date;
  fechaFinMin?: Date;
  inputsDeshabilitados?: Array<Inputs>;
}

interface State {
  ordenCompra: OrdenCompra;
  ordenCompraValida: boolean;
}

export default class OrdenCompraLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const initialValidation = !!props.ordenCompra;

    this.state = {
      ordenCompra: props.ordenCompra || this.crearOrdenCompra(),
      ordenCompraValida: initialValidation,
    };
  }

  crearOrdenCompra = (): OrdenCompra => {
    const { fechaInicioMin, clienteId } = this.props;
    let fechaInicio = fechaInicioMin;

    if (!fechaInicioMin) {
      const mesProximo = addMonths(new Date(), 1);
      fechaInicio = startOfMonth(mesProximo);
    } else {
      fechaInicio = fechaInicioMin;
    }

    let fechaFin = endOfMonth(fechaInicio);
    fechaFin = startOfDay(fechaFin);

    return {
      id: -1,
      cliente: clienteId,
      fecha_inicio: FechaUtils.normalizarFecha(fechaInicio),
      fecha_fin: FechaUtils.normalizarFecha(fechaFin),
      fecha_fin_efectiva: null,
      estado: "EN_PROCESO",
      tiempo: "",
      productos: [],
      maquinas_simbolicas: [],
    };
  };

  componentDidMount = () => {
    this.validar();
    this.props.onChange(this.state.ordenCompra);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { ordenCompra, ordenCompraValida } = this.state;
    const { fechaInicioMin } = this.props;

    if (JSON.stringify(prevState.ordenCompra) !== JSON.stringify(ordenCompra)) {
      this.props.onChange(ordenCompra);
      this.validar();
    }
    if (prevState.ordenCompraValida !== ordenCompraValida) {
      this.props.onValidationChange(ordenCompraValida);
    }
    if (prevProps.fechaInicioMin !== fechaInicioMin && fechaInicioMin) {
      this.setFechaInicio(fechaInicioMin);
    }
    if (
      this.props.ordenCompra &&
      JSON.stringify(prevProps.ordenCompra) !==
        JSON.stringify(this.props.ordenCompra)
    ) {
      this.setState({ ordenCompra: this.props.ordenCompra });
    }
  };

  validar = () => {
    const { ordenCompra } = this.state;
    let valido =
      FechaUtils.normalizarFecha(ordenCompra.fecha_inicio) <
      FechaUtils.normalizarFecha(ordenCompra.fecha_fin);

    valido = valido && ordenCompra.productos.length > 0;
    valido = valido && ordenCompra.maquinas_simbolicas.length > 0;

    if (ordenCompra.fecha_fin_efectiva) {
      valido =
        valido &&
        FechaUtils.normalizarFecha(ordenCompra.fecha_fin_efectiva) <=
          FechaUtils.normalizarFecha(ordenCompra.fecha_fin) &&
        FechaUtils.normalizarFecha(ordenCompra.fecha_fin_efectiva) >=
          FechaUtils.normalizarFecha(ordenCompra.fecha_inicio);
    }

    this.setState({ ordenCompraValida: valido });
  };

  setFechaInicio = (fecha: Date) => {
    const ordenCompra: OrdenCompra = {
      ...this.state.ordenCompra,
      fecha_inicio: FechaUtils.normalizarFecha(fecha),
    };
    this.setState({ ordenCompra: ordenCompra });
  };

  setFechaFin = (fecha: Date) => {
    const ordenCompra: OrdenCompra = {
      ...this.state.ordenCompra,
      fecha_fin: FechaUtils.normalizarFecha(fecha),
    };
    this.setState({ ordenCompra: ordenCompra });
  };

  setFechaFinEfectiva = (fecha: Date) => {
    const ordenCompra: OrdenCompra = {
      ...this.state.ordenCompra,
      fecha_fin_efectiva: FechaUtils.normalizarFecha(fecha),
    };
    this.setState({ ordenCompra: ordenCompra });
  };

  setOrdenesCompraProductos = (
    ordenesCompraProductos: Array<OrdenCompraProducto>
  ) => {
    const ordenCompra = {
      ...this.state.ordenCompra,
      productos: ordenesCompraProductos,
    };
    this.setState({ ordenCompra: ordenCompra });
  };

  setMaquinasOrdenCompra = (
    maquinasOrdenCompra: Array<OrdenCompraMaquinaSimbolica>
  ) => {
    const ordenCompra = {
      ...this.state.ordenCompra,
      maquinas_simbolicas: maquinasOrdenCompra,
    };
    this.setState({ ordenCompra: ordenCompra });
  };

  getFechasInicioPermitidas = (fechaInicial: Date, años: number) => {
    let fechasPermitidas = [];
    let fechaObjetivo = fechaInicial;

    for (let i = 1; i <= 12 * años; i++) {
      fechaObjetivo = addMonths(fechaObjetivo, 1);
      fechasPermitidas.push(startOfMonth(fechaObjetivo));
    }
    return fechasPermitidas;
  };

  getFechasFinPermitidas = (fechaInicial: Date, años: number) => {
    let fechasPermitidas = [];
    let fechaObjetivo = fechaInicial;

    for (let i = 1; i <= 12 * años; i++) {
      fechasPermitidas.push(endOfMonth(fechaObjetivo));
      fechaObjetivo = addMonths(fechaObjetivo, 1);
    }
    return fechasPermitidas;
  };

  render = () => {
    const today = new Date();
    const { ordenCompra } = this.state;
    const {
      fechaInicioMin = today,
      fechaFinMax,
      inputsDeshabilitados = [],
    } = this.props;
    const fechaInicio = FechaUtils.normalizarFecha(ordenCompra.fecha_inicio);
    let fechaFin = ordenCompra.fecha_fin;
    if (fechaFin) {
      fechaFin = FechaUtils.normalizarFecha(fechaFin);
    }

    let fechaFinEfectiva = ordenCompra.fecha_fin_efectiva;
    if (fechaFinEfectiva) {
      fechaFinEfectiva = FechaUtils.normalizarFecha(fechaFinEfectiva);
    }
    const fechasInicioPermitidas = this.getFechasInicioPermitidas(today, 2);
    const fechasFinPermitidas = this.getFechasFinPermitidas(fechaInicioMin, 2);

    return (
      <>
        <Flex container flexDirection="column" marginBottom="1rem">
          <span>Fecha de inicio</span>
          <DatePicker
            selected={fechaInicio}
            onChange={this.setFechaInicio}
            dateFormat="dd/MM/yyyy"
            minDate={fechaInicioMin}
            includeDates={fechasInicioPermitidas}
            disabled={inputsDeshabilitados.indexOf(Inputs.FechaInicio) > -1}
          />
        </Flex>
        <Flex container flexDirection="column" marginBottom="1rem">
          <span>Fecha de finalización</span>
          <DatePicker
            selected={fechaFin}
            onChange={this.setFechaFin}
            dateFormat="dd/MM/yyyy"
            includeDates={fechasFinPermitidas}
            maxDate={fechaFinMax}
            disabled={inputsDeshabilitados.indexOf(Inputs.FechaFin) > -1}
          />
        </Flex>
        <Flex container flexDirection="column">
          <span>Fecha de finalización efectiva</span>
          <DatePicker
            selected={fechaFinEfectiva}
            onChange={this.setFechaFinEfectiva}
            dateFormat="dd/MM/yyyy"
            maxDate={fechaFinMax}
            minDate={fechaInicio}
            isClearable={true}
            disabled={
              inputsDeshabilitados.indexOf(Inputs.FechaFinEfectiva) > -1
            }
          />
        </Flex>
        <Flex container flexDirection="column" marginTop="1rem">
          <OrdenesCompraProductosLoad
            ordenCompraId={ordenCompra.id}
            ordenesCompraProductos={ordenCompra.productos}
            onChange={this.setOrdenesCompraProductos}
            onValidationChange={() => this.validar()}
          />
        </Flex>
        <Flex container flexDirection="column" marginTop="1rem">
          <MaquinasSimbolicasOrdenCompraLoad
            ordenCompraId={ordenCompra.id}
            maquinasOrdenCompra={ordenCompra.maquinas_simbolicas}
            onChange={this.setMaquinasOrdenCompra}
            onValidationChange={() => this.validar()}
          />
        </Flex>
      </>
    );
  };
}
