import React, { Component } from "react";
import Ruta from "../../compartido/models/Ruta";
import { NavLink } from "react-router-dom";
import "./menu.css";

import "../template.css";
import { Navbar, Nav,NavDropdown } from "react-bootstrap";
import Flex from "../../libreria/appearance/flex/Flex";



export interface Props {
  links:Array<Ruta>;
  nombre:string
}


interface State {
  color:string
}

export default class MenuItemDropdown extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {color:"blue"};
  }
  
  
  render() {
    return (
      <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="55px"
      marginLeft="5px">
        {/*
        <NavDropdown  onClick={()=>this.setState({color:"red"})} style={{background:this.state.color,height:"100%",textAlign:"center",border: "1px solid red",padding:"5px"}} renderMenuOnMount={true} title={
          this.props.nombre
          }  id="basic-nav-dropdown">
                {this.props.links.map((ruta) => <NavDropdown.Item  href={ruta.path}>{ruta.name}</NavDropdown.Item> )}
              </NavDropdown>
            */ }
             
          <li className="nav-item dropdown" data-hover="dropdown" style={{height:"55px",paddingTop:5}}>
                <a   style={{color: "#204399" ,fontSize:20 }} className="nav-link dropdown-toggle font" href="#" id="navbardrop" data-toggle="dropdown" >
                  {this.props.nombre}
                </a>
                <div className="dropdown-menu">
                {this.props.links.map((ruta) => 
                <a  className="dropdown-item font" href="#"><NavLink style={{textDecoration:"none",color: "#204399" ,fontSize:"19px" }} to={ruta.path}>{ruta.name}</NavLink></a>
                )}

                </div>
         </li>






      </Flex>
     
    )
  }


}

