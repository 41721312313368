import React, { Component } from "react";
import Text from "../../../../../../libreria/message/text/Text";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../../libreria/appearance/margin/Margin";
import LocalidadShow from "../../../../../../compartido/components/localidad/LocalidadShow";
import { CANALES_WEB_SOCKET, Factura, FacturaService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";




export interface Props {
  idFactura: number;
}

interface State {
  factura: Factura | null;
  cargando: boolean;
  visible: boolean;
}

export default class FacturaPedidoShow extends Component<Props, State> {

  private facturaService:FacturaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      factura: null,
      cargando: true,
      visible: false,
    };

    this.facturaService =  new FacturaService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    await this.cargarFactura();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_FACTURAS,
      funcionCallback: this.onChannelMesagge,
    });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.facturaId == this.props.idFactura) {
      await this.cargarFactura();
    }
  };

  cargarFactura = async () => {
    let factura = await this.facturaService.get(this.props.idFactura).catch(
      (error) => {
        this.facturaService.manejarErrorHTTP(error, "Factura");
        return null;
      }
    );
    this.setState({ factura: factura, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      await this.setState({ cargando: true });
      await this.cargarFactura();
    }
  };

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  renderFactura = () =>
    this.state.factura ? (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="flex-start"
        justifyContent="center"
        marginTop="10px"
      >
        <Text>
          <b>Tipo</b>: {this.state.factura.tipo}
        </Text>
        <Text>
          <b>Fecha de Emisión</b>: {this.state.factura.fecha_emision}
        </Text>
        <Text>
          <b>Número de factura</b>: {this.state.factura.numero_factura}
        </Text>
        <Text>
          <b>Calle</b>: {this.state.factura.calle} N° {this.state.factura.calle}
        </Text>
        <LocalidadShow
          localidad={this.state.factura.localidad}
          soloCampoText={true}
        />
        <Text>
          <b>Cuit</b>: {this.state.factura.cuit}
        </Text>
        <Text>
          <b>Condición IVA</b>: {this.state.factura.condicion_iva.tipo}
        </Text>
        <Text>
          <b>Razón Social</b>: {this.state.factura.razon_social}
        </Text>
        <Margin top="30px" />
      </Flex>
    ) : null;
  renderLoading = () => "Cargando Datos";

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.cargando ? this.renderLoading() : this.renderFactura()}
      </Flex>
    );
  }
}
