import React, { Component } from "react";
import { Marker } from "@react-google-maps/api";
/* global google */
import { WEB_SOCKET_URL } from "serviciossaintmichel";

const options = {
  fillColor: "lightblue",
  fillOpacity: 0.8,
  strokeColor: "blue",
  strokeOpacity: 0.8,
  zIndex: 1,
};

interface Props {
  id: number;
}

interface State {
  lat: number;
  lng: number;
}

class Marcador extends Component<Props, State> {
  canal: string;
  localizacionSocket: WebSocket;

  constructor(props: Props) {
    super(props);

    //this.state={modo:"polygon",poligonos:{},estado:false}
    this.state = {
      lat: -34.922185,
      lng: -57.957313,
    };
    this.canal = "/ws/canales/localizaciones/id/" + this.props.id + "/";
    this.localizacionSocket = new WebSocket(WEB_SOCKET_URL + this.canal);
    this.localizacionSocket.onmessage = this.nuevaPosicion;
  }
  nuevaPosicion = (e: MessageEvent) => {
    //alert(e.data);
    var dato = JSON.parse(e.data);
    this.setState({ lat: dato.message.lat, lng: dato.message.lng });
    //alert(dato.message.lat);
  };

  componentDidMount() {}

  render() {
    return (
      <Marker
        //icon={this.state.icono}
        //icon={this.getIcon()}

        position={{ lat: this.state.lat, lng: this.state.lng }}
      />
    );
  }
}
export default Marcador;
