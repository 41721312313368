import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Flex from "../../libreria/appearance/flex/Flex";
import { getRutasAccesibles } from "../rutasSidebar";
import Menu from "./Menu";

interface Props 
{

}

interface State {

}

export default class TestMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
     
    };

  }



  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        
      >
        <Menu rutas = {getRutasAccesibles()}/>

      </Flex>
    );
  }
}
