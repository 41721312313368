import React, { Component } from "react";
import Flex from "../../../../compartido/components/flex/Flex";
import Box from "../../../../compartido/components/box/Box";
import { Button, ListGroup } from "react-bootstrap";


import { CANALES_WEB_SOCKET, TipoCombustibleGet, TipoCombustibleService, TipoVehiculoGet, TipoVehiculoService, VehiculoGet, VehiculoPost, VehiculoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  vehiculoId: number;
  ocultar: Function;
}

interface State {
  vehiculo: VehiculoGet;
  anchoBox: string;
}

export default class VehiculoVerDetalle extends Component<Props, State> {

  private vehiculoService:VehiculoService;

  constructor(props: Props) {
    super(props);

    this.state = {
      vehiculo: this.crearVehiculoVacio(),
      anchoBox: window.innerWidth < 750 ? "90%" : "50%",
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_VEHICULOS,
      funcionCallback: this.cargarVehiculoCanal,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_VEHICULOS,
      funcionCallback: this.cargarVehiculoCanal,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_COMBUSTIBLES,
      funcionCallback: this.cargarVehiculoCanal,
    });

    this.vehiculoService = new VehiculoService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 750) {
      this.setState({ anchoBox: "90%" });
    } else {
      this.setState({ anchoBox: "50%" });
    }
  };

  componentDidMount() {
    this.cargarVehiculo(this.props.vehiculoId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarVehiculo(this.props.vehiculoId);
    }
  }

  crearVehiculoVacio(): VehiculoGet {
    const tipoCombustible: TipoCombustibleGet = {
      id: 0,
      tipo: "",
    };
    const tipoVehiculo: TipoVehiculoGet = {
      id: 0,
      tipo: "",
    };
    const vehiculo: VehiculoGet = {
      id: 0,
      patente: "",
      nombre: "",
      marca: "",
      tipo_combustible: tipoCombustible,
      tipo_vehiculo: tipoVehiculo,
      descripcion: "",
      capacidad_carga: 0,
      proveedores: [],
    };
    return vehiculo;
  }

  cargarVehiculoCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.vehiculo &&
      dato.message.vehiculoId == this.state.vehiculo.id
    ) {
      this.cargarVehiculo(this.state.vehiculo.id);
    }
    if (
      this.state.vehiculo &&
      dato.message.tipoVehiculoId == this.state.vehiculo.tipo_vehiculo.id
    ) {
      this.cargarVehiculo(this.state.vehiculo.id);
    }
    if (
      this.state.vehiculo &&
      dato.message.tipoCombustibleId == this.state.vehiculo.tipo_combustible.id
    ) {
      this.cargarVehiculo(this.state.vehiculo.id);
    }
  };

  cargarVehiculo = async (id: number) => {
    const resVehiculo = await this.vehiculoService.get(id).catch((error) => {
      this.vehiculoService.manejarErrorHTTP(error, "Vehículo");
      return null;
    });
    if (resVehiculo != null) {
      this.setState({
        vehiculo: resVehiculo,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  render() {
    if (this.state.vehiculo.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal={"10rem"} paddingVertical={"2rem"}>
            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h5>Patente: {this.state.vehiculo.patente}</h5>
                  <h5>Nombre: {this.state.vehiculo.nombre}</h5>
                  <h5>Tipo: {this.state.vehiculo.tipo_vehiculo.tipo}</h5>
                  <h5>
                    Combustible: {this.state.vehiculo.tipo_combustible.tipo}
                  </h5>
                  <h5>Marca: {this.state.vehiculo.marca}</h5>
                  <h5>
                    Capacidad de carga: {this.state.vehiculo.capacidad_carga}
                  </h5>
                  <h5>Descripcion: {this.state.vehiculo.descripcion}</h5>
                </div>
              </Flex>
            </Flex>

            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>

            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h4>
                    Proveedores Actuales
                    {this.state.vehiculo.proveedores.length == 0 ? (
                      " : No tiene"
                    ) : (
                      <Flex
                        container
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        paddingTop="10px"
                      >
                        <ListGroup>
                          {this.state.vehiculo.proveedores.map((relacion) => (
                            <ListGroup.Item style={{ textAlign: "center" }}>
                              {relacion.proveedor.nombre +
                                " - Cantidad mínima que vende: " +
                                relacion.cantidad_minima +
                                " - A: " +
                                relacion.precio +
                                "$"}
                              <Button
                                style={{
                                  marginLeft: "1rem",
                                }}
                              >
                                Ver Historial
                              </Button>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Flex>
                    )}
                  </h4>
                </div>
              </Flex>
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <button
                className={"btn btn-outline-secondary"}
                onClick={this.ocultar}
                style={{ width: "150px" }}
              >
                Salir
              </button>
            </Flex>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
