import React, { Component } from "react";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Visibilizador from "../../../../../../libreria/action/visibilizador/Visibilizador";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Text from "../../../../../../libreria/message/text/Text";


import { AlquilerGet, AlquilerService, CANALES_WEB_SOCKET,  MaquinaSimbolicaAlquiler, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";




export interface Props {
  id: number;
}
interface State {
  alquiler: AlquilerGet | null;
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
}

export default class DatosAlquilerShow extends Component<Props, State> {

  private alquilerService:AlquilerService;

  constructor(props: Props) {
    super(props);
    this.state = {
      alquiler: null,
      cargando: true,
      visible: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES,
      funcionCallback: this.onChannelMesagge,
    });

    this.alquilerService =  new AlquilerService(Global.UsuarioService.getToken()!);

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {
    const alquiler = await this.alquilerService.get(this.props.id);
    this.setState({ alquiler: alquiler, cargando: false });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    const alquiler = await this.alquilerService.get(this.props.id);
    this.setState({ alquiler: alquiler, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({ cargando: true });
      const alquiler = await this.alquilerService.get(this.props.id);
      this.setState({ alquiler: alquiler, cargando: false });
    }
  };

  renderDatos = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      <Text>Nombre:{this.state.alquiler!.nombre}</Text>
    </Flex>
  );

  renderLoading = () => "Cargando Datos";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="20px"
      >
        <Visibilizador
          onChange={this.onChangeVisibilizador}
          label={"Datos"}
          labelAlignment={"flex-start"}
        >
          {this.state.cargando ? this.renderLoading() : this.renderDatos()}
        </Visibilizador>
      </Flex>
    );
  }
}
