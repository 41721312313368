import React, { Component } from "react";
import { ConsumoGet } from "serviciossaintmichel";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Visibilizador from "../../../../../../libreria/action/visibilizador/Visibilizador";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../../libreria/containers/box/Box";
import H from "../../../../../../libreria/message/title/H";
import MaquinaConsumoNew from "./MaquinaConsumoNew";
import MaquinasConsumoUpdate from "./MaquinasConsumoUpdate";

export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  consumo: ConsumoGet;
}
interface State {
  screen: ScreenType;
  consumo: ConsumoGet;
}

export default class MaquinasConsumo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      consumo: this.props.consumo,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({ consumo: this.props.consumo });
    }
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="70%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={3} texto={"Maquinas"} />
        </Flex>

        {this.state.consumo.en_uso ? (
          <Flex
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <H
              size={5}
              texto={
                "No es posible modificar las maquinas del consumo porque el mismo está en uso."
              }
            />
          </Flex>
        ) : (
          <Flex
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Flex
              flexDirection="column"
              width="100%"
              alignItems="center"
              justifyContent="center"
              marginTop="20px"
              marginBottom="20px"
            >
              <Visibilizador label="Nueva Maquina" labelAlignment="center">
                <MaquinaConsumoNew consumo={this.state.consumo} />
              </Visibilizador>
            </Flex>
            <Flex
              flexDirection="column"
              width="100%"
              alignItems="center"
              justifyContent="center"
              marginTop="40px"
            >
              <MaquinasConsumoUpdate consumo={this.state.consumo} />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
