import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import TipoProductos from "./TipoProducto/components/TipoProductos";
import Productos from "./Producto/components/Productos";
import BultoProductos from "./BultoProducto/components/BultoProductos";
import Ruta from "./../../compartido/models/Ruta";
import { Global } from "../../Global";


export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];

  if (
    Global.UsuarioService.getPermisos().Elementos.Productos.TipoProducto.hasAccess()
  ) {
    aux.push({
      name: "Tipo Productos",
      path: SeccionProductos.pathBase + "/tipo-productos",
      component: TipoProductos,
    });
  }
  if (Global.UsuarioService.getPermisos().Elementos.Productos.Producto.hasAccess()) {
    aux.push({
      name: "Productos",
      path: SeccionProductos.pathBase + "/productos",
      component: Productos,
    });
  }
  if (
    Global.UsuarioService.getPermisos().Elementos.Productos.BultoProducto.hasAccess()
  ) {
    aux.push({
      name: "Bulto Productos",
      path: SeccionProductos.pathBase + "/bulto-productos",
      component: BultoProductos,
    });
  }
  return aux;
}

export default class SeccionProductos extends Component<Props> {
  static pathBase = "/elementos/seccion-productos";
  /* 
  static rutas: Array<Ruta> = [
    {
      name: "Tipo Productos",
      path: SeccionProductos.pathBase + "/tipo-productos",
      component: TipoProductos,
    },
    {
      name: "Productos",
      path: SeccionProductos.pathBase + "/productos",
      component: Productos,
    },
  ]; */

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Productos"}
      />
    );
  }
}
