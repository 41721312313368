import React, { Component } from "react";
import { Alert, Button, ButtonProps } from "react-bootstrap";

export interface Props {
  propsBoton?: ButtonProps;
}

interface State {
  mensajeImprimir: string | null;
}

export default class AlertaError extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      mensajeImprimir: null,
    };
    PubSub.subscribe("error_http", this.colocarMensaje);
  }

  colocarMensaje = (canalNombre: string, data: string) => {
    this.setState({ mensajeImprimir: data });
  };

  limpiarMensaje = () => {
    this.setState({ mensajeImprimir: null });
  };

  render() {
    if (this.state.mensajeImprimir != null) {
      return (
        <Alert variant="danger" onClose={() => this.limpiarMensaje()} dismissible>
          <Alert.Heading>Error</Alert.Heading>
          {this.state.mensajeImprimir.split("\n").map((linea) => (
            <p style={{ color: "black" }}>{linea}</p>
          ))}
        </Alert>
      );
    } else {
      return null;
    }
  }
}
