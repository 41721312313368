import React, { Component } from "react";
import DatePicker from "react-datepicker";
import {
  RolEmpleado,
  Reparto,
  RepartoDiario,
  RepartoService,
  RolEmpleadoReparto,
  VehiculoReparto,
  TipoReparto,
  TIPOS_REPARTO,
} from "serviciossaintmichel";
import { getDateFromTimeString, getTimeStringFromDate } from "../../../../compartido/utils/Time";
import { Global } from "../../../../Global";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import RepartosShow, { Version } from "../../../repartos/components/show/RepartosShow";
import TipoRepartoRadio from "../../../repartos/components/TipoRepartoRadio";
import RolesEmpleadosLoad from "./RolesEmpleadosLoad";
import VehiculosRepartoDiarioLoad from "./VehiculosRepartoDiarioLoad";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  repartoDiario: RepartoDiario;
  inputsOcultos?: Array<string>;
  inputsSoloLectura?: Array<string>;
  sinRealizacionesEnFecha?: boolean;
}

interface State {
  repartoDiario: RepartoDiario;
  validation: boolean;
  tipoReparto: TipoReparto;
  repartoSeleccionado: Reparto | null;
}

export default class RepartoDiarioLoad extends Component<Props, State> {
  private repartoService: RepartoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      repartoDiario: props.repartoDiario,
      validation: false,
      repartoSeleccionado: null,
      tipoReparto: TIPOS_REPARTO.DOMICILIO,
    };

    this.repartoService = new RepartoService(Global.UsuarioService.getToken()!);
  }

  componentDidMount = async () => {
    if (this.state.repartoDiario.reparto > 0) {
      const reparto = await this.repartoService.get(this.state.repartoDiario.reparto);
      this.setState({ repartoSeleccionado: reparto });
    }
    this.updateValidation(this.state.repartoDiario);
  };

  setEmpleadosYVehiculosRegulares = async () => {
    const repartoId = this.state.repartoDiario.reparto;
    try {
      const [vehiculosRegulares, empleadosRegulares] = await Promise.all([
        this.repartoService.getVehiculosRegulares(repartoId),
        this.repartoService.getEmpleadosRegulares(repartoId),
      ]);
      const vehiculos = vehiculosRegulares.map((vr: VehiculoReparto) => vr.vehiculo);
      const rolEmpleados = empleadosRegulares.map((er: RolEmpleadoReparto) => {
        return { id: -1, reparto_diario: -1, empleado: er.empleado, rol: er.rol };
      });
      this.setState((prevState) => ({
        repartoDiario: { ...prevState.repartoDiario, roles_empleados: rolEmpleados, vehiculos: vehiculos },
      }));
    } catch (error) {
      this.repartoService.manejarErrorHTTP(error, "Repartos");
    }
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    if (prevProps !== this.props) {
      this.setState({ repartoDiario: this.props.repartoDiario });
    }
    if (prevState.validation !== this.state.validation) {
      this.props.onValidationChange(this.state.validation);
    }
    if (prevState.repartoDiario !== this.state.repartoDiario) {
      this.updateValidation(this.state.repartoDiario);
      this.props.onChange(this.state.repartoDiario);
    }
    if (this.state.repartoDiario.reparto > 0 && prevState.repartoDiario.reparto !== this.state.repartoDiario.reparto) {
      this.setEmpleadosYVehiculosRegulares();
    }
  };

  isValid = (repartoDiario: RepartoDiario) => {
    return (
      repartoDiario.reparto > 0 &&
      repartoDiario.fecha_realizacion !== null &&
      repartoDiario.roles_empleados.length > 0 &&
      repartoDiario.vehiculos.length > 0 &&
      repartoDiario.viaje_numero > -1
    );
  };

  updateValidation = (repartoDiario: RepartoDiario) => {
    const validation = this.isValid(repartoDiario);
    this.setState({ validation: validation });
  };

  setFechaRealizacion = (fecha: Date) => {
    this.setState((prevState) => ({ repartoDiario: { ...prevState.repartoDiario, fecha_realizacion: fecha } }));
  };

  setDescripcion = (descripcion: string) => {
    this.setState((prevState) => ({ repartoDiario: { ...prevState.repartoDiario, descripcion: descripcion } }));
  };

  setReparto = async (reparto: Reparto | null) => {
    this.setState((prevState) => ({
      repartoDiario: {
        ...prevState.repartoDiario,
        reparto: reparto ? reparto.id : -1,
      },
      repartoSeleccionado: reparto,
    }));
  };

  setTipoReparto = async (tipo: TipoReparto) => {
    this.setState((prevState) => ({
      tipoReparto: tipo,
    }));
    this.setReparto(null);
  };

  addVehiculo = (vehiculoId: number) => {
    this.setState((prevState) => ({
      repartoDiario: { ...prevState.repartoDiario, vehiculos: [...prevState.repartoDiario.vehiculos, vehiculoId] },
    }));
  };

  setRolesEmpleados = (rolesEmpleados: Array<RolEmpleado>) => {
    this.setState((prevState) => ({
      repartoDiario: { ...prevState.repartoDiario, roles_empleados: [...rolesEmpleados] },
    }));
  };

  setVehiculos = (vehiculos: Array<number>) => {
    this.setState((prevState) => ({
      repartoDiario: { ...prevState.repartoDiario, vehiculos: [...vehiculos] },
    }));
  };

  setHoraSalida = (horaSalida: Date | null) => {
    const horaSalidaStr = getTimeStringFromDate(horaSalida);
    this.setState((prevState) => ({
      repartoDiario: { ...prevState.repartoDiario, hora_salida: horaSalidaStr },
    }));
  };

  setHoraLlegada = (horaLlegada: Date | null) => {
    const horaLlegadaStr = getTimeStringFromDate(horaLlegada);
    this.setState((prevState) => ({
      repartoDiario: { ...prevState.repartoDiario, hora_llegada: horaLlegadaStr },
    }));
  };

  filterHorarioSalida = (time: Date): boolean => {
    const selectedDate = new Date(time);
    const horaLlegada = getDateFromTimeString(this.state.repartoDiario.hora_llegada);

    if (horaLlegada === null) {
      return true;
    }

    return selectedDate.getTime() < horaLlegada.getTime();
  };

  filterHorarioLlegada = (time: Date): boolean => {
    const selectedDate = new Date(time);
    const horaSalida = getDateFromTimeString(this.state.repartoDiario.hora_salida);

    if (horaSalida === null) {
      return true;
    }

    return selectedDate.getTime() > horaSalida.getTime();
  };

  render() {
    const { repartoDiario, repartoSeleccionado, tipoReparto } = this.state;
    const { inputsOcultos = [], inputsSoloLectura = [], sinRealizacionesEnFecha } = this.props;

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" gap="1rem">
        <Flex flexDirection="row" alignItems="center">
          <strong style={{ width: "100%" }}>Fecha de realización</strong>
          <DatePicker
            placeholderText="Ingrese fecha del reparto diario"
            onChange={(fecha: Date) => {
              this.setFechaRealizacion(fecha);
              this.setReparto(null); // forzar a una nueva seleccion del reparto
            }}
            selected={repartoDiario.fecha_realizacion}
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            disabled={inputsSoloLectura.indexOf("fechaRealizacion") !== -1}
          />
        </Flex>

        <Flex flexDirection="row" alignItems="center">
          <TipoRepartoRadio tipoRepartoSeleccionado={tipoReparto} onChange={this.setTipoReparto} />
        </Flex>

        <Flex flexDirection="column">
          <strong>Reparto</strong>
          {repartoDiario.fecha_realizacion ? (
            <RepartosShow
              version={Version.SELECT}
              propsSelect={{
                callbackParent: this.setReparto,
                seleccionado: repartoSeleccionado ? repartoSeleccionado.id : null,
              }}
              onSelect={this.setReparto}
              seleccionado={repartoDiario.reparto}
              fechaReparto={repartoDiario.fecha_realizacion}
              sinRealizacionesEnFecha={sinRealizacionesEnFecha}
              disabled={inputsSoloLectura.indexOf("reparto") !== -1}
              tipoReparto={tipoReparto}
            />
          ) : (
            <div>Seleccione una fecha de realizacion</div>
          )}
        </Flex>

        <Flex flexDirection="row" alignItems="center">
          <strong>Vehículos</strong>
          <VehiculosRepartoDiarioLoad onChange={this.setVehiculos} vehiculos={repartoDiario.vehiculos} />
        </Flex>

        <Flex flexDirection="row" alignItems="center">
          <strong>Empleados</strong>
          <RolesEmpleadosLoad onChange={this.setRolesEmpleados} rolesEmpleados={repartoDiario.roles_empleados} />
        </Flex>

        {inputsOcultos.indexOf("horaSalida") === -1 && (
          <Flex flexDirection="row" justifyContent="space-around">
            <strong>Hora de salida</strong>
            <DatePicker
              selected={getDateFromTimeString(repartoDiario.hora_salida)}
              onChange={(date: Date) => this.setHoraSalida(date)}
              showTimeSelect
              showTimeSelectOnly
              isClearable
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="H:mm"
              filterTime={this.filterHorarioSalida}
            />
          </Flex>
        )}

        {inputsOcultos.indexOf("horaLlegada") === -1 && (
          <Flex flexDirection="row" justifyContent="space-around">
            <strong>Hora de llegada</strong>
            <DatePicker
              selected={getDateFromTimeString(repartoDiario.hora_llegada)}
              onChange={(date: Date) => this.setHoraLlegada(date)}
              showTimeSelect
              showTimeSelectOnly
              isClearable
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="H:mm"
              filterTime={this.filterHorarioLlegada}
            />
          </Flex>
        )}

        <Flex flexDirection="row" alignItems="center">
          <strong>Descripción</strong>
          <Input
            data={repartoDiario.descripcion}
            placeholder={"Descripción"}
            onChange={this.setDescripcion}
            type={InputTypes.Text}
          />
        </Flex>
      </Flex>
    );
  }
}
