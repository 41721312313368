import { Divider, List } from "@mantine/core";
import React, { Component } from "react";
import { CANALES_WEB_SOCKET, RepartoDiarioService, VisitaLectura, WebSocketService } from "serviciossaintmichel";
import ManejadorErroresHTTP from "../../../../compartido/utils/ManejadorErroresHTTP";
import { Global } from "../../../../Global";

export interface Props {
  visitaId: number;
  repartoDiarioId: number;
}

interface State {
  visita: VisitaLectura | null;
}

export default class ResumenVisitaRepartoDiario extends Component<Props, State> {
  private webSockets: Array<WebSocket>;
  private repartoDiarioService: RepartoDiarioService;

  constructor(props: Props) {
    super(props);
    this.state = {
      visita: null,
    };
    this.webSockets = [];

    this.repartoDiarioService = new RepartoDiarioService(Global.UsuarioService.getToken()!);
  }

  componentDidMount = async () => {
    this.setVisita();
    this.webSockets.push(
      WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
        funcionCallback: this.onChannelMessage,
      })
    );
  };

  onChannelMessage = (msgEvent: MessageEvent) => {
    let message = JSON.parse(msgEvent.data).message;
    const { visitaId } = this.props;

    if (message.visitaId === visitaId) {
      this.setVisita();
    }
  };

  setVisita = async () => {
    try {
      const visita = await this.repartoDiarioService.getVisita(this.props.repartoDiarioId, this.props.visitaId);
      this.setState({ visita: visita });
    } catch (error) {
      ManejadorErroresHTTP.evaluarError(error, "Visita");
    }
  };

  componentWillUnmount = () => {
    this.webSockets.forEach((ws: WebSocket) => ws.close());
  };

  renderInfoVenta = (visita: VisitaLectura) => {
    const { venta } = visita;

    if (venta === null) {
      return <div>No se registro venta</div>;
    }

    return (
      <>
        <div>Productos</div>
        {venta.productos_vendidos.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {venta.productos_vendidos.map((ventaProducto) => (
              <List.Item>
                {ventaProducto.producto.nombre}: {ventaProducto.vendidos} vendidos - {ventaProducto.bonificados}{" "}
                bonificados - {ventaProducto.vacios} vacíos
              </List.Item>
            ))}
          </List>
        )}

        <div>Bultos de producto</div>
        {venta.bultos_producto_vendidos.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {venta.bultos_producto_vendidos.map((ventaBultoProducto) => (
              <List.Item>
                {ventaBultoProducto.bulto_producto.nombre}: {ventaBultoProducto.vendidos} vendidos -{" "}
                {ventaBultoProducto.bonificados} bonificados
              </List.Item>
            ))}
          </List>
        )}

        <div>Combos retornables</div>
        {venta.combos_retornables_vendidos.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {venta.combos_retornables_vendidos.map((ventaComboRetornable) => (
              <List.Item>
                {ventaComboRetornable.combo_retornable.nombre}: {ventaComboRetornable.cantidad} vendidos
              </List.Item>
            ))}
          </List>
        )}

        <div>Promociones</div>
        {venta.promociones_vendidas.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {venta.promociones_vendidas.map((ventaPromocion) => (
              <List.Item>
                {ventaPromocion.promocion.nombre}: {ventaPromocion.cantidad} vendidas
              </List.Item>
            ))}
          </List>
        )}

        <div>Máquinas</div>
        {venta.maquinas_vendidas.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {venta.maquinas_vendidas.map((ventaMaquina) => (
              <List.Item>
                {ventaMaquina.maquina_simbolica.nombre}: {ventaMaquina.vendidas} vendidas - {ventaMaquina.bonificadas}{" "}
                bonificadas
              </List.Item>
            ))}
          </List>
        )}
      </>
    );
  };

  renderInfoEntrega = (visita: VisitaLectura) => {
    const { entrega } = visita;

    if (entrega === null) {
      return <div>No se registro entrega</div>;
    }

    return (
      <>
        <div>Productos</div>
        {entrega.productos_entregados.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {entrega.productos_entregados.map((entregaProducto) => (
              <List.Item>
                {entregaProducto.producto.nombre}: {entregaProducto.entregados} entregados - {entregaProducto.vacios}{" "}
                vacíos
              </List.Item>
            ))}
          </List>
        )}

        <div>Bultos de producto</div>
        {entrega.bultos_producto_entregados.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {entrega.bultos_producto_entregados.map((entregaBultoProducto) => (
              <List.Item>
                {entregaBultoProducto.bulto_producto.nombre}: {entregaBultoProducto.cantidad} entregados
              </List.Item>
            ))}
          </List>
        )}

        <div>Combos retornables</div>
        {entrega.combos_retornables_entregados.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {entrega.combos_retornables_entregados.map((entregaComboRetornable) => (
              <List.Item>
                {entregaComboRetornable.combo_retornable.nombre}: {entregaComboRetornable.cantidad} entregados
              </List.Item>
            ))}
          </List>
        )}

        <div>Máquinas</div>
        {entrega.maquinas_entregadas.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {entrega.maquinas_entregadas.map((entregaMaquina) => (
              <List.Item>
                {entregaMaquina.maquina_simbolica.nombre}: {entregaMaquina.cantidad} entregadas
              </List.Item>
            ))}
          </List>
        )}
      </>
    );
  };

  renderInfoProgramacionEntrega = (visita: VisitaLectura) => {
    const { programacion_entrega } = visita;

    if (programacion_entrega === null) {
      return <div>No se registro programación de entrega</div>;
    }

    return (
      <>
        <div>Venta de Productos</div>
        {programacion_entrega.ventas_producto.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {programacion_entrega.ventas_producto.map(({ paquete }) => (
              <List.Item>
                {paquete.producto.nombre}: {paquete.cantidad} vendidos - {paquete.bonificados} bonificados
              </List.Item>
            ))}
          </List>
        )}

        <div>Venta de Bultos de producto</div>
        {programacion_entrega.ventas_bulto_producto.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {programacion_entrega.ventas_bulto_producto.map(({ paquete }) => (
              <List.Item>
                {paquete.bulto_producto.nombre}: {paquete.cantidad} vendidos - {paquete.bonificados} bonificados
              </List.Item>
            ))}
          </List>
        )}

        <div>Venta de Promociones</div>
        {programacion_entrega.ventas_promocion.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {programacion_entrega.ventas_promocion.map(({ paquete }) => (
              <List.Item>
                {paquete.promocion.nombre}: {paquete.cantidad} vendidas
              </List.Item>
            ))}
          </List>
        )}

        <div>Venta de Máquinas</div>
        {programacion_entrega.ventas_maquina.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {programacion_entrega.ventas_maquina.map(({ paquete }) => (
              <List.Item>
                {paquete.maquina.nombre}: {paquete.cantidad} vendidas
              </List.Item>
            ))}
          </List>
        )}

        <div>Entrega de Productos</div>
        {programacion_entrega.entregas_producto.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {programacion_entrega.entregas_producto.map(({ paquete }) => (
              <List.Item>
                {paquete.producto.nombre}: {paquete.cantidad} entregados
              </List.Item>
            ))}
          </List>
        )}

        <div>Entrega de Bultos de Producto</div>
        {programacion_entrega.entregas_bulto_producto.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {programacion_entrega.entregas_bulto_producto.map(({ paquete }) => (
              <List.Item>
                {paquete.bulto_producto.nombre}: {paquete.cantidad} entregados
              </List.Item>
            ))}
          </List>
        )}

        <div>Entrega de Máquinas</div>
        {programacion_entrega.entregas_maquina.length === 0 ? (
          <span>-</span>
        ) : (
          <List>
            {programacion_entrega.entregas_maquina.map(({ paquete }) => (
              <List.Item>
                {paquete.maquina.nombre}: {paquete.cantidad} entregadas
              </List.Item>
            ))}
          </List>
        )}
      </>
    );
  };

  render() {
    const { visita } = this.state;

    if (visita === null) {
      return <div>Cargando...</div>;
    }

    return (
      <div>
        <div>
          <strong>Venta realizada</strong>
          {this.renderInfoVenta(visita)}
        </div>
        <Divider size="md" style={{ marginTop: "1rem", marginBottom: "1rem" }} />
        <div>
          <strong>Entrega realizada</strong>
          {this.renderInfoEntrega(visita)}
        </div>
        <Divider size="md" style={{ marginTop: "1rem", marginBottom: "1rem" }} />
        <div>
          <strong>Entrega programada realizada</strong>
          {this.renderInfoProgramacionEntrega(visita)}
        </div>
      </div>
    );
  }
}
