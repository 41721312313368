import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Dia, Recorrido, RecorridoReparto } from "serviciossaintmichel";
import SelectDia from "../../../../../compartido/components/franjahoraria/SelectDia";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import RecorridosShow, {
  Version,
} from "../../../../recorridos/components/show/RecorridosShow";



export interface Props {
  onChange: Function;
  onValidationChange: Function;
  recorrido: RecorridoReparto;
}

interface State {
  recorrido: RecorridoReparto;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class RecorridoRepartoLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      recorrido: this.props.recorrido,
      error: "",
      valid: this.isValid(this.props.recorrido),
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  isValid = (recorrido: RecorridoReparto): boolean => {
    return recorrido.recorrido.id > 0 && recorrido.dia_reparto ? true : false;
  };

  componentDidUpdate(props: Props, state: State) {
    if (
      this.props.recorrido.recorrido.id !== props.recorrido.recorrido.id ||
      this.props.recorrido.dia_reparto !== props.recorrido.dia_reparto
    ) {
      let valid = this.isValid(this.props.recorrido);
      this.setState({ recorrido: this.props.recorrido, valid: valid });
    }
    if (this.state.valid != state.valid) {
      this.props.onValidationChange(this.state.valid);
    }
  }

  onChangeRecorrido = (x: Recorrido | null) => {
    let recorrido = this.state.recorrido;
    recorrido.recorrido.id = x ? x.id : -1;
    let valid = this.isValid(recorrido);
    this.setState({ recorrido: recorrido, valid: valid });
    this.props.onChange(recorrido);
    if (this.props.onValidationChange) this.props.onValidationChange(valid);
  };

  onChangeDia = (dia: Dia | null) => {
    let recorrido = this.state.recorrido;
    recorrido.dia_reparto = dia;
    let valid = this.isValid(recorrido);
    this.setState({ recorrido: recorrido, valid: valid });
    this.props.onChange(recorrido);
    if (this.props.onValidationChange) this.props.onValidationChange(valid);
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="flex-start"
          justifyContent="center"
          marginTop="20px"
        >
          <Form.Label> Seleccione Recorrido</Form.Label>
          <RecorridosShow
            version={Version.SELECT}
            recorridosLibres={true}
            propsSelect={{
              seleccionado: {
                id: this.state.recorrido.recorrido.id,
                nombre: this.state.recorrido.recorrido.nombre,
                reparto: this.state.recorrido.recorrido.reparto,
              },
              callbackParent: this.onChangeRecorrido,
              sinTitulo: true,
            }}
          />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="flex-start"
          justifyContent="center"
          marginTop="20px"
        >
          <Form.Label> Seleccione Día</Form.Label>
          <SelectDia
            seleccionado={this.state.recorrido.dia_reparto}
            onChangeDia={this.onChangeDia}
          />
        </Flex>
      </Flex>
    );
  }
}
