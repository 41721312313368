import React, { Component, FormEvent } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ComboRetornableLoad from "../load/ComboRetornableLoad";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import { CANALES_WEB_SOCKET, ComboRetornable, ComboRetornablePost, ComboRetornableService,  WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";



export interface Props {
  onSaveSuccess: Function;
  onExit?: Function;
}

interface State {
  comboRetornable: ComboRetornablePost;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class ComboRetornableNew extends Component<Props, State> {

  private comboRetornableService:ComboRetornableService;

  constructor(props: Props) {
    super(props);
    this.state = {
      comboRetornable: this.createEmptyComboRetornable(),
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    this.comboRetornableService =  new ComboRetornableService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  createEmptyComboRetornable(): ComboRetornablePost {
    let x: ComboRetornablePost = {
      nombre: "",
    };
    return x;
  }

  onSave = () => {
    return this.comboRetornableService.create(this.state.comboRetornable)
      .then((comboRetornable) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.VENTAS_COMBOS_RETORNABLES,
          { type: "NEW", idComboRetornable: comboRetornable.id }
        );
        Swal.fire({
          icon: "success",
          text: "Combo Retornable Creada",
        });
        this.props.onSaveSuccess(comboRetornable.id);
      })
      .catch((error) => {
        this.comboRetornableService.manejarErrorHTTP(error, "Combo Retornable");
      });
  };

  onExit = () => {
    if (this.props.onExit != undefined) this.props.onExit();
  };

  onChange = (comboRetornable: ComboRetornable) => {
    this.setState({ comboRetornable: comboRetornable });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex
      flexDirection="column"
      width="50%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={3} texto={"Combo Retornable Nuevo"} />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <ComboRetornableLoad
            comboRetornable={this.state.comboRetornable}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
          />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Crear"}
            onClick={this.onSave}
          />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
