import React, { Component } from "react";
import { TipoTelefono, TipoTelefonoService } from "serviciossaintmichel";
import SelectGenerico from "../../../../../../../compartido/components/select/SelectGenerico";
import SelectGenericoNull from "../../../../../../../compartido/components/select/SelectGenericoNull";
import { Global } from "../../../../../../../Global";



interface Props {
  callbackParent: Function;
  seleccionado: TipoTelefono;
  opcionNull?: boolean;
}

interface State {
  tiposTelefono: Array<TipoTelefono>;
}

export default class SelectTipoTelefono extends Component<Props, State> {

  private tipoTelefonoService:TipoTelefonoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      tiposTelefono: [],
    };

    this.tipoTelefonoService = new TipoTelefonoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    const tiposTelefono = await this.tipoTelefonoService.listAll();
    this.setState({ tiposTelefono });
  };

  getPosicion = (tipoTelefono: TipoTelefono): number => {
    if (tipoTelefono) {
      return this.state.tiposTelefono.findIndex((t) => t.id === tipoTelefono.id);
    }
    return -1;
  };

  getSeleccionado = (indice: number) => {
    this.props.callbackParent(this.state.tiposTelefono[indice]);
  };

  render() {
    if (this.props.opcionNull) {
      return (
        <SelectGenericoNull
          seleccionado={this.getPosicion(this.props.seleccionado)}
          elementos={this.state.tiposTelefono.map((t) => t.tipo)}
          callback={this.getSeleccionado}
        />
      );
    } else {
      return (
        <SelectGenerico
          seleccionado={this.getPosicion(this.props.seleccionado)}
          elementos={this.state.tiposTelefono.map((t) => t.tipo)}
          callback={this.getSeleccionado}
        />
      );
    }
  }
}
