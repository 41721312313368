import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import Herramientas from "./Herramienta/components/Herramientas";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];
  
  if (Global.UsuarioService.getPermisos().Elementos.Herramientas.hasAccess()) {
    aux.push({
      name: "Herramientas",
      path: SeccionHerramientas.pathBase + "/herramientas",
      component: Herramientas,
    })
  }
  return aux;
}

export default class SeccionHerramientas extends Component<Props> {
  static pathBase = "/elementos/seccion-herramientas";
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Herramientas"}
      />
    );
  }
}
