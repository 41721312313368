import React, { Component } from "react";
import PedidoUpdate from "../components/update/PedidoUpdate";
import PedidoShow from "../components/show/PedidoShow";
import PedidosShow, { Version } from "../components/show/PedidosShow";
import { Tabs, Tab } from "react-bootstrap";
import Flex from "../../../../libreria/appearance/flex/Flex";
import { CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";
import PedidoNew from "./new/PedidoNew";
interface Props {}

interface State {
  tabVisible: any;
  disabledActualizar: boolean;
  disabledVer: boolean;
  responsive: boolean;
  idPedido: number | null;
  recienCreado: boolean;
}

export default class Pedidos extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista",
      disabledActualizar: true,
      disabledVer: true,
      responsive: window.innerWidth < 700 ? true : false,
      idPedido: null,
      recienCreado: false,
    };

    window.addEventListener("resize", this.actualizarDimensiones);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PEDIDOS,
      funcionCallback: this.cargarPedido,
    });
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  cargarPedido = async (e: MessageEvent) => {};

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  actualizar = (idPedido: number) => {
    this.setState({
      tabVisible: "actualizar",
      disabledActualizar: false,
      idPedido: idPedido,
    });
  };

  ver = (idPedido: number) => {
    this.setState({
      tabVisible: "ver",
      disabledVer: false,
      idPedido: idPedido,
      recienCreado: false,
    });
  };

  ocultarVer = () => {
    this.setState({
      tabVisible: "lista",
      disabledVer: true,
      idPedido: null,
    });
  };

  ocultarNuevo = () => {
    this.setState({
      tabVisible: "lista",
    });
  };

  renderizarNuevo = () => {
    if (Global.UsuarioService.getPermisos().Ventas.Pedidos.Pedido.canAdd()) {
      return (
        <Tab eventKey="nuevo" title="Nuevo" width="100%">
          {this.state.tabVisible === "nuevo" ? (
            <PedidoNew
              onExit={this.ocultarNuevo}
              onSaveSuccess={async (idPedido: number) => {
                this.setState({ recienCreado: true, idPedido: idPedido });
                this.actualizar(idPedido);
              }}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarLista = () => {
    if (Global.UsuarioService.getPermisos().Ventas.Pedidos.Pedido.canView()) {
      return (
        <Tab eventKey="lista" title="Lista" width="100%">
          <PedidosShow
            version={Version.TABLE}
            onView={this.ver}
            onUpdate={this.actualizar}
          />
        </Tab>
      );
    }
  };

  renderizarActualizar = () => {
    if (Global.UsuarioService.getPermisos().Ventas.Pedidos.Pedido.canChange()) {
      return (
        <Tab
          width="100%"
          eventKey="actualizar"
          title="Actualizar"
          disabled={this.state.disabledActualizar}
        >
          {this.state.idPedido && this.state.tabVisible === "actualizar" ? (
            <PedidoUpdate idPedido={this.state.idPedido} />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarVerDetalle = () => {
    if (Global.UsuarioService.getPermisos().Ventas.Pedidos.Pedido.canView()) {
      return (
        <Tab
          width="100%"
          eventKey="ver"
          title="Ver Detalle"
          disabled={this.state.disabledVer}
        >
          {this.state.idPedido && this.state.tabVisible === "ver" ? (
            <PedidoShow idPedido={this.state.idPedido} />
          ) : null}
        </Tab>
      );
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey="lista"
      transition={false}
      activeKey={this.state.tabVisible}
      onSelect={this.onSelect}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "flex-start" : "",
      }}
      unmountOnExit={true}
    >
      {this.renderizarNuevo()}
      {this.renderizarLista()}
      {this.renderizarActualizar()}
      {this.renderizarVerDetalle()}
    </Tabs>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <div style={{ display: "block", width: "100%" }}>
          {this.renderizar(this.state.responsive)}
        </div>
      </Flex>
    );
  }
}
