import React, { Component } from "react";
import { PrecioAlquiler } from "serviciossaintmichel";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../../../libreria/data/input/Input";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  precio: PrecioAlquiler;
}
interface State {
  precio: PrecioAlquiler;
  validation: boolean;
}

export default class PrecioAlquilerLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      precio: this.props.precio,
      validation: false,
    };
  }

  componentDidMount = () => {
    this.updateValidation(this.state.precio);
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps != this.props) {
      this.setState({ precio: this.props.precio });
    }
  };

  isValid = (precio: PrecioAlquiler) => {
    return precio.precio > 0 ? true : false;
  };

  updateValidation = (precio: PrecioAlquiler) => {
    let validation: boolean = this.isValid(precio);
    this.setState({ validation: validation });
    this.props.onValidationChange(validation);
  };

  onChange = (value: string) => {
    let precio = this.state.precio;
    precio.precio = parseInt(value);
    this.setState({ precio: precio });
    this.updateValidation(precio);
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Input
          integers={10}
          decimal={2}
          data={this.state.precio.precio}
          label={"Precio Actual: " + this.state.precio.precio}
          placeholder={"Nuevo precio"}
          onChange={this.onChange}
          type={InputTypes.Number}
        />
      </Flex>
    );
  }
}
