import React, { Component } from "react";
import { ComboRetornablePost } from "serviciossaintmichel";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../../libreria/data/input/Input";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  comboRetornable: ComboRetornablePost;
}
interface State {
  comboRetornable: ComboRetornablePost;
  validation: boolean;
}

export default class ComboRetornableLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      comboRetornable: this.props.comboRetornable,
      validation: false,
    };
  }

  componentDidMount = () => {
    this.updateValidation(this.state.comboRetornable);
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps != this.props) {
      this.setState({ comboRetornable: this.props.comboRetornable });
    }
  };

  isValid = (comboRetornable: ComboRetornablePost) => {
    return comboRetornable.nombre != null ? true : false;
  };

  updateValidation = (comboRetornable: ComboRetornablePost) => {
    let validation: boolean = this.isValid(comboRetornable);
    this.setState({ validation: validation });
    if (this.props.onValidationChange)
      this.props.onValidationChange(validation);
  };

  setearNombre = (nombre: string) => {
    let comboRetornable = this.state.comboRetornable;
    comboRetornable.nombre = nombre;
    this.setState({ comboRetornable: comboRetornable });
    this.props.onChange(comboRetornable);
    this.updateValidation(comboRetornable);
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Input
          data={this.state.comboRetornable.nombre}
          label={"Nombre"}
          placeholder={"Nombre"}
          onChange={this.setearNombre}
          type={InputTypes.Text}
        />
      </Flex>
    );
  }
}
