import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { DatosProducto, EstadoLogisticaPaquete, ProductoGet, VentaProgramacionProducto } from "serviciossaintmichel";
import ProductosShow, { Version } from "../../../../elementos/productos/Producto/components/show/ProductosShow";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";

export interface Props {
  onSubmitCallback?: Function;
  excluirProductos?: Array<number>;
}

interface State {
  nuevaVentaProducto: VentaProgramacionProducto;
  productoSeleccionado: DatosProducto | null;
}

export default class VentaProductoProgramacionEntregaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getEstadoInicial();
  }

  productoNuevo = (): ProductoGet => {
    return { id: -1, nombre: "", volumen: 0, tipo_producto: { id: 1, tipo: "" }, insumos: [] };
  };

  getEstadoInicial = () => ({
    nuevaVentaProducto: {
      id: -1,
      paquete: {
        id: -1,
        producto: this.productoNuevo(),
        cantidad: 0,
        bonificados: 0,
      },
      estado: EstadoLogisticaPaquete.PENDIENTE_DE_REPARTO,
    },
    productoSeleccionado: null,
  });

  setProducto = (prod: DatosProducto | null) => {
    const producto: ProductoGet = prod !== null ? { ...prod, insumos: [] } : this.productoNuevo();
    this.setState((prevState) => ({
      nuevaVentaProducto: {
        ...prevState.nuevaVentaProducto,
        producto: prod ? producto : this.state.nuevaVentaProducto.paquete.producto,
      },
      productoSeleccionado: prod,
    }));
  };

  onSubmit = () => {
    const { onSubmitCallback = () => {} } = this.props;
    onSubmitCallback(this.state.nuevaVentaProducto);
    const estadoInicial = this.getEstadoInicial();
    this.setState(estadoInicial);
  };

  validarVenta = (ventaProducto: VentaProgramacionProducto) => {
    const total = ventaProducto.paquete.cantidad + ventaProducto.paquete.bonificados;

    return ventaProducto.paquete.producto.id > 0 && total > 0;
  };

  render() {
    const { nuevaVentaProducto, productoSeleccionado } = this.state;
    return (
      <Flex borderRadius="5px" border="1px solid black" gap="1rem" padding="1rem" alignItems="center">
        <ProductosShow
          version={Version.SELECT}
          productosExcluidos={this.props.excluirProductos}
          propsSelect={{
            callbackParent: this.setProducto,
            seleccionado: productoSeleccionado,
          }}
        />
        <div>
          <span>Cantidad</span>
          <Input
            type={InputTypes.Number}
            data={nuevaVentaProducto.paquete.cantidad}
            onChange={(data: number) => {
              this.setState((prevState) => ({
                nuevaVentaProducto: { ...prevState.nuevaVentaProducto, cantidad: data },
              }));
            }}
          />
        </div>
        <div>
          <span>Bonificados</span>
          <Input
            type={InputTypes.Number}
            data={nuevaVentaProducto.paquete.bonificados}
            onChange={(data: number) => {
              this.setState((prevState) => ({
                nuevaVentaProducto: { ...prevState.nuevaVentaProducto, bonificados: data },
              }));
            }}
          />
        </div>
        <Button onClick={this.onSubmit} disabled={!this.validarVenta(nuevaVentaProducto)}>
          Añadir
        </Button>
      </Flex>
    );
  }
}
