import React, { Component } from "react";
import { CANALES_WEB_SOCKET, Descuento, DescuentoService, WebSocketService } from "serviciossaintmichel";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import { Global } from "../../../../../Global";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import { Loader } from "@mantine/core";
import InfoDescuento from "./InfoDescuento";

export interface Props {
  descuentoId: number;
}
interface State {
  descuento: Descuento | null;
  screen: ScreenType;
  valid: boolean;
}

interface Message {
  type: string;
  idDescuento: number;
}

export default class DescuentoShow extends Component<Props, State> {
  private descuentoService: DescuentoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      descuento: null,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
      valid: false,
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_DESCUENTOS,
      funcionCallback: this.onChannelMesagge,
    });

    this.descuentoService = new DescuentoService(Global.UsuarioService.getToken()!);
  }

  async componentDidMount() {
    const descuento = await this.descuentoService.get(this.props.descuentoId);
    this.setState({
      descuento: descuento,
    });
  }

  onChannelMesagge = async (e: MessageEvent) => {
    const message: Message = JSON.parse(e.data).message;

    if (message.idDescuento === this.props.descuentoId) {
      const descuento = await this.descuentoService.get(this.props.descuentoId);
      this.setState({
        descuento: descuento,
      });
    }
  };

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  renderizarPhone = () => (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex flexDirection="column" width="60%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex flexDirection="column" width="50%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => {
    const { descuento } = this.state;

    if (descuento === null) {
      return null;
    }

    return (
      <Box padding="20px">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <H size={3} texto={`Detalle descuento (id ${this.props.descuentoId})`} />
          <InfoDescuento descuento={descuento} />
        </Flex>
      </Box>
    );
  };

  render() {
    if (this.state.descuento === null) {
      return <Loader />;
    }

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.screen === ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen === ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen === ScreenType.TABLET ? this.renderizarTablet() : null}
      </Flex>
    );
  }
}
