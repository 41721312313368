import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Font } from "../../models/models";

interface Props {
  data?: string;
  onChange: Function;
  fontFamily?: string;
  label?: string;
  placeholder?: string;
  onValidationChange?: Function;
  empty?: boolean;
  error?: string;
  disabled?: boolean;

  decimal?: number;
  integers?: number;
  min?: number;
  max?: number;
}

interface State {
  data: string;
  fontFamily: string;
  valid: boolean;

  validEmpty: boolean;
  validMin: boolean;
  validMax: boolean;
  validNumber: boolean;
  error: string;
  disabled: boolean;
}

class InputNumber extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let data: string = this.props.data !== undefined && this.props.data !== null ? this.props.data : "";
    let validEmpty: boolean = this.isValidEmpty(data);
    let validNumber: boolean = this.isValidNumber(data);
    let validMin: boolean = this.isValidMin(data);
    let validMax: boolean = this.isValidMax(data);
    this.state = {
      data: data,
      disabled: this.props.disabled ? this.props.disabled : false,

      fontFamily: this.props.fontFamily ? this.props.fontFamily : Font.family,
      error: this.props.error ? this.props.error : "",
      valid: validEmpty && validNumber && validMin && validMax,
      validMin: validMin,
      validMax: validMax,
      validNumber: validNumber,
      validEmpty: validEmpty,
    };
  }

  componentDidMount() {
    if (this.props.onValidationChange != undefined) this.props.onValidationChange(this.state.valid);
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps != this.props) {
      //debugger;

      let data: string = this.props.data !== undefined && this.props.data !== null ? this.props.data : "";
      let error: string = this.props.error ? this.props.error : "";

      let disabled: boolean = this.props.disabled ? this.props.disabled : false;

      /*if(prevProps.disabled!=this.props.disabled)
        disabled = this.props.disabled? this.props.disabled:false;
      */

      this.setState({ data: data, error: error, disabled: disabled });
    }

    /*
    if (prevProps.data !=  this.props.data) {
      let data:string = this.props.data ? this.props.data:"";
      this.setState({ data: data });
    }
   
   
    if (prevProps.error != undefined && this.props.error == undefined) {
      this.setState({ error: "" });
    }
    else if (prevProps.error == undefined && this.props.error != undefined) {
      this.setState({ error: this.props.error });
    }
    else if (prevProps.error != undefined && this.props.error != undefined) {
      if (prevProps.error != this.props.error)
        this.setState({ error: this.props.error })
    }
    else { }
    */
  };

  onChange = (data: any) => {
    //debugger;
    let x: string = data.target.value;
    let validEmpty: boolean = this.isValidEmpty(x);
    let validNumber: boolean = this.isValidNumber(x);
    let validMin: boolean = this.isValidMin(x);
    let validMax: boolean = this.isValidMax(x);

    let valid: boolean = validEmpty && validNumber && validMin && validMax;
    this.setState({
      data: x,
      valid: valid,
      validEmpty: validEmpty,
      validMin: validMin,
      validMax: validMax,
      validNumber: validNumber,
    });
    this.props.onChange(x);
    if (this.props.onValidationChange != undefined) this.props.onValidationChange(valid);
  };

  isValidNumber(data: string) {
    if (data.length > 0) {
      let aux: boolean = true;

      if (
        this.props.decimal != undefined &&
        this.props.integers != undefined &&
        this.props.decimal > 0 &&
        this.props.integers > 0
      ) {
        const regularExpression = new RegExp(
          "^\\d{1," + this.props.integers.toString() + "}(\\.{1}\\d{0," + this.props.decimal.toString() + "}){0,1}$",
          "gi"
        );

        if (!regularExpression.test(data)) aux = false;
      } else if (this.props.integers != undefined && this.props.integers > 0) {
        const regularExpression = new RegExp("^\\d{1," + this.props.integers.toString() + "}$", "gi");

        if (!regularExpression.test(data)) aux = false;
      } else if (this.props.decimal != undefined && this.props.decimal > 0) {
        const regularExpression = new RegExp("^\\d+\\.\\d{0," + this.props.decimal.toString() + "}$", "gi");

        //^\d+\.\d{0,2}$

        if (!regularExpression.test(data)) aux = false;
      } else {
        const regularExpression = new RegExp("^\\d+$", "gi");

        if (!regularExpression.test(data)) aux = false;
      }
      return aux;
    } else return true;
  }

  isValidEmpty(data: string) {
    if (this.props.empty != undefined)
      if (data.length == 0) return false;
      else return true;
    else return true;
  }

  isValidMin(data: string) {
    let x: number = Number(data);
    if (this.props.min != undefined)
      if (x < this.props.min) return false;
      else return true;
    else return true;
  }

  isValidMax(data: string) {
    let x: number = Number(data);
    if (this.props.max != undefined)
      if (x > this.props.max) return false;
      else return true;
    else return true;
  }

  render() {
    return (
      <Form.Group style={{ width: "100%" }}>
        {this.props.label ? <Form.Label>{this.props.label}</Form.Label> : null}
        <Form.Control
          disabled={this.state.disabled}
          style={{ fontFamily: this.state.fontFamily }}
          isInvalid={!this.state.valid}
          type="text"
          value={this.state.data}
          placeholder={this.props.placeholder ? this.props.placeholder : ""}
          onChange={this.onChange}
        />
        {this.state.validEmpty == false ? (
          <Form.Control.Feedback type="invalid">Debe ingresar un número.</Form.Control.Feedback>
        ) : null}
        {this.state.validNumber == false ? (
          <Form.Control.Feedback type="invalid">
            El número ingresado no cumple el formato esperado.
          </Form.Control.Feedback>
        ) : null}
        {this.state.validMin == false ? (
          <Form.Control.Feedback type="invalid">
            El número ingresado debe ser mayor que {this.props.min?.toString()}.
          </Form.Control.Feedback>
        ) : null}
        {this.state.validMax == false ? (
          <Form.Control.Feedback type="invalid">
            El número ingresado debe ser menor que {this.props.max?.toString()}
          </Form.Control.Feedback>
        ) : null}
        {this.state.error != "" ? (
          <Form.Control.Feedback type="invalid">{this.state.error}</Form.Control.Feedback>
        ) : null}
      </Form.Group>
    );
  }
}

export default InputNumber;
