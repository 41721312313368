import React, { Component } from "react";
import Flex from "../../../../compartido/components/flex/Flex";
import Box from "../../../../compartido/components/box/Box";
import { Button, ListGroup } from "react-bootstrap";





import { CANALES_WEB_SOCKET, RepuestoGet,  RepuestoPost,  RepuestoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";





export interface Props {
  repuestoId: number;
  ocultar: Function;
}

interface State {
  repuesto: RepuestoGet;
  anchoBox: string;
}

export default class RepuestoVerDetalle extends Component<Props, State> {

  private repuestoService:RepuestoService;

  constructor(props: Props) {
    super(props);

    this.state = {
      repuesto: this.crearRepuestoVacio(),
      anchoBox: window.innerWidth < 750 ? "90%" : "50%",
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_REPUESTOS,
      funcionCallback: this.cargarRepuestoCanal,
    });

    this.repuestoService = new RepuestoService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 750) {
      this.setState({ anchoBox: "90%" });
    } else {
      this.setState({ anchoBox: "50%" });
    }
  };

  componentDidMount() {
    this.cargarRepuesto(this.props.repuestoId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarRepuesto(this.props.repuestoId);
    }
  }

  crearRepuestoVacio(): RepuestoGet {
    const repuesto: RepuestoGet = {
      id: 0,
      nombre: "",
      marca: "",
      descripcion: "",
      proveedores: [],
    };
    return repuesto;
  }

  cargarRepuestoCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.repuesto &&
      dato.message.repuestoId == this.state.repuesto.id
    ) {
      this.cargarRepuesto(this.state.repuesto.id);
    }
  };

  cargarRepuesto = async (id: number) => {
    const resRepuesto = await this.repuestoService.get(id).catch((error) => {
      this.repuestoService.manejarErrorHTTP(error, "Repuesto");
      return null;
    });
    if (resRepuesto != null) {
      this.setState({
        repuesto: resRepuesto,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  render() {
    if (this.state.repuesto.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal={"10rem"} paddingVertical={"2rem"}>
            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h5>Nombre: {this.state.repuesto.nombre}</h5>
                  <h5>Marca: {this.state.repuesto.marca}</h5>
                  <h5>Descripcion: {this.state.repuesto.descripcion}</h5>
                </div>
              </Flex>
            </Flex>

            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>

            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h4>
                    Proveedores Actuales
                    {this.state.repuesto.proveedores.length == 0 ? (
                      " : No tiene"
                    ) : (
                      <Flex
                        container
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        paddingTop="10px"
                      >
                        <ListGroup>
                          {this.state.repuesto.proveedores.map((relacion) => (
                            <ListGroup.Item style={{ textAlign: "center" }}>
                              {relacion.proveedor.nombre +
                                " - Cantidad mínima que vende: " +
                                relacion.cantidad_minima +
                                " - A: " +
                                relacion.precio +
                                "$"}
                              <Button
                                style={{
                                  marginLeft: "1rem",
                                }}
                              >
                                Ver Historial
                              </Button>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Flex>
                    )}
                  </h4>
                </div>
              </Flex>
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <button
                className={"btn btn-outline-secondary"}
                onClick={this.ocultar}
                style={{ width: "150px" }}
              >
                Salir
              </button>
            </Flex>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
