import React, { Component } from "react";
import Text from "../../../../../../libreria/message/text/Text";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import DatosChequeShow from "../../../../../seccion-pagos/cheques/components/show/datos/DatosChequeShow";
import DatosBancoShow from "../../../../../seccion-pagos/bancos/components/show/datos/DatosBancoShow";
import ComprobanteShow from "../../../../../seccion-pagos/pagos/components/show/ComprobanteShow";
import { CANALES_WEB_SOCKET, Pago, PagoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";






export interface Props {
  idPago: number;
}

interface State {
  pago: Pago | null;
  cargando: boolean;
  visible: boolean;
}

export default class PagoProgramacionEntregaShow extends Component<
  Props,
  State
> {

  private pagoService:PagoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      pago: null,
      cargando: true,
      visible: false,
    };

    this.pagoService =  new PagoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    await this.cargarPago();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PAGOS,
      funcionCallback: this.onChannelMesagge,
    });
    console.log(this.state.pago);
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.pagoId == this.props.idPago) {
      await this.cargarPago();
    }
  };

  cargarPago = async () => {
    let pago = await this.pagoService.get(this.props.idPago).catch((error) => {
      this.pagoService.manejarErrorHTTP(error, "Pago");
      return null;
    });
    this.setState({ pago: pago, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      await this.setState({ cargando: true });
      await this.cargarPago();
    }
  };

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  descargarArchivo = () => {
    if (this.state.pago && this.state.pago.comprobante) {
      console.log(this.state.pago.comprobante);
      this.pagoService.descargarArchivo(
        this.state.pago.id,
        this.state.pago.comprobante,
        "comprobante"
      ).catch((error) => {
        this.pagoService.manejarErrorHTTP(error, "Comprobante Pago");
      });
    }
  };

  renderPago = () =>
    this.state.pago ? (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="flex-start"
        justifyContent="center"
        marginTop="10px"
      >
        <Text>
          <b>Monto</b>: {this.state.pago.monto}
        </Text>
        <Text>
          <b>Fecha</b>: {this.state.pago.fecha}
        </Text>
        <Text>
          <b>Tipo</b>: {this.state.pago.tipo_pago.tipo}
        </Text>
        {this.state.pago.cheque ? (
          <Flex container flexDirection="row" flexWrap="nowrap" width="100%">
            <Text>
              <b>Cheque</b>:
            </Text>
            <Flex
              flexDirection="row"
              container
              marginLeft="5px"
              border="1px solid grey"
            >
              <DatosChequeShow
                id={this.state.pago.cheque.id}
                soloDatos={true}
              />
              <Flex
                container
                flexDirection="row"
                flexWrap="nowrap"
                width="100%"
              >
                <Text>
                  <b>Banco</b>:
                </Text>
                <Flex
                  flexDirection="row"
                  container
                  marginLeft="5px"
                  border="1px solid grey"
                >
                  <DatosBancoShow
                    id={this.state.pago.cheque.banco.id}
                    soloDatos={true}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        ) : null}
        <Flex flexWrap="nowrap">
          <Text>
            <b>Comprobante</b>:
          </Text>
          {this.state.pago?.comprobante ? (
            <ComprobanteShow
              idPago={this.state.pago.id}
              comprobante={this.state.pago.comprobante}
            />
          ) : (
            <>No tiene</>
          )}
        </Flex>
      </Flex>
    ) : null;
  renderLoading = () => "Cargando Datos";

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.cargando ? this.renderLoading() : this.renderPago()}
      </Flex>
    );
  }
}
