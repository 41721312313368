import React, { Component, FormEvent } from "react";
import InputDecimal from "../../../../compartido/components/input/InputDecimal";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import SelectTipoProducto from "../../TipoProducto/components/SelectTipoProducto";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";

import { CANALES_WEB_SOCKET,  InsumoService, ProductoGet, ProductoPost, ProductoService, TipoProductoGet, TipoProductoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";


export interface Props {
  productoId: number;
}

interface State {
  producto: ProductoGet;
  tiposProducto: Array<TipoProductoGet>;
}

export default class ProductoActualizarPropio extends Component<Props, State> {


  private productoService:ProductoService;
  private tipoProductoService:TipoProductoService;


  constructor(props: Props) {
    super(props);
    this.state = {
      producto: this.crearProductoVacio(),
      tiposProducto: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_PRODUCTOS,
      funcionCallback: this.cargarTiposProducto,
    });


    this.productoService =  new ProductoService(Global.UsuarioService.getToken()!);
    this.tipoProductoService =  new TipoProductoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.cargarProducto(this.props.productoId);
    this.cargarTiposProducto();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarProducto(this.props.productoId);
      this.cargarTiposProducto();
    }
  }

  crearProductoVacio(): ProductoGet {
    const tipoProducto: TipoProductoGet = {
      id: 0,
      tipo: "",
    };
    const producto: ProductoGet = {
      id: 0,
      nombre: "",
      tipo_producto: tipoProducto,
      volumen: 0,
      insumos: [],
    };
    return producto;
  }

  cargarProducto = async (id: number) => {
    const resProducto = await this.productoService.get(id).catch((error) => {
      this.productoService.manejarErrorHTTP(error, "Producto");
      return null;
    });
    if (resProducto != null) {
      this.setState({
        producto: resProducto,
      });
    }
  };

  cargarTiposProducto = async () => {
    const resTiposProducto = await this.tipoProductoService.listAll().catch(
      (error) => {
        this.tipoProductoService.manejarErrorHTTP(error, "Tipo Producto");
        return null;
      }
    );
    if (resTiposProducto != null) {
      this.setState({
        tiposProducto: resTiposProducto,
      });
    }
  };

  setearNombre = (nombre: string) => {
    let prod = this.state.producto;
    prod.nombre = nombre;
    this.setState({ producto: prod });
  };

  setearTipoProducto = (tipoProducto: TipoProductoGet) => {
    let prod = this.state.producto;
    prod.tipo_producto = tipoProducto;
    this.setState({ producto: prod });
  };

  setearVolumen = (vol: number) => {
    let prod = this.state.producto;
    prod.volumen = vol;
    this.setState({ producto: prod });
  };

  handleUpdate() {
    const productoActualizar: ProductoPost = {
      nombre: this.state.producto.nombre,
      tipo_producto: this.state.producto.tipo_producto.id,
      volumen: this.state.producto.volumen,
    };
    return this.productoService.update(productoActualizar, this.state.producto.id)
      .then((result) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS, {
          productoId: this.state.producto.id,
        });
      })
      .catch((error) => {
        this.productoService.manejarErrorHTTP(error, "Producto");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.producto.nombre &&
      this.state.producto.volumen &&
      this.state.producto.volumen != 0
      ? false
      : true;
  };

  render() {
    if (this.state.producto.id != 0 && this.state.tiposProducto.length > 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            <div className={"row"}>
              <div className={"column"}>
                <form
                  className={"form-horizontal"}
                  onSubmit={this.handleSubmit}
                >
                  <div className={"form-group"}>
                    <label htmlFor="nombre">Nombre</label>
                    <InputCaracteres
                      id="nombre"
                      name="nombre"
                      onChange={this.setearNombre}
                      value={this.state.producto.nombre}
                      placeholder="Ingrese Nombre de Producto"
                    />
                  </div>

                  <div className={"form-group"}>
                    <label htmlFor="tipo-producto">Tipo Producto</label>
                    <SelectTipoProducto
                      tiposProductos={this.state.tiposProducto}
                      seleccionado={this.state.producto.tipo_producto}
                      callbackParent={this.setearTipoProducto}
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="volumen">Volumen</label>{" "}
                    <InputDecimal
                      id="volumen"
                      name="volumen"
                      onChange={this.setearVolumen}
                      value={this.state.producto.volumen}
                      cantEnteros={3}
                      cantDecimales={3}
                      placeholder={"Ingrese Volumen"}
                    />
                  </div>
                  <div
                    className={"form-group"}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <RequestButton
                      texto="Actualizar"
                      msgOnSuccess="Producto actualizado con exito"
                      msgOnFailure="Ocurrio un error al actualizar el producto"
                      propsBoton={{
                        variant: "info",
                        disabled: this.disableButton(),
                      }}
                      onClick={() => this.handleUpdate()}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
