import { CriterioOrden } from "../models/CriterioOrden";

export const criteriosOrdenIguales = (criterioA: CriterioOrden | null, criterioB: CriterioOrden | null): boolean => {
  if (criterioA === null || criterioB === null) {
    return false;
  }
  return criterioA.campo === criterioB.campo && criterioA.modo === criterioB.modo;
};

export const getControlCriterio = (campo: string, criterioOrdenActual: CriterioOrden | null) =>
  criterioOrdenActual?.campo === campo ? criterioOrdenActual.modo : null;

export const getCriterioHandler = (campo: string, criterioOrdenHandler: (critOrden: CriterioOrden | null) => void) => {
  return (modo: "ASC" | "DESC" | null) => {
    const criterioOrden = modo ? { modo: modo, campo: campo } : null;
    if (criterioOrdenHandler) {
      criterioOrdenHandler(criterioOrden);
    }
  };
};

export const manejadorScroll = (scrollableElementId: string, functionToCall?: Function) => {
  const divTabla = document.getElementById("contenedor-scroll");
  if (divTabla === null) {
    return;
  }

  const movimientoTope = divTabla.scrollHeight - divTabla.clientHeight;

  if (movimientoTope === divTabla.scrollTop && functionToCall) {
    functionToCall();
  }
};
