export interface Font
{
size:number;
family:string;
} 


export const Font =
{
size:20,
family:"Arial",
} 


export interface Data 
{
id:number;
text:string;
}