import React, { Component } from "react";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import DireccionesRecorridoUpdate from "./DireccionesRecorridoUpdate";

export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  idRecorrido: number;
}
interface State {
  screen: ScreenType;
}

export default class DireccionesRecorridoSeccion extends Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      /* this.setState({ recorrido: this.props.idRecorrido }); */
    }
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="70%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={3} texto={"Direcciones"} />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Flex
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
            marginTop="20px"
          >
            <DireccionesRecorridoUpdate idRecorrido={this.props.idRecorrido} />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
