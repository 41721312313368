import React, { Component } from "react";
import ListBoxItem from "./ListBoxItem";
import { ItemListBox, TipoItemListBox } from "./Item";
import Flex from "../flex/Flex";
import Contenedor from "../contenedor/Contenedor";
import { ListGroup } from "react-bootstrap";

interface Props<T> {
  items?: Array<ItemListBox<T>>;
  height?: string;
  width?: string;
  borde?: string;
  onSelect?(item: T | null): void;
  onDelete?(item: T): void;
  onDeleteFinish?(item: T): void;
  onView?(item: T): void;
  tipoItem: TipoItemListBox;
  onChangeList?(items: Array<T>): void;
}

interface State<T> {
  items: Array<ItemListBox<T>>;
}

class ListBox<T> extends Component<Props<T>, State<T>> {
  constructor(props: Props<T>) {
    super(props);
    this.state = {
      items: this.props.items ? this.props.items : [],
    };
  }

  componentDidMount() {}

  iguales = (items1: Array<ItemListBox<T>>, items2: Array<ItemListBox<T>>): boolean => {
    if (items1.length == items2.length) {
      let aux = true;
      items1.map((x) => {
        if (items2.filter((y) => y.id == x.id).length == 0) aux = false;
      });
      return aux;
    } else return false;
  };

  componentDidUpdate(prevProps: Props<T>) {
    if (prevProps.items == undefined && this.props.items != undefined) this.setState({ items: this.props.items });
    else if (prevProps.items != undefined && this.props.items == undefined) this.setState({ items: [] });
    else if (prevProps.items != undefined && this.props.items != undefined) {
      if (this.iguales(prevProps.items, this.props.items) == false) this.setState({ items: this.props.items });
    }
  }

  // Metodos Publicos del Componente

  cargar = (lista: Array<ItemListBox<T>>) => {
    this.setState({ items: lista });
  };

  agregar = (item: ItemListBox<T>) => {
    let items: Array<ItemListBox<T>> = this.state.items;
    debugger;
    items.push(item);
    this.setState({ items: items });
    if (this.props.onChangeList != undefined) this.props.onChangeList(this.getLista(items));
  };

  getLength = (): number => {
    return this.state.items.length;
  };

  // Metodos propios del Componente

  onSelect = (item: T | null) => {
    if (this.props.onSelect != undefined) this.props.onSelect(item);
  };

  onDeleteFinish = (item: ItemListBox<T>) => {
    let items: Array<ItemListBox<T>> = this.state.items;
    let lista: Array<ItemListBox<T>> = [];
    items.map((x) => {
      if (x.id != item.id) lista.push(x);
    });
    this.setState({ items: lista });

    if (this.props.onDeleteFinish != undefined) this.props.onDeleteFinish(item.objeto);

    if (this.props.onChangeList != undefined) this.props.onChangeList(this.getLista(lista));
  };

  onDeleteRequest = (item: T) => {
    if (this.props.onDelete != undefined) this.props.onDelete(item);
  };

  onDeleteSimple = (item: ItemListBox<T>) => {
    let items: Array<ItemListBox<T>> = this.state.items;
    let lista: Array<ItemListBox<T>> = [];
    items.map((x) => {
      if (x.id != item.id) {
        lista.push(x);
      }
    });
    this.setState({ items: lista });
    let x: T = item.objeto;
    if (this.props.onDeleteFinish != undefined) this.props.onDeleteFinish(x);

    if (this.props.onChangeList != undefined) this.props.onChangeList(this.getLista(lista));
  };

  onView = (item: T) => {
    if (this.props.onView) this.props.onView(item);
  };

  getLista = (items2: Array<ItemListBox<T>> | null): Array<T> => {
    let items: Array<ItemListBox<T>> = [];
    if (items2 == null) items = this.state.items;
    else items = items2!;

    let lista: Array<T> = [];
    items.forEach((x: ItemListBox<T>) => {
      lista.push(x.objeto);
    });
    return lista;
  };

  render() {
    return (
      <Flex container flexDirection="row" width={this.props.width || "100%"} height={this.props.height || "300px"}>
        <Contenedor borde={this.props.borde || "0px solid grey"} radioBorde="5px" overflowY="auto">
          <ListGroup>
            {this.state.items.map((item: ItemListBox<T>) => (
              <ListBoxItem<T>
                item={item}
                onSelect={this.onSelect}
                tipoItem={this.props.tipoItem}
                onDeleteFinish={this.onDeleteFinish}
                onDeleteRequest={this.onDeleteRequest}
                onDeleteSimple={this.onDeleteSimple}
                onView={this.onView}
              />
            ))}
          </ListGroup>
        </Contenedor>
      </Flex>
    );
  }
}

const Estilos = {
  contenedor: {
    display: "flex",
    flexDirection: "column",
    justifycontent: "flexstart",
    alignitems: "center",
  },
};

export default ListBox;
