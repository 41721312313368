import _ from "lodash";
import React, { Component } from "react";

import { CANALES_WEB_SOCKET, CriterioOrden, WebSocketService, Factura, FacturaService } from "serviciossaintmichel";
import TablaFacturas from "./TablaFacturas";
import { Global } from "../../../Global";
import { Badge, Loader } from "@mantine/core";
import { criteriosOrdenIguales } from "../../../compartido/utils/OrdenUtils";
import FacturasFilter from "./FacturasFilter";
import { formatISO } from "date-fns";
import Flex from "../../../libreria/appearance/flex/Flex";
import { TotalesFactura } from "serviciossaintmichel/lib/apisaintmichel/ventas/facturas/models/Factura";
import { PropsSelectGenericoConId } from "../../../compartido/components/select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../../../compartido/components/select/SelectBusqueda";
import SelectComponent from "../../../compartido/components/select/SelectComponent";
import { cumpleFiltros } from "../../../compartido/utils/filtros";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
  filtros?: FiltrosFacturas;
  filtrosLocales?: Array<Function>;
  version: VersionFacturasShow;
  propsSelect?: PropsSelectGenericoConId<Factura> & PropsSelectGeneral & { labelGetter?: (factura: Factura) => string };
}

export type VersionFacturasShow = "tabla" | "select";

export interface FiltrosFacturas {
  criterioOrden: CriterioOrden | null;
  limite: number;
  tipo?: string;
  estadoAFIP?: string;
  estadosCobro?: Array<string>;
  numeroFactura?: number;
  razonSocial?: string;
  clienteId?: number;
  facturaId?: number;
  fuente?: string;
  repartoId?: number;
  carteraId?: number;
  desdeFechaCreacion?: Date;
  hastaFechaCreacion?: Date;
}

interface State {
  facturas: Array<Factura> | null;
  totalesFactura: TotalesFactura | null;
  filtros: FiltrosFacturas;
}

const ordenInicial: CriterioOrden = { campo: "id", modo: "ASC" };
const PASO_LIMITE = 5;

export default class FacturasShow extends Component<Props, State> {
  private webSocket: WebSocket | null;

  private facturaService: FacturaService;

  constructor(props: Props) {
    super(props);
    const fechaHoy = new Date();

    this.state = {
      facturas: null,
      filtros: {
        criterioOrden: props.filtros ? props.filtros.criterioOrden : ordenInicial,
        limite: props.filtros ? props.filtros.limite : PASO_LIMITE,
        desdeFechaCreacion: props.filtros ? props.filtros.desdeFechaCreacion : fechaHoy,
        hastaFechaCreacion: props.filtros ? props.filtros.hastaFechaCreacion : fechaHoy,
        ...props.filtros,
      },
      totalesFactura: null,
    };

    this.webSocket = null;
    this.facturaService = new FacturaService(Global.UsuarioService.getToken()!);
  }

  componentWillUnmount = () => {
    this.webSocket?.close();
  };

  componentDidMount = () => {
    this.setFacturas();
    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_FACTURAS,
      funcionCallback: this.setFacturas,
    });
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      prevState.filtros.criterioOrden !== this.state.filtros.criterioOrden ||
      prevState.filtros.limite !== this.state.filtros.limite
    ) {
      this.setFacturas();
    }

    if (!_.isEqual(prevProps.filtros, this.props.filtros)) {
      const { filtros } = this.props;
      this.setState((prevState) => ({
        filtros: {
          ...prevState.filtros,
          criterioOrden: filtros ? filtros.criterioOrden : ordenInicial,
          limite: filtros ? filtros.limite : PASO_LIMITE,
        },
      }));
    }
  };

  setFacturas = async () => {
    const {
      limite,
      criterioOrden,
      desdeFechaCreacion,
      hastaFechaCreacion,
      tipo,
      razonSocial,
      estadoAFIP,
      estadosCobro,
      numeroFactura,
      clienteId,
      facturaId,
      fuente,
      repartoId,
      carteraId,
    } = this.state.filtros;

    try {
      const params = new URLSearchParams({
        limite: limite.toString(),
      });

      if (desdeFechaCreacion) {
        params.append("desde_fecha_creacion", formatISO(desdeFechaCreacion, { representation: "date" }));
      }

      if (hastaFechaCreacion) {
        params.append("hasta_fecha_creacion", formatISO(hastaFechaCreacion, { representation: "date" }));
      }

      if (criterioOrden) {
        const orden = criterioOrden.modo === "DESC" ? `-${criterioOrden.campo}` : criterioOrden.campo;
        params.append("orden", orden);
      }

      if (facturaId) {
        params.append("id", facturaId.toString());
      }

      if (tipo) {
        params.append("tipo", tipo);
      }

      if (razonSocial) {
        params.append("razon_social", razonSocial);
      }

      if (estadoAFIP) {
        params.append("estado_afip", estadoAFIP);
      }

      if (estadosCobro) {
        estadosCobro.forEach((estadoCobro) => {
          params.append("estado_cobro", estadoCobro);
        });
      }

      if (fuente) {
        params.append("fuente", fuente);
      }

      if (numeroFactura) {
        params.append("numero_factura", numeroFactura.toString());
      }

      if (clienteId) {
        params.append("cliente_id", clienteId.toString());
      }

      if (repartoId) {
        params.append("reparto_id", repartoId.toString());
      }

      if (carteraId) {
        params.append("cartera_id", carteraId.toString());
      }

      const [facturas, totalesFactura] = await Promise.all([
        this.facturaService.list(params),
        this.facturaService.totalizarFacturas(params),
      ]);

      this.setState({ facturas: facturas, totalesFactura: totalesFactura });
    } catch (error) {
      this.facturaService.manejarErrorHTTP(error, "Facturas");
    }
  };

  seleccionarCriterio = (criterio: CriterioOrden | null) => {
    const { criterioOrden } = this.state.filtros;

    if (criterioOrden === null || !criteriosOrdenIguales(criterioOrden, criterio)) {
      this.setState((prevState) => ({ filtros: { ...prevState.filtros, criterioOrden: criterio } }));
    } else {
      this.setState((prevState) => ({ filtros: { ...prevState.filtros, criterioOrden: null } }));
    }
  };

  aumentarLimite = () => {
    this.setState((prevState) => ({
      filtros: { ...prevState.filtros, limite: prevState.filtros.limite + PASO_LIMITE },
    }));
  };

  setFiltros = (valor: Record<string, any>) => {
    this.setState((prevState) => ({
      filtros: {
        ...prevState.filtros,
        ...valor,
      },
    }));
  };

  renderVersionSelect = (facturas: Array<Factura>) => {
    const { propsSelect } = this.props;

    if (!propsSelect) {
      return null;
    }

    return (
      <SelectComponent
        {...propsSelect}
        items={facturas}
        callbackParent={propsSelect.callbackParent}
        seleccionado={propsSelect.seleccionado}
        labelGetter={propsSelect.labelGetter ? propsSelect.labelGetter : (obj: Factura) => `Factura ${obj.id}`}
      />
    );
  };

  renderVersionTable = (facturas: Array<Factura>, totalesFactura: TotalesFactura) => {
    const { filtros } = this.state;
    const { onView } = this.props;
    return (
      <>
        <FacturasFilter setFiltros={this.setFiltros} busquedaHandler={this.setFacturas} filtros={filtros} />
        <TablaFacturas
          onView={onView}
          facturas={facturas}
          criterioOrdenActual={filtros.criterioOrden}
          criterioOrdenHandler={this.seleccionarCriterio}
          aumentarLimite={this.aumentarLimite}
        />
        <Flex justifyContent="space-between" marginTop="1rem">
          <Flex alignItems="center" width="auto" gap="5px">
            <strong>Monto total:</strong>
            <Badge color="green" radius="xs" variant="filled" size="lg">
              ${totalesFactura.monto_total}
            </Badge>
          </Flex>
          <Flex alignItems="center" width="auto" gap="5px">
            <strong>Facturas totales:</strong>
            <Badge color="dark" radius="xs" variant="filled" size="lg">
              {totalesFactura.cantidad_facturas}
            </Badge>
          </Flex>
        </Flex>
      </>
    );
  };

  render = () => {
    const { facturas, totalesFactura } = this.state;
    const { version, filtrosLocales } = this.props;

    if (facturas === null || totalesFactura === null) {
      return <Loader />;
    }

    // si hay filtros locales, chequea aquellas facturas que cumplen con todos los filtros
    const resultado = !filtrosLocales ? facturas : facturas.filter((factura) => cumpleFiltros(factura, filtrosLocales));

    const funcionRender = {
      tabla: () => this.renderVersionTable(resultado, totalesFactura),
      select: () => this.renderVersionSelect(resultado),
    };

    return funcionRender[version]();
  };
}
