import React, { Component } from "react";
import Ruta from "../../compartido/models/Ruta";
import { NavLink } from "react-router-dom";
import "./menu.css";
import "../template.css";
import {  Nav } from "react-bootstrap";
import MenuItemDropdown from "./MenuItemDropdown";
import Flex from "../../libreria/appearance/flex/Flex";




export interface Props {
  links:Array<Ruta>;
  nombre?:string
}


interface State {
}

export default class MenuItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // this.state = {};
  }
  

    
  render() {
    return (
      this.props.links.length > 1 ? 
      <MenuItemDropdown links={this.props.links} nombre={this.props.nombre!}/>
      : 
    
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="55px"
      marginLeft="5px">

    {/*
      <li className="nav-item">
      <a  style={{color: "#204399" ,fontSize:"19px" }} className="nav-link" href={this.props.links[0].path}>{this.props.links[0].name}</a>

      </li>
   */ }
      
      <Nav.Item>
        <NavLink className={"font"} style={{ textDecoration:"none",color: "#204399" ,fontSize:20,marginLeft:5 }} to={this.props.links[0].path}>{this.props.links[0].name}</NavLink>
      </Nav.Item>
      
    </Flex>
    
    )
  }


}

