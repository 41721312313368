import { Select, TextInput } from "@mantine/core";
import React, { Component } from "react";
import { Banco, CuentaBancaria, TipoCuentaBancaria } from "serviciossaintmichel";
import BancosShow, { Version } from "../../../../../../ventas/seccion-pagos/bancos/components/show/BancosShow";
import _ from "lodash";

export interface Props {
  cuentaBancaria: CuentaBancaria;
  onValidationChange: Function;
  onChange: Function;
}

interface State {
  valido: boolean;
  cuentaBancaria: CuentaBancaria;
}

export default class CuentaBancariaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cuentaBancaria: props.cuentaBancaria,
      valido: this.esValido(props.cuentaBancaria),
    };
  }

  componentDidMount(): void {
    this.props.onValidationChange(this.state.valido);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (!_.isEqual(prevState.cuentaBancaria, this.state.cuentaBancaria)) {
      this.setState({ valido: this.esValido(this.state.cuentaBancaria) });
      this.props.onChange(this.state.cuentaBancaria);
    }

    if (prevState.valido !== this.state.valido) {
      this.props.onValidationChange(this.state.valido);
    }
  }

  esValido = (cuentaBancaria: CuentaBancaria) => {
    const valido =
      cuentaBancaria.razon_social.length > 0 &&
      cuentaBancaria.cuit.length > 0 &&
      cuentaBancaria.nombre.length > 0 &&
      cuentaBancaria.apellido.length > 0 &&
      cuentaBancaria.banco.id > 0 &&
      cuentaBancaria.cliente > 0;

    return valido;
  };

  setTipoCuenta = (tipoCuenta: TipoCuentaBancaria) => {
    this.setState((prevState) => ({
      cuentaBancaria: { ...prevState.cuentaBancaria, tipo: tipoCuenta },
    }));
  };

  setRazonSocial = (razonSocial: string) => {
    this.setState((prevState) => ({
      cuentaBancaria: { ...prevState.cuentaBancaria, razon_social: razonSocial },
    }));
  };

  setCUIT = (cuit: string) => {
    this.setState((prevState) => ({
      cuentaBancaria: { ...prevState.cuentaBancaria, cuit: cuit },
    }));
  };

  setNombre = (nombre: string) => {
    this.setState((prevState) => ({
      cuentaBancaria: { ...prevState.cuentaBancaria, nombre: nombre },
    }));
  };

  setApellido = (apellido: string) => {
    this.setState((prevState) => ({
      cuentaBancaria: { ...prevState.cuentaBancaria, apellido: apellido },
    }));
  };

  setBanco = (banco: Banco | null) => {
    if (banco === null) {
      return;
    }

    this.setState((prevState) => ({
      cuentaBancaria: { ...prevState.cuentaBancaria, banco: banco },
    }));
  };

  getTiposCuentaBancaria = () => {
    const tipos = Object.values(TipoCuentaBancaria).map((tipo) => tipo);
    return tipos;
  };

  render = () => {
    const { cuentaBancaria } = this.state;

    return (
      <div>
        <Select
          data={this.getTiposCuentaBancaria()}
          value={cuentaBancaria.tipo}
          onChange={this.setTipoCuenta}
          label="Tipo de cuenta"
        />
        <TextInput
          value={cuentaBancaria.razon_social}
          label="Razón social"
          onChange={(event) => this.setRazonSocial(event.currentTarget.value)}
        />
        <TextInput
          value={cuentaBancaria.cuit}
          label="CUIT"
          onChange={(event) => this.setCUIT(event.currentTarget.value)}
        />
        <TextInput
          value={cuentaBancaria.nombre}
          label="Nombre"
          onChange={(event) => this.setNombre(event.currentTarget.value)}
        />
        <TextInput
          value={cuentaBancaria.apellido}
          label="Apellido"
          onChange={(event) => this.setApellido(event.currentTarget.value)}
        />
        <BancosShow
          version={Version.SELECT}
          propsSelect={{
            seleccionado: cuentaBancaria.banco,
            callbackParent: this.setBanco,
            sinTitulo: true,
          }}
        />
      </div>
    );
  };
}
