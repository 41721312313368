import React, { Component } from "react";
import { ServicioCliente } from "serviciossaintmichel";
import { Fuentes } from "../../../../../../compartido/estilos/estilos";
import Box from "../../../../../../libreria/containers/box/Box";
import H from "../../../../../../libreria/message/title/H";
import AlquilerClienteShow from "./alquileres/AlquilerClienteShow";
import ConsumoClienteShow from "./consumos/ConsumoClienteShow";
import OrdenCompraShow from "./ordenes-compra/OrdenCompraShow";



export interface Props {
  clienteId: number;
  servicioCliente: ServicioCliente;
  showDeleteBtn?: boolean;
}

interface State {}

export default class ServicioClienteShow extends Component<Props, State> {
  resolverComponente = (servicioCliente: ServicioCliente): Array<JSX.Element | null | string> => {
    const { showDeleteBtn = false } = this.props;
    let componente = null;
    let titulo = "";

    switch (servicioCliente.tipo) {
      case "alquiler": {
        componente = <AlquilerClienteShow alquilerClienteId={servicioCliente.id} showDeleteBtn={showDeleteBtn} />;
        titulo = "Alquiler";
        break;
      }
      case "consumo": {
        componente = <ConsumoClienteShow consumoClienteId={servicioCliente.id} showDeleteBtn={showDeleteBtn} />;
        titulo = "Consumo";
        break;
      }
      case "orden_compra": {
        componente = <OrdenCompraShow ordenCompraId={servicioCliente.id} showDeleteBtn={showDeleteBtn} />;
        titulo = "Orden de compra";
        break;
      }
      default: {
        break;
      }
    }

    return [componente, titulo];
  };

  render = () => {
    const [componente, titulo] = this.resolverComponente(this.props.servicioCliente);

    if (componente === null) {
      return null;
    }

    return (
      <Box width={"auto"} paddingVertical={"2rem"} paddingHorizontal={"2rem"}>
        <H size={4} fuente={Fuentes.Titulos} texto={titulo?.toString()} />
        {componente}
      </Box>
    );
  };
}
