import React, { Component } from "react";
import { Table } from "react-bootstrap";
import VentaMaquinaProgramacionEntregaLoad from "./VentaMaquinaProgramacionEntregaLoad";
import { EstadoLogisticaPaquete, VentaProgramacionMaquina } from "serviciossaintmichel";
import { Select } from "@mantine/core";

interface ConfigCampo {
  disabled: Array<string>;
  hidden: Array<string>;
}

export interface Props {
  crearNuevos?: boolean;
  configCampos?: ConfigCampo;
  ventasMaquina: Array<VentaProgramacionMaquina>;
  onChange?: Function;
}

interface State {
  ventasMaquina: Array<VentaProgramacionMaquina>;
}

export default class VentasMaquinaProgramacionEntregaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ventasMaquina: props.ventasMaquina,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (prevProps.ventasMaquina !== this.props.ventasMaquina && this.state.ventasMaquina !== this.props.ventasMaquina) {
      this.setState({ ventasMaquina: this.props.ventasMaquina });
    }

    if (prevState.ventasMaquina !== this.state.ventasMaquina && this.props.onChange) {
      this.props.onChange(this.state.ventasMaquina);
    }
  }

  eliminarVentaMaquina = (idMaquina: number) => {
    const ventasMaquina = this.state.ventasMaquina.filter(
      (vm: VentaProgramacionMaquina) => vm.paquete.maquina.id !== idMaquina
    );
    this.setState({
      ventasMaquina: [...ventasMaquina],
    });
  };

  agregarVentaMaquina = (nuevaVentaMaquina: VentaProgramacionMaquina): void => {
    this.setState((prevState) => ({
      ventasMaquina: [...prevState.ventasMaquina, nuevaVentaMaquina],
    }));
  };

  actualizarVentaMaquina = (infoVenta: VentaProgramacionMaquina) => {
    const ventas = this.state.ventasMaquina.map((vm) => (vm.paquete.id === infoVenta.paquete.id ? infoVenta : vm));
    this.setState({
      ventasMaquina: ventas,
    });
  };

  estaDeshabilitado = (id: string): boolean => {
    if (!this.props.configCampos) {
      return false;
    }

    return this.props.configCampos.disabled.indexOf(id) !== -1;
  };

  render() {
    const { ventasMaquina } = this.state;
    const { crearNuevos } = this.props;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Máquina</th>
              <th>Cantidad</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {ventasMaquina.map((ventaMaquina, idx) => (
              <tr key={ventaMaquina.paquete.maquina.id}>
                <td>{ventaMaquina.paquete.maquina.nombre}</td>
                <td>{ventaMaquina.paquete.cantidad}</td>
                <td>
                  <Select
                    data={[
                      EstadoLogisticaPaquete.ENTREGADO,
                      EstadoLogisticaPaquete.NO_ENTREGADO,
                      EstadoLogisticaPaquete.RECHAZADO,
                    ]}
                    value={ventaMaquina.estado}
                    onChange={(seleccionado: EstadoLogisticaPaquete) => {
                      this.actualizarVentaMaquina({
                        ...ventaMaquina,
                        estado: seleccionado,
                      });
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {crearNuevos ? (
          <VentaMaquinaProgramacionEntregaLoad
            onSubmitCallback={this.agregarVentaMaquina}
            excluirMaquinas={ventasMaquina.map((vm) => vm.paquete.maquina.id)}
          />
        ) : null}
      </>
    );
  }
}
