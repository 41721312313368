import React, { Component } from "react";
import "../template.css";
import * as $ from "jquery";
import InputGenerico from "../../compartido/components/input/InputGenerico";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import Flex from "../../libreria/appearance/flex/Flex";
import "./login.css";
import Box from "../../libreria/containers/box/Box";

import {Global} from "../../Global"
import Background from "../../compartido/components/background/Background";




export default class Login extends Component {
  render() {
    return (

      <Background>

      <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      
      >
      
      <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
   
      >
      
      <Box
      width="533px"
      height="690px"
      >

      <div className="loginBackground">

        <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"

        >
          
            <div className="form-group">
              <label htmlFor="usuario">Usuario</label>
              <InputGenerico
                style={{ margin: "3px",fontSize:"17px" }}
                type="text"
                id="usuario"
                name="usuario"
                placeholder="Ingrese su usuario"
                callbackOnEnter={this.logearse.bind(this)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="clave">Clave</label>
              <InputGenerico
                style={{ margin: "3px",fontSize:"17px" }}
                type="password"
                id="clave"
                name="clave"
                placeholder="Ingrese su clave"
                callbackOnEnter={this.logearse.bind(this)}
              />
            </div>


            <Button variant="primary" onClick={() => this.logearse()} style={{ alignSelf: "center",marginTop:"5px",fontSize:"17px" }}>
              Ingresar
            </Button>

            </Flex>


          </div>

      </Box>

      

      </Flex>





      </Flex>
      </Background>


      
    );
  }

  async logearse() {
    const credenciales = {
      username: $("#usuario").val(),
      password: $("#clave").val(),
    };
    const logueadoLocal = await Global.UsuarioService.logearUsuario(credenciales);
    if (logueadoLocal === true) {
      window.localStorage.setItem("token", Global.UsuarioService.getToken());

      if (this.props.location.state) this.props.history.replace(this.props.location.state.from);
      else this.props.history.replace("/");
    } else {
      Swal.fire({
        text: "Usuario o contraseña invalidos",
        icon: "error",
      });
    }
  }
}
