import React, { Component } from "react";

import { CANALES_WEB_SOCKET, TipoCliente, TipoClienteService, WebSocketService } from "serviciossaintmichel";
import { PropsSelectGenerico } from "../../../../../../../compartido/components/select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../../../../../../../compartido/components/select/SelectBusqueda";
import { Global } from "../../../../../../../Global";
import Flex from "../../../../../../../libreria/appearance/flex/Flex";
import SelectComponent from "../../../../../../../compartido/components/select/SelectComponent";
import { SelectProps } from "@mantine/core";

export interface Props {
  version: VersionTiposClientesShow;
  propsSelect?: PropsSelectGenerico<TipoCliente> & PropsSelectGeneral & Omit<SelectProps, "data">;
}

export enum VersionTiposClientesShow {
  SELECT,
}

interface State {
  tiposCliente: Array<TipoCliente> | null;
}

export default class TiposClienteShow extends Component<Props, State> {
  private tipoClienteService: TipoClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      tiposCliente: null,
    };

    this.tipoClienteService = new TipoClienteService(Global.UsuarioService.getToken()!);
  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_TIPO_CLIENTES,
      funcionCallback: this.getTiposCliente,
    });
    this.getTiposCliente();
  }

  getTiposCliente = async () => {
    try {
      const tiposCliente = await this.tipoClienteService.listAll();
      this.setState({ tiposCliente: tiposCliente });
    } catch (error) {
      this.tipoClienteService.manejarErrorHTTP(error, "TipoCliente");
    }
  };

  render() {
    const { tiposCliente } = this.state;
    const { propsSelect, version } = this.props;

    if (tiposCliente === null) {
      return null;
    }

    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {version === VersionTiposClientesShow.SELECT && propsSelect ? (
          <SelectComponent
            {...propsSelect}
            items={tiposCliente}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado ? propsSelect.seleccionado : null}
            labelGetter={(obj: TipoCliente) => obj.tipo}
          />
        ) : null}
      </Flex>
    );
  }
}
