import React, { Component } from "react";
import { CANALES_WEB_SOCKET, ConsumoCliente, ConsumoClienteService, WebSocketService } from "serviciossaintmichel";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import RequestButton from "../../../../../../../compartido/components/request-button/RequestButton";
import { Global } from "../../../../../../../Global";
import ConsumoClienteLoad from "./ConsumoClienteLoad";





export interface Props {
  clienteId: number;
  fechaInicioMin?: Date;
}

interface State {
  consumoCliente: ConsumoCliente | null;
  consumoClienteValido: boolean;
}

export default class ConsumoClienteNew extends Component<Props, State> {

  private consumoClienteService:ConsumoClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      consumoCliente: null,
      consumoClienteValido: false,
    };

    this.consumoClienteService = new ConsumoClienteService(Global.UsuarioService.getToken()!);

  }

  handleSubmit = async () => {
    const payload = this.state.consumoCliente;

    if (payload === null) {
      return;
    }

    try {
      await this.consumoClienteService.create(payload);
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_SERVICIO_ACTIVO,
        {
          clienteId: this.props.clienteId,
        }
      );
    } catch (error) {
      this.consumoClienteService.manejarErrorHTTP(error, "ConsumoCliente");
    }
  };

  onChange = (consumoCliente: ConsumoCliente) => {
    this.setState({ consumoCliente: consumoCliente });
  };

  onValidationChange = (consumoClienteValido: boolean) => {
    this.setState({ consumoClienteValido: consumoClienteValido });
  };

  render = () => {
    const { consumoClienteValido } = this.state;
    const { clienteId, fechaInicioMin } = this.props;

    return (
      <>
        <ConsumoClienteLoad
          consumoCliente={null}
          consumo={null}
          clienteId={clienteId}
          onChange={this.onChange}
          onValidationChange={this.onValidationChange}
          fechaInicioMin={fechaInicioMin}
        />
        <Flex container justifyContent="center" marginTop="1rem">
          <RequestButton
            propsBoton={{ disabled: !consumoClienteValido, variant: "success" }}
            onClick={this.handleSubmit}
            texto={"Guardar"}
          />
        </Flex>
      </>
    );
  };
}
