import React, { Component, ChangeEvent } from "react";
import { Form } from "react-bootstrap";
import { Data, Font } from "../models/models";

interface Props {
  onChange: Function;
  selected?: number;
  elements: Array<Data>;
  disabled?:boolean;
  font?:Font;
  loading?:boolean;
}

interface State 
{
selected:number;
elements:Array<Data>;
disabled:boolean;
loading:boolean;
notSelected:boolean;
}


export default class Select extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let selected:number = this.props.selected ? this.props.selected : -1;
    this.state = 
    {
      selected:selected,
      elements:this.props.elements,
      disabled:this.props.disabled ? this.props.disabled : false,
      loading:this.props.loading ? this.props.loading : false,
      notSelected:this.isNotSelected(selected,this.props.elements)
    }
  }

  onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    let id:number = parseInt(event.target.value);
    this.setState({selected:id});
    this.props.onChange(id);
  };



  isNotSelected = (selected:number,elements:Array<Data>):boolean => 
  {
  return elements.filter((x) => x.id == selected).length > 0 ? false : true;
  }


  iguales = (elementsOld:Array<Data>,elementsNew:Array<Data>):boolean => 
  {
    if(elementsOld.length==elementsNew.length)
    {
      let aux=true;
      elementsOld.map((x)=> {
        if(elementsNew.filter((y)=> (y.id == x.id && y.text==x.text) ).length == 0 ) aux=false;
        });
      return aux;

    }
    else return false;
  }



  componentDidUpdate = (prevProps: Props) => {
    
    if (prevProps.selected != undefined && this.props.selected == undefined) {
      this.setState({ selected: -1 ,notSelected:true});
    }
    else if (prevProps.selected == undefined && this.props.selected != undefined) {
      this.setState({ selected: this.props.selected,notSelected:this.isNotSelected(this.props.selected,this.state.elements) });
    }
    else if (prevProps.selected != undefined && this.props.selected != undefined) {
      if (prevProps.selected != this.props.selected)
        this.setState({ selected: this.props.selected ,notSelected:this.isNotSelected(this.props.selected,this.state.elements)});
    }
    else { }


    if (prevProps.disabled != undefined && this.props.disabled == undefined) {
      this.setState({ disabled:false});
    }
    else if (prevProps.selected == undefined && this.props.disabled != undefined) {
      this.setState({ disabled: this.props.disabled });
    }
    else if (prevProps.disabled != undefined && this.props.disabled != undefined) {
      if (prevProps.disabled != this.props.disabled)
        this.setState({ disabled: this.props.disabled});
    }
    else { }




    if (this.iguales(prevProps.elements,this.props.elements)==false)
    {
    this.setState({ elements: this.props.elements,notSelected:this.isNotSelected(this.state.selected,this.props.elements) })
      
    }


  }


  render() {
    return (
        <Form.Group style={{ width:"100%" }}>
            {this.state.loading ? 
            
            <Form.Control disabled={true} as="select">
                <option
                  selected={this.state.notSelected}
                  id={"-1"}
                  value={"-1"}
                  style={{ width:"100%" }}
                  > 
                  {"Cargando ... "}
                </option>
            </Form.Control>
            
            :
            <Form.Control onChange={this.onChange} disabled={this.state.disabled} as="select">
              {this.state.elements.map((element:Data) => ( 
                <option
                  selected={element.id === this.state.selected ? true : false}
                  id={element.id.toString()}
                  value={element.id.toString()}
                  style={{ width:"100%" }}
                >
                  {element.text}
                </option>
              ))}

                <option
                  selected={this.state.notSelected}
                  id={"-1"}
                  value={"-1"}
                  style={{ width:"100%" }}
                >
                  {"Not Selected"}
                </option>
            </Form.Control>
            }
        </Form.Group>
    );
  }
}
