export interface Identificable {
  id: number;
}

export function excluirIds(exclusiones: Array<number>): Function {
  return (item: Identificable) => exclusiones.indexOf(item.id) === -1;
}

export function soloIds(inclusiones: Array<number>): Function {
  return (item: Identificable) => inclusiones.indexOf(item.id) !== -1;
}

// retorna verdadero si todos los filtros a aplicados sobre el elemento retornan verdadero, caso contrario retorna falso
export function cumpleFiltros(elemento: any, filtros: Array<Function>): boolean {
  return filtros.every((fnc) => fnc(elemento));
}
