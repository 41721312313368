import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Visibilizador from "../../../../../../libreria/action/visibilizador/Visibilizador";
import Flex from "../../../../../../libreria/appearance/flex/Flex";


import { AlquilerService, CANALES_WEB_SOCKET,   MaquinaSimbolicaAlquilerGet, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";



export interface Props {
  id: number;
}
interface State {
  maquinas: Array<MaquinaSimbolicaAlquilerGet>;
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
}

export default class MaquinasAlquilerShow extends Component<Props, State> {

  private alquilerService:AlquilerService;

  constructor(props: Props) {
    super(props);
    this.state = {
      maquinas: [],
      visible: false,

      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES_MAQUINAS,
      funcionCallback: this.onChannelMesagge,
    });

    this.alquilerService =  new AlquilerService(Global.UsuarioService.getToken()!);

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {
    const maquinas = await this.alquilerService.getMaquinas(this.props.id);
    this.setState({ maquinas: maquinas, cargando: false });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    this.setState({ cargando: true });
    const maquinas = await this.alquilerService.getMaquinas(this.props.id);
    this.setState({ maquinas: maquinas, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({ cargando: true });
      const maquinas = await this.alquilerService.getMaquinas(this.props.id);
      this.setState({ maquinas: maquinas, cargando: false });
    }
  };

  renderMaquinas = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      {this.renderizarTabla()}
    </Flex>
  );

  renderizarFilas = () => {
    return this.state.maquinas.map((maquina, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{maquina.maquina_simbolica.nombre}</td>
          <td className="text-center">{maquina.cantidad}</td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Maquina</th>
            <th className="text-center">Cantidad</th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  renderLoading = () => "Cargando Productos";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="20px"
      >
        <Visibilizador
          onChange={this.onChangeVisibilizador}
          label={"Maquinas"}
          labelAlignment={"flex-start"}
        >
          {this.state.cargando ? this.renderLoading() : this.renderMaquinas()}
        </Visibilizador>
      </Flex>
    );
  }
}
