import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { CargamentoLectura,CANALES_WEB_SOCKET, RepartoDiarioService, WebSocketService } from "serviciossaintmichel";
import Swal from "sweetalert2";
import ManejadorErroresHTTP from "../../../../compartido/utils/ManejadorErroresHTTP";
import { Global } from "../../../../Global";
import CargaLoad from "../load/CargaLoad";




export interface Props {
  idRepartoDiario: number;
}

interface State {
  cargaFormulario: CargamentoLectura;
  cargaValida: boolean;
}

export default class CargaNew extends Component<Props, State> {

  private repartoDiarioService:RepartoDiarioService;

  constructor(props: Props) {
    super(props);
    this.state = {
      cargaFormulario: this.nuevaCarga(),
      cargaValida: false,
    };

    this.repartoDiarioService =  new RepartoDiarioService(Global.UsuarioService.getToken()!);

  }

  nuevaCarga = (): CargamentoLectura => {
    return {
      id: -1,
      cargas_maquina_simbolica: [],
      cargas_bulto_producto: [],
      cargas_producto: [],
      fecha_creacion: new Date(),
      fecha_modificacion: new Date(),
      es_descarga: false,
      momento_realizacion: null,
      reparto_diario: this.props.idRepartoDiario,
    };
  };

  onChange = (carga: CargamentoLectura) => {
    this.setState({ cargaFormulario: carga });
  };

  onValidezChange = (validez: boolean) => {
    this.setState({ cargaValida: validez });
  };

  guardarCambios = async () => {
    try {
      await this.repartoDiarioService.crearCarga(this.props.idRepartoDiario, this.state.cargaFormulario);
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS, {
        idRepartoDiario: this.props.idRepartoDiario,
      });
      this.setState({ cargaFormulario: this.nuevaCarga() });
      Swal.fire({
        icon: "success",
        text: "Datos guardados con éxito",
      });
    } catch (error) {
      const msjError = ManejadorErroresHTTP.getMensajeError(error);
      Swal.fire({
        icon: "error",
        text: "Ocurrió un error - " + msjError,
      });
    }
  };

  render() {
    const { cargaFormulario, cargaValida } = this.state;

    return (
      <div>
        <CargaLoad carga={cargaFormulario} onChange={this.onChange} onValidezChange={this.onValidezChange} />
        <Button variant="success" style={{ marginTop: "1rem" }} disabled={!cargaValida} onClick={this.guardarCambios}>
          Guardar
        </Button>
      </div>
    );
  }
}
