import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Email } from "serviciossaintmichel";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import Input, { InputTypes } from "../../../../../../../libreria/data/input/Input";

interface Props {
  clienteId: number;
  email: Email | null;
  onChange?: Function;
  onChangeValidation?: Function;
}

interface State {
  email: Email;
  estado: boolean;
}

export default class EmailClienteLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: props.email || this.crearEmail(),
      estado: false,
    };
  }

  crearEmail(): Email {
    return {
      id: -1,
      cliente: this.props.clienteId,
      email: "",
      descripcion: "",
    };
  }

  componentDidMount = async () => {
    this.updateValidacion();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { estado, email } = this.state;
    const { onChangeValidation, onChange } = this.props;

    if (prevProps.email !== this.props.email) {
      if (this.props.email === null) {
        this.setState({ email: this.crearEmail() });
      } else {
        this.setState({ email: this.props.email });
      }
    }

    if (prevState.estado !== estado) {
      if (onChangeValidation) {
        onChangeValidation(estado);
      }
    }

    if (prevState.email !== email) {
      this.updateValidacion();
      if (onChange) {
        onChange(email);
      }
    }
  };

  esValido = (): boolean => {
    const { email } = this.state;
    return email.email !== "" && email.descripcion !== "";
  };

  updateValidacion = () => {
    let validacion = this.esValido();
    this.setState({ estado: validacion });
  };

  setearEmail = (email: string) => {
    this.setState({
      email: {
        ...this.state.email,
        email: email,
      },
    });
  };

  setearDescripcion = (desc: string) => {
    this.setState({
      email: {
        ...this.state.email,
        descripcion: desc,
      },
    });
  };

  render() {
    const { email } = this.state;

    return (
      <Flex container flexDirection="column">
        <Flex marginTop="15px" container flexDirection="column" alignItems="center" justifyContent="center">
          <Flex container flexDirection="column">
            <Form.Group style={{ width: "100%" }}>
              <Form.Label htmlFor="descripcion">Email</Form.Label>
              <Input
                type={InputTypes.Email}
                data={email.email}
                placeholder="Email"
                onChange={(email: string) => this.setearEmail(email)}
              />
            </Form.Group>
          </Flex>

          <Flex container flexDirection="column">
            <Form.Group style={{ width: "100%" }}>
              <Form.Label htmlFor="descripcion">Descrición</Form.Label>
              <Input
                type={InputTypes.Character}
                data={email.descripcion}
                placeholder="Descripción"
                onChange={(desc: string) => this.setearDescripcion(desc)}
              />
            </Form.Group>
          </Flex>
        </Flex>
      </Flex>
    );
  }
}
