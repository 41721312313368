import React, { Component, FormEvent } from "react";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";
import SelectProducto from "../../../productos/Producto/components/SelectProducto";
import { Table, Col, Row, Form, Container } from "react-bootstrap";
import { CANALES_WEB_SOCKET, MaquinaServicioSimbolicaGet, MaquinaServicioSimbolicaService, ProductoGet, ProductoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  maquinaServicioSimbolicaId: number;
}

interface State {
  maquinaServicioSimbolica: MaquinaServicioSimbolicaGet;
  productosCandidatos: Array<ProductoGet>;
  alerta: any;
  producto: ProductoGet | null;
}

export default class MaquinaServicioSimbolicaActualizarProductos extends Component<
  Props,
  State
> {

  private maquinaServicioSimbolicaService:MaquinaServicioSimbolicaService;
  private productoService:ProductoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      maquinaServicioSimbolica: this.crearMaquinaServicioSimbolicaVacio(),
      productosCandidatos: [],
      producto: null,
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };

    this.maquinaServicioSimbolicaService = new MaquinaServicioSimbolicaService(Global.UsuarioService.getToken()!);
    this.productoService = new ProductoService(Global.UsuarioService.getToken()!);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS,
      funcionCallback: this.cargarProductos,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS,
      funcionCallback: this.cargarMaquinaServicioSimbolicaCanal,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_SIMBOLICA,
      funcionCallback: this.cargarMaquinaServicioSimbolicaCanal,
    });
  }

  async componentDidMount() {
    await this.cargarMaquinaServicioSimbolica(
      this.props.maquinaServicioSimbolicaId
    );
    this.cargarProductos();
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      await this.cargarMaquinaServicioSimbolica(
        this.props.maquinaServicioSimbolicaId
      );
      this.cargarProductos();
    }
  }

  crearMaquinaServicioSimbolicaVacio(): MaquinaServicioSimbolicaGet {
    const maquinaServicioSimbolica: MaquinaServicioSimbolicaGet = {
      id: 0,
      nombre: "",
      productos: [],
      proveedores: [],
    };
    return maquinaServicioSimbolica;
  }

  cargarMaquinaServicioSimbolicaCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.maquinaServicioSimbolica &&
      dato.message.maquinaServicioSimbolicaId ==
        this.state.maquinaServicioSimbolica.id
    ) {
      await this.cargarMaquinaServicioSimbolica(
        this.props.maquinaServicioSimbolicaId
      );
      this.cargarProductos();
    }
    if (
      this.state.maquinaServicioSimbolica &&
      this.state.maquinaServicioSimbolica.productos.find(
        (i) => i.id === dato.message.productoId
      )
    ) {
      await this.cargarMaquinaServicioSimbolica(
        this.props.maquinaServicioSimbolicaId
      );
      this.cargarProductos();
    }
  };

  cargarMaquinaServicioSimbolica = async (id: number) => {
    const resMaquinaServicioSimbolica =
      await this.maquinaServicioSimbolicaService.get(id).catch((error) => {
        this.maquinaServicioSimbolicaService.manejarErrorHTTP(
          error,
          "Máquina Servicio Simbólica"
        );
        return null;
      });
    if (resMaquinaServicioSimbolica != null) {
      this.setState({
        maquinaServicioSimbolica: resMaquinaServicioSimbolica,
        alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
      });
    }
  };

  cargarProductos = async () => {
    let productos = await this.productoService.listAll().catch((error) => {
      this.productoService.manejarErrorHTTP(error, "Producto");
      return null;
    });
    if (productos != null) {
      this.actualizarProductosCandidatos(productos);
    }
  };

  actualizarProductosCandidatos = (productos: Array<ProductoGet>) => {
    const relacionados = this.state.maquinaServicioSimbolica.productos.map(
      (producto) => producto.id
    );
    let productosCandidatos = productos.filter(
      (producto) => !relacionados.includes(producto.id)
    );
    this.setState({
      productosCandidatos: productosCandidatos,
      producto: productosCandidatos[0],
    });
  };

  setearProducto = (producto: ProductoGet) => {
    this.setState({ producto: producto });
  };

  eliminarProducto = (producto: ProductoGet) => {
    this.maquinaServicioSimbolicaService.eliminarRelacionConProducto(
      this.state.maquinaServicioSimbolica.id,
      producto.id
    )
      .then((result) => {
        {
          WebSocketService.enviarMensaje(
            CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_SIMBOLICA,
            {
              maquinaServicioSimbolicaId:
                this.state.maquinaServicioSimbolica.id,
            }
          );
          this.cargarProductos();
        }
      })
      .catch((error) => {
        this.maquinaServicioSimbolicaService.manejarErrorHTTP(
          error,
          "Máquina Servicio Simbólica"
        );
      });
  };

  agregarProducto = (producto: ProductoGet) => {
    this.maquinaServicioSimbolicaService.relacionarConProducto(
      this.state.maquinaServicioSimbolica.id,
      producto.id
    )
      .then((result) => {
        {
          WebSocketService.enviarMensaje(
            CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_SIMBOLICA,
            {
              maquinaServicioSimbolicaId:
                this.state.maquinaServicioSimbolica.id,
            }
          );
        }
      })
      .catch((error) => {
        this.maquinaServicioSimbolicaService.manejarErrorHTTP(
          error,
          "Máquina Servicio Simbólica"
        );
      });
    this.cargarProductos();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.producto ? false : true;
  };

  renderizarFormulario = () => {
    if (
      this.state.productosCandidatos.length < 1 &&
      this.state.maquinaServicioSimbolica.id == 0
    ) {
      return null;
    }
    return (
      <Form>
        <Form.Group>
          <Form.Label>Producto</Form.Label>
          <SelectProducto
            productos={this.state.productosCandidatos}
            seleccionado={
              this.state.producto
                ? this.state.producto
                : this.state.productosCandidatos[0]
            }
            callbackParent={(producto: ProductoGet) => {
              this.setearProducto(producto);
            }}
          />
        </Form.Group>
        <Form.Group>
          <RequestButton
            texto="Añadir"
            propsBoton={{
              variant: "info",
              disabled: !!!this.state.producto,
            }}
            onClick={() => {
              if (this.state.producto)
                return this.agregarProducto(this.state.producto);
            }}
          />
        </Form.Group>
      </Form>
    );
  };

  renderizarFilas = () => {
    return this.state.maquinaServicioSimbolica.productos.map(
      (producto, index) => {
        return (
          <tr>
            <td>{index}</td>
            <td>{producto.nombre}</td>
            <td>{producto.tipo_producto.tipo}</td>
            <td>
              <RequestButton
                texto="Eliminar"
                confirmacion={true}
                propsBoton={{
                  variant: "danger",
                }}
                onClick={() => this.eliminarProducto(producto)}
              />
            </td>
          </tr>
        );
      }
    );
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true}>
        <thead>
          <tr>
            <th>#</th>
            <th>Producto</th>
            <th>Tipo de Producto</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  render() {
    return (
      <Container>
        <Row>
          <Col md={{ span: 6 }}>{this.renderizarFormulario()}</Col>
        </Row>
        <Row>
          <Col>{this.renderizarTabla()}</Col>
        </Row>
      </Container>
    );
  }
}
