import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";

import { CANALES_WEB_SOCKET, TipoCombustibleGet, TipoCombustiblePost, TipoCombustibleService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";







export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  tipoCombustibles: Array<TipoCombustibleGet>;
}

export default class TipoCombustibleLista extends Component<Props, State> {
  private tipoCombustibleService:TipoCombustibleService;

  constructor(props: Props) {
    super(props);
    this.state = {
      tipoCombustibles: [],
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_COMBUSTIBLES,
      funcionCallback: this.getElementos,
    });

    this.tipoCombustibleService = new TipoCombustibleService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.tipoCombustibleService.list()
      .then((result) => {
        this.setState({
          tipoCombustibles: result,
        });
      })
      .catch((error) => {
        this.tipoCombustibleService.manejarErrorHTTP(error, "Tipo Combustible");
      });
  };

  componentDidMount() {
    this.tipoCombustibleService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }

  armarFilas(
    tipo_combustibles: Array<TipoCombustibleGet>
  ): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (tipo_combustibles.length > 0) {
      for (let i = 0; i < tipo_combustibles.length; i++) {
        let fila: Array<string> = [];
        fila.push(tipo_combustibles[i].tipo);
        let filaResult: FilaTablaGenerica = {
          id: tipo_combustibles[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Elementos.Vehiculos.TipoCombustible.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.tipoCombustibleService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.tipoCombustibles)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.tipoCombustibleService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaTipoCombustibles"
      />
    );
  }
}
