import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Fuentes } from "../../estilos/estilos";

interface Props {
  estado?: boolean;
  label: string;
  onChange: Function;
}

interface State {
  estado: boolean;
}

class CheckBox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      estado: this.props.estado ? this.props.estado : false,
    };
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.estado != this.props.estado)
      this.setState({ estado: this.props.estado! });
  };

  onChange = () => {
    let x = this.state.estado;
    x = !x;
    this.setState({ estado: x });
    this.props.onChange(x);
  };

  render() {
    return (
      <Form.Group>
        <Form.Check
          checked={this.state.estado}
          isValid={this.state.estado}
          onChange={this.onChange}
          type="checkbox"
          label={this.props.label}
          style={{ fontSize: 19, fontFamily: Fuentes.Labels }}
        />
      </Form.Group>
    );
  }
}

export default CheckBox;
