import React, { Component } from "react";

import { CANALES_WEB_SOCKET, WebSocketService, CuentaBancaria, CuentaBancariaService } from "serviciossaintmichel";
import { Loader, SelectProps } from "@mantine/core";
import { PropsSelectGenericoConId } from "../../../../../../compartido/components/select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../../../../../../compartido/components/select/SelectBusqueda";
import { Global } from "../../../../../../Global";
import SelectComponent from "../../../../../../compartido/components/select/SelectComponent";

export interface Props {
  filtros?: URLSearchParams;
  version: VersionCuentasBancariasShow;
  propsSelect?: PropsSelectGenericoConId<CuentaBancaria> & PropsSelectGeneral & Omit<SelectProps, "data">;
}

export type VersionCuentasBancariasShow = "select";

interface State {
  cuentasBancarias: Array<CuentaBancaria> | null;
  filtros: URLSearchParams | null;
}

export default class CuentasBancariasShow extends Component<Props, State> {
  private webSocket: WebSocket | null;

  private cuentaBancariaService: CuentaBancariaService;

  constructor(props: Props) {
    super(props);

    this.state = {
      cuentasBancarias: null,
      filtros: props.filtros ?? null,
    };

    this.webSocket = null;
    this.cuentaBancariaService = new CuentaBancariaService(Global.UsuarioService.getToken()!);
  }

  componentWillUnmount = () => {
    this.webSocket?.close();
  };

  componentDidMount = () => {
    this.setCuentasBancarias();
    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_CUENTAS_BANCARIAS,
      funcionCallback: this.setCuentasBancarias,
    });
  };

  setCuentasBancarias = async () => {
    try {
      const cuentasBancarias: Array<CuentaBancaria> = await this.cuentaBancariaService.list(this.props.filtros);

      this.setState({ cuentasBancarias: cuentasBancarias });
    } catch (error) {
      this.cuentaBancariaService.manejarErrorHTTP(error, "Cuentas Bancarias");
    }
  };

  render = () => {
    const { cuentasBancarias } = this.state;
    const { version, propsSelect } = this.props;

    if (cuentasBancarias === null) {
      return <Loader />;
    }

    switch (version) {
      case "select": {
        if (!propsSelect) {
          return null;
        }

        return (
          <SelectComponent
            {...propsSelect}
            items={cuentasBancarias}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado}
            labelGetter={(obj: CuentaBancaria) => `${obj.cuit} - ${obj.banco.nombre}`}
          />
        );
      }
      default: {
        return null;
      }
    }
  };
}
