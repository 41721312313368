import React, { Component } from "react";

import { GrupoTipoCliente, GrupoTipoClienteService } from "serviciossaintmichel";
import { PropsSelectGenerico } from "../../../../../../../compartido/components/select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../../../../../../../compartido/components/select/SelectBusqueda";
import { Global } from "../../../../../../../Global";
import Flex from "../../../../../../../libreria/appearance/flex/Flex";
import SelectComponent from "../../../../../../../compartido/components/select/SelectComponent";
import { SelectProps } from "@mantine/core";

export interface Props {
  version: VersionGruposTipoClientesShow;
  propsSelect?: PropsSelectGenerico<GrupoTipoCliente> & PropsSelectGeneral & Omit<SelectProps, "data">;
}

export enum VersionGruposTipoClientesShow {
  SELECT,
}

interface State {
  gruposTipoCliente: Array<GrupoTipoCliente> | null;
}

export default class GruposTipoClienteShow extends Component<Props, State> {
  private grupoTipoClienteService: GrupoTipoClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      gruposTipoCliente: null,
    };

    this.grupoTipoClienteService = new GrupoTipoClienteService(Global.UsuarioService.getToken()!);
  }

  componentDidMount() {
    this.getGruposTipoCliente();
  }

  getGruposTipoCliente = async () => {
    try {
      const gruposTipoCliente = await this.grupoTipoClienteService.listAll();
      this.setState({ gruposTipoCliente: gruposTipoCliente });
    } catch (error) {
      this.grupoTipoClienteService.manejarErrorHTTP(error, "GrupoTipoCliente");
    }
  };

  render() {
    const { gruposTipoCliente } = this.state;
    const { propsSelect, version } = this.props;

    if (gruposTipoCliente === null) {
      return null;
    }

    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {version === VersionGruposTipoClientesShow.SELECT && propsSelect ? (
          <SelectComponent
            {...propsSelect}
            items={gruposTipoCliente}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado ? propsSelect.seleccionado : null}
            labelGetter={(obj: GrupoTipoCliente) => obj.nombre}
          />
        ) : null}
      </Flex>
    );
  }
}
