import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";
import { Menu } from "@mantine/core";
import { RepartoDiarioResumenLogisticaPedido } from "serviciossaintmichel/lib/apisaintmichel/distribucion/repartos-diarios/models/RepartoDiarioResumenLogisticaPedido";
import { RepartoDiarioLectura } from "serviciossaintmichel";

export interface AccionFila {
  nombre: string;
  manejador: (
    repartoDiario: Pick<RepartoDiarioLectura, "id" | "reparto" | "fecha_realizacion" | "viaje_numero">
  ) => void;
}

interface Props {
  resumenesRepartosDiarios: Array<RepartoDiarioResumenLogisticaPedido> | null;
  accionesFila?: Array<AccionFila>;
}

interface State {}

export default class TablaLogisticaRepartosDiarios extends Component<Props, State> {
  render() {
    const { resumenesRepartosDiarios, accionesFila = [] } = this.props;
    if (resumenesRepartosDiarios === null) {
      return <div>Cargando...</div>;
    }

    return (
      <Table striped bordered>
        <thead>
          <tr>
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Reparto
            </th>
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Viaje numero
            </th>
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Cantidad de programaciones
            </th>
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Peso total
            </th>
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Monto total
            </th>
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Zonas
            </th>
            {accionesFila.length > 0 ? (
              <th className="text-center" style={{ position: "sticky", top: "0" }}></th>
            ) : null}
          </tr>
        </thead>

        <tbody>
          {resumenesRepartosDiarios.map(({ resumen_programaciones, reparto_diario }, idx) => (
            <tr key={`${reparto_diario.reparto.id}_${reparto_diario.fecha_realizacion}_${reparto_diario.viaje_numero}`}>
              <td className="text-center">{reparto_diario.reparto.nombre}</td>
              <td className="text-center">{reparto_diario.viaje_numero}</td>
              <td className="text-center">{resumen_programaciones.cantidad_programaciones}</td>
              <td className="text-center">{resumen_programaciones.peso_total}</td>
              <td className="text-center">{resumen_programaciones.precio_total}</td>
              <td className="text-center">
                {resumen_programaciones.zonas.length > 0
                  ? resumen_programaciones.zonas.map((zona) => zona.nombre)
                  : "-"}
              </td>
              <td className="text-center">
                <Menu shadow="md" width={200}>
                  <Menu.Target>
                    <Button>Acciones</Button>
                  </Menu.Target>
                  <Menu.Dropdown>
                    {accionesFila.map((accionFila) => (
                      <Menu.Item
                        onClick={() => {
                          accionFila.manejador(reparto_diario);
                        }}
                      >
                        {accionFila.nombre}
                      </Menu.Item>
                    ))}
                  </Menu.Dropdown>
                </Menu>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}
