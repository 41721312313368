import React, { Component } from "react";
import Flex from "../../../../../../compartido/components/flex/Flex";
import { Tabs, Tab } from "react-bootstrap";
import PersonaResponsable from "./personaResponsable/PersonaResponsable";
import DatosDeContacto from "./datosDeContacto/DatosDeContacto";
import Margin from "../../../../../../compartido/components/margin/Margin";
import DatosAfip from "./datosAfip/DatosAfip";
import TipoClienteUpdate from "./tipoDeCliente/TipoClienteUpdate";
import { Cliente } from "serviciossaintmichel";



interface Props {
  cliente: Cliente;
}

interface State {
  tabVisible: any;
  responsive: boolean;
  cliente: Cliente;
}

export default class ClienteDatosGenerales extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "persona",
      responsive: window.innerWidth < 700 ? true : false,
      cliente: props.cliente,
    };

    window.addEventListener("resize", this.actualizarDimensiones);

    /*
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES,
      funcionCallback: this.refrescarCliente,
    });
    */
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  renderizar = (responsive: boolean) => (
    <Margin top="30px">
      <Tabs
        defaultActiveKey="persona"
        transition={false}
        activeKey={this.state.tabVisible}
        onSelect={this.onSelect}
        variant={/*responsive ? "pills" : "tabs"*/ "pills"}
        style={{
          marginBottom: "1rem",
          flexDirection: responsive ? "column" : "row",
          alignItems: responsive ? "center" : "",
        }}
        unmountOnExit={true}
        width="100%"
      >
        <Tab eventKey="persona" title="Persona Responsable">
          <PersonaResponsable personaId={this.state.cliente.persona_responsable} />
        </Tab>

        <Tab eventKey="datosDeContacto" title="Datos de Contacto">
          <DatosDeContacto clienteId={this.state.cliente.id} />
        </Tab>

        <Tab eventKey="datosAfip" title="Datos Afip">
          <DatosAfip cliente={this.state.cliente} />
        </Tab>

        <Tab eventKey="tipoDeCliente" title="Tipo de Cliente">
          <TipoClienteUpdate clienteId={this.state.cliente.id} />
        </Tab>
      </Tabs>
    </Margin>
  );

  render() {
    return (
      <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        {this.renderizar(this.state.responsive)}
      </Flex>
    );
  }
}
