import React, { Component } from "react";
import Flex from "../../../../libreria/appearance/flex/Flex";
import { RepartoDiarioOrdenVisitasListBox } from "./RepartoDiarioOrdenVisitasListBox";
import { CANALES_WEB_SOCKET,   OrdenVisitaRepartoDiarioLectura, RepartoDiarioService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  onUpdate?: Function;
  onView?: Function;
  onSelect?: Function;
  version: Version;
  seleccionado?: number;
  repartoDiarioId: number;
}

export enum Version {
  LISTBOX,
}

interface State {
  ordenesVisitas: Array<OrdenVisitaRepartoDiarioLectura> | null;
}

export default class RepartoDiarioOrdenVisitasShow extends Component<Props, State> {
  private webSockets: Array<WebSocket>;

  private repartoDiarioService:RepartoDiarioService;

  constructor(props: Props) {
    super(props);
    this.state = {
      ordenesVisitas: null,
    };
    this.webSockets = [];

    this.repartoDiarioService =  new RepartoDiarioService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    try {
      const ordenesVisita = await this.repartoDiarioService.getOrdenesVisita(this.props.repartoDiarioId);
      this.setState({ ordenesVisitas: ordenesVisita });
    } catch (error) {
      this.repartoDiarioService.manejarErrorHTTP(error, "Reparto Diario");
    }
    this.webSockets.push(
      WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
        funcionCallback: this.onChannelMessage,
      })
    );
  }

  onChannelMessage = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.idRepartoDiario !== this.props.repartoDiarioId) {
      return;
    }
    const ordenesVisita = await this.repartoDiarioService.getOrdenesVisita(this.props.repartoDiarioId);
    this.setState({ ordenesVisitas: ordenesVisita });
  };

  componentWillUnmount = () => {
    this.webSockets.forEach((ws: WebSocket) => ws.close());
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };
  onSelect = (id: number) => {
    if (this.props.onSelect) this.props.onSelect(id);
  };

  render() {
    const { ordenesVisitas } = this.state;

    if (ordenesVisitas === null) {
      return <>Cargando visitas...</>;
    }

    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.props.version === Version.LISTBOX ? (
          <RepartoDiarioOrdenVisitasListBox
            onSelect={this.onSelect}
            ordenesVisita={ordenesVisitas}
            seleccionado={this.props.seleccionado}
          />
        ) : null}
      </Flex>
    );
  }
}
