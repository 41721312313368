import React, { Component } from "react";
import { Recorrido, RecorridoService } from "serviciossaintmichel";
import TablaGenerica, {
  FilaTablaGenerica,
  AccionTabla,
} from "../../../../compartido/components/tabla/TablaGenerica";
import { Global } from "../../../../Global";




export interface Props {
  onUpdate: Function;
  onView: Function;
  recorridos: Array<Recorrido>;
  recorridosFuncion: Function;
}

interface State {}

export default class RecorridosShowTable extends Component<Props, State> {

  private recorridoService:RecorridoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      recorridos: [],
    };

    this.recorridoService =  new RecorridoService(Global.UsuarioService.getToken()!);

  }

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };
  armarFilas(recorridos: Array<Recorrido>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (recorridos.length > 0) {
      for (let i = 0; i < recorridos.length; i++) {
        let fila: Array<string> = [];
        fila.push(recorridos[i].nombre);
        let filaResult: FilaTablaGenerica = {
          id: recorridos[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.onUpdate(id);
  };

  handleVer = (id: number) => {
    this.props.onView(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Distribucion.Recorridos.Recorrido.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Distribucion.Recorridos.Recorrido.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.recorridoService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.props.recorridos)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.recorridoService.paramsURL}
        listarElementos={this.props.recorridosFuncion}
        id="TablaRecorridos"
      />
    );
  }
}
