import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import Repuestos from "./Repuesto/components/Repuestos";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];
  
  if (Global.UsuarioService.getPermisos().Elementos.Repuestos.hasAccess()) {
    aux.push({
      name: "Repuestos",
      path: SeccionRepuestos.pathBase + "/repuestos",
      component: Repuestos,
    })
  }
  return aux;
}

export default class SeccionRepuestos extends Component<Props> {
  static pathBase = "/elementos/seccion-repuestos";
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Repuestos"}
      />
    );
  }
}
