import React, { Component } from "react";
import { PropietarioService, Propietario } from "serviciossaintmichel";
import CheckBox from "../../../../../../compartido/components/checkbox/CheckBox";
import Flex from "../../../../../../compartido/components/flex/Flex";
import PropietariosShow, {
  Version,
} from "../../../../../../compartido/components/propietarios/PropietariosShow";
import { Global } from "../../../../../../Global";

interface Props {
  onChangePropietario: Function;
  propietario: number | null;
  propietarios?: Array<Propietario>;
  estado: boolean;
  onChangeEstado: Function;
}
interface State {
  seleccionado: Propietario | null;
  propietarios: Array<Propietario>;
  estado: boolean;
}

export default class CheckBoxSelectPropietario extends Component<Props, State> {
  private propietarioService: PropietarioService;
  constructor(props: Props) {
    super(props);
    this.state = {
      propietarios: this.props.propietarios ? this.props.propietarios : [],
      seleccionado: this.encontrarPropietario(this.props.propietario),
      estado: this.props.estado,
    };
    this.propietarioService = new PropietarioService(
      Global.UsuarioService.getToken()!
    );
  }

  componentDidMount = async () => {
    const [propietarios] = await Promise.all([this.cargarPropietarios()]);
    if (propietarios != null)
      this.setState({
        propietarios: propietarios,
      });
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    if (prevState.propietarios !== this.state.propietarios) {
      this.setState({
        seleccionado: this.encontrarPropietario(this.props.propietario),
      });
    }
  };

  cargarPropietarios = async () => {
    return await this.propietarioService.listAll().catch((error) => {
      this.propietarioService.manejarErrorHTTP(error, "Propietario");
    });
  };

  encontrarPropietario(valor: number | null) {
    if (valor != null) {
      const posicion = this.props.propietarios
        ? this.props.propietarios.findIndex((i) => i.id === valor)
        : this.state
        ? this.state.propietarios.findIndex((i) => i.id === valor)
        : -1;
      return posicion != -1
        ? this.props.propietarios
          ? this.props.propietarios[posicion]
          : this.state.propietarios[posicion]
        : null;
    } else return null;
  }

  onChangePropietario = (propietario: Propietario | null) => {
    this.props.onChangePropietario(propietario);
    this.setState({ seleccionado: propietario });
  };

  onChangeEstado = (estado: boolean) => {
    this.setState({ estado: estado });
    this.props.onChangeEstado(estado);
  };

  setEstado = (estado: boolean) => {
    this.setState({ estado: estado });
  };

  render() {
    const { propietarios, propietario } = this.props;
    const propietariosState = this.state.propietarios;

    return (
      <Flex
        container
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        <CheckBox
          label={"Propietario"}
          estado={this.props.estado ? this.props.estado : false}
          onChange={this.onChangeEstado}
        />

        {this.state.estado ? (
          <PropietariosShow
            version={Version.SELECT}
            propsSelect={{
              callbackParent: this.onChangePropietario,
              seleccionado: this.state.seleccionado,
              placeholder: "Seleccione una Persona",
              sinTitulo: true,
            }}
          />
        ) : null}
      </Flex>
    );
  }
}
