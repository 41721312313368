import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import SelectInsumo from "../../Insumo/components/SelectInsumo";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import InputDecimal from "../../../../compartido/components/input/InputDecimal";


import { BultoInsumoGet, BultoInsumoPost, BultoInsumoService, CANALES_WEB_SOCKET, InsumoGet, InsumoService, TipoInsumoGet, UnidadInsumoGet, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";


export interface Props {
  ocultar: Function;
}

interface State {
  bultoInsumo: BultoInsumoPost;
  insumos: Array<InsumoGet>;
  alerta: any;
}

export default class InsumoNuevo extends Component<Props, State> {

  private bultoInsumoService:BultoInsumoService;
  private insumoService:InsumoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      bultoInsumo: this.bultoInsumoCrear(),
      insumos: [],
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_INSUMOS,
      funcionCallback: this.cargarInsumos,
    });

    this.bultoInsumoService =  new BultoInsumoService(Global.UsuarioService.getToken()!);
    this.insumoService =  new InsumoService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    await this.cargarInsumos();
    const bultoInsumoCrear: BultoInsumoPost = this.state.bultoInsumo;
    if (this.state.insumos.length > 0) {
      bultoInsumoCrear.insumo = this.state.insumos[0].id;
    } else {
      bultoInsumoCrear.insumo = 0;
    }
    this.setState({
      bultoInsumo: bultoInsumoCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  cargarInsumos = async () => {
    const resInsumos = await this.insumoService.listAll().catch((error) => {
      this.insumoService.manejarErrorHTTP(error, "Insumo");
      return null;
    });
    if (resInsumos != null) {
      this.setState({
        insumos: resInsumos,
      });
    }
  };

  bultoInsumoCrear(): BultoInsumoPost {
    return {
      nombre: "",
      insumo: 0,
      cantidad: 0,
    };
  }
  setearNombre = (nombre: string) => {
    let bultoInsumo = this.state.bultoInsumo;
    bultoInsumo.nombre = nombre;
    this.setState({ bultoInsumo: bultoInsumo });
  };
  setearInsumo = (insumo: InsumoGet) => {
    let bultoInsumo = this.state.bultoInsumo;
    bultoInsumo.insumo = insumo.id;
    this.setState({ bultoInsumo: bultoInsumo });
  };
  setearCantidad = (cantidad: number) => {
    let bultoInsumo = this.state.bultoInsumo;
    bultoInsumo.cantidad = cantidad;
    this.setState({ bultoInsumo: bultoInsumo });
  };

  handleCreate() {
    this.bultoInsumoService.create(this.state.bultoInsumo)
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_BULTO_INSUMOS
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Bulto Insumo Creado!",
            titulo: "Atención",
            estado: "block",
          },
        });
        const bultoInsumoCrear: BultoInsumoPost = this.bultoInsumoCrear();
        bultoInsumoCrear.insumo = this.state.bultoInsumo.insumo;
        this.setState({
          bultoInsumo: bultoInsumoCrear,
        });
      })
      .catch((error) => {
        this.bultoInsumoService.manejarErrorHTTP(error, "Bulto Insumo");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.bultoInsumo.nombre &&
      this.state.bultoInsumo.insumo &&
      this.state.bultoInsumo.insumo != 0 &&
      this.state.bultoInsumo.cantidad &&
      this.state.bultoInsumo.cantidad != 0
      ? false
      : true;
  };

  renderizarFormulario() {
    if (this.state.insumos.length == 0) {
      return (
        <div>
          {"Aún no se poseen insumos, vaya y cree uno."}
          <button
            type={"reset"}
            className={"btn btn-outline-secondary"}
            onClick={this.ocultar}
          >
            {"Ocultar"}
          </button>
        </div>
      );
    }
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="nombre">Nombre</label>
                <InputCaracteres
                  id="nombre"
                  name="nombre"
                  onChange={this.setearNombre}
                  value={this.state.bultoInsumo.nombre}
                  placeholder="Ingrese Nombre de Bulto Insumo"
                />
              </div>

              <div className={"form-group"}>
                <SelectInsumo
                  insumos={this.state.insumos}
                  seleccionado={this.state.insumos[0]}
                  callbackParent={this.setearInsumo}
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="cantidad">Cantidad</label>{" "}
                <InputDecimal
                  id="cantidad"
                  name="cantidad"
                  onChange={this.setearCantidad}
                  value={this.state.bultoInsumo.cantidad}
                  cantEnteros={3}
                  cantDecimales={3}
                  placeholder={"Ingrese cantidad"}
                />
              </div>
              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  ref="boton"
                  type="submit"
                  className={"btn btn-info"}
                  disabled={this.disableButton()}
                >
                  {"Crear"}
                </button>
                <button
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
              <div className={"form-group"}>
                <Alerta
                  tipo={this.state.alerta.tipo}
                  titulo={this.state.alerta.titulo}
                  mensaje={this.state.alerta.mensaje}
                  estado={this.state.alerta.estado}
                />
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
