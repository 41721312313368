import React, { Component } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Fuentes } from "../../estilos/estilos";
import Flex from "../flex/Flex";
import H from "../titulos/H";
import InputCaracteres from "../input/InputCaracteres";
import InputDigitos from "../input/InputDigitos";
import RequestButton from "../request-button/RequestButton";
import FechaUtils from "../../utils/FechaUtils";
import { CANALES_WEB_SOCKET,  Persona,  PersonaService,  WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../Global";



export interface Props {
  persona?: Persona;
  titulo?: string;
  onUpdatePersonaCallback?: Function;
  onUpdateValidezCallback?: Function;
  showSubmitButton?: boolean;
}

interface State {
  persona: Persona;
  valido: boolean;
}

export default class FormularioPersona extends Component<Props, State> {
  static defaultProps = {
    persona: {
      id: -1,
      nombre: "",
      apellido: "",
      dni: "",
      fecha_nacimiento: new Date(),
    },
    titulo: "Datos de la Persona",
    onUpdatePersonaCallback: () => {},
    onUpdateValidezCallback: () => {},
    showSubmitButton: true,
  };

  private personaService:PersonaService;

  constructor(props: Props) {
    super(props);

    const { persona = FormularioPersona.defaultProps.persona } = props;

    this.state = {
      persona: persona,
      valido: false,
    };

    this.personaService = new PersonaService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    const valido = this.validar(this.state.persona);
    this.setState({ valido: valido });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { persona, valido } = this.state;
    const {
      onUpdatePersonaCallback = FormularioPersona.defaultProps.onUpdatePersonaCallback,
      onUpdateValidezCallback = FormularioPersona.defaultProps.onUpdateValidezCallback,
    } = this.props;

    if (prevState.persona !== persona) {
      onUpdatePersonaCallback(persona);
      this.setState({ valido: this.validar(persona) });
    }
    if (prevState.valido !== valido) {
      onUpdateValidezCallback(valido);
    }
  }

  validar(persona: Persona) {
    return !!persona.nombre && !!persona.apellido && !!persona.dni;
  }

  personaStateSetter = (propiedad: string) => {
    return (valor: any) =>
      this.setState({
        persona: {
          ...this.state.persona,
          [propiedad]: valor,
        },
      });
  };

  handleSubmit = async (persona: Persona) => {
    const personaResultante =
      persona.id === -1 ? this.personaService.create(persona) : this.personaService.update(persona, persona.id);

    WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES, {
      personaId: (await personaResultante).id,
    });
  };

  renderSubmitButton = (persona: Persona, valido: boolean, showSubmitButton: boolean) => {
    if (!showSubmitButton) {
      return null;
    }

    const textoBoton = persona.id === -1 ? "Crear Persona" : "Guardar cambios";

    return (
      <RequestButton
        texto={textoBoton}
        onClick={() => this.handleSubmit(persona)}
        propsBoton={{
          variant: "success",
          disabled: !valido,
        }}
      />
    );
  };

  render() {
    const { titulo, showSubmitButton = FormularioPersona.defaultProps.showSubmitButton } = this.props;
    const { persona, valido } = this.state;

    const fechaNacimientoSeleccionada = FechaUtils.normalizarFecha(persona.fecha_nacimiento);

    return (
      <Flex container flexDirection="column" width="100%" padding="30px">
        <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%">
          <H size={3} fuente={Fuentes.Titulos} texto={titulo} />
        </Flex>
        <Form>
          <Form.Group style={{ width: "100%" }}>
            <Form.Label style={{ fontFamily: Fuentes.Labels, fontWeight: "bold" }} htmlFor="nombre">
              Nombre
            </Form.Label>
            <InputCaracteres
              id="nombre"
              name="nombre"
              onChange={this.personaStateSetter("nombre")}
              value={persona.nombre}
              placeholder="Nombre"
            />
          </Form.Group>

          <Form.Group style={{ width: "100%" }}>
            <Form.Label style={{ fontFamily: Fuentes.Labels, fontWeight: "bold" }} htmlFor="apellido">
              Apellido
            </Form.Label>
            <InputCaracteres
              id="apellido"
              name="apellido"
              onChange={this.personaStateSetter("apellido")}
              value={persona.apellido}
              placeholder="Apellido"
            />
          </Form.Group>
          <Form.Group style={{ width: "100%" }}>
            <Form.Label style={{ fontFamily: Fuentes.Labels, fontWeight: "bold" }} htmlFor="dni">
              DNI
            </Form.Label>
            <InputDigitos
              id="dni"
              name="dni"
              onChange={this.personaStateSetter("dni")}
              value={persona.dni}
              placeholder="DNI"
              maxlength="10"
            />
          </Form.Group>

          <Form.Group style={{ width: "100%" }}>
            <Form.Label
              style={{
                fontFamily: Fuentes.Labels,
                fontWeight: "bold",
                marginRight: "1rem",
              }}
              htmlFor="fecha_nacimiento"
            >
              Fecha de Nacimiento
            </Form.Label>
            <DatePicker
              id="fecha_nacimiento"
              selected={fechaNacimientoSeleccionada}
              onChange={this.personaStateSetter("fecha_nacimiento")}
              dateFormat={"dd/MM/yyyy"}
            />
          </Form.Group>
          {
            <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%">
              {this.renderSubmitButton(persona, valido, showSubmitButton)}
            </Flex>
          }
        </Form>
      </Flex>
    );
  }
}
