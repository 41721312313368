import React, { Component } from "react";
import { OrdenCompraProducto, ProductoGet, ProductoService } from "serviciossaintmichel";
import SelectProducto from "../../../../../../../../elementos/productos/Producto/components/SelectProducto";
import { Global } from "../../../../../../../../Global";
import Flex from "../../../../../../../../libreria/appearance/flex/Flex";
import Input, {
  InputTypes,
} from "../../../../../../../../libreria/data/input/Input";





export interface Props {
  ordenCompraProducto: OrdenCompraProducto | null;
  onChange: Function;
  onValidationChange: Function;
  productosNoValidos?: Array<number>;
}

interface State {
  ordenCompraProducto: OrdenCompraProducto;
  ordenCompraProductoValida: boolean;
  precioValido: boolean;
  cantidadValida: boolean;
  productos: Array<ProductoGet>;
}

export default class OrdenCompraProductoLoad extends Component<Props, State> {


  private productoService:ProductoService;


  constructor(props: Props) {
    super(props);

    const initialValidation = !!props.ordenCompraProducto;

    this.state = {
      ordenCompraProducto:
        props.ordenCompraProducto || this.crearOrdenCompraProducto(),
      ordenCompraProductoValida: initialValidation,
      productos: [],
      precioValido: false,
      cantidadValida: false,
    };

    this.productoService =  new ProductoService(Global.UsuarioService.getToken()!);

  }

  crearOrdenCompraProducto = (): OrdenCompraProducto => {
    return {
      id: -1,
      orden_compra: -1,
      producto: -1,
      precio: 0,
      cantidad: 1,
    };
  };

  componentDidMount = async () => {
    const { productosNoValidos } = this.props;
    const { ordenCompraProducto } = this.state;

    this.validar();
    this.props.onChange(this.state.ordenCompraProducto);
    let productos = await this.productoService.listAll();
    if (productosNoValidos) {
      productos = productos.filter(
        (p: ProductoGet) => productosNoValidos.indexOf(p.id) === -1
      );
    }

    if (productos.length < 1) {
      return;
    }

    const productoSeleccionado =
      ordenCompraProducto.producto === -1
        ? productos[0].id
        : ordenCompraProducto.producto;

    this.setState({
      productos: productos,
      ordenCompraProducto: {
        ...ordenCompraProducto,
        producto: productoSeleccionado,
      },
    });
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { ordenCompraProducto, ordenCompraProductoValida } = this.state;

    if (
      JSON.stringify(prevState.ordenCompraProducto) !==
      JSON.stringify(ordenCompraProducto)
    ) {
      this.props.onChange(ordenCompraProducto);
      this.validar();
    }
    if (prevState.ordenCompraProductoValida !== ordenCompraProductoValida) {
      this.props.onValidationChange(ordenCompraProductoValida);
    }
    if (
      this.props.ordenCompraProducto &&
      JSON.stringify(prevProps.ordenCompraProducto) !==
        JSON.stringify(this.props.ordenCompraProducto)
    ) {
      this.setState({ ordenCompraProducto: this.props.ordenCompraProducto });
    }
  };

  validar = () => {
    const { ordenCompraProducto, precioValido, cantidadValida } = this.state;
    let valido =
      ordenCompraProducto.producto !== -1 && precioValido && cantidadValida;
    valido = valido && !!ordenCompraProducto.precio;
    valido = valido && !!ordenCompraProducto.cantidad;
    this.setState({ ordenCompraProductoValida: valido });
  };

  setPrecio = (precio: number) => {
    const ordenCompraProducto = {
      ...this.state.ordenCompraProducto,
      precio: precio,
    };
    this.setState({ ordenCompraProducto: ordenCompraProducto });
  };

  setCantidad = (cantidad: number) => {
    const ordenCompraProducto = {
      ...this.state.ordenCompraProducto,
      cantidad: cantidad,
    };
    this.setState({ ordenCompraProducto: ordenCompraProducto });
  };

  setProducto = (producto: ProductoGet) => {
    const ordenCompraProducto = {
      ...this.state.ordenCompraProducto,
      producto: producto.id,
    };
    this.setState({ ordenCompraProducto: ordenCompraProducto });
  };

  render = () => {
    const { ordenCompraProducto, productos } = this.state;

    if (productos.length < 1) {
      return <div>No hay productos para seleccionar</div>;
    }

    return (
      <>
        <Flex flexDirection="column" marginBottom="1rem">
          <span>Producto</span>
          <SelectProducto
            productos={productos}
            seleccionado={productos[0]}
            callbackParent={(producto: ProductoGet) => {
              this.setProducto(producto);
            }}
          />
        </Flex>

        <Flex flexDirection="column" marginBottom="1rem">
          <Input
            data={ordenCompraProducto.precio}
            label={"Precio unitario"}
            placeholder={"Precio unitario"}
            onChange={this.setPrecio}
            onValidationChange={(valido: boolean) => {
              this.setState({ precioValido: valido });
            }}
            type={InputTypes.Number}
            decimal={2}
            integers={6}
            min={0}
          />
        </Flex>

        <Flex flexDirection="column" marginBottom="1rem">
          <Input
            data={ordenCompraProducto.cantidad}
            label={"Cantidad"}
            placeholder={"Cantidad"}
            onChange={this.setCantidad}
            onValidationChange={(valida: boolean) => {
              this.setState({ cantidadValida: valida });
            }}
            type={InputTypes.Number}
            min={1}
          />
        </Flex>
      </>
    );
  };
}
