import React, { Component } from "react";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Visibilizador from "../../../../../../libreria/action/visibilizador/Visibilizador";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../../libreria/containers/box/Box";
import H from "../../../../../../libreria/message/title/H";
import ProductoComboRetornableNew from "./ProductoComboRetornableNew";
import ProductosComboRetornableShow from "./ProductosComboRetornableShow";


import { CANALES_WEB_SOCKET, ComboRetornableService, ProductoComboRetornable, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";


export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  idComboRetornable: number;
}
interface State {
  productosComboRetornable: Array<ProductoComboRetornable>;
  screen: ScreenType;
}

export default class ProductosComboRetornableUpdate extends Component<
  Props,
  State
> {

  private comboRetornableService:ComboRetornableService;

  constructor(props: Props) {
    super(props);
    this.state = {
      productosComboRetornable: [],
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    this.comboRetornableService =  new ComboRetornableService(Global.UsuarioService.getToken()!);

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  async componentDidMount() {
    await this.cargarComboRetornable();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_COMBOS_RETORNABLES,
      funcionCallback: this.onChannelMesagge,
    });
  }

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.comboRetornableId == this.props.idComboRetornable) {
      this.cargarComboRetornable();
    }
  };

  cargarComboRetornable = async () => {
    let comboRetornable = await this.comboRetornableService.get(
      this.props.idComboRetornable
    ).catch((error) => {
      this.comboRetornableService.manejarErrorHTTP(error, "ComboRetornable");
      return null;
    });
    if (comboRetornable) {
      this.setState({ productosComboRetornable: comboRetornable.productos });
    } else
      this.setState({ productosComboRetornable: [] /* , cargando: false */ });
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      /* this.setState({ comboRetornable: this.props.idComboRetornable }); */
    }
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="70%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={3} texto={"Productos"} />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Flex
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
            marginTop="20px"
            marginBottom="20px"
          >
            <Visibilizador label="Nuevo Producto" labelAlignment="center">
              <ProductoComboRetornableNew
                idComboRetornable={this.props.idComboRetornable}
              />
            </Visibilizador>
          </Flex>
          <Flex
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
            marginTop="40px"
          >
            <ProductosComboRetornableShow
              idComboRetornable={this.props.idComboRetornable}
              productos={this.state.productosComboRetornable}
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
