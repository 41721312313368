import React, { Component } from "react";
import { CondicionIVA } from "serviciossaintmichel";
import SelectGenerico from "../../../../../compartido/components/select/SelectGenerico";

interface Props {
  callbackParent: Function;
  seleccionado: CondicionIVA;
  condicionesIVA: Array<CondicionIVA>;
}
interface State {
  seleccionado: CondicionIVA;
}

export default class SelectCondicionIVA extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado,
    };
  }

  getPosicion = (
    valor: CondicionIVA,
    condicionesIVA: Array<CondicionIVA>
  ): number => {
    return condicionesIVA.findIndex((i) => i.id === valor.id);
  };

  getSeleccionado = (indice: number) => {
    const { condicionesIVA } = this.props;
    this.props.callbackParent(condicionesIVA[indice]);
  };

  render() {
    const { condicionesIVA, seleccionado } = this.props;
    const elementoSeleccionado = this.getPosicion(seleccionado, condicionesIVA);

    return (
      <SelectGenerico
        seleccionado={elementoSeleccionado}
        elementos={condicionesIVA.map((condicion) => condicion.tipo)}
        callback={this.getSeleccionado}
      />
    );
  }
}
