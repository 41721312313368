import React from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";
import {
  CANALES_WEB_SOCKET,
  Reparto,
  RepartoService,
  WebSocketService,
} from "serviciossaintmichel";
import { Global } from "../../../../Global";

interface Props {
  onUpdate: Function;
  onView: Function;
}

interface State {
  repartos: Array<Reparto>;
}

export default class RepartosTable extends React.Component<Props, State> {
  private repartoService: RepartoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      repartos: [],
    };

    this.repartoService = new RepartoService(Global.UsuarioService.getToken()!);
  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS,
      funcionCallback: this.getRepartos,
    });
    this.repartoService.paramsURL.setearLimite(LIMITES[0]);
    this.getRepartos();
  }

  getRepartos = () => {
    this.repartoService
      .list()
      .then((result) => {
        this.setState({ repartos: result });
      })
      .catch((error) => {
        this.repartoService.manejarErrorHTTP(error, "Reparto");
      });
  };

  armarFilas(repartos: Array<Reparto>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (repartos.length > 0) {
      for (let i = 0; i < repartos.length; i++) {
        let fila: Array<string> = [];
        fila.push(repartos[i].nombre);
        fila.push(repartos[i].tipo);
        let filaResult: FilaTablaGenerica = {
          id: repartos[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.onUpdate(id);
  };

  handleVer = (id: number) => {
    this.props.onView(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Distribucion.Repartos.Reparto.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Distribucion.Repartos.Reparto.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.repartoService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.repartos)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.repartoService.paramsURL}
        listarElementos={this.getRepartos}
        id="TablaRepartos"
      />
    );
  }
}
