import React, { Component } from "react";
import Flex from "../flex/Flex";
import { LoadScript } from "@react-google-maps/api"; /* global google */ /* eslint-disable no-undef */
import ListGroup from "react-bootstrap/esm/ListGroup";


import { AdaptadorGisGeosGoogleMap, CANALES_WEB_SOCKET, LatLng, Localizacion, WebSocketService, ZonaGet, ZonaService } from "serviciossaintmichel";
import { Global } from "../../../Global";


interface Props {
  zonasId: Array<number>;
  onChange: Function;
}

interface State {
  zonas: Array<ZonaGet>;
  zonasLocalizacion: Array<ZonaGet>;
  zonasLocalizacionAnterior: Array<ZonaGet>;
  estadoNuevasZonas: boolean;
}

export default class ComponenteZonas2 extends Component<Props, State> {

  private zonaService:ZonaService;

  constructor(props: Props) {
    super(props);

    this.state = {
      zonas: [],
      zonasLocalizacion: [],
      zonasLocalizacionAnterior: [],
      estadoNuevasZonas: false,
    };

    this.zonaService = new ZonaService(Global.UsuarioService.getToken()!);

  }

  actualizarZonas = async () => {
    try {
      const zonas = await this.zonaService.listAll();
      this.setState({
        zonas: zonas,
      });
      return zonas;
    } catch (error) {
      this.zonaService.manejarErrorHTTP(error, "Zona");
      return [];
    }
  };

  async componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.MAPAS_ZONAS,
      funcionCallback: this.actualizarZonas,
    });

    const { zonasId } = this.props;
    const zonas = await this.actualizarZonas();
    const zonasLocalizacion = zonas.filter((zona) => zonasId.indexOf(zona.id) > -1);
    this.setState({ zonasLocalizacion: zonasLocalizacion, zonasLocalizacionAnterior: zonasLocalizacion });
  }

  setNuevasZonasLocalizacion = (zonasLocalizacion: Array<ZonaGet>) => {
    this.setState({
      zonasLocalizacionAnterior: zonasLocalizacion,
      estadoNuevasZonas: false,
    });
  };

  nuevasZonasLocalizacion = (zonasLocalizacion: Array<ZonaGet>): boolean => {
    if (zonasLocalizacion.length !== this.state.zonasLocalizacionAnterior.length) return true;
    else {
      let estado: boolean = false;
      zonasLocalizacion.forEach((zona) => {
        if (this.state.zonasLocalizacionAnterior.find((p) => p.id === zona.id) == undefined) estado = true;
      });
      return estado;
    }
  };

  buscarZonasLocalizacion = (localizacion: Localizacion) => {
    let zonasLocalizacion: Array<ZonaGet> = [];

    let zonas_aux: Array<ZonaGet> = this.state.zonas;

    zonas_aux.forEach((zona) => {
      var puntos: Array<LatLng> = [];
      var i = 0;
      zona.poligono.coordinates[0].forEach((x: any) => {
        let aux = { lat: x[0], lng: x[1] };
        puntos[i] = aux;
        i++;
      });

      let latLng: LatLng = new AdaptadorGisGeosGoogleMap().toGMLatLng(localizacion);
      let polygono = new google.maps.Polygon({ paths: puntos });
      let point: google.maps.LatLng = new google.maps.LatLng(latLng.lat, latLng.lng);

      if (google.maps.geometry.poly.containsLocation(point, polygono)) {
        zonasLocalizacion.push(zona);
      }
    });

    let estado: boolean = this.nuevasZonasLocalizacion(zonasLocalizacion);

    this.setState({
      zonasLocalizacion: zonasLocalizacion,
      estadoNuevasZonas: estado,
    });
    const zonasId = zonasLocalizacion.map((zona) => zona.id);
    this.props.onChange(zonasId);
  };

  render() {
    return (
      <Flex container flexDirection="column" width="100%">
        <LoadScript
          id="script-loader"
          googleMapsApiKey="AIzaSyDG1j7AQa8_IW7_cxmRTH0kMsZdLf3akpg"
          libraries={["drawing", "geometry"]}
          //onLoad={this.actualizarZonas}
        ></LoadScript>
        <Flex container flexDirection="row" alignItems="center" justifyContent="center">
          <div>
            <h5>Zonas</h5>
          </div>
        </Flex>
        <br />
        <Flex container flexDirection="column" alignItems="flex-start" justifyContent="center">
          <div>
            <p>
              {this.state.zonasLocalizacion.length > 0
                ? "La dirección pertenece a " + this.state.zonasLocalizacion.length.toString() + " zonas"
                : "La dirección no pertenece a ninguna zona"}
            </p>
          </div>
          {this.state.estadoNuevasZonas ? (
            <div>
              <p style={{ color: "blue" }}>
                {" "}
                Atención: La localización pertenece a nuevas Zonas, recuerde guardar!!!!{" "}
              </p>
            </div>
          ) : null}
        </Flex>
        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          verticalScroll="auto"
          height="300px"
          paddingTop="50px"
        >
          <ListGroup style={{ width: "80%" }}>
            {this.state.zonasLocalizacion.map((zona) => (
              <ListGroup.Item style={{ textAlign: "center", width: "100%" }}>{zona.nombre}</ListGroup.Item>
            ))}
          </ListGroup>
        </Flex>
      </Flex>
    );
  }
}
