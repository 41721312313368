import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../../compartido/models/Screen";
import Flex from "../../../../../../../libreria/appearance/flex/Flex";
import H from "../../../../../../../libreria/message/title/H";
import { Fuentes } from "../../../../../../../compartido/estilos/estilos";

import { CANALES_WEB_SOCKET, ClienteService, Telefono, TelefonoLectura, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../../Global";



export interface Props {
  clienteId: number;
}

interface State {
  cargando: boolean;
  screen: ScreenType;
  telefonos: Array<TelefonoLectura>;
  telefonoId: number | null;
}

export default class TelefonosClienteShow extends Component<Props, State> {

  private clienteService:ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      telefonoId: null,
      telefonos: [],
      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = () => {
    window.addEventListener("resize", this.actualizarDimensiones);

    this.getTelefonos();

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_TELEFONOS,
      funcionCallback: this.onChannelMessage,
    });
  };

  onChannelMessage = async (e: MessageEvent) => {
    let message = JSON.parse(e.data).message;
    const { clienteId } = this.props;

    if (message.clienteId === clienteId) {
      this.getTelefonos();
    }
  };

  getTelefonos = async () => {
    const telefonos = await this.clienteService.getTelefonos(this.props.clienteId);
    this.setState({ telefonos: telefonos });
  };

  actualizarDimensiones = () => {
    this.setState({ screen: Screen.getType(window.innerWidth, window.innerHeight) });
  };

  renderizarFilas = () => {
    return this.state.telefonos.map((telefono, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{`(${telefono.codigo_area}) ${telefono.numero}`}</td>
          <td className="text-center">{telefono.tipo_telefono.tipo}</td>
          <td className="text-center">{telefono.descripcion}</td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Número de Teléfono</th>
            <th className="text-center">Tipo</th>
            <th className="text-center">Descripción</th>
          </tr>
        </thead>

        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  render() {
    const { telefonos } = this.state;

    if (telefonos.length === 0) {
      return <div>El cliente no posee teléfonos asociados</div>;
    }

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="2rem"
        >
          <H size={3} fuente={Fuentes.Titulos} texto="Teléfonos" />

          {this.renderizarTabla()}
        </Flex>
      </Flex>
    );
  }
}
