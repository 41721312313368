import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { CANALES_WEB_SOCKET, ClienteService, DireccionDTO, FranjaHoraria, Recorrido, WebSocketService } from "serviciossaintmichel";
import DireccionLoad from "../../../../../../compartido/components/direccion/DireccionLoad";
import Flex from "../../../../../../compartido/components/flex/Flex";
import FranjasHorariasLoad from "../../../../../../compartido/components/franjahoraria/FranjasHorariasLoad";

import ManejadorErroresHTTP from "../../../../../../compartido/utils/ManejadorErroresHTTP";
import RecorridosLoad from "../../../../../../distribucion/recorridos/components/RecorridosLoad";
import { Global } from "../../../../../../Global";
import { Box } from "../../../../../../libreria/containers/box/Box";

export interface Props {
  clienteId: number;
  onClose?: Function;
}

interface State {
  direccion: DireccionDTO | null;
  franjasHorarias: Array<FranjaHoraria>;
  direccionValida: boolean;
  franjasHorariasValidas: boolean;
  recorridos: Array<Recorrido>;
  recorridosValidos: boolean;
}

export default class ClienteDireccionNew extends Component<Props, State> {

  private clienteService:ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      direccion: null,
      franjasHorarias: [],
      franjasHorariasValidas: false,
      direccionValida: false,
      recorridos: [],
      recorridosValidos: false,
    };

    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);

  }

  onChangeFranjas = (franjasHorarias: Array<FranjaHoraria>) => {
    this.setState({ franjasHorarias: franjasHorarias });
  };

  onFranjasValidationChange = (valido: boolean) => {
    this.setState({ franjasHorariasValidas: valido });
  };

  onChangeDireccion = (direccion: DireccionDTO) => {
    this.setState({ direccion: direccion });
  };

  onDireccionValidationChange = (valido: boolean) => {
    this.setState({ direccionValida: valido });
  };

  onChangeRecorridos = (recorridos: Array<Recorrido>) => {
    this.setState({ recorridos: recorridos });
  };

  onRecorridosValidationChange = (valido: boolean) => {
    this.setState({ recorridosValidos: valido });
  };

  onSubmit = async () => {
    const { direccion, franjasHorarias, recorridos } = this.state;
    const { clienteId, onClose = () => {} } = this.props;

    if (direccion === null) {
      return;
    }

    try {
      const resultado = await this.clienteService.crearDireccion(clienteId, direccion, franjasHorarias, recorridos);
      const data = { direccionId: resultado.id, clienteId: clienteId };
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.COMPARTIDO_DIRECCION, data);
      onClose();
    } catch (error) {
      ManejadorErroresHTTP.evaluarError(error, "DireccionCliente");
    }
  };

  render = () => {
    const { franjasHorarias, direccionValida, franjasHorariasValidas, recorridos, recorridosValidos } = this.state;
    const { onClose = () => {} } = this.props;
    return (
      <Box
        width={"75%"}
        paddingVertical={"2rem"}
        paddingHorizontal={"2rem"}
        showCloseBtn={true}
        onCloseCallback={onClose}
      >
        <Flex container flexDirection={"column"} alignItems={"center"} padding={"2rem"}>
          <DireccionLoad
            direccion={null}
            onChangeDireccion={(direccion: DireccionDTO) => {
              this.onChangeDireccion(direccion);
            }}
            onChangeValidation={(valido: boolean) => {
              this.onDireccionValidationChange(valido);
            }}
          />
          <hr style={{ width: "100%" }} />
          <FranjasHorariasLoad
            franjas={franjasHorarias}
            onChange={(franjas: Array<FranjaHoraria>) => {
              this.onChangeFranjas(franjas);
            }}
            onValidationChange={(valido: boolean) => {
              this.onFranjasValidationChange(valido);
            }}
          />
          <hr style={{ width: "100%" }} />
          <RecorridosLoad
            recorridos={recorridos}
            onChangeRecorridos={(recorridos: Array<Recorrido>) => {
              this.onChangeRecorridos(recorridos);
            }}
            onChangeValidation={(valido: boolean) => {
              this.onRecorridosValidationChange(valido);
            }}
          />
          <Button
            onClick={() => {
              this.onSubmit();
            }}
            disabled={!direccionValida || !franjasHorariasValidas || !recorridosValidos}
          >
            Crear dirección
          </Button>
        </Flex>
      </Box>
    );
  };
}
