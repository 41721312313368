import React, { Component } from "react";
import { Dia } from "serviciossaintmichel";
import SelectGenericoNull from "../select/SelectGenericoNull";

interface Props {
  onChangeDia: Function;
  seleccionado: Dia | null;
  disabled?: boolean;
}
interface State {
  dias: Array<Dia>;
  seleccionado: Dia | null;
}

export default class SelectDia extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado,
      dias: [Dia.LUN, Dia.MAR, Dia.MIE, Dia.JUE, Dia.VIE, Dia.SAB, Dia.DOM],
    };
  }

  getPosicion = (valor: Dia): number => {
    return this.state.dias.findIndex((i) => i === valor);
  };

  getSeleccionado = (indice: number) => {
    if (indice >= 0) this.props.onChangeDia(this.state.dias[indice]);
    else this.props.onChangeDia(null);
  };

  render() {
    return (
      <SelectGenericoNull
        disabled={this.props.disabled}
        seleccionado={
          this.props.seleccionado
            ? this.getPosicion(this.props.seleccionado)
            : null
        }
        elementos={this.state.dias.map((i) => i)}
        callback={this.getSeleccionado}
      />
    );
  }
}
