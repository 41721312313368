import React, { Component } from "react";
import Flex from "../flex/Flex";
import { ItemListBox, TipoItemListBox } from "./Item";
import * as Icon from 'react-bootstrap-icons';
import { Button, ListGroup } from "react-bootstrap";
import Swal from "sweetalert2";

interface Props<T> {
  item: ItemListBox<T>;

  onSelect?(item: T|null): void;
  onView?(item: T): void;
  onDeleteSimple?(item: ItemListBox<T>): void;
  onDeleteRequest?(item: T): void;
  onDeleteFinish?(item: ItemListBox<T>): void;
  tipoItem:TipoItemListBox;
  msgOnSuccess?:string;
  msgOnFailure?:string;

}

interface State<T> {
  item: ItemListBox<T>;
  waiting:boolean;
  
}

class ListBoxItem<T> extends Component<Props<T>, State<T>> {
  constructor(props: Props<T>) {
    super(props);
    this.state = {
      item: this.props.item,
      waiting:false
    };

    if(this.props.tipoItem.deleteSimple && this.props.tipoItem.view) 
    {
    this.widthText="80%";
    this.widthDelete="10%";
    this.widthView="10%";
    }
    else if (this.props.tipoItem.deleteSimple)
    {
    this.widthText="90%";
    this.widthDelete="10%";
    this.widthView="";

    }
    else if (this.props.tipoItem.view) 
    {
      this.widthText="90%";
      this.widthDelete="";
      this.widthView="10%";

    }
    else
    {
      this.widthText="100%";
      this.widthDelete="";
      this.widthView="";
    }
  }

  widthText:string;
  widthDelete:string;
  widthView:string;


  componentDidMount() {}



  componentDidUpdate(prevProps: Props<T>) {
    if (prevProps.item.id !== this.props.item.id) {
      this.setState({
        item: this.props.item,
      });
    }
  }



  onClick = () => {
    if(this.props.tipoItem.select){ 
    let item: ItemListBox<T> = this.state.item;
    item.estado = !item.estado;
    this.setState({ item: item });

    if(item.estado){

      if(this.props.onSelect)
        this.props.onSelect(item.objeto);
    }
    else{
      if(this.props.onSelect)
        this.props.onSelect(null);}
  }};

  onView = () => {
    if(this.props.onView)
      this.props.onView(this.state.item.objeto);
  };

  onDeleteSimple = () => {
    if(this.props.onDeleteSimple)
      this.props.onDeleteSimple(this.state.item);

  };

  onDeleteRequest = async () => {
    this.setState({ waiting: true });

    try {
      if(this.props.onDeleteRequest)
        await this.props.onDeleteRequest(this.state.item.objeto);

      Swal.fire({
        icon: "success",
        text: this.props.msgOnSuccess ? this.props.msgOnSuccess : "Operacion realizada con exito",
      })

      if(this.props.onDeleteFinish)
      this.props.onDeleteFinish(this.state.item);


    } catch (e) {
      Swal.fire({
        icon: "error",
        text: this.props.msgOnFailure ? this.props.msgOnFailure  + e : e,
      })
    }

    this.setState({ waiting: false });
   
  };



  isActive = ():boolean => 
  {
  if(this.props.tipoItem.select)
    return this.state.item.estado;
  else
    return false;
  }


  render() {
    return (

      <ListGroup.Item  onClick={this.onClick}  active={this.isActive()}>

      <Flex
       container 
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      width="100%"
        >



                
      <Flex 
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={this.widthText}  
          >
            {this.state.item.id+" "+this.state.item.texto}

      </Flex> 


      {this.props.tipoItem.view ? 
      <Flex 

          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={this.widthView} >
            
            <Button
            onClick={this.onView}
            >
              <Icon.ArrowBarDown size={50}/>
            </Button>

      </Flex> 
      :null }

      {this.props.tipoItem.deleteSimple ? 
      <Flex 
      container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={this.widthDelete}
          >
            <Button
            onClick={this.onDeleteSimple}
            variant="light"
            >
              <Icon.XCircle  size={15}/>
            </Button>
      </Flex> 
      :null }
    
    {this.props.tipoItem.deleteRequest ? 
      <Flex container flexDirection="column"
      alignItems="center"
          justifyContent="center"
          width={this.widthDelete} >
                 <Button
      onClick={this.onDeleteRequest}
        disabled = {this.state.waiting}
      >
                <Icon.ArrowBarDown size={50}/>
    </Button>
      </Flex> 
      :null }

      </Flex> 

      </ListGroup.Item>


      




      /* 
        
          <ListGroup.Item  onClick={this.onClick}  active={this.isActive()}>
                
          <Flex flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width={this.widthText}  >
                {this.state.item.texto}
          </Flex> 


          {this.props.tipoItem.view ? 
          <Flex flexDirection="column"
          alignItems="center"
              justifyContent="center"
              width={this.widthView} >
                
                <Button
                onClick={this.onView}
                >
                  <Icon.ArrowBarDown size={50}/>
                </Button>

          </Flex> 
          :null }

          {this.props.tipoItem.deleteSimple ? 
          <Flex flexDirection="column"
          alignItems="center"
              justifyContent="center"
              width={this.widthDelete} >
                <Button
                onClick={this.onDelete}
                >
                  <Icon.ArrowBarDown size={50}/>
                </Button>
          </Flex> 
          :null }
        
        {this.props.tipoItem.deleteRequest ? 
          <Flex flexDirection="column"
          alignItems="center"
              justifyContent="center"
              width={this.widthDelete} >
                     <Button
          onClick={this.onDeleteRequest}
            disabled = {this.state.waiting}
          >
                    <Icon.ArrowBarDown size={50}/>
        </Button>
          </Flex> 
          :null }



        </ListGroup.Item>

        
        */ 
      
      
      
    );
  }
}

export default ListBoxItem;
