import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Flex from "../../../../../compartido/components/flex/Flex";
import * as Icon from "react-bootstrap-icons";
import H from "../../../../../compartido/components/titulos/H";
import { GrupoTipoCliente, GrupoTipoClienteService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";


export interface Props {
  grupoSeleccionado: Function;
}

interface State {
  responsive: boolean;
  gruposTipoCliente: Array<GrupoTipoCliente>;
  cargando: boolean;
}

export default class PasoSeleccionDeGrupo extends Component<Props, State> {

  private grupoTipoClienteService:GrupoTipoClienteService;

  constructor(props: Props) {
    super(props);

    this.state = {
      responsive: window.innerWidth < 700 ? true : false,
      gruposTipoCliente: [],
      cargando: true,
    };

    window.addEventListener("resize", this.actualizarDimensiones);

    this.grupoTipoClienteService = new GrupoTipoClienteService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    const gruposTipoCliente = await this.grupoTipoClienteService.listAll().catch(
      (error) => {
        this.grupoTipoClienteService.manejarErrorHTTP(error, "Grupo Tipo Cliente");
        return null;
      }
    );
    if (gruposTipoCliente != null) {
      this.setState({ gruposTipoCliente: gruposTipoCliente, cargando: false });
    }
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  render() {
    const { gruposTipoCliente, responsive, cargando } = this.state;

    if (cargando) {
      return null;
    }

    return responsive
      ? this.renderMobile(gruposTipoCliente)
      : this.renderDesktop(gruposTipoCliente);
  }

  renderGrupos(gruposTipoCliente: Array<GrupoTipoCliente>) {
    let grupos: Array<JSX.Element> = [];

    for (let grupoTipoCliente of gruposTipoCliente) {
      let grupo = this.renderGrupo(grupoTipoCliente);
      grupos.push(grupo);
    }

    return grupos;
  }

  renderGrupo(grupoTipoCliente: GrupoTipoCliente) {
    return (
      <Flex
        container
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="25%"
        marginTop="20px"
      >
        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginBottom="15px"
        >
          <H size={4} fuente="Segoe Print" texto={grupoTipoCliente.nombre} />
        </Flex>
        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {this.renderBoton(grupoTipoCliente)}
        </Flex>
      </Flex>
    );
  }

  renderBoton(grupoTipoCliente: GrupoTipoCliente) {
    return (
      <Button
        variant="light"
        onClick={() => {
          this.props.grupoSeleccionado(grupoTipoCliente);
        }}
      >
        {this.renderIcono(grupoTipoCliente.nombre)}
      </Button>
    );
  }

  renderIcono(nombre: string) {
    const size = 75;
    const nombreIcono: { [name: string]: JSX.Element } = {
      Empresa: <Icon.Building size={size} />,
      Comercio: <Icon.Shop size={size} />,
      Domicilio: <Icon.HouseDoor size={size} />,
      Particular: <Icon.Person size={size} />,
    };
    const iconoDefault = <Icon.QuestionSquare size={size} />;

    return nombreIcono[nombre] || iconoDefault;
  }

  renderDesktop(gruposTipoCliente: Array<GrupoTipoCliente>) {
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
        marginTop="30px"
        marginBottom="30px"
      >
        {this.renderGrupos(gruposTipoCliente)}
      </Flex>
    );
  }

  renderMobile(gruposTipoCliente: Array<GrupoTipoCliente>) {
    return (
      <Flex
        container
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        marginTop="30px"
      >
        {this.renderGrupos(gruposTipoCliente)}
      </Flex>
    );
  }
}
