import React, { Component } from "react";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import RequestButton from "../../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import H from "../../../../../../libreria/message/title/H";
import MaquinaConsumoLoad from "./MaquinaConsumoLoad";



import { CANALES_WEB_SOCKET,  ConsumoGet,  ConsumoService,   MaquinaSimbolicaConsumo,   WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";



export interface Props {
  onSaveSuccess?: Function;
  onExit?: Function;
  consumo: ConsumoGet;
}

interface State {
  maquina: MaquinaSimbolicaConsumo;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class MaquinaConsumoNew extends Component<Props, State> {

  private consumoService:ConsumoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      maquina: this.createEmptyMaquinaSimbolica(),
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.consumoService =  new ConsumoService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  createEmptyMaquinaSimbolica = (): MaquinaSimbolicaConsumo => {
    return { maquina_simbolica: -1, cantidad: 0 };
  };

  onSave = async () => {
    try {
      let x = await this.consumoService.agregarMaquina(this.props.consumo.id, this.state.maquina);

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ADMINISTRACION_CONSUMOS_MAQUINAS, {
        type: "new",
        idConsumo: this.props.consumo.id,
        idmaquina: x.maquina_simbolica,
      });

      await Swal.fire({
        icon: "success",
        text: "Maquina Actualizado",
      });
    } catch (error) {
      this.consumoService.manejarErrorHTTP(error, "Nueva Maquina Consumo");
    }
  };

  onExit = () => {
    if (this.props.onExit != undefined) this.props.onExit();
  };

  isValid = (nombre: string): boolean => {
    return nombre.length > 0 ? true : false;
  };

  onChangeMaquina = (maquina: MaquinaSimbolicaConsumo) => {
    this.setState({ maquina: maquina });
  };

  onChangeValidation = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border="1px solid grey"
        borderRadius="5px"
        padding="10px"
      >
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="10px">
          <H size={4} texto={"Agregar Maquina"} />
        </Flex>

        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
          <MaquinaConsumoLoad
            maquina={this.state.maquina}
            onChange={this.onChangeMaquina}
            onValidationChange={this.onChangeValidation}
          />
        </Flex>

        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
          <RequestButton propsBoton={{ disabled: !this.state.valid }} texto={"Agregar"} onClick={this.onSave} />
        </Flex>
      </Flex>
    );
  }
}
