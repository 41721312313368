import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { CriterioOrden, Pago } from "serviciossaintmichel";
import Flex from "../../../libreria/appearance/flex/Flex";
import BotonOrden from "../../seccion-clientes/clientes/components/BotonOrden";
import { getControlCriterio, getCriterioHandler, manejadorScroll } from "../../../compartido/utils/OrdenUtils";
import FechaDisplay from "../../../compartido/components/fecha-display/FechaDisplay";
import { Badge } from "@mantine/core";

interface Props {
  onView?: Function;
  pagos: Array<Pago>;
  criterioOrdenActual: CriterioOrden | null;
  criterioOrdenHandler?: (critOrden: CriterioOrden | null) => void;
  aumentarLimite?: () => void;
}

interface State {}

export default class TablaPagos extends Component<Props, State> {
  generarFilas = (pagos: Array<Pago>) => {
    if (pagos.length === 0) {
      return (
        <tr>
          <td colSpan={12}>Sin resultados</td>
        </tr>
      );
    }

    return pagos.map((pago, idx) => this.generarFila(pago));
  };

  generarFila = (pago: Pago) => (
    <tr key={pago.id}>
      <td className="text-center">{pago.id}</td>
      <td className="text-center">
        <FechaDisplay fecha={pago.fecha} />
      </td>
      <td className="text-center">{pago.monto}</td>
      <td className="text-center">
        <Badge variant={"filled"}>{pago.tipo_pago.tipo}</Badge>
      </td>
      <td className="text-center">
        <Badge variant={"filled"}>{pago.fuente}</Badge>
      </td>
      <td className="text-center">
        {pago.pagos_factura.length === 0 ? (
          "-"
        ) : (
          <ul style={{ listStyleType: "none" }}>
            {pago.pagos_factura.map((pagoFactura) => (
              <li>{`Factura ${pagoFactura.factura} - $${pagoFactura.monto}`}</li>
            ))}
          </ul>
        )}
      </td>
      <td className="text-center">{pago.comprobante !== null ? "SI" : "NO"}</td>
    </tr>
  );

  renderBotonOrden = (campo: string) => {
    if (!this.props.criterioOrdenHandler) {
      return null;
    }
    return (
      <BotonOrden
        criterio={getControlCriterio(campo, this.props.criterioOrdenActual)}
        onCriterioClicked={getCriterioHandler(campo, this.props.criterioOrdenHandler)}
      />
    );
  };

  generarEncabezados = () => (
    <tr>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
          <span>Id </span>
          {this.renderBotonOrden("id")}
        </Flex>
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
          <span>Fecha </span>
          {this.renderBotonOrden("fecha")}
        </Flex>
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        <span>Monto </span>
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
          <span>Tipo </span>
          {this.renderBotonOrden("tipo_pago")}
        </Flex>
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        Fuente
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        Facturas
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        <span>Comprobante </span>
      </th>
    </tr>
  );

  render() {
    const { pagos, aumentarLimite } = this.props;

    return (
      <div
        style={{ maxHeight: "300px", overflowY: "scroll" }}
        onScroll={() => manejadorScroll("contenedor-scroll", aumentarLimite)}
        id="contenedor-scroll"
      >
        <Table striped bordered>
          <thead>{this.generarEncabezados()}</thead>
          <tbody>{this.generarFilas(pagos)}</tbody>
        </Table>
      </div>
    );
  }
}
