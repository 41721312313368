import React, { Component } from "react";
import ConsumoUpdate from "./components/update/ConsumoUpdate";
import ConsumoShow from "./components/show/ConsumoShow";
import ConsumosShow, { Version } from "./components/show/ConsumosShow";
import ConsumoNew from "./components/new/ConsumoNew";
import { Tabs, Tab } from "react-bootstrap";
import Flex from "../../../libreria/appearance/flex/Flex";
import { CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../Global";




interface Props {}

interface State {
  tabVisible: any;
  disabledActualizar: boolean;
  disabledVer: boolean;
  responsive: boolean;
  idConsumo: number | null;
  recienCreado: boolean;
}

export default class Consumos extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista",
      disabledActualizar: true,
      disabledVer: true,
      responsive: window.innerWidth < 700 ? true : false,
      idConsumo: null,
      recienCreado: false,
    };

    window.addEventListener("resize", this.actualizarDimensiones);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS,
      funcionCallback: this.cargarConsumo,
    });
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  cargarConsumo = async (e: MessageEvent) => {};

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  actualizar = (idConsumo: number) => {
    this.setState({
      tabVisible: "actualizar",
      disabledActualizar: false,
      idConsumo: idConsumo,
    });
  };

  ver = (idConsumo: number) => {
    this.setState({
      tabVisible: "ver",
      disabledVer: false,
      idConsumo: idConsumo,
      recienCreado: false,
    });
  };

  ocultarVer = () => {
    this.setState({
      tabVisible: "lista",
      disabledVer: true,
      idConsumo: null,
    });
  };

  ocultarNuevo = () => {
    this.setState({
      tabVisible: "lista",
    });
  };

  renderizarNuevo = () => {
    if (
      Global.UsuarioService.getPermisos().Administracion.Servicios.Consumo.canAdd()
    ) {
      return (
        <Tab eventKey="nuevo" title="Nuevo" width="100%">
          {this.state.tabVisible === "nuevo" ? (
            <ConsumoNew
              onExit={this.ocultarNuevo}
              onSaveSuccess={async (idConsumo: number) => {
                this.setState({ recienCreado: true, idConsumo: idConsumo });
                this.actualizar(idConsumo);
              }}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarLista = () => {
    if (
      Global.UsuarioService.getPermisos().Administracion.Servicios.Consumo.canView()
    ) {
      return (
        <Tab eventKey="lista" title="Lista" width="100%">
          <ConsumosShow
            version={Version.TABLE}
            onView={this.ver}
            onUpdate={this.actualizar}
          />
        </Tab>
      );
    }
  };

  renderizarActualizar = () => {
    if (
      Global.UsuarioService.getPermisos().Administracion.Servicios.Consumo.canChange()
    ) {
      return (
        <Tab
          width="100%"
          eventKey="actualizar"
          title="Actualizar"
          disabled={this.state.disabledActualizar}
        >
          {this.state.idConsumo && this.state.tabVisible === "actualizar" ? (
            <ConsumoUpdate
              id={this.state.idConsumo}
              //recienCreado={this.state.recienCreado}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarVerDetalle = () => {
    if (
      Global.UsuarioService.getPermisos().Administracion.Servicios.Consumo.canView()
    ) {
      return (
        <Tab
          width="100%"
          eventKey="ver"
          title="Ver Detalle"
          disabled={this.state.disabledVer}
        >
          {this.state.idConsumo && this.state.tabVisible === "ver" ? (
            <ConsumoShow id={this.state.idConsumo} />
          ) : null}
        </Tab>
      );
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey="lista"
      transition={false}
      activeKey={this.state.tabVisible}
      onSelect={this.onSelect}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "flex-start" : "",
      }}
      unmountOnExit={true}
    >
      {this.renderizarNuevo()}
      {this.renderizarLista()}
      {this.renderizarActualizar()}
      {this.renderizarVerDetalle()}
    </Tabs>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <div style={{ display: "block", width: "100%" }}>
          {this.renderizar(this.state.responsive)}
        </div>
      </Flex>
    );
  }
}
