import React from "react";
import { Button } from "react-bootstrap";
import Flex from "../../appearance/flex/Flex";
import * as Icon from "react-bootstrap-icons";

export interface Props {
  label?: string;
  visible?: boolean;
  children?: any;
  labelAlignment?: "center" | "flex-start";
  onChange?: Function;
}

interface State {
  visible: boolean;
  label: string;
}

export default class Visibilizador extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: this.props.visible ? this.props.visible : false,
      label: this.props.label ? this.props.label : "",
    };
  }

  componentDidUpdate = (oldProps: Props) => {
    if (this.props !== oldProps) {
    }
  };

  onClick = () => {
    let visible = !this.state.visible;
    this.setState({ visible: visible });
    if (this.props.onChange) this.props.onChange(visible);
  };

  render() {
    return (
      <Flex width="100%" flexDirection="column" justifyContent={"center"} alignItems={"center"}>
        <Flex
          width="100%"
          flexDirection="column"
          justifyContent={"center"}
          alignItems={this.props.labelAlignment ? this.props.labelAlignment : "center"}
        >
          <Button variant="ligth" onClick={this.onClick} style={{ fontSize: 20 }}>
            {this.state.label}{" "}
            {this.state.visible ? <Icon.DashCircle size={"25px"} /> : <Icon.PlusCircle size={"25px"} />}
          </Button>
        </Flex>
        {this.state.visible ? (
          <Flex marginTop="20px" width="100%" flexDirection="column" justifyContent={"center"} alignItems={"center"}>
            {this.props.children}
          </Flex>
        ) : null}
      </Flex>
    );
  }
}
