import React, { Component } from "react";
import { PropsSelectGeneral } from "../../../../../../../compartido/components/select/SelectBusqueda";
import { PropsSelectGenerico } from "../../../../../../../compartido/components/select/PropsSelectGenerico";
import MediosComunicacionShowSelect from "./MediosComunicacionShowSelect";
import { LIMITES } from "../../../../../../../compartido/components/select/SelectLimiteTabla";
import Flex from "../../../../../../../libreria/appearance/flex/Flex";
import {
  MedioComunicacion,
  MedioComunicacionService,
} from "serviciossaintmichel";
import { Global } from "../../../../../../../Global";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
  version: Version;
  propsSelect?: PropsSelectGenerico<MedioComunicacion> & PropsSelectGeneral;
}

export enum Version {
  SELECT,
  TABLE,
}

interface State {
  medios: Array<MedioComunicacion>;
}

export default class ClientesShow extends Component<Props, State> {
  private medioComunicacionService: MedioComunicacionService;
  constructor(props: Props) {
    super(props);
    this.state = {
      medios: [],
    };
    this.medioComunicacionService = new MedioComunicacionService(
      Global.UsuarioService.getToken()!
    );
  }

  componentDidMount() {
    if (this.props.version == Version.TABLE)
      this.medioComunicacionService.paramsURL.setearLimite(LIMITES[0]);
    this.getMediosComunicacion();
  }

  getMediosComunicacion = () => {
    this.medioComunicacionService
      .listAll()
      .then((result) => {
        this.setState({ medios: result });
      })
      .catch((error) => {
        this.medioComunicacionService.manejarErrorHTTP(
          error,
          "Medio Comunicación"
        );
      });
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems={Version.TABLE ? undefined : "center"}
        justifyContent="center"
      >
        {this.props.version == Version.SELECT && this.props.propsSelect ? (
          <MediosComunicacionShowSelect
            {...this.props.propsSelect}
            callbackParent={this.props.propsSelect.callbackParent}
            seleccionado={
              this.props.propsSelect.seleccionado
                ? this.props.propsSelect.seleccionado
                : null
            }
            items={this.state.medios}
          />
        ) : null}
        {/* {this.props.version == Version.TABLE ? (
          <MediosComunicacionShowTable onView={this.onView} onUpdate={this.onUpdate} />
        ) : null} */}
      </Flex>
    );
  }
}
