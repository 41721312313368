import React, { Component } from "react";
import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import { EmpleadoGet, RolEmpleado } from "serviciossaintmichel";
import EmpleadosShow, {
  Version as VersionEmpleadosShow,
} from "../../../../administracion/seccion-personal/empleados/components/EmpleadosShow";
import Flex from "../../../../libreria/appearance/flex/Flex";

export interface Props {
  onSubmit: Function;
  rolEmpleado?: RolEmpleado;
  excluirEmpleados?: Array<number>;
}

interface State {
  rolEmpleado: RolEmpleado;
  validation: boolean;
  empleado: EmpleadoGet | null;
}

export default class RolEmpleadoLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      rolEmpleado: props.rolEmpleado || this.crearRolEmpleado(),
      validation: false,
      empleado: null,
    };
  }

  crearRolEmpleado = () => {
    return { id: -1, empleado: -1, rol: "", reparto_diario: -1 };
  };

  componentDidMount = () => {
    this.updateValidation(this.state.rolEmpleado);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (this.props.rolEmpleado && prevProps.rolEmpleado !== this.props.rolEmpleado) {
      this.setState({ rolEmpleado: this.props.rolEmpleado });
    }
    if (JSON.stringify(prevState.rolEmpleado) !== JSON.stringify(this.state.rolEmpleado)) {
      this.updateValidation(this.state.rolEmpleado);
    }
  };

  isValid = (rolEmpleado: RolEmpleado) => {
    return !!(rolEmpleado.empleado > 0 && rolEmpleado.rol);
  };

  updateValidation = (rolEmpleado: RolEmpleado) => {
    const validation = this.isValid(rolEmpleado);
    this.setState({ validation: validation });
  };

  setEmpleado = (empleado: EmpleadoGet | null) => {
    this.setState((prevState) => ({
      rolEmpleado: { ...prevState.rolEmpleado, empleado: empleado ? empleado.id : -1 },
      empleado: empleado,
    }));
  };

  setRol = (rol: string) => {
    this.setState((prevState) => ({ rolEmpleado: { ...prevState.rolEmpleado, rol: rol } }));
  };

  onSubmit = () => {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.rolEmpleado);
    }
    this.setState({ rolEmpleado: this.crearRolEmpleado() });
  };

  render() {
    const { rolEmpleado, validation, empleado } = this.state;
    const { excluirEmpleados = [] } = this.props;

    const radios = [
      { name: "Ayudante", value: "Ayudante" },
      { name: "Chofer", value: "Chofer" },
      { name: "Supervisor", value: "Supervisor" },
    ];

    return (
      <Flex flexDirection="row" width="100%" alignItems="center" justifyContent="center">
        <Flex flexDirection="row" alignItems="center">
          <strong>Empleado</strong>
          <EmpleadosShow
            version={VersionEmpleadosShow.SELECT}
            excluirIds={excluirEmpleados}
            propsSelect={{ callbackParent: this.setEmpleado, seleccionado: empleado }}
          />
        </Flex>
        <Flex flexDirection="column">
          <strong>Rol</strong>
          <ButtonGroup>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={"outline-secondary"}
                name="radio-rol"
                value={radio.value}
                checked={rolEmpleado.rol === radio.value}
                onChange={(e) => this.setRol(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Flex>
        <Flex flexDirection="row" alignItems="center" justifyContent="center">
          <Button onClick={this.onSubmit} disabled={!validation}>
            Agregar empleado
          </Button>
        </Flex>
      </Flex>
    );
  }
}
