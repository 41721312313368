import React, { Component } from "react";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import Screen, { ScreenType } from "../../../../compartido/models/Screen";
import H from "../../../../libreria/message/title/H";
import RepartoLoad from "../load/RepartoLoad";
import Flex from "../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../libreria/containers/box/Box";
import Swal from "sweetalert2";

import {
  CANALES_WEB_SOCKET,
  DatosReparto,
  RepartoService,
  WebSocketService,
  TIPOS_REPARTO,
} from "serviciossaintmichel";
import { Global } from "../../../../Global";

export interface Props {
  onSaveSucces: Function;
  onExit?: Function;
}

interface State {
  reparto: DatosReparto;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class RepartoNew extends Component<Props, State> {
  private repartoService: RepartoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      reparto: this.createEmptyReparto(),
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.repartoService = new RepartoService(Global.UsuarioService.getToken()!);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  createEmptyReparto(): DatosReparto {
    let x: DatosReparto = { id: -1, nombre: "", tipo: TIPOS_REPARTO.DOMICILIO };
    return x;
  }

  onSave = () => {
    return this.repartoService
      .create(this.state.reparto)
      .then((reparto) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS, {
          type: "NEW",
          idReparto: reparto.id,
        });
        Swal.fire({
          icon: "success",
          text: "Reparto Creado",
        });
        this.props.onSaveSucces(reparto.id);
      })
      .catch((error) => {
        this.repartoService.manejarErrorHTTP(error, "Reparto");
      });
  };

  onExit = () => {
    if (this.props.onExit != undefined) this.props.onExit();
  };

  onChange = (reparto: DatosReparto) => {
    this.setState({ reparto: reparto });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex flexDirection="column" width="60%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex flexDirection="column" width="50%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <H size={3} texto={"Reparto Nuevo"} />
        </Flex>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <RepartoLoad
            reparto={this.state.reparto}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
          />
        </Flex>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
          <RequestButton propsBoton={{ disabled: !this.state.valid }} texto={"Crear"} onClick={this.onSave} />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET ? this.renderizarTablet() : null}
      </Flex>
    );
  }
}
