import React, { Component } from "react";
import { CANALES_WEB_SOCKET, CuponLectura, CuponService, WebSocketService } from "serviciossaintmichel";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import { Global } from "../../../../../Global";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import { Loader } from "@mantine/core";
import FechaDisplay from "../../../../../compartido/components/fecha-display/FechaDisplay";
import InfoDescuento from "../../../../seccion-descuentos/descuentos/components/show/InfoDescuento";

export interface Props {
  cuponId: number;
}
interface State {
  cupon: CuponLectura | null;
  screen: ScreenType;
  valid: boolean;
}

interface Message {
  type: string;
  idCupon: number;
}

export default class CuponShow extends Component<Props, State> {
  private cuponService: CuponService;

  constructor(props: Props) {
    super(props);
    this.state = {
      cupon: null,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
      valid: false,
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_CUPONES,
      funcionCallback: this.onChannelMesagge,
    });

    this.cuponService = new CuponService(Global.UsuarioService.getToken()!);
  }

  async componentDidMount() {
    const cupon = await this.cuponService.get(this.props.cuponId);
    this.setState({
      cupon: cupon,
    });
  }

  onChannelMesagge = async (e: MessageEvent) => {
    const message: Message = JSON.parse(e.data).message;

    if (message.idCupon === this.props.cuponId) {
      const cupon = await this.cuponService.get(this.props.cuponId);
      this.setState({
        cupon: cupon,
      });
    }
  };

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  renderizarPhone = () => (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex flexDirection="column" width="60%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex flexDirection="column" width="50%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => {
    const { cupon } = this.state;

    if (cupon === null) {
      return null;
    }

    return (
      <Box padding="20px">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <H size={3} texto={`Detalle cupon (id ${this.props.cuponId})`} />
          <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
            <div>
              <strong>Tipo:</strong> {cupon.tipo}
            </div>
            <div>
              <strong>Es automatico:</strong> {cupon.automatico ? "Si" : "No"}
            </div>
            <div>
              <strong>Fecha inicio:</strong> <FechaDisplay fecha={cupon.fecha_inicio} />
            </div>
            <div>
              <strong>Fecha fin:</strong> <FechaDisplay fecha={cupon.fecha_fin} />
            </div>
            <div>
              <strong>Descuento:</strong>
              <Flex flexDirection="column" width="auto" paddingLeft="1rem">
                <InfoDescuento descuento={cupon.descuento} />
              </Flex>
            </div>
          </div>
        </Flex>
      </Box>
    );
  };

  render() {
    if (this.state.cupon === null) {
      return <Loader />;
    }

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.screen === ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen === ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen === ScreenType.TABLET ? this.renderizarTablet() : null}
      </Flex>
    );
  }
}
