import React, { Component } from "react";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import {
  VentaProductoPedido,
  VentaBultoProductoPedido,
  VentaMaquinaPedido,
  VentaPromocionPedido,
} from "serviciossaintmichel";
import VentaBultoProductosPedidoShow from "../ventas/VentaBultoProductosPedidoShow";
import VentaMaquinasPedidoShow from "../ventas/VentaMaquinasPedidoShow";
import VentaProductosPedidoShow from "../ventas/VentaProductosPedidoShow";
import VentaPromocionesPedidoShow from "./VentaPromocionesPedidoShow";
export interface Props {
  ventasProducto: Array<VentaProductoPedido>;
  ventasBultoProducto: Array<VentaBultoProductoPedido>;
  ventasMaquina: Array<VentaMaquinaPedido>;
  ventasPromocion: Array<VentaPromocionPedido>;
}

interface State {
  cargando: boolean;
}

export default class VentasPedidoShow extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cargando: false,
    };
  }

  componentDidMount = async () => {};

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      /* await this.setState({ cargando: false }); */
    }
  };

  renderVentasPedido = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <VentaProductosPedidoShow ventaProductos={this.props.ventasProducto} />
      <VentaBultoProductosPedidoShow
        ventaBultoProductos={this.props.ventasBultoProducto}
      />
      <VentaMaquinasPedidoShow ventaMaquinas={this.props.ventasMaquina} />
      <VentaPromocionesPedidoShow
        ventaPromociones={this.props.ventasPromocion}
      />
    </Flex>
  );

  renderLoading = () => "Cargando Datos";

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        borderRadius="5px"
      >
        {this.state.cargando ? this.renderLoading() : this.renderVentasPedido()}
      </Flex>
    );
  }
}
