import React, { Component } from "react";
import { SelectItem } from "@mantine/core";
import { PropsSelectGenericoItems } from "../select/PropsSelectGenerico";
import SelectBusqueda, { PropsSelectGeneral } from "../select/SelectBusqueda";
import Flex from "../../../libreria/appearance/flex/Flex";
import SelectItemUtils from "../../utils/SelectItemUtils";
import { Propietario } from "serviciossaintmichel";

interface State {}

export default class PropietariosSelect extends Component<
  PropsSelectGenericoItems<Propietario> & PropsSelectGeneral,
  State
> {
  constructor(
    props: PropsSelectGenericoItems<Propietario> & PropsSelectGeneral
  ) {
    super(props);
    this.state = {};
  }

  getPosicion = (valor: Propietario | null): string | null => {
    if (valor != null)
      return this.props.items
        .findIndex((item) => item.id === valor.id)
        .toString();
    else return valor;
  };

  getSeleccionado = (indice: number | null) => {
    if (indice != null) this.props.callbackParent(this.props.items[indice]);
    else this.props.callbackParent(indice);
  };

  armarDatosSelect(): SelectItem[] {
    let arreglo: SelectItem[] = [];
    arreglo = this.props.items.map((item, index) => ({
      value: index.toString(),
      label: `${item.persona.apellido} ${item.persona.nombre}`,
    }));
    return SelectItemUtils.ordenarLabelAlfabeticamente(arreglo);
  }
  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <SelectBusqueda
          {...this.props}
          placeholder="Seleccione un propietario"
          data={this.armarDatosSelect()}
          callbackParent={this.getSeleccionado}
          value={this.getPosicion(this.props.seleccionado)}
        />
      </Flex>
    );
  }
}
