import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import Visibilizador from "../../../../../libreria/action/visibilizador/Visibilizador";
import Flex from "../../../../../libreria/appearance/flex/Flex";

import { CANALES_WEB_SOCKET,        RecorridoReparto,        Reparto,    RepartoService,    WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";



export interface Props {
  idReparto: number;
}
interface State {
  recorridos: Array<RecorridoReparto>;
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
}

export default class RecorridosRepartoShow extends Component<Props, State> {

  private repartoService:RepartoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      recorridos: [],
      visible: false,
      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.repartoService =  new RepartoService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {
    await this.cargarRecorridos();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS,
      funcionCallback: this.onChannelMesagge,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS,
      funcionCallback: this.onChannelMesaggeRecorrido,
    });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.repartoId == this.props.idReparto) {
      await this.cargarRecorridos();
    }
  };

  onChannelMesaggeRecorrido = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (
      this.state.recorridos.find(
        (recorrido) => recorrido.recorrido.id == dato.message.recorridoId
      )
    ) {
      await this.cargarRecorridos();
    }
  };

  cargarRecorridos = async () => {
    let recorridos = await this.repartoService.getRecorridosReparto(
      this.props.idReparto
    ).catch((error) => {
      this.repartoService.manejarErrorHTTP(error, "Reparto");
      return null;
    });
    let recorridosOrdenadosId = recorridos
      ? recorridos.sort((a, b) => a.recorrido.id - b.recorrido.id)
      : [];
    this.setState({ recorridos: recorridosOrdenadosId, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({ cargando: true });
      await this.cargarRecorridos();
    }
  };

  renderRecorridos = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      {this.state.recorridos.length > 0
        ? this.renderizarTabla()
        : this.renderSinRecorridos()}
    </Flex>
  );

  renderSinRecorridos = () => {
    return "No posee Recorridos";
  };

  renderizarFilas = () => {
    return this.state.recorridos.map((recorrido, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{recorrido.recorrido.nombre}</td>
          <td className="text-center">{recorrido.dia_reparto}</td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Recorrido</th>
            <th className="text-center">Dia Reparto</th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  renderLoading = () => "Cargando Recorridos";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="20px"
      >
        <Visibilizador
          onChange={this.onChangeVisibilizador}
          label={"Recorridos"}
          labelAlignment={"flex-start"}
        >
          {this.state.cargando ? this.renderLoading() : this.renderRecorridos()}
        </Visibilizador>
      </Flex>
    );
  }
}
