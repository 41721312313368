import React, { Component } from "react";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import {
  EntregaProgramacionProducto,
  EntregaProgramacionBultoProducto,
  EntregaProgramacionMaquina,
} from "serviciossaintmichel";
import EntregaBultoProductosProgramacionEntregaShow from "./EntregaBultoProductosProgramacionEntregaShow";
import EntregaMaquinasProgramacionEntregaShow from "./EntregaMaquinasProgramacionEntregaShow";
import EntregaProductosProgramacionEntregaShow from "./EntregaProductosProgramacionEntregaShow";
export interface Props {
  entregasProducto: Array<EntregaProgramacionProducto>;
  entregasBultoProducto: Array<EntregaProgramacionBultoProducto>;
  entregasMaquina: Array<EntregaProgramacionMaquina>;
}

interface State {
  cargando: boolean;
}

export default class EntregasProgramacionEntregaShow extends Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cargando: false,
    };
  }

  componentDidMount = async () => {};

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      /* await this.setState({ cargando: false }); */
    }
  };

  renderEntregasProgramacionEntrega = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <EntregaProductosProgramacionEntregaShow
        entregaProductos={this.props.entregasProducto}
      />
      <EntregaBultoProductosProgramacionEntregaShow
        entregaBultoProductos={this.props.entregasBultoProducto}
      />
      <EntregaMaquinasProgramacionEntregaShow
        entregaMaquinas={this.props.entregasMaquina}
      />
    </Flex>
  );

  renderLoading = () => "Cargando Datos";
  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        borderRadius="5px"
      >
        {this.state.cargando
          ? this.renderLoading()
          : this.renderEntregasProgramacionEntrega()}
      </Flex>
    );
  }
}
