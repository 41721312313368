import React, { Component } from "react";
import { Button, ButtonProps, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";

export interface Props {
  onClick: Function;
  propsBoton?: ButtonProps;
  texto: string;
  confirmacion?: boolean;
  msgConfirmation?: string;
  msgOnSuccess?: string;
  msgOnFailure?: string;
  icon?: any;
}

interface State {
  waiting: boolean;
}

export default class RequestButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      waiting: false,
    };
  }

  procesar = async () => {
    if (this.props.confirmacion) {
      let cancelar = false;

      await Swal.fire({
        icon: "question",
        title: this.props.msgConfirmation ? this.props.msgConfirmation : "Confirma la acción?",
        showDenyButton: true,
        confirmButtonText: "Confirmar",
        denyButtonText: "Cancelar",
      }).then((result) => {
        if (result.isDenied) {
          cancelar = true;
        }
      });

      if (cancelar) {
        return;
      }
    }
    this.setState({ waiting: true });

    await this.props.onClick();

    /*
    TODO borrar cuando este terminada la nueva manera de mostrar el error
    try {
      await this.props.onClick();
      Swal.fire({
        icon: "success",
        text: this.props.msgOnSuccess ? this.props.msgOnSuccess : "Operacion realizada con exito",
      })
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: this.props.msgOnFailure ? this.props.msgOnFailure : "Algo salio mal..." + e,
      })
    }
    */
    this.setState({ waiting: false });
  };

  render() {
    const { icon = null, texto, propsBoton } = this.props;
    const { waiting } = this.state;

    return (
      <Button
        {...propsBoton}
        onClick={() => {
          this.procesar();
        }}
        disabled={waiting || propsBoton?.disabled}
      >
        {texto + " "}
        {waiting ? <Spinner animation="border" size="sm" /> : icon}
      </Button>
    );
  }
}
