import React, { Component } from "react";
import { CANALES_WEB_SOCKET, DescuentoEscritura, DescuentoService, WebSocketService } from "serviciossaintmichel";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import { Global } from "../../../../../Global";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Box from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import DescuentoLoad from "../load/DescuentoLoad";

export interface Props {
  onSaveSuccess?: Function;
  onExit?: Function;
}

interface State {
  descuento: DescuentoEscritura;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class DescuentoNew extends Component<Props, State> {
  private descuentoService: DescuentoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      descuento: this.createEmptyDescuento(),
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    this.descuentoService = new DescuentoService(Global.UsuarioService.getToken()!);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  createEmptyDescuento = (): DescuentoEscritura => {
    return { nombre: "", monto: null, porcentaje: null, acumulable: false };
  };

  onSave = async () => {
    try {
      const descuento = await this.descuentoService.create(this.state.descuento);

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.VENTAS_DESCUENTOS, { type: "new", id: descuento.id });

      await Swal.fire({
        icon: "success",
        text: "Descuento creado",
      });

      if (this.props.onSaveSuccess) this.props.onSaveSuccess(descuento.id);
      this.setState({ descuento: this.createEmptyDescuento() });
    } catch (error) {
      this.descuentoService.manejarErrorHTTP(error, "Descuento");
    }
  };

  onExit = () => {
    if (this.props.onExit !== undefined) this.props.onExit();
  };

  onChange = (descuento: DescuentoEscritura) => {
    this.setState({ descuento: descuento });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex flexDirection="column" width="60%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex flexDirection="column" width="50%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <H size={3} texto={"Crear descuento"} />
        </Flex>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <DescuentoLoad
            descuento={this.state.descuento}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
          />
        </Flex>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
          <RequestButton propsBoton={{ disabled: !this.state.valid }} texto={"Crear"} onClick={this.onSave} />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.screen === ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen === ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen === ScreenType.TABLET ? this.renderizarTablet() : null}
      </Flex>
    );
  }
}
