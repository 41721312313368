import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Dia, FranjaHoraria } from "serviciossaintmichel";

import Flex from "../flex/Flex";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Fuentes } from "../../estilos/estilos";
import SelectDia from "./SelectDia";
import Time from "../../utils/Time";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  franja: FranjaHoraria | null;
}

interface State {
  franja: FranjaHoraria;
  validado: boolean;
}

export class FranjaHorariaLoad extends Component<Props, State> {
  maxTime: Time;
  minTime: Time;

  constructor(props: Props) {
    super(props);

    this.state = {
      franja: props.franja || this.crearFranja(),
      validado: props.franja ? this.isValidado(props.franja) : false,
    };

    this.maxTime = Time.crearHorario(22, 0, 0);
    this.minTime = Time.crearHorario(7, 0, 0);
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { validado, franja } = this.state;
    const { onChange, onValidationChange } = this.props;

    if (prevState.validado !== validado) {
      onValidationChange(validado);
    }

    if (prevState.franja !== franja) {
      onChange(franja);
      this.updateValidacion();
    }

    if (prevProps.franja !== this.props.franja) {
      const franjaProps = this.props.franja
        ? this.props.franja
        : this.crearFranja();
      this.setState({ franja: franjaProps });
    }
  };

  crearFranja = (): FranjaHoraria => {
    let inicio = Time.crearHorario(7, 0, 0);
    let fin = Time.crearHorario(22, 0, 0);
    return { inicio: inicio, fin: fin, dia: null };
  };

  newFranja = () => {
    let franja: FranjaHoraria = this.crearFranja();
    this.setState({ franja: franja, validado: false });
  };

  isValidado = (franja: FranjaHoraria): boolean => {
    return !!franja.dia && !!franja.inicio && !!franja.fin;
  };

  updateValidacion = () => {
    let valido: boolean = this.isValidado(this.state.franja);
    this.setState({ validado: valido });
  };

  setearHoraInicio = (hora: Date) => {
    let horario = Time.getTimeFromDate(hora);
    let fin = this.state.franja.fin;

    if (horario > fin) {
      fin = Time.crearHorario(22, 0, 0);
    }

    const nuevaFranja = { ...this.state.franja, inicio: horario, fin: fin };
    this.setState({ franja: nuevaFranja });
  };

  setearHoraFin = (hora: Date) => {
    let horario = Time.getTimeFromDate(hora);
    const nuevaFranja = { ...this.state.franja, fin: horario };
    this.setState({ franja: nuevaFranja });
  };

  setDia = (dia: Dia | null) => {
    const nuevaFranja = { ...this.state.franja, dia: dia };
    this.setState({ franja: nuevaFranja });
  };

  render() {
    const { franja } = this.state;

    return (
      <Flex container flexDirection="column">
        <Form.Group>
          <Form.Label
            style={{ fontFamily: Fuentes.Labels, marginRight: "10px" }}
          >
            Hora Inicio
          </Form.Label>
          <DatePicker
            onChange={this.setearHoraInicio}
            selected={franja.inicio}
            maxTime={this.maxTime}
            minTime={this.minTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label
            style={{ fontFamily: Fuentes.Labels, marginRight: "10px" }}
          >
            Hora Fin
          </Form.Label>
          <DatePicker
            onChange={this.setearHoraFin}
            selected={franja.fin}
            minTime={franja.inicio}
            maxTime={this.maxTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label style={{ fontFamily: Fuentes.Labels }}>Día</Form.Label>
          <SelectDia seleccionado={franja.dia} onChangeDia={this.setDia} />
        </Form.Group>
      </Flex>
    );
  }
}
export default FranjaHorariaLoad;
