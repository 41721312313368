import React, { Component } from "react";
import CheckBox from "../checkbox/CheckBox";
import { Item } from "./Item";
import Flex from "../../appearance/flex/Flex";
import Contenedor from "../../containers/contenedor/Contenedor";
import Text from "../../message/text/Text";
import { Font } from "../models/models";



interface Props{
  items: Array<Item>;
  height?: string;
  width?: string;
  onChange(item:Item): void;
  fontFamily?:string;
}

interface State 
{  
items: Array<Item>;
fontFamily:string;
}

class CheckBoxList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = 
    {
    items:this.props.items,   
    fontFamily:this.props.fontFamily ? this.props.fontFamily : Font.family
    };
  
  }

  componentDidMount() {}



  iguales = (itemsOld:Array<Item>,itemsNew:Array<Item>):boolean => 
  {
  if(itemsOld.length==itemsNew.length)
    {
      let aux=true;
      itemsOld.map((x)=> {
        if(itemsNew.filter((y)=> (y.item.id == x.item.id && y.item.text==x.item.text && y.checked == x.checked ) ).length == 0 ) aux=false;
        });
      return aux;
    }
  else return false;
  }





  componentDidUpdate(prevProps:Props) 
  {

  if(this.iguales(prevProps.items,this.props.items) == false )
      this.setState({items:this.props.items});
  else {}
  }

  onChange = (id: number,checked:boolean) => {
    
      let items:Array<Item> = this.state.items;

      let aux:Array<Item> = items.filter((x)=> x.item.id == id);

      if(aux.length > 0)
        {
        let item:Item = aux[0];
        item.checked = checked;
        this.setState({items:items});
        this.props.onChange(item);
        }

  };




  render() {

    return (
      <Flex
        container
        flexDirection="row"
        width={this.props.width || "500px"}
        height={this.props.height || "250px"}
      >
        <Contenedor
          overflowY="auto"
          borde="1px solid grey"
          radioBorde="5px"
        >
           { this.state.items.length > 0 ?
          
          <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                >
                  {
            this.state.items.map((item: Item,index:number) => (      
              
                
                  <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  width="100%"
                  height="50px"
                  borderBottom={this.state.items.length == (index +1 ) ? "" : "1px solid grey"} 
                  paddingLeft="10px"
                  key={index}
                  >
                    <CheckBox fontFamily={this.state.fontFamily} onChange={this.onChange} data={item.item} checked={item.checked} />
                  </Flex>  
              ))
            }
              </Flex>  
                 
            
          :
          
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            width="100%"
            border="1px solid grey"
            height="150px"
           >
             <Text>No hay items para mostrar</Text>
           </Flex>
          
          }
          
        </Contenedor>
      </Flex>
    );
  }
}


export default CheckBoxList;
