import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import Flex from "../../../../libreria/appearance/flex/Flex";
import RecorridosShowSelect from "./RecorridosShowSelect";
import { PropsSelectGeneral } from "../../../../compartido/components/select/SelectBusqueda";
import RecorridosShowTable from "./RecorridosShowTable";
import { PropsSelectGenerico } from "../../../../compartido/components/select/PropsSelectGenerico";

import { CANALES_WEB_SOCKET,Reparto,  RecorridoService,  WebSocketService, RepartoService, Recorrido } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  onUpdate?: Function;
  onView?: Function;
  version: Version;
  recorridosLibres?: boolean;
  propsSelect?: PropsSelectGenerico<Recorrido> & PropsSelectGeneral;
  filtros?: Array<Function>; // lista de filtros sobre los datos (solo sirve para el select)
}

export enum Version {
  SELECT,
  TABLE,
}

interface State {
  recorridos: Array<Recorrido>;
}

export default class RecorridosShow extends Component<Props, State> {

  private recorridoService:RecorridoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      recorridos: [],
    };

    this.recorridoService =  new RecorridoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS,
      funcionCallback: this.getRecorridos,
    });
    if (this.props.version === Version.TABLE) this.recorridoService.paramsURL.setearLimite(LIMITES[0]);
    this.getRecorridos();
  }

  getRecorridos = () => {
    switch (this.props.version) {
      case Version.SELECT: {
        this.recorridoService.listAll()
          .then((result) => {
            if (this.props.recorridosLibres) result = result.filter((r) => r.reparto == null);
            const { filtros = [] } = this.props;
            result = filtros ? result.filter((item) => filtros.every((fnc) => fnc(item))) : result;
            this.setState({ recorridos: result });
          })
          .catch((error) => {
            this.recorridoService.manejarErrorHTTP(error, "Recorrido");
          });
        break;
      }
      case Version.TABLE: {
        this.recorridoService.list()
          .then((result) => {
            if (this.props.recorridosLibres) result = result.filter((r) => r.reparto == null);
            this.setState({ recorridos: result });
          })
          .catch((error) => {
            this.recorridoService.manejarErrorHTTP(error, "Recorrido");
          });
        break;
      }
    }
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  render() {
    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.props.version === Version.SELECT && this.props.propsSelect ? (
          <RecorridosShowSelect
            {...this.props.propsSelect}
            callbackParent={this.props.propsSelect.callbackParent}
            seleccionado={this.props.propsSelect.seleccionado ? this.props.propsSelect.seleccionado : null}
            items={this.state.recorridos}
          />
        ) : null}
        {this.props.version === Version.TABLE ? (
          <RecorridosShowTable
            onView={this.onView}
            onUpdate={this.onUpdate}
            recorridos={this.state.recorridos}
            recorridosFuncion={this.getRecorridos}
          />
        ) : null}
      </Flex>
    );
  }
}
