import React, { Component } from "react";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../libreria/appearance/margin/Margin";
import { Box } from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import ProductosComboRetornableShow from "./producto/ProductoComboRetornableShow";

import { CANALES_WEB_SOCKET, ComboRetornable,  ComboRetornableService,  WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";


export interface Props {
  idComboRetornable: number;
}

interface State {
  comboRetornable: ComboRetornable | null;
  cargando: boolean;
}

export default class ComboRetornableShow extends Component<Props, State> {

  private comboRetornableService:ComboRetornableService;

  constructor(props: Props) {
    super(props);
    this.state = {
      comboRetornable: null,
      cargando: true,
    };

    this.comboRetornableService =  new ComboRetornableService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    await this.cargarComboRetornable();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_COMBOS_RETORNABLES,
      funcionCallback: this.onChannelMesagge,
    });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.comboRetornableId == this.props.idComboRetornable) {
      await this.cargarComboRetornable();
    }
  };

  cargarComboRetornable = async () => {
    let comboRetornable = await this.comboRetornableService.get(
      this.props.idComboRetornable
    ).catch((error) => {
      this.comboRetornableService.manejarErrorHTTP(error, "ComboRetornable");
      return null;
    });
    this.setState({ comboRetornable: comboRetornable, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      await this.setState({ cargando: true });
      await this.cargarComboRetornable();
    }
  };

  renderComboRetornable = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="10px"
    >
      <H
        alineacion={"center"}
        size={3}
        texto={this.state.comboRetornable!.nombre}
      />
      <Margin top="25px" />
      {
        <ProductosComboRetornableShow
          productos={this.state.comboRetornable!.productos}
        />
      }
      <Margin bottom="50px" />
    </Flex>
  );
  renderLoading = () => "Cargando Datos";

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Margin top="20px" />
        <Box padding="15px">
          {this.state.cargando
            ? this.renderLoading()
            : this.renderComboRetornable()}
        </Box>
      </Flex>
    );
  }
}
