import React, { Component } from "react";
import Ruta from "../../compartido/models/Ruta";
import Seccion from "../../compartido/components/seccion/Seccion";
import Pedidos from "./pedidos/components/Pedidos";
import ProgramacionesEntrega from "./programaciones-entrega/components/ProgramacionesEntregas";
import { Global } from "../../Global";

export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];
  if (Global.UsuarioService.getPermisos().Ventas.Pedidos.Pedido.hasAccess()) {
    aux.push({
      name: "Pedidos",
      path: SeccionPedidos.pathBase + "/pedidos",
      component: Pedidos,
    });
  }
  if (
    Global.UsuarioService.getPermisos().Ventas.Pedidos.ProgramacionEntrega.hasAccess()
  ) {
    aux.push({
      name: "Programaciones Entrega",
      path: SeccionPedidos.pathBase + "/programaciones-entrega",
      component: ProgramacionesEntrega,
    });
  }
  return aux;
}

export default class SeccionPedidos extends Component<Props> {
  static pathBase = "/ventas/seccion-pedidos/pedidos";
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Pedidos"}
      />
    );
  }
}
