import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../../compartido/models/Screen";
import Flex from "../../../../../../../libreria/appearance/flex/Flex";
import RequestButton from "../../../../../../../compartido/components/request-button/RequestButton";
import * as Icon from "react-bootstrap-icons";
import EmailClienteUpdate from "./EmailClienteUpdate";



import { CANALES_WEB_SOCKET, ClienteService, Email, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../../Global";



export interface Props {
  clienteId: number;
}

interface State {
  screen: ScreenType;
  emails: Array<Email>;
  emailId: number | null;
}

export default class EmailsClienteUpdate extends Component<Props, State> {

  private clienteService:ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      emailId: null,
      emails: [],
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = () => {
    window.addEventListener("resize", this.actualizarDimensiones);

    this.getEmails();

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_EMAILS,
      funcionCallback: this.onChannelMessage,
    });
  };

  onChannelMessage = async (e: MessageEvent) => {
    let message = JSON.parse(e.data).message;
    const { clienteId } = this.props;

    if (message.clienteId === clienteId) {
      this.getEmails();
    }
  };

  getEmails = async () => {
    const emails = await this.clienteService.getEmails(this.props.clienteId);
    this.setState({ emails: emails });
  };

  actualizarDimensiones = () => {
    this.setState({ screen: Screen.getType(window.innerWidth, window.innerHeight) });
  };

  onUpdateRequest = (emailId: number) => {
    this.setState({ emailId: emailId });
  };

  eliminarEmail = async (emailId: number) => {
    let indice = this.state.emails.findIndex((e) => e.id === emailId);
    const emailObjetivo = { ...this.state.emails[indice] };
    try {
      await this.clienteService.eliminarEmail(this.props.clienteId, emailObjetivo.id);
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_EMAILS, {
        id: emailObjetivo.id,
        clienteId: emailObjetivo.cliente,
      });
      if (emailId === this.state.emailId) {
        this.setState({ emailId: null });
      }
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error, "Cliente");
    }
  };

  renderizarFilas = () => {
    return this.state.emails.map((email, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{email.email}</td>
          <td className="text-center">{email.descripcion}</td>
          <td className="text-center">
            <Button variant={"light"} onClick={() => this.onUpdateRequest(email.id)}>
              <Icon.ArrowRepeat />
            </Button>
          </td>
          <td className="text-center">
            <RequestButton
              confirmacion={true}
              texto=""
              propsBoton={{ variant: "light" }}
              icon={<Icon.Trash />}
              onClick={() => this.eliminarEmail(email.id)}
            />
          </td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Email</th>
            <th className="text-center">Descripción</th>
            <th className="text-center">Actualizar</th>
            <th className="text-center">Eliminar</th>
          </tr>
        </thead>

        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  render() {
    const { emails, emailId } = this.state;
    const { clienteId } = this.props;

    if (emails.length === 0) {
      return <div>El cliente no posee emails asociados</div>;
    }

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="2rem">
          {this.renderizarTabla()}
        </Flex>
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="2rem"
        >
          {emailId && (
            <EmailClienteUpdate
              clienteId={clienteId}
              emailId={emailId}
              onCloseCallback={() => this.setState({ emailId: null })}
            />
          )}
        </Flex>
      </Flex>
    );
  }
}
