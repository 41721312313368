import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import ArticulosGraficos from "./ArticuloGrafico/components/ArticulosGraficos";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];
  
  if (Global.UsuarioService.getPermisos().Elementos.ArticulosGraficos.ArticuloGrafico.hasAccess()) {
    aux.push({
      name: "Articulos Graficos",
      path: SeccionArticulosGraficos.pathBase + "/articulos-grafico",
      component: ArticulosGraficos,
    })
  }
  return aux;
}

export default class SeccionArticulosGraficos extends Component<Props> {
  static pathBase = "/elementos/seccion-articulos-grafico";
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Articulos Graficos"}
      />
    );
  }
}
