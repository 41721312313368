import React, { Component } from "react";
import { MedioComunicacion } from "serviciossaintmichel";
import SelectGenericoNull from "../../../../../../compartido/components/select/SelectGenericoNull";

interface Props {
  callbackParent: Function;
  seleccionado: number | null;
  mediosComunicacion: Array<MedioComunicacion>;
}
interface State {
  seleccionado: number | null;
}

export default class SelectMedioComunicacion extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado,
    };
  }

  getPosicion = (
    valor: number,
    mediosComunicacion: Array<MedioComunicacion>
  ): number => {
    return mediosComunicacion.findIndex((i) => i.id === valor);
  };

  getSeleccionado = (indice: number) => {
    const { mediosComunicacion } = this.props;
    if (indice >= 0) this.props.callbackParent(mediosComunicacion[indice]);
    else this.props.callbackParent(null);
  };

  render() {
    const { mediosComunicacion, seleccionado } = this.props;
    const elementoSeleccionado = seleccionado
      ? this.getPosicion(seleccionado, mediosComunicacion)
      : null;

    return (
      <SelectGenericoNull
        seleccionado={elementoSeleccionado}
        elementos={mediosComunicacion.map((i) => i.medio)}
        callback={this.getSeleccionado}
      />
    );
  }
}
