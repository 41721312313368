import React, { Component, ReactElement } from "react";
import { CANALES_WEB_SOCKET, CargamentoLectura, RepartoDiarioService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";
import Flex from "../../../../libreria/appearance/flex/Flex";




export interface Props {
  idRepartoDiario: number;
  renderMethod: (c: CargamentoLectura) => ReactElement;
}

interface State {
  cargas: Array<CargamentoLectura> | null;
}

export default class RepartoDiarioCargasShow extends Component<Props, State> {
  private webSockets: Array<WebSocket>;

  private repartoDiarioService:RepartoDiarioService;


  constructor(props: Props) {
    super(props);
    this.webSockets = [];
    this.state = {
      cargas: null,
    };

    this.repartoDiarioService =  new RepartoDiarioService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    this.setCargas();
    this.webSockets.push(
      WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
        funcionCallback: this.onChannelMessage,
      })
    );
  };

  componentWillUnmount = () => {
    this.webSockets.forEach((ws: WebSocket) => ws.close());
  };

  setCargas = async () => {
    const cargas = await this.repartoDiarioService.getCargas(this.props.idRepartoDiario);
    this.setState({ cargas: cargas });
  };

  onChannelMessage = (message: MessageEvent) => {
    const dato = JSON.parse(message.data);
    if (dato.message.idRepartoDiario !== this.props.idRepartoDiario) {
      return;
    }
    this.setCargas();
  };

  render() {
    const { cargas } = this.state;
    const { renderMethod } = this.props;

    if (cargas === null) {
      return <div>Cargando...</div>;
    }

    return (
      <Flex gap="1rem" flexWrap="wrap" marginBottom="1rem">
        {cargas.length > 0 ? (
          cargas.map((carga) => {
            return renderMethod(carga);
          })
        ) : (
          <span>No posee cargas asociadas</span>
        )}
      </Flex>
    );
  }
}
