import React, { Component } from "react";
import Seccion from "../compartido/components/seccion/Seccion";
export interface Props {}

export default class SeccionInicio extends Component<Props> {
  rutasInicio = [];

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Seccion
        rutasNavbar={this.rutasInicio}
        tituloSeccion={"Este es el Inicio"}
        tituloNavbar={""}
      />
    );
  }
}
