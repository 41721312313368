import React, { Component } from "react";
import { PropsSelectGeneral } from "../../../../../compartido/components/select/SelectBusqueda";
import { PropsSelectGenerico } from "../../../../../compartido/components/select/PropsSelectGenerico";
import { LIMITES } from "../../../../../compartido/components/select/SelectLimiteTabla";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import BancosShowSelect from "./BancosShowSelect";

import { Banco, BancoService, CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";


export interface Props {
  onUpdate?: Function;
  onView?: Function;
  version: Version;
  propsSelect?: PropsSelectGenerico<Banco> & PropsSelectGeneral;
}

export enum Version {
  SELECT,
  TABLE,
}

interface State {
  bancos: Array<Banco>;
}

export default class BancosShow extends Component<Props, State> {

  private bancoService:BancoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      bancos: [],
    };

    this.bancoService =  new BancoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    /* WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_BANCOS,
      funcionCallback: this.getBancos,
    }); */
    if (this.props.version == Version.TABLE)
      this.bancoService.paramsURL.setearLimite(LIMITES[0]);
    this.getBancos();
  }

  getBancos = () => {
    this.bancoService.listAll()
      .then((result) => {
        this.setState({ bancos: result });
      })
      .catch((error) => {
        this.bancoService.manejarErrorHTTP(error, "Banco");
      });
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.props.version == Version.SELECT && this.props.propsSelect ? (
          <BancosShowSelect
            {...this.props.propsSelect}
            callbackParent={this.props.propsSelect.callbackParent}
            seleccionado={
              this.props.propsSelect.seleccionado
                ? this.props.propsSelect.seleccionado
                : null
            }
            items={this.state.bancos}
          />
        ) : null}
        {this.props.version == Version.TABLE
          ? {
              /* <BancosShowTable
            onView={this.onView}
            onUpdate={this.onUpdate}
            bancos={this.state.bancos}
            bancosFuncion={this.getBancos}
          /> */
            }
          : null}
      </Flex>
    );
  }
}
