import React, { Component } from "react";
import { Table } from "react-bootstrap";
import EntregaProductoProgramacionEntregaLoad from "./EntregaProductoProgramacionEntregaLoad";
import { EntregaProgramacionProducto, EstadoLogisticaPaquete } from "serviciossaintmichel";
import { Select } from "@mantine/core";

interface ConfigCampo {
  disabled: Array<string>;
  hidden: Array<string>;
}

export interface Props {
  crearNuevos?: boolean;
  configCampos?: ConfigCampo;
  entregasProducto: Array<EntregaProgramacionProducto>;
  onChange?: Function;
}

interface State {
  entregasProducto: Array<EntregaProgramacionProducto>;
}

export default class EntregasProductoProgramacionEntregaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entregasProducto: props.entregasProducto,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (
      prevProps.entregasProducto !== this.props.entregasProducto &&
      this.state.entregasProducto !== this.props.entregasProducto
    ) {
      this.setState({ entregasProducto: this.props.entregasProducto });
    }

    if (prevState.entregasProducto !== this.state.entregasProducto && this.props.onChange) {
      this.props.onChange(this.state.entregasProducto);
    }
  }

  eliminarEntregaProducto = (idProducto: number) => {
    const entregasProducto = this.state.entregasProducto.filter(
      (vp: EntregaProgramacionProducto) => vp.paquete.producto.id !== idProducto
    );
    this.setState({
      entregasProducto: [...entregasProducto],
    });
  };

  agregarEntregaProducto = (nuevaEntregaProducto: EntregaProgramacionProducto): void => {
    this.setState((prevState) => ({
      entregasProducto: [...prevState.entregasProducto, nuevaEntregaProducto],
    }));
  };

  actualizarEntregaProducto = (infoEntrega: EntregaProgramacionProducto) => {
    const ventas = this.state.entregasProducto.map((ep) =>
      ep.paquete.id === infoEntrega.paquete.id ? infoEntrega : ep
    );
    this.setState({
      entregasProducto: ventas,
    });
  };

  estaDeshabilitado = (id: string): boolean => {
    if (!this.props.configCampos) {
      return false;
    }

    return this.props.configCampos.disabled.indexOf(id) !== -1;
  };

  render() {
    const { entregasProducto } = this.state;
    const { crearNuevos } = this.props;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Producto</th>
              <th>Cantidad</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {entregasProducto.map((entregaProducto, idx) => (
              <tr key={entregaProducto.paquete.producto.id}>
                <td>{entregaProducto.paquete.producto.nombre}</td>
                <td>{entregaProducto.paquete.cantidad}</td>
                <td>
                  <Select
                    data={[
                      EstadoLogisticaPaquete.ENTREGADO,
                      EstadoLogisticaPaquete.NO_ENTREGADO,
                      EstadoLogisticaPaquete.RECHAZADO,
                    ]}
                    value={entregaProducto.estado}
                    onChange={(seleccionado: EstadoLogisticaPaquete) => {
                      this.actualizarEntregaProducto({
                        ...entregaProducto,
                        estado: seleccionado,
                      });
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {crearNuevos ? (
          <EntregaProductoProgramacionEntregaLoad
            onSubmitCallback={this.agregarEntregaProducto}
            excluirProductos={entregasProducto.map((ep) => ep.paquete.producto.id)}
          />
        ) : null}
      </>
    );
  }
}
