import { Item } from "../../../../compartido/components/checkboxlist/Item";

export default class ItemGrupoPermisos<T> implements Item<T> {
  id: number;
  nombre: string;
  estado: boolean;
  objeto: T;

  constructor(id: number, nombre: string, estado: boolean, objeto: T) {
    this.id = id;
    this.nombre = nombre;
    this.estado = estado;
    this.objeto = objeto;
  }

  equalsTo(item: Item<T>): boolean {
    if (this.id == item.id) return true;
    else return false;
  }

  copyTo(item: Item<T>): void {
    this.id = item.id;
    this.nombre = item.nombre;
    this.estado = item.estado;
    this.objeto = item.objeto;
  }

  getCopy(): Item<T> {
    return new ItemGrupoPermisos(this.id,this.nombre,this.estado,this.objeto);
  }


}
