import React, { Component } from "react";
import * as Icon from "react-bootstrap-icons";
import { Table, Button } from "react-bootstrap";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import VentaPromocionLoad from "./VentaPromocionLoad";
import { VentaPromocionLectura } from "serviciossaintmichel";

export interface Props {
  ventasPromo: Array<VentaPromocionLectura>;
  onChange?: Function;
}

interface State {
  ventasPromo: Array<VentaPromocionLectura>;
}

export default class VentasPromocionLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ventasPromo: props.ventasPromo,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (prevProps.ventasPromo !== this.props.ventasPromo && this.state.ventasPromo !== this.props.ventasPromo) {
      this.setState({ ventasPromo: this.props.ventasPromo });
    }

    if (prevState.ventasPromo !== this.state.ventasPromo && this.props.onChange) {
      this.props.onChange(this.state.ventasPromo);
    }
  }

  eliminarVentaPromo = (idPromo: number) => {
    const ventasPromo = this.state.ventasPromo.filter((vp: VentaPromocionLectura) => vp.promocion.id !== idPromo);
    this.setState({
      ventasPromo: [...ventasPromo],
    });
  };

  agregarVentaPromo = (nuevaVentaPromo: VentaPromocionLectura): void => {
    this.setState((prevState) => ({
      ventasPromo: [...prevState.ventasPromo, nuevaVentaPromo],
    }));
  };

  actualizarVentaPromo = (idPromo: number, infoVenta: VentaPromocionLectura) => {
    const ventas = this.state.ventasPromo.map((vp) => (vp.promocion.id === idPromo ? infoVenta : vp));
    this.setState({
      ventasPromo: ventas,
    });
  };

  render() {
    const { ventasPromo } = this.state;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Promoción</th>
              <th>Cantidad</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {ventasPromo.map((ventaPromo, idx) => (
              <tr key={ventaPromo.promocion.id}>
                <td>{ventaPromo.promocion.nombre}</td>
                <td>
                  <Input
                    type={InputTypes.Number}
                    data={ventaPromo.cantidad}
                    onChange={(valor: number) => {
                      this.actualizarVentaPromo(ventaPromo.promocion.id, {
                        ...ventaPromo,
                        cantidad: valor,
                      });
                    }}
                    min={1}
                  />
                </td>

                <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      this.eliminarVentaPromo(ventaPromo.promocion.id);
                    }}
                  >
                    <Icon.Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <VentaPromocionLoad
          onSubmitCallback={this.agregarVentaPromo}
          excluirPromos={ventasPromo.map((vp) => vp.promocion.id)}
        />
      </>
    );
  }
}
