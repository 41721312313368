import React, { Component } from "react";
import MaquinaServicioSimbolicaActualizarPropio from "./MaquinaServicioSimbolicaActualizarPropio";
import MaquinaServicioSimbolicaActualizarProductos from "./MaquinaServicioSimbolicaActualizarProductos";
import Flex from "../../../../compartido/components/flex/Flex";
import { Tabs, Tab } from "react-bootstrap";

interface Props {
  maquinaServicioSimbolicaId: number;
  recienCreado: boolean;
}

interface State {
  responsive: boolean;
}

export default class MaquinasServicioSimbolica extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      responsive: window.innerWidth < 700 ? true : false,
    };

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey={
        this.props.recienCreado ? "productos" : "maquinaServicioSimbolica"
      }
      transition={false}
      variant="pills"
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "center" : "",
      }}
      unmountOnExit={true}
    >
      <Tab
        eventKey="maquinaServicioSimbolica"
        title="Maquina Servicio Simbolica"
      >
        <MaquinaServicioSimbolicaActualizarPropio
          maquinaServicioSimbolicaId={this.props.maquinaServicioSimbolicaId}
        />
      </Tab>
      <Tab eventKey="productos" title="Productos de la Máquina">
        <MaquinaServicioSimbolicaActualizarProductos
          maquinaServicioSimbolicaId={this.props.maquinaServicioSimbolicaId}
        />
      </Tab>
    </Tabs>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {this.renderizar(this.state.responsive)}
      </Flex>
    );
  }
}
