import React, { Component } from "react";
import ListGroup from "react-bootstrap/esm/ListGroup";

import { CANALES_WEB_SOCKET, WebSocketService, ZonaGet, ZonaService } from "serviciossaintmichel";
import { Global } from "../../../Global";
import { PropsSelectGenerico } from "../select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../select/SelectBusqueda";
import SelectComponent from "../select/SelectComponent";

export enum Version {
  SELECT,
  LIST,
}

interface Props {
  zonasId?: Array<number>;
  version: Version;
  propsSelect?: PropsSelectGenerico<ZonaGet> & PropsSelectGeneral;
}

interface State {
  zonas: Array<ZonaGet>;
}

export default class ZonasShow extends Component<Props, State> {
  private zonaService: ZonaService;

  constructor(props: Props) {
    super(props);

    this.state = {
      zonas: [],
    };

    this.zonaService = new ZonaService(Global.UsuarioService.getToken()!);
  }

  setZonas = async () => {
    try {
      let zonas = await this.zonaService.listAll();
      const { zonasId = [] } = this.props;

      if (zonasId.length > 0) {
        zonas = zonas.filter((zona) => zonasId.indexOf(zona.id) > -1);
      }

      this.setState({ zonas: zonas });
      return zonas;
    } catch (error) {
      this.zonaService.manejarErrorHTTP(error, "Zona");
      return [];
    }
  };

  async componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.MAPAS_ZONAS,
      funcionCallback: this.setZonas,
    });

    this.setZonas();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.zonasId !== this.props.zonasId) {
      this.setZonas();
    }
  }

  render() {
    const { zonas } = this.state;
    const { version, propsSelect } = this.props;

    if (zonas.length === 0) {
      return <div>No se registraron zonas</div>;
    }

    switch (version) {
      case Version.SELECT: {
        if (!propsSelect) {
          return null;
        }

        return (
          <SelectComponent
            {...propsSelect}
            items={zonas}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado}
            labelGetter={(obj: ZonaGet) => obj.nombre}
          />
        );
      }
      case Version.LIST: {
        return (
          <ListGroup style={{ width: "100%" }}>
            {zonas.map((zona) => (
              <ListGroup.Item style={{ textAlign: "center", width: "100%" }}>{zona.nombre}</ListGroup.Item>
            ))}
          </ListGroup>
        );
      }
      default:
        return null;
    }
  }
}
