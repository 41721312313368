import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import FacturasShow from "./FacturasShow";
import FacturaShow from "./FacturaShow";
import { Global } from "../../../Global";
import FacturadorServicios from "./FacturadorServicios";
import PagosShow from "./PagosShow";

interface Props {}

interface State {
  tabVisible: string;
  responsive: boolean;
  idDetalle: number | null;
}

export default class Facturacion extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista-facturas",
      responsive: window.innerWidth < 700 ? true : false,
      idDetalle: null,
    };

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  render() {
    const { tabVisible, responsive, idDetalle } = this.state;

    return (
      <Tabs
        defaultActiveKey="lista-facturas"
        transition={false}
        activeKey={tabVisible}
        onSelect={this.onSelect}
        variant={responsive ? "pills" : "tabs"}
        style={{
          marginBottom: "1rem",
          flexDirection: responsive ? "column" : "row",
          alignItems: responsive ? "flex-start" : "",
        }}
        unmountOnExit={true}
      >
        {Global.UsuarioService.getPermisos().Administracion.Facturacion.Facturacion.canView() && (
          <Tab eventKey="lista-facturas" title="Facturas">
            <FacturasShow
              version="tabla"
              onView={(id: number) => {
                this.setState({ idDetalle: id, tabVisible: "detalle-factura" });
              }}
            />
          </Tab>
        )}
        {Global.UsuarioService.getPermisos().Administracion.Facturacion.Facturacion.canView() && (
          <Tab eventKey="detalle-factura" title="Detalle factura" disabled={idDetalle === null}>
            {idDetalle !== null && <FacturaShow facturaId={idDetalle} />}
          </Tab>
        )}
        {Global.UsuarioService.getPermisos().Administracion.Facturacion.Facturacion.canView() && (
          <Tab eventKey="facturar-servicios" title="Facturar servicio">
            <FacturadorServicios />
          </Tab>
        )}
        {Global.UsuarioService.getPermisos().Administracion.Facturacion.Facturacion.canView() && (
          <Tab eventKey="lista-pagos" title="Pagos">
            <PagosShow version="tabla" />
          </Tab>
        )}
      </Tabs>
    );
  }
}
