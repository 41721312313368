import React, { Component } from "react";
import { Fuentes } from "../../../../../../compartido/estilos/estilos";
import Box from "../../../../../../libreria/containers/box/Box";
import Select from "../../../../../../libreria/data/select/Select";
import H from "../../../../../../libreria/message/title/H";
import AlquilerClienteNew from "./alquileres/AlquilerClienteNew";
import ConsumoClienteNew from "./consumos/ConsumoClienteNew";
import OrdenCompraNew from "./ordenes-compra/OrdenCompraNew";

export interface Props {
  clienteId: number;
  fechaInicioMin?: Date;
}

interface State {
  tipoServicio: string | null;
}

export default class ServicioClienteNew extends Component<Props, State> {
  opciones = [
    { id: 1, text: "Alquiler" },
    { id: 2, text: "Consumo" },
    { id: 3, text: "Orden de Compra" },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      tipoServicio: null,
    };
  }

  evaluarOpcionId = (id: number): string | null => {
    const res = this.opciones.filter((opc) => opc.id === id);
    if (res.length > 0) {
      return res[0].text;
    }
    return null;
  };

  onChangeSelect = (id: number) => {
    const opcion = this.evaluarOpcionId(id);
    this.setState({ tipoServicio: opcion });
  };

  render = () => {
    const { tipoServicio } = this.state;
    const { clienteId, fechaInicioMin } = this.props;

    let component;
    let titulo = "";

    switch (tipoServicio) {
      case "Alquiler": {
        component = <AlquilerClienteNew clienteId={clienteId} fechaInicioMin={fechaInicioMin} />;
        titulo = "Alquiler";
        break;
      }
      case "Consumo": {
        component = <ConsumoClienteNew clienteId={clienteId} fechaInicioMin={fechaInicioMin} />;
        titulo = "Consumo";
        break;
      }
      case "Orden de Compra": {
        component = <OrdenCompraNew clienteId={clienteId} fechaInicioMin={fechaInicioMin} />;
        titulo = "Orden de compra";
        break;
      }
      default: {
        component = null;
        break;
      }
    }

    return (
      <div style={{ marginTop: "1rem" }}>
        <div>Añadir servicio</div>
        <Select elements={this.opciones} onChange={this.onChangeSelect} />
        {component && (
          <Box width="100%" height="auto" paddingVertical={"2rem"} paddingHorizontal={"2rem"}>
            <H size={4} fuente={Fuentes.Titulos} texto={titulo} />
            {component}
          </Box>
        )}
      </div>
    );
  };
}
