import React, { Component } from "react";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import ManejadorErroresHTTP from "../../../../compartido/utils/ManejadorErroresHTTP";
import CargaLoad from "../load/CargaLoad";

import { CANALES_WEB_SOCKET,          CargamentoLectura,          RepartoDiarioService,   WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  idRepartoDiario: number;
  idCarga: number;
}

interface State {
  cargaFormulario: CargamentoLectura | null;
  cargaValida: boolean;
}

export default class CargaUpdate extends Component<Props, State> {

  private repartoDiarioService:RepartoDiarioService;

  private webSockets: Array<WebSocket>;
  constructor(props: Props) {
    super(props);
    this.state = {
      cargaFormulario: null,
      cargaValida: false,
    };
    this.webSockets = [];

    this.repartoDiarioService =  new RepartoDiarioService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.setCarga();
    const ws = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
      funcionCallback: this.onChannelMessage,
    });
    this.webSockets.push(ws);
  }

  componentWillUnmount(): void {
    this.webSockets.forEach((ws) => ws.close());
  }

  onChannelMessage = (message: MessageEvent) => {
    const dato = JSON.parse(message.data);
    if (dato.message.idRepartoDiario !== this.props.idRepartoDiario) {
      return;
    }
    this.setCarga();
  };

  setCarga = async () => {
    try {
      const carga = await this.repartoDiarioService.getCarga(this.props.idRepartoDiario, this.props.idCarga);
      this.setState({ cargaFormulario: carga });
    } catch (error) {
      ManejadorErroresHTTP.evaluarError(error);
    }
  };

  onChange = (carga: CargamentoLectura) => {
    this.setState({ cargaFormulario: carga });
  };

  onValidezChange = (validez: boolean) => {
    this.setState({ cargaValida: validez });
  };

  guardarCambios = async () => {
    if (this.state.cargaFormulario === null) {
      return;
    }

    try {
      await this.repartoDiarioService.modificarCarga(
        this.props.idRepartoDiario,
        this.props.idCarga,
        this.state.cargaFormulario
      );
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS, {
        idRepartoDiario: this.props.idRepartoDiario,
      });
      Swal.fire({
        icon: "success",
        text: "Datos guardados con éxito",
      });
    } catch (error) {
      const msjError = ManejadorErroresHTTP.getMensajeError(error);
      Swal.fire({
        icon: "error",
        text: "Ocurrió un error - " + msjError,
      });
    }
  };

  render() {
    const { cargaFormulario, cargaValida } = this.state;

    if (cargaFormulario === null) {
      return <div>Cargando...</div>;
    }

    return (
      <div>
        <CargaLoad carga={cargaFormulario} onChange={this.onChange} onValidezChange={this.onValidezChange} />
        <Button variant="success" style={{ marginTop: "1rem" }} disabled={!cargaValida} onClick={this.guardarCambios}>
          Guardar
        </Button>
      </div>
    );
  }
}
