import React, { Component } from "react";
import { EstadoProgramacion, EstadoProgramacionEnum, MotivoRechazo } from "serviciossaintmichel";
import SelectComponent from "../../../../compartido/components/select/SelectComponent";
import InputText from "../../../../libreria/data/input/text/InputText";
import MotivosRechazoShow, { Version } from "../show/MotivosRechazoShow";

export interface Props {
  estadoProgramacion: EstadoProgramacion;
  onChange?: Function;
}

interface State {
  estadoFormulario: EstadoProgramacion;
  motivoSeleccionado: MotivoRechazo | null;
  estadoSeleccionado: { id: EstadoProgramacionEnum } | null;
}

export default class EstadoProgramacionEntregaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      estadoFormulario: props.estadoProgramacion,
      motivoSeleccionado: props.estadoProgramacion.motivo,
      estadoSeleccionado: { id: props.estadoProgramacion.estado },
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.props.onChange && prevState.estadoFormulario !== this.state.estadoFormulario) {
      this.props.onChange(this.state.estadoFormulario);
    }
  }

  setEstado = (estado: { id: EstadoProgramacionEnum } | null) => {
    if (estado === null) {
      return;
    }

    if (estado.id !== EstadoProgramacionEnum.RECHAZADA && estado.id !== EstadoProgramacionEnum.NO_ENTREGADA) {
      this.setState((prevState) => ({
        estadoFormulario: { ...prevState.estadoFormulario, estado: estado.id, motivo: null, detalle: null },
        estadoSeleccionado: estado,
      }));
    }
    this.setState((prevState) => ({
      estadoFormulario: { ...prevState.estadoFormulario, estado: estado?.id },
      estadoSeleccionado: estado,
    }));
  };

  setMotivoRechazo = (motivoRechazo: MotivoRechazo | null) => {
    this.setState((prevState) => ({
      estadoFormulario: { ...prevState.estadoFormulario, motivo: motivoRechazo },
      motivoSeleccionado: motivoRechazo,
    }));
  };

  setDetalle = (detalle: string) => {
    this.setState((prevState) => ({
      estadoFormulario: { ...prevState.estadoFormulario, detalle: detalle },
    }));
  };

  render() {
    const { estadoFormulario, motivoSeleccionado, estadoSeleccionado } = this.state;

    return (
      <div style={{ marginBottom: "1rem" }}>
        <div>
          <strong>Estado</strong>
          <SelectComponent
            items={[
              { id: EstadoProgramacionEnum.EN_CAMINO },
              { id: EstadoProgramacionEnum.ENTREGADA },
              { id: EstadoProgramacionEnum.NO_ENTREGADA },
              { id: EstadoProgramacionEnum.RECHAZADA },
              { id: EstadoProgramacionEnum.CON_RECHAZOS_PARCIALES },
            ]}
            callbackParent={this.setEstado}
            seleccionado={estadoSeleccionado}
            labelGetter={(estado) => estado.id}
          />
        </div>
        {estadoFormulario.estado === EstadoProgramacionEnum.RECHAZADA ||
        estadoFormulario.estado === EstadoProgramacionEnum.NO_ENTREGADA ? (
          <>
            <div>
              <strong>Motivo de rechazo/no entrega</strong>
              <MotivosRechazoShow
                version={Version.SELECT}
                propsSelect={{
                  callbackParent: this.setMotivoRechazo,
                  seleccionado: motivoSeleccionado,
                }}
              />
            </div>
            <div>
              <strong>Detalle</strong>
              <InputText
                data={estadoFormulario.detalle !== null ? estadoFormulario.detalle : undefined}
                onChange={this.setDetalle}
              />
            </div>
          </>
        ) : null}
      </div>
    );
  }
}
