import React, { Component } from "react";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import ManejadorErroresHTTP from "../../../../compartido/utils/ManejadorErroresHTTP";
import CargaLoad from "../load/CargaLoad";


import { CANALES_WEB_SOCKET,          CargamentoLectura,          RepartoDiarioService,   WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";





export interface Props {
  idRepartoDiario: number;
  idDescarga: number;
}

interface State {
  descargaFormulario: CargamentoLectura | null;
  descargaValida: boolean;
}

export default class DescargaUpdate extends Component<Props, State> {
  private webSockets: Array<WebSocket>;
  private repartoDiarioService:RepartoDiarioService;

  constructor(props: Props) {
    super(props);
    this.state = {
      descargaFormulario: null,
      descargaValida: false,
    };
    this.webSockets = [];
    this.repartoDiarioService =  new RepartoDiarioService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.setDescarga();
    const ws = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
      funcionCallback: this.onChannelMessage,
    });
    this.webSockets.push(ws);
  }

  componentWillUnmount(): void {
    this.webSockets.forEach((ws) => ws.close());
  }

  onChannelMessage = (message: MessageEvent) => {
    const dato = JSON.parse(message.data);
    if (dato.message.idRepartoDiario !== this.props.idRepartoDiario) {
      return;
    }
    this.setDescarga();
  };

  setDescarga = async () => {
    try {
      const descarga = await this.repartoDiarioService.getDescarga(this.props.idRepartoDiario, this.props.idDescarga);
      this.setState({ descargaFormulario: descarga });
    } catch (error) {
      ManejadorErroresHTTP.evaluarError(error);
    }
  };

  onChange = (descarga: CargamentoLectura) => {
    this.setState({ descargaFormulario: descarga });
  };

  onValidezChange = (validez: boolean) => {
    this.setState({ descargaValida: validez });
  };

  guardarCambios = async () => {
    if (this.state.descargaFormulario === null) {
      return;
    }

    try {
      await this.repartoDiarioService.modificarDescarga(
        this.props.idRepartoDiario,
        this.props.idDescarga,
        this.state.descargaFormulario
      );
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS, {
        idRepartoDiario: this.props.idRepartoDiario,
      });
      Swal.fire({
        icon: "success",
        text: "Datos guardados con éxito",
      });
    } catch (error) {
      const msjError = ManejadorErroresHTTP.getMensajeError(error);
      Swal.fire({
        icon: "error",
        text: "Ocurrió un error - " + msjError,
      });
    }
  };

  render() {
    const { descargaFormulario, descargaValida } = this.state;

    if (descargaFormulario === null) {
      return <div>Cargando...</div>;
    }

    return (
      <div>
        <CargaLoad carga={descargaFormulario} onChange={this.onChange} onValidezChange={this.onValidezChange} />
        <Button
          variant="success"
          style={{ marginTop: "1rem" }}
          disabled={!descargaValida}
          onClick={this.guardarCambios}
        >
          Guardar
        </Button>
      </div>
    );
  }
}
