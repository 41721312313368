import SeccionInicio from "../inicio/SeccionInicio";
import SeccionProductos from "../elementos/productos/SeccionProductos";
import SeccionInsumos from "../elementos/insumos/SeccionInsumos";
import SeccionPersonal from "../administracion/seccion-personal/SeccionPersonal";
import SeccionIndumentarias from "../elementos/indumentarias/SeccionIndumentaria";
import SeccionProductosLimpieza from "../elementos/productosLimpieza/SeccionProductoLimpieza";
import SeccionProductosSeguridadHigiene from "../elementos/productosSeguridadHigiene/SeccionProductoSeguridadHigiene";
import SeccionMateriales from "../elementos/materiales/SeccionMateriales";
import SeccionHerramientas from "../elementos/herramientas/SeccionHerramienta";
import SeccionArticulosOficina from "../elementos/articulosOficina/SeccionArticuloOficina";
import SeccionElementosComputacion from "../elementos/elementosComputacion/SeccionElementoComputacion";
import SeccionRepuestos from "../elementos/repuestos/SeccionRepuesto";
import SeccionVehiculos from "../elementos/vehiculos/SeccionVehiculos";
import SeccionArticulosGraficos from "../elementos/articulosGraficos/SeccionArticuloGrafico";
import SeccionMaquinas from "../elementos/maquinas/SeccionMaquinas";

import SeccionMapas from "../mapas/mapas/views/SeccionMapas";
import SeccionZonas from "../mapas/zonas/views/SeccionZonas";

import SeccionClientes from "../administracion/seccion-clientes/SeccionClientes";
import SeccionServicios from "../administracion/seccion-servicios/SeccionServicios";

import SeccionRepartos from "../distribucion/repartos/SeccionRepartos";
import SeccionRecorridos from "../distribucion/recorridos/SeccionRecorridos";

import PruebasVisuales from "../pruebasvisuales/PruebasVisuales";

import Ruta from "../compartido/models/Ruta";
import { Props } from "./Sidebar/SidebarItem";

import SeccionRepartosDiarios from "../distribucion/repartos-diarios/SeccionRepartosDiarios";
import SeccionPedidos from "../ventas/seccion-pedidos/SeccionPedidos";
import SeccionOtros from "../ventas/seccion-otros/SeccionOtros";
import SeccionLogisticaPedidos from "../distribucion/logistica-pedidos/SeccionLogisticaPedidos";
import SeccionAtencionClientes from "../atencion-clientes/seccion-atencion-clientes/SeccionAtencionClientes";

import { Global } from "../Global";
import SeccionDescuentos from "../administracion/seccion-descuentos/SeccionDescuentos";
import SeccionCupones from "../administracion/seccion-cupones/SeccionCupones";
import SeccionFacturacion from "../administracion/seccion-facturacion/SeccionFacturacion";

export function getRutas() {
  let rutas: Array<Ruta> = [];
  getRutasAccesibles().forEach((opc) => {
    rutas.push(...opc.links);
  });
  return rutas;
}

export function getRutasAccesibles(): Array<Props> {
  let aux: Array<Props> = [];

  aux.push({
    links: [
      {
        path: "/inicio",
        name: "Inicio",
        component: SeccionInicio,
      },
    ],
  });

  if (Global.UsuarioService.getPermisos().Elementos.hasAccess()) {
    let aux2: Array<Ruta> = [];

    if (Global.UsuarioService.getPermisos().Elementos.Insumos.hasAccess())
      aux2.push({
        name: "Insumos",
        path: SeccionInsumos.pathBase,
        component: SeccionInsumos,
      });

    if (Global.UsuarioService.getPermisos().Elementos.Productos.hasAccess())
      aux2.push({
        path: SeccionProductos.pathBase,
        name: "Productos",
        component: SeccionProductos,
      });

    if (Global.UsuarioService.getPermisos().Elementos.Indumentarias.hasAccess())
      aux2.push({
        path: SeccionIndumentarias.pathBase,
        name: "Indumentarias",
        component: SeccionIndumentarias,
      });

    if (Global.UsuarioService.getPermisos().Elementos.ProductosLimpieza.hasAccess())
      aux2.push({
        path: SeccionProductosLimpieza.pathBase,
        name: "Productos Limpieza",
        component: SeccionProductosLimpieza,
      });

    if (Global.UsuarioService.getPermisos().Elementos.ProductosSeguridadHigiene.hasAccess())
      aux2.push({
        path: SeccionProductosSeguridadHigiene.pathBase,
        name: "Productos Seguridad & Higiene",
        component: SeccionProductosSeguridadHigiene,
      });

    if (Global.UsuarioService.getPermisos().Elementos.Materiales.hasAccess())
      aux2.push({
        name: "Materiales",
        path: SeccionMateriales.pathBase,
        component: SeccionMateriales,
      });

    if (Global.UsuarioService.getPermisos().Elementos.Herramientas.hasAccess())
      aux2.push({
        name: "Herramientas",
        path: SeccionHerramientas.pathBase,
        component: SeccionHerramientas,
      });

    if (Global.UsuarioService.getPermisos().Elementos.ArticulosOficina.hasAccess())
      aux2.push({
        name: "Articulos de Oficina",
        path: SeccionArticulosOficina.pathBase,
        component: SeccionArticulosOficina,
      });

    if (Global.UsuarioService.getPermisos().Elementos.ElementosComputacion.hasAccess())
      aux2.push({
        name: "Elementos de Computacion",
        path: SeccionElementosComputacion.pathBase,
        component: SeccionElementosComputacion,
      });

    if (Global.UsuarioService.getPermisos().Elementos.Repuestos.hasAccess())
      aux2.push({
        name: "Repuestos",
        path: SeccionRepuestos.pathBase,
        component: SeccionRepuestos,
      });

    if (Global.UsuarioService.getPermisos().Elementos.Vehiculos.hasAccess())
      aux2.push({
        name: "Vehiculos",
        path: SeccionVehiculos.pathBase,
        component: SeccionVehiculos,
      });

    if (Global.UsuarioService.getPermisos().Elementos.ArticulosGraficos.hasAccess())
      aux2.push({
        name: "Articulos Graficos",
        path: SeccionArticulosGraficos.pathBase,
        component: SeccionArticulosGraficos,
      });
    if (Global.UsuarioService.getPermisos().Elementos.Maquinas.hasAccess())
      aux2.push({
        name: "Maquinas",
        path: SeccionMaquinas.pathBase,
        component: SeccionMaquinas,
      });

    aux2.sort((a, b) => (a.name >= b.name ? 1 : -1));
    aux.push({
      links: aux2,
      nombre: "Elementos",
    });
  }

  if (Global.UsuarioService.getPermisos().Mapas.hasAccess()) {
    let aux2: Array<Ruta> = [];

    if (Global.UsuarioService.getPermisos().Mapas.Mapas.hasAccess())
      aux2.push({
        name: "Mapas",
        path: SeccionMapas.pathBase,
        component: SeccionMapas,
      });

    if (Global.UsuarioService.getPermisos().Mapas.Zonas.hasAccess())
      aux2.push({
        name: "Zonas",
        path: SeccionZonas.pathBase,
        component: SeccionZonas,
      });

    aux2.sort((a, b) => (a.name >= b.name ? 1 : -1));
    aux.push({
      links: aux2,
      nombre: "Mapas",
    });
  }

  if (Global.UsuarioService.getPermisos().Administracion.hasAccess()) {
    let aux2: Array<Ruta> = [];
    if (Global.UsuarioService.getPermisos().Administracion.Clientes.hasAccess()) {
      aux2.push({
        name: "Clientes",
        path: SeccionClientes.pathBase,
        component: SeccionClientes,
      });
    }
    if (Global.UsuarioService.getPermisos().Administracion.Personal.hasAccess()) {
      aux2.push({
        name: "Personal",
        path: SeccionPersonal.pathBase,
        component: SeccionPersonal,
      });
    }
    if (Global.UsuarioService.getPermisos().Administracion.Servicios.hasAccess()) {
      aux2.push({
        name: "Servicios",
        path: SeccionServicios.pathBase,
        component: SeccionServicios,
      });
    }
    if (Global.UsuarioService.getPermisos().Administracion.Descuentos.hasAccess()) {
      aux2.push({
        name: "Descuentos",
        path: SeccionDescuentos.pathBase,
        component: SeccionDescuentos,
      });
    }
    if (Global.UsuarioService.getPermisos().Administracion.Cupones.hasAccess()) {
      aux2.push({
        name: "Cupones",
        path: SeccionCupones.pathBase,
        component: SeccionCupones,
      });
    }
    if (Global.UsuarioService.getPermisos().Administracion.Facturacion.hasAccess()) {
      aux2.push({
        name: "Facturación",
        path: SeccionFacturacion.pathBase,
        component: SeccionFacturacion,
      });
    }

    aux2.sort((a, b) => (a.name >= b.name ? 1 : -1));
    aux.push({
      nombre: "Administracion",
      links: aux2,
    });
  }

  if (Global.UsuarioService.getPermisos().Distribucion.hasAccess()) {
    let aux2: Array<Ruta> = [];
    if (Global.UsuarioService.getPermisos().Distribucion.Recorridos.hasAccess()) {
      aux2.push({
        name: "Recorridos",
        path: SeccionRecorridos.pathBase,
        component: SeccionRecorridos,
      });
    }
    if (Global.UsuarioService.getPermisos().Distribucion.Repartos.hasAccess()) {
      aux2.push({
        name: "Repartos",
        path: SeccionRepartos.pathBase,
        component: SeccionRepartos,
      });
    }
    if (Global.UsuarioService.getPermisos().Distribucion.RepartosDiarios.hasAccess()) {
      aux2.push({
        name: "Repartos Diarios",
        path: SeccionRepartosDiarios.pathBase,
        component: SeccionRepartosDiarios,
      });
    }
    if (Global.UsuarioService.getPermisos().Distribucion.LogisticaPedidos.hasAccess()) {
      aux2.push({
        name: "Logistica de Pedidos",
        path: SeccionLogisticaPedidos.pathBase,
        component: SeccionLogisticaPedidos,
      });
    }
    aux2.sort((a, b) => (a.name >= b.name ? 1 : -1));
    aux.push({
      nombre: "Distribución",
      links: aux2,
    });
  }

  if (Global.UsuarioService.getPermisos().Ventas.hasAccess()) {
    let aux2: Array<Ruta> = [];
    if (Global.UsuarioService.getPermisos().Ventas.Pedidos.hasAccess()) {
      aux2.push({
        name: "Pedidos",
        path: SeccionPedidos.pathBase,
        component: SeccionPedidos,
      });
    }
    aux2.sort((a, b) => (a.name >= b.name ? 1 : -1));
    if (Global.UsuarioService.getPermisos().Ventas.Otros.hasAccess()) {
      aux2.push({
        name: "Otros",
        path: SeccionOtros.pathBase,
        component: SeccionOtros,
      });
    }
    aux.push({
      nombre: "Ventas",
      links: aux2,
    });
  }
  aux.push({
    nombre: "Pruebas Visuales",
    links: [
      {
        name: "Pruebas",
        path: "/pruebasvisuales",
        component: PruebasVisuales,
      },
    ],
  });
  if (Global.UsuarioService.getPermisos().Administracion.Clientes.hasAccess()) {
    aux.push({
      nombre: "At/Cliente",
      links: [
        {
          name: "At/Cliente",
          path: SeccionAtencionClientes.pathBase,
          component: SeccionAtencionClientes,
        },
      ],
    });
  }

  aux.sort((a, b) => sortSeccion(a, b));
  return aux;
}

function sortSeccion(a: Props, b: Props): number {
  if (a.nombre != null && b.nombre != null) {
    return a.nombre >= b.nombre ? 1 : -1;
  }
  if (a.nombre != null) {
    return 1;
  } else return -1;
}
