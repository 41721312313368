import React from "react";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Text from "../../../../../../libreria/message/text/Text";

export interface Props {
  idVisita: number;
}

interface State {
  //visita: Visita;
}

export default class VisitaProgramacionEntregaShow extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="flex-start"
        justifyContent="center"
        borderRadius="5px"
        padding="2px"
        marginLeft="2px"
      >
        <Text>VISITA ID {this.props.idVisita}</Text>
        <Text>ESTADO ACTUAL</Text>
        <Text>VENTAS</Text>
        <Text>ENTREGAS</Text>
        <Text>ENTREGAS PROGRAMADAS</Text>
      </Flex>
    );
  }
}
