



export const Fuentes =  {

    Titulos:"Segoe Print",
    Labels:"Times New Roman"

}
