import React, { Component } from "react";
import { CargamentoLectura } from "serviciossaintmichel";
import FechaDisplay from "../../../../compartido/components/fecha-display/FechaDisplay";

export interface Props {
  cargamento: CargamentoLectura;
}

interface State {}

export default class CargamentoDetalle extends Component<Props, State> {
  render() {
    const { cargamento } = this.props;
    return (
      <>
        <div>ID Cargamento: {cargamento.id}</div>
        <div>
          <strong>Fecha de creación: </strong>
          <FechaDisplay fecha={cargamento.fecha_creacion} />
        </div>
        <div>
          <strong>Fecha de modificación: </strong>
          <FechaDisplay fecha={cargamento.fecha_modificacion} />
        </div>
        <div>
          <strong>Realizada en: </strong>
          <FechaDisplay fecha={cargamento.momento_realizacion} incluirTiempo />
        </div>
        <div>
          <strong>Productos: </strong>
          {cargamento.cargas_producto.length > 0 ? (
            <ul>
              {cargamento.cargas_producto.map((cargaProducto) => (
                <li>
                  {cargaProducto.producto.nombre} - {cargaProducto.cantidad} unidad/es
                </li>
              ))}
            </ul>
          ) : (
            <span>-</span>
          )}
        </div>
        <div>
          <strong>Bultos producto: </strong>
          {cargamento.cargas_bulto_producto.length > 0 ? (
            <ul>
              {cargamento.cargas_bulto_producto.map((cargaBultProducto) => (
                <li>
                  {cargaBultProducto.bulto_producto.nombre} - {cargaBultProducto.cantidad} unidad/es
                </li>
              ))}
            </ul>
          ) : (
            <span>-</span>
          )}
        </div>
        <div>
          <strong>Máquinas simbólicas: </strong>
          {cargamento.cargas_maquina_simbolica.length > 0 ? (
            <ul>
              {cargamento.cargas_maquina_simbolica.map((cargaMaquinaSimbolica) => (
                <li>
                  {cargaMaquinaSimbolica.maquina_simbolica.nombre} - {cargaMaquinaSimbolica.cantidad} unidad/es
                </li>
              ))}
            </ul>
          ) : (
            <span>-</span>
          )}
        </div>
      </>
    );
  }
}
