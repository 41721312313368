import React, { Component } from "react";
import { GoogleMap, LoadScript, Polygon } from "@react-google-maps/api";
/* global google */
import CheckBoxList from "../../../compartido/components/checkboxlist/CheckBoxList";
import { Item } from "../../../compartido/components/checkboxlist/Item";
import Flex from "../../../compartido/components/flex/Flex";
import ItemZona from "../models/ItemZona";
import CheckBoxListAdapter from "../../../compartido/components/checkboxlist/CheckBoxListAdapter";


import {  CANALES_WEB_SOCKET,   WebSocketService,  ZonaGet,  ZonaService } from "serviciossaintmichel";
import { Global } from "../../../Global";



const options = {
  fillColor: "lightblue",
  fillOpacity: 0.4,
  strokeColor: "grey",
  strokeOpacity: 0.7,
  zIndex: 1,
};

export interface Props {}

interface State {
  zonasSeleccionadas: Array<Item<ZonaGet>>;
  zonas: Array<Item<ZonaGet>>;
}

class ZonaLista extends Component<Props, State> {
  mapa: any;

  adaptador: CheckBoxListAdapter<ZonaGet>;

  private zonaService:ZonaService;

  constructor(props: Props) {
    super(props);

    //this.state={modo:"polygon",poligonos:{},estado:false}
    this.state = {
      zonasSeleccionadas: [],
      zonas: [],
    };

    this.adaptador = new CheckBoxListAdapter<ZonaGet>();

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.MAPAS_ZONAS,
      funcionCallback: this.actualizarZonas,
    });

    this.zonaService = new ZonaService(Global.UsuarioService.getToken()!);

  }

  actualizarZonas = async () => {
    this.zonaService.listAll()
      .then((result) => {
        let lista: Array<Item<ZonaGet>> = [];
        result.map((zona) =>
          lista.push(new ItemZona(zona.id, zona.nombre, false, zona))
        );
        this.adaptador.renovar(lista);
        this.setState({
          zonas: this.adaptador.getLista(),
          zonasSeleccionadas: this.adaptador.getSeleccionados(),
        });
      })
      .catch((error) => {
        this.zonaService.manejarErrorHTTP(error, "Zona");
      });
  };

  componentDidMount() {
    this.zonaService.listAll()
      .then((result) => {
        let lista: Array<Item<ZonaGet>> = [];
        result.map((zona) =>
          lista.push(new ItemZona(zona.id, zona.nombre, false, zona))
        );
        this.adaptador.cargar(lista);
        this.setState({
          zonas: this.adaptador.getLista(),
        });
      })
      .catch((error) => {
        this.zonaService.manejarErrorHTTP(error, "Zona");
      });
  }

  load = (map: any) => {
    this.mapa = map;
  };

  onZonaChanged = (item: Item<ZonaGet>) => {
    this.adaptador.itemChange(item);

    this.setState({
      zonasSeleccionadas: this.adaptador.getSeleccionados(),
    });
  };

  render() {
    return (
      <Flex container flexDirection="column" width="100%">
        <Flex
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <h3>Zonas Actuales</h3>
          </div>
        </Flex>

        <br />
        <br />

        <Flex container flexDirection="column" width="300px">
          <Flex
            container
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <div>
              <h5>Seleccione Zona</h5>
            </div>
          </Flex>
          <CheckBoxList<ZonaGet>
            height="300px"
            width="100%"
            items={this.state.zonas}
            borde="1px solid grey"
            onChange={this.onZonaChanged}
          />
        </Flex>
        <br />

        <Flex container flexDirection="column" height="800px" width="100%">
          <LoadScript
            id="script-loader"
            googleMapsApiKey="AIzaSyCDsnMxHafUo0xkHuJfTxoAHcinkNDSi9c"
            libraries={["drawing"]}
          >
            <GoogleMap
              id="example-map"
              mapContainerStyle={{
                height: "100%",
                width: "100%",
                margin: "auto",
              }}
              zoom={12}
              center={{ lat: -34.912185, lng: -57.957313 }}
              onLoad={this.load}
            >
              {this.state.zonasSeleccionadas.map((zona) => {
                var puntos: Array<any> = [];
                var i = 0;
                zona.objeto.poligono.coordinates[0].forEach((x: any) => {
                  let aux = { lat: x[0], lng: x[1] };
                  puntos[i] = aux;
                  i++;
                });
                return <Polygon paths={puntos} options={options} />;
              })}
            </GoogleMap>
          </LoadScript>
        </Flex>

        <br />
        <br />
      </Flex>
    );
  }
}
export default ZonaLista;
