import React from "react";
import { Button } from "react-bootstrap";
import Flex from "../flex/Flex";
import * as Icon from 'react-bootstrap-icons';

export interface Props 
{
size:number,
fuente?:string,
color?:string,
texto?:string,
alineacion?:string
}

interface State {
}

export default class H extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
    this.style = {textAlign:this.props.alineacion ? this.props.alineacion:null, fontFamily:this.props.fuente ? this.props.fuente : null,color:this.props.color?this.props.color:"black"}
  }

  style:any;

 

  render() {

   
    
    if(this.props.size == 1)
      return (
      <h1 style={this.style}>{this.props.children}</h1> 
      )
  
    if(this.props.size == 2)
      return (
      <h2 style={this.style}>{this.props.texto ? this.props.texto : null}{this.props.children}</h2>
      )    

    if(this.props.size == 3)
      return (
      <h3 style={this.style}>{this.props.texto ? this.props.texto : null}{this.props.children}</h3>
      ) 

    if(this.props.size == 4)
      return (
      <h4 style={this.style}>{this.props.texto ? this.props.texto : null}{this.props.children}</h4>
      ) 

    if(this.props.size == 5)
      return (
      <h5 style={this.style}>{this.props.texto ? this.props.texto : null}{this.props.children}</h5>
      ) 

    if(this.props.size == 6)
      return (
      <h6 style={this.style}>{this.props.texto ? this.props.texto : null}{this.props.children}</h6>
      ) 

    }
}
