import React, { Component } from "react";
import { PropsSelectGenericoConId } from "../../../../compartido/components/select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../../../../compartido/components/select/SelectBusqueda";
import { diaNumToId } from "../../../../compartido/utils/dias";
import RepartosSelect from "./RepartosSelect";
import RepartosTable from "./RepartosTable";

import { CANALES_WEB_SOCKET, Reparto, RepartoService, TipoReparto, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
  onSelect?: Function;
  version: Version;
  seleccionado?: number;
  fechaReparto?: Date;
  tipoReparto?: TipoReparto;
  sinRealizacionesEnFecha?: boolean;
  disabled?: boolean;
  propsSelect?: PropsSelectGenericoConId<Reparto> & PropsSelectGeneral;
}

export enum Version {
  SELECT,
  TABLE,
}

interface State {
  repartos: Array<Reparto> | null;
}

export default class RepartosShow extends Component<Props, State> {
  private repartoService: RepartoService;

  private webSockets: Array<WebSocket>;
  constructor(props: Props) {
    super(props);
    this.state = { repartos: null };
    this.webSockets = [];

    this.repartoService = new RepartoService(Global.UsuarioService.getToken()!);
  }

  async componentDidMount() {
    this.webSockets.push(
      WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS,
        funcionCallback: this.getRepartos,
      })
    );
    this.webSockets.push(
      WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
        funcionCallback: this.getRepartos,
      })
    );
    this.actualizarRepartos();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.fechaReparto !== this.props.fechaReparto || prevProps.tipoReparto !== this.props.tipoReparto) {
      this.actualizarRepartos();
    }
  }

  componentWillUnmount() {
    this.webSockets.forEach((ws: WebSocket) => ws.close());
  }

  async actualizarRepartos() {
    const repartos = await this.getRepartos();
    this.setState({ repartos: repartos });
  }

  getRepartos = async () => {
    const { fechaReparto, sinRealizacionesEnFecha, tipoReparto } = this.props;
    const queryParams = new URLSearchParams();

    if (fechaReparto) {
      const diaSemana = fechaReparto.getDay();
      const dia = diaNumToId(diaSemana);
      queryParams.append("dia_reparto", dia);
    }

    if (fechaReparto && sinRealizacionesEnFecha) {
      queryParams.append("sin_realizacion_en_fecha", fechaReparto.toISOString());
    }

    if (tipoReparto) {
      queryParams.append("tipo", tipoReparto);
    }

    return await this.repartoService.listAll(queryParams);
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };
  onSelect = (reparto: Reparto) => {
    if (this.props.onSelect) this.props.onSelect(reparto);
  };

  render() {
    const { version, propsSelect, disabled } = this.props;
    const { repartos } = this.state;

    if (repartos === null) {
      return null;
    }

    switch (version) {
      case Version.SELECT: {
        if (!propsSelect) {
          return null;
        }
        return (
          <RepartosSelect
            {...this.props.propsSelect}
            disabled={disabled}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado ? propsSelect.seleccionado : null}
            items={repartos}
          />
        );
      }
      case Version.TABLE: {
        return <RepartosTable onUpdate={this.onUpdate} onView={this.onView} />;
      }
    }
  }
}
