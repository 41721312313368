import React, { Component } from "react";
import Visibilizador from "../../../../../../libreria/action/visibilizador/Visibilizador";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Text from "../../../../../../libreria/message/text/Text";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";

import { Banco, BancoService, CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";



export interface Props {
  id: number;
  soloDatos?: boolean;
}
interface State {
  banco: Banco | null;
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
}

export default class DatosBancoShow extends Component<Props, State> {

  private bancoService:BancoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      banco: null,
      cargando: true,
      visible: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_BANCOS,
      funcionCallback: this.onChannelMesagge,
    });

    this.bancoService =  new BancoService(Global.UsuarioService.getToken()!);


    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {
    const banco = await this.bancoService.get(this.props.id);
    this.setState({ banco: banco, cargando: false });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    const banco = await this.bancoService.get(this.props.id);
    this.setState({ banco: banco, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({ cargando: true });
      const banco = await this.bancoService.get(this.props.id);
      this.setState({ banco: banco, cargando: false });
    }
  };

  renderDatos = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      <Text>Nombre: {this.state.banco!.nombre}</Text>
      {/* <Text>Razón Social: {this.state.banco!.razon_social}</Text> */}
      <Text>CUIT: {this.state.banco!.cuit}</Text>
      <Text>Calle: {this.state.banco!.calle}</Text>
      <Text>Número: {this.state.banco!.numero}</Text>
      {/* <Text>Condición IVA: {this.state.banco!.condicion_iva}</Text> */}
      {/* <Text>Localidad: {this.state.banco!.localidad}</Text> */}
    </Flex>
  );

  renderLoading = () => "Cargando Datos";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="2px"
      >
        {this.props.soloDatos ? (
          this.state.cargando ? (
            this.renderLoading()
          ) : (
            this.renderDatos()
          )
        ) : (
          <Visibilizador
            onChange={this.onChangeVisibilizador}
            label={"Datos"}
            labelAlignment={"flex-start"}
          >
            {this.state.cargando ? this.renderLoading() : this.renderDatos()}
          </Visibilizador>
        )}
      </Flex>
    );
  }
}
