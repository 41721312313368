import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import SeccionFlex from "../../compartido/components/seccion/SeccionFlex";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
import Clientes from "./clientes/components/Clientes";

export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];
  if (Global.UsuarioService.getPermisos().Administracion.Clientes.hasAccess()) {
    aux.push({
      name: "Clientes",
      path: SeccionClientes.pathBase + "/clientes",
      component: Clientes,
    });
  }
  return aux;
}

export default class SeccionClientes extends Component<Props> {
  static pathBase = "/administracion/seccion-clientes";
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Clientes"}
      />
    );
  }
}
