import React, { Component } from "react";
import { Button, Table, Form, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import FechaUtils from "../../../../../../compartido/utils/FechaUtils";
import ServicioClienteShow from "./ServicioClienteShow";
import RequestButton from "../../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import BotonOrden from "../../BotonOrden";
import { ClienteService, CriterioOrden, ServicioCliente } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";


export interface Props {
  clienteId: number;
}

interface State {
  servicios: Array<ServicioCliente> | null;
  servicioDetalle: ServicioCliente | null;
  desde: Date | null;
  hasta: Date | null;
  tipos: Record<string, boolean>;
  criterioOrden: CriterioOrden | null;
}

const tiposMap: Record<string, string> = {
  alquiler: "Alquiler",
  consumo: "Consumo",
  orden_compra: "Orden de compra",
};

export default class ServiciosClienteShow extends Component<Props, State> {

  private clienteService:ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      servicios: null,
      servicioDetalle: null,
      desde: null,
      hasta: null,
      tipos: {
        alquiler: true,
        consumo: true,
        orden_compra: true,
      },
      criterioOrden: null,
    };
    this.clienteService =  new ClienteService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = () => {
    this.setServicios();
  };

  setServicios = async () => {
    const { clienteId } = this.props;
    const { desde, hasta, tipos, criterioOrden } = this.state;

    try {
      let limite = undefined;
      let orden = criterioOrden !== null ? criterioOrden : undefined;

      const filtros = {
        desde: desde,
        hasta: hasta,
        tipos: tipos,
      };

      const servicios = await this.clienteService.getServicios(clienteId, filtros, orden, limite);

      this.setState({ servicios: servicios });
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error, "Servicios Cliente");
    }
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevState.criterioOrden !== this.state.criterioOrden) {
      this.setServicios();
    }
  };

  normalizarTipo = (tipo: string): string => {
    const keys = Object.keys(tiposMap);

    if (keys.indexOf(tipo) >= 0) {
      return tiposMap[tipo];
    }

    return "-";
  };

  onCheckboxChange = (key: string) => {
    const tiposConfig = this.state.tipos;
    tiposConfig[key] = !tiposConfig[key];
    this.setState({ tipos: tiposConfig });
  };

  criteriosOrdenIguales = (criterioA: CriterioOrden | null, criterioB: CriterioOrden | null): boolean => {
    if (criterioA === null || criterioB === null) {
      return false;
    }
    return criterioA.campo === criterioB.campo && criterioA.modo === criterioB.modo;
  };

  seleccionarCriterio = (criterio: CriterioOrden | null) => {
    const { criterioOrden } = this.state;

    if (criterioOrden === null || !this.criteriosOrdenIguales(criterioOrden, criterio)) {
      this.setState({ criterioOrden: criterio });
    } else {
      this.setState({ criterioOrden: null });
    }
  };

  getCriterioHandler = (campo: string) => {
    return (modo: "ASC" | "DESC" | null) => {
      const criterioOrden = modo ? { modo: modo, campo: campo } : null;
      this.seleccionarCriterio(criterioOrden);
    };
  };

  getControlCriterio = (campo: string) =>
    this.state.criterioOrden?.campo === campo ? this.state.criterioOrden.modo : null;

  render = () => {
    const { clienteId } = this.props;
    const { servicios, servicioDetalle, desde, hasta, tipos } = this.state;
    const minColWidth = "250px";

    if (servicios === null) {
      return <div>Cargando...</div>;
    }

    return (
      <>
        <Form
          style={{
            padding: "1rem",
            backgroundColor: "gainsboro",
            borderRadius: "5px",
            marginBottom: "1rem",
          }}
        >
          <Form.Row className="align-items-center" style={{ rowGap: "1rem" }}>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <Form.Label as="strong">Tipo de servicio</Form.Label>
                {Object.entries(tipos).map((tipoConfig) => (
                  <Form.Check
                    type="checkbox"
                    id={`${tiposMap[tipoConfig[0]]}-check-box`}
                    label={tiposMap[tipoConfig[0]]}
                    checked={tipoConfig[1]}
                    onChange={() => this.onCheckboxChange(tipoConfig[0])}
                  />
                ))}
              </Flex>
            </Col>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <Form.Label as="strong">Desde</Form.Label>
                <DatePicker
                  placeholderText="Ingrese fecha"
                  onChange={(fecha: Date) => {
                    this.setState({ desde: fecha });
                  }}
                  selected={desde}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                />
              </Flex>
            </Col>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <Form.Label as="strong">Hasta</Form.Label>
                <DatePicker
                  placeholderText="Ingrese fecha"
                  onChange={(fecha: Date) => {
                    this.setState({ hasta: fecha });
                  }}
                  selected={hasta}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                />
              </Flex>
            </Col>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <RequestButton onClick={() => this.setServicios()} texto="Buscar" icon={<Icon.Search />} />
              </Flex>
            </Col>
          </Form.Row>
        </Form>

        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th className="text-center">
                <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                  <span>Id </span>
                  <BotonOrden
                    criterio={this.getControlCriterio("id")}
                    onCriterioClicked={this.getCriterioHandler("id")}
                  />
                </Flex>
              </th>
              <th className="text-center">Tipo</th>
              <th className="text-center">
                <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                  <span>Fecha de inicio </span>
                  <BotonOrden
                    criterio={this.getControlCriterio("fecha_inicio")}
                    onCriterioClicked={this.getCriterioHandler("fecha_inicio")}
                  />
                </Flex>
              </th>
              <th className="text-center">
                <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                  <span>Fecha de fin </span>
                  <BotonOrden
                    criterio={this.getControlCriterio("fecha_fin")}
                    onCriterioClicked={this.getCriterioHandler("fecha_fin")}
                  />
                </Flex>
              </th>
              <th className="text-center"></th>
            </tr>
          </thead>

          <tbody>
            {servicios.map((servicio, idx) => (
              <tr>
                <td className="text-center">{idx + 1}</td>
                <td className="text-center">{servicio.id}</td>
                <td className="text-center">{this.normalizarTipo(servicio.tipo)}</td>
                <td className="text-center">
                  {FechaUtils.normalizarFecha(servicio.fecha_inicio).toLocaleDateString("es-AR")}
                </td>
                <td className="text-center">
                  {servicio.fecha_fin
                    ? FechaUtils.normalizarFecha(servicio.fecha_fin).toLocaleDateString("es-AR")
                    : "-"}
                </td>
                <td className="text-center">
                  {`${servicio.tipo}_${servicio.id}` !== `${servicioDetalle?.tipo}_${servicioDetalle?.id}` ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-${servicio.tipo}-${servicio.id}`}>Mostrar detalle</Tooltip>}
                    >
                      <Button
                        onClick={() => {
                          this.setState({ servicioDetalle: servicio });
                        }}
                      >
                        <Icon.Eye />
                      </Button>
                    </OverlayTrigger>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({ servicioDetalle: null });
                      }}
                    >
                      <Icon.EyeSlash />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {servicioDetalle && <ServicioClienteShow clienteId={clienteId} servicioCliente={servicioDetalle} />}
      </>
    );
  };
}
