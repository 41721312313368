/*



.box {
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12)
  ;
  
    
    
    min-height: 200px;
    width: 50vw;
    margin: 100px auto;
    background: white;
    border-radius: 5px;
  }
*/

import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import Swal from "sweetalert2";
import Flex from "../../../compartido/components/flex/Flex";
import H from "../../message/title/H";

interface Props {
  width?: string;
  height?: string;
  paddingHorizontal?: string;
  paddingVertical?: string;
  padding?: string;
  showCloseBtn?: boolean;
  onCloseCallback?: Function;
  closeConfirmation?: boolean;
  title?: string;
}

interface State {}

export class Box extends Component<Props, State> {
  onCloseBtnClicked = async () => {
    const { closeConfirmation = true } = this.props;

    if (closeConfirmation) {
      const modalResult = await Swal.fire({
        icon: "question",
        title: "Desea cerrar y descartar el formulario?",
        showDenyButton: true,
        confirmButtonText: "Confirmar",
        denyButtonText: "Cancelar",
      });

      if (modalResult.isDenied) {
        return;
      }
    }

    if (this.props.onCloseCallback) {
      this.props.onCloseCallback();
    }
  };

  render() {
    return (
      <div
        style={{
          width: this.props.width || "100%",
          height: this.props.height || "100%",
          padding: this.props.padding || "0px",
          paddingRight: this.props.paddingHorizontal || this.props.padding || "0px",
          paddingLeft: this.props.paddingHorizontal || this.props.padding || "0px",
          paddingTop: this.props.paddingVertical || this.props.padding || "0px",
          paddingBottom: this.props.paddingVertical || this.props.padding || "0px",
          boxShadow: "1px 1px 3px 1px #9E9E9E",
          background: "white",
          borderRadius: "5px",
        }}
      >
        <Flex container justifyContent={"space-between"} alignItems="center" >
          {this.props.title ? <H texto={this.props.title} size={3} /> : <div></div>}
          <div></div>
          {this.props.showCloseBtn ? (
            <Button variant={"danger"} onClick={this.onCloseBtnClicked}>
              <X color="white" />
            </Button>
          ) : (
            <div></div>
          )}
        </Flex>

        {this.props.children}
      </div>
    );
  }
}
export default Box;
