import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
import MaquinasHerramienta from "./MaquinaHerramienta/components/MaquinasHerramienta";
import MaquinasServicioFisica from "./MaquinaServicioFisica/components/MaquinasServicioFisica";
import MaquinasServicioSimbolica from "./MaquinaServicioSimbolica/components/MaquinasServicioSimbolica";
export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];

  if (
    Global.UsuarioService.getPermisos().Elementos.Maquinas.MaquinaHerramienta.hasAccess()
  ) {
    aux.push({
      name: "Maquina Herramientas",
      path: SeccionMaquinas.pathBase + "/maquinas-herramienta",
      component: MaquinasHerramienta,
    });
  }
  if (
    Global.UsuarioService.getPermisos().Elementos.Maquinas.MaquinaServicioFisica.hasAccess()
  ) {
    aux.push({
      name: "Maquina Servicio Fisica",
      path: SeccionMaquinas.pathBase + "/maquinas-servicio-fisica",
      component: MaquinasServicioFisica,
    });
  }
  if (
    Global.UsuarioService.getPermisos().Elementos.Maquinas.MaquinaServicioSimbolica.hasAccess()
  ) {
    aux.push({
      name: "Maquina Servicio Simbolica",
      path: SeccionMaquinas.pathBase + "/maquinas-servicio-simbolica",
      component: MaquinasServicioSimbolica,
    });
  }
  return aux;
}

export default class SeccionMaquinas extends Component<Props> {
  static pathBase = "/elementos/seccion-maquinas";
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Maquinas"}
      />
    );
  }
}
