import React, { ChangeEvent, Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";

import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import { Form, FormFile, Image } from "react-bootstrap";
import { ArticuloGraficoPost, ArticuloGraficoService, CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";
export interface Props {
  ocultar: Function;
}

interface State {
  articuloGrafico: ArticuloGraficoPost;
  alerta: any;
}

export default class ArticuloGraficoNuevo extends Component<Props, State> {


  private articuloGraficoService:ArticuloGraficoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      articuloGrafico: this.articuloGraficoCrear(),
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    this.articuloGraficoService = new ArticuloGraficoService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    const articuloGraficoCrear: ArticuloGraficoPost =
      this.state.articuloGrafico;
    this.setState({
      articuloGrafico: articuloGraficoCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  articuloGraficoCrear(): ArticuloGraficoPost {
    return {
      nombre: "",
      agencia_diseño: "",
      descripcion: "",
    };
  }

  setearNombre = (nombre: string) => {
    let articuloGrafico = this.state.articuloGrafico;
    articuloGrafico.nombre = nombre;
    this.setState({ articuloGrafico: articuloGrafico });
  };
  setearAgenciaDiseño = (agencia_diseño: string) => {
    let articuloGrafico = this.state.articuloGrafico;
    articuloGrafico.agencia_diseño = agencia_diseño;
    this.setState({ articuloGrafico: articuloGrafico });
  };
  setearDescripcion = (descripcion: string) => {
    let articuloGrafico = this.state.articuloGrafico;
    articuloGrafico.descripcion = descripcion;
    this.setState({ articuloGrafico: articuloGrafico });
  };
  setearImagen = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files && event.target.files[0] != null) {
      let archivo = event.target.files[0];
      if (archivo.type.startsWith("image") || archivo.type.endsWith("pdf")) {
        let articuloGrafico = this.state.articuloGrafico;
        articuloGrafico.archivo_impresion = archivo;
        this.setState({ articuloGrafico: articuloGrafico });
      } else {
        event.target.value = "";
        alert("Archivo no soportado");
      }
    }
  };
  quitarImagen = () => {
    let articuloGrafico = this.state.articuloGrafico;
    articuloGrafico.archivo_impresion = undefined;
    this.setState({ articuloGrafico: articuloGrafico });
  };

  handleCreate() {
    this.articuloGraficoService.create(this.state.articuloGrafico)
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_ARTICULOS_GRAFICO
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Articulo Grafico Creado!",
            titulo: "Atención",
            estado: "block",
          },
        });
        const articuloGraficoCrear: ArticuloGraficoPost =
          this.articuloGraficoCrear();
        this.setState({
          articuloGrafico: articuloGraficoCrear,
        });
      })
      .catch((error) => {
        this.articuloGraficoService.manejarErrorHTTP(error, "Árticulo Gráfico");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.articuloGrafico.nombre &&
      this.state.articuloGrafico.agencia_diseño &&
      this.state.articuloGrafico.descripcion
      ? false
      : true;
  };

  renderizarFormulario() {
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="nombre">Nombre</label>
                <InputCaracteres
                  id="nombre"
                  name="nombre"
                  onChange={this.setearNombre}
                  value={this.state.articuloGrafico.nombre}
                  placeholder="Ingrese Nombre de Articulo Grafico"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="agencia_diseño">Agencia de Diseño</label>
                <InputCaracteres
                  id="agencia_diseño"
                  name="agencia_diseño"
                  onChange={this.setearAgenciaDiseño}
                  value={this.state.articuloGrafico.agencia_diseño}
                  placeholder="Ingrese Agencia de Diseño"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="descripcion">Descripcion</label>
                <InputCaracteres
                  id="descripcion"
                  name="descripcion"
                  onChange={this.setearDescripcion}
                  value={this.state.articuloGrafico.descripcion}
                  placeholder="Ingrese Descripcion"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="archivo_impresion">Archivo de Impresión</label>
                <br />
                {this.state.articuloGrafico.archivo_impresion ? (
                  <>
                    {!this.state.articuloGrafico.archivo_impresion.name.endsWith(
                      ".pdf"
                    ) ? (
                      <Image
                        src={URL.createObjectURL(
                          this.state.articuloGrafico.archivo_impresion
                        )}
                        fluid
                      />
                    ) : (
                      <div>
                        <p>
                          Abrir pdf en{" "}
                          <a
                            href={URL.createObjectURL(
                              this.state.articuloGrafico.archivo_impresion
                            )}
                          >
                            nueva pestaña
                          </a>
                          .
                        </p>
                        <iframe
                          src={
                            URL.createObjectURL(
                              this.state.articuloGrafico.archivo_impresion
                            ) + "#toolbar=0"
                          }
                          width="100%"
                          height="500px"
                        ></iframe>
                      </div>
                    )}
                    <br />
                    {this.state.articuloGrafico.archivo_impresion.name}
                    <br />
                    <button
                      className="btn btn-danger"
                      onClick={() => this.quitarImagen()}
                    >
                      {" "}
                      Quitar Imagen{" "}
                    </button>
                  </>
                ) : (
                  <Form.File
                    id="archivo_impresion"
                    name="archivo_impresion"
                    onChange={this.setearImagen}
                    label="Ingrese la Imagen (no obligatorio)"
                    accept="image/* , application/pdf"
                  />
                )}
              </div>
              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  ref="boton"
                  type="submit"
                  className={"btn btn-info"}
                  disabled={this.disableButton()}
                >
                  {"Crear"}
                </button>
                <button
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
              <div className={"form-group"}>
                <Alerta
                  tipo={this.state.alerta.tipo}
                  titulo={this.state.alerta.titulo}
                  mensaje={this.state.alerta.mensaje}
                  estado={this.state.alerta.estado}
                />
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
