import React, { Component } from "react";
import { Table } from "react-bootstrap";
import VentaPromocionProgramacionEntregaLoad from "./VentaPromocionProgramacionEntregaLoad";
import { EstadoLogisticaPaquete, VentaProgramacionPromocion } from "serviciossaintmichel";
import { Select } from "@mantine/core";

interface ConfigCampo {
  disabled: Array<string>;
  hidden: Array<string>;
}

export interface Props {
  crearNuevos?: boolean;
  configCampos?: ConfigCampo;
  ventasPromo: Array<VentaProgramacionPromocion>;
  onChange?: Function;
}

interface State {
  ventasPromo: Array<VentaProgramacionPromocion>;
}

export default class VentasPromocionProgramacionEntregaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ventasPromo: props.ventasPromo,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (prevProps.ventasPromo !== this.props.ventasPromo && this.state.ventasPromo !== this.props.ventasPromo) {
      this.setState({ ventasPromo: this.props.ventasPromo });
    }

    if (prevState.ventasPromo !== this.state.ventasPromo && this.props.onChange) {
      this.props.onChange(this.state.ventasPromo);
    }
  }

  eliminarVentaPromo = (idPromo: number) => {
    const ventasPromo = this.state.ventasPromo.filter(
      (vp: VentaProgramacionPromocion) => vp.paquete.promocion.id !== idPromo
    );
    this.setState({
      ventasPromo: [...ventasPromo],
    });
  };

  agregarVentaPromo = (nuevaVentaPromo: VentaProgramacionPromocion): void => {
    this.setState((prevState) => ({
      ventasPromo: [...prevState.ventasPromo, nuevaVentaPromo],
    }));
  };

  actualizarVentaPromo = (infoVenta: VentaProgramacionPromocion) => {
    const ventas = this.state.ventasPromo.map((vp) => (vp.paquete.id === infoVenta.paquete.id ? infoVenta : vp));
    this.setState({
      ventasPromo: ventas,
    });
  };

  estaDeshabilitado = (id: string): boolean => {
    if (!this.props.configCampos) {
      return false;
    }

    return this.props.configCampos.disabled.indexOf(id) !== -1;
  };

  render() {
    const { ventasPromo } = this.state;
    const { crearNuevos } = this.props;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Promoción</th>
              <th>Cantidad</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {ventasPromo.map((ventaPromo, idx) => (
              <tr key={ventaPromo.paquete.promocion.id}>
                <td>{ventaPromo.paquete.promocion.nombre}</td>
                <td>{ventaPromo.paquete.cantidad}</td>
                <td>
                  <Select
                    data={[
                      EstadoLogisticaPaquete.ENTREGADO,
                      EstadoLogisticaPaquete.NO_ENTREGADO,
                      EstadoLogisticaPaquete.RECHAZADO,
                    ]}
                    value={ventaPromo.estado}
                    onChange={(seleccionado: EstadoLogisticaPaquete) => {
                      this.actualizarVentaPromo({
                        ...ventaPromo,
                        estado: seleccionado,
                      });
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {crearNuevos ? (
          <VentaPromocionProgramacionEntregaLoad
            onSubmitCallback={this.agregarVentaPromo}
            excluirPromos={ventasPromo.map(({ paquete }) => paquete.promocion.id)}
          />
        ) : null}
      </>
    );
  }
}
