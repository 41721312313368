import React, { Component } from "react";
import { CANALES_WEB_SOCKET, ConsumoCliente, ConsumoClienteService, ConsumoGet, ConsumoService, WebSocketService } from "serviciossaintmichel";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import FechaUtils from "../../../../../../../compartido/utils/FechaUtils";
import { Global } from "../../../../../../../Global";
import RequestButton from "../../../../../../../libreria/action/request-button/RequestButton";


export interface Props {
  consumoClienteId: number;
  showDeleteBtn: boolean;
}

interface State {
  consumo: ConsumoGet | null;
  consumoCliente: ConsumoCliente | null;
}

export default class ConsumoClienteShow extends Component<Props, State> {

  private consumoClienteService:ConsumoClienteService;
  private consumoService:ConsumoService;

  private webSocket: WebSocket | null;
  constructor(props: Props) {
    super(props);
    this.state = {
      consumo: null,
      consumoCliente: null,
    };
    this.webSocket = null;

    this.consumoClienteService = new ConsumoClienteService(Global.UsuarioService.getToken()!);
    this.consumoService = new ConsumoService(Global.UsuarioService.getToken()!);

  }

  componentWillUnmount = () => {
    this.webSocket?.close();
  };

  componentDidMount = async () => {
    try {
      this.webSocket = WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_CONSUMOS,
        funcionCallback: this.consumoClienteWSHandler,
      });
      const consumoCliente = await this.consumoClienteService.get(
        this.props.consumoClienteId
      );
      const consumo = await this.consumoService.get(consumoCliente.consumo);
      this.setState({ consumo: consumo, consumoCliente: consumoCliente });
    } catch (error) {
      this.consumoClienteService.manejarErrorHTTP("ConsumoCliente");
    }
  };

  consumoClienteWSHandler = async () => {
    const consumoCliente = await this.consumoClienteService.get(
      this.props.consumoClienteId
    );
    this.setState({ consumoCliente: consumoCliente });
  };

  eliminarConsumoCliente = async (consumoClienteId: number) => {
    try {
      this.consumoClienteService.delete(consumoClienteId);
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_SERVICIO_ACTIVO,
        {
          clienteId: this.state.consumoCliente?.cliente,
        }
      );
    } catch (error) {
      this.consumoClienteService.manejarErrorHTTP("ConsumoCliente");
    }
  };

  render = () => {
    const { consumo, consumoCliente } = this.state;
    const { showDeleteBtn } = this.props;

    if (consumo === null || consumoCliente === null) {
      return <div>Cargando datos...</div>;
    }

    const fechaInicio = FechaUtils.normalizarFecha(consumoCliente.fecha_inicio);
    let fechaFin = consumoCliente.fecha_fin;
    if (consumoCliente.fecha_fin) {
      fechaFin = FechaUtils.normalizarFecha(consumoCliente.fecha_fin);
    }

    return (
      <>
        <Flex container flexDirection="column">
          <span>Nombre del consumo</span>
          <div>{consumo.nombre}</div>
        </Flex>
        <Flex container flexDirection="column">
          <span>Es especial</span>
          <div>{consumo.especial ? "Si" : "No"}</div>
        </Flex>
        <Flex container flexDirection="column">
          <span>Fecha de inicio</span>
          <div>{fechaInicio.toLocaleDateString("es-AR")}</div>
        </Flex>
        <Flex container flexDirection="column">
          <span>Fecha de finalización</span>
          <div>
            {fechaFin === null ? "-" : fechaFin.toLocaleDateString("es-AR")}
          </div>
        </Flex>
        <Flex
          container
          flexDirection="row"
          justifyContent="center"
          marginTop="auto"
        >
          {showDeleteBtn && (
            <RequestButton
              texto="Eliminar"
              propsBoton={{
                variant: "danger",
              }}
              onClick={() => {
                this.eliminarConsumoCliente(consumoCliente.id);
              }}
              confirmacion={true}
            />
          )}
        </Flex>
      </>
    );
  };
}
