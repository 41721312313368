import React, { Component } from "react";
import Swal from "sweetalert2";
import * as Icon from "react-bootstrap-icons";
import { Button, Col, Form } from "react-bootstrap";
import RequestButton from "../../../libreria/action/request-button/RequestButton";
import Flex from "../../../libreria/appearance/flex/Flex";
import TablaProgramaciones from "./TablaProgramaciones";
import Input, { InputTypes } from "../../../libreria/data/input/Input";
import {
  ProgramacionEntrega,
  ResumenProgramacionesEntrega as ResumenProgramacionesEntregaI,
  ProgramacionEntregaService,
  ZonaGet,
  RepartoDiarioLectura,
  WebSocketService,
  CANALES_WEB_SOCKET,
  DatosProducto,
  DatosBultoProducto,
  EstadoProgramacionEnum,
} from "serviciossaintmichel";
import { Global } from "../../../Global";
import ManejadorErroresHTTP from "../../../compartido/utils/ManejadorErroresHTTP";
import { Checkbox, Loader, Modal, Radio, Select } from "@mantine/core";
import ZonasShow, {
  Version as ZonasShowVersion,
} from "../../../compartido/components/direccion/ZonasShow";
import RepartosDiariosShowV2, {
  VersionRepartosDiariosShow,
} from "../../repartos-diarios/components/show/RepartosDiariosShowV2";
import ResumenProgramacionesEntrega from "./ResumenProgramacionesEntrega";
import ProgramacionEntregaShow from "../../../ventas/seccion-pedidos/programaciones-entrega/components/show/ProgramacionEntregaShow";
import ProductosShow, {
  Version as ProductosShowVersion,
} from "../../../elementos/productos/Producto/components/show/ProductosShow";
import BultosProductoShow, {
  Version as BultosProductoShowVersion,
} from "../../../elementos/productos/BultoProducto/components/BultosProductoShow";
import PanelReprogramacion from "./PanelReprogramacion";
import FechaUtils from "../../../compartido/utils/FechaUtils";
import ImagenesURLs from "../../../compartido/utils/ImagenesURLs";

interface Props {
  fecha: Date;
}

interface FiltroBusqueda {
  cliente: string;
  zona: ZonaGet | null;
  turno: string | null;
  monto: [string, number | undefined];
  peso: [string, number | undefined];
  sinVisita: boolean;
  sinReparto: boolean;
  producto: {
    elementos: Array<DatosProducto>;
    operador: string;
    cantidad: number | undefined;
  };
  bultoProducto: {
    elementos: Array<DatosBultoProducto>;
    operador: string;
    cantidad: number | undefined;
  };
}

interface State {
  filtroBusqueda: FiltroBusqueda;
  programaciones: Array<ProgramacionEntrega> | null;
  repartoDiarioSeleccionado: RepartoDiarioLectura | null;
  programacionesSeleccionadas: Array<ProgramacionEntrega>;
  resumenProgramaciones: ResumenProgramacionesEntregaI | null;
  resumenParcialProgramaciones: ResumenProgramacionesEntregaI | null;
  incluirRepartosDiariosDomicilio: boolean;
  idProgramacionDetalle: number | null;
  modalReprogramacion: boolean;
}

export default class LogisticaPedidosProgramaciones extends Component<
  Props,
  State
> {
  private programacionEntregaService: ProgramacionEntregaService;
  private webSockets: Array<WebSocket>;

  constructor(props: Props) {
    super(props);
    this.state = {
      filtroBusqueda: {
        cliente: "",
        zona: null,
        turno: null,
        monto: [">", undefined],
        peso: [">", undefined],
        sinVisita: true,
        sinReparto: true,
        producto: {
          elementos: [],
          operador: "=",
          cantidad: undefined,
        },
        bultoProducto: {
          elementos: [],
          operador: "=",
          cantidad: undefined,
        },
      },
      programaciones: null,
      resumenProgramaciones: null,
      resumenParcialProgramaciones: null,
      repartoDiarioSeleccionado: null,
      programacionesSeleccionadas: [],
      incluirRepartosDiariosDomicilio: false,
      idProgramacionDetalle: null,
      modalReprogramacion: false,
    };
    this.programacionEntregaService = new ProgramacionEntregaService(
      Global.UsuarioService.getToken()!
    );
    this.webSockets = [];
  }

  async componentDidMount() {
    await Promise.all([this.setResumen(), this.setProgramaciones()]);
    await this.setResumenParcial();
    this.webSockets.push(
      WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.VENTAS_PROGRAMACIONES_ENTREGA,
        funcionCallback: async () => {
          await Promise.all([this.setResumen(), this.setProgramaciones()]);
          this.setState({
            programacionesSeleccionadas: [],
            repartoDiarioSeleccionado: null,
          });
        },
      })
    );
  }

  componentWillUnmount(): void {
    this.webSockets.forEach((ws) => ws.close());
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.fecha !== this.props.fecha) {
      this.setProgramaciones();
      this.setResumen();
    }

    if (prevState.programaciones !== this.state.programaciones) {
      this.setResumenParcial();
    }
  }

  setResumen = async () => {
    const { fecha } = this.props;

    if (fecha === null) {
      return;
    }

    try {
      const resumen = await this.programacionEntregaService.generarResumen(
        fecha
      );
      this.setState({ resumenProgramaciones: resumen });
    } catch (error) {
      ManejadorErroresHTTP.evaluarError(error);
    }
  };

  setResumenParcial = async () => {
    const { fecha } = this.props;
    const { programaciones } = this.state;

    if (fecha === null || programaciones === null) {
      return;
    }

    const idProgramaciones = programaciones.map((progra) => progra.id);

    try {
      const resumen = await this.programacionEntregaService.generarResumen(
        fecha,
        idProgramaciones
      );
      this.setState({ resumenParcialProgramaciones: resumen });
    } catch (error) {
      ManejadorErroresHTTP.evaluarError(error);
    }
  };

  setProgramaciones = async () => {
    const { fecha } = this.props;
    const { filtroBusqueda } = this.state;

    if (fecha === null) {
      return;
    }

    const filtroPeso: [string, number] | undefined = filtroBusqueda.peso[1]
      ? [filtroBusqueda.peso[0], filtroBusqueda.peso[1] as number]
      : undefined;

    const filtroMonto: [string, number] | undefined = filtroBusqueda.monto[1]
      ? [filtroBusqueda.monto[0], filtroBusqueda.monto[1] as number]
      : undefined;

    const { producto } = filtroBusqueda;

    const filtroProductos =
      producto.elementos.length > 0 && producto.cantidad !== undefined
        ? {
            ids: producto.elementos.map((ele) => ele.id),
            operador: producto.operador,
            cantidad: producto.cantidad,
          }
        : undefined;

    const { bultoProducto } = filtroBusqueda;

    const filtroBultosProducto =
      bultoProducto.elementos.length > 0 && bultoProducto.cantidad !== undefined
        ? {
            ids: bultoProducto.elementos.map((ele) => ele.id),
            operador: bultoProducto.operador,
            cantidad: bultoProducto.cantidad,
          }
        : undefined;

    const filtros = {
      desde: fecha,
      hasta: fecha,
      cliente: filtroBusqueda.cliente,
      sinReparto: true,
      sinVisita: filtroBusqueda.sinVisita,
      zonasId: filtroBusqueda.zona !== null ? [filtroBusqueda.zona.id] : null,
      peso: filtroPeso,
      monto: filtroMonto,
      turno: filtroBusqueda.turno ? filtroBusqueda.turno : undefined,
      productos: filtroProductos,
      bultosProducto: filtroBultosProducto,
    };

    try {
      const programaciones =
        await this.programacionEntregaService.getProgramaciones(filtros);
      this.setState({ programaciones: programaciones });
    } catch (error) {
      ManejadorErroresHTTP.evaluarError(error);
    }
  };

  setFiltroZona = (zonaSeleccionada: ZonaGet | null) => {
    this.setState((prevState: State) => ({
      ...prevState,
      filtroBusqueda: { ...prevState.filtroBusqueda, zona: zonaSeleccionada },
    }));
  };

  setRepartoDiarioSeleccionado = (
    repartoDiario: RepartoDiarioLectura | null
  ) => {
    this.setState({ repartoDiarioSeleccionado: repartoDiario });
  };

  asignacionPermitida = () => {
    const { programacionesSeleccionadas, repartoDiarioSeleccionado } =
      this.state;
    const asignables = programacionesSeleccionadas.every(
      (programacion) =>
        programacion.visita === null &&
        programacion.estado_programacion.estado ===
          EstadoProgramacionEnum.NO_ASIGNADA
    );
    return !!(
      repartoDiarioSeleccionado &&
      programacionesSeleccionadas.length > 0 &&
      asignables
    );
  };

  desasignacionPermitida = () => {
    return (
      this.state.programacionesSeleccionadas.length > 0 &&
      this.state.programacionesSeleccionadas.every(
        (programacion) =>
          programacion.estado_programacion.estado ===
          EstadoProgramacionEnum.EN_ESPERA
      )
    );
  };

  reprogramacionPermitida = () => {
    const fechaHoy = new Date();
    const { programacionesSeleccionadas } = this.state;
    const condicionReprogramacion = programacionesSeleccionadas.every(
      (programacion) =>
        programacion.programacion_entrega_hijo === null &&
        (programacion.programacion_entrega_padre === null ||
          FechaUtils.normalizarFecha(
            programacion.programacion_entrega_padre.fecha
          ) < fechaHoy)
    );
    return !!(
      programacionesSeleccionadas.length > 0 && condicionReprogramacion
    );
  };

  filtroRestablecimiento = (programacion: ProgramacionEntrega) => {
    const fechaProgra = FechaUtils.normalizarFecha(programacion.fecha);
    return (
      programacion.programacion_entrega_hijo !== null &&
      fechaProgra > new Date()
    );
  };

  restablecimientoPermitido = () => {
    const { programacionesSeleccionadas } = this.state;
    const condicionRestablecimiento = programacionesSeleccionadas.every(
      this.filtroRestablecimiento
    );
    return !!(
      programacionesSeleccionadas.length > 0 && condicionRestablecimiento
    );
  };

  asignarRepartoDiario = async () => {
    const { repartoDiarioSeleccionado, programacionesSeleccionadas } =
      this.state;

    if (
      repartoDiarioSeleccionado === null ||
      programacionesSeleccionadas.length === 0
    ) {
      return;
    }

    const idProgramaciones = programacionesSeleccionadas.map(
      (programacion) => programacion.id
    );
    try {
      await this.programacionEntregaService.asignarRepartoDiario(
        repartoDiarioSeleccionado.id,
        idProgramaciones
      );
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.VENTAS_PROGRAMACIONES_ENTREGA,
        {
          type: "update",
          ids: idProgramaciones,
        }
      );
      this.setState({
        programacionesSeleccionadas: [],
        repartoDiarioSeleccionado: null,
      });
      await Swal.fire({
        icon: "success",
        text: "Programaciones asignadas con éxito",
      });
    } catch (error) {
      this.programacionEntregaService.manejarErrorHTTP(
        error,
        "Programacion entrega"
      );
    }
  };

  desasignarVisita = async () => {
    const { programacionesSeleccionadas } = this.state;

    if (programacionesSeleccionadas.length === 0) {
      return;
    }

    const idProgramaciones = programacionesSeleccionadas.map(
      (programacion) => programacion.id
    );
    try {
      await this.programacionEntregaService.desasignarVisita(idProgramaciones);
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.VENTAS_PROGRAMACIONES_ENTREGA,
        {
          type: "update",
          ids: idProgramaciones,
        }
      );
      this.setState({
        programacionesSeleccionadas: [],
        repartoDiarioSeleccionado: null,
      });
      await Swal.fire({
        icon: "success",
        text: "Programaciones desasignadas con éxito",
      });
    } catch (error) {
      this.programacionEntregaService.manejarErrorHTTP(
        error,
        "Programacion entrega"
      );
    }
  };

  restablecerProgramaciones = async () => {
    const { programacionesSeleccionadas } = this.state;

    if (programacionesSeleccionadas.length === 0) {
      return;
    }

    const idProgramaciones = programacionesSeleccionadas.map(
      (programacion) => programacion.id
    );
    try {
      await this.programacionEntregaService.restablecerProgramaciones(
        idProgramaciones
      );
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.VENTAS_PROGRAMACIONES_ENTREGA,
        {
          type: "update",
          ids: idProgramaciones,
        }
      );
      this.setState({
        programacionesSeleccionadas: [],
        repartoDiarioSeleccionado: null,
      });
      await Swal.fire({
        icon: "success",
        text: "Programaciones restablecidas con éxito",
      });
    } catch (error) {
      this.programacionEntregaService.manejarErrorHTTP(
        error,
        "Programacion entrega"
      );
    }
  };

  renderFiltrosBusqueda = () => {
    const { filtroBusqueda } = this.state;
    return (
      <Form
        style={{
          padding: "1rem",
          backgroundColor: "gainsboro",
          borderRadius: "5px",
          marginBottom: "1rem",
        }}
      >
        <Form.Row className="align-items-center" style={{ rowGap: "1rem" }}>
          <Col md>
            <Form.Label as="strong">Cliente</Form.Label>
            <Input
              data={filtroBusqueda.cliente}
              placeholder={
                "Ingrese razon social, nombre o apellido del cliente"
              }
              onChange={(busquedaCliente: string) => {
                this.setState((prevState: State) => ({
                  ...prevState,
                  filtroBusqueda: {
                    ...prevState.filtroBusqueda,
                    cliente: busquedaCliente,
                  },
                }));
              }}
              type={InputTypes.Text}
            />

            <Form.Label as="strong">Zona</Form.Label>
            <ZonasShow
              version={ZonasShowVersion.SELECT}
              propsSelect={{
                callbackParent: this.setFiltroZona,
                seleccionado: filtroBusqueda.zona,
                clearable: true,
                placeholder: "Seleccione zona",
              }}
            />

            <Form.Label as="strong">Turno</Form.Label>
            <Select
              clearable
              placeholder="Seleccione turno"
              value={filtroBusqueda.turno}
              onChange={(turno: string | null) => {
                this.setState((prevState: State) => ({
                  ...prevState,
                  filtroBusqueda: { ...prevState.filtroBusqueda, turno: turno },
                }));
              }}
              data={[
                { value: "Mañana", label: "Mañana" },
                { value: "Mediodía", label: "Mediodía" },
                { value: "Tarde", label: "Tarde" },
                { value: "Nochecita", label: "Nochecita" },
                { value: "Noche", label: "Noche" },
              ]}
            />
          </Col>

          <Col md>
            <Form.Label as="strong">Peso</Form.Label>
            <Flex gap="1rem" justifyContent="center">
              <Radio.Group
                orientation="horizontal"
                value={filtroBusqueda.peso[0]}
                onChange={(valorRadio: string) => {
                  this.setState((prevState: State) => ({
                    ...prevState,
                    filtroBusqueda: {
                      ...prevState.filtroBusqueda,
                      peso: [valorRadio, prevState.filtroBusqueda.peso[1]],
                    },
                  }));
                }}
              >
                <Radio value=">" label="Mayor que" />
                <Radio value="<" label="Menor que" />
              </Radio.Group>
              <div style={{ width: "auto" }}>
                <Input
                  data={filtroBusqueda.peso[1]}
                  placeholder={"Peso total (kg)"}
                  onChange={(peso: string) => {
                    this.setState((prevState: State) => ({
                      ...prevState,
                      filtroBusqueda: {
                        ...prevState.filtroBusqueda,
                        peso: [
                          prevState.filtroBusqueda.peso[0],
                          isNaN(Number.parseInt(peso))
                            ? undefined
                            : Number.parseInt(peso),
                        ],
                      },
                    }));
                  }}
                  type={InputTypes.Number}
                />
              </div>
            </Flex>
          </Col>
          <Col md>
            <Form.Label as="strong">Monto</Form.Label>
            <Flex gap="1rem" justifyContent="center">
              <Radio.Group
                orientation="horizontal"
                value={filtroBusqueda.monto[0]}
                onChange={(valorRadio: string) => {
                  this.setState((prevState: State) => ({
                    ...prevState,
                    filtroBusqueda: {
                      ...prevState.filtroBusqueda,
                      monto: [valorRadio, prevState.filtroBusqueda.monto[1]],
                    },
                  }));
                }}
              >
                <Radio value=">" label="Mayor que" />
                <Radio value="<" label="Menor que" />
              </Radio.Group>
              <div style={{ width: "auto" }}>
                <Input
                  data={filtroBusqueda.monto[1]}
                  placeholder={"Monto total (AR$)"}
                  onChange={(monto: string) => {
                    this.setState((prevState: State) => ({
                      ...prevState,
                      filtroBusqueda: {
                        ...prevState.filtroBusqueda,
                        monto: [
                          prevState.filtroBusqueda.monto[0],
                          isNaN(Number.parseInt(monto))
                            ? undefined
                            : Number.parseInt(monto),
                        ],
                      },
                    }));
                  }}
                  type={InputTypes.Number}
                />
              </div>
            </Flex>
          </Col>
          <Col md>
            <Form.Label as="strong">Producto</Form.Label>
            <Flex gap="1rem" justifyContent="center">
              <ProductosShow
                version={ProductosShowVersion.MULTI_SELECT}
                propsMultiSelect={{
                  seleccionados: filtroBusqueda.producto.elementos,
                  callbackParent: (prods) => {
                    this.setState((prevState) => {
                      return {
                        filtroBusqueda: {
                          ...prevState.filtroBusqueda,
                          producto: {
                            ...prevState.filtroBusqueda.producto,
                            elementos: prods,
                          },
                        },
                      };
                    });
                  },
                  clearable: true,
                }}
              />
              <Radio.Group
                orientation="horizontal"
                value={filtroBusqueda.producto.operador}
                onChange={(valor: string) => {
                  this.setState((prevState) => {
                    return {
                      filtroBusqueda: {
                        ...prevState.filtroBusqueda,
                        producto: {
                          ...prevState.filtroBusqueda.producto,
                          operador: valor,
                        },
                      },
                    };
                  });
                }}
              >
                <Radio value=">" label="Mayor que" />
                <Radio value="<" label="Menor que" />
                <Radio value="=" label="Igual que" />
              </Radio.Group>
              <div style={{ width: "auto" }}>
                <Input
                  data={filtroBusqueda.producto.cantidad}
                  placeholder={"Cantidad (unidades)"}
                  onChange={(cantidad: string) => {
                    this.setState((prevState) => {
                      return {
                        filtroBusqueda: {
                          ...prevState.filtroBusqueda,
                          producto: {
                            ...prevState.filtroBusqueda.producto,
                            cantidad: isNaN(Number.parseInt(cantidad))
                              ? undefined
                              : Number.parseInt(cantidad),
                          },
                        },
                      };
                    });
                  }}
                  type={InputTypes.Number}
                />
              </div>
            </Flex>
          </Col>
          <Col md>
            <Form.Label as="strong">Bulto producto</Form.Label>
            <Flex gap="1rem" justifyContent="center">
              <BultosProductoShow
                version={BultosProductoShowVersion.MULTI_SELECT}
                propsMultiSelect={{
                  seleccionados: filtroBusqueda.bultoProducto.elementos,
                  callbackParent: (bultosProd) => {
                    this.setState((prevState) => {
                      return {
                        filtroBusqueda: {
                          ...prevState.filtroBusqueda,
                          bultoProducto: {
                            ...prevState.filtroBusqueda.producto,
                            elementos: bultosProd,
                          },
                        },
                      };
                    });
                  },
                  clearable: true,
                }}
              />
              <Radio.Group
                orientation="horizontal"
                value={filtroBusqueda.bultoProducto.operador}
                onChange={(valor: string) => {
                  this.setState((prevState) => {
                    return {
                      filtroBusqueda: {
                        ...prevState.filtroBusqueda,
                        bultoProducto: {
                          ...prevState.filtroBusqueda.bultoProducto,
                          operador: valor,
                        },
                      },
                    };
                  });
                }}
              >
                <Radio value=">" label="Mayor que" />
                <Radio value="<" label="Menor que" />
                <Radio value="=" label="Igual que" />
              </Radio.Group>
              <div style={{ width: "auto" }}>
                <Input
                  data={filtroBusqueda.bultoProducto.cantidad}
                  placeholder={"Cantidad (unidades)"}
                  onChange={(cantidad: string) => {
                    this.setState((prevState) => {
                      return {
                        filtroBusqueda: {
                          ...prevState.filtroBusqueda,
                          bultoProducto: {
                            ...prevState.filtroBusqueda.bultoProducto,
                            cantidad: isNaN(Number.parseInt(cantidad))
                              ? undefined
                              : Number.parseInt(cantidad),
                          },
                        },
                      };
                    });
                  }}
                  type={InputTypes.Number}
                />
              </div>
            </Flex>
          </Col>
          <Col md>
            <Checkbox
              label="Incluir programaciones con reparto diario asignado"
              checked={!filtroBusqueda.sinVisita}
              onClick={() => {
                this.setState((prevState: State) => ({
                  ...prevState,
                  filtroBusqueda: {
                    ...prevState.filtroBusqueda,
                    sinVisita: !prevState.filtroBusqueda.sinVisita,
                  },
                }));
              }}
            />
          </Col>

          <Col md>
            <Flex flexDirection="column" alignItems="center">
              <RequestButton
                onClick={() => {
                  this.setProgramaciones();
                }}
                texto="Buscar"
                icon={<Icon.Search />}
              />
            </Flex>
          </Col>
        </Form.Row>
      </Form>
    );
  };

  render() {
    const { fecha } = this.props;
    const {
      programaciones,
      resumenProgramaciones,
      resumenParcialProgramaciones,
      repartoDiarioSeleccionado,
      incluirRepartosDiariosDomicilio,
      idProgramacionDetalle,
      modalReprogramacion,
      programacionesSeleccionadas,
    } = this.state;

    if (
      programaciones === null ||
      resumenProgramaciones === null ||
      resumenParcialProgramaciones === null
    ) {
      return <Loader />;
    }

    return (
      <>
        <ResumenProgramacionesEntrega
          resumenProgramaciones={resumenProgramaciones}
          titulo={`Resumen de las programaciones de comercio o mixtas para la fecha ${fecha.toLocaleDateString(
            "es-AR"
          )}`}
        />

        {this.renderFiltrosBusqueda()}

        <ResumenProgramacionesEntrega
          resumenProgramaciones={resumenParcialProgramaciones}
        />

        <Flex gap="1rem" marginBottom="1rem">
          <RequestButton
            confirmacion={true}
            propsBoton={{
              disabled: !this.desasignacionPermitida(),
              variant: "danger",
            }}
            texto={"Desasignar"}
            onClick={this.desasignarVisita}
          />
          <Button
            disabled={!this.reprogramacionPermitida()}
            onClick={() => {
              this.setState({ modalReprogramacion: true });
            }}
          >
            Reprogramar
          </Button>
          <RequestButton
            confirmacion={true}
            propsBoton={{
              disabled: !this.restablecimientoPermitido(),
              variant: "secondary",
            }}
            texto={"Restablecer"}
            onClick={this.restablecerProgramaciones}
          />
        </Flex>

        <TablaProgramaciones
          data={programaciones}
          onChangeSeleccionados={(
            seleccionadas: Array<ProgramacionEntrega>
          ) => {
            this.setState({ programacionesSeleccionadas: seleccionadas });
          }}
          onVerDetalle={(programacion: ProgramacionEntrega) => {
            this.setState({ idProgramacionDetalle: programacion.id });
          }}
        />
        <Checkbox
          label="Incluir repartos diarios de domicilio"
          checked={incluirRepartosDiariosDomicilio}
          onClick={() => {
            this.setState((prevState: State) => ({
              incluirRepartosDiariosDomicilio:
                !prevState.incluirRepartosDiariosDomicilio,
            }));
          }}
        />
        <Flex alignItems="center" gap="1rem" marginBottom="1rem">
          <div style={{ width: "50%" }}>
            <RepartosDiariosShowV2
              filtros={{
                desde: fecha,
                hasta: fecha,
                estadoActual: null,
                repartoBusqueda: null,
                criterioOrden: null,
                limite: 0,
                tipo: incluirRepartosDiariosDomicilio
                  ? null
                  : ["Comercio", "Mixto"],
              }}
              version={VersionRepartosDiariosShow.SELECT}
              propsSelect={{
                callbackParent: this.setRepartoDiarioSeleccionado,
                seleccionado: repartoDiarioSeleccionado,
                placeholder: "Seleccione un reparto diario",
                label: "Reparto diario a asignar",
              }}
            />
          </div>
          <div>
            <RequestButton
              confirmacion={true}
              propsBoton={{ disabled: !this.asignacionPermitida() }}
              texto={"Asignar programaciones"}
              onClick={this.asignarRepartoDiario}
            />
          </div>
        </Flex>

        <Modal
          centered
          transitionDuration={0}
          opened={idProgramacionDetalle !== null}
          title="Ver detalle"
          overflow="inside"
          size="70%"
          onClose={() => {
            this.setState({ idProgramacionDetalle: null });
          }}
          styles={{
            modal: {
              backgroundImage: ImagenesURLs.urlFondoModal(),
            },
          }}
        >
          {idProgramacionDetalle !== null ? (
            <ProgramacionEntregaShow
              idProgramacionEntrega={idProgramacionDetalle}
            />
          ) : null}
        </Modal>

        <Modal
          centered
          transitionDuration={0}
          opened={modalReprogramacion}
          title="Reprogramacion"
          overflow="inside"
          size="70%"
          onClose={() => {
            this.setState({ modalReprogramacion: false });
          }}
          styles={{
            modal: {
              backgroundImage: ImagenesURLs.urlFondoModal(),
            },
          }}
        >
          <PanelReprogramacion
            programaciones={programacionesSeleccionadas}
            callbackParent={() => {
              this.setState({ modalReprogramacion: false });
            }}
          />
        </Modal>
      </>
    );
  }
}
