import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
import AtencionClientes from "./clientes/components/AtencionClientes";
import PotencialesClientes from "./potenciales-clientes/PotencialesClientes";

export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];
  if (Global.UsuarioService.getPermisos().Administracion.Clientes.hasAccess()) {
    aux.push({
      name: "Clientes",
      path: SeccionClientes.pathBase + "/atencion-clientes",
      component: AtencionClientes,
    });
    aux.push({
      name: "Potenciales Clientes",
      path: SeccionClientes.pathBase + "/potenciales-clientes",
      component: PotencialesClientes,
    });
  }
  return aux;
}

export default class SeccionClientes extends Component<Props> {
  static pathBase = "/atencion-clientes/seccion-clientes";
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Atención Clientes"}
      />
    );
  }
}
