import React, { Component } from "react";

import Flex from "../../../../../compartido/components/flex/Flex";

import  EmpleadoVerDatosPersonales from "./EmpleadoVerDatosPersonales";
import  EmpleadoVerAreas from "./EmpleadoVerAreas";
import EmpleadoVerSubAreas from "./EmpleadoVerSubAreas";
import EmpleadoVerPermisos from "./EmpleadoVerPermisos";




import { CANALES_WEB_SOCKET, EmpleadoGet, EmpleadoPost, EmpleadoService, RelacionEmpleadoArea, RelacionEmpleadoSubArea, TipoResponsabilidad, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";




export interface Props {
  empleado: EmpleadoGet;
  ocultar: Function;
}

interface State {
  empleado: EmpleadoGet;
}

export default class EmpleadoVerDetalle extends Component<Props, State> {

  private empleadoService:EmpleadoService;

  constructor(props: Props) {
    super(props);

    this.state = {
      empleado: this.props.empleado
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_PERSONAL_EMPLEADOS,
      funcionCallback: this.refrescarEmpleado,
    });

    this.empleadoService = new EmpleadoService(Global.UsuarioService.getToken()!);


  }



  refrescarEmpleado = async (e:MessageEvent) => {
    
    
     
        let id:number = JSON.parse(e.data).message.empleadoId;
    
        if (id==this.state.empleado.id)
        {
        let aux:EmpleadoGet = await this.empleadoService.get(id);
        this.setState({ empleado: aux });
    
        }
      }


  componentDidMount() {
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.setState({
        empleado: this.props.empleado,
      });
    }
  }

  ocultar = () => {
    this.props.ocultar();
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
       
        padding="30px"
      >

        <Flex
        container
        alignItems="center"
        justifyContent="center"
        marginTop="30px"
        >
        <h3> Datos Detallados del Empleado</h3>
        </Flex>



        <Flex
        container
        alignItems="flex-start"
        justifyContent="center"
        marginTop="30px"
        >
        <EmpleadoVerDatosPersonales empleado={this.state.empleado} />
        </Flex>

        <Flex
        container
        alignItems="flex-start"
        justifyContent="center"
        marginTop="30px"
        >
       <EmpleadoVerAreas empleado={this.state.empleado} />
        </Flex>

        <Flex
        container
        alignItems="flex-start"
        justifyContent="center"
        marginTop="30px"
        >
       <EmpleadoVerSubAreas empleado={this.state.empleado} />
        </Flex>

        <Flex
        container
        alignItems="flex-start"
        justifyContent="center"
        marginTop="30px"
        >
       <EmpleadoVerPermisos empleado={this.state.empleado} />
        </Flex>

      </Flex>
    );
  }
}
