import React, { Component } from "react";
import * as Icon from "react-bootstrap-icons";
import { Table, Button } from "react-bootstrap";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import VentaBultoProductoLoad from "./VentaBultoProductoLoad";
import { VentaBultoProductoLectura } from "serviciossaintmichel";

export interface Props {
  ventasBultoProducto: Array<VentaBultoProductoLectura>;
  onChange?: Function;
}

interface State {
  ventasBultoProducto: Array<VentaBultoProductoLectura>;
}

export default class VentasBultoProductoLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ventasBultoProducto: props.ventasBultoProducto,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (
      prevProps.ventasBultoProducto !== this.props.ventasBultoProducto &&
      this.state.ventasBultoProducto !== this.props.ventasBultoProducto
    ) {
      this.setState({ ventasBultoProducto: this.props.ventasBultoProducto });
    }

    if (prevState.ventasBultoProducto !== this.state.ventasBultoProducto && this.props.onChange) {
      this.props.onChange(this.state.ventasBultoProducto);
    }
  }

  eliminarVentaBultoProducto = (idBultoProducto: number) => {
    const ventasBultoProducto = this.state.ventasBultoProducto.filter(
      (vbp: VentaBultoProductoLectura) => vbp.bulto_producto.id !== idBultoProducto
    );
    this.setState({
      ventasBultoProducto: [...ventasBultoProducto],
    });
  };

  agregarVentaBultoProducto = (nuevaVentaBultoProducto: VentaBultoProductoLectura): void => {
    this.setState((prevState) => ({
      ventasBultoProducto: [...prevState.ventasBultoProducto, nuevaVentaBultoProducto],
    }));
  };

  actualizarVentaBultoProducto = (idBultoProducto: number, infoVenta: VentaBultoProductoLectura) => {
    const ventas = this.state.ventasBultoProducto.map((vbp) =>
      vbp.bulto_producto.id === idBultoProducto ? infoVenta : vbp
    );
    this.setState({
      ventasBultoProducto: ventas,
    });
  };

  render() {
    const { ventasBultoProducto } = this.state;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Bulto Producto</th>
              <th>Vendidos</th>
              <th>Bonificados</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {ventasBultoProducto.map((ventaBultoProducto, idx) => (
              <tr key={ventaBultoProducto.bulto_producto.id}>
                <td>{`${ventaBultoProducto.bulto_producto.nombre} - Id ${ventaBultoProducto.bulto_producto.id}`}</td>
                <td>
                  <Input
                    type={InputTypes.Number}
                    data={ventaBultoProducto.vendidos}
                    onChange={(valor: number) => {
                      this.actualizarVentaBultoProducto(ventaBultoProducto.bulto_producto.id, {
                        ...ventaBultoProducto,
                        vendidos: valor,
                      });
                    }}
                  />
                </td>
                <td>
                  <Input
                    type={InputTypes.Number}
                    data={ventaBultoProducto.bonificados}
                    onChange={(valor: number) => {
                      this.actualizarVentaBultoProducto(ventaBultoProducto.bulto_producto.id, {
                        ...ventaBultoProducto,
                        bonificados: valor,
                      });
                    }}
                  />
                </td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      this.eliminarVentaBultoProducto(ventaBultoProducto.bulto_producto.id);
                    }}
                  >
                    <Icon.Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <VentaBultoProductoLoad
          onSubmitCallback={this.agregarVentaBultoProducto}
          excluirBultosProducto={ventasBultoProducto.map((vP) => vP.bulto_producto.id)}
        />
      </>
    );
  }
}
