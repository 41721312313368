import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
import ComboRetornables from "./ComboRetornable/ComboRetornables";
import Promociones from "./Promocion/Promociones";

export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];

  if (Global.UsuarioService.getPermisos().Ventas.Otros.Promocion.hasAccess()) {
    aux.push({
      name: "Promociones",
      path: SeccionOtros.pathBase + "/promociones",
      component: Promociones,
    });
  }
  if (Global.UsuarioService.getPermisos().Ventas.Otros.Promocion.hasAccess()) {
    aux.push({
      name: "Combo Retornables",
      path: SeccionOtros.pathBase + "/combo-retornables",
      component: ComboRetornables,
    });
  }
  return aux;
}

export default class SeccionOtros extends Component<Props> {
  static pathBase = "/ventas/seccion-otros";
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Seccion rutasNavbar={getRutasAccesibles()} tituloNavbar={"Otros"} />
    );
  }
}
