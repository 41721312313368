import React, { Component } from "react";
import ServiciosClienteShow from "../actualizar/servicios/ServiciosClienteShow";

export interface Props {
  clienteId: number;
}

interface State {}

export default class ClienteVerServicios extends Component<Props, State> {
  render() {
    const { clienteId } = this.props;
    return <ServiciosClienteShow clienteId={clienteId} />;
  }
}
