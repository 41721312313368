import { getHours, getMinutes, setHours, setMinutes, setSeconds } from "date-fns";

export default class Time extends Date {
  toJSON(): string {
    return `${this.getHours()}:${this.getMinutes()}:${this.getSeconds()}`;
  }
  toISOString(): string {
    return `${this.getHours()}:${this.getMinutes()}:${this.getSeconds()}`;
  }
  setTimeFromDate(date: Date): void {
    this.setHours(date.getHours());
    this.setMinutes(date.getMinutes());
    this.setSeconds(date.getSeconds());
  }
  static getTimeFromDate(date: Date): Time {
    let time = new Time();
    time.setHours(date.getHours());
    time.setMinutes(date.getMinutes());
    time.setSeconds(date.getSeconds());
    return time;
  }
  static crearHorario = (horas: number, minutos: number, segundos: number) => {
    let horario = new Time();
    horario.setHours(horas);
    horario.setMinutes(minutos);
    horario.setSeconds(segundos);
    return horario;
  };
  static getTimeFromString(timeStr: string): Time {
    const [horas, min, seg] = timeStr.split(":");
    let time = new Time();
    time.setHours(Number.parseInt(horas));
    time.setMinutes(Number.parseInt(min));
    time.setSeconds(Number.parseInt(seg));
    return time;
  }
}

/**
 * En base a un string de tiempo representado como "HH:MM:SS" retorna un objeto fecha con esa hora
 */
export const getDateFromTimeString = (horario: string | null): Date | null => {
  if (horario === null) {
    return null;
  }

  const [horas, minutos] = horario.split(":", 2);
  let fecha = new Date();
  fecha = setHours(fecha, Number(horas));
  fecha = setMinutes(fecha, Number(minutos));
  fecha = setSeconds(fecha, 0);
  return fecha;
};

/**
 * En base a una fecha retorna un string de tiempo representado como "HH:MM"
 */
export const getTimeStringFromDate = (fecha: Date | null): string | null => {
  if (fecha === null) {
    return null;
  }

  const horas = getHours(fecha);
  const horasStr = horas < 10 ? `0${horas}` : horas.toString();
  const minutos = getMinutes(fecha);
  const minutosStr = minutos < 10 ? `0${minutos}` : minutos.toString();
  const horarioStr = `${horasStr}:${minutosStr}`;
  return horarioStr;
};
