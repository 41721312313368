import React, { Component } from "react";
import Flex from "../../../../../../compartido/components/flex/Flex";
import { Tabs, Tab } from "react-bootstrap";
import Margin from "../../../../../../compartido/components/margin/Margin";
import PersonaResponsableShow from "./personaResponsable/PersonaResponsableShow";
import DatosDeContactoLectura from "./datosDeContacto/DatosDeContactoLectura";
import DatosAfipShow from "./datosAfip/DatosAfipShow";
import TipoClienteShow from "./tipoDeCliente/TipoClienteShow";
import VinculacionesClienteShow from "./vinculaciones/VinculacionesClienteShow";
import { Cliente } from "serviciossaintmichel";

interface Props {
  cliente: Cliente;
}

interface State {
  tabVisible: any;
  responsive: boolean;
  cliente: Cliente;
}

export default class ClienteDatosGeneralesLectura extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "persona",
      responsive: window.innerWidth < 700 ? true : false,
      cliente: props.cliente,
    };

    window.addEventListener("resize", this.actualizarDimensiones);

    /*
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES,
      funcionCallback: this.refrescarCliente,
    });
    */
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  renderizar = (responsive: boolean) => {
    const { cliente } = this.state;
    return (
      <Margin top="30px">
        <Tabs
          defaultActiveKey="persona"
          transition={false}
          activeKey={this.state.tabVisible}
          onSelect={this.onSelect}
          variant={"pills"}
          style={{
            marginBottom: "1rem",
            flexDirection: responsive ? "column" : "row",
            alignItems: responsive ? "center" : "",
          }}
          unmountOnExit={true}
          width="100%"
        >
          <Tab eventKey="persona" title="Persona Responsable">
            <PersonaResponsableShow personaId={cliente.persona_responsable} />
          </Tab>

          <Tab eventKey="datosDeContacto" title="Datos de Contacto">
            <DatosDeContactoLectura clienteId={cliente.id} />
          </Tab>

          <Tab eventKey="datosAfip" title="Datos Afip">
            <DatosAfipShow cliente={cliente} />
          </Tab>

          <Tab eventKey="tipoDeCliente" title="Tipo de Cliente">
            <TipoClienteShow tipoClienteId={cliente.tipo_cliente} />
          </Tab>

          <Tab eventKey="vinculacionesCliente" title="Vinculaciones">
            <VinculacionesClienteShow idCliente={cliente.id} />
          </Tab>
        </Tabs>
      </Margin>
    );
  };

  render() {
    return (
      <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        {this.renderizar(this.state.responsive)}
      </Flex>
    );
  }
}
