import InputBase from "./InputBase";

export default class InputDigitos extends InputBase {
  esValida(cadena) {
    const expresionRegular = new RegExp("^[0-9]*$", "gi");
    return expresionRegular.test(cadena) || cadena === "";
  }
  render() {
    return super.render();
  }
}
