import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";


import { CANALES_WEB_SOCKET, TipoVehiculoGet, TipoVehiculoPost, TipoVehiculoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";


export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  tipoVehiculos: Array<TipoVehiculoGet>;
}

export default class TipoVehiculoLista extends Component<Props, State> {

  private tipoVehiculoService:TipoVehiculoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      tipoVehiculos: [],
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_VEHICULOS,
      funcionCallback: this.getElementos,
    });

    this.tipoVehiculoService = new TipoVehiculoService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.tipoVehiculoService.list()
      .then((result) => {
        this.setState({
          tipoVehiculos: result,
        });
      })
      .catch((error) => {
        this.tipoVehiculoService.manejarErrorHTTP(error, "Tipo Vehículo");
      });
  };

  componentDidMount() {
    this.tipoVehiculoService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }
  armarFilas(tipo_vehiculos: Array<TipoVehiculoGet>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (tipo_vehiculos.length > 0) {
      for (let i = 0; i < tipo_vehiculos.length; i++) {
        let fila: Array<string> = [];
        fila.push(tipo_vehiculos[i].tipo);
        let filaResult: FilaTablaGenerica = {
          id: tipo_vehiculos[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Elementos.Vehiculos.TipoVehiculo.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.tipoVehiculoService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.tipoVehiculos)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.tipoVehiculoService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaTipoVehiculos"
      />
    );
  }
}
