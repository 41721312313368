import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import SelectProveedor from "../../../../produccion/proveedores/components/SelectProveedor";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectMaquinaServicioSimbolica from "../../MaquinaServicioSimbolica/components/SelectMaquinaServicioSimbolica";

import { CANALES_WEB_SOCKET,       MaquinaServicioFisicaGet,       MaquinaServicioFisicaPost,       MaquinaServicioFisicaService,       MaquinaServicioSimbolicaGet,       MaquinaServicioSimbolicaService,       Proveedor, ProveedorService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  maquinaServicioFisicaId: number;
  ocultar: Function;
}

interface State {
  maquinaServicioFisica: MaquinaServicioFisicaGet;
  proveedores: Array<Proveedor>;
  maquinasSimbolica: Array<MaquinaServicioSimbolicaGet>;
  alerta: any;
}

export default class MaquinaServicioFisicaActualizar extends Component<Props, State> {

  private proveedorService:ProveedorService;
  private maquinaServicioSimbolicaService:MaquinaServicioSimbolicaService;
  private maquinaServicioFisicaService:MaquinaServicioFisicaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      maquinaServicioFisica: this.crearMaquinaServicioFisicaVacio(),
      proveedores: [],
      maquinasSimbolica: [],
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_SIMBOLICA,
      funcionCallback: this.cargarMaquinasSimbolica,
    });
    /* WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.PROVEEDORES,
      funcionCallback: this.cargarProveedor,
    }); */

    this.proveedorService = new ProveedorService(Global.UsuarioService.getToken()!);
    this.maquinaServicioSimbolicaService = new MaquinaServicioSimbolicaService(Global.UsuarioService.getToken()!);
    this.maquinaServicioFisicaService = new MaquinaServicioFisicaService(Global.UsuarioService.getToken()!);

  }

  cargarProveedores = async () => {
    const resProveedores = await this.proveedorService.listAll().catch((error) => {
      this.proveedorService.manejarErrorHTTP(error, "Proveedor");
      return null;
    });
    if (resProveedores != null) {
      this.setState({
        proveedores: resProveedores,
      });
    }
  };

  cargarMaquinasSimbolica = async () => {
    const resMaquinasSimbolica = await this.maquinaServicioSimbolicaService.listAll().catch((error) => {
      this.maquinaServicioSimbolicaService.manejarErrorHTTP(error, "Máquina Servicio Simbólica");
      return null;
    });
    if (resMaquinasSimbolica != null) {
      this.setState({
        maquinasSimbolica: resMaquinasSimbolica,
      });
    }
  };

  componentDidMount() {
    this.cargarMaquinaServicioFisica(this.props.maquinaServicioFisicaId);
    this.cargarProveedores();
    this.cargarMaquinasSimbolica();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarMaquinaServicioFisica(this.props.maquinaServicioFisicaId);
      this.cargarProveedores();
      this.cargarMaquinasSimbolica();
    }
  }

  crearMaquinaServicioFisicaVacio(): MaquinaServicioFisicaGet {
    const proveedor: Proveedor = { id: 0, nombre: "" };
    const maquina_simbolica: MaquinaServicioSimbolicaGet = {
      id: 0,
      nombre: "",
      productos: [],
      proveedores: [],
    };
    const maquinaServicioFisica: MaquinaServicioFisicaGet = {
      id: 0,
      marca: "",
      numero_serie: "",
      fecha_fabricacion: new Date(),
      maquina_simbolica: maquina_simbolica,
      proveedor: proveedor,
      direccion: null,
    };
    return maquinaServicioFisica;
  }

  cargarMaquinaServicioFisica = async (id: number) => {
    const resMaquinaServicioFisica = await this.maquinaServicioFisicaService.get(id).catch((error) => {
      this.maquinaServicioFisicaService.manejarErrorHTTP(error, "Máquina Servicio Física");
      return null;
    });
    if (resMaquinaServicioFisica != null) {
      this.setState({
        maquinaServicioFisica: resMaquinaServicioFisica,
        alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  setearNumeroSerie = (numeroSerie: string) => {
    let maquinaServicioFisica = this.state.maquinaServicioFisica;
    maquinaServicioFisica.numero_serie = numeroSerie;
    this.setState({ maquinaServicioFisica: maquinaServicioFisica });
  };

  setearMarca = (marca: string) => {
    let maquinaServicioFisica = this.state.maquinaServicioFisica;
    maquinaServicioFisica.marca = marca;
    this.setState({ maquinaServicioFisica: maquinaServicioFisica });
  };

  setearFechaFabricacion = (fecha: Date) => {
    let maquinaServicioFisica = this.state.maquinaServicioFisica;
    maquinaServicioFisica.fecha_fabricacion = fecha;
    this.setState({ maquinaServicioFisica: maquinaServicioFisica });
  };

  setearProveedor = (proveedor: Proveedor) => {
    let maquinaServicioFisica = this.state.maquinaServicioFisica;
    maquinaServicioFisica.proveedor = proveedor;
    this.setState({ maquinaServicioFisica: maquinaServicioFisica });
  };

  setearMaquinaSimbolica = (maquinaSimbolica: MaquinaServicioSimbolicaGet) => {
    let maquinaServicioFisica = this.state.maquinaServicioFisica;
    maquinaServicioFisica.maquina_simbolica = maquinaSimbolica;
    this.setState({ maquinaServicioFisica: maquinaServicioFisica });
  };

  handleUpdate() {
    const maquinaServicioFisicaActualizar: MaquinaServicioFisicaPost = {
      numero_serie: this.state.maquinaServicioFisica.numero_serie,
      marca: this.state.maquinaServicioFisica.marca,
      fecha_fabricacion: this.state.maquinaServicioFisica.fecha_fabricacion,
      proveedor: this.state.maquinaServicioFisica.proveedor.id,
      maquina_simbolica: this.state.maquinaServicioFisica.maquina_simbolica.id,
      direccion: null,
    };

    this.maquinaServicioFisicaService.update(maquinaServicioFisicaActualizar, this.state.maquinaServicioFisica.id)
      .then((result) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_FISICA, {
          maquinaServicioFisicaId: this.state.maquinaServicioFisica.id,
        });
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Maquina Servicio Fisica Actualizada!",
            titulo: "Atención",
            estado: "block",
          },
        });
      })
      .catch((error) => {
        this.maquinaServicioFisicaService.manejarErrorHTTP(error, "Máquina Servicio Física");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.maquinaServicioFisica.numero_serie &&
      this.state.maquinaServicioFisica.marca &&
      this.state.maquinaServicioFisica.proveedor &&
      this.state.maquinaServicioFisica.maquina_simbolica
      ? false
      : true;
  };

  render() {
    if (
      this.state.maquinaServicioFisica.id != 0 &&
      this.state.maquinasSimbolica.length > 0 &&
      this.state.proveedores.length > 0
    ) {
      return (
        <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            <div className={"row"}>
              <div className={"column"}>
                <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
                  <div className={"form-group"}>
                    <label htmlFor="numeroSerie">Número Serie</label>
                    <InputCaracteres
                      id="numeroSerie"
                      name="numeroSerie"
                      onChange={this.setearNumeroSerie}
                      value={this.state.maquinaServicioFisica.numero_serie}
                      placeholder="Ingrese Número de Serie de la Maquina Servicio Fisica"
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="marca">Marca</label>
                    <InputCaracteres
                      id="marca"
                      name="marca"
                      onChange={this.setearMarca}
                      value={this.state.maquinaServicioFisica.marca}
                      placeholder="Ingrese Marca de Serie de la Maquina Servicio Fisica"
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="fechaFabricacion">Fecha de Fabricación</label>
                    <DatePicker
                      id="fechaFabricacion"
                      selected={new Date(this.state.maquinaServicioFisica.fecha_fabricacion)}
                      dateFormat="dd/MM/yyy"
                      onChange={this.setearFechaFabricacion}
                    />
                  </div>
                  <div className={"form-group"}>
                    <SelectProveedor
                      proveedores={this.state.proveedores}
                      seleccionado={this.state.maquinaServicioFisica.proveedor}
                      callbackParent={this.setearProveedor}
                    />
                  </div>
                  <div className={"form-group"}>
                    <label>Tipo de máquina</label>
                    <SelectMaquinaServicioSimbolica
                      maquinas={this.state.maquinasSimbolica}
                      idSeleccionado={this.state.maquinaServicioFisica.maquina_simbolica.id}
                      onSelect={this.setearMaquinaSimbolica}
                    />
                  </div>
                  <div className={"form-group"} style={{ display: "flex", justifyContent: "space-between" }}>
                    <button ref="boton" type="submit" className={"btn btn-info"} disabled={this.disableButton()}>
                      {"Actualizar"}
                    </button>
                    <button className={"btn btn-outline-secondary"} onClick={this.ocultar}>
                      Ocultar
                    </button>
                  </div>
                  <div className={"form-group"}>
                    <Alerta
                      tipo={this.state.alerta.tipo}
                      titulo={this.state.alerta.titulo}
                      mensaje={this.state.alerta.mensaje}
                      estado={this.state.alerta.estado}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
