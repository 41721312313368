import React, { Component } from "react";
import { Select, SelectProps } from "@mantine/core";

export interface PropsSelectGeneral {
  disabled?: boolean;
  clearable?: boolean; // Si puede estar el select vacio
  sinTitulo?: boolean; // Si no se quiere el tener el label de titulo
  label?: string;
  placeholder?: string; // Si no se quiere el tener el label de titulo
}
export interface PropsSelectBusqueda {
  callbackParent: (index: number | null) => any; // Funcion que se se ejecuta con el value seleccionado (entero) o null
  stylesLabel?: any; // Estilos para el label de titulo
  limitMinBusqueda?: number; // Cantidad minima de elementos para que aparezca el input de busqueda
}
interface StateSelectBusqueda {}

export default class SelectBusqueda extends Component<
  PropsSelectBusqueda & PropsSelectGeneral & SelectProps,
  StateSelectBusqueda
> {
  constructor(props: PropsSelectBusqueda & PropsSelectGeneral & SelectProps) {
    super(props);
    this.state = {};
  }

  onChangeHandler = (value: string | null) => {
    this.props.callbackParent(value != null ? Number.parseInt(value) : value);
  };

  LIMITE_SELECT_BUSCABLE: number = 5;
  esBuscable(): boolean {
    if (this.props.searchable) return true;
    if (this.props.limitMinBusqueda && this.props.data.length > this.props.limitMinBusqueda) return true;
    if (this.props.data.length > this.LIMITE_SELECT_BUSCABLE) return true;
    return false;
  }

  render() {
    return (
      <Select
        style={{
          width: "100%",
        }}
        styles={{
          label: {
            fontSize: "medium",
            ...this.props.stylesLabel,
          },
        }}
        label={this.props.sinTitulo ? null : this.props.label}
        searchable={this.esBuscable()}
        nothingFound="No encontrado"
        maxDropdownHeight={250}
        onChange={this.onChangeHandler}
        {...this.props}
      />
    );
  }
}
