import React, { Component } from "react";
import { Button, ListGroup } from "react-bootstrap";
import VehiculosShow, {
  Version as VersionVehiculosShow,
} from "../../../../elementos/vehiculos/Vehiculo/components/VehiculosShow";
import Flex from "../../../../libreria/appearance/flex/Flex";
import * as Icon from "react-bootstrap-icons";
import VehiculoShow from "../../../../elementos/vehiculos/Vehiculo/components/VehiculoShow";
import { VehiculoGet } from "serviciossaintmichel";

export interface Props {
  onChange: Function;
  vehiculos?: Array<number>;
}

interface State {
  vehiculo: VehiculoGet | null;
  vehiculos: Array<number>;
  validation: boolean;
}

export default class VehiculosRepartoDiarioLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      vehiculos: props.vehiculos || [],
      validation: false,
      vehiculo: null,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.vehiculos && prevProps.vehiculos !== this.props.vehiculos) {
      this.setState({ vehiculos: this.props.vehiculos });
    }

    if (prevState.vehiculos !== this.state.vehiculos) {
      this.props.onChange(this.state.vehiculos);
    }
  }

  setVehiculo = (vehiculo: VehiculoGet | null) => {
    this.setState({ vehiculo: vehiculo });
  };

  addVehiculo = () => {
    if (!this.state.vehiculo) {
      return;
    }

    this.setState({
      vehiculos: [...this.state.vehiculos, this.state.vehiculo.id],
      vehiculo: null,
    });
  };

  removeVehiculo = (vehiculoId: number) => {
    const vehiculos = this.state.vehiculos.filter((v) => v !== vehiculoId);
    this.setState({
      vehiculos: vehiculos,
    });
  };

  render() {
    const { vehiculos, vehiculo } = this.state;

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" gap="1rem">
        <ListGroup as="ol" style={{ width: "100%" }}>
          {vehiculos.map((vehiculo: number, idx: number) => (
            <ListGroup.Item
              as="li"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              key={vehiculo}
            >
              <VehiculoShow vehiculoId={vehiculo} />

              <Button variant="danger" onClick={() => this.removeVehiculo(vehiculo)}>
                <Icon.Trash />
              </Button>
            </ListGroup.Item>
          ))}
        </ListGroup>
        <Flex flexDirection="row" alignItems="center" justifyContent="center">
          <VehiculosShow
            version={VersionVehiculosShow.SELECT}
            onSelect={this.setVehiculo}
            excluirIds={vehiculos}
            propsSelect={{ callbackParent: this.setVehiculo, seleccionado: vehiculo }}
          />
          <Button onClick={this.addVehiculo} disabled={vehiculo === null} style={{ marginTop: "1rem" }}>
            Agregar
          </Button>
        </Flex>
      </Flex>
    );
  }
}
