import React, { Component } from "react";
import "../../../template/template.css";
import { NavLink } from "react-router-dom";
import RutaNavbar from "../../models/Ruta";
import { Navbar, Nav } from "react-bootstrap";

export interface Props {
  titulo?: string;
  rutas: Array<RutaNavbar>;
}

export default class NavbarPro extends Component<Props> {
  render() {
    return (
      <Navbar style={{ padding: "5px",borderRadius:5 }} collapseOnSelect expand="lg" bg="light" variant="light">
        <Navbar.Brand>{this.props.titulo ? this.props.titulo : "Menú"}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {this.props.rutas.length > 1
              ? this.props.rutas.map((ruta: RutaNavbar, i: number) => (
                  <Nav.Link>
                    <NavLink to={ruta.path} style={{ color: "black" }} activeStyle={{ fontWeight: "bold" }}>
                      <div>{ruta.name}</div>
                    </NavLink>
                  </Nav.Link>
                ))
              : null}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
