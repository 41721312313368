import React, { Component } from "react";
import Text from "../../../libreria/message/text/Text";
import Flex from "../../../libreria/appearance/flex/Flex";
import Margin from "../../../libreria/appearance/margin/Margin";
import { Box } from "../../../libreria/containers/box/Box";
import H from "../../../libreria/message/title/H";
import { Fuentes } from "../../estilos/estilos";

import { Localidad, LocalidadService,    } from "serviciossaintmichel";
import { Global } from "../../../Global";


export interface Props {
  idLocalidad?: number;
  localidad?: Localidad;
  soloCampoText?: boolean;
  soloCampoTextSinLabel?: boolean;
}

interface State {
  localidad: Localidad | null;
  cargando: boolean;
  visible: boolean;
}

export default class LocalidadShow extends Component<Props, State> {

  private localidadService:LocalidadService;

  constructor(props: Props) {
    super(props);
    this.state = {
      localidad: null,
      cargando: true,
      visible: false,
    };
    this.localidadService = new LocalidadService(Global.UsuarioService.getToken()!);

  
  }

  componentDidMount = async () => {
    await this.cargarLocalidad();
    /* WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET,
      funcionCallback: this.onChannelMesagge,
    }); */
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.facturaId == this.props.idLocalidad) {
      await this.cargarLocalidad();
    }
  };

  cargarLocalidad = async () => {
    if (this.props.idLocalidad) {
      let localidad = await this.localidadService.get(this.props.idLocalidad).catch(
        (error) => {
          this.localidadService.manejarErrorHTTP(error, "Localidad");
          return null;
        }
      );
      this.setState({ localidad: localidad, cargando: false });
    } else if (this.props.localidad)
      this.setState({ localidad: this.props.localidad, cargando: false });
    else this.setState({ cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      await this.setState({ cargando: true });
      await this.cargarLocalidad();
    }
  };

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  renderLocalidad = () =>
    this.state.localidad ? (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        marginTop="10px"
      >
        <H
          size={3}
          fuente={Fuentes.Titulos}
          texto={"Localidad " + this.state.localidad.id}
        />
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="flex-start"
          justifyContent="center"
          marginTop="10px"
        >
          <Text>Localidad: {this.state.localidad.nombre}</Text>
          <Text>Partido: {this.state.localidad.partido.nombre}</Text>
          <Text>
            Provincia: {this.state.localidad.partido.provincia.nombre}
          </Text>
          <Margin top="30px" />
        </Flex>
      </Flex>
    ) : null;
  renderLoading = () => "Cargando Datos";

  renderLocalidadText = () =>
    this.state.localidad ? (
      <Text>
        {this.props.soloCampoTextSinLabel == true ? "" : <b>Localidad</b>}:{" "}
        {this.state.localidad.nombre +
          ", " +
          this.state.localidad.partido.nombre +
          ", " +
          this.state.localidad.partido.provincia.nombre}
      </Text>
    ) : null;

  render() {
    if (!this.props.soloCampoText)
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Margin top="20px" />
          <Box padding="15px">
            {this.state.cargando
              ? this.renderLoading()
              : this.renderLocalidad()}
          </Box>
        </Flex>
      );
    else return this.renderLocalidadText();
  }
}
