import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";

import { CANALES_WEB_SOCKET, IndumentariaGet, IndumentariaPost, IndumentariaService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  indumentarias: Array<IndumentariaGet>;
}

export default class IndumentariaLista extends Component<Props, State> {

  private indumentariaService:IndumentariaService;


  constructor(props: Props) {
    super(props);
    this.state = {
      indumentarias: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_INDUMENTARIAS,
      funcionCallback: this.getElementos,
    });

    this.indumentariaService =  new IndumentariaService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.indumentariaService.list()
      .then((result) => {
        this.setState({ indumentarias: result });
      })
      .catch((error) => {
        this.indumentariaService.manejarErrorHTTP(error, "Indumentaria");
      });
  };

  componentDidMount() {
    this.indumentariaService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }
  armarFilas(indumentarias: Array<IndumentariaGet>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (indumentarias.length > 0) {
      for (let i = 0; i < indumentarias.length; i++) {
        let fila: Array<string> = [];
        fila.push(indumentarias[i].nombre);
        fila.push(indumentarias[i].talle);
        fila.push(indumentarias[i].marca);
        fila.push(indumentarias[i].descripcion);
        let filaResult: FilaTablaGenerica = {
          id: indumentarias[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Elementos.Indumentarias.Indumentaria.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Elementos.Indumentarias.Indumentaria.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.indumentariaService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.indumentarias)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.indumentariaService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaIndumentarias"
      />
    );
  }
}
