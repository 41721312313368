import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { addMonths, endOfMonth, startOfMonth } from "date-fns/esm";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import FechaUtils from "../../../../../../../compartido/utils/FechaUtils";
import SelectConsumo from "../../../../../../seccion-servicios/consumos/components/SelectConsumo";
import { ConsumoCliente, ConsumoGet } from "serviciossaintmichel";



export interface Props {
  consumoCliente: ConsumoCliente | null;
  consumo: ConsumoGet | null;
  clienteId: number;
  onChange: Function;
  onValidationChange: Function;
  updating?: boolean;
  fechaInicioMin?: Date;
  fechaFinMax?: Date;
  fechaFinMin?: Date;
}

interface State {
  consumoCliente: ConsumoCliente;
  consumoClienteValido: boolean;
  consumoSeleccionado: ConsumoGet | null;
  consumoValido: boolean;
}

export default class ConsumoClienteLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const initialValidation = !!props.consumoCliente;

    this.state = {
      consumoSeleccionado: props.consumo,
      consumoCliente: props.consumoCliente || this.crearConsumoCliente(),
      consumoClienteValido: initialValidation,
      consumoValido: initialValidation,
    };
  }

  crearConsumoCliente = (): ConsumoCliente => {
    const { fechaInicioMin } = this.props;
    let fechaInicio = fechaInicioMin;

    if (!fechaInicioMin) {
      const mesProximo = addMonths(new Date(), 1);
      fechaInicio = startOfMonth(mesProximo);
    } else {
      fechaInicio = fechaInicioMin;
    }

    return {
      id: -1,
      consumo: -1,
      cliente: this.props.clienteId,
      fecha_inicio: FechaUtils.normalizarFecha(fechaInicio),
      fecha_fin: null,
      tiempo: "",
    };
  };

  componentDidMount = () => {
    this.validar();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { consumoCliente, consumoClienteValido } = this.state;
    const { fechaInicioMin } = this.props;

    if (prevState.consumoCliente !== consumoCliente) {
      this.props.onChange(consumoCliente);
      this.validar();
    }
    if (prevState.consumoClienteValido !== consumoClienteValido) {
      this.props.onValidationChange(consumoClienteValido);
    }
    if (prevProps.fechaInicioMin !== fechaInicioMin && fechaInicioMin) {
      this.setFechaInicio(fechaInicioMin);
    }
    if (
      this.props.consumoCliente &&
      JSON.stringify(prevProps.consumoCliente) !==
        JSON.stringify(this.props.consumoCliente)
    ) {
      this.setState({ consumoCliente: this.props.consumoCliente });
    }
  };

  validar = () => {
    const { consumoValido, consumoCliente } = this.state;
    let valido = !!consumoValido;
    if (consumoCliente.fecha_fin) {
      valido = valido && consumoCliente.fecha_fin > consumoCliente.fecha_inicio;
    }
    this.setState({ consumoClienteValido: valido });
  };

  setConsumo = (consumo: ConsumoGet) => {
    const consumoValido = !!consumo;
    const consumoCliente: ConsumoCliente = {
      ...this.state.consumoCliente,
      consumo: consumo ? consumo.id : -1,
    };
    this.setState({
      consumoSeleccionado: consumo,
      consumoValido: consumoValido,
      consumoCliente: consumoCliente,
    });
  };

  setFechaInicio = (fecha: Date) => {
    const consumoCliente: ConsumoCliente = {
      ...this.state.consumoCliente,
      fecha_inicio: FechaUtils.normalizarFecha(fecha),
    };
    this.setState({ consumoCliente: consumoCliente });
  };

  setFechaFin = (fecha: Date) => {
    const consumoCliente: ConsumoCliente = {
      ...this.state.consumoCliente,
      fecha_fin: FechaUtils.normalizarFecha(fecha),
    };
    this.setState({ consumoCliente: consumoCliente });
  };

  getFechasInicioPermitidas = (fechaInicial: Date, años: number) => {
    let fechasPermitidas = [];
    let fechaObjetivo = fechaInicial;

    for (let i = 1; i <= 12 * años; i++) {
      fechaObjetivo = addMonths(fechaObjetivo, 1);
      fechasPermitidas.push(startOfMonth(fechaObjetivo));
    }
    return fechasPermitidas;
  };

  getFechasFinPermitidas = (fechaInicial: Date, años: number) => {
    let fechasPermitidas = [];
    let fechaObjetivo = fechaInicial;

    for (let i = 1; i <= 12 * años; i++) {
      fechasPermitidas.push(endOfMonth(fechaObjetivo));
      fechaObjetivo = addMonths(fechaObjetivo, 1);
    }
    return fechasPermitidas;
  };

  render = () => {
    const today = new Date();
    const { consumoSeleccionado, consumoCliente } = this.state;
    const {
      updating = false,
      fechaInicioMin = today,
      fechaFinMax,
    } = this.props;
    const fechaInicio = FechaUtils.normalizarFecha(consumoCliente.fecha_inicio);
    let fechaFin = consumoCliente.fecha_fin;
    if (fechaFin) {
      fechaFin = FechaUtils.normalizarFecha(fechaFin);
    }
    const fechasInicioPermitidas = this.getFechasInicioPermitidas(today, 2);
    const fechasFinPermitidas = this.getFechasFinPermitidas(fechaInicioMin, 2);

    return (
      <>
        <Flex container flexDirection="column">
          <span>Nombre del consumo</span>
          {updating ? (
            <div>{consumoSeleccionado?.nombre}</div>
          ) : (
            <SelectConsumo
              callbackParent={this.setConsumo}
              seleccionado={consumoSeleccionado}
            />
          )}
        </Flex>
        <Flex container flexDirection="column">
          <span>Es especial</span>
          <div>{consumoSeleccionado?.especial ? "Si" : "No"}</div>
        </Flex>
        <Flex container flexDirection="column" marginBottom="1rem">
          {updating ? (
            <>
              <span>Fecha de inicio</span>
              <div>{fechaInicio.toLocaleDateString("es-AR")}</div>
            </>
          ) : (
            <>
              <span>Fecha de inicio</span>
              <DatePicker
                selected={fechaInicio}
                onChange={this.setFechaInicio}
                dateFormat="dd/MM/yyyy"
                minDate={fechaInicioMin}
                includeDates={fechasInicioPermitidas}
              />
            </>
          )}
        </Flex>
        <Flex container flexDirection="column">
          <span>Fecha de finalización</span>
          <DatePicker
            selected={fechaFin}
            onChange={this.setFechaFin}
            dateFormat="dd/MM/yyyy"
            includeDates={fechasFinPermitidas}
            maxDate={fechaFinMax}
            isClearable={!fechaFinMax}
          />
        </Flex>
      </>
    );
  };
}
