import React, { Component } from "react";
import { Fondos } from "./Fondos";

interface Props {
  children?: any;
  className?: string;
  borde?: string;
  radioBorde?: string;
  width?: string;
  height?: string;
  fondo?: Fondos;
  padding?: string;
  overflowY?:
    | "-moz-initial"
    | "inherit"
    | "initial"
    | "revert"
    | "unset"
    | "hidden"
    | "auto"
    | "scroll"
    | "visible"
    | undefined;
}

interface State {}

class Contenedor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <div
        className={this.props.className}
        style={{
          border: this.props.borde || "0px solid black",
          borderRadius: this.props.radioBorde || 0,
          width: this.props.width || "100%",
          height: this.props.height || "100%",
          background: this.props.fondo || "white",
          padding: this.props.padding || "0px",
          overflowY: this.props.overflowY || undefined,
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
export default Contenedor;
