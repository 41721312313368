import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import RequestButton from "../../../../../../libreria/action/request-button/RequestButton";
import { Modal } from "@mantine/core";
import ProductoPromocionUpdate from "./ProductoPromocionUpdate";
import {
  CANALES_WEB_SOCKET,
  ProductoPromocion,
  PromocionService,
  WebSocketService,
} from "serviciossaintmichel";
import { Global } from "../../../../../../Global";
import ImagenesURLs from "../../../../../../compartido/utils/ImagenesURLs";

export interface Props {
  idPromocion: number;
  productos: Array<ProductoPromocion>;
}
interface State {
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
  producto: ProductoPromocion | null;
}

export default class ProductosPromocionShow extends Component<Props, State> {
  private promocionService: PromocionService;

  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      cargando: false,
      producto: null,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.promocionService = new PromocionService(
      Global.UsuarioService.getToken()!
    );
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {};

  eliminarProductoPromocion = async (
    idPromocion: number,
    idProducto: number
  ) => {
    try {
      this.promocionService.eliminarRelacionConProducto(
        idPromocion,
        idProducto
      );
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.VENTAS_PROMOCIONES, {
        promocionId: this.props.idPromocion,
      });
    } catch (error) {
      this.promocionService.manejarErrorHTTP("Eliminar Producto Promocion");
    }
  };

  renderProductos = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      {this.props.productos.length > 0
        ? this.renderizarTabla()
        : this.renderSinProductos()}
      <Modal
        styles={{
          title: {
            fontSize: "larger",
          },
          modal: {
            backgroundImage: ImagenesURLs.urlFondoModal(),
          },
        }}
        opened={this.state.producto ? true : false}
        onClose={() => this.setOpened(null)}
        title="Actualizar Producto de la Promoción"
      >
        {this.state.producto ? (
          <ProductoPromocionUpdate
            producto={{
              cantidad_cobrable: this.state.producto.cantidad_cobrable,
              cantidad_bonificada: this.state.producto.cantidad_bonificada,
              activo: this.state.producto.activo,
              producto: this.state.producto.producto,
            }}
            idPromocion={this.props.idPromocion}
            onSaveSuccess={() => this.setOpened(null)}
            onExit={() => this.setOpened(null)}
          />
        ) : null}
      </Modal>
    </Flex>
  );

  renderSinProductos = () => {
    return "No posee Productos";
  };

  renderizarFilas = () => {
    return this.props.productos.map((relacion, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{relacion.producto.nombre}</td>
          <td className="text-center">{relacion.cantidad_cobrable}</td>
          <td className="text-center">{relacion.cantidad_bonificada}</td>
          <td className="text-center">{this.botones(relacion)}</td>
        </tr>
      );
    });
  };

  botones = (relacion: ProductoPromocion) => {
    return (
      <Flex
        flexDirection="row"
        width="100%"
        alignItems="center"
        justifyContent="center"
        marginTop="20px"
        gap="1rem"
      >
        <RequestButton
          texto={"Actualizar"}
          onClick={() => {
            this.setOpened(relacion);
          }}
        />
        <RequestButton
          propsBoton={{
            variant: "danger",
          }}
          texto={"Eliminar"}
          onClick={() => {
            this.eliminarProductoPromocion(
              this.props.idPromocion,
              relacion.producto.id
            );
          }}
        />
      </Flex>
    );
  };
  setOpened = (producto: ProductoPromocion | null) => {
    this.setState({ producto: producto });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Producto</th>
            <th className="text-center">Cantidad</th>
            <th className="text-center">Bonificación</th>
            <th className="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  renderLoading = () => "Cargando Productos";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="20px"
      >
        {this.state.cargando ? this.renderLoading() : this.renderProductos()}
      </Flex>
    );
  }
}
