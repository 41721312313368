import React, { Component } from "react";
import { Modal } from "@mantine/core";
import * as Icon from "react-bootstrap-icons";
import ManejadorErroresHTTP from "../../../../compartido/utils/ManejadorErroresHTTP";
import { Button } from "react-bootstrap";
import CargaUpdate from "../update/CargaUpdate";
import CargamentoDetalle from "../show/CargamentoDetalle";
import Flex from "../../../../libreria/appearance/flex/Flex";
import RepartoDiarioCargasShow from "../show/RepartoDiarioCargasShow";
import CargaNew from "../new/CargaNew";
import Swal from "sweetalert2";
import RequestButton from "../../../../libreria/action/request-button/RequestButton";

import {
  CANALES_WEB_SOCKET,
  CargamentoLectura,
  RepartoDiarioService,
  WebSocketService,
} from "serviciossaintmichel";
import { Global } from "../../../../Global";
import ImagenesURLs from "../../../../compartido/utils/ImagenesURLs";

interface Props {
  idRepartoDiario: number;
}

interface State {
  cargaId: number | null;
}

export default class CargasUpdate extends Component<Props, State> {
  private repartoDiarioService: RepartoDiarioService;

  constructor(props: Props) {
    super(props);
    this.state = {
      cargaId: null,
    };

    this.repartoDiarioService = new RepartoDiarioService(
      Global.UsuarioService.getToken()!
    );
  }

  eliminarCarga = async (cargaId: number) => {
    try {
      await this.repartoDiarioService.eliminarCarga(
        this.props.idRepartoDiario,
        cargaId
      );
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
        {
          idRepartoDiario: this.props.idRepartoDiario,
        }
      );
      Swal.fire({
        icon: "success",
        text: "Carga eliminada con éxito",
      });
    } catch (error) {
      const msjError = ManejadorErroresHTTP.getMensajeError(error);
      Swal.fire({
        icon: "error",
        text: "No se pudo eliminar la carga - " + msjError,
      });
    }
  };

  renderCarga = (carga: CargamentoLectura) => {
    return (
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid black",
          borderRadius: "5px",
          padding: "1rem",
        }}
      >
        <Flex gap="1rem" justifyContent="flex-end">
          <Button
            onClick={() => {
              this.setState({ cargaId: carga.id });
            }}
          >
            <Icon.Pencil />
          </Button>
          <RequestButton
            propsBoton={{ variant: "danger" }}
            onClick={() => {
              this.eliminarCarga(carga.id);
            }}
            icon={<Icon.Trash />}
            texto=""
            confirmacion
          />
        </Flex>
        <CargamentoDetalle cargamento={carga} />
      </div>
    );
  };

  render() {
    const { cargaId } = this.state;
    const { idRepartoDiario } = this.props;

    return (
      <>
        <RepartoDiarioCargasShow
          idRepartoDiario={idRepartoDiario}
          renderMethod={this.renderCarga}
        />

        <Button
          variant="success"
          onClick={() => {
            this.setState({ cargaId: -1 });
          }}
        >
          Añadir una carga
        </Button>

        {cargaId !== null ? (
          <Modal
            centered
            size="70%"
            transitionDuration={0}
            opened={cargaId !== null}
            onClose={() => {
              this.setState({ cargaId: null });
            }}
            styles={{
              modal: {
                backgroundImage: ImagenesURLs.urlFondoModal(),
              },
            }}
            closeOnClickOutside={false}
            title={
              cargaId && cargaId > 0
                ? "Modificación de carga"
                : "Registro de carga"
            }
          >
            {cargaId > -1 ? (
              <CargaUpdate
                idRepartoDiario={idRepartoDiario}
                idCarga={cargaId}
              />
            ) : (
              <CargaNew idRepartoDiario={idRepartoDiario} />
            )}
          </Modal>
        ) : null}
      </>
    );
  }
}
