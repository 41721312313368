import React, { Component } from "react";
import EmailsClienteShow from "../emails/EmailsClienteShow";
import TelefonosClienteShow from "../telefonos/TelefonosClienteShow";

interface Props {
  clienteId: number;
}

interface State {}

export default class DatosDeContactoLectura extends Component<Props, State> {
  render() {
    const { clienteId } = this.props;
    return (
      <>
        <TelefonosClienteShow clienteId={clienteId} />
        <hr style={{ width: "100%" }} />
        <EmailsClienteShow clienteId={clienteId} />
      </>
    );
  }
}
