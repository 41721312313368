import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Screen, { ScreenType } from "../../../../compartido/models/Screen";
import Flex from "../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../libreria/containers/box/Box";
import FechaUtils from "../../../../compartido/utils/FechaUtils";
import VehiculoShow from "../../../../elementos/vehiculos/Vehiculo/components/VehiculoShow";
import EmpleadoShow from "../../../../administracion/seccion-personal/empleados/components/EmpleadoShow";
import { Badge } from "@mantine/core";

import { CANALES_WEB_SOCKET,    Reparto, RepartoDiarioLectura, RepartoDiarioService, RepartoService, RolEmpleado, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  repartoDiarioId: number;
}

interface State {
  repartoDiario: RepartoDiarioLectura | null;
  reparto: Reparto | null;
  screen: ScreenType;
}

export default class RepartoDiarioShow extends Component<Props, State> {

  private repartoDiarioService:RepartoDiarioService;
  private repartoService:RepartoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      repartoDiario: null,
      reparto: null,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    this.repartoDiarioService =  new RepartoDiarioService(Global.UsuarioService.getToken()!);
    this.repartoService =  new RepartoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
      funcionCallback: this.refrescarRepartoDiario,
    });
    window.addEventListener("resize", this.actualizarDimensiones);
    const repartoDiario = await this.refrescarRepartoDiario();
    const reparto = await this.repartoService.get(repartoDiario.reparto.id);
    this.setState({ reparto: reparto });
  };

  refrescarRepartoDiario = async () => {
    const repartoDiario = await this.repartoDiarioService.get(this.props.repartoDiarioId);
    repartoDiario.fecha_realizacion = repartoDiario.fecha_realizacion
      ? FechaUtils.normalizarFecha(repartoDiario.fecha_realizacion)
      : null;
    this.setState({ repartoDiario: repartoDiario });
    return repartoDiario;
  };

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  renderizarPhone = () => (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex flexDirection="column" width="60%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex flexDirection="column" width="50%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => {
    const { repartoDiario, reparto } = this.state;

    if (repartoDiario === null || reparto === null) {
      return null;
    }

    return (
      <Box padding="20px">
        <Flex flexDirection="column" width="100%" alignItems="flex-start" gap="1rem">
          <div>
            <strong>Reparto: </strong>
            <span>{`${reparto.id} - ${reparto.nombre}`}</span>
          </div>
          <div>
            <strong>Fecha de realización: </strong>
            <span>{repartoDiario.fecha_realizacion?.toLocaleDateString("es-AR")}</span>
          </div>
          <div>
            <strong>Estado: </strong>
            <Badge color="dark" variant="filled" radius="xs">
              {repartoDiario.estado_actual}
            </Badge>
          </div>
          <div>
            <strong>Hora de salida: </strong>
            <span>{repartoDiario.hora_salida ?? "-"}</span>
          </div>
          <div>
            <strong>Hora de llegada: </strong>
            <span>{repartoDiario.hora_llegada ?? "-"}</span>
          </div>
          <div>
            <strong>Vehículos: </strong>
            <ul>
              {repartoDiario.vehiculos.map((vehiculoId: number) => (
                <li>
                  <VehiculoShow vehiculoId={vehiculoId} />
                </li>
              ))}
            </ul>
          </div>
          <div>
            <strong>Empleados: </strong>
            <ul>
              {repartoDiario.roles_empleados.map((val: RolEmpleado) => (
                <li>
                  <EmpleadoShow empleadoId={val.empleado} />
                  <span> - {val.rol}</span>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <strong>Descripción: </strong>
            <div>{repartoDiario.descripcion ?? "-"}</div>
          </div>
        </Flex>
      </Box>
    );
  };

  render() {
    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.screen === ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen === ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen === ScreenType.TABLET ? this.renderizarTablet() : null}
      </Flex>
    );
  }
}
