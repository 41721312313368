import React, { Component } from "react";
import { Marker } from "@react-google-maps/api"; /* global google */
import { ItemPoint } from "../models";

const options = {
  fillColor: "lightblue",
  fillOpacity: 0.8,
  strokeColor: "blue",
  strokeOpacity: 0.8,
  zIndex: 1,
};

interface Props {
  item: ItemPoint;
  order: number;
  selected: boolean;
  onSelect: Function;
  clickable: boolean;
}

interface State {
  item: ItemPoint;
  order: number;
  selected: boolean;
}

class LocationMarker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      item: this.props.item,
      order: this.props.order,
      selected: this.props.selected,
    };
  }
  componentDidMount() {}
  componentDidUpdate = (prevProps: Props) => {
    if (prevProps != this.props) {
      this.setState({
        item: this.props.item,
        order: this.props.order,
        selected: this.props.selected,
      });
    }
  };

  onClick = () => {
    let selected: boolean = this.state.selected;
    selected = !selected;
    this.setState({ selected: selected });
    this.props.onSelect(this.state.item, selected);
  };

  render() {
    return (
      <Marker
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: 11,
          fillColor: this.state.selected ? "#0066ff" : "#ff4d4d",
          fillOpacity: 0.9,
          strokeWeight: 0.4,
        }}
        //opacity={0.5}
        onClick={this.onClick}
        label={this.state.order.toString()}
        position={{
          lat: this.state.item.point.lat,
          lng: this.state.item.point.lng,
        }}
        clickable={this.props.clickable}
      />
    );
  }
}
export default LocationMarker;
