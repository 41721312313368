import React, { Component } from "react";

/* https://medium.com/better-programming/make-a-flexbox-react-component-e96a038da7ec */

/* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */

interface FlexProps {
  children?: any;
  className?: string;
  container?: boolean;
  /****** Container Props ********/
  flexDirection?: "row" | "column";
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "initial" | "inherit";
  flexWrap?: "wrap" | "nowrap" | "wrap-reverse";
  alignItems?: "stretch" | "center" | "flex-start" | "flex-end" | "baseline" | "initial" | "inherit";
  /****** Child Props ********/
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: number;
  flex?: string;
  /****** Common Layout Props ********/
  padding?: string;
  paddingTop?: string;
  paddingRight?: string;
  paddingLeft?: string;
  paddingBottom?: string;

  margin?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  marginRigth?: string;
  marginTop?: string;
  marginLeft?: string;
  marginBottom?: string;
  verticalScroll?:
    | "hidden"
    | "-moz-initial"
    | "inherit"
    | "initial"
    | "revert"
    | "unset"
    | "auto"
    | "visible"
    | "scroll"
    | undefined;

  /****** Borde ********/
  border?: string;
  borderRadius?: string;
  borderTop?: string;
  borderRight?: string;
  borderLeft?: string;
  borderBottom?: string;
  backgroundColor?: string;
  gap?: string;

  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

interface State {}

class Flex extends Component<FlexProps, State> {
  render() {
    return (
      <div
        className={this.props.className}
        onClick={this.props.onClick ? this.props.onClick : undefined}
        style={{
          display: "flex",
          gap: this.props.gap || "0px",
          justifyContent: this.props.justifyContent || "flex-start",
          flexDirection: this.props.flexDirection || "row",
          flexGrow: this.props.flexGrow || 0,
          flexBasis: this.props.flexBasis || "auto",
          flexShrink: this.props.flexShrink || 1,
          flexWrap: this.props.flexWrap || "wrap",
          flex: this.props.flex || undefined,
          alignItems: this.props.alignItems || "stretch",
          margin: this.props.margin || "0",
          padding: this.props.padding || "0",
          paddingTop: this.props.paddingTop || this.props.padding || "0",
          paddingRight: this.props.paddingRight || this.props.padding || "0",
          paddingLeft: this.props.paddingLeft || this.props.padding || "0",
          paddingBottom: this.props.paddingBottom || this.props.padding || "0",

          width: this.props.width || "100%",
          height: this.props.height || "auto",
          maxWidth: this.props.maxWidth || "none",
          marginRight: this.props.marginRigth || "0",
          marginTop: this.props.marginTop || "0",
          marginLeft: this.props.marginLeft || "0",
          marginBottom: this.props.marginBottom || "0",
          overflowY: this.props.verticalScroll || undefined,
          border: this.props.border || "0px solid black",
          borderRadius: this.props.borderRadius || "0px",
          borderRight:
            this.props.border === undefined && this.props.borderRight !== undefined
              ? this.props.borderRight
              : this.props.border,
          borderTop:
            this.props.border === undefined && this.props.borderTop !== undefined
              ? this.props.borderTop
              : this.props.border,
          borderLeft:
            this.props.border === undefined && this.props.borderLeft !== undefined
              ? this.props.borderLeft
              : this.props.border,
          borderBottom:
            this.props.border === undefined && this.props.borderBottom !== undefined
              ? this.props.borderBottom
              : this.props.border,

          backgroundColor: this.props.backgroundColor || "",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
export default Flex;
