import React, { Component } from "react";
import { ComboRetornable, ComboRetornableService } from "serviciossaintmichel";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../../compartido/components/tabla/TablaGenerica";
import { Global } from "../../../../../Global";




export interface Props {
  onUpdate: Function;
  onView: Function;
  comboRetornables: Array<ComboRetornable>;
  comboRetornablesFuncion: Function;
}

interface State {}

export default class ComboRetornablesShowTable extends Component<Props, State> {

  private comboRetornableService:ComboRetornableService;

  constructor(props: Props) {
    super(props);
    this.state = {
      comboRetornables: [],
    };

    this.comboRetornableService =  new ComboRetornableService(Global.UsuarioService.getToken()!);

  }

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };
  armarFilas(
    comboRetornables: Array<ComboRetornable>
  ): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (comboRetornables.length > 0) {
      for (let i = 0; i < comboRetornables.length; i++) {
        let fila: Array<string> = [];
        fila.push(comboRetornables[i].nombre);
        let filaResult: FilaTablaGenerica = {
          id: comboRetornables[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.onUpdate(id);
  };

  handleVer = (id: number) => {
    this.props.onView(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (Global.UsuarioService.getPermisos().Ventas.Otros.ComboRetornable.canChange()) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (Global.UsuarioService.getPermisos().Ventas.Otros.ComboRetornable.canView()) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.comboRetornableService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.props.comboRetornables)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.comboRetornableService.paramsURL}
        listarElementos={this.props.comboRetornablesFuncion}
        id="TablaComboRetornables"
      />
    );
  }
}
