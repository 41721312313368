import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";
import { CANALES_WEB_SOCKET,       MaquinaServicioFisicaGet,              MaquinaServicioFisicaService,                       WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  maquinasServicioFisica: Array<MaquinaServicioFisicaGet>;
}

export default class MaquinaServicioFisicaLista extends Component<
  Props,
  State
> {

  private maquinaServicioFisicaService:MaquinaServicioFisicaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      maquinasServicioFisica: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_FISICA,
      funcionCallback: this.getElementos,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_SIMBOLICA,
      funcionCallback: this.getElementos,
    });
    /* WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.PROVEEDORES,
      funcionCallback: this.cargarProveedores,
    }); */
    this.maquinaServicioFisicaService = new MaquinaServicioFisicaService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.maquinaServicioFisicaService.list()
      .then((result) => {
        this.setState({ maquinasServicioFisica: result });
      })
      .catch((error) => {
        this.maquinaServicioFisicaService.manejarErrorHTTP(
          error,
          "Máquina Servicio Física"
        );
      });
  };

  componentDidMount() {
    this.maquinaServicioFisicaService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }
  armarFilas(
    maquinasServicioFisica: Array<MaquinaServicioFisicaGet>
  ): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (maquinasServicioFisica.length > 0) {
      for (let i = 0; i < maquinasServicioFisica.length; i++) {
        let fila: Array<string> = [];
        fila.push(maquinasServicioFisica[i].numero_serie);
        fila.push(maquinasServicioFisica[i].marca);
        fila.push(maquinasServicioFisica[i].proveedor.nombre);
        fila.push(maquinasServicioFisica[i].maquina_simbolica.nombre);
        let filaResult: FilaTablaGenerica = {
          id: maquinasServicioFisica[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Elementos.Maquinas.MaquinaServicioFisica.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Elementos.Maquinas.MaquinaServicioFisica.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.maquinaServicioFisicaService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.maquinasServicioFisica)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.maquinaServicioFisicaService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaMaquinasServicioFisica"
      />
    );
  }
}
