import React, { Component } from "react";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import RepartoLoad from "../../load/RepartoLoad";

import {
  CANALES_WEB_SOCKET,
  DatosReparto,
  TIPOS_REPARTO,
  RepartoService,
  WebSocketService,
} from "serviciossaintmichel";
import { Global } from "../../../../../Global";

export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  datosReparto: DatosReparto;
}
interface State {
  datosRepartoNew: DatosReparto;
  datosRepartoOld: DatosReparto;
  screen: ScreenType;
  valid: boolean;
}

export default class DatosRepartoUpdate extends Component<Props, State> {
  private repartoService: RepartoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      datosRepartoOld: this.props.datosReparto,
      datosRepartoNew: this.getCopy(this.props.datosReparto),
      screen: Screen.getType(window.innerWidth, window.innerHeight),
      valid: false,
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.repartoService = new RepartoService(Global.UsuarioService.getToken()!);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  getCopy = (datos: DatosReparto): DatosReparto => {
    return { id: datos.id, nombre: datos.nombre, tipo: datos.tipo };
  };

  async componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS,
      funcionCallback: this.onChannelMesagge,
    });
  }

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.repartoId === this.props.datosReparto.id) {
      let reparto = await this.repartoService.get(this.props.datosReparto.id).catch((error) => {
        this.repartoService.manejarErrorHTTP(error, "Reparto");
        return null;
      });
      let datos: DatosReparto = {
        id: reparto ? reparto.id : -1,
        nombre: reparto ? reparto.nombre : "",
        tipo: reparto ? reparto.tipo : TIPOS_REPARTO.DOMICILIO,
      };
      this.setState({
        datosRepartoOld: datos,
        datosRepartoNew: this.getCopy(datos),
      });
    }
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({
        datosRepartoOld: this.props.datosReparto,
        datosRepartoNew: this.getCopy(this.props.datosReparto),
      });
    }
  };

  onUpdate = async () => {
    let datosRepartoNew = {
      nombre: this.state.datosRepartoNew.nombre,
    };
    return this.repartoService
      .update(this.state.datosRepartoNew, this.state.datosRepartoNew.id)
      .then((reparto) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS, {
          type: "UPDATE",
          repartoId: reparto.id,
        });
        this.setState({
          datosRepartoOld: this.state.datosRepartoNew,
          datosRepartoNew: this.getCopy(this.props.datosReparto),
        });
        Swal.fire({
          icon: "success",
          text: "Datos Reparto Modificados",
        });
      })
      .catch((error) => {
        this.repartoService.manejarErrorHTTP(error, "Reparto");
      });
  };

  isValid = (nombre: string): boolean => {
    return nombre.length > 0 ? true : false;
  };

  onChange = (datosReparto: DatosReparto) => {
    this.setState({ datosRepartoNew: datosReparto });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex flexDirection="column" width="60%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex flexDirection="column" width="50%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <H size={3} texto={"Datos"} />
        </Flex>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="10px">
          <RepartoLoad
            reparto={this.state.datosRepartoNew}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
            camposDeshabilitados={["tipo"]}
          />
        </Flex>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
          <RequestButton propsBoton={{ disabled: !this.state.valid }} texto={"Actualizar"} onClick={this.onUpdate} />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.screen === ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen === ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen === ScreenType.TABLET ? this.renderizarTablet() : null}
      </Flex>
    );
  }
}
