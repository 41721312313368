import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import SelectInsumo from "../../Insumo/components/SelectInsumo";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import InputDecimal from "../../../../compartido/components/input/InputDecimal";
import { BultoInsumoGet, BultoInsumoPost, BultoInsumoService, CANALES_WEB_SOCKET, InsumoGet, InsumoService, TipoInsumoGet, UnidadInsumoGet, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";





export interface Props {
  bultoInsumoId: number;
  ocultar: Function;
}

interface State {
  bultoInsumo: BultoInsumoGet;
  insumos: Array<InsumoGet>;
  alerta: any;
}

export default class InsumoActualizar extends Component<Props, State> {


  private bultoInsumoService:BultoInsumoService;
  private insumoService:InsumoService;


  constructor(props: Props) {
    super(props);
    this.state = {
      bultoInsumo: this.crearBultoInsumoVacio(),
      insumos: [],
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_INSUMOS,
      funcionCallback: this.cargarInsumos,
    });


    this.bultoInsumoService =  new BultoInsumoService(Global.UsuarioService.getToken()!);
    this.insumoService =  new InsumoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.cargarBultoInsumo(this.props.bultoInsumoId);
    this.cargarInsumos();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarBultoInsumo(this.props.bultoInsumoId);
      this.cargarInsumos();
    }
  }

  crearBultoInsumoVacio(): BultoInsumoGet {
    const tipo_insumo: TipoInsumoGet = {
      id: 0,
      tipo: "",
    };
    const unidad_insumo: UnidadInsumoGet = {
      id: 0,
      unidad: "",
    };
    const insumo: InsumoGet = {
      id: 0,
      nombre: "",
      tipo_insumo: tipo_insumo,
      unidad_insumo: unidad_insumo,
    };
    const bultoInsumo: BultoInsumoGet = {
      id: 0,
      nombre: "",
      cantidad: 0,
      insumo: insumo,
      proveedores: [],
    };
    return bultoInsumo;
  }

  cargarBultoInsumo = async (id: number) => {
    const resBultoInsumo = await this.bultoInsumoService.get(id).catch((error) => {
      this.bultoInsumoService.manejarErrorHTTP(error, "Bulto Insumo");
      return null;
    });
    if (resBultoInsumo != null) {
      this.setState({
        bultoInsumo: resBultoInsumo,
        alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
      });
    }
  };

  cargarInsumos = async () => {
    const resInsumos = await this.insumoService.listAll().catch((error) => {
      this.insumoService.manejarErrorHTTP(error, "Insumo");
      return null;
    });
    if (resInsumos != null) {
      this.setState({
        insumos: resInsumos,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  setearNombre = (nombre: string) => {
    let bultoInsumo = this.state.bultoInsumo;
    bultoInsumo.nombre = nombre;
    this.setState({ bultoInsumo: bultoInsumo });
  };

  setearInsumo = (insumo: InsumoGet) => {
    let bultoInsumo = this.state.bultoInsumo;
    bultoInsumo.insumo = insumo;
    this.setState({ bultoInsumo: bultoInsumo });
  };
  setearCantidad = (cantidad: number) => {
    let bultoInsumo = this.state.bultoInsumo;
    bultoInsumo.cantidad = cantidad;
    this.setState({ bultoInsumo: bultoInsumo });
  };
  handleUpdate() {
    const bultoInsumoActualizar: BultoInsumoPost = {
      nombre: this.state.bultoInsumo.nombre,
      insumo: this.state.bultoInsumo.insumo.id,
      cantidad: this.state.bultoInsumo.cantidad,
    };
    this.bultoInsumoService.update(bultoInsumoActualizar, this.state.bultoInsumo.id)
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_BULTO_INSUMOS,
          {
            bultoInsumoId: this.state.bultoInsumo.id,
          }
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Bulto Insumo Actualizado!",
            titulo: "Atención",
            estado: "block",
          },
        });
      })
      .catch((error) => {
        this.bultoInsumoService.manejarErrorHTTP(error, "Bulto Insumo");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.bultoInsumo.nombre && this.state.bultoInsumo.insumo
      ? false
      : true &&
        this.state.bultoInsumo.cantidad &&
        this.state.bultoInsumo.cantidad != 0
      ? false
      : true;
  };

  render() {
    if (this.state.bultoInsumo.id != 0 && this.state.insumos.length > 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            <div className={"row"}>
              <div className={"column"}>
                <form
                  className={"form-horizontal"}
                  onSubmit={this.handleSubmit}
                >
                  <div className={"form-group"}>
                    <label htmlFor="nombre">Nombre</label>
                    <InputCaracteres
                      id="nombre"
                      name="nombre"
                      onChange={this.setearNombre}
                      value={this.state.bultoInsumo.nombre}
                      placeholder="Ingrese Nombre de Bulto Insumo"
                    />
                  </div>

                  <div className={"form-group"}>
                    <SelectInsumo
                      insumos={this.state.insumos}
                      seleccionado={this.state.bultoInsumo.insumo}
                      callbackParent={this.setearInsumo}
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="cantidad">Cantidad</label>{" "}
                    <InputDecimal
                      id="cantidad"
                      name="cantidad"
                      onChange={this.setearCantidad}
                      value={this.state.bultoInsumo.cantidad}
                      cantEnteros={3}
                      cantDecimales={3}
                      placeholder={"Ingrese cantidad"}
                    />
                  </div>
                  <div
                    className={"form-group"}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button
                      ref="boton"
                      type="submit"
                      className={"btn btn-info"}
                      disabled={this.disableButton()}
                    >
                      {"Actualizar"}
                    </button>
                    <button
                      className={"btn btn-outline-secondary"}
                      onClick={this.ocultar}
                    >
                      Ocultar
                    </button>
                  </div>
                  <div className={"form-group"}>
                    <Alerta
                      tipo={this.state.alerta.tipo}
                      titulo={this.state.alerta.titulo}
                      mensaje={this.state.alerta.mensaje}
                      estado={this.state.alerta.estado}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
