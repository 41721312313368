import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Margin from "../../../../libreria/appearance/margin/Margin";
import { Tab, Tabs } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../compartido/models/Screen";
import H from "../../../../libreria/message/title/H";
import DatosRepartoUpdate from "./datos/DatosRepartoUpdate";
import { Box } from "../../../../libreria/containers/box/Box";
import Flex from "../../../../libreria/appearance/flex/Flex";
import RecorridosRepartoSeccion from "./recorridos/RecorridosRepartoSeccion";


import { CANALES_WEB_SOCKET,                Reparto,    RepartoService,    WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";





export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  idReparto: number;
  recienCreado: boolean;
}

interface State {
  reparto: Reparto | null;
  cargando: boolean;
  screen: ScreenType;
}

export default class RepartoUpdate extends Component<Props, State> {

  private repartoService:RepartoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      reparto: null,
      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.repartoService =  new RepartoService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  async componentDidMount() {
    await this.cargarReparto();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS,
      funcionCallback: this.onChannelMesagge,
    });
  }

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.repartoId == this.props.idReparto) {
      this.cargarReparto();
    }
  };

  cargarReparto = async () => {
    let reparto = await this.repartoService.get(this.props.idReparto).catch(
      (error) => {
        this.repartoService.manejarErrorHTTP(error, "Reparto");
        return null;
      }
    );
    if (reparto) {
      this.setState({ reparto: reparto });
    } else this.setState({ reparto: reparto, cargando: false });
  };

  renderizar = () =>
    this.state.reparto != null ? (
      <Margin top="20px">
        <Box padding="15px">
          <H alineacion={"center"} size={3} texto={this.state.reparto.nombre} />
          <Margin top="20px" />
          <Tabs
            defaultActiveKey={this.props.recienCreado ? "recorridos" : "datos"}
            transition={false}
            variant={this.state.screen == ScreenType.PHONE ? "pills" : "tabs"}
            style={{
              marginBottom: "1rem",
              flexDirection:
                this.state.screen == ScreenType.PHONE ? "column" : "row",
              alignItems: this.state.screen == ScreenType.PHONE ? "center" : "",
            }}
            unmountOnExit={true}
          >
            <Tab eventKey="datos" title="Datos">
              {<DatosRepartoUpdate datosReparto={this.state.reparto} />}
            </Tab>
            <Tab eventKey="recorridos" title="Recorridos">
              {<RecorridosRepartoSeccion idReparto={this.state.reparto.id} />}
            </Tab>
          </Tabs>
          <Margin top="20px" />
        </Box>
      </Margin>
    ) : null;

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <div style={{ display: "block", width: "100%" }}>
          {this.renderizar()}
        </div>
      </Flex>
    );
  }
}
