import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../../compartido/models/Screen";
import Flex from "../../../../../../../libreria/appearance/flex/Flex";
import RequestButton from "../../../../../../../compartido/components/request-button/RequestButton";
import * as Icon from "react-bootstrap-icons";
import TelefonoClienteUpdate from "./TelefonoClienteUpdate";

import { CANALES_WEB_SOCKET, ClienteService,  TelefonoLectura, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../../Global";



export interface Props {
  clienteId: number;
}

interface State {
  cargando: boolean;
  screen: ScreenType;
  telefonos: Array<TelefonoLectura>;
  telefonoId: number | null;
}

export default class TelefonosClienteUpdate extends Component<Props, State> {

  private clienteService:ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      telefonoId: null,
      telefonos: [],
      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = () => {
    window.addEventListener("resize", this.actualizarDimensiones);

    this.getTelefonos();

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_TELEFONOS,
      funcionCallback: this.onChannelMessage,
    });
  };

  onChannelMessage = async (e: MessageEvent) => {
    let message = JSON.parse(e.data).message;
    const { clienteId } = this.props;

    if (message.clienteId === clienteId) {
      this.getTelefonos();
    }
  };

  getTelefonos = async () => {
    const telefonos = await this.clienteService.getTelefonos(this.props.clienteId);
    this.setState({ telefonos: telefonos });
  };

  actualizarDimensiones = () => {
    this.setState({ screen: Screen.getType(window.innerWidth, window.innerHeight) });
  };

  onUpdateRequest = (telefonoId: number) => {
    this.setState({ telefonoId: telefonoId });
  };

  eliminarTelefono = async (telefonoId: number) => {
    let indice = this.state.telefonos.findIndex((t) => t.id === telefonoId);
    const telefonoObjetivo = { ...this.state.telefonos[indice] };
    try {
      await this.clienteService.eliminarTelefono(this.props.clienteId, telefonoObjetivo.id);
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_TELEFONOS, {
        id: telefonoObjetivo.id,
        clienteId: telefonoObjetivo.cliente,
      });
      if (telefonoId === this.state.telefonoId) {
        this.setState({ telefonoId: null });
      }
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error, "Cliente");
    }
  };

  renderizarFilas = () => {
    return this.state.telefonos.map((telefono, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{`(${telefono.codigo_area}) ${telefono.numero}`}</td>
          <td className="text-center">{telefono.tipo_telefono.tipo}</td>
          <td className="text-center">{telefono.descripcion}</td>
          <td className="text-center">
            <Button variant={"light"} onClick={() => this.onUpdateRequest(telefono.id)}>
              <Icon.ArrowRepeat />
            </Button>
          </td>
          <td className="text-center">
            <RequestButton
              confirmacion={true}
              texto=""
              propsBoton={{ variant: "light" }}
              icon={<Icon.Trash />}
              onClick={() => this.eliminarTelefono(telefono.id)}
            />
          </td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Número de Teléfono</th>
            <th className="text-center">Tipo</th>
            <th className="text-center">Descripción</th>
            <th className="text-center">Actualizar</th>
            <th className="text-center">Eliminar</th>
          </tr>
        </thead>

        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  render() {
    const { telefonos, telefonoId } = this.state;
    const { clienteId } = this.props;

    if (telefonos.length === 0) {
      return <div>El cliente no posee teléfonos asociados</div>;
    }

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="2rem">
          {this.renderizarTabla()}
        </Flex>
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="2rem"
        >
          {telefonoId && (
            <TelefonoClienteUpdate
              clienteId={clienteId}
              telefonoId={telefonoId}
              onCloseCallback={() => this.setState({ telefonoId: null })}
            />
          )}
        </Flex>
      </Flex>
    );
  }
}
