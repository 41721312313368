import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { addMonths, endOfMonth, startOfMonth } from "date-fns/esm";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import FechaUtils from "../../../../../../../compartido/utils/FechaUtils";
import Input, {
  InputTypes,
} from "../../../../../../../libreria/data/input/Input";
import SelectAlquiler from "../../../../../../seccion-servicios/alquileres/components/SelectAlquiler";
import { AlquilerCliente, AlquilerGet } from "serviciossaintmichel";

export interface Props {
  alquilerCliente: AlquilerCliente | null;
  alquiler: AlquilerGet | null;
  clienteId: number;
  onChange: Function;
  onValidationChange: Function;
  updating?: boolean;
  fechaInicioMin?: Date;
  fechaFinMax?: Date;
  fechaFinMin?: Date;
}

interface State {
  alquilerCliente: AlquilerCliente;
  alquilerClienteValido: boolean;
  alquilerSeleccionado: AlquilerGet | null;
  alquilerValido: boolean;
  precioEspecialValido: boolean;
}

export default class AlquilerClienteLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const initialValidation = !!props.alquilerCliente;

    this.state = {
      alquilerSeleccionado: props.alquiler,
      alquilerCliente: props.alquilerCliente || this.crearAlquilerCliente(),
      alquilerClienteValido: initialValidation,
      alquilerValido: initialValidation,
      precioEspecialValido: initialValidation,
    };
  }

  crearAlquilerCliente = (): AlquilerCliente => {
    const { fechaInicioMin } = this.props;
    let fechaInicio = fechaInicioMin;

    if (!fechaInicioMin) {
      const mesProximo = addMonths(new Date(), 1);
      fechaInicio = startOfMonth(mesProximo);
    } else {
      fechaInicio = fechaInicioMin;
    }

    return {
      id: -1,
      alquiler: -1,
      cliente: this.props.clienteId,
      precio_especial: null,
      fecha_inicio: FechaUtils.normalizarFecha(fechaInicio),
      fecha_fin: null,
      tiempo: "",
    };
  };

  componentDidMount = () => {
    this.validar();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { alquilerCliente, alquilerClienteValido } = this.state;
    const { fechaInicioMin } = this.props;

    if (prevState.alquilerCliente !== alquilerCliente) {
      this.props.onChange(alquilerCliente);
      this.validar();
    }
    if (prevState.alquilerClienteValido !== alquilerClienteValido) {
      this.props.onValidationChange(alquilerClienteValido);
    }
    if (prevProps.fechaInicioMin !== fechaInicioMin && fechaInicioMin) {
      this.setFechaInicio(fechaInicioMin);
    }
    if (
      this.props.alquilerCliente &&
      JSON.stringify(prevProps.alquilerCliente) !==
        JSON.stringify(this.props.alquilerCliente)
    ) {
      this.setState({ alquilerCliente: this.props.alquilerCliente });
    }
  };

  validar = () => {
    const { precioEspecialValido, alquilerValido } = this.state;
    const valido = precioEspecialValido && alquilerValido;
    this.setState({ alquilerClienteValido: valido });
  };

  setAlquiler = (alquiler: AlquilerGet) => {
    const alquilerValido = !!alquiler;
    const alquilerCliente: AlquilerCliente = {
      ...this.state.alquilerCliente,
      alquiler: alquiler ? alquiler.id : -1,
    };
    this.setState({
      alquilerSeleccionado: alquiler,
      alquilerValido: alquilerValido,
      alquilerCliente: alquilerCliente,
    });
  };

  setFechaInicio = (fecha: Date) => {
    const alquilerCliente: AlquilerCliente = {
      ...this.state.alquilerCliente,
      fecha_inicio: FechaUtils.normalizarFecha(fecha),
    };
    this.setState({ alquilerCliente: alquilerCliente });
  };

  setFechaFin = (fecha: Date) => {
    const alquilerCliente: AlquilerCliente = {
      ...this.state.alquilerCliente,
      fecha_fin: FechaUtils.normalizarFecha(fecha),
    };
    this.setState({ alquilerCliente: alquilerCliente });
  };

  setPrecioEspecial = (precio: number) => {
    const alquilerCliente: AlquilerCliente = {
      ...this.state.alquilerCliente,
      precio_especial: precio ? precio : null,
    };
    this.setState({ alquilerCliente: alquilerCliente });
  };

  getFechasInicioPermitidas = (fechaInicial: Date, años: number) => {
    let fechasPermitidas = [];
    let fechaObjetivo = fechaInicial;

    for (let i = 1; i <= 12 * años; i++) {
      fechaObjetivo = addMonths(fechaObjetivo, 1);
      fechasPermitidas.push(startOfMonth(fechaObjetivo));
    }
    return fechasPermitidas;
  };

  getFechasFinPermitidas = (fechaInicial: Date, años: number) => {
    let fechasPermitidas = [];
    let fechaObjetivo = fechaInicial;

    for (let i = 1; i <= 12 * años; i++) {
      fechasPermitidas.push(endOfMonth(fechaObjetivo));
      fechaObjetivo = addMonths(fechaObjetivo, 1);
    }
    return fechasPermitidas;
  };

  render = () => {
    const today = new Date();
    const { alquilerSeleccionado, alquilerCliente } = this.state;
    const {
      updating = false,
      fechaInicioMin = today,
      fechaFinMax,
    } = this.props;
    const fechaInicio = FechaUtils.normalizarFecha(
      alquilerCliente.fecha_inicio
    );
    let fechaFin = alquilerCliente.fecha_fin;
    if (fechaFin) {
      fechaFin = FechaUtils.normalizarFecha(fechaFin);
    }
    const fechasInicioPermitidas = this.getFechasInicioPermitidas(today, 2);
    const fechasFinPermitidas = this.getFechasFinPermitidas(fechaInicioMin, 2);

    return (
      <>
        <Flex container flexDirection="column">
          <span>Nombre del alquiler</span>
          {updating ? (
            <div>{alquilerSeleccionado?.nombre}</div>
          ) : (
            <SelectAlquiler
              callbackParent={this.setAlquiler}
              seleccionado={alquilerSeleccionado}
            />
          )}
        </Flex>
        <Flex container flexDirection="column">
          <span>Precio regular del alquiler</span>
          <div>{alquilerSeleccionado ? alquilerSeleccionado.precio : "-"}</div>
        </Flex>
        <Flex container>
          <Input
            data={alquilerCliente.precio_especial}
            label={"Precio especial"}
            placeholder={"Precio especial"}
            onChange={this.setPrecioEspecial}
            onValidationChange={(valido: boolean) => {
              this.setState({ precioEspecialValido: valido });
            }}
            type={InputTypes.Number}
            decimal={2}
            integers={6}
          />
        </Flex>
        <Flex container flexDirection="column" marginBottom="1rem">
          {updating ? (
            <>
              <span>Fecha de inicio</span>
              <div>{fechaInicio.toLocaleDateString("es-AR")}</div>
            </>
          ) : (
            <>
              <span>Fecha de inicio</span>
              <DatePicker
                selected={fechaInicio}
                onChange={this.setFechaInicio}
                dateFormat="dd/MM/yyyy"
                minDate={fechaInicioMin}
                includeDates={fechasInicioPermitidas}
              />
            </>
          )}
        </Flex>
        <Flex container flexDirection="column">
          <span>Fecha de finalización</span>
          <DatePicker
            selected={fechaFin}
            onChange={this.setFechaFin}
            dateFormat="dd/MM/yyyy"
            includeDates={fechasFinPermitidas}
            maxDate={fechaFinMax}
            isClearable={!fechaFinMax}
          />
        </Flex>
      </>
    );
  };
}
