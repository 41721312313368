import React, { Component } from "react";
import Flex from "../flex/Flex";
import DatoGeograficoShow from "./DatoGeograficoShow";
import LocalizacionShow from "./LocalizacionShow";
import ZonasShow, { Version as ZonasShowVersion } from "./ZonasShow";
import Visibilizador from "../../../libreria/action/visibilizador/Visibilizador";
import MaquinasServicioFisicaShow, {
  Version,
} from "../../../elementos/maquinas/MaquinaServicioFisica/components/MaquinasServicioFisicaShow";

import { DireccionDTO, DireccionService, TipoLugar, TipoLugarService } from "serviciossaintmichel";
import { Global } from "../../../Global";

interface Props {
  direccionId: number;
}

interface State {
  direccion: DireccionDTO | null;
  tipoLugar: TipoLugar | null;
}

export default class DireccionShow extends Component<Props, State> {
  private direccionService: DireccionService;
  private tipoLugarService: TipoLugarService;

  constructor(props: Props) {
    super(props);

    this.state = {
      direccion: null,
      tipoLugar: null,
    };
    this.direccionService = new DireccionService(Global.UsuarioService.getToken()!);
    this.tipoLugarService = new TipoLugarService(Global.UsuarioService.getToken()!);
  }

  componentDidMount() {
    this.setearDireccionYTipoLugar(this.props.direccionId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.direccionId !== this.props.direccionId) {
      this.setearDireccionYTipoLugar(this.props.direccionId);
    }
  }

  setearDireccionYTipoLugar = async (direccionId: number) => {
    try {
      const direccion = await this.direccionService.get(direccionId);
      const tipoLugar = await this.tipoLugarService.get(direccion.tipo_lugar);
      this.setState({ direccion: direccion, tipoLugar: tipoLugar });
    } catch (error) {
      this.direccionService.manejarErrorHTTP(error, "Direccion");
    }
  };

  render() {
    const { direccion, tipoLugar } = this.state;

    if (direccion === null || tipoLugar === null) {
      return <div> Cargando... </div>;
    }

    return (
      <Flex container flexDirection="column">
        <Flex container flexDirection="row" alignItems="center" justifyContent="center">
          <div>
            <h3>Dirección</h3>
          </div>
        </Flex>
        <Flex marginTop="1rem" container flexDirection="column" alignItems="center" justifyContent="center">
          <div>Calle: {direccion.calle}</div>
          <div>
            Entre: {direccion.entre_1} y {direccion.entre_2}
          </div>
          <div>Número: {direccion.numero}</div>
          <div>Departamento: {direccion.departamento ?? "-"}</div>
          <div>Piso: {direccion.piso ?? "-"}</div>
          <div>Tipo de lugar: {tipoLugar.tipo}</div>
          <div>Descripción: {direccion.descripcion ?? "-"}</div>
        </Flex>

        <Flex marginTop="1rem" container flexDirection="column" alignItems="center" justifyContent="center">
          <Visibilizador label="Datos geográficos">
            <DatoGeograficoShow localidadId={direccion.localidad} />
          </Visibilizador>
        </Flex>

        {direccion.localizacion && (
          <Flex marginTop="1rem" container flexDirection="column" alignItems="center" justifyContent="center">
            <Visibilizador label="Localización">
              <LocalizacionShow localizacion={direccion.localizacion} />
            </Visibilizador>
          </Flex>
        )}

        {direccion.zonas && (
          <Flex marginTop="1rem" container flexDirection="column" alignItems="center" justifyContent="center">
            <Visibilizador label="Zonas">
              <ZonasShow zonasId={direccion.zonas} version={ZonasShowVersion.LIST} />
            </Visibilizador>
          </Flex>
        )}

        {direccion.zonas && (
          <Flex marginTop="1rem" container flexDirection="column" alignItems="center" justifyContent="center">
            <Visibilizador label="Máquinas">
              <MaquinasServicioFisicaShow direccionId={direccion.id} version={Version.LIST} />
            </Visibilizador>
          </Flex>
        )}
      </Flex>
    );
  }
}
