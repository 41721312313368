import React, { Component } from "react";
import ProgramacionEntregaShow from "./show/ProgramacionEntregaShow";
import ProgramacionesEntregaShow, {
  Version,
} from "./show/ProgramacionesEntregaShow";
import { Tabs, Tab } from "react-bootstrap";

import Flex from "../../../../libreria/appearance/flex/Flex";
import { CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";
interface Props {}

interface State {
  tabVisible: any;
  disabledActualizar: boolean;
  disabledVer: boolean;
  responsive: boolean;
  idProgramacionEntrega: number | null;
  recienCreado: boolean;
}

export default class ProgramacionesEntrega extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista",
      disabledActualizar: true,
      disabledVer: true,
      responsive: window.innerWidth < 700 ? true : false,
      idProgramacionEntrega: null,
      recienCreado: false,
    };

    window.addEventListener("resize", this.actualizarDimensiones);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PROGRAMACIONES_ENTREGA,
      funcionCallback: this.cargarProgramacionEntrega,
    });
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  cargarProgramacionEntrega = async (e: MessageEvent) => {};

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  actualizar = (idProgramacionEntrega: number) => {
    this.setState({
      tabVisible: "actualizar",
      disabledActualizar: false,
      idProgramacionEntrega: idProgramacionEntrega,
    });
  };

  ver = (idProgramacionEntrega: number) => {
    this.setState({
      tabVisible: "ver",
      disabledVer: false,
      idProgramacionEntrega: idProgramacionEntrega,
      recienCreado: false,
    });
  };

  ocultarVer = () => {
    this.setState({
      tabVisible: "lista",
      disabledVer: true,
      idProgramacionEntrega: null,
    });
  };

  ocultarNuevo = () => {
    this.setState({
      tabVisible: "lista",
    });
  };

  renderizarNuevo = () => {
    if (
      Global.UsuarioService.getPermisos().Ventas.Pedidos.ProgramacionEntrega.canAdd()
    ) {
      return (
        <Tab eventKey="nuevo" title="Nuevo" width="100%">
          {/* {this.state.tabVisible === "nuevo" ? (
            <ProgramacionEntregaNew
              onExit={this.ocultarNuevo}
              onSaveSuccess={async (idProgramacionEntrega: number) => {
                this.setState({ recienCreado: true, idProgramacionEntrega: idProgramacionEntrega });
                this.actualizar(idProgramacionEntrega);
              }}
            />
          ) : null} */}
        </Tab>
      );
    }
  };

  renderizarLista = () => {
    if (
      Global.UsuarioService.getPermisos().Ventas.Pedidos.ProgramacionEntrega.canView()
    ) {
      return (
        <Tab eventKey="lista" title="Lista" width="100%">
          <ProgramacionesEntregaShow
            version={Version.TABLE}
            onView={this.ver}
            onUpdate={this.actualizar}
          />
        </Tab>
      );
    }
  };

  renderizarActualizar = () => {
    if (
      Global.UsuarioService.getPermisos().Ventas.Pedidos.ProgramacionEntrega.canChange()
    ) {
      return (
        <Tab
          width="100%"
          eventKey="actualizar"
          title="Actualizar"
          disabled={this.state.disabledActualizar}
        >
          {/*  {this.state.idProgramacionEntrega && this.state.tabVisible === "actualizar" ? (
            <ProgramacionEntregaUpdate
              id={this.state.idProgramacionEntrega}
            />
          ) : null} */}
        </Tab>
      );
    }
  };

  renderizarVerDetalle = () => {
    if (
      Global.UsuarioService.getPermisos().Ventas.Pedidos.ProgramacionEntrega.canView()
    ) {
      return (
        <Tab
          width="100%"
          eventKey="ver"
          title="Ver Detalle"
          disabled={this.state.disabledVer}
        >
          {this.state.idProgramacionEntrega &&
          this.state.tabVisible === "ver" ? (
            <ProgramacionEntregaShow
              idProgramacionEntrega={this.state.idProgramacionEntrega}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey="lista"
      transition={false}
      activeKey={this.state.tabVisible}
      onSelect={this.onSelect}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "flex-start" : "",
      }}
      unmountOnExit={true}
    >
      {this.renderizarNuevo()}
      {this.renderizarLista()}
      {this.renderizarActualizar()}
      {this.renderizarVerDetalle()}
    </Tabs>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <div style={{ display: "block", width: "100%" }}>
          {this.renderizar(this.state.responsive)}
        </div>
      </Flex>
    );
  }
}
