import React, { Component } from "react";
import { PropsSelectGenerico } from "../../../../compartido/components/select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../../../../compartido/components/select/SelectBusqueda";
import SelectComponent from "../../../../compartido/components/select/SelectComponent";
import Flex from "../../../../libreria/appearance/flex/Flex";

import { CANALES_WEB_SOCKET, Cliente, ClienteService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";
import { MultiSelect, MultiSelectProps } from "@mantine/core";

export interface Props {
  version: VersionClientesShow;
  propsSelect?: PropsSelectGenerico<Cliente> & PropsSelectGeneral;
  propsMultiSelect?: Omit<MultiSelectProps, "data">;
  filtros?: Array<Function>; // lista de filtros sobre los datos (solo sirve para el select)
}

export enum VersionClientesShow {
  SELECT,
  MULTI_SELECT,
}

interface State {
  clientes: Array<Cliente> | null;
}

export default class ClientesShow extends Component<Props, State> {
  private clienteService: ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      clientes: null,
    };

    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);
  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES,
      funcionCallback: this.getClientes,
    });
    this.getClientes();
  }

  getClientes = () => {
    switch (this.props.version) {
      case VersionClientesShow.SELECT: {
        this.clienteService
          .listAll()
          .then((result) => {
            this.setState({ clientes: result });
          })
          .catch((error) => {
            this.clienteService.manejarErrorHTTP(error, "Cliente");
          });
        break;
      }
      case VersionClientesShow.MULTI_SELECT: {
        this.clienteService
          .listAll()
          .then((result) => {
            this.setState({ clientes: result });
          })
          .catch((error) => {
            this.clienteService.manejarErrorHTTP(error, "Cliente");
          });
        break;
      }
      default:
        break;
    }
  };

  render() {
    const { clientes } = this.state;
    const { propsSelect, propsMultiSelect, version, filtros } = this.props;

    if (clientes === null) {
      return null;
    }

    // si hay filtros, chequea aquellos combos que cumplen con todos los filtros
    const items = filtros ? clientes.filter((cli) => filtros.every((fnc) => fnc(cli))) : clientes;

    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {version === VersionClientesShow.SELECT && propsSelect ? (
          <SelectComponent
            {...propsSelect}
            items={items}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado ? propsSelect.seleccionado : null}
            labelGetter={(obj: Cliente) => obj.razon_social}
          />
        ) : null}
        {version === VersionClientesShow.MULTI_SELECT && propsMultiSelect ? (
          <MultiSelect
            {...propsMultiSelect}
            data={clientes.map((cliente) => ({ value: cliente.id.toString(), label: cliente.razon_social }))}
          />
        ) : null}
      </Flex>
    );
  }
}
