import React, { Component, FormEvent } from "react";
import InputDecimal from "../../../../compartido/components/input/InputDecimal";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import SelectTipoProducto from "../../TipoProducto/components/SelectTipoProducto";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";


import { CANALES_WEB_SOCKET,  ProductoPost, ProductoService, TipoProductoGet, TipoProductoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  ocultar: Function;
  callbackParent?: Function;
}

interface State {
  producto: ProductoPost;
  tiposProducto: Array<TipoProductoGet>;
}

export default class ProductoCrear extends Component<Props, State> {

  private productoService:ProductoService;
  private tipoProductoService:TipoProductoService;

  private socket: WebSocket;
  constructor(props: Props) {
    super(props);
    this.state = {
      producto: this.productoCrear(),
      tiposProducto: [],
    };
    this.socket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_PRODUCTOS,
      funcionCallback: this.cargarTiposProducto,
    });

    this.productoService =  new ProductoService(Global.UsuarioService.getToken()!);
    this.tipoProductoService =  new TipoProductoService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    await this.cargarTiposProducto();
    const productoCrear: ProductoPost = this.state.producto;
    if (this.state.tiposProducto.length > 0) {
      productoCrear.tipo_producto = this.state.tiposProducto[0].id;
    } else {
      productoCrear.tipo_producto = 0;
    }
    this.setState({
      producto: productoCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  cargarTiposProducto = async () => {
    const resTiposProducto = await this.tipoProductoService.listAll().catch(
      (error) => {
        this.tipoProductoService.manejarErrorHTTP(error, "Tipo Producto");
        return null;
      }
    );
    if (resTiposProducto != null) {
      this.setState({
        tiposProducto: resTiposProducto,
      });
    }
  };

  productoCrear(): ProductoPost {
    return {
      nombre: "",
      tipo_producto: 0,
      volumen: 0,
    };
  }
  setearNombre = (nombre: string) => {
    let prod = this.state.producto;
    prod.nombre = nombre;
    this.setState({ producto: prod });
  };
  setearTipoProducto = (tipoProducto: TipoProductoGet) => {
    let prod = this.state.producto;
    prod.tipo_producto = tipoProducto.id;
    this.setState({ producto: prod });
  };
  setearVolumen = (vol: number) => {
    let prod = this.state.producto;
    prod.volumen = vol;
    this.setState({ producto: prod });
  };

  handleCreate() {
    return this.productoService.create(this.state.producto)
      .then((producto) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS);
        if (this.props.callbackParent) {
          this.props.callbackParent(producto.id);
        } else {
          const productoCrear: ProductoPost = this.productoCrear();
          productoCrear.tipo_producto = this.state.producto.tipo_producto;
          this.setState({
            producto: productoCrear,
          });
        }
      })
      .catch((error) => {
        this.productoService.manejarErrorHTTP(error, "Producto");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.producto.nombre &&
      this.state.producto.tipo_producto &&
      this.state.producto.tipo_producto != 0 &&
      this.state.producto.volumen &&
      this.state.producto.volumen != 0
      ? false
      : true;
  };

  renderizarFormulario() {
    if (this.state.tiposProducto.length == 0) {
      return (
        <div>
          {"Aún no se poseen tipos de producto, vaya y cree uno."}
          <button
            type={"reset"}
            className={"btn btn-outline-secondary"}
            onClick={this.ocultar}
          >
            {"Ocultar"}
          </button>
        </div>
      );
    }
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="nombre">Nombre</label>
                <InputCaracteres
                  id="nombre"
                  name="nombre"
                  onChange={this.setearNombre}
                  value={this.state.producto.nombre}
                  placeholder="Ingrese Nombre de Producto"
                />
              </div>

              <div className={"form-group"}>
                <label htmlFor="tipo-producto">Tipo Producto</label>
                <SelectTipoProducto
                  tiposProductos={this.state.tiposProducto}
                  seleccionado={this.state.tiposProducto[0]}
                  callbackParent={this.setearTipoProducto}
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="volumen">Volumen</label>
                <InputDecimal
                  id="volumen"
                  name="volumen"
                  onChange={this.setearVolumen}
                  value={this.state.producto.volumen}
                  cantEnteros={3}
                  cantDecimales={3}
                  placeholder={"Ingrese Volumen"}
                />
              </div>
              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <RequestButton
                  texto="Crear"
                  msgOnSuccess="Producto creado con exito"
                  msgOnFailure="Ocurrio un error al crear el producto"
                  propsBoton={{
                    variant: "info",
                    disabled: this.disableButton(),
                  }}
                  onClick={() => this.handleCreate()}
                />
                <button
                  type={"reset"}
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
