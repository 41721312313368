import React, { Component, FormEvent } from "react";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import { CANALES_WEB_SOCKET, TipoMaterialGet, TipoMaterialPost, TipoMaterialService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  tipoMaterialId: number;
  ocultar: Function;
}

interface State {
  tipoMaterial: TipoMaterialGet;
  alerta: any;
}

export default class TipoMaterialActualizar extends Component<Props, State> {

  private tipoMaterialService:TipoMaterialService;

  constructor(props: Props) {
    super(props);
    this.state = {
      tipoMaterial: this.crearTipoMaterialVacio(),
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };

    this.tipoMaterialService = new TipoMaterialService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.cargarTipoMaterial(this.props.tipoMaterialId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarTipoMaterial(this.props.tipoMaterialId);
    }
  }

  crearTipoMaterialVacio(): TipoMaterialGet {
    const tipoMaterial: TipoMaterialGet = {
      id: 0,
      tipo: "",
    };
    return tipoMaterial;
  }

  cargarTipoMaterial = async (id: number) => {
    const resTipoMaterial = await this.tipoMaterialService.get(id).catch((error) => {
      this.tipoMaterialService.manejarErrorHTTP(error, "Tipo Material");
      return null;
    });
    if (resTipoMaterial != null) {
      this.setState({
        tipoMaterial: resTipoMaterial,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  setearTipo = (tipo: string) => {
    let tipoMaterial = this.state.tipoMaterial;
    tipoMaterial.tipo = tipo;
    this.setState({ tipoMaterial: tipoMaterial });
  };

  handleUpdate() {
    const tipoMaterialActualizar: TipoMaterialPost = {
      tipo: this.state.tipoMaterial.tipo,
    };
    this.tipoMaterialService.update(
      tipoMaterialActualizar,
      this.state.tipoMaterial.id
    )
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_TIPO_MATERIALES,
          { tipoMaterialId: this.state.tipoMaterial.id }
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Tipo Material Actualizado!",
            titulo: "Atención",
            estado: "block",
          },
        });
      })
      .catch((error) => {
        this.tipoMaterialService.manejarErrorHTTP(error, "Tipo Material");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.tipoMaterial.tipo ? false : true;
  };

  render() {
    if (this.state.tipoMaterial.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            <div className={"row"}>
              <div className={"column"}>
                <form
                  className={"form-horizontal"}
                  onSubmit={this.handleSubmit}
                >
                  <div className={"form-group"}>
                    <label htmlFor="tipo">Nombre de Tipo Material</label>
                    <InputCaracteres
                      id="tipo"
                      name="tipo"
                      onChange={this.setearTipo}
                      value={this.state.tipoMaterial.tipo}
                      placeholder="Ingrese Nombre de TipoMaterial"
                    />
                  </div>

                  <div
                    className={"form-group"}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button
                      ref="boton"
                      type="submit"
                      className={"btn btn-info"}
                      disabled={this.disableButton()}
                    >
                      {"Actualizar"}
                    </button>
                    <button
                      className={"btn btn-outline-secondary"}
                      onClick={this.ocultar}
                    >
                      Ocultar
                    </button>
                  </div>
                  <div className={"form-group"}>
                    <Alerta
                      tipo={this.state.alerta.tipo}
                      titulo={this.state.alerta.titulo}
                      mensaje={this.state.alerta.mensaje}
                      estado={this.state.alerta.estado}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
