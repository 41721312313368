import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import SelectProveedor from "../../../../produccion/proveedores/components/SelectProveedor";
import InputBase from "../../../../compartido/components/input/InputBase";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectMaquinaServicioSimbolica from "../../MaquinaServicioSimbolica/components/SelectMaquinaServicioSimbolica";

import { CANALES_WEB_SOCKET,       MaquinaServicioFisicaGet,       MaquinaServicioFisicaPost,       MaquinaServicioFisicaService,       MaquinaServicioSimbolicaGet,       MaquinaServicioSimbolicaService,       Proveedor, ProveedorService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  ocultar: Function;
}

interface State {
  maquinaServicioFisica: MaquinaServicioFisicaPost;
  proveedores: Array<Proveedor>;
  maquinasSimbolica: Array<MaquinaServicioSimbolicaGet>;
  alerta: any;
}

export default class MaquinaServicioFisicaNuevo extends Component<Props, State> {

  private proveedorService:ProveedorService;
  private maquinaServicioSimbolicaService:MaquinaServicioSimbolicaService;
  private maquinaServicioFisicaService:MaquinaServicioFisicaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      maquinaServicioFisica: this.maquinaServicioFisicaCrear(),
      proveedores: [],
      maquinasSimbolica: [],
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_SIMBOLICA,
      funcionCallback: this.cargarMaquinasSimbolica,
    });
    /* TODO Cuando este el canal de proveedores, terminar la actualizacion de este componeten, y el de actualizar */
    /* WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.PROVEEDORES,
      funcionCallback: this.cargarProveedor,
    }); */

    this.proveedorService = new ProveedorService(Global.UsuarioService.getToken()!);
    this.maquinaServicioSimbolicaService = new MaquinaServicioSimbolicaService(Global.UsuarioService.getToken()!);
    this.maquinaServicioFisicaService = new MaquinaServicioFisicaService(Global.UsuarioService.getToken()!);

  }
  async componentDidMount() {
    await this.cargarProveedores();
    await this.cargarMaquinasSimbolica();
    const maquinaServicioFisicaCrear: MaquinaServicioFisicaPost = this.state.maquinaServicioFisica;
    if (this.state.proveedores.length > 0) {
      maquinaServicioFisicaCrear.proveedor = this.state.proveedores[0].id;
    } else {
      maquinaServicioFisicaCrear.proveedor = 0;
    }
    if (this.state.maquinasSimbolica.length > 0) {
      maquinaServicioFisicaCrear.maquina_simbolica = this.state.maquinasSimbolica[0].id;
    } else {
      maquinaServicioFisicaCrear.maquina_simbolica = 0;
    }
    this.setState({
      maquinaServicioFisica: maquinaServicioFisicaCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  cargarProveedores = async () => {
    const resProveedores = await this.proveedorService.listAll().catch((error) => {
      this.proveedorService.manejarErrorHTTP(error, "Proveedor");
    });
    if (resProveedores != null) {
      this.setState({
        proveedores: resProveedores,
      });
    }
  };

  cargarMaquinasSimbolica = async () => {
    const resMaquinasServicioSimbolica = await this.maquinaServicioSimbolicaService.listAll().catch((error) => {
      this.maquinaServicioFisicaService.manejarErrorHTTP(error, "Máquina Servicio Simbólica");
      return null;
    });
    if (resMaquinasServicioSimbolica != null) {
      this.setState({
        maquinasSimbolica: resMaquinasServicioSimbolica,
      });
    }
  };

  maquinaServicioFisicaCrear(): MaquinaServicioFisicaPost {
    return {
      numero_serie: "",
      marca: "",
      proveedor: 0,
      maquina_simbolica: 0,
      fecha_fabricacion: new Date(),
      direccion: null,
    };
  }

  setearNumeroSerie = (numeroSerie: string) => {
    let maquinaServicioFisica = this.state.maquinaServicioFisica;
    maquinaServicioFisica.numero_serie = numeroSerie;
    this.setState({ maquinaServicioFisica: maquinaServicioFisica });
  };

  setearMarca = (marca: string) => {
    let maquinaServicioFisica = this.state.maquinaServicioFisica;
    maquinaServicioFisica.marca = marca;
    this.setState({ maquinaServicioFisica: maquinaServicioFisica });
  };

  setearFechaFabricacion = (fecha: Date) => {
    let maquinaServicioFisica = this.state.maquinaServicioFisica;
    maquinaServicioFisica.fecha_fabricacion = fecha;
    this.setState({ maquinaServicioFisica: maquinaServicioFisica });
  };

  setearProveedor = (proveedor: Proveedor) => {
    let maquinaServicioFisica = this.state.maquinaServicioFisica;
    maquinaServicioFisica.proveedor = proveedor.id;
    this.setState({ maquinaServicioFisica: maquinaServicioFisica });
  };

  setearMaquinaSimbolica = (maquinaSimbolica: MaquinaServicioSimbolicaGet) => {
    let maquinaServicioFisica = this.state.maquinaServicioFisica;
    maquinaServicioFisica.maquina_simbolica = maquinaSimbolica.id;
    this.setState({ maquinaServicioFisica: maquinaServicioFisica });
  };

  handleCreate() {
    this.maquinaServicioFisicaService.create(this.state.maquinaServicioFisica)
      .then((result) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_FISICA);
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Maquina Servicio Fisica Creada!",
            titulo: "Atención",
            estado: "block",
          },
        });
        const maquinaServicioFisicaCrear: MaquinaServicioFisicaPost = this.maquinaServicioFisicaCrear();
        maquinaServicioFisicaCrear.maquina_simbolica = this.state.maquinaServicioFisica.maquina_simbolica;
        maquinaServicioFisicaCrear.proveedor = this.state.maquinaServicioFisica.proveedor;
        this.setState({
          maquinaServicioFisica: maquinaServicioFisicaCrear,
        });
      })
      .catch((error) => {
        this.maquinaServicioFisicaService.manejarErrorHTTP(error, "Máquina Servicio Física");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.maquinaServicioFisica.numero_serie &&
      this.state.maquinaServicioFisica.marca &&
      this.state.maquinaServicioFisica.proveedor &&
      this.state.maquinaServicioFisica.proveedor != 0 &&
      this.state.maquinaServicioFisica.maquina_simbolica &&
      this.state.maquinaServicioFisica.maquina_simbolica != 0
      ? false
      : true;
  };

  renderizarFormulario() {
    return (
      <Flex container flexDirection="row" alignItems="center" justifyContent="center">
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="numeroSerie">Número Serie</label>
                <InputBase
                  id="numeroSerie"
                  name="numeroSerie"
                  onChange={this.setearNumeroSerie}
                  value={this.state.maquinaServicioFisica.numero_serie}
                  placeholder="Ingrese Número de Serie de la Maquina Servicio Fisica"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="marca">Marca</label>
                <InputCaracteres
                  id="marca"
                  name="marca"
                  onChange={this.setearMarca}
                  value={this.state.maquinaServicioFisica.marca}
                  placeholder="Ingrese Marca de Serie de la Maquina Servicio Fisica"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="fechaFabricacion">Fecha de Fabricación</label>
                <DatePicker
                  dateFormat="dd/MM/yyy"
                  id="fechaFabricacion"
                  selected={this.state.maquinaServicioFisica.fecha_fabricacion}
                  onChange={this.setearFechaFabricacion}
                />
              </div>
              <div className={"form-group"}>
                <SelectProveedor
                  proveedores={this.state.proveedores}
                  seleccionado={this.state.proveedores[0]}
                  callbackParent={this.setearProveedor}
                />
              </div>
              <div className={"form-group"}>
                <label>Tipo de máquina</label>
                <SelectMaquinaServicioSimbolica
                  maquinas={this.state.maquinasSimbolica}
                  idSeleccionado={this.state.maquinasSimbolica[0].id}
                  onSelect={this.setearMaquinaSimbolica}
                />
              </div>
              <div className={"form-group"} style={{ display: "flex", justifyContent: "space-between" }}>
                <button ref="boton" type="submit" className={"btn btn-info"} disabled={this.disableButton()}>
                  {"Crear"}
                </button>
                <button className={"btn btn-outline-secondary"} onClick={this.ocultar}>
                  Ocultar
                </button>
              </div>
              <div className={"form-group"}>
                <Alerta
                  tipo={this.state.alerta.tipo}
                  titulo={this.state.alerta.titulo}
                  mensaje={this.state.alerta.mensaje}
                  estado={this.state.alerta.estado}
                />
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    if (this.state.maquinasSimbolica.length > 0 && this.state.proveedores.length > 0) {
      return (
        <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            {this.renderizarFormulario()}
          </Box>
        </Flex>
      );
    } else return null;
  }
}
