import React, { ChangeEvent, Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";

import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import { Form, Image } from "react-bootstrap";
import { API_URL } from "serviciossaintmichel";
import {
  ArchivoImpresion,
  ArticuloGraficoGet,
  ArticuloGraficoPost,
  ArticuloGraficoService,
  CANALES_WEB_SOCKET,
  ImagenArticuloGraficoService,
  WebSocketService,
} from "serviciossaintmichel";
import { Global } from "../../../../Global";

export interface Props {
  articuloGraficoId: number;
  ocultar: Function;
}

interface State {
  articuloGrafico: ArticuloGraficoGet;
  imagenNueva: File | undefined;
  urlLocal: string;
  alerta: any;
}

export default class ArticuloGraficoActualizar extends Component<Props, State> {
  private articuloGraficoService: ArticuloGraficoService;
  private imagenArticuloGraficoService: ImagenArticuloGraficoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      articuloGrafico: this.crearArticuloGraficoVacio(),
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
      imagenNueva: undefined,
      urlLocal: API_URL,
    };
    this.articuloGraficoService = new ArticuloGraficoService(Global.UsuarioService.getToken()!);
    this.imagenArticuloGraficoService = new ImagenArticuloGraficoService(Global.UsuarioService.getToken()!);
  }

  componentDidMount() {
    this.cargarArticuloGrafico(this.props.articuloGraficoId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarArticuloGrafico(this.props.articuloGraficoId);
    }
  }

  crearArticuloGraficoVacio(): ArticuloGraficoGet {
    const archivo_impresion: ArchivoImpresion = {
      imagen_impresion: "",
    };
    const articuloGrafico: ArticuloGraficoGet = {
      id: 0,
      nombre: "",
      agencia_diseño: "",
      archivo_impresion: archivo_impresion,
      descripcion: "",
      proveedores: [],
    };
    return articuloGrafico;
  }

  cargarArticuloGrafico = async (id: number) => {
    const resArticuloGrafico = await this.articuloGraficoService.get(id).catch((error) => {
      this.articuloGraficoService.manejarErrorHTTP(error, "Artículo Gráfico");
      return null;
    });
    if (resArticuloGrafico != null) {
      this.setState({
        articuloGrafico: resArticuloGrafico,
        alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
        imagenNueva: undefined,
        urlLocal:
          API_URL +
          (resArticuloGrafico.archivo_impresion.imagen_impresion
            ? resArticuloGrafico.archivo_impresion.imagen_impresion
            : ""),
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  setearNombre = (nombre: string) => {
    let articuloGrafico = this.state.articuloGrafico;
    articuloGrafico.nombre = nombre;
    this.setState({ articuloGrafico: articuloGrafico });
  };

  setearAgenciaDiseño = (agencia_diseño: string) => {
    let articuloGrafico = this.state.articuloGrafico;
    articuloGrafico.agencia_diseño = agencia_diseño;
    this.setState({ articuloGrafico: articuloGrafico });
  };
  setearDescripcion = (descripcion: string) => {
    let articuloGrafico = this.state.articuloGrafico;
    articuloGrafico.descripcion = descripcion;
    this.setState({ articuloGrafico: articuloGrafico });
  };
  setearImagen = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files && event.target.files[0] != null) {
      let archivo = event.target.files[0];
      if (archivo.type.startsWith("image") || archivo.type.endsWith("pdf")) {
        let articuloGrafico = this.state.articuloGrafico;
        articuloGrafico.archivo_impresion.imagen_impresion = "";
        this.setState({
          imagenNueva: archivo,
          articuloGrafico: articuloGrafico,
          urlLocal: URL.createObjectURL(archivo),
        });
      } else {
        event.target.value = "";
        alert("Archivo no soportado");
      }
    }
  };
  cambiarImagen = () => {
    this.setState({ imagenNueva: undefined, urlLocal: API_URL });
  };

  //TODO Encontrar un mejor lugar y utilizacion a nivel visual
  quitarImagen = () => {
    const articuloGraficoActualizar: ArticuloGraficoPost = {
      nombre: this.state.articuloGrafico.nombre,
      agencia_diseño: this.state.articuloGrafico.agencia_diseño,
      descripcion: this.state.articuloGrafico.descripcion,
    };
    this.imagenArticuloGraficoService
      .update(articuloGraficoActualizar, this.state.articuloGrafico.id)
      .then((result) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_ARTICULOS_GRAFICO, {
          articuloGraficoId: this.state.articuloGrafico.id,
        });
        this.setState({
          imagenNueva: undefined,
          urlLocal: API_URL,
          alerta: {
            tipo: "alert-success",
            mensaje: "Articulo Grafico Actualizado!",
            titulo: "Atención",
            estado: "block",
          },
        });
      })
      .catch((error) => {
        this.articuloGraficoService.manejarErrorHTTP(error, "Artículo Gráfico");
      });
  };

  handleUpdate() {
    const articuloGraficoActualizar: ArticuloGraficoPost = {
      nombre: this.state.articuloGrafico.nombre,
      agencia_diseño: this.state.articuloGrafico.agencia_diseño,
      descripcion: this.state.articuloGrafico.descripcion,
    };
    if (this.state.imagenNueva) articuloGraficoActualizar.archivo_impresion = this.state.imagenNueva;
    this.articuloGraficoService
      .update(articuloGraficoActualizar, this.state.articuloGrafico.id)
      .then((result) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_ARTICULOS_GRAFICO, {
          articuloGraficoId: this.state.articuloGrafico.id,
        });
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Articulo Grafico Actualizado!",
            titulo: "Atención",
            estado: "block",
          },
        });
      })
      .catch((error) => {
        this.articuloGraficoService.manejarErrorHTTP(error, "Artículo Gráfico");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.articuloGrafico.nombre &&
      this.state.articuloGrafico.agencia_diseño &&
      this.state.articuloGrafico.descripcion
      ? false
      : true;
  };

  render() {
    if (this.state.articuloGrafico.id != 0) {
      return (
        <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            <div className={"row"}>
              <div className={"column"}>
                <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
                  <div className={"form-group"}>
                    <label htmlFor="nombre">Nombre</label>
                    <InputCaracteres
                      id="nombre"
                      name="nombre"
                      onChange={this.setearNombre}
                      value={this.state.articuloGrafico.nombre}
                      placeholder="Ingrese Nombre de Articulo Grafico"
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="agencia_diseño">Agencia de Diseño</label>
                    <InputCaracteres
                      id="agencia_diseño"
                      name="agencia_diseño"
                      onChange={this.setearAgenciaDiseño}
                      value={this.state.articuloGrafico.agencia_diseño}
                      placeholder="Ingrese Agencia de Diseño"
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="descripcion">Descripcion</label>
                    <InputCaracteres
                      id="descripcion"
                      name="descripcion"
                      onChange={this.setearDescripcion}
                      value={this.state.articuloGrafico.descripcion}
                      placeholder="Ingrese Descripcion"
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="archivo_impresion">Archivo de Impresión</label>
                    <br />
                    {this.state.urlLocal != API_URL ? (
                      <>
                        {!this.state.imagenNueva ? (
                          !this.state.urlLocal.endsWith(".pdf") ? (
                            <Image src={this.state.urlLocal} fluid />
                          ) : (
                            <div>
                              <p>
                                Abrir pdf en <a href={this.state.urlLocal}>nueva pestaña</a>.
                              </p>
                              <iframe src={this.state.urlLocal + "#toolbar=0"} width="100%" height="500px"></iframe>
                            </div>
                          )
                        ) : !this.state.imagenNueva.type.endsWith("pdf") ? (
                          <Image src={URL.createObjectURL(this.state.imagenNueva)} fluid />
                        ) : (
                          <div>
                            <p>
                              Abrir pdf en <a href={URL.createObjectURL(this.state.imagenNueva)}>nueva pestaña</a>.
                            </p>
                            <iframe
                              src={URL.createObjectURL(this.state.imagenNueva) + "#toolbar=0"}
                              width="100%"
                              height="500px"
                            ></iframe>
                          </div>
                        )}
                        <br />
                        {this.state.imagenNueva
                          ? this.state.imagenNueva.name
                          : this.state.urlLocal.substring(this.state.urlLocal.lastIndexOf("/") + 1)}
                        <br />
                        <button className="btn btn-info" onClick={() => this.cambiarImagen()}>
                          {" "}
                          Cambiar Imagen{" "}
                        </button>
                      </>
                    ) : (
                      <Form.File
                        id="archivo_impresion"
                        name="archivo_impresion"
                        onChange={this.setearImagen}
                        label="Ingrese la Imagen (no obligatorio)"
                        accept="image/* , application/pdf"
                      />
                    )}
                  </div>
                  <div className={"form-group"} style={{ display: "flex", justifyContent: "space-between" }}>
                    <button ref="boton" type="submit" className={"btn btn-info"} disabled={this.disableButton()}>
                      {"Actualizar"}
                    </button>
                    <button className={"btn btn-outline-secondary"} onClick={this.ocultar}>
                      Ocultar
                    </button>
                  </div>
                  <div className={"form-group"}>
                    <Alerta
                      tipo={this.state.alerta.tipo}
                      titulo={this.state.alerta.titulo}
                      mensaje={this.state.alerta.mensaje}
                      estado={this.state.alerta.estado}
                    />
                  </div>
                </form>
                {this.state.articuloGrafico.archivo_impresion.imagen_impresion != null ? (
                  <button className={"btn btn-danger"} onClick={this.quitarImagen}>
                    {"Quitar Imagen"}
                  </button>
                ) : null}
              </div>
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
