import React, { Component } from "react";
import Habilitador from "../../../../../compartido/components/habilitador/Habilitador";
import RequestButton from "../../../../../compartido/components/request-button/RequestButton";
import { Table } from "react-bootstrap";
import SelectTipoResponsabilidad from "../SelectTipoResponsabilidad";
import * as Icon from "react-bootstrap-icons";


import { CANALES_WEB_SOCKET, EmpleadoGet, EmpleadoPost, EmpleadoService, RelacionEmpleadoArea, RelacionEmpleadoSubArea, TipoResponsabilidad, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";







interface Props {
  empleado: EmpleadoGet;
}
interface State {
  empleado: EmpleadoGet;
  responsabilidades: Array<TipoResponsabilidad>;
}

export default class TablaSubAreas extends Component<Props, State> {

  private empleadoService:EmpleadoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      empleado: this.props.empleado,
      responsabilidades: [],
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_PERSONAL_EMPLEADOS,
      funcionCallback: this.refrescarEmpleado,
    });

    this.empleadoService = new EmpleadoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.cargarResponsabilidades();
  }

  refrescarEmpleado = async (e: MessageEvent) => {
    let id: number = JSON.parse(e.data).message.empleadoId;

    if (id == this.state.empleado.id) {
      let aux: EmpleadoGet = await this.empleadoService.get(id);
      this.setState({ empleado: aux });
    }
  };

  cargarResponsabilidades = async () => {
    let responsabilidades = await this.empleadoService.getResponsabilidades();
    this.setState({
      responsabilidades: responsabilidades,
    });
  };

  actualizarSubArea = (relacionSubArea: RelacionEmpleadoSubArea) => {
    return this.empleadoService
      .actualizarRelacionConSubArea(
        this.state.empleado.id,
        relacionSubArea.subarea.id,
        relacionSubArea.tipo_responsabilidad.id
      )
      .then(() => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ADMINISTRACION_PERSONAL_EMPLEADOS,
          {
            empleadoId: this.state.empleado.id,
          }
        );
      });
  };

  renderizarFilas = () => {
    return this.state.empleado.subareas.map((relacion, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{relacion.subarea.area.nombre}</td>
          <td className="text-center">{relacion.subarea.nombre}</td>

          <td className="text-center">
            {
              <Habilitador>
                <SelectTipoResponsabilidad
                  responsabilidades={this.state.responsabilidades}
                  seleccionado={relacion.tipo_responsabilidad}
                  callbackParent={(
                    tipo_responsabilidad: TipoResponsabilidad
                  ) => {
                    relacion.tipo_responsabilidad = tipo_responsabilidad;
                  }}
                />
              </Habilitador>
            }
          </td>
          <td className="text-center">
            {new Date(relacion.fecha_inicio).toLocaleDateString()}
          </td>

          <td className="text-center">
            <RequestButton
              texto="Actualizar"
              propsBoton={{
                variant: "info",
                disabled: relacion.tipo_responsabilidad ? false : true,
              }}
              icon={<Icon.ArrowRepeat />}
              onClick={() => this.actualizarSubArea(relacion)}
            />
          </td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Area</th>
            <th className="text-center">SubArea</th>
            <th className="text-center">Responsabilidad</th>
            <th className="text-center">Fecha de Inicio</th>
            <th className="text-center">Actualizar</th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  render() {
    return this.renderizarTabla();
  }
}
