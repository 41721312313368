import React, { Component } from "react";
import { SelectItem, SelectProps } from "@mantine/core";
import SelectBusqueda, { PropsSelectGeneral } from "./SelectBusqueda";
import { PropsSelectGenericoItems } from "./PropsSelectGenerico";
import SelectItemUtils from "../../utils/SelectItemUtils";

interface State {}

interface Props {
  labelGetter: (obj: any) => string; // funcion que indica como obtener el label a mostrar
}

export default class SelectComponent extends Component<
  Props & PropsSelectGenericoItems<any> & PropsSelectGeneral & Omit<SelectProps, "data">,
  State
> {
  getPosicion = (valor: any | null): string | null => {
    if (valor === null) {
      return null;
    }

    if (typeof valor === "number") {
      return this.props.items.findIndex((e) => e.id === valor).toString();
    }

    return this.props.items.findIndex((e) => e.id === valor.id).toString();
  };

  getSeleccionado = (indice: number | null) => {
    if (indice != null) this.props.callbackParent(this.props.items[indice]);
    else this.props.callbackParent(indice);
  };

  armarDatosSelect(): SelectItem[] {
    let arreglo: SelectItem[] = [];
    arreglo = this.props.items.map((item, index) => ({
      value: index.toString(),
      label: this.props.labelGetter(item),
    }));
    return SelectItemUtils.ordenarLabelAlfabeticamente(arreglo);
  }

  render() {
    return (
      <SelectBusqueda
        {...this.props}
        data={this.armarDatosSelect()}
        callbackParent={this.getSeleccionado}
        value={this.getPosicion(this.props.seleccionado)}
      />
    );
  }
}
