import React, { Component } from "react";
import { CANALES_WEB_SOCKET, OrdenCompra, OrdenCompraService, WebSocketService } from "serviciossaintmichel";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import RequestButton from "../../../../../../../compartido/components/request-button/RequestButton";
import { Global } from "../../../../../../../Global";
import OrdenCompraLoad, { Inputs } from "./OrdenCompraLoad";





export interface Props {
  clienteId: number;
  fechaInicioMin?: Date;
}

interface State {
  ordenCompra: OrdenCompra | null;
  ordenCompraValida: boolean;
}

export default class OrdenCompraNew extends Component<Props, State> {

  private ordenCompraService:OrdenCompraService;

  constructor(props: Props) {
    super(props);
    this.state = {
      ordenCompra: null,
      ordenCompraValida: false,
    };

    this.ordenCompraService =  new OrdenCompraService(Global.UsuarioService.getToken()!);

  }

  handleSubmit = async () => {
    const { ordenCompra } = this.state;

    if (ordenCompra === null) {
      return;
    }

    try {
      await this.ordenCompraService.crear(ordenCompra);
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_SERVICIO_ACTIVO,
        {
          clienteId: this.props.clienteId,
        }
      );
    } catch (error) {
      this.ordenCompraService.manejarErrorHTTP(error, "OrdenCompra");
    }
  };

  onChange = (ordenCompra: OrdenCompra) => {
    this.setState({ ordenCompra: ordenCompra });
  };

  onValidationChange = (ordenCompraValida: boolean) => {
    this.setState({ ordenCompraValida: ordenCompraValida });
  };

  render = () => {
    const { ordenCompraValida } = this.state;
    const { clienteId, fechaInicioMin } = this.props;

    return (
      <>
        <OrdenCompraLoad
          ordenCompra={null}
          clienteId={clienteId}
          onChange={this.onChange}
          onValidationChange={this.onValidationChange}
          fechaInicioMin={fechaInicioMin}
          inputsDeshabilitados={[Inputs.FechaFinEfectiva]}
        />
        <Flex container justifyContent="center" marginTop="1rem">
          <RequestButton
            propsBoton={{ disabled: !ordenCompraValida, variant: "success" }}
            onClick={this.handleSubmit}
            texto={"Guardar"}
          />
        </Flex>
      </>
    );
  };
}
