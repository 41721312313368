import React, { Component } from "react";
import Seccion from "../../../compartido/components/seccion/Seccion";
import Ruta from "../../../compartido/models/Ruta";
import { Global } from "../../../Global";

import ZonaCrear from "../components/ZonaCrear";
import ZonaLista from "../components/ZonaLista";

export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];

  if (Global.UsuarioService.getPermisos().Mapas.Zonas.hasAccess()) {
    aux.push({
      name: "Crear",
      path: SeccionZonas.pathBase + "/crear",
      component: ZonaCrear,
    });
  }
  if (Global.UsuarioService.getPermisos().Mapas.Zonas.hasAccess()) {
    aux.push({
      name: "Lista",
      path: SeccionZonas.pathBase + "/zonas",
      component: ZonaLista,
    });
  }
  return aux;
}

export default class SeccionZonas extends Component<Props> {
  static pathBase = "/mapas/seccion-zonas";
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Seccion rutasNavbar={getRutasAccesibles()} tituloNavbar={"Menú Zonas"} />
    );
  }
}
