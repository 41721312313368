import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Flex from "../../../compartido/components/flex/Flex";
import RepartosShow, { Version } from "./show/RepartosShow";
import RepartoNew from "./new/RepartoNew";
import RepartoUpdate from "./update/RepartoUpdate";
import RepartoShow from "./show/RepartoShow";
import { CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../Global";

interface Props {}

interface State {
  tabVisible: any;
  disabledActualizar: boolean;
  disabledVer: boolean;
  responsive: boolean;
  idReparto: number | null;
  recienCreado: boolean;
}

export default class Repartos extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista",
      disabledActualizar: true,
      disabledVer: true,
      responsive: window.innerWidth < 700 ? true : false,
      idReparto: null,
      recienCreado: false,
    };

    window.addEventListener("resize", this.actualizarDimensiones);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS,
      funcionCallback: this.cargarReparto,
    });
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  cargarReparto = async (e: MessageEvent) => {};

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  actualizar = (idReparto: number) => {
    this.setState({
      tabVisible: "actualizar",
      disabledActualizar: false,
      idReparto: idReparto,
    });
  };

  ver = (idReparto: number) => {
    this.setState({
      tabVisible: "ver",
      disabledVer: false,
      idReparto: idReparto,
      recienCreado: false,
    });
  };

  ocultarVer = () => {
    this.setState({
      tabVisible: "lista",
      disabledVer: true,
      idReparto: null,
    });
  };

  ocultarNuevo = () => {
    this.setState({
      tabVisible: "lista",
    });
  };

  renderizarNuevo = () => {
    if (Global.UsuarioService.getPermisos().Distribucion.Repartos.Reparto.canAdd()) {
      return (
        <Tab eventKey="nuevo" title="Nuevo" width="100%">
          {this.state.tabVisible === "nuevo" ? (
            <RepartoNew
              onExit={this.ocultarNuevo}
              onSaveSucces={async (idReparto: number) => {
                this.setState({ recienCreado: true, idReparto: idReparto });
                this.actualizar(idReparto);
              }}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarLista = () => {
    if (Global.UsuarioService.getPermisos().Distribucion.Repartos.Reparto.canView()) {
      return (
        <Tab eventKey="lista" title="Lista">
          <RepartosShow
            version={Version.TABLE}
            onView={this.ver}
            onUpdate={this.actualizar}
          />
        </Tab>
      );
    }
  };

  renderizarActualizar = () => {
    if (
      Global.UsuarioService.getPermisos().Distribucion.Repartos.Reparto.canChange()
    ) {
      return (
        <Tab
          eventKey="actualizar"
          title="Actualizar"
          disabled={this.state.disabledActualizar}
        >
          {this.state.idReparto && this.state.tabVisible === "actualizar" ? (
            <RepartoUpdate
              idReparto={this.state.idReparto}
              recienCreado={this.state.recienCreado}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarVerDetalle = () => {
    if (Global.UsuarioService.getPermisos().Distribucion.Repartos.Reparto.canView()) {
      return (
        <Tab
          eventKey="ver"
          title="Ver Detalle"
          disabled={this.state.disabledVer}
        >
          {this.state.idReparto && this.state.tabVisible === "ver" ? (
            <RepartoShow idReparto={this.state.idReparto} />
          ) : null}
        </Tab>
      );
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey="lista"
      transition={false}
      activeKey={this.state.tabVisible}
      onSelect={this.onSelect}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "center" : "",
      }}
      unmountOnExit={true}
      width="100%"
    >
      {this.renderizarNuevo()}
      {this.renderizarLista()}
      {this.renderizarActualizar()}
      {this.renderizarVerDetalle()}
    </Tabs>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {this.renderizar(this.state.responsive)}
      </Flex>
    );
  }
}
