import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";

import { CANALES_WEB_SOCKET, ProductoSeguridadHigieneGet, ProductoSeguridadHigienePost, ProductoSeguridadHigieneService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  productosSeguridadHigiene: Array<ProductoSeguridadHigieneGet>;
}

export default class ProductoSeguridadHigieneLista extends Component<
  Props,
  State
> {

  private productoSeguridadHigieneService:ProductoSeguridadHigieneService;

  constructor(props: Props) {
    super(props);
    this.state = {
      productosSeguridadHigiene: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_PROODUCTOS_SEGURIDAD_HIGIENE,
      funcionCallback: this.getElementos,
    });

    this.productoSeguridadHigieneService = new ProductoSeguridadHigieneService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.productoSeguridadHigieneService.list()
      .then((result) => {
        this.setState({ productosSeguridadHigiene: result });
      })
      .catch((error) => {
        this.productoSeguridadHigieneService.manejarErrorHTTP(
          error,
          "Producto Seguridad Higiene"
        );
      });
  };

  componentDidMount() {
    this.productoSeguridadHigieneService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }
  armarFilas(
    productosSeguridadHigiene: Array<ProductoSeguridadHigieneGet>
  ): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (productosSeguridadHigiene.length > 0) {
      for (let i = 0; i < productosSeguridadHigiene.length; i++) {
        let fila: Array<string> = [];
        fila.push(productosSeguridadHigiene[i].nombre);
        fila.push(productosSeguridadHigiene[i].marca);
        fila.push(productosSeguridadHigiene[i].descripcion);
        let filaResult: FilaTablaGenerica = {
          id: productosSeguridadHigiene[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Elementos.ProductosSeguridadHigiene.ProductoSeguridadHigiene.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Elementos.ProductosSeguridadHigiene.ProductoSeguridadHigiene.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.productoSeguridadHigieneService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.productosSeguridadHigiene)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.productoSeguridadHigieneService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaProductosSeguridadHigiene"
      />
    );
  }
}
