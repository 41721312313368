import React, { Component } from "react";
import { ResumenProgramacionesEntrega as ResumenProgramacionesEntregaI } from "serviciossaintmichel";

interface Props {
  resumenProgramaciones: ResumenProgramacionesEntregaI;
  titulo?: string;
}

interface State {}

export default class ResumenProgramacionesEntrega extends Component<Props, State> {
  render() {
    const { resumenProgramaciones, titulo } = this.props;

    const zonasText =
      resumenProgramaciones.zonas.length > 0
        ? resumenProgramaciones.zonas.map((zona) => zona.nombre).join(", ")
        : "Sin zonas";

    const productosText =
      resumenProgramaciones.productos_requeridos.length > 0
        ? resumenProgramaciones.productos_requeridos
            .map((productoCantidad) => `${productoCantidad.producto.nombre} x ${productoCantidad.cantidad} unidades`)
            .join(" + ")
        : "Sin productos";

    const bultosProductoText =
      resumenProgramaciones.bultos_producto_requeridos.length > 0
        ? resumenProgramaciones.bultos_producto_requeridos
            .map(
              (bultoProductoCantidad) =>
                `${bultoProductoCantidad.bulto_producto.nombre} x ${bultoProductoCantidad.cantidad} unidades`
            )
            .join(" + ")
        : "Sin bultos producto";

    return (
      <div style={{ border: "1px solid black", borderRadius: "1rem", padding: "1rem", margin: "1rem 0px" }}>
        {titulo ? (
          <div style={{ textAlign: "center" }}>
            <strong>{titulo}</strong>
          </div>
        ) : null}

        <div>
          <strong>Cantidad: </strong>
          <span>{resumenProgramaciones.cantidad_programaciones}</span>
          <span> - </span>
          <strong>Peso total: </strong>
          <span>{resumenProgramaciones.peso_total} kg</span>
          <span> - </span>
          <strong>Monto total: </strong>
          <span>{resumenProgramaciones.precio_total} $</span>
        </div>

        <div>
          <strong>Zonas involucradas: </strong>
          <span>{zonasText} </span>
          <span> - </span>
          <strong>Productos requeridos: </strong>
          <span>{productosText}</span>
          <span> - </span>
          <strong>Bultos producto requeridos: </strong>
          <span>{bultosProductoText}</span>
        </div>
      </div>
    );
  }
}
