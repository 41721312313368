import React, { Component } from "react";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import RequestButton from "../../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import H from "../../../../../../libreria/message/title/H";
import MaquinaAlquilerLoad from "./MaquinaAlquilerLoad";
import { AlquilerGet, AlquilerService, CANALES_WEB_SOCKET,  MaquinaSimbolicaAlquiler, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";




export interface Props {
  onSaveSuccess?: Function;
  onExit?: Function;
  alquiler: AlquilerGet;
}

interface State {
  maquina: MaquinaSimbolicaAlquiler;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class MaquinaAlquilerNew extends Component<Props, State> {

  private alquilerService:AlquilerService;

  constructor(props: Props) {
    super(props);
    this.state = {
      maquina: this.createEmptyMaquinaSimbolica(),
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.alquilerService =  new AlquilerService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  createEmptyMaquinaSimbolica = (): MaquinaSimbolicaAlquiler => {
    return { maquina_simbolica: -1, cantidad: 0 };
  };

  onSave = async () => {
    try {
      debugger;

      let x = await this.alquilerService.agregarMaquina(
        this.props.alquiler.id,
        this.state.maquina
      );

      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES_MAQUINAS,
        {
          type: "new",
          idAlquiler: this.props.alquiler.id,
          idmaquina: x.maquina_simbolica,
        }
      );

      await Swal.fire({
        icon: "success",
        text: "Maquina Actualizado",
      });
    } catch (error) {
      this.alquilerService.manejarErrorHTTP(error, "Nueva Maquina Alquiler");
    }
  };

  onExit = () => {
    if (this.props.onExit != undefined) this.props.onExit();
  };

  isValid = (nombre: string): boolean => {
    return nombre.length > 0 ? true : false;
  };

  onChangeMaquina = (maquina: MaquinaSimbolicaAlquiler) => {
    this.setState({ maquina: maquina });
  };

  onChangeValidation = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border="1px solid grey"
        borderRadius="5px"
        padding="10px"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="10px"
        >
          <H size={4} texto={"Agregar Maquina"} />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <MaquinaAlquilerLoad
            maquina={this.state.maquina}
            onChange={this.onChangeMaquina}
            onValidationChange={this.onChangeValidation}
          />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Agregar"}
            onClick={this.onSave}
          />
        </Flex>
      </Flex>
    );
  }
}
