import React, { Component } from "react";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../libreria/appearance/margin/Margin";
import Box from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import DatosAlquilerShow from "./datos/DatosAlquilerShow";
import MaquinasAlquilerShow from "./maquinas/MaquinasAlquilerShow";
import PreciosAlquilerShow from "./precios/PreciosAlquilerShow";
import ProductosAlquilerShow from "./productos/ProductosAlquilerShow";

import { AlquilerGet, AlquilerService, CANALES_WEB_SOCKET,  MaquinaSimbolicaAlquiler, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";


export interface Props {
  id: number;
}
interface State {
  alquiler: AlquilerGet | null;
  cargando: boolean;
}

export default class AlquilerShow extends Component<Props, State> {

  private alquilerService:AlquilerService;

  constructor(props: Props) {
    super(props);
    this.state = {
      alquiler: null,
      cargando: true,
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES,
      funcionCallback: this.onChannelMesagge,
    });

    this.alquilerService =  new AlquilerService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    const alquiler = await this.alquilerService.get(this.props.id);
    this.setState({ alquiler: alquiler, cargando: false });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    const alquiler = await this.alquilerService.get(this.props.id);
    this.setState({ alquiler: alquiler, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({ cargando: true });
      const alquiler = await this.alquilerService.get(this.props.id);
      this.setState({ alquiler: alquiler, cargando: false });
    }
  };

  renderAlquiler = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <H alineacion={"center"} size={3} texto={this.state.alquiler!.nombre} />
      <Margin top="30px" />
      <DatosAlquilerShow id={this.state.alquiler!.id} />
      <Margin top="25px" />
      <PreciosAlquilerShow id={this.state.alquiler!.id} />
      <Margin bottom="50px" />
      <ProductosAlquilerShow id={this.state.alquiler!.id} />
      <Margin bottom="50px" />
      <MaquinasAlquilerShow id={this.state.alquiler!.id} />
      <Margin bottom="50px" />
    </Flex>
  );

  renderLoading = () => "Cargando Datos";

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Margin top="20px" />
        <Box padding="15px">
          {this.state.cargando ? this.renderLoading() : this.renderAlquiler()}
        </Box>
      </Flex>
    );
  }
}
