import React, { Component } from "react";
import { Modal } from "@mantine/core";
import * as Icon from "react-bootstrap-icons";
import ManejadorErroresHTTP from "../../../../compartido/utils/ManejadorErroresHTTP";
import { Button } from "react-bootstrap";
import DescargaUpdate from "./DescargaUpdate";
import CargamentoDetalle from "../show/CargamentoDetalle";
import Flex from "../../../../libreria/appearance/flex/Flex";
import RepartoDiarioDescargasShow from "../show/RepartoDiarioDescargasShow";
import DescargaNew from "../new/DescargaNew";
import Swal from "sweetalert2";
import RequestButton from "../../../../libreria/action/request-button/RequestButton";

import {
  CANALES_WEB_SOCKET,
  CargamentoLectura,
  RepartoDiarioService,
  WebSocketService,
} from "serviciossaintmichel";
import { Global } from "../../../../Global";
import ImagenesURLs from "../../../../compartido/utils/ImagenesURLs";

interface Props {
  idRepartoDiario: number;
}

interface State {
  descargaId: number | null;
}

export default class DescargasUpdate extends Component<Props, State> {
  private repartoDiarioService: RepartoDiarioService;

  constructor(props: Props) {
    super(props);
    this.state = {
      descargaId: null,
    };

    this.repartoDiarioService = new RepartoDiarioService(
      Global.UsuarioService.getToken()!
    );
  }

  eliminarDescarga = async (descargaId: number) => {
    try {
      await this.repartoDiarioService.eliminarDescarga(
        this.props.idRepartoDiario,
        descargaId
      );
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
        {
          idRepartoDiario: this.props.idRepartoDiario,
        }
      );
      Swal.fire({
        icon: "success",
        text: "Descarga eliminada con éxito",
      });
    } catch (error) {
      const msjError = ManejadorErroresHTTP.getMensajeError(error);
      Swal.fire({
        icon: "error",
        text: "No se pudo eliminar la descarga - " + msjError,
      });
    }
  };

  renderDescarga = (descarga: CargamentoLectura) => {
    return (
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid black",
          borderRadius: "5px",
          padding: "1rem",
        }}
      >
        <Flex gap="1rem" justifyContent="flex-end">
          <Button
            onClick={() => {
              this.setState({ descargaId: descarga.id });
            }}
          >
            <Icon.Pencil />
          </Button>
          <RequestButton
            propsBoton={{ variant: "danger" }}
            onClick={() => {
              this.eliminarDescarga(descarga.id);
            }}
            icon={<Icon.Trash />}
            texto=""
            confirmacion
          />
        </Flex>
        <CargamentoDetalle cargamento={descarga} />
      </div>
    );
  };

  render() {
    const { descargaId } = this.state;
    const { idRepartoDiario } = this.props;

    return (
      <>
        <RepartoDiarioDescargasShow
          idRepartoDiario={idRepartoDiario}
          renderMethod={this.renderDescarga}
        />

        <Button
          variant="success"
          onClick={() => {
            this.setState({ descargaId: -1 });
          }}
        >
          Añadir una descarga
        </Button>

        {descargaId !== null ? (
          <Modal
            centered
            size="70%"
            transitionDuration={0}
            opened={descargaId !== null}
            onClose={() => {
              this.setState({ descargaId: null });
            }}
            styles={{
              modal: {
                backgroundImage: ImagenesURLs.urlFondoModal(),
              },
            }}
            closeOnClickOutside={false}
            title={
              descargaId && descargaId > 0
                ? "Modificación de descarga"
                : "Registro de descarga"
            }
          >
            {descargaId > -1 ? (
              <DescargaUpdate
                idRepartoDiario={idRepartoDiario}
                idDescarga={descargaId}
              />
            ) : (
              <DescargaNew idRepartoDiario={idRepartoDiario} />
            )}
          </Modal>
        ) : null}
      </>
    );
  }
}
