import React, { Component } from "react";
import Text from "../../../../../../libreria/message/text/Text";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../../libreria/appearance/margin/Margin";
import { API_URL } from "serviciossaintmichel";
import { Button, Image } from "react-bootstrap";
import { Remito, RemitoService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";

export interface Props {
  idRemito: number;
}

interface State {
  remito: Remito | null;
  cargando: boolean;
}

export default class RemitoProgramacionEntregaShow extends Component<Props, State> {
  private remitoService: RemitoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      remito: null,
      cargando: true,
    };
    this.remitoService = new RemitoService(Global.UsuarioService.getToken()!);
  }

  componentDidMount = async () => {
    await this.cargarRemito();
    //TODO sacar cuando se pueda
    /* WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_REMITOS,
      funcionCallback: this.onChannelMesagge,
    }); */
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.remitoId == this.props.idRemito) {
      await this.cargarRemito();
    }
  };

  cargarRemito = async () => {
    let remito = await this.remitoService.get(this.props.idRemito).catch((error) => {
      this.remitoService.manejarErrorHTTP(error, "Remito");
      return null;
    });
    this.setState({ remito: remito, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      await this.setState({ cargando: true });
      await this.cargarRemito();
    }
  };

  descargarArchivo = () => {
    if (this.state.remito && this.state.remito.archivo) {
      this.remitoService.descargarArchivo(this.state.remito.id, this.state.remito.archivo, "archivo").catch((error) => {
        this.remitoService.manejarErrorHTTP(error, "Remito");
      });
    }
  };

  renderRemito = () =>
    this.state.remito ? (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="flex-start"
        justifyContent="center"
        marginTop="10px"
      >
        <Text>
          <b>Fecha</b>: {this.state.remito.fecha}
        </Text>
        <Text>
          <b>Número de remito</b>: {this.state.remito.numero}
        </Text>
        <Flex flexWrap="nowrap">
          <Text>
            <b>Archivo</b>:
          </Text>
          {this.state.remito.archivo ? (
            <Flex container flexDirection="column" alignItems="center">
              {!this.state.remito.archivo.endsWith(".pdf") ? (
                <Image src={API_URL + this.state.remito.archivo} fluid />
              ) : (
                <div>
                  <p>
                    Abrir pdf en <a href={API_URL + this.state.remito.archivo}>nueva pestaña</a>.
                  </p>
                  <iframe src={API_URL + this.state.remito.archivo + "#toolbar=0"} width="100%" height="500px"></iframe>
                </div>
              )}
              <Button variant="success" style={{ marginTop: "0.5rem" }} onClick={this.descargarArchivo}>
                Descargar
              </Button>
            </Flex>
          ) : (
            <>No tiene</>
          )}
        </Flex>
        <Margin top="30px" />
      </Flex>
    ) : null;
  renderLoading = () => "Cargando Datos";

  render() {
    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.cargando ? this.renderLoading() : this.renderRemito()}
      </Flex>
    );
  }
}
