import React from "react";
import { ClienteService, DireccionDTO, MaquinaServicioFisicaGet, MaquinaServicioFisicaService, MaquinaServicioSimbolicaGet, ServicioCliente } from "serviciossaintmichel";
import MaquinasServicioFisicaShow, {
  Version as VersionMaquinaFisica,
} from "../../../../../../elementos/maquinas/MaquinaServicioFisica/components/MaquinasServicioFisicaShow";
import MaquinasSimbolicasShow, {
  Version as VersionMaquinaSimbolica,
} from "../../../../../../elementos/maquinas/MaquinaServicioSimbolica/components/show/MaquinasSimbolicasShow";
import { Global } from "../../../../../../Global";
import RequestButton from "../../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import ClienteDireccionesShow, { Version as VersionSelect } from "../direcciones/ClienteDireccionesShow";

interface Props {
  clienteId: number;
  direccionesCliente: Array<DireccionDTO>;
  servicio: ServicioCliente;
  maquinasCliente: Array<MaquinaServicioFisicaGet>;
}

interface State {
  maquinaSimbolicasRestantes: Array<MaquinaServicioSimbolicaGet> | null;
  maquinaSimbolicaSeleccionada: MaquinaServicioSimbolicaGet | null;
  maquinaFisica: MaquinaServicioFisicaGet | null;
  direccionId: number | null;
}

export default class MaquinaClienteUpdate extends React.Component<Props, State> {

  private clienteService:ClienteService;
  private maquinaServicioFisicaService:MaquinaServicioFisicaService;


  constructor(props: Props) {
    super(props);
    this.state = {
      maquinaSimbolicaSeleccionada: null,
      maquinaFisica: null,
      direccionId: null,
      maquinaSimbolicasRestantes: null,
    };

    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);
    this.maquinaServicioFisicaService = new MaquinaServicioFisicaService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.setMaquinaRestantes();
    PubSub.subscribe("MAQUINAS_CLIENTE", (canal: string, data: any) => {
      if (data.cliente_id === this.props.clienteId) {
        this.setMaquinaRestantes();
      }
    });
  }

  componentWillUnmount() {
    PubSub.clearAllSubscriptions();
  }

  async setMaquinaRestantes() {
    try {
      const maquinaSimbolicas = await this.clienteService.getMaquinasRestantesServicioActivo(this.props.clienteId);
      this.setState({
        maquinaSimbolicasRestantes: maquinaSimbolicas,
        maquinaSimbolicaSeleccionada: null,
        maquinaFisica: null,
        direccionId: null,
      });
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error, "ServicioActivo");
    }
  }

  onMaquinaSimbolicaSelectChange = (maquinaSimbolicaSeleccionada: MaquinaServicioSimbolicaGet | null) => {
    this.setState({ maquinaSimbolicaSeleccionada: maquinaSimbolicaSeleccionada, maquinaFisica: null });
  };

  onMaquinaFisicaSelectChange = (maquinaFisica: MaquinaServicioFisicaGet | null) => {
    this.setState({ maquinaFisica: maquinaFisica });
  };

  onDireccionSelectChange = (direccionId: number) => {
    const valor = direccionId !== -1 ? direccionId : null;
    this.setState({ direccionId: valor });
  };

  onSubmit = async () => {
    const { maquinaFisica, direccionId } = this.state;

    if (maquinaFisica === null) {
      return;
    }

    const payload = {
      ...maquinaFisica,
      proveedor: maquinaFisica.proveedor.id,
      maquina_simbolica: maquinaFisica.maquina_simbolica.id,
      direccion: direccionId,
    };

    try {
      await this.maquinaServicioFisicaService.update(payload, maquinaFisica.id);
    } catch (error) {
      this.maquinaServicioFisicaService.manejarErrorHTTP(error, "MaquinaServicioFisica");
    }

    await this.setMaquinaRestantes();
    PubSub.publish("MAQUINAS_CLIENTE", { cliente_id: this.props.clienteId });
  };

  render = () => {
    const { clienteId } = this.props;
    const { maquinaSimbolicasRestantes, maquinaSimbolicaSeleccionada, direccionId, maquinaFisica } = this.state;

    if (maquinaSimbolicasRestantes === null) {
      return <span>Cargando...</span>;
    }

    if (maquinaSimbolicasRestantes.length < 1) {
      return <span>Las máquinas del servicio actual se encuentran todas asignadas</span>;
    }

    return (
      <div>
        <Flex gap="1rem">
          <div>
            <strong>Tipo de máquina</strong>
            <MaquinasSimbolicasShow
              version={VersionMaquinaSimbolica.SELECT}
              propsSelect={{
                callbackParent: this.onMaquinaSimbolicaSelectChange,
                seleccionado: maquinaSimbolicaSeleccionada,
              }}
              filtrarIds={maquinaSimbolicasRestantes.map((maq) => maq.id)}
            />
          </div>
          <div>
            <strong>Máquina física</strong>
            {!!maquinaSimbolicaSeleccionada ? (
              <MaquinasServicioFisicaShow
                version={VersionMaquinaFisica.SELECT}
                maquinaSimbolicaId={maquinaSimbolicaSeleccionada.id}
                onSelect={this.onMaquinaFisicaSelectChange}
                soloDisponibles
              />
            ) : (
              <div>Seleccione un tipo de máquina</div>
            )}
          </div>
          <div>
            <strong>Dirección del cliente</strong>
            <ClienteDireccionesShow
              clienteId={clienteId}
              version={VersionSelect.SELECT}
              onSelect={this.onDireccionSelectChange}
              idSeleccionado={direccionId ?? undefined}
            />
          </div>
          <div style={{ alignSelf: "center" }}>
            <RequestButton
              propsBoton={{ disabled: direccionId === null || maquinaFisica === null }}
              onClick={this.onSubmit}
              texto="Asignar"
              confirmacion
            />
          </div>
        </Flex>
      </div>
    );
  };
}
