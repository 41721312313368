import React, { Component } from "react";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../libreria/appearance/margin/Margin";
import { Box } from "../../../../libreria/containers/box/Box";
import H from "../../../../libreria/message/title/H";
import DatosRepartoShow from "./datos/DatosRepartoShow";
import RecorridosRepartoShow from "./recorridos/RecorridosRepartoShow";

import { CANALES_WEB_SOCKET,        Reparto,    RepartoService,    WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";


export interface Props {
  idReparto: number;
}

interface State {
  reparto: Reparto | null;
  cargando: boolean;
}

export default class RepartoShow extends Component<Props, State> {

  private repartoService:RepartoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      reparto: null,
      cargando: true,
    };

    this.repartoService =  new RepartoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    await this.cargarReparto();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS,
      funcionCallback: this.onChannelMesagge,
    });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.repartoId == this.props.idReparto) {
      await this.cargarReparto();
    }
  };

  cargarReparto = async () => {
    let reparto = await this.repartoService.get(this.props.idReparto).catch(
      (error) => {
        this.repartoService.manejarErrorHTTP(error, "Reparto");
        return null;
      }
    );
    this.setState({ reparto: reparto, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({ cargando: true });
      await this.cargarReparto();
    }
  };

  renderReparto = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <H alineacion={"center"} size={3} texto={this.state.reparto!.nombre} />
      <Margin top="30px" />
      <DatosRepartoShow idReparto={this.state.reparto!.id} />
      <Margin top="25px" />
      <RecorridosRepartoShow idReparto={this.state.reparto!.id} />
      <Margin bottom="50px" />
    </Flex>
  );

  renderLoading = () => "Cargando Datos";

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Margin top="20px" />
        <Box padding="15px">
          {this.state.cargando ? this.renderLoading() : this.renderReparto()}
        </Box>
      </Flex>
    );
  }
}
