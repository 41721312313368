import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
import Repartos from "./components/Repartos";

export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];

  if (Global.UsuarioService.getPermisos().Distribucion.Repartos.Reparto.hasAccess()) {
    aux.push({
      name: "Reparto",
      path: SeccionReparto.pathBase + "/reparto",
      component: Repartos,
    });
  }
  return aux;
}

export default class SeccionReparto extends Component<Props> {
  static pathBase = "/distribucion/seccion-repartos";
  render() {
    return <Seccion rutasNavbar={getRutasAccesibles()} tituloNavbar={"Sección Repartos"} />;
  }
}
