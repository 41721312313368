import { Button, Checkbox, Group, Stepper, Switch } from "@mantine/core";
import React, { Component } from "react";
import {
  Cliente,
  ListaPrecio,
  CrearClienteVacio,
  CrearListaPrecioVacio,
  CrearFacturaVacio,
  Fuente,
  Pedido,
} from "serviciossaintmichel";
import ClientesShow, {
  VersionClientesShow,
} from "../../../../../administracion/seccion-clientes/clientes/components/ClientesShow";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Box from "../../../../../libreria/containers/box/Box";
import ListasPreciosShow, { Version } from "../../../../seccion-listas-precios/show/ListasPreciosShow";
import FuenteLoad from "../../../fuentes/components/load/FuenteLoad";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  pedido: Pedido;
  onSave: Function;
  tituloOnSave: string;
}

interface State {
  pedido: Pedido;
  validation: boolean;
  clienteSeleccionado: Cliente | null;
  opcionListaPrecio: boolean;
  listaPrecioSeleccionado: ListaPrecio | null;
  fuenteValidada: boolean;
  pasoActivo: number;
  setPasoActivo: (stepIndex: number) => void;
}

export default class PedidoLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pedido: props.pedido,
      clienteSeleccionado: this.props.pedido ? this.props.pedido.cliente : null,
      opcionListaPrecio: false,
      listaPrecioSeleccionado: this.props.pedido ? this.props.pedido.lista_precio : null,
      validation: this.isValid(props.pedido),
      fuenteValidada: props.pedido.fuente.id > -1,
      pasoActivo: 0,
      setPasoActivo: (estado) => this.setState({ pasoActivo: estado }),
    };
    this.props.onValidationChange(this.state.validation);
  }

  componentDidMount = async () => {
    this.updateValidation(this.state.pedido);
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    if (prevProps.pedido !== this.props.pedido) {
      this.setState({ pedido: this.props.pedido });
    }
    if (prevState.validation !== this.state.validation) {
      this.props.onValidationChange(this.state.validation);
    }
    if (prevState.fuenteValidada !== this.state.fuenteValidada) {
      this.updateValidation(this.state.pedido);
    }
    if (prevState.pedido !== this.state.pedido) {
      this.updateValidation(this.state.pedido);
      this.props.onChange(this.state.pedido);
    }
  };

  isValid = (pedido: Pedido) => {
    return pedido.cliente.id > -1 && this.FuenteValida(pedido.fuente);
  };

  FuenteValida(fuente: Fuente): boolean {
    return (
      ((fuente.empleado != null && fuente.empleado.id > -1) ||
        (fuente.cliente != null && fuente.cliente.id > -1) ||
        (fuente.propietario != null && fuente.propietario.id > -1)) &&
      fuente.medio_comunicacion.id > -1 &&
      fuente.medio_ingreso.id > -1
    );
  }

  updateValidation = (pedido: Pedido) => {
    const validation = this.isValid(pedido);
    this.setState({ validation: validation });
  };

  onChangeCliente = (cliente: Cliente | null) => {
    this.setState((prevState) => ({
      pedido: {
        ...prevState.pedido,
        cliente: cliente ? cliente : CrearClienteVacio(),
      },
      clienteSeleccionado: cliente,
    }));
  };

  onChangeListaPrecio = (listaPrecio: ListaPrecio | null) => {
    this.setState((prevState) => ({
      pedido: {
        ...prevState.pedido,
        listaPrecio: listaPrecio ? listaPrecio : CrearListaPrecioVacio(),
      },
      listaPrecioSeleccionado: listaPrecio,
    }));
  };

  onChangeFuente = (fuente: Fuente) => {
    this.setState((prevState) => ({
      pedido: { ...prevState.pedido, fuente: fuente },
    }));
  };

  onChangeValidationFuente = (estado: boolean) => {
    this.setState({ fuenteValidada: estado });
  };

  onChangeFactura = (factura: boolean) => {
    this.setState((prevState) => ({
      pedido: {
        ...prevState.pedido,
        factura: factura ? CrearFacturaVacio() : null,
      },
    }));
  };

  render() {
    const { clienteSeleccionado, listaPrecioSeleccionado, pasoActivo, setPasoActivo } = this.state;
    const nextStep = () =>
      this.setState({
        pasoActivo: this.state.pasoActivo < 3 ? this.state.pasoActivo + 1 : this.state.pasoActivo,
      });
    const prevStep = () =>
      this.setState({
        pasoActivo: this.state.pasoActivo > 0 ? this.state.pasoActivo - 1 : this.state.pasoActivo,
      });

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" gap="1rem">
        <Stepper active={pasoActivo} onStepClick={setPasoActivo} breakpoint="sm">
          <Stepper.Step label="Paso 1:" description="Datos de Fuente">
            <Flex flexDirection="row" alignItems="center">
              <ClientesShow
                version={VersionClientesShow.SELECT}
                propsSelect={{
                  callbackParent: this.onChangeCliente,
                  seleccionado: clienteSeleccionado,
                  placeholder: "Seleccione un cliente",
                  label: "Cliente",
                }}
              />
            </Flex>
            <Flex flexDirection="row" alignItems="center" marginTop="1rem">
              <Box padding="1rem">
                <Switch
                  label="¿Desea Seleccionar una Lista de precios?"
                  labelPosition="left"
                  checked={this.state.opcionListaPrecio}
                  onChange={(event) => {
                    this.setState({
                      opcionListaPrecio: event.currentTarget.checked,
                    });
                  }}
                />
                {this.state.opcionListaPrecio ? (
                  <ListasPreciosShow
                    version={Version.SELECT}
                    propsSelect={{
                      callbackParent: this.onChangeListaPrecio,
                      seleccionado: listaPrecioSeleccionado,
                      placeholder: "Seleccione una lista de precio",
                      label: "Lista Precio",
                    }}
                  />
                ) : null}
              </Box>
            </Flex>
            <Flex flexDirection="row" alignItems="center" marginTop="1rem">
              <Box padding="1rem">
                <FuenteLoad
                  fuente={this.state.pedido.fuente}
                  onChange={this.onChangeFuente}
                  onValidationChange={this.onChangeValidationFuente}
                />
                <Checkbox
                  onChange={(event) => this.onChangeFactura(event.currentTarget.checked)}
                  label="Con Creación de Factura"
                  checked={this.state.pedido.factura ? true : false}
                />
              </Box>
            </Flex>
          </Stepper.Step>
          <Stepper.Step
            label="Paso 2:"
            description="Contenido"
            disabled={this.state.pasoActivo == 0 && !this.state.fuenteValidada}
          >
            <Flex flexDirection="row" alignItems="center">
              <Box padding="1rem">Ventas Promociones</Box>
            </Flex>
            <Flex flexDirection="row" alignItems="center">
              <Box padding="1rem">Ventas Personalizadas</Box>
            </Flex>
            <Flex flexDirection="row" alignItems="center">
              <Box padding="1rem">Entregas</Box>
            </Flex>
          </Stepper.Step>
          <Stepper.Step label="Paso 3:" description="Programaciones" disabled={this.state.pasoActivo < 1}>
            <Flex flexDirection="row" alignItems="center">
              <Box padding="1rem">Programaciones Entregas</Box>
            </Flex>
          </Stepper.Step>
          <Stepper.Completed>
            <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
              <RequestButton
                propsBoton={{ disabled: !this.state.validation }}
                texto={this.props.tituloOnSave}
                onClick={this.props.onSave}
              />
            </Flex>
          </Stepper.Completed>
        </Stepper>

        <Group position="center" mt="xl">
          <Button variant="default" onClick={prevStep}>
            Back
          </Button>
          <Button onClick={nextStep} disabled={this.state.pasoActivo === 0 && !this.state.validation}>
            Next step
          </Button>
        </Group>
      </Flex>
    );
  }
}
