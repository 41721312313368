import React, { Component } from "react";
import { PropsSelectGeneral } from "../../../../../compartido/components/select/SelectBusqueda";
import { PropsSelectGenerico } from "../../../../../compartido/components/select/PropsSelectGenerico";
import { LIMITES } from "../../../../../compartido/components/select/SelectLimiteTabla";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import AtencionClientesShowTable from "./AtencionClientesShowTable";
import {
  CANALES_WEB_SOCKET,
  WebSocketService,
  Cliente,
  ClienteService,
} from "serviciossaintmichel";
import { Global } from "../../../../../Global";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
  version: Version;
  propsSelect?: PropsSelectGenerico<Cliente> & PropsSelectGeneral;
}

export enum Version {
  SELECT,
  TABLE,
}

interface State {
  clientes: Array<Cliente>;
}

export default class AtencionClientesShow extends Component<Props, State> {
  private clienteService: ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      clientes: [],
    };

    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);
  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES,
      funcionCallback: this.getClientes,
    });
    if (this.props.version == Version.TABLE)
      this.clienteService.paramsURL.setearLimite(LIMITES[0]);
    else this.getClientes();
  }

  getClientes = () => {
    this.clienteService
      .listAll()
      .then((result) => {
        this.setState({ clientes: result });
      })
      .catch((error) => {
        this.clienteService.manejarErrorHTTP(error, "Cliente");
      });
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems={Version.TABLE ? undefined : "center"}
        justifyContent="center"
      >
        {this.props.version == Version.SELECT && this.props.propsSelect
          ? {
              /* <AtencionClientesShowSelect
            {...this.props.propsSelect}
            callbackParent={this.props.propsSelect.callbackParent}
            seleccionado={
              this.props.propsSelect.seleccionado
                ? this.props.propsSelect.seleccionado
                : null
            }
            items={this.state.clientes}
          /> */
            }
          : null}
        {this.props.version == Version.TABLE ? (
          <AtencionClientesShowTable
            onView={this.onView}
            onUpdate={this.onUpdate}
          />
        ) : null}
      </Flex>
    );
  }
}
