import React, { Component } from "react";
import SelectGenericoNull from "../../../../../../compartido/components/select/SelectGenericoNull";
import SelectGenerico from "../../../../../../compartido/components/select/SelectGenerico";
import { TipoCliente } from "serviciossaintmichel";



interface Props {
  callbackParent: Function;
  seleccionado: TipoCliente | null;
  tiposCliente: Array<TipoCliente>;
  opcionNull?: boolean;
}
interface State {
  seleccionado: TipoCliente | null;
}

export default class SelectTipoCliente extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado,
    };
  }

  getPosicion = (valor: TipoCliente, tiposCliente: Array<TipoCliente>): number => {
    return tiposCliente.findIndex((i) => i.id === valor.id);
  };

  getSeleccionado = (indice: number) => {
    const { tiposCliente } = this.props;
    if (indice >= 0) this.props.callbackParent(tiposCliente[indice]);
    else this.props.callbackParent(null);
  };

  render() {
    const { seleccionado, tiposCliente, opcionNull = true } = this.props;
    const posicionSeleccionado = seleccionado ? this.getPosicion(seleccionado, tiposCliente) : null;
    const elementos = tiposCliente.map((tc) => tc.tipo);
    return posicionSeleccionado !== null && !opcionNull ? (
      <SelectGenerico seleccionado={posicionSeleccionado} elementos={elementos} callback={this.getSeleccionado} />
    ) : (
      <SelectGenericoNull seleccionado={posicionSeleccionado} elementos={elementos} callback={this.getSeleccionado} />
    );
  }
}
