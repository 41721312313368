import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import H from "../../../../../libreria/message/title/H";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Swal from "sweetalert2";
import Habilitador from "../../../../../libreria/action/habilitador/Habilitador";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import * as Icon from "react-bootstrap-icons";
import SelectDia from "../../../../../compartido/components/franjahoraria/SelectDia";
import { Table } from "react-bootstrap";

import { CANALES_WEB_SOCKET,                DatosReparto,                    Dia,                    RecorridoReparto,                    RepartoService,    WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";


export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  idReparto: number;
}

interface State {
  recorridos: Array<RecorridoReparto>;
  cargando: boolean;
  screen: ScreenType;
}

interface Message {
  type: string;
  idReparto: number;
  idRecorrido: number;
}

export default class RecorridosRepartoUpdate extends Component<Props, State> {

  private repartoService:RepartoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
      recorridos: [],
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.repartoService =  new RepartoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    const recorridos = await this.repartoService.getRecorridosReparto(
      this.props.idReparto
    );
    let recorridosOrdenadosId = recorridos
      ? recorridos.sort((a, b) => a.recorrido.id - b.recorrido.id)
      : [];
    this.setState({
      recorridos: this.getCopy(recorridosOrdenadosId),
      cargando: false,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS,
      funcionCallback: this.onChannelMesagge,
    });
  };

  getCopy = (recorridos: Array<RecorridoReparto>): Array<RecorridoReparto> => {
    let recorridosCopy: Array<RecorridoReparto> = [];
    recorridos.map((x) =>
      recorridosCopy.push({
        reparto: x.reparto,
        recorrido: x.recorrido,
        dia_reparto: x.dia_reparto,
      })
    );
    return recorridosCopy;
  };

  onChannelMesagge = async (e: MessageEvent) => {
    let message: Message = JSON.parse(e.data).message;
    if (message.idReparto == this.props.idReparto) {
      const recorridos = await this.repartoService.getRecorridosReparto(
        this.props.idReparto
      );
      let recorridosOrdenadosId = recorridos
        ? recorridos.sort((a, b) => a.recorrido.id - b.recorrido.id)
        : [];
      this.setState({
        recorridos: this.getCopy(recorridosOrdenadosId),
        cargando: false,
      });
    }
  };

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
    }
  };

  eliminarRecorrido = async (id: number) => {
    try {
      await Swal.fire({
        icon: "question",
        title: "¿Está seguro en eliminar un recorrido ?",
        showDenyButton: true,
        confirmButtonText: "Confirmar",
        denyButtonText: "Cancelar",
      }).then(async (result) => {
        if (!result.isDenied) {
          let response = await this.repartoService.deleteRecorridoReparto(id);
          WebSocketService.enviarMensaje(
            CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS,
            {
              type: "update",
              idReparto: this.props.idReparto,
            }
          );
          WebSocketService.enviarMensaje(
            CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS,
            {
              type: "update",
              idRecorrido: id,
            }
          );

          const recorridos = this.state.recorridos.filter(
            (x) => x.recorrido.id != id
          );
          this.setState({
            recorridos: this.getCopy(recorridos),
            cargando: false,
          });

          await Swal.fire({
            icon: "success",
            text: "Recorrido Eliminado",
          });
        }
      });
    } catch (error) {
      this.repartoService.manejarErrorHTTP(
        error,
        "Eliminación de Recorrido Reparto"
      );
    }
  };

  actualizarRecorrido = async (id: number) => {
    try {
      await this.repartoService.putRecorridoReparto(
        this.props.idReparto,
        id,
        this.state.recorridos.filter((x) => x.recorrido.id == id)[0].dia_reparto
      );
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS, {
        type: "update",
        idReparto: this.props.idReparto,
      });
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS,
        {
          type: "update",
          idRecorrido: id,
        }
      );
      await Swal.fire({
        icon: "success",
        text: "Recorrido Actualizado",
      });
    } catch (error) {
      this.repartoService.manejarErrorHTTP(error, "Actualización Recorrido Reparto");
    }
  };

  onChange = (id: number, dia_reparto: Dia) => {
    let recorridosNew = this.state.recorridos;
    try {
      recorridosNew.filter(
        (x: RecorridoReparto) => x.recorrido.id == id
      )[0].dia_reparto = dia_reparto;
      this.setState({ recorridos: recorridosNew });
    } catch (e) {}
  };

  renderizarFilas = () => {
    return this.state.recorridos.map((recorrido, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{recorrido.recorrido.nombre}</td>
          <td className="text-center">
            <Habilitador>
              <SelectDia
                seleccionado={recorrido.dia_reparto}
                onChangeDia={(dia_reparto: Dia) =>
                  this.onChange(recorrido.recorrido.id, dia_reparto)
                }
              />
            </Habilitador>
          </td>
          <td className="text-center">
            <RequestButton
              texto="Actualizar"
              propsBoton={{ variant: "light" }}
              icon={<Icon.ArrowRepeat />}
              onClick={() => this.actualizarRecorrido(recorrido.recorrido.id)}
            />
          </td>
          <td className="text-center">
            <RequestButton
              texto="Eliminar"
              propsBoton={{ variant: "light" }}
              icon={<Icon.ArrowRepeat />}
              onClick={() => this.eliminarRecorrido(recorrido.recorrido.id)}
            />
          </td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Recorrido</th>
            <th className="text-center">Dia Reparto</th>
            <th className="text-center">Actualizar</th>
            <th className="text-center">Eliminar</th>
          </tr>
        </thead>

        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={4} texto={"Recorridos Actuales"} />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="15px"
        >
          {this.renderizarTabla()}
        </Flex>
      </Flex>
    );
  }
}
