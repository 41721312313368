import React, { Component } from "react";
import { PersonaNueva } from "serviciossaintmichel";
import FormularioPersona from "../../../../../compartido/components/formularios/FormularioPersona";

export interface Props {
  persona: PersonaNueva;
  onChangePersona: Function;
  onChangeValidation: Function;
}

interface State {}

export default class PasoDatosPersona extends Component<Props, State> {
  render() {
    const { persona, onChangePersona, onChangeValidation } = this.props;

    return (
      <FormularioPersona
        persona={{ ...persona, id: -1, fecha_nacimiento: persona.fecha_nacimiento ?? new Date() }}
        onUpdateValidezCallback={onChangeValidation}
        onUpdatePersonaCallback={onChangePersona}
        showSubmitButton={false}
      />
    );
  }
}
