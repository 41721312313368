import React, { Component } from "react";
import { Banco, Cheque } from "serviciossaintmichel";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../../libreria/data/input/Input";
import BancosShow, { Version } from "../../../bancos/components/show/BancosShow";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  cheque: Cheque;
}
interface State {
  cheque: Cheque;
  validation: boolean;
}

export default class ChequeLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cheque: this.props.cheque,
      validation: false,
    };
  }

  componentDidMount = () => {
    this.updateValidation(this.state.cheque);
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps !== this.props) {
      this.setState({ cheque: this.props.cheque });
    }
  };

  isValid = (cheque: Cheque) => {
    return cheque.numero > 0 && cheque.fecha_emision && cheque.banco.id > 0 ? true : false;
  };

  updateValidation = (cheque: Cheque) => {
    let validation: boolean = this.isValid(cheque);
    this.setState({ validation: validation });
    if (this.props.onValidationChange) this.props.onValidationChange(validation);
  };

  setearNumero = (numero: number) => {
    let cheque = this.state.cheque;
    cheque.numero = numero;
    this.setState({ cheque: cheque });
    this.props.onChange(cheque);
    this.updateValidation(cheque);
  };

  setearFechaEmision = (fechaEmision: Date) => {
    let cheque = this.state.cheque;
    cheque.fecha_emision = fechaEmision;
    this.setState({ cheque: cheque });
    this.props.onChange(cheque);
    this.updateValidation(cheque);
  };

  onChangeBanco = (x: Banco | null) => {
    let cheque = this.state.cheque;
    cheque.banco.id = x ? x.id : -1;
    this.setState({ cheque: cheque });
    this.props.onChange(cheque);
    this.updateValidation(cheque);
  };

  render() {
    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Input
          data={this.state.cheque.numero}
          label={"Número"}
          placeholder={"Número"}
          onChange={this.setearNumero}
          type={InputTypes.Number}
        />
        <Input
          data={this.state.cheque.fecha_emision}
          label={"Fecha de emisión"}
          placeholder={"Fecha de emisión"}
          onChange={this.setearFechaEmision}
          type={InputTypes.Date}
        />
        <BancosShow
          version={Version.SELECT}
          propsSelect={{
            seleccionado: this.state.cheque.banco,
            callbackParent: this.onChangeBanco,
            sinTitulo: true,
          }}
        />
      </Flex>
    );
  }
}
