import React, { Component } from "react";
import Swal from "sweetalert2";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import Screen, { ScreenType } from "../../../../../../../compartido/models/Screen";
import RequestButton from "../../../../../../../libreria/action/request-button/RequestButton";
import Box from "../../../../../../../libreria/containers/box/Box";
import H from "../../../../../../../libreria/message/title/H";
import TelefonoClienteLoad from "./TelefonoClienteLoad";
import { CANALES_WEB_SOCKET, ClienteService, Telefono, TelefonoLectura, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../../Global";



export interface Props {
  clienteId: number;
  telefonoId: number;
  onCloseCallback?: Function;
}

interface State {
  telefono: TelefonoLectura | null;
  screen: ScreenType;
  valid: boolean;
}

export default class TelefonoClienteUpdate extends Component<Props, State> {
  
  private clienteService:ClienteService;

  
  constructor(props: Props) {
    super(props);
    this.state = {
      telefono: null,
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);


  }

  componentDidMount = () => {
    window.addEventListener("resize", this.actualizarDimensiones);
    this.getTelefono();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevProps.telefonoId !== this.props.telefonoId) {
      this.getTelefono();
    }
  };

  getTelefono = async () => {
    const { clienteId, telefonoId } = this.props;
    const tel = await this.clienteService.getTelefono(clienteId, telefonoId);
    this.setState({ telefono: tel });
  };

  actualizarDimensiones = () => {
    this.setState({ screen: Screen.getType(window.innerWidth, window.innerHeight) });
  };

  onSave = async (telefono: TelefonoLectura) => {
    try {
      const payload: Telefono = { ...telefono, tipo_telefono: telefono.tipo_telefono.id };
      const resultado = await this.clienteService.actualizarTelefono(this.props.clienteId, telefono.id, payload);

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_TELEFONOS, {
        id: resultado.id,
        clienteId: resultado.cliente,
      });

      await Swal.fire({
        icon: "success",
        text: "Teléfono Actualizado",
      });
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error, "Teléfono");
    }
  };

  onChange = (telefono: TelefonoLectura) => {
    this.setState({ telefono: telefono });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex flexDirection="column" width="60%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex flexDirection="column" width="50%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  onClose = () => {
    if (this.props.onCloseCallback) {
      this.props.onCloseCallback();
    }
  };

  renderizar = () => {
    const { telefono, valid } = this.state;
    const { clienteId } = this.props;
    return (
      <Box paddingVertical={"2rem"} paddingHorizontal={"2rem"} showCloseBtn={true} onCloseCallback={this.onClose}>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
            <H size={3} texto={"Actualizar teléfono"} />
          </Flex>
          <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
            <TelefonoClienteLoad
              telefono={telefono}
              clienteId={clienteId}
              onChange={this.onChange}
              onChangeValidation={this.onValidationChange}
            />
          </Flex>
          <Flex
            container
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
            marginTop="20px"
          >
            <RequestButton
              confirmacion={true}
              propsBoton={{ disabled: !valid }}
              texto={"Guardar cambios"}
              onClick={() => {
                if (telefono !== null) this.onSave(telefono);
              }}
            />
          </Flex>
        </Flex>
      </Box>
    );
  };

  render() {
    const { telefono } = this.state;

    if (telefono === null) {
      return null;
    }

    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.screen === ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen === ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen === ScreenType.TABLET ? this.renderizarTablet() : null}
      </Flex>
    );
  }
}
