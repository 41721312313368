import React, { Component, FormEvent } from "react";
import * as Icon from "react-bootstrap-icons";

import { Button, Collapse, NumberInput, Select, TextInput, Flex } from "@mantine/core";
import DatePicker from "react-datepicker";
import RequestButton from "../../../libreria/action/request-button/RequestButton";
import { FiltrosFacturas } from "./FacturasShow";
import {
  EstadoCobroFactura,
  EstadoFacturaAFIP,
  FuenteFactura,
  TipoFactura,
} from "serviciossaintmichel/lib/apisaintmichel/ventas/facturas/models/Factura";
import RepartosShow, { Version } from "../../../distribucion/repartos/components/show/RepartosShow";
import { Cartera, Reparto } from "serviciossaintmichel";
import CarterasShow from "../../../compartido/components/carteras/CarterasShow";

export interface Props {
  busquedaHandler: Function;
  setFiltros: Function;
  filtros: FiltrosFacturas;
}

interface State {
  filtrosAdicionales: boolean;
}

export default class FacturasFilter extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filtrosAdicionales: false,
    };
  }

  submitHandler = (event: FormEvent<HTMLFormElement>) => {
    this.props.busquedaHandler();
    event.preventDefault();
  };

  render = () => {
    const { filtrosAdicionales } = this.state;
    const { filtros } = this.props;

    return (
      <Flex
        direction="column"
        rowGap="1rem"
        style={{
          padding: "1rem",
          backgroundColor: "gainsboro",
          borderRadius: "5px",
          marginBottom: "1rem",
        }}
      >
        <Flex justify="space-between" gap="1rem" align="center" wrap="wrap">
          <Flex justify="center" gap="1rem" align="center" wrap="wrap">
            <Flex gap="0.5rem">
              <div>Desde</div>
              <DatePicker
                selected={filtros.desdeFechaCreacion}
                onChange={(fecha) => this.props.setFiltros({ desdeFechaCreacion: fecha })}
                selectsStart
                startDate={filtros.desdeFechaCreacion}
                endDate={filtros.hastaFechaCreacion}
                dateFormat="dd/MM/yyyy"
              />
            </Flex>

            <Flex gap="0.5rem">
              <div>Hasta</div>
              <DatePicker
                selected={filtros.hastaFechaCreacion}
                onChange={(fecha) => this.props.setFiltros({ hastaFechaCreacion: fecha })}
                selectsEnd
                startDate={filtros.desdeFechaCreacion}
                endDate={filtros.hastaFechaCreacion}
                minDate={filtros.desdeFechaCreacion}
                dateFormat="dd/MM/yyyy"
              />
            </Flex>

            <RequestButton
              onClick={() => {
                this.props.busquedaHandler();
              }}
              texto="Buscar"
              icon={<Icon.Search />}
            />
          </Flex>
          <Flex justify="center">
            <Button
              onClick={() => {
                this.setState((prevState: State) => ({ filtrosAdicionales: !prevState.filtrosAdicionales }));
              }}
            >
              {filtrosAdicionales ? "Ocultar filtros adicionales" : "Mostrar filtros adicionales"}
            </Button>
          </Flex>
        </Flex>
        <Collapse in={filtrosAdicionales}>
          <Flex gap="1rem" align="center" wrap={"wrap"}>
            <NumberInput
              placeholder="Id de factura"
              label="Id factura"
              value={filtros.facturaId}
              onChange={(facturaId: number) => this.props.setFiltros({ facturaId: facturaId ?? null })}
              hideControls
            />
            <Select
              label="Tipo de factura"
              placeholder="Seleccione tipo"
              data={Object.values(TipoFactura).map((tipo) => ({ value: tipo, label: tipo }))}
              value={filtros.tipo}
              onChange={(tipo: string) => this.props.setFiltros({ tipo: tipo })}
              clearable
            />

            <NumberInput
              placeholder="Id de cliente"
              label="Id cliente"
              value={filtros.clienteId}
              onChange={(clienteId: number) => this.props.setFiltros({ clienteId: clienteId })}
              hideControls
            />

            <TextInput
              label="Razón social"
              placeholder="Razón social"
              value={filtros.razonSocial}
              onChange={(event) => this.props.setFiltros({ razonSocial: event.currentTarget.value })}
            />

            <NumberInput
              placeholder="Número de factura"
              label="Número de factura"
              value={filtros.numeroFactura}
              onChange={(numeroFactura: number) => this.props.setFiltros({ numeroFactura: numeroFactura })}
              hideControls
            />

            <Select
              label="Estado en AFIP"
              placeholder="Seleccione estado"
              data={Object.values(EstadoFacturaAFIP).map((estado) => ({ value: estado, label: estado }))}
              value={filtros.estadoAFIP}
              onChange={(estadoAFIP: string) => this.props.setFiltros({ estadoAFIP: estadoAFIP })}
              clearable
            />

            <Select
              label="Estado del cobro"
              placeholder="Seleccione estado"
              data={Object.values(EstadoCobroFactura).map((estado) => ({ value: estado, label: estado }))}
              value={filtros.estadoAFIP}
              onChange={(estadoCobro: string) => this.props.setFiltros({ estadoCobro: estadoCobro })}
              clearable
            />

            <Select
              label="Fuente de la factura"
              placeholder="Seleccione fuente"
              data={Object.values(FuenteFactura).map((fuente) => ({ value: fuente, label: fuente }))}
              value={filtros.fuente}
              onChange={(fuente: string) => this.props.setFiltros({ fuente: fuente })}
              clearable
            />
            <div>
              <RepartosShow
                version={Version.SELECT}
                propsSelect={{
                  callbackParent: (reparto: Reparto | null) => {
                    this.props.setFiltros({ repartoId: reparto?.id });
                  },
                  seleccionado: this.props.filtros.repartoId ?? null,
                  clearable: true,
                  label: "Reparto",
                  placeholder: "Seleccione un reparto",
                }}
              />
            </div>
            <div>
              <CarterasShow
                version="select"
                propsSelect={{
                  callbackParent: (cartera: Cartera | null) => {
                    this.props.setFiltros({ carteraId: cartera ? cartera.id : null });
                  },
                  seleccionado: this.props.filtros.carteraId ?? null,
                  clearable: true,
                  label: "Cartera",
                  placeholder: "Seleccione una cartera",
                }}
              />
            </div>
          </Flex>
        </Collapse>
      </Flex>
    );
  };
}
