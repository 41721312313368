import _ from "lodash";
import React, { Component } from "react";
import { CANALES_WEB_SOCKET, WebSocketService,MaquinaServicioSimbolicaGet as MaquinaSimbolicaGet ,MaquinaServicioSimbolicaService as MaquinaSimbolicaService} from "serviciossaintmichel";
import { PropsSelectGenerico } from "../../../../../compartido/components/select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../../../../../compartido/components/select/SelectBusqueda";
import SelectComponent from "../../../../../compartido/components/select/SelectComponent";
import { Global } from "../../../../../Global";








export interface Props {
  version: Version;
  propsSelect?: PropsSelectGenerico<MaquinaSimbolicaGet> & PropsSelectGeneral;
  filtrarIds?: Array<number>;
  excluirIds?: Array<number>;
}

export enum Version {
  SELECT,
}

interface State {
  maquinas: Array<MaquinaSimbolicaGet> | null;
}

export default class MaquinasSimbolicasShow extends Component<Props, State> {

  private maquinaSimbolicaService:MaquinaSimbolicaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      maquinas: null,
    };

    this.maquinaSimbolicaService = new MaquinaSimbolicaService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_SIMBOLICA,
      funcionCallback: this.onChannelMesagge,
    });
    await this.setData();
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      !_.isEqual(prevProps.filtrarIds, this.props.filtrarIds) ||
      !_.isEqual(prevProps.excluirIds, this.props.excluirIds)
    ) {
      this.setData();
    }
  }

  onChannelMesagge = async (message: MessageEvent) => {
    await this.setData();
  };

  async setData() {
    const { filtrarIds, excluirIds } = this.props;
    let maquinas = await this.maquinaSimbolicaService.listAll();

    if (filtrarIds) {
      maquinas = maquinas.filter((maq) => filtrarIds.indexOf(maq.id) !== -1);
    }

    if (excluirIds) {
      maquinas = maquinas.filter((maq) => excluirIds.indexOf(maq.id) === -1);
    }

    this.setState({ maquinas: maquinas });
  }

  render() {
    const { version, propsSelect } = this.props;
    const { maquinas } = this.state;

    if (maquinas === null) {
      return <div>Cargando...</div>;
    }

    switch (version) {
      case Version.SELECT: {
        if (!propsSelect) {
          return null;
        }

        return (
          <SelectComponent
            {...propsSelect}
            items={maquinas}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado}
            labelGetter={(obj: MaquinaSimbolicaGet) => obj.nombre}
          />
        );
      }
      default:
        return null;
    }
  }
}
