import React, { Component } from "react";
import SelectGenerico from "./SelectGenerico";

interface Props {
  callbackParent: Function;
}

export const LIMITES = [6, 8, 10, 1000, 0];

export default class SelectLimiteTabla extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  getSeleccionado = (indice: number) => {
    this.props.callbackParent(LIMITES[indice]);
  };

  render() {
    return (
      <div style={{ flex: 0.3 }}>
        <SelectGenerico
          titulo="Incremento de resultados:"
          seleccionado={0}
          elementos={LIMITES.map((e) => (e === 0 ? "Todos" : e.toString()))}
          callback={this.getSeleccionado}
        />
      </div>
    );
  }
}
