import fondoOP1 from "../components/background/images/OP1_1920X1080.png";
import fondoOP3 from "../components/background/images/OP3_1920X1080.png";

class ImagenesURLs {
  urlFondoModal(): string {
    return this.urlFondoOP3();
  }
  urlFondoOP1(): string {
    return `url(${fondoOP1})`;
  }
  urlFondoOP3(): string {
    return `url(${fondoOP3})`;
  }
}
export default new ImagenesURLs();
