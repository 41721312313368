import React, { Component } from "react";
import { Button } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import * as Icon from "react-bootstrap-icons";
import { MaquinaServicioSimbolicaGet, MaquinaServicioSimbolicaService, OrdenCompraMaquinaSimbolica } from "serviciossaintmichel";
import { Global } from "../../../../../../../../Global";




export interface Props {
  maquinasOrdenCompra: Array<OrdenCompraMaquinaSimbolica>;
  showDelete?: boolean;
  deleteHandler?: Function;
}

interface State {
  maquinasInfo: Map<number, MaquinaServicioSimbolicaGet> | null;
}

export default class MaquinasSimbolicasOrdenCompraShow extends Component<
  Props,
  State
> {

  private maquinaServicioSimbolicaService:MaquinaServicioSimbolicaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      maquinasInfo: null,
    };

    this.maquinaServicioSimbolicaService = new MaquinaServicioSimbolicaService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    let maquinas = await this.maquinaServicioSimbolicaService.listAll();
    const maquinaMap = new Map();
    for (let maquina of maquinas) {
      maquinaMap.set(maquina.id, maquina);
    }
    this.setState({ maquinasInfo: maquinaMap });
  };

  handleOnDelete = (idx: number) => {
    if (this.props.deleteHandler) {
      this.props.deleteHandler(idx);
    }
  };

  render = () => {
    const { maquinasOrdenCompra } = this.props;
    const { maquinasInfo } = this.state;

    if (maquinasInfo === null) {
      return null;
    }

    return (
      <ListGroup as="ol">
        {maquinasOrdenCompra.map((moc, idx) => (
          <ListGroup.Item
            as="li"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div>{maquinasInfo.get(moc.maquina_simbolica)?.nombre}</div>
              <div>{`Cantidad: ${moc.cantidad}`}</div>
            </div>
            {this.props.showDelete && (
              <Button
                variant="danger"
                onClick={() => {
                  this.handleOnDelete(idx);
                }}
              >
                <Icon.Trash />
              </Button>
            )}
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  };
}
