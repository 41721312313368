import React, { Component } from "react";
import { CANALES_WEB_SOCKET, Recorrido, RecorridoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../libreria/appearance/margin/Margin";
import { Box } from "../../../../libreria/containers/box/Box";
import H from "../../../../libreria/message/title/H";
import DatosRecorridoShow from "./datos/DatosRecorridoShow";
import DireccionesRecorridoShow from "./direcciones/DireccionesRecorridoShow";
import RepartoRecorridoShow from "./reparto/RepartoRecorridoShow";



export interface Props {
  idRecorrido: number;
}

interface State {
  recorrido: Recorrido | null;
  cargando: boolean;
}

export default class RecorridoShow extends Component<Props, State> {

  private recorridoService:RecorridoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      recorrido: null,
      cargando: true,
    };

    this.recorridoService =  new RecorridoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    await this.cargarRecorrido();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS,
      funcionCallback: this.onChannelMesagge,
    });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.recorridoId == this.props.idRecorrido) {
      await this.cargarRecorrido();
    }
  };

  cargarRecorrido = async () => {
    let recorrido = await this.recorridoService.get(this.props.idRecorrido).catch(
      (error) => {
        this.recorridoService.manejarErrorHTTP(error, "Recorrido");
        return null;
      }
    );
    this.setState({ recorrido: recorrido, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      await this.setState({ cargando: true });
      await this.cargarRecorrido();
    }
  };

  renderRecorrido = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <H alineacion={"center"} size={3} texto={this.state.recorrido!.nombre} />
      <Margin top="30px" />
      <DatosRecorridoShow idRecorrido={this.state.recorrido!.id} />
      <Margin top="25px" />
      {<RepartoRecorridoShow idRecorrido={this.state.recorrido!.id} />}
      <Margin top="25px" />
      {<DireccionesRecorridoShow idRecorrido={this.state.recorrido!.id} />}
      <Margin bottom="50px" />
    </Flex>
  );
  renderLoading = () => "Cargando Datos";

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Margin top="20px" />
        <Box padding="15px">
          {this.state.cargando ? this.renderLoading() : this.renderRecorrido()}
        </Box>
      </Flex>
    );
  }
}
