import React, { Component } from "react";
import Ruta from "../../compartido/models/Ruta";
import Descuentos from "./descuentos/Descuentos";
import Seccion from "../../compartido/components/seccion/Seccion";
import { Global } from "../../Global";

export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];
  if (Global.UsuarioService.getPermisos().Administracion.Descuentos.Descuento.hasAccess()) {
    aux.push({
      name: "Descuentos",
      path: SeccionDescuentos.pathBase + "/descuentos",
      component: Descuentos,
    });
  }

  return aux;
}

export default class SeccionDescuentos extends Component<Props> {
  static pathBase = "/administracion/seccion-descuentos";

  render() {
    return <Seccion rutasNavbar={getRutasAccesibles()} tituloNavbar={"Descuentos"} />;
  }
}
