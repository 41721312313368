import React, { Component } from "react";
import Flex from "../../../../../compartido/components/flex/Flex";

import { Fuentes } from "../../../../../compartido/estilos/estilos";
import H from "../../../../../compartido/components/titulos/H";

import "react-datepicker/dist/react-datepicker.css";
import ComponenteFranjaHoraria from "../../../../../compartido/components/franjahoraria/ComponenteFranjaHoraria";
import ListBox from "../../../../../compartido/components/listbox/ListBox";
import { ItemListBox } from "../../../../../compartido/components/listbox/Item";
import { FranjaHoraria } from "serviciossaintmichel";

export interface Props {
  franjas: Array<FranjaHoraria>;
  onChangeFranjas: Function;
  onChangeValidation: Function;
}

interface State {
  pasoValidado: boolean;
  nfranjascreadas: number;
}

export default class PasoFranjasHorarias extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      pasoValidado: this.isPasoValidado(this.props.franjas),
      nfranjascreadas: this.props.franjas.length,
    };

    this.props.onChangeValidation(this.state.pasoValidado);
    this.listBoxFranjas = React.createRef();
    this.componenteFranjaHoraria = React.createRef();
  }

  listBoxFranjas: any;
  componenteFranjaHoraria: any;

  isPasoValidado = (franjas: Array<FranjaHoraria>): boolean => {
    return franjas.length > 0 ? true : false;
  };

  updateValidacion = (numeroFranjas: number) => {
    let valido: boolean = numeroFranjas > 0 ? true : false;
    this.setState({ pasoValidado: valido });
    this.props.onChangeValidation(valido);
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.setState({
        //Cliente: this.props.Cliente,
      });
    }
    if (prevState.nfranjascreadas !== this.state.nfranjascreadas) {
      this.updateValidacion(this.state.nfranjascreadas);
    }
  }

  existeFranja = (franja: FranjaHoraria): boolean => {
    let existe: boolean = false;
    let aux: Array<FranjaHoraria> = this.listBoxFranjas.current.getLista();
    aux.map((x: FranjaHoraria) => {
      if (
        x.inicio.getHours() == franja.inicio.getHours() &&
        x.inicio.getMinutes() == franja.inicio.getMinutes()
      )
        if (
          x.fin.getHours() == franja.fin.getHours() &&
          x.fin.getMinutes() == franja.fin.getMinutes()
        )
          if (x.dia == franja.dia) existe = true;
    });
    return existe;
  };

  agregarFranja = (franja: FranjaHoraria) => {
    if (!this.existeFranja(franja)) {
      let nfranjascreadas: number = this.state.nfranjascreadas + 1;
      let item: ItemListBox<FranjaHoraria> = this.getItem(
        nfranjascreadas,
        franja
      );
      this.listBoxFranjas.current.agregar(item);
      this.setState({ nfranjascreadas: nfranjascreadas });
      this.props.onChangeFranjas(this.listBoxFranjas.current.getLista());
      this.componenteFranjaHoraria.current.newFranja();
    }
  };

  getItems = (): Array<ItemListBox<FranjaHoraria>> => {
    let items: Array<ItemListBox<FranjaHoraria>> = [];
    this.props.franjas.map((franja, index) =>
      items.push(this.getItem(index + 1, franja))
    );
    return items;
  };

  getItem = (id: number, franja: FranjaHoraria): ItemListBox<FranjaHoraria> => {
    let item: ItemListBox<FranjaHoraria> = {
      objeto: franja,
      id: id,
      texto:
        franja.dia +
        " " +
        franja.inicio.toLocaleTimeString() +
        " - " +
        franja.fin.toLocaleTimeString(),
      estado: false,
    };
    return item;
  };

  onDeleteFinish = () => {
    this.setState({ nfranjascreadas: this.state.nfranjascreadas - 1 });
  };

  onChangeFranjas = (franjas: Array<FranjaHoraria>) => {
    this.props.onChangeFranjas(franjas);
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        padding="30px"
        marginBottom="20px"
      >
        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <H size={3} fuente={Fuentes.Titulos} texto="Franjas Horarias" />
        </Flex>

        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Flex
            container
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            width="100%"
          >
            <H size={5} fuente={Fuentes.Titulos} texto="Datos Nueva Franja" />
          </Flex>

          <Flex
            container
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            width="100%"
            marginTop="15px"
          >
            <Flex
              container
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="50%"
            >
              <ComponenteFranjaHoraria
                ref={this.componenteFranjaHoraria}
                accion
                onClickAccion={this.agregarFranja}
                textoAccion="Agregar"
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Flex
            container
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
          >
            <H size={5} fuente={Fuentes.Titulos} texto="Franjas Creadas" />

            <ListBox<FranjaHoraria>
              onChangeList={this.onChangeFranjas}
              ref={this.listBoxFranjas}
              items={this.getItems()}
              tipoItem={{
                view: false,
                deleteSimple: true,
                deleteRequest: false,
                select: false,
              }}
              onDeleteFinish={this.onDeleteFinish}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  }
}
