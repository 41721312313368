import React, { Component } from "react";
import { RolEmpleado } from "serviciossaintmichel";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Box from "../../../../libreria/containers/box/Box";
import RolEmpleadoLoad from "./RolEmpleadoLoad";
import RolesEmpleadosShow from "./RolesEmpleadosShow";

export interface Props {
  onChange: Function;
  rolesEmpleados?: Array<RolEmpleado>;
}

interface State {
  rolesEmpleados: Array<RolEmpleado>;
  validation: boolean;
}

export default class RolesEmpleadosLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      rolesEmpleados: props.rolesEmpleados || [],
      validation: false,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.rolesEmpleados && prevProps.rolesEmpleados !== this.props.rolesEmpleados) {
      this.setState({ rolesEmpleados: this.props.rolesEmpleados });
    }
    if (JSON.stringify(prevState.rolesEmpleados) !== JSON.stringify(this.state.rolesEmpleados)) {
      this.props.onChange(this.state.rolesEmpleados);
    }
  }

  isValid = (rolEmpleado: RolEmpleado) => {
    return !!(rolEmpleado.empleado > 0 && rolEmpleado.rol);
  };

  updateValidation = (rolEmpleado: RolEmpleado) => {
    const validation = this.isValid(rolEmpleado);
    this.setState({ validation: validation });
  };

  agregarRolEmpleado = (rolEmpleado: RolEmpleado) => {
    this.setState((prevState) => ({ rolesEmpleados: [...prevState.rolesEmpleados, rolEmpleado] }));
  };

  quitarRolEmpleado = (rolEmpleado: RolEmpleado) => {
    const rolesEmpleados = this.state.rolesEmpleados.filter((re) => re.empleado !== rolEmpleado.empleado);
    this.setState({ rolesEmpleados: rolesEmpleados });
  };

  render() {
    const { rolesEmpleados } = this.state;

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" gap="1rem">
        <Flex flexDirection="row" alignItems="center">
          <RolesEmpleadosShow rolesEmpleados={rolesEmpleados} showDelete onDelete={this.quitarRolEmpleado} />
        </Flex>
        <Box padding="1rem">
          <RolEmpleadoLoad
            onSubmit={this.agregarRolEmpleado}
            excluirEmpleados={rolesEmpleados.map((re) => re.empleado)}
          />
        </Box>
      </Flex>
    );
  }
}
