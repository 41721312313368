import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";



import { CANALES_WEB_SOCKET, MaquinaServicioSimbolicaGet, MaquinaServicioSimbolicaPost, MaquinaServicioSimbolicaService, ProductoGet, ProductoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";

export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  maquinasServicioSimbolica: Array<MaquinaServicioSimbolicaGet>;
}

export default class MaquinaServicioSimbolicaLista extends Component<
  Props,
  State
> {

  private maquinaServicioSimbolicaService:MaquinaServicioSimbolicaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      maquinasServicioSimbolica: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_SIMBOLICA,
      funcionCallback: this.getElementos,
    });

    this.maquinaServicioSimbolicaService = new MaquinaServicioSimbolicaService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.maquinaServicioSimbolicaService.list()
      .then((result) => {
        this.setState({ maquinasServicioSimbolica: result });
      })
      .catch((error) => {
        this.maquinaServicioSimbolicaService.manejarErrorHTTP(
          error,
          "Máquina Servicio Simbólica"
        );
      });
  };

  componentDidMount() {
    this.maquinaServicioSimbolicaService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }
  armarFilas(
    maquinasServicioSimbolica: Array<MaquinaServicioSimbolicaGet>
  ): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (maquinasServicioSimbolica.length > 0) {
      for (let i = 0; i < maquinasServicioSimbolica.length; i++) {
        let fila: Array<string> = [];
        fila.push(maquinasServicioSimbolica[i].nombre);
        let filaResult: FilaTablaGenerica = {
          id: maquinasServicioSimbolica[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Elementos.Maquinas.MaquinaServicioSimbolica.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Elementos.Maquinas.MaquinaServicioSimbolica.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.maquinaServicioSimbolicaService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.maquinasServicioSimbolica)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.maquinaServicioSimbolicaService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaMaquinasServicioSimbolica"
      />
    );
  }
}
