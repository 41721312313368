import _ from "lodash";
import React, { Component } from "react";

import { CANALES_WEB_SOCKET, CriterioOrden, Descuento, DescuentoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";
import DescuentosTable from "./DescuentosTable";
import DescuentosFilter from "./DescuentosFilter";
import Swal from "sweetalert2";
import SelectComponent from "../../../../../compartido/components/select/SelectComponent";
import { PropsSelectGenerico } from "../../../../../compartido/components/select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../../../../../compartido/components/select/SelectBusqueda";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
  filtros?: FiltrosDescuentos;
  version: VersionDescuentosShow;
  propsSelect?: PropsSelectGenerico<Descuento> & PropsSelectGeneral;
}

export enum VersionDescuentosShow {
  TABLE,
  SELECT,
}

interface FiltrosDescuentos {
  nombre: string | null;
  criterioOrden: CriterioOrden | null;
  limite: number;
}

interface State extends FiltrosDescuentos {
  descuentos: Array<Descuento> | null;
}

const ordenInicial: CriterioOrden = { campo: "id", modo: "ASC" };
const PASO_LIMITE = 5;

export default class DescuentosShow extends Component<Props, State> {
  private webSocket: WebSocket | null;

  private descuentoService: DescuentoService;

  constructor(props: Props) {
    super(props);

    this.state = {
      descuentos: null,
      nombre: props.filtros ? props.filtros.nombre : null,
      criterioOrden: props.filtros ? props.filtros.criterioOrden : ordenInicial,
      limite: props.filtros ? props.filtros.limite : PASO_LIMITE,
    };

    this.webSocket = null;
    this.descuentoService = new DescuentoService(Global.UsuarioService.getToken()!);
  }

  componentWillUnmount = () => {
    this.webSocket?.close();
  };

  componentDidMount = () => {
    this.setDescuentos();
    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_DESCUENTOS,
      funcionCallback: this.setDescuentos,
    });
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevState.criterioOrden !== this.state.criterioOrden || prevState.limite !== this.state.limite) {
      this.setDescuentos();
    }

    if (!_.isEqual(prevProps.filtros, this.props.filtros)) {
      const { filtros } = this.props;
      this.setState({
        nombre: filtros ? filtros.nombre : null,
        criterioOrden: filtros ? filtros.criterioOrden : ordenInicial,
        limite: filtros ? filtros.limite : PASO_LIMITE,
      });
    }
  };

  setDescuentos = async () => {
    const { criterioOrden, limite, nombre } = this.state;

    try {
      const params = new URLSearchParams({ limite: limite.toString() });

      if (criterioOrden) {
        const orden = criterioOrden.modo === "DESC" ? `-${criterioOrden.campo}` : criterioOrden.campo;
        params.append("orden", orden);
      }

      if (nombre) {
        params.append("nombre", nombre);
      }

      const descuentos: Array<Descuento> = await this.descuentoService.list(params);

      this.setState({ descuentos: descuentos });
    } catch (error) {
      this.descuentoService.manejarErrorHTTP(error, "Descuentos");
    }
  };

  onDelete = async (descuentoId: number) => {
    try {
      await this.descuentoService.delete(descuentoId);

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.VENTAS_DESCUENTOS);

      await Swal.fire({
        icon: "success",
        text: "Descuento eliminado",
      });
    } catch (error) {
      this.descuentoService.manejarErrorHTTP(error, "Descuento");
    }
  };

  criteriosOrdenIguales = (criterioA: CriterioOrden | null, criterioB: CriterioOrden | null): boolean => {
    if (criterioA === null || criterioB === null) {
      return false;
    }
    return criterioA.campo === criterioB.campo && criterioA.modo === criterioB.modo;
  };

  seleccionarCriterio = (criterio: CriterioOrden | null) => {
    const { criterioOrden } = this.state;

    if (criterioOrden === null || !this.criteriosOrdenIguales(criterioOrden, criterio)) {
      this.setState({ criterioOrden: criterio });
    } else {
      this.setState({ criterioOrden: null });
    }
  };

  getCriterioHandler = (campo: string) => {
    return (modo: "ASC" | "DESC" | null) => {
      const criterioOrden = modo ? { modo: modo, campo: campo } : null;
      this.seleccionarCriterio(criterioOrden);
    };
  };

  getControlCriterio = (campo: string) =>
    this.state.criterioOrden?.campo === campo ? this.state.criterioOrden.modo : null;

  aumentarLimite = () => {
    this.setState((prevState) => ({ limite: prevState.limite + PASO_LIMITE }));
  };

  setFiltros = ({ nombre = this.state.nombre }) => {
    this.setState({ nombre: nombre });
  };

  render = () => {
    const { descuentos, nombre, criterioOrden } = this.state;
    const { version, propsSelect } = this.props;

    if (descuentos === null) {
      return <div>Cargando...</div>;
    }

    switch (version) {
      case VersionDescuentosShow.TABLE: {
        return (
          <div>
            <DescuentosFilter
              setFiltros={this.setFiltros}
              busquedaHandler={this.setDescuentos}
              filtros={{ nombre: nombre }}
            />
            <DescuentosTable
              onUpdate={this.props.onUpdate}
              onView={this.props.onView}
              onDelete={this.onDelete}
              descuentos={descuentos}
              criterioOrdenActual={criterioOrden}
              criterioOrdenHandler={this.seleccionarCriterio}
              aumentarLimite={this.aumentarLimite}
            />
          </div>
        );
      }
      case VersionDescuentosShow.SELECT: {
        if (!propsSelect) {
          return null;
        }

        return (
          <SelectComponent
            {...propsSelect}
            items={descuentos}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado}
            labelGetter={(obj: Descuento) => obj.nombre}
          />
        );
      }
      default: {
        return null;
      }
    }
  };
}
