import React from "react";
import { Button } from "react-bootstrap";
import Flex from "../../appearance/flex/Flex";
import * as Icon from "react-bootstrap-icons";

export interface Props {
  habilitado?: boolean;
}

interface State {
  habilitado: boolean;
}

export default class Habilitador extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      habilitado: this.props.habilitado ? this.props.habilitado : false,
    };
  }

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      // this.setState({habilitado:true});
    }
  };

  onClick = () => {
    let habilitado: boolean = this.state.habilitado;
    if (habilitado == false) this.setState({ habilitado: true });
  };

  render() {
    return (
      <Flex
        onClick={this.onClick}
        width="100%"
        justifyContent={"center"}
        alignItems={"center"}
        flexWrap={"nowrap"}
      >
        {React.cloneElement(this.props.children as React.ReactElement<any>, {
          disabled: !this.state.habilitado,
        })}
        <Button
          variant="light"
          onClick={() => {
            this.setState({ habilitado: !this.state.habilitado });
          }}
        >
          <Icon.Pencil />
        </Button>
      </Flex>
    );
  }
}
