import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import CargasUpdate from "./CargasUpdate";
import DescargasUpdate from "./DescargasUpdate";

interface Props {
  idRepartoDiario: number;
}

interface State {
  responsive: boolean;
}

export default class CargamentoTabs extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      responsive: window.innerWidth < 700 ? true : false,
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.actualizarDimensiones);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.actualizarDimensiones);
  };

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  render() {
    const { idRepartoDiario } = this.props;
    const { responsive } = this.state;
    return (
      <Tabs
        defaultActiveKey={"cargas"}
        transition={false}
        variant={responsive ? "pills" : "tabs"}
        style={{
          marginBottom: "1rem",
          flexDirection: responsive ? "column" : "row",
          alignItems: responsive ? "center" : "",
        }}
        unmountOnExit={true}
      >
        <Tab eventKey="cargas" title="Cargas">
          <CargasUpdate idRepartoDiario={idRepartoDiario} />
        </Tab>
        <Tab eventKey="descargas" title="Descargas">
          <DescargasUpdate idRepartoDiario={idRepartoDiario} />
        </Tab>
      </Tabs>
    );
  }
}
