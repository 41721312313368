import { Menu, Modal } from "@mantine/core";
import React, { Component } from "react";
import { Button, Table, Form, Col, DropdownButton, Dropdown } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import BotonOrden from "../../../../../administracion/seccion-clientes/clientes/components/BotonOrden";
import { CriterioOrden } from "../../../../../compartido/models/CriterioOrden";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../../libreria/data/input/Input";
import { Search, CartPlus, FileEarmarkPlus, PersonXFill, CashCoin, Pencil } from "react-bootstrap-icons";

import { CANALES_WEB_SOCKET, WebSocketService, Cliente, ClienteService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";
import PedidoNew from "../../../../../ventas/seccion-pedidos/pedidos/components/new/PedidoNew";
import ClienteActualizar from "../../../../../administracion/seccion-clientes/clientes/components/actualizar/ClienteActualizar";
import ServiciosClienteShow from "../../../../../administracion/seccion-clientes/clientes/components/actualizar/servicios/ServiciosClienteShow";
import ImagenesURLs from "../../../../../compartido/utils/ImagenesURLs";
import PagoVoluntarioNew from "../../../../../ventas/seccion-pagos/pagos/components/new/PagoVoluntarioNew";

interface GestionAtencionCliente {
  cliente: Cliente;
  seccion: "PEDIDO" | "CONSUMO" | "CLIENTE" | "SOLICITUD" | "RECLAMO" | "DEUDAS" | "PAGO";
}

export interface Props {
  onUpdate?: Function;
  onView?: Function;
}

interface State {
  clientes: Array<Cliente> | null;
  gestionAtencionCliente: GestionAtencionCliente | null;
  cliente: string | null;
  cuit: string | null;
  idBusqueda: number | null;
  telefono: string | null;
  calle: string | null;
  numero: string | null;
  criterioOrden: CriterioOrden | null;
  limite: number;
}

const ordenInicial: CriterioOrden = { campo: "razon_social", modo: "DESC" };

export default class AtencionClientesShowTable extends Component<Props, State> {
  private clienteService: ClienteService;

  private webSocket: WebSocket | null;
  constructor(props: Props) {
    super(props);
    this.state = {
      clientes: null,
      gestionAtencionCliente: null,
      cliente: null,
      cuit: null,
      idBusqueda: null,
      telefono: null,
      calle: null,
      numero: null,
      criterioOrden: ordenInicial,
      limite: 5,
    };
    this.webSocket = null;
    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);
  }

  componentWillUnmount = () => {
    this.webSocket?.close();
  };

  componentDidMount = () => {
    this.setClientes();
    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES,
      funcionCallback: this.setClientes,
    });
  };

  setClientes = async () => {
    const { cliente, cuit, idBusqueda, telefono, calle, numero, criterioOrden, limite } = this.state;

    try {
      let orden = criterioOrden !== null ? criterioOrden : undefined;
      const filtros = {
        cliente: cliente,
        cuit: cuit,
        idBusqueda: idBusqueda,
        telefono: telefono,
        calle: calle,
        numero: numero,
      };
      const clientes: Array<Cliente> = await this.clienteService.buscarGeneralClientes(filtros, orden, limite);
      this.setState({ clientes: clientes });
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error, "Clientes");
    }
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevState.criterioOrden !== this.state.criterioOrden || prevState.limite !== this.state.limite) {
      this.setClientes();
    }
  };

  criteriosOrdenIguales = (criterioA: CriterioOrden | null, criterioB: CriterioOrden | null): boolean => {
    if (criterioA === null || criterioB === null) {
      return false;
    }
    return criterioA.campo === criterioB.campo && criterioA.modo === criterioB.modo;
  };

  seleccionarCriterio = (criterio: CriterioOrden | null) => {
    const { criterioOrden } = this.state;

    if (criterioOrden === null || !this.criteriosOrdenIguales(criterioOrden, criterio)) {
      this.setState({ criterioOrden: criterio });
    } else {
      this.setState({ criterioOrden: null });
    }
  };

  getCriterioHandler = (campo: string) => {
    return (modo: "ASC" | "DESC" | null) => {
      const criterioOrden = modo ? { modo: modo, campo: campo } : null;
      this.seleccionarCriterio(criterioOrden);
    };
  };

  getControlCriterio = (campo: string) =>
    this.state.criterioOrden?.campo === campo ? this.state.criterioOrden.modo : null;

  manejadorScroll = () => {
    const divTabla = document.getElementById("contenedor-scroll");
    if (divTabla === null) {
      return;
    }
    let movimientoTope = divTabla.scrollHeight - divTabla.clientHeight;

    if (movimientoTope === divTabla.scrollTop) {
      this.aumentarLimite();
    }
  };

  aumentarLimite = () => {
    this.setState((prevState) => ({ limite: prevState.limite + 5 }));
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };

  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  renderAccionMenu = (cliente: Cliente) => {
    return (
      <DropdownButton title="Acciones">
        <Dropdown.Item
          onClick={() => {
            this.setState({
              gestionAtencionCliente: {
                cliente: cliente,
                seccion: "PEDIDO",
              },
            });
          }}
        >
          <CartPlus />
          Nuevo <b>Pedido</b>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            this.setState({
              gestionAtencionCliente: {
                cliente: cliente,
                seccion: "CONSUMO",
              },
            });
          }}
        >
          <Search />
          Ver <b>Consumos</b>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            this.setState({
              gestionAtencionCliente: {
                cliente: cliente,
                seccion: "CLIENTE",
              },
            });
          }}
        >
          <Pencil />
          Actualizar <b>Cliente</b>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            alert("Falta implementar Nueva Solicitud");
          }}
        >
          <FileEarmarkPlus />
          Nueva <b>Solicitud</b>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            alert("Falta implementar Nuevo Reclamo");
          }}
        >
          <PersonXFill />
          Nuevo <b>Reclamo</b>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            alert("Falta implementar Ver Deudas");
          }}
        >
          <Search />
          Ver <b>Deudas</b>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            this.setState({
              gestionAtencionCliente: {
                cliente: cliente,
                seccion: "PAGO",
              },
            });
          }}
        >
          <CashCoin />
          Nuevo <b>Pago</b>
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  // TODO version del boton menu de mantine que tiene un bug (se congela la app al abrirse) que aun no pudimos resolver
  renderAccionMenuDeprecado = (cliente: Cliente) => {
    return (
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Button>Acciones</Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            onClick={() => {
              this.setState({
                gestionAtencionCliente: {
                  cliente: cliente,
                  seccion: "PEDIDO",
                },
              });
            }}
          >
            <CartPlus />
            Nuevo <b>Pedido</b>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              this.setState({
                gestionAtencionCliente: {
                  cliente: cliente,
                  seccion: "CONSUMO",
                },
              });
            }}
          >
            <Search />
            Ver <b>Consumos</b>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              this.setState({
                gestionAtencionCliente: {
                  cliente: cliente,
                  seccion: "CLIENTE",
                },
              });
            }}
          >
            <Pencil />
            Actualizar <b>Cliente</b>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              alert("Falta implementar Nueva Solicitud");
            }}
          >
            <FileEarmarkPlus />
            Nueva <b>Solicitud</b>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              alert("Falta implementar Nuevo Reclamo");
            }}
          >
            <PersonXFill />
            Nuevo <b>Reclamo</b>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              alert("Falta implementar Ver Deudas");
            }}
          >
            <Search />
            Ver <b>Deudas</b>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              this.setState({
                gestionAtencionCliente: {
                  cliente: cliente,
                  seccion: "PAGO",
                },
              });
            }}
          >
            <CashCoin />
            Nuevo <b>Pago</b>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  };

  renderModal = (gestionAtencionCliente: GestionAtencionCliente | null) => {
    let componenteGestion = null;
    let anchoModal = "40%";
    let anchoFlexComponente = "50%";
    if (gestionAtencionCliente === null) {
      return null;
    }
    if (gestionAtencionCliente.seccion === "PEDIDO") {
      anchoFlexComponente = "80%";
      componenteGestion = (
        <PedidoNew
          cliente={gestionAtencionCliente.cliente}
          onSaveSuccess={() => {
            this.setState({
              gestionAtencionCliente: null,
            });
          }}
        />
      );
    }
    if (gestionAtencionCliente.seccion === "CONSUMO") {
      anchoModal = "90%";
      anchoFlexComponente = "900%";
      componenteGestion = <ServiciosClienteShow clienteId={gestionAtencionCliente.cliente.id} />;
    }
    if (gestionAtencionCliente.seccion === "CLIENTE") {
      anchoModal = "60%";
      anchoFlexComponente = "100%";
      componenteGestion = <ClienteActualizar id={gestionAtencionCliente.cliente.id} recienCreado={false} />;
    }
    if (gestionAtencionCliente.seccion === "PAGO") {
      anchoModal = "30%";
      anchoFlexComponente = "70%";
      componenteGestion = (
        <PagoVoluntarioNew
          clienteId={gestionAtencionCliente.cliente.id}
          onSaveSuccess={() => {
            this.setState({
              gestionAtencionCliente: null,
            });
          }}
        />
      );
    }

    return (
      <Modal
        centered
        size={anchoModal}
        transitionDuration={0}
        opened={gestionAtencionCliente !== null}
        onClose={() => {
          this.setState({ gestionAtencionCliente: null });
        }}
        styles={{
          modal: {
            backgroundImage: ImagenesURLs.urlFondoModal(),
          },
          body: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
        closeOnClickOutside={false}
        /* title="Nuevo Pago" */
      >
        <Flex width={anchoFlexComponente}>{componenteGestion}</Flex>
      </Modal>
    );
  };

  render = () => {
    const { clientes, gestionAtencionCliente, cliente, cuit, idBusqueda, telefono, calle, numero } = this.state;
    const minColWidth = "250px";

    if (clientes === null) {
      return <div>Cargando...</div>;
    }

    return (
      <>
        <Form
          style={{
            padding: "1rem",
            backgroundColor: "gainsboro",
            borderRadius: "5px",
            marginBottom: "1rem",
          }}
        >
          <Form.Row className="align-items-center" style={{ rowGap: "1rem" }}>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <Form.Label as="strong">Cliente</Form.Label>
                <Input
                  data={idBusqueda}
                  placeholder={"Ingrese id del cliente"}
                  onChange={(idBusqueda: number) => {
                    this.setState({ idBusqueda: idBusqueda });
                  }}
                  type={InputTypes.Number}
                />
                <Input
                  data={cliente}
                  placeholder={"Ingrese apellido / razón social"}
                  onChange={(cliente: string) => {
                    this.setState({ cliente: cliente });
                  }}
                  type={InputTypes.Text}
                />
                <Input
                  data={cuit}
                  placeholder={"Ingrese cuit / cuil"}
                  onChange={(cuit: string) => {
                    this.setState({ cuit: cuit });
                  }}
                  type={InputTypes.Number}
                />
              </Flex>
            </Col>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <Form.Label as="strong">Teléfono</Form.Label>
                <Input
                  data={telefono}
                  placeholder={"Ingrese teléfono"}
                  onChange={(telefono: string) => {
                    this.setState({ telefono: telefono });
                  }}
                  type={InputTypes.Number}
                />
              </Flex>
              <Flex flexDirection="column" alignItems="center">
                <Form.Label as="strong">Dirección</Form.Label>
                <Input
                  data={calle}
                  placeholder={"Ingrese calle"}
                  onChange={(calle: string) => {
                    this.setState({ calle: calle });
                  }}
                  type={InputTypes.Text}
                />
                <Input
                  data={numero}
                  placeholder={"Ingrese número"}
                  onChange={(numero: string) => {
                    this.setState({ numero: numero });
                  }}
                  type={InputTypes.Number}
                />
              </Flex>
            </Col>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <RequestButton onClick={() => this.setClientes()} texto="Buscar" icon={<Icon.Search />} />
              </Flex>
            </Col>
          </Form.Row>
        </Form>

        <div style={{ maxHeight: "350px", overflowY: "scroll" }} onScroll={this.manejadorScroll} id="contenedor-scroll">
          <Table striped bordered>
            <thead>
              <tr>
                <th className="text-center" style={{ position: "sticky", top: "0" }}>
                  #
                </th>
                <th className="text-center" style={{ position: "sticky", top: "0" }}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                    <span>Id </span>
                    <BotonOrden
                      criterio={this.getControlCriterio("id")}
                      onCriterioClicked={this.getCriterioHandler("id")}
                    />
                  </Flex>
                </th>
                <th className="text-center" style={{ position: "sticky", top: "0" }}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                    <span>Razón Social</span>
                    <BotonOrden
                      criterio={this.getControlCriterio("razon_social")}
                      onCriterioClicked={this.getCriterioHandler("razon_social")}
                    />
                  </Flex>
                </th>
                <th className="text-center" style={{ position: "sticky", top: "0" }}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                    <span>cuit </span>
                    <BotonOrden
                      criterio={this.getControlCriterio("cuit")}
                      onCriterioClicked={this.getCriterioHandler("cuit")}
                    />
                  </Flex>
                </th>
                <th className="text-center" style={{ position: "sticky", top: "0" }}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                    <span>Dirección</span>
                  </Flex>
                </th>
                <th className="text-center" style={{ position: "sticky", top: "0" }}></th>
              </tr>
            </thead>

            <tbody>
              {clientes ? (
                clientes.map((cliente, idx) => (
                  <tr>
                    <td className="text-center">{idx + 1}</td>
                    <td className="text-center">{cliente.id}</td>
                    <td className="text-center">{cliente.razon_social}</td>
                    <td className="text-center">{cliente.cuit}</td>
                    <td className="text-center">{"Calle " + cliente.calle + " n° " + cliente.numero}</td>
                    <td className="text-center">{this.renderAccionMenu(cliente)}</td>
                  </tr>
                ))
              ) : (
                <>No hay clientes</>
              )}
            </tbody>
          </Table>
        </div>
        {this.renderModal(gestionAtencionCliente)}
      </>
    );
  };
}
