import { addDays } from "date-fns";
import React, { Component } from "react";
import { ServicioCliente } from "serviciossaintmichel";
import ServicioClienteNew from "./ServicioClienteNew";
import ServicioClienteShow from "./ServicioClienteShow";

export interface Props {
  servicioCliente: ServicioCliente | null;
  clienteId: number;
  fechaFinServicioActual?: Date;
}

interface State {}

export default class ServicioProximo extends Component<Props, State> {
  render = () => {
    const { servicioCliente, clienteId, fechaFinServicioActual } = this.props;

    let fechaInicioMin;
    if (fechaFinServicioActual) {
      fechaInicioMin = addDays(fechaFinServicioActual, 1);
    }

    if (servicioCliente === null) {
      return <ServicioClienteNew clienteId={clienteId} fechaInicioMin={fechaInicioMin} />;
    }

    return <ServicioClienteShow servicioCliente={servicioCliente} clienteId={clienteId} showDeleteBtn />;
  };
}
