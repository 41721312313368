import React, { Component } from "react";
import * as Icon from "react-bootstrap-icons";
import { Table, Button } from "react-bootstrap";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import EntregaProductoLoad from "./EntregaProductoLoad";
import { EntregaProductoLectura } from "serviciossaintmichel";

export interface Props {
  entregasProducto: Array<EntregaProductoLectura>;
  onChange?: Function;
}

interface State {
  entregasProducto: Array<EntregaProductoLectura>;
}

export default class EntregasProductoLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entregasProducto: props.entregasProducto,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (
      prevProps.entregasProducto !== this.props.entregasProducto &&
      this.state.entregasProducto !== this.props.entregasProducto
    ) {
      this.setState({ entregasProducto: this.props.entregasProducto });
    }

    if (prevState.entregasProducto !== this.state.entregasProducto && this.props.onChange) {
      this.props.onChange(this.state.entregasProducto);
    }
  }

  eliminarEntregaProducto = (idProducto: number) => {
    const entregasProducto = this.state.entregasProducto.filter(
      (vp: EntregaProductoLectura) => vp.producto.id !== idProducto
    );
    this.setState({
      entregasProducto: [...entregasProducto],
    });
  };

  agregarEntregaProducto = (nuevaEntregaProducto: EntregaProductoLectura): void => {
    this.setState((prevState) => ({
      entregasProducto: [...prevState.entregasProducto, nuevaEntregaProducto],
    }));
  };

  actualizarEntregaProducto = (idProducto: number, infoEntrega: EntregaProductoLectura) => {
    const ventas = this.state.entregasProducto.map((ep) => (ep.producto.id === idProducto ? infoEntrega : ep));
    this.setState({
      entregasProducto: ventas,
    });
  };

  render() {
    const { entregasProducto } = this.state;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Producto</th>
              <th>Entregados</th>
              <th>Vacíos</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {entregasProducto.map((entregaProducto, idx) => (
              <tr key={entregaProducto.producto.id}>
                <td>{entregaProducto.producto.nombre}</td>
                <td>
                  <Input
                    type={InputTypes.Number}
                    data={entregaProducto.entregados}
                    onChange={(valor: number) => {
                      this.actualizarEntregaProducto(entregaProducto.producto.id, {
                        ...entregaProducto,
                        entregados: valor,
                      });
                    }}
                  />
                </td>
                <td>
                  <Input
                    type={InputTypes.Number}
                    data={entregaProducto.vacios}
                    onChange={(valor: number) => {
                      this.actualizarEntregaProducto(entregaProducto.producto.id, {
                        ...entregaProducto,
                        vacios: valor,
                      });
                    }}
                  />
                </td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      this.eliminarEntregaProducto(entregaProducto.producto.id);
                    }}
                  >
                    <Icon.Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <EntregaProductoLoad
          onSubmitCallback={this.agregarEntregaProducto}
          excluirProductos={entregasProducto.map((ep) => ep.producto.id)}
        />
      </>
    );
  }
}
