import React, { Component } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Flex from "../../../appearance/flex/Flex";
import { Font } from "../../models/models";
import Text from "../../../message/text/Text";

interface Props {
  data?: Date;
  onChange: Function;
  fontFamily?: string;
  label?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
}

interface State {
  data: Date;
  fontFamily: string;
  error: string;
  disabled: boolean;
}

class InputDate extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let data: Date = this.props.data ? this.props.data : new Date();
    this.state = {
      data: data,
      disabled: this.props.disabled ? this.props.disabled : false,
      error: this.props.error ? this.props.error : "",
      fontFamily: this.props.fontFamily ? this.props.fontFamily : Font.family,
    };
  }

  componentDidMount() {}

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.data != this.props.data) {
      let data: Date = this.props.data ? this.props.data : new Date();
      this.setState({ data: data });
    }
    if (prevProps.error != undefined && this.props.error == undefined) {
      this.setState({ error: "" });
    } else if (prevProps.error == undefined && this.props.error != undefined) {
      this.setState({ error: this.props.error });
    } else if (prevProps.error != undefined && this.props.error != undefined) {
      if (prevProps.error != this.props.error) this.setState({ error: this.props.error });
    } else {
    }
  };

  onChange = (data: Date) => {
    let x: Date = data;
    this.setState({ data: x });
    this.props.onChange(x);
  };

  onClick = () => {
    this.setState({ disabled: false });
  };

  render() {
    return (
      <Form.Group onClick={this.onClick} style={{ width: "100%" }}>
        {this.props.label ? <Form.Label>{this.props.label}</Form.Label> : null}
        <DatePicker
          locale="es"
          disabled={this.state.disabled}
          id="fecha_nacimiento"
          selected={this.state.data /*new Date(this.state.data)*/}
          onChange={this.onChange}
          dateFormat="dd/MM/yyyy"
        />
        {this.state.error != "" ? (
          <Flex flexDirection="column" alignItems="flex-start" justifyContent="center" width="100%">
            <Text color="red" size="16" align={"left"}>
              {this.state.error}
            </Text>
          </Flex>
        ) : null}
      </Form.Group>
    );
  }
}

export default InputDate;
