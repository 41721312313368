import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import Input, { InputTypes } from "../../../../../../../libreria/data/input/Input";
import { Phone } from "../../../../../../../libreria/data/input/phone/InputPhone";
import SelectTipoTelefono from "./SelectTipoTelefono";
import { TelefonoLectura, TipoTelefono } from "serviciossaintmichel";




interface Props {
  clienteId: number;
  telefono: TelefonoLectura | null;
  onChange?: Function;
  onChangeValidation?: Function;
}

interface State {
  telefono: TelefonoLectura;
  estado: boolean;
}

export default class TelefonoClienteLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      telefono: props.telefono || this.crearTelefono(),
      estado: false,
    };
  }

  crearTelefono(): TelefonoLectura {
    return {
      id: -1,
      codigo_area: "0",
      numero: "0",
      descripcion: "",
      tipo_telefono: { id: -1, tipo: "" },
      cliente: this.props.clienteId,
    };
  }

  componentDidMount = async () => {
    this.updateValidacion();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { estado, telefono } = this.state;
    const { onChangeValidation, onChange } = this.props;

    if (prevProps.telefono !== this.props.telefono) {
      if (this.props.telefono === null) {
        this.setState({ telefono: this.crearTelefono() });
      } else {
        this.setState({ telefono: this.props.telefono });
      }
    }

    if (prevState.estado !== estado) {
      if (onChangeValidation) {
        onChangeValidation(estado);
      }
    }

    if (prevState.telefono !== telefono) {
      this.updateValidacion();
      if (onChange) {
        onChange(telefono);
      }
    }
  };

  esValido = (): boolean => {
    const { telefono } = this.state;
    return (
      telefono.codigo_area !== "" &&
      telefono.numero !== "" &&
      telefono.descripcion !== "" &&
      telefono.tipo_telefono?.id > -1
    );
  };

  updateValidacion = () => {
    let validacion = this.esValido();
    this.setState({ estado: validacion });
  };

  setearNumero = (numero: Phone) => {
    this.setState({
      telefono: {
        ...this.state.telefono,
        codigo_area: numero.prefix || this.state.telefono.codigo_area,
        numero: numero.phone || this.state.telefono.numero,
      },
    });
  };

  setearDescripcion = (desc: string) => {
    this.setState({
      telefono: {
        ...this.state.telefono,
        descripcion: desc,
      },
    });
  };

  setearTipoTelefono = (tipoTelefono: TipoTelefono) => {
    this.setState({
      telefono: {
        ...this.state.telefono,
        tipo_telefono: tipoTelefono,
      },
    });
  };

  render() {
    const { telefono } = this.state;

    return (
      <Flex container flexDirection="column">
        <Flex marginTop="15px" container flexDirection="column" alignItems="center" justifyContent="center">
          <Flex container flexDirection="column" alignItems="center">
            <Form.Group style={{ width: "100%" }}>
              <Form.Label htmlFor="numero">Número</Form.Label>
              <Input
                max={5}
                type={InputTypes.Phone}
                data={{ prefix: Number.parseInt(telefono.codigo_area), phone: Number.parseInt(telefono.numero) }}
                placeholder="Número"
                onChange={(tel: Phone) => this.setearNumero(tel)}
              />
            </Form.Group>
          </Flex>

          <Flex container flexDirection="column">
            <Form.Group style={{ width: "100%" }}>
              <Form.Label htmlFor="descripcion">Descrición</Form.Label>
              <Input
                type={InputTypes.Character}
                data={telefono.descripcion}
                placeholder="Descripción"
                onChange={(desc: string) => this.setearDescripcion(desc)}
              />
            </Form.Group>
          </Flex>

          <Flex container flexDirection="column">
            <Form.Group style={{ width: "100%" }}>
              <Form.Label htmlFor="tipoTelefono">Tipo</Form.Label>
              <SelectTipoTelefono
                opcionNull={true}
                seleccionado={telefono.tipo_telefono}
                callbackParent={(tipoTelefono: TipoTelefono) => this.setearTipoTelefono(tipoTelefono)}
              />
            </Form.Group>
          </Flex>
        </Flex>
      </Flex>
    );
  }
}
