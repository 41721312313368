import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Font } from "../../models/models";

interface Props {
  data?: string;
  onChange: Function;
  fontFamily?: string;
  label?:string;
  placeholder?:string;
  onValidationChange?:Function;
  error?:string;
  disabled?:boolean;

}

interface State {
  data:string;
  fontFamily: string;
  valid:boolean;
  error:string;
  disabled:boolean;

}

class InputEmail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let data:string = this.props.data ? this.props.data : "";
    this.state = {
      data: data,
      disabled:this.props.disabled?this.props.disabled:false,

      error:this.props.error?this.props.error:"",

      fontFamily: this.props.fontFamily ? this.props.fontFamily : Font.family,
      valid:this.isValid(data)
    };
  }

  componentDidMount() {
    if(this.props.onValidationChange != undefined)
      this.props.onValidationChange(this.state.valid);
}

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.data !=  this.props.data) {
      let data:string = this.props.data ? this.props.data:"";
      this.setState({ data: data });
    }

    if (prevProps.error != undefined && this.props.error == undefined) {
      this.setState({ error: "" });
    }
    else if (prevProps.error == undefined && this.props.error != undefined) {
      this.setState({ error: this.props.error });
    }
    else if (prevProps.error != undefined && this.props.error != undefined) {
      if (prevProps.error != this.props.error)
        this.setState({ error: this.props.error })
    }
    else { }

  }

  onChange = (data:any) => {

    //debugger;
    let x:string = data.target.value;  
    let valid:boolean = this.isValid(x);
    this.setState({ data: x ,valid:valid});
    this.props.onChange(x);
    if(this.props.onValidationChange != undefined)
      this.props.onValidationChange(valid);

  };

  isValid(data:string) {
    var regularExpression = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return regularExpression.test(data)|| data == ""; 
  }


  onClick = () => {this.setState({disabled:false});}


  render() {

    return (
      <Form.Group onClick={this.onClick} style={{width:"100%"}}>
        {this.props.label ? <Form.Label>{this.props.label}</Form.Label> : null } 
        <Form.Control disabled={this.state.disabled} style={{fontFamily:this.state.fontFamily}} isInvalid={!this.state.valid} type="text" value={this.state.data} placeholder={this.props.placeholder ? this.props.placeholder : ""} onChange = {this.onChange}/>
        <Form.Control.Feedback type="invalid">El email ingresado no es válido.</Form.Control.Feedback>  
        {this.state.error != "" ? <Form.Control.Feedback type="invalid">{this.state.error}</Form.Control.Feedback>:null }

      </Form.Group>
    );

  }
}


export default InputEmail;
