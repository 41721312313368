import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Visibilizador from "../../../../../../libreria/action/visibilizador/Visibilizador";
import { ProductoPromocion } from "serviciossaintmichel";

export interface Props {
  productos: Array<ProductoPromocion>;
}
interface State {
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
}

export default class ProductosPromocionShow extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      cargando: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {};

  renderProductos = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      {this.props.productos.length > 0
        ? this.renderizarTabla()
        : this.renderSinProductos()}
    </Flex>
  );

  renderSinProductos = () => {
    return "No posee Productos";
  };

  renderizarFilas = () => {
    return this.props.productos.map((relacion, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{relacion.producto.nombre}</td>
          <td className="text-center">{relacion.cantidad_cobrable}</td>
          <td className="text-center">{relacion.cantidad_bonificada}</td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Producto</th>
            <th className="text-center">Cantidad</th>
            <th className="text-center">Bonificación</th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  renderLoading = () => "Cargando Productos";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="20px"
      >
        <Visibilizador
          onChange={this.onChangeVisibilizador}
          label={"Productos"}
          labelAlignment={"flex-start"}
        >
          {this.state.cargando ? this.renderLoading() : this.renderProductos()}
        </Visibilizador>
      </Flex>
    );
  }
}
