import React, { Component } from "react";
import { Localidad } from "serviciossaintmichel";
import SelectGenerico from "../select/SelectGenerico";

interface Props {
  callbackParent: Function;
  seleccionado?: Localidad;
  localidades: Array<Localidad>;
}
interface State {
  localidades: Array<Localidad>;
  seleccionado: Localidad;
}

export default class SelectLocalidad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado
        ? this.props.seleccionado
        : this.props.localidades[0],
      localidades: this.props.localidades,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.localidades !== this.props.localidades) {
      this.setState({ localidades: this.props.localidades });
    }
  }

  getPosicion = (valor: Localidad): number => {
    return this.state.localidades.findIndex((i) => i.id === valor.id);
  };

  getSeleccionado = (indice: number) => {
    this.props.callbackParent(this.state.localidades[indice]);
  };

  render() {
    return (
      <SelectGenerico
        seleccionado={
          this.props.seleccionado
            ? this.getPosicion(this.props.seleccionado)
            : 0
        }
        elementos={this.state.localidades.map((i) => i.nombre)}
        callback={this.getSeleccionado}
      />
    );
  }
}
