import React, { Component } from "react";
import { Table } from "react-bootstrap";
import VentaProductoProgramacionEntregaLoad from "./VentaProductoProgramacionEntregaLoad";
import { EstadoLogisticaPaquete, VentaProgramacionProducto } from "serviciossaintmichel";
import { Select } from "@mantine/core";

interface ConfigCampo {
  disabled: Array<string>;
  hidden: Array<string>;
}

export interface Props {
  nuevosProductos?: boolean;
  configCampos?: ConfigCampo;
  ventasProducto: Array<VentaProgramacionProducto>;
  onChange?: Function;
}

interface State {
  ventasProducto: Array<VentaProgramacionProducto>;
}

export default class VentasProductoProgramacionEntregaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ventasProducto: props.ventasProducto,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (
      prevProps.ventasProducto !== this.props.ventasProducto &&
      this.state.ventasProducto !== this.props.ventasProducto
    ) {
      this.setState({ ventasProducto: this.props.ventasProducto });
    }

    if (prevState.ventasProducto !== this.state.ventasProducto && this.props.onChange) {
      this.props.onChange(this.state.ventasProducto);
    }
  }

  eliminarVentaProducto = (idProducto: number) => {
    const ventasProducto = this.state.ventasProducto.filter(
      (vp: VentaProgramacionProducto) => vp.paquete.producto.id !== idProducto
    );
    this.setState({
      ventasProducto: [...ventasProducto],
    });
  };

  agregarVentaProducto = (nuevaVentaProducto: VentaProgramacionProducto): void => {
    this.setState((prevState) => ({
      ventasProducto: [...prevState.ventasProducto, nuevaVentaProducto],
    }));
  };

  actualizarVentaProducto = (infoVenta: VentaProgramacionProducto) => {
    const ventas = this.state.ventasProducto.map((vp) => (vp.paquete.id === infoVenta.paquete.id ? infoVenta : vp));
    this.setState({
      ventasProducto: ventas,
    });
  };

  estaDeshabilitado = (id: string): boolean => {
    if (!this.props.configCampos) {
      return false;
    }

    return this.props.configCampos.disabled.indexOf(id) !== -1;
  };

  render() {
    const { ventasProducto } = this.state;
    const { nuevosProductos } = this.props;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Producto</th>
              <th>Cantidad</th>
              <th>Bonificados</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {ventasProducto.map((ventaProducto, idx) => (
              <tr key={ventaProducto.paquete.producto.id}>
                <td>{ventaProducto.paquete.producto.nombre}</td>
                <td>{ventaProducto.paquete.cantidad}</td>
                <td>{ventaProducto.paquete.bonificados}</td>
                <td>
                  <Select
                    data={[
                      EstadoLogisticaPaquete.ENTREGADO,
                      EstadoLogisticaPaquete.NO_ENTREGADO,
                      EstadoLogisticaPaquete.RECHAZADO,
                    ]}
                    value={ventaProducto.estado}
                    onChange={(seleccionado: EstadoLogisticaPaquete) => {
                      this.actualizarVentaProducto({
                        ...ventaProducto,
                        estado: seleccionado,
                      });
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {nuevosProductos ? (
          <VentaProductoProgramacionEntregaLoad
            onSubmitCallback={this.agregarVentaProducto}
            excluirProductos={ventasProducto.map((vP) => vP.paquete.producto.id)}
          />
        ) : null}
      </>
    );
  }
}
