import React, { Component } from "react";
import Flex from "../../../../compartido/components/flex/Flex";
import Box from "../../../../compartido/components/box/Box";
import { ListGroup } from "react-bootstrap";

import { BultoProductoGet,  BultoProductoPost,  BultoProductoService, CANALES_WEB_SOCKET, RelacionBultoProductoInsumo, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  bultoProductoId: number;
  ocultar: Function;
}

interface State {
  bultoProducto: BultoProductoGet;
  anchoBox: string;
}

export default class BultoProductoVerDetalle extends Component<Props, State> {

  private bultoProductoService:BultoProductoService;

  constructor(props: Props) {
    super(props);

    this.state = {
      bultoProducto: this.crearBultoProductoVacio(),
      anchoBox: window.innerWidth < 750 ? "90%" : "50%",
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_BULTO_PRODUCTOS,
      funcionCallback: this.cargarBultoProductoCanal,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS,
      funcionCallback: this.cargarBultoProductoCanal,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_INSUMOS,
      funcionCallback: this.cargarBultoProductoCanal,
    });

    this.bultoProductoService =  new BultoProductoService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 750) {
      this.setState({ anchoBox: "90%" });
    } else {
      this.setState({ anchoBox: "50%" });
    }
  };

  componentDidMount() {
    this.cargarBultoProducto(this.props.bultoProductoId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarBultoProducto(this.props.bultoProductoId);
    }
  }

  crearBultoProductoVacio(): BultoProductoGet {
    const bultoProducto: BultoProductoGet = {
      id: 0,
      nombre: "",
      insumos: [],
      productos: [],
    };
    return bultoProducto;
  }

  cargarBultoProducto = async (id: number) => {
    const resBultoProducto = await this.bultoProductoService.get(id).catch(
      (error) => {
        this.bultoProductoService.manejarErrorHTTP(error, "Bulto Producto");
        return null;
      }
    );
    if (resBultoProducto != null) {
      this.setState({
        bultoProducto: resBultoProducto,
      });
    }
  };

  cargarBultoProductoCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.bultoProducto &&
      dato.message.bultoProductoId == this.state.bultoProducto.id
    ) {
      this.cargarBultoProducto(this.state.bultoProducto.id);
    }
    if (
      this.state.bultoProducto &&
      this.state.bultoProducto.productos.find(
        (i) => i.producto.id === dato.message.productoId
      )
    ) {
      this.cargarBultoProducto(this.state.bultoProducto.id);
    }
    if (
      this.state.bultoProducto &&
      this.state.bultoProducto.insumos.find(
        (i) => i.insumo.id === dato.message.insumoId
      )
    ) {
      this.cargarBultoProducto(this.state.bultoProducto.id);
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  renderizarInsumosProducto = (relaciones: Array<RelacionBultoProductoInsumo>) => {
    if (relaciones.length < 1) {
      return null;
    }
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        paddingTop="10px"
      >
        <ListGroup>
          {relaciones.map((x) => (
            <ListGroup.Item style={{ textAlign: "center" }}>
              {x.cantidad.toString() +
                " [" +
                x.insumo.unidad_insumo.unidad +
                "] - " +
                x.insumo.nombre +
                " - " +
                x.insumo.tipo_insumo.tipo}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Flex>
    );
  };

  render() {
    if (this.state.bultoProducto.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal={"10rem"} paddingVertical={"2rem"}>
            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h5>Nombre: {this.state.bultoProducto.nombre}</h5>
                </div>
              </Flex>
            </Flex>

            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>

            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h4>
                    Insumos Asociados {"("}al Bulto{")"}
                    {this.state.bultoProducto.insumos.length == 0
                      ? " : No tiene"
                      : this.renderizarInsumosProducto(
                          this.state.bultoProducto.insumos
                        )}
                  </h4>
                </div>
              </Flex>
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>
            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h4>
                    Productos Asociados
                    {this.state.bultoProducto.productos.length == 0 ? (
                      " : No tiene"
                    ) : (
                      <Flex
                        container
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        paddingTop="10px"
                      >
                        <ListGroup>
                          {this.state.bultoProducto.productos.map((x) => (
                            <ListGroup.Item style={{ textAlign: "center" }}>
                              {x.cantidad.toString() +
                                " [Unidades] - " +
                                x.producto.nombre +
                                " - " +
                                x.producto.tipo_producto.tipo}
                              <div>
                                <h5>
                                  Insumos Asociados por este producto:
                                  {x.producto.insumos.length == 0
                                    ? " No tiene"
                                    : this.renderizarInsumosProducto(
                                        x.producto.insumos
                                      )}
                                </h5>
                              </div>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Flex>
                    )}
                  </h4>
                </div>
              </Flex>
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <button
                className={"btn btn-outline-secondary"}
                onClick={this.ocultar}
                style={{ width: "150px" }}
              >
                Salir
              </button>
            </Flex>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
