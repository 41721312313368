import React, { Component, FormEvent } from "react";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import { CANALES_WEB_SOCKET, TipoCombustibleGet, TipoCombustiblePost, TipoCombustibleService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";






export interface Props {
  tipoCombustibleId: number;
  ocultar: Function;
}

interface State {
  tipoCombustible: TipoCombustibleGet;
  alerta: any;
}

export default class TipoCombustibleActualizar extends Component<Props, State> {

  private tipoCombustibleService:TipoCombustibleService;

  constructor(props: Props) {
    super(props);
    this.state = {
      tipoCombustible: this.crearTipoCombustibleVacio(),
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };

    this.tipoCombustibleService = new TipoCombustibleService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.cargarTipoCombustible(this.props.tipoCombustibleId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarTipoCombustible(this.props.tipoCombustibleId);
    }
  }

  crearTipoCombustibleVacio(): TipoCombustibleGet {
    const tipoCombustible: TipoCombustibleGet = {
      id: 0,
      tipo: "",
    };
    return tipoCombustible;
  }

  cargarTipoCombustible = async (id: number) => {
    const resTipoCombustible = await this.tipoCombustibleService.get(id).catch(
      (error) => {
        this.tipoCombustibleService.manejarErrorHTTP(error, "Tipo Combustible");
        return null;
      }
    );
    if (resTipoCombustible != null) {
      this.setState({
        tipoCombustible: resTipoCombustible,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  setearTipo = (tipo: string) => {
    let tipoCombustible = this.state.tipoCombustible;
    tipoCombustible.tipo = tipo;
    this.setState({ tipoCombustible: tipoCombustible });
  };

  handleUpdate() {
    const tipoCombustibleActualizar: TipoCombustiblePost = {
      tipo: this.state.tipoCombustible.tipo,
    };
    this.tipoCombustibleService.update(
      tipoCombustibleActualizar,
      this.state.tipoCombustible.id
    )
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_TIPO_COMBUSTIBLES,
          { tipoCombustibleId: this.state.tipoCombustible.id }
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Tipo Combustible Actualizado!",
            titulo: "Atención",
            estado: "block",
          },
        });
      })
      .catch((error) => {
        this.tipoCombustibleService.manejarErrorHTTP(error, "Tipo Combustible");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.tipoCombustible.tipo ? false : true;
  };

  render() {
    if (this.state.tipoCombustible.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            <div className={"row"}>
              <div className={"column"}>
                <form
                  className={"form-horizontal"}
                  onSubmit={this.handleSubmit}
                >
                  <div className={"form-group"}>
                    <label htmlFor="tipo">Nombre de Tipo Combustible</label>
                    <InputCaracteres
                      id="tipo"
                      name="tipo"
                      onChange={this.setearTipo}
                      value={this.state.tipoCombustible.tipo}
                      placeholder="Ingrese Nombre de TipoCombustible"
                    />
                  </div>

                  <div
                    className={"form-group"}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button
                      ref="boton"
                      type="submit"
                      className={"btn btn-info"}
                      disabled={this.disableButton()}
                    >
                      {"Actualizar"}
                    </button>
                    <button
                      className={"btn btn-outline-secondary"}
                      onClick={this.ocultar}
                    >
                      Ocultar
                    </button>
                  </div>
                  <div className={"form-group"}>
                    <Alerta
                      tipo={this.state.alerta.tipo}
                      titulo={this.state.alerta.titulo}
                      mensaje={this.state.alerta.mensaje}
                      estado={this.state.alerta.estado}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
