import React, { Component } from "react";
import { Reparto } from "serviciossaintmichel";
import CheckBoxList from "../../../../libreria/data/checkboxlist/CheckBoxList";
import { Item } from "../../../../libreria/data/checkboxlist/Item";

interface Props {
  repartos: Array<Reparto>;
  onChange: Function;
}

interface State {
  items: Array<Item> | null;
}

export default class RepartosCheckbox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      items: null,
    };
  }

  componentDidMount() {
    this.setearItems();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.items !== prevState.items) {
      this.props.onChange(this.state.items);
    }

    if (this.props.repartos !== prevProps.repartos) {
      this.setearItems();
    }
  }

  setearItems() {
    const items = this.crearItems(this.props.repartos);
    this.setState({ items: items });
  }

  crearItems = (repartos: Array<Reparto>): Array<Item> => {
    return repartos.map((reparto: Reparto) => ({ item: { id: reparto.id, text: reparto.nombre }, checked: false }));
  };

  onItemChange = (target: Item) => {
    const { items } = this.state;

    if (items === null) {
      return;
    }

    const newItems = items.map((item) => (item.item.id === target.item.id ? target : item));
    this.setState({ items: newItems });
  };

  render = () => {
    const { items } = this.state;

    if (items === null) {
      return null;
    }

    return <CheckBoxList items={items} onChange={this.onItemChange} />;
  };
}
