import { Checkbox } from "@mantine/core";
import React, { Component } from "react";
import { Table, DropdownButton } from "react-bootstrap";

import { CriterioOrden, CuponLectura } from "serviciossaintmichel";
import BotonOrden from "../../../../seccion-clientes/clientes/components/BotonOrden";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import FechaDisplay from "../../../../../compartido/components/fecha-display/FechaDisplay";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { getControlCriterio, getCriterioHandler } from "../../../../../compartido/utils/OrdenUtils";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
  onDelete?: Function;
  criterioOrdenActual: CriterioOrden | null;
  criterioOrdenHandler?: (critOrden: CriterioOrden | null) => void;
  aumentarLimite?: () => void;
  cupones: Array<CuponLectura>;
}

interface State {}

export default class CuponesTable extends Component<Props, State> {
  manejadorScroll = () => {
    const divTabla = document.getElementById("contenedor-scroll");
    if (divTabla === null) {
      return;
    }
    let movimientoTope = divTabla.scrollHeight - divTabla.clientHeight;

    if (movimientoTope === divTabla.scrollTop) {
      if (this.props.aumentarLimite) {
        this.props.aumentarLimite();
      }
    }
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };

  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  onDelete = (id: number) => {
    if (this.props.onDelete) this.props.onDelete(id);
  };

  renderAccionMenu = (cuponId: number) => {
    return (
      <DropdownButton title="Acciones">
        {this.props.onView ? (
          <DropdownItem
            key="view-item"
            onClick={() => {
              this.onView(cuponId);
            }}
          >
            Ver detalle
          </DropdownItem>
        ) : null}
        {this.props.onUpdate ? (
          <DropdownItem
            key="update-item"
            onClick={() => {
              this.onUpdate(cuponId);
            }}
          >
            Actualizar
          </DropdownItem>
        ) : null}
        {this.props.onDelete ? (
          <DropdownItem
            key="delete-item"
            onClick={() => {
              this.onDelete(cuponId);
            }}
          >
            Eliminar
          </DropdownItem>
        ) : null}
      </DropdownButton>
    );
  };

  renderBotonOrden = (campo: string) => {
    if (!this.props.criterioOrdenHandler) {
      return null;
    }
    return (
      <BotonOrden
        criterio={getControlCriterio(campo, this.props.criterioOrdenActual)}
        onCriterioClicked={getCriterioHandler(campo, this.props.criterioOrdenHandler)}
      />
    );
  };

  render = () => {
    const { cupones } = this.props;

    return (
      <div style={{ maxHeight: "350px", overflowY: "scroll" }} onScroll={this.manejadorScroll} id="contenedor-scroll">
        <Table striped bordered>
          <thead>
            <tr>
              <th className="text-center" style={{ position: "sticky", top: "0" }}>
                #
              </th>
              <th className="text-center" style={{ position: "sticky", top: "0" }}>
                <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                  <span>Id </span>
                  {this.renderBotonOrden("id")}
                </Flex>
              </th>
              <th className="text-center" style={{ position: "sticky", top: "0" }}>
                <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                  <span>Tipo </span>
                  {this.renderBotonOrden("tipo")}
                </Flex>
              </th>
              <th className="text-center" style={{ position: "sticky", top: "0", zIndex: "2" }}>
                Automatico
              </th>
              <th className="text-center" style={{ position: "sticky", top: "0" }}>
                <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                  <span>Fecha inicio </span>
                  {this.renderBotonOrden("fecha_inicio")}
                </Flex>
              </th>
              <th className="text-center" style={{ position: "sticky", top: "0" }}>
                <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                  <span>Fecha fin </span>
                  {this.renderBotonOrden("fecha_fin")}
                </Flex>
              </th>
              <th className="text-center" style={{ position: "sticky", top: "0" }}>
                Descuento
              </th>
              <th className="text-center" style={{ position: "sticky", top: "0", zIndex: "2" }}></th>
            </tr>
          </thead>

          <tbody>
            {cupones.map((cupon, idx) => (
              <tr key={cupon.id}>
                <td className="text-center">{idx + 1}</td>
                <td className="text-center">{cupon.id}</td>
                <td className="text-center">{cupon.tipo}</td>
                <td className="text-center">
                  <Flex justifyContent="center" alignItems="center">
                    <Checkbox checked={cupon.automatico} readOnly />
                  </Flex>
                </td>
                <td className="text-center">
                  <FechaDisplay fecha={cupon.fecha_inicio} />
                </td>
                <td className="text-center">
                  <FechaDisplay fecha={cupon.fecha_fin} />
                </td>
                <td className="text-center">{cupon.descuento.nombre}</td>
                <td className="text-center">{this.renderAccionMenu(cupon.id)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };
}
