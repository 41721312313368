import React, { ChangeEvent, Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import {
  CANALES_WEB_SOCKET,
  WebSocketService,
  Pago,
  PagoService,
  PagoPost,
  CrearPagoVacio,
} from "serviciossaintmichel";
import { Global } from "../../../../../Global";
import { Form, Image } from "react-bootstrap";
import PagoVoluntarioLoad from "../load/PagoVoluntarioLoad";

export interface Props {
  clienteId: number;
  onSaveSuccess: Function;
  onExit?: Function;
}

interface State {
  pago: Pago;
  error: string;
  valid: boolean;
  screen: ScreenType;
  comprobante: File | null;
}

export default class PagoVoluntarioNew extends Component<Props, State> {
  private pagoService: PagoService;

  constructor(props: Props) {
    super(props);
    const pagoVoluntario = CrearPagoVacio();
    pagoVoluntario.cliente = props.clienteId;
    this.state = {
      pago: pagoVoluntario,
      comprobante: null,
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.pagoService = new PagoService(Global.UsuarioService.getToken()!);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  onSave = () => {
    const { pago, comprobante } = this.state;
    const { cheque, transferencia_bancaria } = pago;

    const nuevoPago: PagoPost = {
      monto: pago.monto,
      fecha: pago.fecha,
      tipo_pago: pago.tipo_pago.id,
      cheque: cheque
        ? {
            numero: cheque.numero,
            fecha_emision: cheque.fecha_emision,
            banco: cheque.banco.id,
            fecha_pago: null,
            echeq: true,
          }
        : null,
      comprobante: comprobante,
      cliente: pago.cliente,
      venta: pago.venta,
      programacion_entrega: pago.programacion_entrega,
      visita: pago.visita,
      facturacion: pago.pagos_factura,
      transferencia_bancaria:
        transferencia_bancaria !== null
          ? {
              ...transferencia_bancaria,
              cuenta_destino: transferencia_bancaria.cuenta_destino.id,
              cuenta_origen: transferencia_bancaria.cuenta_origen.id,
            }
          : null,
    };

    return this.pagoService
      .create(nuevoPago)
      .then((pago) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.VENTAS_PAGOS, {
          type: "NEW",
          idPago: pago.id,
        });
        Swal.fire({
          icon: "success",
          text: `Pago Creado - Id ${pago.id}`,
        });
        this.props.onSaveSuccess(pago.id);
      })
      .catch((error) => {
        this.pagoService.manejarErrorHTTP(error, "Pago");
      });
  };

  onExit = () => {
    if (this.props.onExit !== undefined) this.props.onExit();
  };

  onChange = (pago: Pago) => {
    this.setState({ pago: pago });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  setearImagen = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files && event.target.files[0] != null) {
      let archivo = event.target.files[0];
      if (archivo.type.startsWith("image") || archivo.type.endsWith("pdf")) {
        this.setState({ comprobante: archivo });
      } else {
        event.target.value = "";
        alert("Archivo no soportado");
      }
    }
  };

  quitarImagen = () => {
    this.setState({ comprobante: null });
  };

  renderizarPhone = () => (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex flexDirection="column" width="60%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => this.renderizar();

  renderizar = () => (
    <Box padding="20px">
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <H size={3} texto={"Nuevo pago voluntario"} />
        </Flex>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <PagoVoluntarioLoad
            clienteId={this.props.clienteId}
            pago={this.state.pago}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
          />
          <div className={"form-group"}>
            <label htmlFor="comprobante">Comprobante</label>
            <br />
            {this.state.comprobante ? (
              <>
                {!this.state.comprobante.name.endsWith(".pdf") ? (
                  <Image src={URL.createObjectURL(this.state.comprobante)} fluid />
                ) : (
                  <div>
                    <p>
                      Abrir pdf en <a href={URL.createObjectURL(this.state.comprobante)}>nueva pestaña</a>.
                    </p>
                    <iframe
                      title="Comprobante de pago"
                      src={URL.createObjectURL(this.state.comprobante) + "#toolbar=0"}
                      width="100%"
                      height="500px"
                    ></iframe>
                  </div>
                )}
                <br />
                {this.state.comprobante.name}
                <br />
                <button className="btn btn-danger" onClick={() => this.quitarImagen()}>
                  {" "}
                  Quitar Imagen{" "}
                </button>
              </>
            ) : (
              <Form.File
                id="comprobante"
                name="comprobante"
                onChange={this.setearImagen}
                label="Ingrese la Imagen (no obligatorio)"
                accept="image/* , application/pdf"
              />
            )}
          </div>
        </Flex>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
          <RequestButton propsBoton={{ disabled: !this.state.valid }} texto={"Crear"} onClick={this.onSave} />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.screen === ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen === ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen === ScreenType.TABLET ? this.renderizarTablet() : null}
      </Flex>
    );
  }
}
