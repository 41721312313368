import { Button, Flex, Loader } from "@mantine/core";
import React, { Component } from "react";
import {
  CANALES_WEB_SOCKET,
  CuentaBancaria,
  CuentaBancariaPost,
  CuentaBancariaService,
  WebSocketService,
} from "serviciossaintmichel";
import CuentaBancariaLoad from "./CuentaBancariaLoad";
import { Global } from "../../../../../../Global";
import Swal from "sweetalert2";

export interface Props {
  cuentaBancariaId: number;
  onSubmit: Function;
}

interface State {
  valido: boolean;
  cuentaBancaria: CuentaBancaria | null;
}

export default class CuentaBancariaUpdate extends Component<Props, State> {
  private cuentaBancariaService: CuentaBancariaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      cuentaBancaria: null,
      valido: false,
    };
    this.cuentaBancariaService = new CuentaBancariaService(Global.UsuarioService.getToken()!);
  }

  async componentDidMount() {
    const cuentaBancaria = await this.cuentaBancariaService.get(this.props.cuentaBancariaId);
    this.setState({ cuentaBancaria: cuentaBancaria });
  }

  setValidation = (valido: boolean) => {
    this.setState({ valido: valido });
  };

  setCuentaBancaria = (cuentaBancaria: CuentaBancaria) => {
    this.setState({ cuentaBancaria: cuentaBancaria });
  };

  actualizarCuentaBancaria = async () => {
    const { cuentaBancaria } = this.state;

    if (cuentaBancaria === null) {
      return;
    }

    const payload: CuentaBancariaPost = {
      tipo: cuentaBancaria.tipo,
      razon_social: cuentaBancaria.razon_social,
      cuit: cuentaBancaria.razon_social,
      nombre: cuentaBancaria.nombre,
      apellido: cuentaBancaria.apellido,
      banco: cuentaBancaria.banco.id,
      cliente: cuentaBancaria.cliente,
    };
    try {
      const resultado = await this.cuentaBancariaService.update(payload, cuentaBancaria.id);
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.VENTAS_CUENTAS_BANCARIAS, {
        idCuentaBancaria: resultado.id,
      });
      Swal.fire({
        icon: "success",
        text: `Cuenta bancaria ${resultado.id} modificada con éxito`,
      });
      this.props.onSubmit();
    } catch (error) {
      this.cuentaBancariaService.manejarErrorHTTP(error);
    }
  };

  render = () => {
    const { cuentaBancaria, valido } = this.state;

    if (cuentaBancaria === null) {
      return <Loader />;
    }

    return (
      <Flex gap={"md"} direction={"column"} align={"center"}>
        <CuentaBancariaLoad
          cuentaBancaria={cuentaBancaria}
          onValidationChange={this.setValidation}
          onChange={this.setCuentaBancaria}
        />
        <Button disabled={!valido} onClick={this.actualizarCuentaBancaria} color="green">
          Guardar
        </Button>
      </Flex>
    );
  };
}
