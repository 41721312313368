import { Button, Flex } from "@mantine/core";
import React, { Component } from "react";
import {
  CANALES_WEB_SOCKET,
  CrearCuentaBancariaVacia,
  CuentaBancaria,
  CuentaBancariaPost,
  CuentaBancariaService,
  WebSocketService,
} from "serviciossaintmichel";
import CuentaBancariaLoad from "./CuentaBancariaLoad";
import { Global } from "../../../../../../Global";
import Swal from "sweetalert2";

export interface Props {
  clienteId: number;
  onSubmit: Function;
}

interface State {
  valido: boolean;
  cuentaBancaria: CuentaBancaria;
}

export default class CuentaBancariaNew extends Component<Props, State> {
  private cuentaBancariaService: CuentaBancariaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      cuentaBancaria: { ...CrearCuentaBancariaVacia(), cliente: props.clienteId },
      valido: false,
    };
    this.cuentaBancariaService = new CuentaBancariaService(Global.UsuarioService.getToken()!);
  }

  setValidation = (valido: boolean) => {
    this.setState({ valido: valido });
  };

  setCuentaBancaria = (cuentaBancaria: CuentaBancaria) => {
    this.setState({ cuentaBancaria: cuentaBancaria });
  };

  crearCuentaBancaria = async () => {
    const { cuentaBancaria } = this.state;
    const payload: CuentaBancariaPost = {
      tipo: cuentaBancaria.tipo,
      razon_social: cuentaBancaria.razon_social,
      cuit: cuentaBancaria.razon_social,
      nombre: cuentaBancaria.nombre,
      apellido: cuentaBancaria.apellido,
      banco: cuentaBancaria.banco.id,
      cliente: cuentaBancaria.cliente,
    };
    try {
      const resultado = await this.cuentaBancariaService.create(payload);
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.VENTAS_CUENTAS_BANCARIAS, {
        idCuentaBancaria: resultado.id,
      });
      Swal.fire({
        icon: "success",
        text: `Cuenta bancaria creada con éxito - ID ${resultado.id}`,
      });
      this.props.onSubmit();
    } catch (error) {
      this.cuentaBancariaService.manejarErrorHTTP(error);
    }
  };

  render = () => {
    const { cuentaBancaria, valido } = this.state;

    return (
      <Flex direction={"column"} align={"center"} gap={"md"}>
        <CuentaBancariaLoad
          cuentaBancaria={cuentaBancaria}
          onValidationChange={this.setValidation}
          onChange={this.setCuentaBancaria}
        />
        <Button disabled={!valido} onClick={this.crearCuentaBancaria} color="green">
          Crear cuenta
        </Button>
      </Flex>
    );
  };
}
