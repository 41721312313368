import React, { Component } from "react";
import { PropsSelectGeneral } from "../../../../../compartido/components/select/SelectBusqueda";
import { PropsSelectGenerico } from "../../../../../compartido/components/select/PropsSelectGenerico";
import { LIMITES } from "../../../../../compartido/components/select/SelectLimiteTabla";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import ProgramacionesEntregaShowSelect from "./ProgramacionesEntregaShowSelect";
import ProgramacionesEntregaShowTable from "./ProgramacionesEntregaShowTable";

import {
  CANALES_WEB_SOCKET,
  ProgramacionEntregaService,
  ProgramacionEntrega,
  WebSocketService,
} from "serviciossaintmichel";
import { Global } from "../../../../../Global";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
  version: Version;
  propsSelect?: PropsSelectGenerico<ProgramacionEntrega> & PropsSelectGeneral;
}

export enum Version {
  SELECT,
  TABLE,
}

interface State {
  programacionesEntrega: Array<ProgramacionEntrega>;
}

export default class ProgramacionesEntregaShow extends Component<Props, State> {
  private programacionEntregaService: ProgramacionEntregaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      programacionesEntrega: [],
    };

    this.programacionEntregaService = new ProgramacionEntregaService(Global.UsuarioService.getToken()!);
  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PROGRAMACIONES_ENTREGA,
      funcionCallback: this.getProgramacionesEntrega,
    });
    if (this.props.version === Version.TABLE) this.programacionEntregaService.paramsURL.setearLimite(LIMITES[0]);
    this.getProgramacionesEntrega();
  }

  getProgramacionesEntrega = () => {
    this.programacionEntregaService
      .listAll()
      .then((result) => {
        this.setState({ programacionesEntrega: result });
      })
      .catch((error) => {
        this.programacionEntregaService.manejarErrorHTTP(error, "Programacion Entrega");
      });
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems={Version.TABLE ? undefined : "center"}
        justifyContent="center"
      >
        {this.props.version === Version.SELECT && this.props.propsSelect ? (
          <ProgramacionesEntregaShowSelect
            {...this.props.propsSelect}
            callbackParent={this.props.propsSelect.callbackParent}
            seleccionado={this.props.propsSelect.seleccionado ? this.props.propsSelect.seleccionado : null}
            items={this.state.programacionesEntrega}
          />
        ) : null}
        {this.props.version === Version.TABLE ? (
          <ProgramacionesEntregaShowTable onView={this.onView} onUpdate={this.onUpdate} />
        ) : null}
      </Flex>
    );
  }
}
