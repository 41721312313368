import React, { Component } from "react";
import { ServicioCliente } from "serviciossaintmichel";
import { Fuentes } from "../../../../../../compartido/estilos/estilos";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../../libreria/containers/box/Box";
import H from "../../../../../../libreria/message/title/H";
import AlquilerClienteUpdate from "./alquileres/AlquilerClienteUpdate";
import ConsumoClienteUpdate from "./consumos/ConsumoClienteUpdate";
import OrdenCompraUpdate from "./ordenes-compra/OrdenCompraUpdate";



export interface Props {
  clienteId: number;
  servicioCliente: ServicioCliente;
  fechaFinMax?: Date;
}

interface State {}

export default class ServicioClienteUpdate extends Component<Props, State> {
  resolverComponente = (servicioCliente: ServicioCliente): Array<JSX.Element | null | string> => {
    let componente = null;
    let titulo = "";
    const { fechaFinMax } = this.props;

    switch (servicioCliente.tipo) {
      case "alquiler": {
        componente = <AlquilerClienteUpdate alquilerClienteId={servicioCliente.id} fechaFinMax={fechaFinMax} />;
        titulo = "Alquiler";
        break;
      }
      case "consumo": {
        componente = <ConsumoClienteUpdate consumoClienteId={servicioCliente.id} fechaFinMax={fechaFinMax} />;
        titulo = "Consumo";
        break;
      }
      case "orden_compra": {
        componente = <OrdenCompraUpdate ordenCompraId={servicioCliente.id} fechaFinMax={fechaFinMax} />;
        titulo = "Orden de compra";
        break;
      }
      default: {
        break;
      }
    }

    return [componente, titulo];
  };

  render = () => {
    const [componente, titulo] = this.resolverComponente(this.props.servicioCliente);

    return (
      <Flex container justifyContent="space-around" width="auto">
        <Box paddingVertical={"2rem"} paddingHorizontal={"2rem"}>
          <H size={4} fuente={Fuentes.Titulos} texto={titulo?.toString()} />
          {componente}
        </Box>
      </Flex>
    );
  };
}
