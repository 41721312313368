import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import ArticulosOficina from "./ArticuloOficina/components/ArticulosOficina";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];
  
  if (Global.UsuarioService.getPermisos().Elementos.ArticulosOficina.hasAccess()) {
    aux.push({
      name: "Articulos de Oficina",
      path: SeccionArticulosOficina.pathBase + "/articulos-oficina",
      component: ArticulosOficina,
    })
  }
  return aux;
}

export default class SeccionArticulosOficina extends Component<Props> {
  static pathBase = "/elementos/seccion-articulos-oficina";
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Articulos de Oficina"}
      />
    );
  }
}
