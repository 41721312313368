import React, { Component } from "react";
import Swal from "sweetalert2";
import { Badge, Menu, Modal, Select } from "@mantine/core";
import { Table, Button, Form, Col } from "react-bootstrap";
import EntregaVisitaUpdate from "./EntregaVisitaUpdate";
import ProgramacionEntregaVisitaUpdate from "./ProgramacionEntregaVisitaUpdate";
import ResumenVisitaRepartoDiario from "./ResumenVisitaRepartoDiario";
import VentaVisitaUpdate from "./VentaVisitaUpdate";
import VisitaLoad from "../load/VisitaLoad";
import Box from "../../../../libreria/containers/box/Box";
import RecorridosShow, { Version as VersionRecorridosShow } from "../../../recorridos/components/show/RecorridosShow";
import ManejadorErroresHTTP from "../../../../compartido/utils/ManejadorErroresHTTP";
import FechaUtils from "../../../../compartido/utils/FechaUtils";
import VisitaEstadoUpdate from "./VisitaEstadoUpdate";
import { diaNumToId } from "../../../../compartido/utils/dias";
import { excluirIds } from "../../../../compartido/utils/filtros";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import RequestButton from "../../../../libreria/action/request-button/RequestButton";
import * as Icon from "react-bootstrap-icons";

import {
  clienteVacio,
  direccionVacia,
  CANALES_WEB_SOCKET,
  Cliente,
  DireccionDTO,
  EstadoRepartoDiario,
  EstadoVisita,
  getColorEstadoVisita,
  getColorPrioridadVisita,
  OrdenVisitaRepartoDiarioLectura,
  PrioridadVisita,
  Recorrido,
  RecorridoReparto,
  RepartoDiarioLectura,
  RepartoDiarioService,
  RepartoService,
  VisitaLectura,
  WebSocketService,
} from "serviciossaintmichel";
import { Global } from "../../../../Global";
import ImagenesURLs from "../../../../compartido/utils/ImagenesURLs";

interface GestionVisita {
  visitaId: number;
  seccion: "VENTA" | "ENTREGA" | "PROGRAMACION_ENTREGA" | "ESTADO_VISITA" | "PRIORIDAD_VISITA";
}
export interface Props {
  idRepartoDiario: number;
}

interface State {
  ordenVisitas: Array<OrdenVisitaRepartoDiarioLectura> | null;
  visitaIdResumen: number | null;
  gestionVisita: GestionVisita | null;
  repartoDiario: RepartoDiarioLectura | null;
  visitaFormulario: VisitaLectura | null;
  visitaCliente: Cliente | null;
  visitaDireccion: DireccionDTO | null;
  recorridoSeleccionado: Recorrido | null;
  recorridosReparto: Array<RecorridoReparto> | null;
  filtroCliente: string;
  filtroDireccionCalle: string;
  filtroDireccionNumero: string;
  filtroPrioridad: PrioridadVisita | null;
  filtroEstadoActual: EstadoVisita | null;
}

export default class RepartoDiarioVisitasUpdate extends Component<Props, State> {
  private webSockets: Array<WebSocket>;
  private repartoDiarioService: RepartoDiarioService;
  private repartoService: RepartoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      ordenVisitas: null,
      visitaIdResumen: null,
      gestionVisita: null,
      repartoDiario: null,
      visitaFormulario: null,
      visitaCliente: null,
      visitaDireccion: null,
      recorridoSeleccionado: null,
      recorridosReparto: null,
      filtroCliente: "",
      filtroDireccionCalle: "",
      filtroDireccionNumero: "",
      filtroPrioridad: null,
      filtroEstadoActual: null,
    };
    this.webSockets = [];
    this.repartoDiarioService = new RepartoDiarioService(Global.UsuarioService.getToken()!);
    this.repartoService = new RepartoService(Global.UsuarioService.getToken()!);
  }

  componentDidMount = async () => {
    this.setOrdenesVisita();
    const repartoDiario = await this.setRepartoDiario();
    this.setRecorridosReparto(repartoDiario);
    this.webSockets.push(
      WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
        funcionCallback: this.onChannelMessage,
      })
    );
  };

  componentWillUnmount = () => {
    this.webSockets.forEach((ws: WebSocket) => ws.close());
  };

  onChannelMessage = async (message: MessageEvent) => {
    const dato = JSON.parse(message.data);
    if (dato.message.idRepartoDiario !== this.props.idRepartoDiario) {
      return;
    }
    this.setOrdenesVisita();
    const repartoDiario = await this.setRepartoDiario();
    this.setRecorridosReparto(repartoDiario);
  };

  getQueryParams = () => {
    const filtros = {
      visita_cliente: this.state.filtroCliente,
      visita_direccion_calle: this.state.filtroDireccionCalle,
      visita_direccion_numero: this.state.filtroDireccionNumero,
      visita_prioridad: this.state.filtroPrioridad,
      visita_estado_actual: this.state.filtroEstadoActual,
    };

    const resultado = new URLSearchParams();

    for (const [claveFiltro, valorFiltro] of Object.entries(filtros)) {
      if (valorFiltro) {
        resultado.set(claveFiltro, valorFiltro);
      }
    }

    return resultado;
  };

  async setOrdenesVisita() {
    const queryParams = this.getQueryParams();
    const ordenesVisita = await this.repartoDiarioService.getOrdenesVisita(this.props.idRepartoDiario, queryParams);
    ordenesVisita.sort((a, b) => a.orden - b.orden);
    this.setState({ ordenVisitas: ordenesVisita });
  }

  async setRepartoDiario() {
    const repartoDiario = await this.repartoDiarioService.get(this.props.idRepartoDiario);
    this.setState({ repartoDiario: repartoDiario });
    return repartoDiario;
  }

  async setRecorridosReparto(repartoDiario: RepartoDiarioLectura) {
    let recorridosReparto = await this.repartoService.getRecorridosReparto(repartoDiario.reparto.id);
    this.setState({ recorridosReparto: recorridosReparto });
  }

  finalizoRepartoDiario = (repartoDiario: RepartoDiarioLectura): boolean => {
    return (
      [EstadoRepartoDiario.FINALIZADO, EstadoRepartoDiario.RINDIENDO].findIndex(
        (item) => item === repartoDiario.estado_actual
      ) !== -1
    );
  };

  eliminarVisita = async (repartoDiarioId: number, visitaId: number) => {
    try {
      await this.repartoDiarioService.eliminarVisita(repartoDiarioId, visitaId);
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS, {
        idRepartoDiario: repartoDiarioId,
      });
      Swal.fire({
        icon: "success",
        text: "Visita Eliminada",
      });
    } catch (error) {
      const msjError = ManejadorErroresHTTP.getMensajeError(error);
      Swal.fire({
        icon: "error",
        text: "No se pudo eliminar la visita - " + msjError,
      });
    }
  };

  onClickEliminarVisita = async (visita: VisitaLectura, repartoDiario: RepartoDiarioLectura) => {
    const result = await Swal.fire({
      icon: "question",
      title: "Confirma la acción?",
      showDenyButton: true,
      confirmButtonText: "Confirmar",
      denyButtonText: "Cancelar",
    });

    if (result.isDenied) {
      return;
    }

    this.eliminarVisita(repartoDiario.id, visita.id);
  };

  renderMenuGestiones = (visita: VisitaLectura, repartoDiario: RepartoDiarioLectura) => {
    const repartoDiarioFinalizado = this.finalizoRepartoDiario(repartoDiario);
    return (
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Button>Acciones</Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            onClick={() => {
              this.setState({ visitaFormulario: visita });
            }}
            disabled={repartoDiarioFinalizado}
          >
            Modificar
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              this.setState({ gestionVisita: { visitaId: visita.id, seccion: "ENTREGA" } });
            }}
            disabled={!repartoDiarioFinalizado}
          >
            Gestionar entrega
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              this.setState({ gestionVisita: { visitaId: visita.id, seccion: "VENTA" } });
            }}
            disabled={!repartoDiarioFinalizado}
          >
            Gestionar venta
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              this.setState({ gestionVisita: { visitaId: visita.id, seccion: "PROGRAMACION_ENTREGA" } });
            }}
            disabled={!repartoDiarioFinalizado}
          >
            Gestionar programación entrega
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              this.setState({ gestionVisita: { visitaId: visita.id, seccion: "ESTADO_VISITA" } });
            }}
            disabled={!repartoDiarioFinalizado}
          >
            Agregar estado
          </Menu.Item>
          <Menu.Item
            onClick={() => this.onClickEliminarVisita(visita, repartoDiario)}
            disabled={visita.estado_actual !== EstadoVisita.EN_ESPERA}
          >
            Eliminar
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  };

  renderModalGestionVisita = (gestionVisita: GestionVisita | null) => {
    let componenteGestion = null;
    if (gestionVisita === null) {
      return null;
    }

    if (gestionVisita.seccion === "VENTA") {
      componenteGestion = (
        <VentaVisitaUpdate visitaId={gestionVisita.visitaId} repartoDiarioId={this.props.idRepartoDiario} />
      );
    }
    if (gestionVisita.seccion === "ENTREGA") {
      componenteGestion = (
        <EntregaVisitaUpdate visitaId={gestionVisita.visitaId} repartoDiarioId={this.props.idRepartoDiario} />
      );
    }
    if (gestionVisita.seccion === "PROGRAMACION_ENTREGA") {
      componenteGestion = (
        <ProgramacionEntregaVisitaUpdate
          visitaId={gestionVisita.visitaId}
          repartoDiarioId={this.props.idRepartoDiario}
        />
      );
    }
    if (gestionVisita.seccion === "ESTADO_VISITA") {
      componenteGestion = (
        <VisitaEstadoUpdate idVisita={gestionVisita.visitaId} idRepartoDiario={this.props.idRepartoDiario} />
      );
    }

    return (
      <Modal
        centered
        size="70%"
        transitionDuration={0}
        opened={gestionVisita !== null}
        onClose={() => {
          this.setState({ gestionVisita: null });
        }}
        styles={{
          modal: {
            backgroundImage: ImagenesURLs.urlFondoModal(),
          },}}
        closeOnClickOutside={false}
        title="Gestión de la visita"
      >
        {componenteGestion}
      </Modal>
    );
  };

  renderModalResumenVisita = (visitaIdResumen: number | null) => {
    return (
      <Modal
        centered
        transitionDuration={0}
        opened={visitaIdResumen !== null}
        onClose={() => {
          this.setState({ visitaIdResumen: null });
        }}
        styles={{
          modal: {
            backgroundImage: ImagenesURLs.urlFondoModal(),
          },}}
        title="Resumen de la visita"
        overflow="inside"
      >
        {visitaIdResumen !== null ? (
          <ResumenVisitaRepartoDiario visitaId={visitaIdResumen} repartoDiarioId={this.props.idRepartoDiario} />
        ) : null}
      </Modal>
    );
  };

  renderTablaVisitas = (ordenVisitas: Array<OrdenVisitaRepartoDiarioLectura>, repartoDiario: RepartoDiarioLectura) => {
    if (ordenVisitas.length === 0) {
      return <div>No se encontraron visitas</div>;
    }

    const filas = ordenVisitas.map((ordenVisita) => (
      <tr key={ordenVisita.visita.id}>
        <td>{ordenVisita.visita.id}</td>
        <td>{ordenVisita.orden}</td>
        <td>{ordenVisita.visita.cliente.razon_social}</td>
        <td>{`${ordenVisita.visita.direccion.calle} N° ${ordenVisita.visita.direccion.numero} - ${ordenVisita.visita.direccion.localidad.nombre}`}</td>
        <td>
          <Badge color={getColorEstadoVisita(ordenVisita.visita.estado_actual)} variant="filled">
            {ordenVisita.visita.estado_actual}
          </Badge>
        </td>
        <td>
          <Badge color={getColorPrioridadVisita(ordenVisita.visita.prioridad)} variant="filled">
            {ordenVisita.visita.prioridad}
          </Badge>
        </td>
        <td>
          <Button
            onClick={() => {
              this.setState({ visitaIdResumen: ordenVisita.visita.id });
            }}
          >
            Ver resumen
          </Button>
        </td>
        <td>{this.renderMenuGestiones(ordenVisita.visita, repartoDiario)}</td>
      </tr>
    ));

    return (
      <Table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Orden</th>
            <th>Cliente</th>
            <th>Dirección</th>
            <th>Estado actual</th>
            <th>Prioridad</th>
            <th>Resumen</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{filas}</tbody>
      </Table>
    );
  };

  visitaVacia = (): VisitaLectura => {
    return {
      id: -1,
      estado_actual: EstadoVisita.EN_ESPERA,
      cliente: clienteVacio(),
      activo: false,
      observacion: "",
      cliente_nuevo: false,
      prioridad: PrioridadVisita.MEDIA,
      direccion: direccionVacia(),
      venta: null,
      entrega: null,
      programacion_entrega: null,
      reparto_diario: {
        id: -1,
        fecha_realizacion: new Date(),
        reparto: { id: -1, nombre: "", recorridos: [], tipo: "Comercio" },
        viaje_numero: 0,
      },
    };
  };

  guardarVisita = async (visitaFormulario: VisitaLectura) => {
    try {
      const visitaResultante = await this.repartoDiarioService.crearOModificarVisita(
        this.props.idRepartoDiario,
        visitaFormulario
      );
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS, {
        idRepartoDiario: this.props.idRepartoDiario,
        visitaId: visitaResultante.id,
      });
      await Swal.fire({
        icon: "success",
        text: "Visita guardada con éxito",
      });
      this.setState({ visitaFormulario: null });
    } catch (error) {
      const msjError = ManejadorErroresHTTP.getMensajeError(error);
      await Swal.fire({
        icon: "error",
        text: `Ocurrió un error: ${msjError}`,
      });
    }
  };

  crearVisitasRecorrido = async () => {
    if (this.state.recorridoSeleccionado === null) {
      return;
    }

    try {
      const visitasCreadas = await this.repartoDiarioService.crearVisitasRecorrido(
        this.props.idRepartoDiario,
        this.state.recorridoSeleccionado.id
      );
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS, {
        idRepartoDiario: this.props.idRepartoDiario,
      });
      await Swal.fire({
        icon: "success",
        text: `Se añadieron ${visitasCreadas.length} visitas`,
      });
      this.setState({ visitaFormulario: null });
    } catch (error) {
      const msjError = ManejadorErroresHTTP.getMensajeError(error);
      await Swal.fire({
        icon: "error",
        text: `Ocurrió un error: ${msjError}`,
      });
    }
  };

  renderAñadirVisitas = (
    visitaFormulario: VisitaLectura | null,
    recorridoSeleccionado: Recorrido | null,
    ordenVisitas: Array<OrdenVisitaRepartoDiarioLectura>
  ) => {
    const { recorridosReparto, repartoDiario } = this.state;

    if (recorridosReparto === null || repartoDiario === null) {
      return null;
    }

    let filtrosRecorridos;

    if (repartoDiario.fecha_realizacion) {
      const fechaRealizacion = FechaUtils.normalizarFecha(repartoDiario.fecha_realizacion);
      const diaSemana = diaNumToId(fechaRealizacion.getDay());
      const recorridosAExcluir = recorridosReparto.filter((elemento) => elemento.dia_reparto === diaSemana);
      const recorridosIdExcluidos = recorridosAExcluir.map((item) => item.recorrido.id);
      filtrosRecorridos = [excluirIds(recorridosIdExcluidos)];
    }

    return (
      <>
        {visitaFormulario ? (
          <Box
            showCloseBtn
            onCloseCallback={() => {
              this.setState({ visitaFormulario: null });
            }}
            padding="1rem"
          >
            <VisitaLoad
              visita={visitaFormulario}
              ocultarCampos={visitaFormulario.id > 0 ? ["direccion"] : undefined}
              onChange={(visitaLoad: VisitaLectura) => {
                this.setState({ visitaFormulario: visitaLoad });
              }}
              exluirDirecciones={ordenVisitas.map((ordVis) => ordVis.visita.direccion.id)}
            />
            <Button
              variant="success"
              onClick={() => {
                this.guardarVisita(visitaFormulario);
              }}
              disabled={visitaFormulario.direccion.id === -1}
            >
              Guardar visita
            </Button>
          </Box>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              this.setState({ visitaFormulario: this.visitaVacia() });
            }}
          >
            Añadir visita individual
          </Button>
        )}
        <div style={{ marginTop: "1rem" }}>
          <RecorridosShow
            version={VersionRecorridosShow.SELECT}
            propsSelect={{
              callbackParent: (recorrido: Recorrido | null) => {
                this.setState({ recorridoSeleccionado: recorrido });
              },
              seleccionado: recorridoSeleccionado,
              placeholder: "Seleccione un recorrido",
            }}
            filtros={filtrosRecorridos}
          />
          <Button
            style={{ marginTop: "1rem" }}
            variant="success"
            disabled={recorridoSeleccionado === null}
            onClick={() => {
              this.crearVisitasRecorrido();
            }}
          >
            Añadir visitas por recorrido
          </Button>
        </div>
      </>
    );
  };

  renderPanelBusqueda = () => {
    const minColWidth = "250px";
    const { filtroCliente, filtroEstadoActual, filtroPrioridad, filtroDireccionCalle, filtroDireccionNumero } =
      this.state;

    return (
      <Form
        style={{
          padding: "1rem",
          backgroundColor: "gainsboro",
          borderRadius: "5px",
          marginBottom: "1rem",
        }}
      >
        <Form.Row className="align-items-center" style={{ rowGap: "1rem" }}>
          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <Form.Label as="strong">Cliente</Form.Label>
              <Input
                type={InputTypes.Text}
                data={filtroCliente}
                onChange={(data: string) => this.setState({ filtroCliente: data })}
                placeholder="Ingrese razon social del cliente"
              />
            </Flex>
          </Col>

          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <Form.Label as="strong">Direccion</Form.Label>
              <Input
                type={InputTypes.Text}
                data={filtroDireccionCalle}
                onChange={(data: string) => this.setState({ filtroDireccionCalle: data })}
                placeholder="Ingrese calle"
              />
              <Input
                type={InputTypes.Text}
                data={filtroDireccionNumero}
                onChange={(data: string) => this.setState({ filtroDireccionNumero: data })}
                placeholder="Ingrese numero"
              />
            </Flex>
          </Col>

          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <Form.Label as="strong">Estado actual</Form.Label>
              <Select
                placeholder="Seleccione estado"
                value={filtroEstadoActual}
                clearable
                data={[
                  { value: EstadoVisita.EN_ESPERA, label: EstadoVisita.EN_ESPERA },
                  { value: EstadoVisita.EN_CAMINO, label: EstadoVisita.EN_CAMINO },
                  { value: EstadoVisita.NO_ENCONTRADO, label: EstadoVisita.NO_ENCONTRADO },
                  { value: EstadoVisita.NO_VISITADO, label: EstadoVisita.NO_VISITADO },
                  { value: EstadoVisita.VISITADO, label: EstadoVisita.VISITADO },
                ]}
                onChange={(estadoSeleccionado: EstadoVisita | null) => {
                  this.setState({ filtroEstadoActual: estadoSeleccionado });
                }}
              />
            </Flex>
          </Col>

          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <Form.Label as="strong">Prioridad</Form.Label>
              <Select
                placeholder="Seleccione prioridad"
                value={filtroPrioridad}
                clearable
                data={[
                  { value: PrioridadVisita.BAJA, label: PrioridadVisita.BAJA },
                  { value: PrioridadVisita.MEDIA, label: PrioridadVisita.MEDIA },
                  { value: PrioridadVisita.ALTA, label: PrioridadVisita.ALTA },
                  { value: PrioridadVisita.URGENTE, label: PrioridadVisita.URGENTE },
                ]}
                onChange={(prioridad: PrioridadVisita | null) => {
                  this.setState({ filtroPrioridad: prioridad });
                }}
              />
            </Flex>
          </Col>
          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <RequestButton onClick={() => this.setOrdenesVisita()} texto="Buscar" icon={<Icon.Search />} />
            </Flex>
          </Col>
        </Form.Row>
      </Form>
    );
  };

  render() {
    const { ordenVisitas, visitaIdResumen, gestionVisita, repartoDiario, visitaFormulario, recorridoSeleccionado } =
      this.state;

    if (ordenVisitas === null || repartoDiario === null) {
      return <div>Cargando...</div>;
    }

    return (
      <>
        {this.renderPanelBusqueda()}
        {this.renderTablaVisitas(ordenVisitas, repartoDiario)}
        {this.finalizoRepartoDiario(repartoDiario)
          ? null
          : this.renderAñadirVisitas(visitaFormulario, recorridoSeleccionado, ordenVisitas)}
        {this.renderModalResumenVisita(visitaIdResumen)}
        {this.renderModalGestionVisita(gestionVisita)}
      </>
    );
  }
}
