import React, { Component } from "react";
import Flex from "../flex/Flex";



import { Localidad, LocalidadService} from "serviciossaintmichel";
import { Global } from "../../../Global";



interface Props {
  localidadId: number;
}

interface State {
  localidad: Localidad | null;
}

export default class DatoGeograficoShow extends Component<Props, State> {

  private localidadService:LocalidadService;

  constructor(props: Props) {
    super(props);
    this.state = {
      localidad: null,
    };

    this.localidadService = new LocalidadService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.setLocalidad(this.props.localidadId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.localidadId !== this.props.localidadId) {
      this.setLocalidad(this.props.localidadId);
    }
  }

  setLocalidad = async (localidadId: number) => {
    try {
      const localidad = await this.localidadService.get(localidadId);
      this.setState({ localidad: localidad });
    } catch (error) {
      this.localidadService.manejarErrorHTTP(error, "Localidad");
    }
  };

  render() {
    const { localidad } = this.state;

    if (localidad === null) {
      return <div>Cargando...</div>;
    }

    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <div>Provincia: {localidad.partido.provincia.nombre}</div>
        <div>Partido: {localidad.partido.nombre}</div>
        <div>Localidad: {localidad.nombre}</div>
      </Flex>
    );
  }
}
