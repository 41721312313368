import React, { Component } from "react";
import { AlquilerCliente, AlquilerClienteService, AlquilerGet, AlquilerService, CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import FechaUtils from "../../../../../../../compartido/utils/FechaUtils";
import { Global } from "../../../../../../../Global";
import RequestButton from "../../../../../../../libreria/action/request-button/RequestButton";


export interface Props {
  alquilerClienteId: number;
  showDeleteBtn: boolean;
}

interface State {
  alquiler: AlquilerGet | null;
  alquilerCliente: AlquilerCliente | null;
}

export default class AlquilerClienteShow extends Component<Props, State> {

  private alquilerClienteService:AlquilerClienteService;
  private alquilerService:AlquilerService;

  private webSocket: WebSocket | null;
  constructor(props: Props) {
    super(props);
    this.state = {
      alquiler: null,
      alquilerCliente: null,
    };
    this.webSocket = null;

    this.alquilerClienteService = new AlquilerClienteService(Global.UsuarioService.getToken()!);
    this.alquilerService = new AlquilerService(Global.UsuarioService.getToken()!);

  }

  componentWillUnmount = () => {
    this.webSocket?.close();
  };

  componentDidMount = async () => {
    try {
      this.webSocket = WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_ALQUILERES,
        funcionCallback: this.alquilerClienteWSHandler,
      });
      const alquilerCliente = await this.alquilerClienteService.get(
        this.props.alquilerClienteId
      );
      const alquiler = await this.alquilerService.get(alquilerCliente.alquiler);
      this.setState({ alquiler: alquiler, alquilerCliente: alquilerCliente });
    } catch (error) {
      this.alquilerClienteService.manejarErrorHTTP("AlquilerCliente");
    }
  };

  alquilerClienteWSHandler = async () => {
    const alquilerCliente = await this.alquilerClienteService.get(
      this.props.alquilerClienteId
    );
    this.setState({ alquilerCliente: alquilerCliente });
  };

  eliminarAlquilerCliente = async (alquilerClienteId: number) => {
    try {
      this.alquilerClienteService.delete(alquilerClienteId);
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_SERVICIO_ACTIVO,
        {
          clienteId: this.state.alquilerCliente?.cliente,
        }
      );
    } catch (error) {
      this.alquilerClienteService.manejarErrorHTTP("AlquilerCliente");
    }
  };

  render = () => {
    const { alquiler, alquilerCliente } = this.state;
    const { showDeleteBtn } = this.props;

    if (alquiler === null || alquilerCliente === null) {
      return <div>Cargando datos...</div>;
    }

    const fechaInicio = FechaUtils.normalizarFecha(
      alquilerCliente.fecha_inicio
    );
    let fechaFin = alquilerCliente.fecha_fin;
    if (alquilerCliente.fecha_fin) {
      fechaFin = FechaUtils.normalizarFecha(alquilerCliente.fecha_fin);
    }

    return (
      <>
        <Flex container flexDirection="column">
          <span>Nombre del alquiler</span>
          <div>{alquiler.nombre}</div>
        </Flex>
        <Flex container flexDirection="column">
          <span>Precio regular del alquiler</span>
          <div>{alquiler.precio}</div>
        </Flex>
        <Flex container flexDirection="column">
          <span>Precio especial</span>
          <div>{alquilerCliente.precio_especial ?? "-"}</div>
        </Flex>
        <Flex container flexDirection="column">
          <span>Fecha de inicio</span>
          <div>{fechaInicio.toLocaleDateString("es-AR")}</div>
        </Flex>
        <Flex container flexDirection="column">
          <span>Fecha de finalización</span>
          <div>
            {fechaFin === null ? "-" : fechaFin.toLocaleDateString("es-AR")}
          </div>
        </Flex>
        <Flex
          container
          flexDirection="row"
          justifyContent="center"
          marginTop="auto"
        >
          {showDeleteBtn && (
            <RequestButton
              texto="Eliminar"
              propsBoton={{
                variant: "danger",
              }}
              onClick={() => {
                this.eliminarAlquilerCliente(alquilerCliente.id);
              }}
              confirmacion={true}
            />
          )}
        </Flex>
      </>
    );
  };
}
