import React, { Component } from "react";
import { CANALES_WEB_SOCKET, ConsumoService, DatosConsumo, WebSocketService } from "serviciossaintmichel";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import { Global } from "../../../../../Global";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Box from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import ConsumoLoad from "../load/ConsumoLoad";





export interface Props {
  onSaveSuccess: Function;
  onExit?: Function;
}

interface State {
  consumo: DatosConsumo;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class ConsumoNew extends Component<Props, State> {

  private consumoService:ConsumoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      consumo: this.createEmptyConsumo(),
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.consumoService =  new ConsumoService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  createEmptyConsumo = () => {
    let x: DatosConsumo = {
      id: -1,
      nombre: "",
      fecha_creacion: new Date(),
      especial: false,
    };
    return x;
  };

  onSave = () => {
    return this.consumoService.create(this.state.consumo).then((x) => {
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CONSUMOS,
        { type: "new", id: x.id }
      );
      this.props.onSaveSuccess(x.id);
    });
  };

  onExit = () => {
    if (this.props.onExit != undefined) this.props.onExit();
  };

  onChange = (consumo: DatosConsumo) => {
    this.setState({ consumo: consumo });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex
      flexDirection="column"
      width="50%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={3} texto={"Consumo Nuevo"} />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <ConsumoLoad
            consumo={this.state.consumo}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
          />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Crear"}
            onClick={this.onSave}
          />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
