import React, { Component } from "react";
import { PromocionPost } from "serviciossaintmichel";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../../libreria/data/input/Input";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  promocion: PromocionPost;
}
interface State {
  promocion: PromocionPost;
  validation: boolean;
}

export default class PromocionLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      promocion: this.props.promocion,
      validation: false,
    };
  }

  componentDidMount = () => {
    this.updateValidation(this.state.promocion);
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps != this.props) {
      this.setState({ promocion: this.props.promocion });
    }
  };

  isValid = (promocion: PromocionPost) => {
    return promocion.nombre != null ? true : false;
  };

  updateValidation = (promocion: PromocionPost) => {
    let validation: boolean = this.isValid(promocion);
    this.setState({ validation: validation });
    if (this.props.onValidationChange)
      this.props.onValidationChange(validation);
  };

  setearNombre = (nombre: string) => {
    let promocion = this.state.promocion;
    promocion.nombre = nombre;
    this.setState({ promocion: promocion });
    this.props.onChange(promocion);
    this.updateValidation(promocion);
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Input
          data={this.state.promocion.nombre}
          label={"Nombre"}
          placeholder={"Nombre"}
          onChange={this.setearNombre}
          type={InputTypes.Text}
        />
      </Flex>
    );
  }
}
