import { Select } from "@mantine/core";
import React, { Component } from "react";
import { Col, Form } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import RequestButton from "../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../libreria/appearance/flex/Flex";
import RepartosShow, { Version } from "../../../repartos/components/show/RepartosShow";

import { EstadoRepartoDiario, Reparto } from "serviciossaintmichel";

export interface Props {
  busquedaHandler: Function;
  setFiltros: Function;
  filtros: {
    desde: Date | null;
    hasta: Date | null;
    estadoActual: EstadoRepartoDiario | null;
    repartoBusqueda: Reparto | null;
  };
}

interface State {}

export default class RepartosDiariosFilter extends Component<Props, State> {
  render = () => {
    const { filtros } = this.props;
    const minColWidth = "250px";

    return (
      <Form
        style={{
          padding: "1rem",
          backgroundColor: "gainsboro",
          borderRadius: "5px",
          marginBottom: "1rem",
        }}
      >
        <Form.Row className="align-items-center" style={{ rowGap: "1rem" }}>
          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <Form.Label as="strong">Reparto</Form.Label>
              <RepartosShow
                version={Version.SELECT}
                propsSelect={{
                  callbackParent: (reparto: Reparto | null) => {
                    this.props.setFiltros({ repartoBusqueda: reparto });
                  },
                  seleccionado: filtros.repartoBusqueda ? filtros.repartoBusqueda.id : null,
                  clearable: true,
                }}
              />
            </Flex>
          </Col>
          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <Form.Label as="strong">Desde</Form.Label>
              <DatePicker
                placeholderText="Ingrese fecha"
                onChange={(fecha: Date) => {
                  this.props.setFiltros({ desde: fecha });
                }}
                selected={filtros.desde}
                dateFormat="dd/MM/yyyy"
                isClearable
              />
            </Flex>
          </Col>
          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <Form.Label as="strong">Hasta</Form.Label>
              <DatePicker
                placeholderText="Ingrese fecha"
                onChange={(fecha: Date) => {
                  this.props.setFiltros({ hasta: fecha });
                }}
                selected={filtros.hasta}
                dateFormat="dd/MM/yyyy"
                isClearable
              />
            </Flex>
          </Col>
          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <Form.Label as="strong">Estado actual</Form.Label>
              <Select
                placeholder="Seleccione estado"
                value={filtros.estadoActual}
                clearable
                data={[
                  { value: EstadoRepartoDiario.NO_INICIADO, label: EstadoRepartoDiario.NO_INICIADO },
                  { value: EstadoRepartoDiario.EN_RECORRIDO, label: EstadoRepartoDiario.EN_RECORRIDO },
                  { value: EstadoRepartoDiario.AVERIADO, label: EstadoRepartoDiario.AVERIADO },
                  { value: EstadoRepartoDiario.DETENIDO, label: EstadoRepartoDiario.DETENIDO },
                  { value: EstadoRepartoDiario.EN_REGRESO, label: EstadoRepartoDiario.EN_REGRESO },
                  { value: EstadoRepartoDiario.RINDIENDO, label: EstadoRepartoDiario.RINDIENDO },
                  { value: EstadoRepartoDiario.FINALIZADO, label: EstadoRepartoDiario.FINALIZADO },
                ]}
                onChange={(estadoSeleccionado: EstadoRepartoDiario | null) => {
                  this.props.setFiltros({ estadoActual: estadoSeleccionado });
                }}
              />
            </Flex>
          </Col>
          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <RequestButton
                onClick={() => {
                  this.props.busquedaHandler();
                }}
                texto="Buscar"
                icon={<Icon.Search />}
              />
            </Flex>
          </Col>
        </Form.Row>
      </Form>
    );
  };
}
