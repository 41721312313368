import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import FormularioPersona from "../../../../../../../compartido/components/formularios/FormularioPersona";
import { Global } from "../../../../../../../Global";
import { CANALES_WEB_SOCKET, Persona, PersonaService, WebSocketService } from "serviciossaintmichel";




interface Props {
  personaId: number;
}
interface State {
  persona: Persona | null;
}
export default class PersonaResponsable extends Component<Props, State> {

  private personaService:PersonaService;

  constructor(props: Props) {
    super(props);
    this.state = { persona: null };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.COMPARTIDO_PERSONAS,
      funcionCallback: this.procesarMensajeCanal,
    });

    this.personaService = new PersonaService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.cargarPersona(this.props.personaId);
  }

  procesarMensajeCanal = (e: MessageEvent) => {
    const { personaId } = this.props;
    let payload = JSON.parse(e.data);
    if (payload.message.personaId === personaId) {
      this.cargarPersona(personaId);
    }
  };

  cargarPersona = async (personaId: number) => {
    const persona = await this.personaService.get(personaId);
    this.setState({ persona: persona });
  };

  render() {
    const { persona } = this.state;
    if (persona === null) {
      return null;
    }

    return <FormularioPersona persona={persona} />;
  }
}
