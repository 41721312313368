import React, { Component } from "react";
import Flex from "../flex/Flex";
import {
  GoogleMap,
  LoadScript,
  Marker,
} from "@react-google-maps/api"; /* global google */ /* eslint-disable no-undef */
import {  Localizacion,LatLng, AdaptadorGisGeosGoogleMap, DireccionDTO, LocalidadService } from "serviciossaintmichel";
import { Global } from "../../../Global";

interface Props {
  direccion: DireccionDTO;
  onChange: Function;
}

interface State {
  localizacion: Localizacion | null;
  address: string | null;
  localizacionAnterior: Localizacion | null;
  estadoNuevaLocalizacion: boolean;
}

export default class ComponenteLocalizacion2 extends Component<Props, State> {

  private localidadService:LocalidadService;

  private localizacionPorDefecto: LatLng;
  private mapa: any;
  private geocoder: any;

  constructor(props: Props) {
    super(props);

    this.localizacionPorDefecto = { lat: -34.912185, lng: -57.957313 };

    this.state = {
      localizacion: null,
      localizacionAnterior: null,
      address: null,
      estadoNuevaLocalizacion: false,
    };

    this.localidadService = new LocalidadService(Global.UsuarioService.getToken()!);

  }

  load = (map: any) => {
    this.mapa = map;
    this.geocoder = new google.maps.Geocoder();
  };

  componentDidMount = async () => {
    const { direccion } = this.props;
    const cadenaDireccion = await this.getDireccionAsString(direccion);
    let localizacion = direccion.localizacion;

    if (localizacion !== null && localizacion.coordinates[0] === 0.0 && localizacion.coordinates[1] === 0.0) {
      localizacion = null;
    }

    this.setState({ localizacion: localizacion, localizacionAnterior: localizacion, address: cadenaDireccion });
  };

  setNuevaLocalizacion = (localizacion: Localizacion | null) => {
    this.setState({
      localizacionAnterior: localizacion,
      estadoNuevaLocalizacion: false,
    });
  };

  localizacionNueva = (localizacion: Localizacion | null): boolean => {
    const { localizacionAnterior } = this.state;

    if (localizacionAnterior == null && localizacion != null) return true;
    else if (localizacionAnterior != null && localizacion == null) return true;
    else if (localizacionAnterior != null && localizacion != null) {
      if (
        localizacionAnterior.coordinates[0] === localizacion.coordinates[0] &&
        localizacionAnterior.coordinates[1] === localizacion.coordinates[1]
      )
        return false;
      else return true;
    } else return false;
  };

  getDireccionAsString = async (direccion: DireccionDTO): Promise<string> => {
    if (direccion.localidad === -1) {
      return "";
    }

    const localidad = await this.localidadService.get(direccion.localidad);
    return (
      direccion.calle +
      " " +
      direccion.numero +
      " , " +
      localidad.nombre +
      " , " +
      localidad.partido.nombre +
      " , " +
      localidad.partido.provincia.nombre +
      " , Argentina"
    );
  };

  geoLocalizar = async (direccion: DireccionDTO) => {
    const address = await this.getDireccionAsString(direccion);

    let localizacion: LatLng | null = null;

    if (this.geocoder) {
      this.geocoder.geocode(
        { address: address },
        (results: Array<google.maps.GeocoderResult>, status: google.maps.GeocoderStatus) => {
          //si el estado de la llamado es OK
          if (status === google.maps.GeocoderStatus.OK) {
            localizacion = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            };

            let aux_localizacion: Localizacion = new AdaptadorGisGeosGoogleMap().toGGPoint(localizacion);
            let estado: boolean = this.localizacionNueva(aux_localizacion);

            this.setState({
              localizacion: aux_localizacion,
              estadoNuevaLocalizacion: estado,
              address: address,
            });
            this.props.onChange(aux_localizacion);
          } else {
            let estado = this.localizacionNueva(null);

            this.setState({
              localizacion: null,
              address: address,
              estadoNuevaLocalizacion: estado,
            });
            this.props.onChange(null);
          }
        }
      );
    }
  };

  render() {
    const { localizacion, estadoNuevaLocalizacion, address } = this.state;

    return (
      <Flex container flexDirection="column" width="100%">
        <Flex container flexDirection="row" alignItems="center" justifyContent="center">
          <div>
            <h5>Localización</h5>
          </div>
        </Flex>
        <br />
        <Flex container flexDirection="column" alignItems="flex-start" justifyContent="center">
          <div>
            <p>Dirección: {address}</p>
          </div>
          <div>
            <p>Estado Localización: {localizacion ? "Dirección Localizada" : "Dirección No Localizada"}</p>
          </div>
          {estadoNuevaLocalizacion ? (
            <div>
              <p style={{ color: "blue" }}> Atención: La localización ha cambiado recuerde guardar!!!! </p>
            </div>
          ) : null}
        </Flex>
        <br />

        <Flex container flexDirection="column" height="400px" width="100%">
          <LoadScript
            id="script-loader"
            googleMapsApiKey="AIzaSyDG1j7AQa8_IW7_cxmRTH0kMsZdLf3akpg"
            libraries={["drawing", "geometry"]}
          >
            <GoogleMap
              id="example-map"
              mapContainerStyle={{
                height: "100%",
                width: "100%",
                margin: "auto",
              }}
              ref={(map) => (this.mapa = map)}
              zoom={12}
              center={localizacion ? new AdaptadorGisGeosGoogleMap().toGMLatLng(localizacion) : this.localizacionPorDefecto}
              onLoad={this.load}
            >
              {localizacion ? <Marker position={new AdaptadorGisGeosGoogleMap().toGMLatLng(localizacion)} /> : null}
            </GoogleMap>
          </LoadScript>
        </Flex>
      </Flex>
    );
  }
}
