import React, { Component } from "react";
import Swal from "sweetalert2";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../libreria/appearance/flex/Flex";

import DireccionesShow, { Version } from "./DireccionesShow";
import Margin from "../../../../../libreria/appearance/margin/Margin";
import Text from "../../../../../libreria/message/text/Text";
import { CANALES_WEB_SOCKET, RelacionRecorridoDireccion, WebSocketService,RecorridoService,DireccionRecorridoService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";









export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  idRecorrido: number;
}
interface State {
  direcciones: Array<RelacionRecorridoDireccion>;
  direccionSeleccionada: RelacionRecorridoDireccion | null;
  cargando: boolean;
}
interface Message {
  type: string;
  idRecorrido: number;
  idDireccion: number;
}

export default class DireccionesRecorridoUpdate extends Component<
  Props,
  State
> {

  private recorridoService:RecorridoService;
  private direccionRecorridoService:DireccionRecorridoService;
  
  constructor(props: Props) {
    super(props);
    this.state = {
      direcciones: [],
      direccionSeleccionada: null,
      cargando: true,
    };

    this.direccionRecorridoService = new DireccionRecorridoService(Global.UsuarioService.getToken()!);
    this.recorridoService = new RecorridoService(Global.UsuarioService.getToken()!);

    /*  window.addEventListener("resize", this.actualizarDimensiones); */
  }
  /*
  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  }; */

  componentDidMount = async () => {
    const direcciones =
      await this.direccionRecorridoService.getDireccionesDeRecorrido(
        this.props.idRecorrido
      );
    /* let direccionesOrdenados = direcciones
      ? direcciones.sort((a, b) => a.orden - b.orden)
      : []; */
    await this.setState({
      direcciones: this.getCopy(direcciones),
      cargando: false,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS,
      funcionCallback: this.onChannelMesagge,
    });
  };

  getCopy = (
    direcciones: Array<RelacionRecorridoDireccion>
  ): Array<RelacionRecorridoDireccion> => {
    let direccionesCopy: Array<RelacionRecorridoDireccion> = [];
    direcciones.map((x) =>
      direccionesCopy.push({
        direccion: x.direccion,
        orden: x.orden,
      })
    );
    return direccionesCopy;
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.recorridoId == this.props.idRecorrido) {
      const direcciones =
        await this.direccionRecorridoService.getDireccionesDeRecorrido(
          this.props.idRecorrido
        );

      let direSeleccionada;
      if (this.state.direccionSeleccionada)
        direSeleccionada = direcciones.find(
          (dire) =>
            dire.direccion.id == this.state.direccionSeleccionada?.direccion.id
        );

      this.setState({
        direcciones: this.getCopy(direcciones),
        direccionSeleccionada: direSeleccionada ? direSeleccionada : null,
        cargando: false,
      });
    }
  };

  componentDidUpdate = (oldProps: Props, oldState: State) => {
    if (this.props != oldProps) {
    }
    if (
      this.state.direccionSeleccionada != oldState.direccionSeleccionada ||
      (this.state.direccionSeleccionada &&
        oldState.direccionSeleccionada &&
        this.state.direccionSeleccionada.direccion.id !=
          oldState.direccionSeleccionada.direccion.id)
    ) {
      console.log("Cambio la direccion seleccionada");
    }
  };

  subirOrden = async () => {
    if (this.state.direccionSeleccionada)
      return this.direccionRecorridoService.putOrdenDireccionRecorrido(
        this.props.idRecorrido,
        this.state.direccionSeleccionada.direccion.id,
        this.state.direccionSeleccionada.orden + 1
      )
        .then((recorrido) => {
          WebSocketService.enviarMensaje(
            CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS,
            { type: "UPDATE", recorridoId: recorrido.recorrido }
          );
          WebSocketService.enviarMensaje(
            CANALES_WEB_SOCKET.COMPARTIDO_DIRECCION,
            { type: "UPDATE", direccionId: recorrido.direccion }
          );
          Swal.fire({
            icon: "success",
            text: "Cambio en la ubicacion de la direccion dentro del Recorrido",
          });
        })
        .catch((error) => {
          this.recorridoService.manejarErrorHTTP(error, "Recorrido");
        });
  };

  bajarOrden = async () => {
    if (this.state.direccionSeleccionada)
      return this.direccionRecorridoService.putOrdenDireccionRecorrido(
        this.props.idRecorrido,
        this.state.direccionSeleccionada.direccion.id,
        this.state.direccionSeleccionada.orden - 1
      )
        .then((recorrido) => {
          WebSocketService.enviarMensaje(
            CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS,
            { type: "UPDATE", recorridoId: recorrido.recorrido }
          );
          WebSocketService.enviarMensaje(
            CANALES_WEB_SOCKET.COMPARTIDO_DIRECCION,
            { type: "UPDATE", direccionId: recorrido.direccion }
          );
          Swal.fire({
            icon: "success",
            text: "Cambio en la ubicacion de la direccion dentro del Recorrido",
          });
        })
        .catch((error) => {
          this.recorridoService.manejarErrorHTTP(error, "Recorrido");
        });
  };

  onSelect = (id: number | null) => {
    console.log(id);
    const direccionSeleccionada = this.state.direcciones.find(
      (relacion) => relacion.direccion.id == id
    );
    this.setState({
      direccionSeleccionada: direccionSeleccionada
        ? direccionSeleccionada
        : null,
    });
  };

  direccionesId(n: Array<RelacionRecorridoDireccion>) {
    let ids = this.state.direcciones.map((relacion) => {
      return relacion.direccion.id;
    });
    console.log("ids: " + ids);
    return ids;
  }

  renderizar = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      {this.state.direcciones.length > 0 ? (
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <DireccionesShow
            version={Version.LISTBOX}
            direccionesMostrar={this.direccionesId(this.state.direcciones)}
            onSelect={this.onSelect}
            seleccionado={this.state.direccionSeleccionada?.direccion.id}
          />
          <Flex
            flexDirection="row"
            width="100%"
            alignItems="center"
            justifyContent="center"
            marginTop="20px"
          >
            <RequestButton
              propsBoton={{
                disabled:
                  !this.state.direccionSeleccionada ||
                  this.state.direccionSeleccionada.orden == 0,
              }}
              texto={"Subir"}
              onClick={this.bajarOrden}
            />
            <RequestButton
              propsBoton={{
                disabled:
                  !this.state.direccionSeleccionada ||
                  this.state.direccionSeleccionada.orden ==
                    this.state.direcciones.length - 1,
              }}
              texto={"Bajar"}
              onClick={this.subirOrden}
            />
          </Flex>
          <Margin top="30px" />
          <DireccionesShow
            version={Version.LOCATIONMAP}
            direccionesMostrar={this.direccionesId(this.state.direcciones)}
            onSelect={this.onSelect}
            seleccionado={this.state.direccionSeleccionada?.direccion.id}
          />
        </Flex>
      ) : (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Text>Este recorrido no posee direcciones asociadas</Text>
        </Flex>
      )}
    </Flex>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.renderizar()}
      </Flex>
    );
  }
}
