import React, { Component } from "react";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import Visibilizador from "../../../../../libreria/action/visibilizador/Visibilizador";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Text from "../../../../../libreria/message/text/Text";

import { CANALES_WEB_SOCKET,Reparto,  RecorridoService,  WebSocketService, RepartoService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";



export interface Props {
  idRecorrido: number;
}
interface State {
  reparto: Reparto | null;
  diaReparto: string;
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
}

export default class RepartoRecorridoShow extends Component<Props, State> {

  private recorridoService:RecorridoService;
  private repartoService:RepartoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      reparto: null,
      diaReparto: "",
      cargando: true,
      visible: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.recorridoService =  new RecorridoService(Global.UsuarioService.getToken()!);
    this.repartoService =  new RepartoService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {
    await this.cargarRecorrido();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS,
      funcionCallback: this.onChannelMesagge,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS,
      funcionCallback: this.onChannelMesaggeReparto,
    });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.recorridoId == this.props.idRecorrido) {
      await this.cargarRecorrido();
    }
  };

  onChannelMesaggeReparto = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (this.state.reparto && dato.message.repartoId == this.state.reparto) {
      await this.cargarReparto(this.state.reparto.id);
    }
  };

  cargarRecorrido = async () => {
    let recorrido = await this.recorridoService.get(this.props.idRecorrido).catch(
      (error) => {
        this.recorridoService.manejarErrorHTTP(error, "Recorrido");
        return null;
      }
    );
    let reparto: Reparto | null = null;
    let diaReparto: string = "";
    if (recorrido != null) {
      reparto = await this.repartoService.get(recorrido.reparto).catch((error) => {
        this.repartoService.manejarErrorHTTP(error, "Reparto");
        return null;
      });
      diaReparto = recorrido.dia_reparto;
    }
    this.setState({
      reparto: reparto,
      diaReparto: diaReparto,
      cargando: false,
    });
  };

  cargarReparto = async (repartoId: number) => {
    let reparto: Reparto | null = null;
    reparto = await this.repartoService.get(repartoId).catch((error) => {
      this.repartoService.manejarErrorHTTP(error, "Reparto");
      return null;
    });
    this.setState({
      reparto: reparto,
    });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      await this.setState({ cargando: true });
      await this.cargarRecorrido();
    }
  };

  renderDatos = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      {this.state.reparto ? (
        <>
          <Text>Reparto: {this.state.reparto.nombre}</Text>
          <Text>Dia del Reparto: {this.state.diaReparto}</Text>
        </>
      ) : (
        <Text>"No tiene Reparto asignado"</Text>
      )}
    </Flex>
  );

  renderLoading = () => "Cargando Reparto";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="20px"
      >
        <Visibilizador
          onChange={this.onChangeVisibilizador}
          label={"Reparto"}
          labelAlignment={"flex-start"}
        >
          {this.state.cargando ? this.renderLoading() : this.renderDatos()}
        </Visibilizador>
      </Flex>
    );
  }
}
