import React, { Component } from "react";
import { TipoProductoGet } from "serviciossaintmichel";
import SelectGenerico from "../../../../compartido/components/select/SelectGenerico";

interface Props {
  callbackParent: Function;
  seleccionado: TipoProductoGet;
  tiposProductos: Array<TipoProductoGet>;
}
interface State {}

export default class SelectTipoProducto extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado,
    };
  }

  getPosicion = (tipoProducto: TipoProductoGet): number => {
    return this.props.tiposProductos.findIndex((e) => e.id === tipoProducto.id);
  };
  getSeleccionado = (indice: number) => {
    this.props.callbackParent(this.props.tiposProductos[indice]);
  };

  render() {
    return (
      <SelectGenerico
        seleccionado={this.getPosicion(this.props.seleccionado)}
        elementos={this.props.tiposProductos.map((t) => t.tipo)}
        callback={this.getSeleccionado}
      />
    );
  }
}
