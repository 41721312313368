import React, { Component } from "react";
import { Button } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import * as Icon from "react-bootstrap-icons";

import { OrdenCompraProducto, ProductoGet, ProductoService } from "serviciossaintmichel";
import { Global } from "../../../../../../../../Global";



export interface Props {
  ordenesCompraProducto: Array<OrdenCompraProducto>;
  showDelete?: boolean;
  deleteHandler?: Function;
}

interface State {
  productosInfo: Map<number, ProductoGet> | null;
}

export default class OrdenesCompraProductoShow extends Component<Props, State> {

  private productoService:ProductoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      productosInfo: null,
    };

    this.productoService =  new ProductoService(Global.UsuarioService.getToken()!);



  }

  componentDidMount = async () => {
    let productos = await this.productoService.listAll();
    const prodMap = new Map();
    for (let prod of productos) {
      prodMap.set(prod.id, prod);
    }
    this.setState({ productosInfo: prodMap });
  };

  handleOnDelete = (idx: number) => {
    if (this.props.deleteHandler) {
      this.props.deleteHandler(idx);
    }
  };

  render = () => {
    const { ordenesCompraProducto } = this.props;
    const { productosInfo } = this.state;

    if (productosInfo === null) {
      return null;
    }

    return (
      <ListGroup as="ol">
        {ordenesCompraProducto.map((ocp, idx) => (
          <ListGroup.Item
            as="li"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div>{productosInfo.get(ocp.producto)?.nombre}</div>
              <div>{`Cantidad: ${ocp.cantidad}`}</div>
              <div>{`Precio: $${ocp.precio}`}</div>
            </div>
            {this.props.showDelete && (
              <Button
                variant="danger"
                onClick={() => {
                  this.handleOnDelete(idx);
                }}
              >
                <Icon.Trash />
              </Button>
            )}
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  };
}
