import React, { Component } from "react";
import { AlquilerService, CANALES_WEB_SOCKET, PrecioAlquiler, WebSocketService } from "serviciossaintmichel";
import Swal from "sweetalert2";
import RequestButton from "../../../../../../compartido/components/request-button/RequestButton";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import { Global } from "../../../../../../Global";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../../libreria/containers/box/Box";
import H from "../../../../../../libreria/message/title/H";
import PrecioAlquilerLoad from "./PrecioAlquilerLoad";





export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  precioAlquiler: PrecioAlquiler;
}
interface State {
  precioAlquilerNew: PrecioAlquiler;
  precioAlquilerOld: PrecioAlquiler;
  screen: ScreenType;
  valid: boolean;
}

interface Message {
  type: string;
  idAlquiler: number;
}

export default class PrecioAlquilerUpdate extends Component<Props, State> {

  private alquilerService:AlquilerService;

  constructor(props: Props) {
    super(props);
    this.state = {
      precioAlquilerOld: this.props.precioAlquiler,
      precioAlquilerNew: {
        precio: this.props.precioAlquiler.precio,
        alquiler: this.props.precioAlquiler.alquiler,
      },
      screen: Screen.getType(window.innerWidth, window.innerHeight),
      valid: false,
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES_PRECIOS,
      funcionCallback: this.onChannelMesagge,
    });

    this.alquilerService =  new AlquilerService(Global.UsuarioService.getToken()!);

  }

  onChannelMesagge = async (e: MessageEvent) => {
    let message: Message = JSON.parse(e.data).message;

    if (
      message.idAlquiler == this.props.precioAlquiler.alquiler &&
      message.type == "update"
    ) {
      const alquiler = await this.alquilerService.get(
        this.props.precioAlquiler.alquiler
      );
      let precio: PrecioAlquiler = {
        alquiler: alquiler.id,
        precio: alquiler.precio,
      };
      this.setState({
        precioAlquilerOld: precio,
        precioAlquilerNew: this.getCopy(precio),
      });
    }
  };

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({
        precioAlquilerOld: this.props.precioAlquiler,
        precioAlquilerNew: this.getCopy(this.props.precioAlquiler),
      });
    }
  };

  getCopy = (precio: PrecioAlquiler): PrecioAlquiler => {
    return { precio: precio.precio, alquiler: precio.alquiler };
  };

  onUpdate = async () => {
    try {
      await Swal.fire({
        icon: "question",
        title: "¿Está seguro en modificar el precio del alquiler ?",
        text: "Tenga presente que el nuevo precio entrará en vigencia a partir del presente mes!",
        showDenyButton: true,
        confirmButtonText: "Confirmar",
        denyButtonText: "Cancelar",
      }).then(async (result) => {
        if (!result.isDenied) {
          await this.alquilerService.actualizarPrecio(
            this.state.precioAlquilerNew.alquiler,
            this.state.precioAlquilerNew.precio
          );
          WebSocketService.enviarMensaje(
            CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES_PRECIOS,
            {
              type: "update",
              idAlquiler: this.state.precioAlquilerNew.alquiler,
            }
          );
          this.setState({
            precioAlquilerOld: this.state.precioAlquilerNew,
            precioAlquilerNew: this.getCopy(this.state.precioAlquilerNew),
          });

          await Swal.fire({
            icon: "success",
            text: "Precio Modificado",
          });
        }
      });
    } catch (error) {
      this.alquilerService.manejarErrorHTTP(error, "Alquiler");
    }
  };

  onChange = (precio: PrecioAlquiler) => {
    this.setState({ precioAlquilerNew: precio });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex
      flexDirection="column"
      width="50%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={3} texto={"Actualizar Alquiler"} />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <PrecioAlquilerLoad
            precio={this.state.precioAlquilerNew}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
          />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Actualizar"}
            onClick={this.onUpdate}
          />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
