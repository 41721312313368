import React, { Component } from "react";
import Text from "../../../../../../libreria/message/text/Text";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../../libreria/appearance/margin/Margin";
import LocalizacionShow from "../../../../../../compartido/components/direccion/LocalizacionShow";

import {
  CANALES_WEB_SOCKET,
  FuenteService,
  Fuente,
  WebSocketService,
} from "serviciossaintmichel";
import { Global } from "../../../../../../Global";

export interface Props {
  idFuente: number;
}

interface State {
  fuente: Fuente | null;
  cargando: boolean;
  visible: boolean;
}

export default class FuentePedidoShow extends Component<Props, State> {
  private fuenteService: FuenteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      fuente: null,
      cargando: true,
      visible: false,
    };

    this.fuenteService = new FuenteService(Global.UsuarioService.getToken()!);
  }

  componentDidMount = async () => {
    await this.cargarFuente();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_FUENTES,
      funcionCallback: this.onChannelMesagge,
    });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.fuenteId == this.props.idFuente) {
      await this.cargarFuente();
    }
  };

  cargarFuente = async () => {
    let fuente = await this.fuenteService
      .get(this.props.idFuente)
      .catch((error) => {
        this.fuenteService.manejarErrorHTTP(error, "Fuente");
        return null;
      });
    this.setState({ fuente: fuente, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      await this.setState({ cargando: true });
      await this.cargarFuente();
    }
  };

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  personaComunicación = (fuente: Fuente) => {
    if (fuente.cliente) {
      return fuente.cliente.razon_social + " (Cliente)";
    } else if (fuente.empleado) {
      return (
        fuente.empleado.persona.nombre +
        " " +
        fuente.empleado.persona.apellido +
        " (Empleado)"
      );
    } else if (fuente.propietario) {
      return (
        fuente.propietario.persona.nombre +
        " " +
        fuente.propietario.persona.apellido +
        " (Propietario)"
      );
    }
    return null;
  };

  renderFuente = () =>
    this.state.fuente ? (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="flex-start"
        justifyContent="center"
        marginTop="10px"
      >
        <Text>
          <b>Fecha (Fuente)</b>: {this.state.fuente.fecha}
        </Text>
        <Text>
          <b>Hora (Fuente)</b>: {this.state.fuente.hora}
        </Text>
        <Text>
          <b>Medio de Comunicación</b>:{" "}
          {this.state.fuente.medio_comunicacion.medio}
        </Text>
        <Text>
          <b>Medio de ingreso</b>: {this.state.fuente.medio_ingreso.medio}
        </Text>
        <Text>
          <b>Persona que realizó la Comunicación</b>:{" "}
          {this.personaComunicación(this.state.fuente)}
        </Text>
        {this.state.fuente.localizacion &&
        !(
          this.state.fuente.localizacion.coordinates[0] == 0 &&
          this.state.fuente.localizacion.coordinates[1] == 0
        ) ? (
          <LocalizacionShow localizacion={this.state.fuente.localizacion} />
        ) : (
          <Text>
            <b>Sin Localización</b>
          </Text>
        )}
      </Flex>
    ) : null;
  renderLoading = () => "Cargando Datos";

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Margin top="20px" />
        {this.state.cargando ? this.renderLoading() : this.renderFuente()}
      </Flex>
    );
  }
}
