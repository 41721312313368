import _ from "lodash";
import React, { Component } from "react";

import {
  CANALES_WEB_SOCKET,
  CriterioOrden,
  EstadoRepartoDiario,
  Reparto,
  RepartoDiarioLectura,
  RepartoDiarioService,
  WebSocketService,
} from "serviciossaintmichel";
import { PropsSelectGenerico } from "../../../../compartido/components/select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../../../../compartido/components/select/SelectBusqueda";
import SelectComponent from "../../../../compartido/components/select/SelectComponent";
import FechaUtils from "../../../../compartido/utils/FechaUtils";
import { Global } from "../../../../Global";
import RepartosDiariosFilter from "./RepartosDiariosFilter";
import RepartosDiariosTable from "./RepartosDiariosTable";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
  propsSelect?: PropsSelectGenerico<any> & PropsSelectGeneral;
  filtros?: FiltrosRepartosDiarios;
  version: VersionRepartosDiariosShow;
}

export enum VersionRepartosDiariosShow {
  TABLE,
  SELECT,
}

interface FiltrosRepartosDiarios {
  desde: Date | null;
  hasta: Date | null;
  estadoActual: EstadoRepartoDiario | null;
  repartoBusqueda: Reparto | null;
  criterioOrden: CriterioOrden | null;
  limite: number;
  tipo: Array<string> | null;
}

interface State extends FiltrosRepartosDiarios {
  repartosDiarios: Array<RepartoDiarioLectura> | null;
}

const ordenInicial: CriterioOrden = { campo: "fecha_realizacion", modo: "DESC" };
const PASO_LIMITE = 5;

export default class RepartosDiariosShowV2 extends Component<Props, State> {
  private webSocket: WebSocket | null;

  private repartoDiarioService: RepartoDiarioService;

  constructor(props: Props) {
    super(props);
    this.state = {
      repartosDiarios: null,
      desde: props.filtros ? props.filtros.desde : null,
      hasta: props.filtros ? props.filtros.hasta : null,
      estadoActual: props.filtros ? props.filtros.estadoActual : null,
      repartoBusqueda: props.filtros ? props.filtros.repartoBusqueda : null,
      criterioOrden: props.filtros ? props.filtros.criterioOrden : ordenInicial,
      limite: props.filtros ? props.filtros.limite : PASO_LIMITE,
      tipo: props.filtros ? props.filtros.tipo : null,
    };
    this.webSocket = null;

    this.repartoDiarioService = new RepartoDiarioService(Global.UsuarioService.getToken()!);
  }

  componentWillUnmount = () => {
    this.webSocket?.close();
  };

  componentDidMount = () => {
    this.setRepartosDiarios();
    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
      funcionCallback: this.setRepartosDiarios,
    });
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      prevState.criterioOrden !== this.state.criterioOrden ||
      prevState.limite !== this.state.limite ||
      prevState.tipo !== this.state.tipo
    ) {
      this.setRepartosDiarios();
    }

    if (!_.isEqual(prevProps.filtros, this.props.filtros)) {
      const { filtros } = this.props;
      this.setState({
        desde: filtros ? filtros.desde : null,
        hasta: filtros ? filtros.hasta : null,
        estadoActual: filtros ? filtros.estadoActual : null,
        repartoBusqueda: filtros ? filtros.repartoBusqueda : null,
        criterioOrden: filtros ? filtros.criterioOrden : ordenInicial,
        limite: filtros ? filtros.limite : PASO_LIMITE,
        tipo: filtros ? filtros.tipo : null,
      });
    }
  };

  setRepartosDiarios = async () => {
    const { desde, hasta, estadoActual, repartoBusqueda, criterioOrden, limite, tipo } = this.state;

    try {
      let orden = criterioOrden !== null ? criterioOrden : undefined;

      const filtros = {
        desde: desde,
        hasta: hasta,
        estadoActual: estadoActual,
        repartoId: repartoBusqueda ? repartoBusqueda.id : null,
        tipo: tipo,
      };
      const repartosDiarios: Array<RepartoDiarioLectura> = await this.repartoDiarioService.getRepartosDiarios(
        filtros,
        orden,
        limite
      );

      this.setState({ repartosDiarios: repartosDiarios });
    } catch (error) {
      this.repartoDiarioService.manejarErrorHTTP(error, "Repartos Diarios");
    }
  };

  criteriosOrdenIguales = (criterioA: CriterioOrden | null, criterioB: CriterioOrden | null): boolean => {
    if (criterioA === null || criterioB === null) {
      return false;
    }
    return criterioA.campo === criterioB.campo && criterioA.modo === criterioB.modo;
  };

  seleccionarCriterio = (criterio: CriterioOrden | null) => {
    const { criterioOrden } = this.state;

    if (criterioOrden === null || !this.criteriosOrdenIguales(criterioOrden, criterio)) {
      this.setState({ criterioOrden: criterio });
    } else {
      this.setState({ criterioOrden: null });
    }
  };

  getCriterioHandler = (campo: string) => {
    return (modo: "ASC" | "DESC" | null) => {
      const criterioOrden = modo ? { modo: modo, campo: campo } : null;
      this.seleccionarCriterio(criterioOrden);
    };
  };

  getControlCriterio = (campo: string) =>
    this.state.criterioOrden?.campo === campo ? this.state.criterioOrden.modo : null;

  aumentarLimite = () => {
    this.setState((prevState) => ({ limite: prevState.limite + PASO_LIMITE }));
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };

  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  setFiltros = ({
    desde = this.state.desde,
    hasta = this.state.hasta,
    estadoActual = this.state.estadoActual,
    repartoBusqueda = this.state.repartoBusqueda,
  }) => {
    this.setState({ desde: desde, hasta: hasta, estadoActual: estadoActual, repartoBusqueda });
  };

  render = () => {
    const { repartosDiarios, desde, hasta, estadoActual, repartoBusqueda, criterioOrden } = this.state;
    const { version } = this.props;

    if (repartosDiarios === null) {
      return <div>Cargando...</div>;
    }

    switch (version) {
      case VersionRepartosDiariosShow.TABLE: {
        return (
          <div>
            <RepartosDiariosFilter
              setFiltros={this.setFiltros}
              busquedaHandler={this.setRepartosDiarios}
              filtros={{ desde: desde, hasta: hasta, estadoActual: estadoActual, repartoBusqueda: repartoBusqueda }}
            />
            <RepartosDiariosTable
              onUpdate={this.props.onUpdate}
              onView={this.props.onView}
              repartosDiarios={repartosDiarios}
              criterioOrdenActual={criterioOrden}
              criterioOrdenHandler={this.seleccionarCriterio}
              aumentarLimite={this.aumentarLimite}
            />
          </div>
        );
      }
      case VersionRepartosDiariosShow.SELECT: {
        const { propsSelect } = this.props;

        if (!propsSelect) {
          return null;
        }

        return (
          <SelectComponent
            {...propsSelect}
            items={repartosDiarios}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado ? propsSelect.seleccionado : null}
            labelGetter={(repartoDiario: RepartoDiarioLectura) => {
              const fechaRealizacion = repartoDiario.fecha_realizacion
                ? FechaUtils.normalizarFecha(repartoDiario.fecha_realizacion).toLocaleDateString("es-AR")
                : "SIN-FECHA";
              return `${repartoDiario.reparto.tipo} - ${repartoDiario.reparto.nombre} - ${fechaRealizacion} - Viaje ${repartoDiario.viaje_numero}`;
            }}
          />
        );
      }
      default: {
        return null;
      }
    }
  };
}
