import React, { Component } from "react";
import Flex from "../../../../../compartido/components/flex/Flex";
import * as Icon from 'react-bootstrap-icons';
import { Button, Card } from "react-bootstrap";




import { CANALES_WEB_SOCKET, EmpleadoGet, EmpleadoPost, EmpleadoService, RelacionEmpleadoArea, RelacionEmpleadoSubArea, TipoResponsabilidad, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";




export interface Props {
  empleado: EmpleadoGet;
}

interface State {
  empleado: EmpleadoGet;
  visible:boolean;
}

export default class EmpleadoVerDatosPersonales extends Component<Props, State> {


  constructor(props: Props) {
    super(props);

    this.state = {
      empleado: this.props.empleado,
      visible:true
    };


  }

  componentDidMount() {
  }


 

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="flex-start"
        justifyContent="center"
      >

        <Flex container flexDirection="row">
            <Flex
              container
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              marginTop="5px"
            >
              <h5>Datos Personales</h5>
            </Flex>
            <Flex
              container
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              marginRigth="10px"

            >
              
            <Button
            variant="ligth" 
              onClick={() => {
                this.setState({ visible: !this.state.visible });
              }}
            >
                          {this.state.visible ? <Icon.Dash size={"25px"}/> :  <Icon.Plus size={"25px"}/>}

            </Button>



            
            </Flex>
          </Flex>
            
            {this.state.visible ?
          <Flex
              container
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
              marginTop="10px"

            >
              <Card.Text style={{color:"black"}}>Nombre: {this.state.empleado.persona.nombre}</Card.Text>
              <Card.Text style={{color:"black"}}>Apellido: {this.state.empleado.persona.apellido}</Card.Text>
              <Card.Text style={{color:"black"}}>DNI: {this.state.empleado.persona.dni}</Card.Text>
              <Card.Text style={{color:"black"}}>Fecha Nacimiento: {this.state.empleado.persona.fecha_nacimiento}</Card.Text>

            </Flex>
          :null}



      </Flex>
    );
  }
}
