import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";
import { Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputDigitos from "../../../../compartido/components/input/InputDigitos";

import { CANALES_WEB_SOCKET, EmpleadoGet, EmpleadoPost, EmpleadoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  ocultar: Function;
  callbackParent?: Function;
}

interface State {
  empleado: EmpleadoPost;
}

export default class EmpleadoCrear extends Component<Props, State> {

  private empleadoService:EmpleadoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      empleado: this.empleadoCrear(),
    };

    this.empleadoService = new EmpleadoService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    const empleadoCrear: EmpleadoPost = this.state.empleado;
    this.setState({
      empleado: empleadoCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  empleadoCrear(): EmpleadoPost {
    return {
      persona: {
        nombre: "",
        apellido: "",
        dni: "",
        fecha_nacimiento: new Date(),
      },
    };
  }
  setearNombre = (nombre: string) => {
    let x = this.state.empleado;
    x.persona.nombre = nombre;
    this.setState({ empleado: x });
  };

  setearApellido = (valor: string) => {
    let x = this.state.empleado;
    x.persona.apellido = valor;
    this.setState({ empleado: x });
  };

  setearDNI = (valor: string) => {
    let x = this.state.empleado;
    x.persona.dni = valor;
    this.setState({ empleado: x });
  };

  setearFechaNacimiento = (valor: Date) => {
    let x = this.state.empleado;
    x.persona.fecha_nacimiento = valor;
    this.setState({ empleado: x });
  };

  handleCreate() {
    return this.empleadoService.create(this.state.empleado)
      .then((empleado) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ADMINISTRACION_PERSONAL_EMPLEADOS
        );
        if (this.props.callbackParent) {
          this.props.callbackParent(empleado.id);
        } else {
          const empleadoCrear: EmpleadoPost = this.empleadoCrear();
          this.setState({
            empleado: empleadoCrear,
          });
        }
      })
      .catch((error) => {
        this.empleadoService.manejarErrorHTTP(error, "Empleado");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.empleado.persona.nombre &&
      this.state.empleado.persona.apellido &&
      this.state.empleado.persona.dni &&
      this.state.empleado.persona.fecha_nacimiento
      ? false
      : true;
  };

  renderizarFormulario() {
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <div className={"row"}>
          <div className={"column"}>
            <Card.Title style={{ textAlign: "center" }}>
              Nuevo Empleado
            </Card.Title>

            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="nombre">Nombre</label>
                <InputCaracteres
                  id="nombre"
                  name="nombre"
                  onChange={this.setearNombre}
                  value={this.state.empleado.persona.nombre}
                  placeholder="Ingrese Nombre del Empleado"
                />
              </div>

              <div className={"form-group"}>
                <label htmlFor="nombre">Apellido</label>
                <InputCaracteres
                  id="apellido"
                  name="apellido"
                  onChange={this.setearApellido}
                  value={this.state.empleado.persona.apellido}
                  placeholder="Ingrese Apellido del Empleado"
                />
              </div>

              <div className={"form-group"}>
                <label htmlFor="nombre">DNI</label>
                <InputDigitos
                  id="dni"
                  name="dni"
                  onChange={this.setearDNI}
                  value={this.state.empleado.persona.dni}
                  placeholder="Ingrese DNI del Empleado"
                  maxlength="10"
                />
              </div>

              <div className={"form-group"}>
                <label htmlFor="fecha_nacimiento">Fecha de Nacimiento</label>
                <DatePicker
                  id="fecha_nacimiento"
                  selected={this.state.empleado.persona.fecha_nacimiento}
                  onChange={this.setearFechaNacimiento}
                />
              </div>

              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <RequestButton
                  texto="Crear"
                  msgOnSuccess="Empleado creado con exito"
                  msgOnFailure="Ocurrio un error al crear el empleado"
                  propsBoton={{
                    variant: "info",
                    disabled: this.disableButton(),
                  }}
                  onClick={() => this.handleCreate()}
                />
                <button
                  type={"reset"}
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
