import React, { Component } from "react";
import Flex from "../../../../../compartido/components/flex/Flex";
import { Fuentes } from "../../../../../compartido/estilos/estilos";
import H from "../../../../../compartido/components/titulos/H";
import SelectReparto from "../../../../../distribucion/repartos/components/SelectReparto";
import CheckBoxList from "../../../../../compartido/components/checkboxlist/CheckBoxList";
import { Item } from "../../../../../compartido/components/checkboxlist/Item";
import { Button } from "react-bootstrap";
import ListBox from "../../../../../compartido/components/listbox/ListBox";
import { ItemListBox } from "../../../../../compartido/components/listbox/Item";
import Margin from "../../../../../compartido/components/margin/Margin";
import { Recorrido, RecorridoService, RepartoService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";

export interface Props {
  recorridos: Array<Recorrido>;
  onChangeRecorridos: Function;
  onChangeValidation: Function;
}

interface State {
  recorridos: Array<Recorrido>; // Todos los recorridos que existen
  repartoSeleccionado: number | null; // Reparto Seleccionado
  repartosCargados: Map<number, Array<Recorrido>>;
  pasoValidado: boolean;
  disabledButtonAgregar: boolean;
}

export default class PasoRecorridos extends Component<Props, State> {

  private recorridoService:RecorridoService;
  private repartoService:RepartoService;

  constructor(props: Props) {
    super(props);

    this.state = {
      repartoSeleccionado: null,
      recorridos: [],
      repartosCargados: new Map<number, Array<Recorrido>>(),
      pasoValidado: this.isPasoValidado(this.props.recorridos),
      disabledButtonAgregar: true,
    };

    this.props.onChangeValidation(this.state.pasoValidado);
    this.listBoxRecorridos = React.createRef();
    this.checkBoxListRecorridos = React.createRef();

    this.recorridoService = new RecorridoService(Global.UsuarioService.getToken()!);
    this.repartoService = new RepartoService(Global.UsuarioService.getToken()!);

  }
  async componentDidMount() {
    if (this.props.recorridos.length > 0) {
      const rep = await this.recorridoService.get(this.props.recorridos[0].id);
      await this.setState({ repartoSeleccionado: rep.reparto });
      await this.onChangeReparto(rep.reparto);
    }
  }

  listBoxRecorridos: any;
  checkBoxListRecorridos: any;

  isPasoValidado = (recorridos: Array<Recorrido>): boolean => {
    return recorridos.length > 0 ? true : false;
  };

  updateValidacion = () => {
    let x: boolean = this.isPasoValidado(this.state.recorridos);
    this.setState({ pasoValidado: x });
    this.props.onChangeValidation(x);
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.setState({
        //Cliente: this.props.Cliente,
      });
    }

    if (prevState.recorridos !== this.state.recorridos) {
      this.onChangeReparto(this.state.repartoSeleccionado);
    }
  }

  // ---------------------------------------------------
  // ---------------- Select Reparto  ------------------

  onChangeReparto = async (reparto: number | null) => {
    this.setState({ repartoSeleccionado: reparto });

    const { repartosCargados } = this.state;

    if (reparto != null) {
      if (!repartosCargados.has(reparto)) {
        const repartoSeleccionado = await this.repartoService.get(reparto).catch((error) => {
          this.repartoService.manejarErrorHTTP(error, "Reparto");
          return null;
        });
        if (repartoSeleccionado != null) {
          repartosCargados.set(reparto, repartoSeleccionado.recorridos);
          this.setState({ repartosCargados: repartosCargados });
        }
      }

      const recorridos = this.getItemsCheckBoxListRecorridos(repartosCargados.get(reparto) || []);

      const idsRecorridosElegidos = this.state.recorridos.map((recorrido) => recorrido.id);

      const result = recorridos.filter((itemRecorrido) => !idsRecorridosElegidos.includes(itemRecorrido.id));

      this.checkBoxListRecorridos.current.cargar(result);
    } else {
      this.checkBoxListRecorridos.current.cargar([]);
    }
  };

  // ---------------------------------------------------
  // ---------------- Check Box List Recorridos  ------------------

  getItemsCheckBoxListRecorridos = (recorridos: Array<Recorrido>): Array<Item<Recorrido>> => {
    let items: Array<Item<Recorrido>> = [];
    recorridos.map((recorrido) => {
      if (this.props.recorridos.findIndex((rec) => recorrido.id == rec.id) == -1)
        items.push(new ItemRecorrido<Recorrido>(recorrido.id, recorrido.nombre, false, recorrido));
    });
    return items;
  };

  isOneSelectedCheckBoxListRecorridos = (estado: boolean) => {
    this.setState({ disabledButtonAgregar: !estado });
  };

  agregarRecorridos = () => {
    let recorridos: Array<Item<Recorrido>> = this.checkBoxListRecorridos.current.getSeleccionados();
    recorridos.map((x) =>
      this.listBoxRecorridos.current.agregar({
        id: x.objeto.id,
        texto: x.objeto.nombre,
        objeto: x.objeto,
      })
    );
    this.checkBoxListRecorridos.current.deleteSeleccionados();
  };

  // -----------------------------------------------------------------------
  // ----------------  List Box Recorridos Seleccionados  ------------------

  getItemsListBoxRecorridos = (): Array<ItemListBox<Recorrido>> => {
    let items: Array<ItemListBox<Recorrido>> = [];
    this.props.recorridos.map((recorrido) =>
      items.push({
        objeto: recorrido,
        id: recorrido.id,
        texto: recorrido.nombre,
        estado: false,
      })
    );
    return items;
  };

  onChangeRecorridosSeleccionados = (recorridos: Array<Recorrido>) => {
    this.props.onChangeRecorridos(recorridos);
    let validado = this.isPasoValidado(recorridos);
    this.setState({ pasoValidado: validado, recorridos: recorridos });
    this.props.onChangeValidation(validado);
  };

  // ---------------------------------------------------

  render() {
    return (
      <Flex container flexDirection="column" width="100%" padding="30px">
        <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%">
          <H size={3} fuente={Fuentes.Titulos} texto="Asignación de Recorridos" />
        </Flex>

        <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%">
          <SelectReparto
            onChangeSelect={this.onChangeReparto}
            seleccionado={this.state.repartoSeleccionado}
            disabled={this.state.pasoValidado}
          />

          <Flex
            container
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            marginTop="15px"
            marginBottom="20px"
          >
            <CheckBoxList<Recorrido>
              height="300px"
              width="100%"
              borde="1px solid grey"
              ref={this.checkBoxListRecorridos}
              isOneSelected={this.isOneSelectedCheckBoxListRecorridos}
            />
            <Margin top="20px">
              <Button variant="primary" disabled={this.state.disabledButtonAgregar} onClick={this.agregarRecorridos}>
                Agregar
              </Button>
            </Margin>
          </Flex>
        </Flex>

        <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%">
          <H size={5} fuente={Fuentes.Titulos} texto="Recorridos Seleccionados" />

          <ListBox<Recorrido>
            borde={"1px solid black"}
            onChangeList={this.onChangeRecorridosSeleccionados}
            ref={this.listBoxRecorridos}
            items={this.getItemsListBoxRecorridos()}
            tipoItem={{
              view: false,
              deleteSimple: true,
              deleteRequest: false,
              select: false,
            }}
          />
        </Flex>
      </Flex>
    );
  }
}

class ItemRecorrido<T> implements Item<T> {
  id: number;
  nombre: string;
  estado: boolean;
  objeto: T;

  constructor(id: number, nombre: string, estado: boolean, objeto: T) {
    this.id = id;
    this.nombre = nombre;
    this.estado = estado;
    this.objeto = objeto;
  }

  equalsTo(item: Item<T>): boolean {
    if (this.id == item.id) return true;
    else return false;
  }

  copyTo(item: Item<T>): void {
    this.id = item.id;
    this.nombre = item.nombre;
    this.estado = item.estado;
    this.objeto = item.objeto;
  }

  getCopy(): Item<T> {
    return new ItemRecorrido(this.id, this.nombre, this.estado, this.objeto);
  }
}
