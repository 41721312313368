import React, { Component } from "react";
import Flex from "../flex/Flex";
import DireccionLoad from "./DireccionLoad";
import Box from "../box/Box";
import RequestButton from "../request-button/RequestButton";

import { CANALES_WEB_SOCKET,  DireccionDTO, DireccionService,    WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../Global";


export interface Props {
  direccionId: number;
}

interface State {
  direccion: DireccionDTO | null;
  formularioValidado: boolean;
}

export default class DireccionUpdate extends Component<Props, State> {

  private direccionService:DireccionService;

  constructor(props: Props) {
    super(props);
    this.state = {
      direccion: null,
      formularioValidado: false,
    };

    this.direccionService = new DireccionService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = () => {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.COMPARTIDO_DIRECCION,
      funcionCallback: (e: MessageEvent) => {
        let data = JSON.parse(e.data);
        this.cargarDireccion(data.message.direccionId);
      },
    });

    this.cargarDireccion(this.props.direccionId);
  };

  cargarDireccion = async (direccionId: number) => {
    try {
      const direccion = await this.direccionService.get(direccionId);
      this.setState({ direccion: direccion });
    } catch (error) {
      this.direccionService.manejarErrorHTTP(error, "Direccion");
    }
  };

  setDireccion = (direccion: DireccionDTO) => {
    this.setState({ direccion: direccion });
  };

  actualizarDireccion = async (direccion: DireccionDTO) => {
    try {
      await this.direccionService.update(direccion, direccion.id);
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.COMPARTIDO_DIRECCION, {
        direccionId: direccion.id,
      });
    } catch (error) {
      this.direccionService.manejarErrorHTTP(error, "Direccion");
    }
  };

  setValidacion = (valido: boolean) => {
    this.setState({ formularioValidado: valido });
  };

  render() {
    const { direccion, formularioValidado } = this.state;

    if (direccion === null) {
      return null;
    }

    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
            <DireccionLoad
              direccion={direccion}
              onChangeDireccion={this.setDireccion}
              onChangeValidation={this.setValidacion}
            />
            <RequestButton
              texto="Guardar cambios"
              propsBoton={{
                variant: "success",
                disabled: !formularioValidado,
              }}
              onClick={() => this.actualizarDireccion(direccion)}
            />
          </Flex>
        </Box>
      </Flex>
    );
  }
}
