import React, { Component } from "react";
import * as Icon from "react-bootstrap-icons";
import { Button, Card, ListGroup } from "react-bootstrap";
import Flex from "../../../../../../../compartido/components/flex/Flex";

import { CANALES_WEB_SOCKET, EmpleadoService, MedioComunicacion, MedioComunicacionService, Motivo, MotivoVinculacionService, Persona, PropietarioService, TipoMotivo, VinculacionCliente, VinculacionClienteService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../../Global";

export interface Props {
  idVinculacionCliente: number;
}

interface State {
  vinculacion: VinculacionCliente | null;
  motivosVinculacion: Array<Motivo> | null;
  persona: Persona | null;
  medioComunicacion: MedioComunicacion | null;
  visible: boolean;
}

export default class VinculacionClienteShow extends Component<Props, State> {

  private empleadoService:EmpleadoService;
  private medioComunicacionService:MedioComunicacionService;
  private vinculacionClienteService:VinculacionClienteService;
  private motivoVinculacionService:MotivoVinculacionService;
  private propietarioService:PropietarioService;

  constructor(props: Props) {
    super(props);

    this.state = {
      vinculacion: null,
      motivosVinculacion: null,
      medioComunicacion: null,
      persona: null,
      visible: false,
    };

    this.empleadoService = new EmpleadoService(Global.UsuarioService.getToken()!);
    this.medioComunicacionService = new MedioComunicacionService(Global.UsuarioService.getToken()!);

    this.vinculacionClienteService = new VinculacionClienteService(Global.UsuarioService.getToken()!);

    this.motivoVinculacionService = new MotivoVinculacionService(Global.UsuarioService.getToken()!);
    this.propietarioService = new PropietarioService(Global.UsuarioService.getToken()!);

    
  }

  componentDidMount = async () => {
    const vinculacion = await this.cargarVinculacion(this.props.idVinculacionCliente);
    if (vinculacion != null) {
      this.setState({ vinculacion: vinculacion });
      const [motivos, medio, personaEmpleado, personaPropietario] = await Promise.all([
        this.cargarMotivosVinculacion(vinculacion.motivos_vinculacion),
        this.cargarMedioComunicacion(vinculacion.medio_comunicacion),
        this.cargarEmpleado(vinculacion.empleado),
        this.cargarPropietario(vinculacion.propietario),
      ]);
      const persona = personaEmpleado ? personaEmpleado : personaPropietario;
      if (motivos != null && medio != null && persona != null) {
        this.setState({
          motivosVinculacion: motivos,
          medioComunicacion: medio,
          persona: persona,
        });
      }
    }
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_VINCULACION_CLIENTE,
      funcionCallback: this.refrescarVinculacion,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_PERSONAL_EMPLEADOS,
      funcionCallback: this.refrescarEmpleado,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_PERSONAL_PROPIETARIOS,
      funcionCallback: this.refrescarPropietario,
    });
  };

  cargarVinculacion = async (vinculacionId: number | null) => {
    if (vinculacionId != null) {
      let vinculacion = await this.vinculacionClienteService.get(vinculacionId).catch((error) => {
        this.vinculacionClienteService.manejarErrorHTTP(error, "Vinculación Cliente");
        return null;
      });
      return vinculacion;
    }
    return null;
  };

  cargarMotivoVinculacion = async (motivo_vinculacion: number | null) => {
    if (motivo_vinculacion != null) {
      let motivo = await this.motivoVinculacionService.get(motivo_vinculacion).catch((error) => {
        this.motivoVinculacionService.manejarErrorHTTP(error, "Motivos Vinculación");
        return null;
      });
      return motivo;
    }
    return null;
  };

  cargarMotivosVinculacion = async (motivos_vinculacion: Array<number> | null) => {
    if (motivos_vinculacion != null) {
      let motivosVinculacion: Array<Motivo> = [];
      motivos_vinculacion.forEach(async (mot: number) => {
        let motivo = await this.cargarMotivoVinculacion(mot);
        if (motivo != null) motivosVinculacion.push(motivo);
      });
      return motivosVinculacion;
    }
    return null;
  };

  cargarMedioComunicacion = async (medio_comunicacion: number | null) => {
    if (medio_comunicacion != null) {
      let medio = await this.medioComunicacionService.get(medio_comunicacion).catch((error) => {
        this.medioComunicacionService.manejarErrorHTTP(error, "Medio Comunicación");
        return null;
      });
      return medio;
    }
    return null;
  };

  cargarEmpleado = async (empleadoId: number | null) => {
    if (empleadoId != null) {
      let empleado = await this.empleadoService.get(empleadoId).catch((error) => {
        this.empleadoService.manejarErrorHTTP(error, "Empleado");
        return null;
      });
      if (empleado != null) {
        return empleado.persona;
      }
    }
    return null;
  };

  cargarPropietario = async (propietarioId: number | null) => {
    if (propietarioId != null) {
      let propietario = await this.propietarioService.get(propietarioId).catch((error) => {
        this.propietarioService.manejarErrorHTTP(error, "Propietario");
        return null;
      });
      if (propietario != null) {
        return propietario.persona;
      }
    }
    return null;
  };

  refrescarVinculacion = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (this.props.idVinculacionCliente != null && dato.message.vinculacionId === this.props.idVinculacionCliente) {
      let vinculacion = await this.cargarVinculacion(this.props.idVinculacionCliente);
      if (vinculacion != null) {
        this.setState({ vinculacion: vinculacion });
      }
    }
  };

  refrescarEmpleado = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.vinculacion != null &&
      this.state.vinculacion.empleado != null &&
      dato.message.empleadoId === this.state.vinculacion.empleado
    ) {
      let persona = await this.cargarEmpleado(this.state.vinculacion.empleado);
      if (persona != null) {
        this.setState({ persona: persona });
      }
    }
  };

  refrescarPropietario = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.vinculacion != null &&
      this.state.vinculacion.propietario != null &&
      dato.message.propietarioId === this.state.vinculacion.propietario
    ) {
      let persona = await this.cargarPropietario(this.state.vinculacion.propietario);
      if (persona != null) {
        this.setState({ persona: persona });
      }
    }
  };

  renderizarMotivosVinculacion(motivos: Array<Motivo>) {
    let motivosAlta: Array<Motivo> = motivos.filter((motivo) => motivo.tipo === TipoMotivo.ALTA);
    let motivosBaja: Array<Motivo> = motivos.filter((motivo) => motivo.tipo === TipoMotivo.BAJA);
    return (
      <>
        {this.renderizarTiposMotivosVinculacion(motivosAlta, TipoMotivo.ALTA)}
        {this.renderizarTiposMotivosVinculacion(motivosBaja, TipoMotivo.BAJA)}
      </>
    );
  }

  renderizarTiposMotivosVinculacion(motivos: Array<Motivo>, tipo: TipoMotivo) {
    const variante = tipo === TipoMotivo.ALTA ? "success" : "danger";
    if (motivos.length > 0)
      return (
        <ListGroup>
          <strong>{tipo + "S"}</strong>
          {motivos.map((motivo) => {
            return <ListGroup.Item variant={variante}>{motivo.motivo}</ListGroup.Item>;
          })}
        </ListGroup>
      );
    else return null;
  }

  render() {
    if (
      this.state.vinculacion != null &&
      this.state.medioComunicacion != null &&
      this.state.motivosVinculacion != null &&
      this.state.persona != null
    ) {
      return (
        <Flex container flexDirection="column" width="100%" alignItems="flex-start" justifyContent="center">
          <Flex container flexDirection="row">
            <Flex container flexDirection="column" alignItems="center" justifyContent="center" marginRigth="10px">
              <Button
                variant="ligth"
                onClick={() => {
                  this.setState({ visible: !this.state.visible });
                }}
              >
                {this.state.visible ? (
                  <Icon.Dash size={"25px"} />
                ) : (
                  <Flex container flexDirection="row" alignItems="flex-start" justifyContent="center" marginTop="10px">
                    <Card.Text style={{ color: "black" }}>Fecha de alta: {this.state.vinculacion.fecha_alta}</Card.Text>
                    <Icon.Plus size={"25px"} />
                  </Flex>
                )}
              </Button>
            </Flex>
          </Flex>
          {this.state.visible ? (
            <Flex container flexDirection="column" alignItems="flex-start" justifyContent="center" marginTop="10px">
              <Flex container flexDirection="column" alignItems="flex-start" justifyContent="center" marginTop="10px">
                <Card.Text style={{ color: "black" }}>
                  Motivo Vinculación: {this.renderizarMotivosVinculacion(this.state.motivosVinculacion)}
                </Card.Text>
                <Card.Text style={{ color: "black" }}>
                  Medio Vinculación: {this.state.medioComunicacion.medio}
                </Card.Text>
                <Card.Text style={{ color: "black" }}>Fecha de alta: {this.state.vinculacion.fecha_alta}</Card.Text>
                {this.state.vinculacion.fecha_baja ? (
                  <Card.Text style={{ color: "black" }}>Fecha de baja: {this.state.vinculacion.fecha_baja}</Card.Text>
                ) : null}
                <Card.Text style={{ color: "black" }}>
                  Vendedor: {this.state.persona.nombre + " " + this.state.persona.apellido}
                </Card.Text>
              </Flex>
            </Flex>
          ) : null}
        </Flex>
      );
    } else return null;
  }
}
