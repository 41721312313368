import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import MaquinasSimbolicasShow, {
  Version,
} from "../../../../elementos/maquinas/MaquinaServicioSimbolica/components/show/MaquinasSimbolicasShow";
import { CargaMaquinaSimbolicaLectura, MaquinaServicioSimbolicaGet } from "serviciossaintmichel";

export interface Props {
  onSubmitCallback?: Function;
  excluirMaquinas?: Array<number>;
}

interface State {
  nuevaCargaMaquina: CargaMaquinaSimbolicaLectura;
  maquinaSeleccionada: MaquinaServicioSimbolicaGet | null;
}

export default class CargaMaquinaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getEstadoInicial();
  }

  maquinaNueva = () => {
    return { id: -1, nombre: "" };
  };

  getEstadoInicial = () => ({
    nuevaCargaMaquina: {
      maquina_simbolica: this.maquinaNueva(),
      cantidad: 0,
    },
    maquinaSeleccionada: null,
  });

  setMaquina = (maquinaSimbolica: MaquinaServicioSimbolicaGet | null) => {
    const maquina =
      maquinaSimbolica !== null ? { id: maquinaSimbolica.id, nombre: maquinaSimbolica.nombre } : this.maquinaNueva();
    this.setState((prevState) => ({
      nuevaCargaMaquina: {
        ...prevState.nuevaCargaMaquina,
        maquina_simbolica: maquinaSimbolica ? maquina : this.state.nuevaCargaMaquina.maquina_simbolica,
      },
      maquinaSeleccionada: maquinaSimbolica,
    }));
  };

  onSubmit = () => {
    const { onSubmitCallback = () => {} } = this.props;
    onSubmitCallback(this.state.nuevaCargaMaquina);
    const estadoInicial = this.getEstadoInicial();
    this.setState(estadoInicial);
  };

  validarCarga = (cargaMaquina: CargaMaquinaSimbolicaLectura) => {
    return cargaMaquina.maquina_simbolica.id > 0 && cargaMaquina.cantidad > 0;
  };

  render() {
    const { nuevaCargaMaquina, maquinaSeleccionada } = this.state;

    return (
      <Flex borderRadius="5px" border="1px solid black" gap="1rem" padding="1rem" alignItems="center">
        <MaquinasSimbolicasShow
          version={Version.SELECT}
          excluirIds={this.props.excluirMaquinas}
          propsSelect={{
            label: "Máquinas",
            placeholder: "Seleccine una máquina",
            seleccionado: maquinaSeleccionada,
            callbackParent: this.setMaquina,
          }}
        />
        <div>
          <span>Cantidad</span>
          <Input
            type={InputTypes.Number}
            data={nuevaCargaMaquina.cantidad}
            onChange={(data: number) => {
              this.setState((prevState) => ({
                nuevaCargaMaquina: { ...prevState.nuevaCargaMaquina, cantidad: data },
              }));
            }}
          />
        </div>

        <Button onClick={this.onSubmit} disabled={!this.validarCarga(nuevaCargaMaquina)}>
          Añadir
        </Button>
      </Flex>
    );
  }
}
