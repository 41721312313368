import React, { Component } from "react";
/* import ClienteUpdate from "./update/ClienteUpdate";
import Clienteshow from "./show/Clienteshow"; */
/* import AtencionClientesShow, { Version } from "./show/AtencionClientesShow";
 */ import { Tabs, Tab } from "react-bootstrap";
import { CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../Global";
import Flex from "../../../libreria/appearance/flex/Flex";
/* import ClienteNew from "./new/ClienteNew"; */
interface Props {}

interface State {
  tabVisible: any;
  disabledActualizar: boolean;
  disabledVer: boolean;
  responsive: boolean;
  idCliente: number | null;
  recienCreado: boolean;
}

export default class PotencialesClientes extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista",
      disabledActualizar: true,
      disabledVer: true,
      responsive: window.innerWidth < 700 ? true : false,
      idCliente: null,
      recienCreado: false,
    };

    window.addEventListener("resize", this.actualizarDimensiones);

    /* WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMIINSTRACION_CLIENTES,
      funcionCallback: this.cargarCliente,
    }); */
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  cargarCliente = async (e: MessageEvent) => {};

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  actualizar = (idCliente: number) => {
    this.setState({
      tabVisible: "actualizar",
      disabledActualizar: false,
      idCliente: idCliente,
    });
  };

  ver = (idCliente: number) => {
    this.setState({
      tabVisible: "ver",
      disabledVer: false,
      idCliente: idCliente,
      recienCreado: false,
    });
  };

  ocultarVer = () => {
    this.setState({
      tabVisible: "lista",
      disabledVer: true,
      idCliente: null,
    });
  };

  ocultarNuevo = () => {
    this.setState({
      tabVisible: "lista",
    });
  };

  renderizarNuevo = () => {
    if (
      Global.UsuarioService.getPermisos().Administracion.Clientes.Cliente.canAdd()
    ) {
      /*  return (
        <Tab eventKey="nuevo" title="Nuevo" width="100%">
          {this.state.tabVisible === "nuevo" ? (
            <ClienteNew
              onExit={this.ocultarNuevo}
              onSaveSuccess={async (idCliente: number) => {
                this.setState({ recienCreado: true, idCliente: idCliente });
                this.actualizar(idCliente);
              }}
            />
          ) : null}
        </Tab>
      ); */
    }
  };

  renderizarLista = () => {
    if (
      Global.UsuarioService.getPermisos().Administracion.Clientes.Cliente.canView()
    ) {
      return (
        <Tab eventKey="lista" title="Lista" width="100%">
          {/*  <AtencionClientesShow
            version={Version.TABLE}
            onView={this.ver}
            onUpdate={this.actualizar}
          /> */}
        </Tab>
      );
    }
  };

  renderizarActualizar = () => {
    if (
      Global.UsuarioService.getPermisos().Administracion.Clientes.Cliente.canChange()
    ) {
      /* return (
        <Tab
          width="100%"
          eventKey="actualizar"
          title="Actualizar"
          disabled={this.state.disabledActualizar}
        >
          {this.state.idCliente && this.state.tabVisible === "actualizar" ? (
            <ClienteUpdate idCliente={this.state.idCliente} />
          ) : null}
        </Tab>
      ); */
    }
  };

  renderizarVerDetalle = () => {
    if (
      Global.UsuarioService.getPermisos().Administracion.Clientes.Cliente.canView()
    ) {
      /*  return (
        <Tab
          width="100%"
          eventKey="ver"
          title="Ver Detalle"
          disabled={this.state.disabledVer}
        >
          {this.state.idCliente && this.state.tabVisible === "ver" ? (
            <Clienteshow idCliente={this.state.idCliente} />
          ) : null}
        </Tab>
      ); */
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey="lista"
      transition={false}
      activeKey={this.state.tabVisible}
      onSelect={this.onSelect}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "flex-start" : "",
      }}
      unmountOnExit={true}
    >
      {this.renderizarNuevo()}
      {this.renderizarLista()}
      {this.renderizarActualizar()}
      {this.renderizarVerDetalle()}
    </Tabs>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <div style={{ display: "block", width: "100%" }}>
          {this.renderizar(this.state.responsive)}
        </div>
      </Flex>
    );
  }
}
