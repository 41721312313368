import React, { Component } from "react";
import Flex from "../../../../../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../../../../../libreria/data/input/Input";
import SelectMaquinaServicioSimbolica from "../../../../../../../../elementos/maquinas/MaquinaServicioSimbolica/components/SelectMaquinaServicioSimbolica";
import { MaquinaServicioSimbolicaGet, MaquinaServicioSimbolicaService, OrdenCompraMaquinaSimbolica } from "serviciossaintmichel";
import { Global } from "../../../../../../../../Global";




export interface Props {
  maquinaOrdenCompra: OrdenCompraMaquinaSimbolica | null;
  onChange: Function;
  onValidationChange: Function;
  maquinasNoValidas?: Array<number>;
}

interface State {
  maquinaOrdenCompra: OrdenCompraMaquinaSimbolica;
  maquinaOrdenCompraValida: boolean;
  cantidadValida: boolean;
  maquinas: Array<MaquinaServicioSimbolicaGet>;
}

export default class MaquinaSimbolicaOrdenCompraLoad extends Component<Props, State> {


  private maquinaServicioSimbolicaService:MaquinaServicioSimbolicaService;


  constructor(props: Props) {
    super(props);

    const initialValidation = !!props.maquinaOrdenCompra;

    this.state = {
      maquinaOrdenCompra: props.maquinaOrdenCompra || this.crearOrdenCompraMaquina(),
      maquinaOrdenCompraValida: initialValidation,
      maquinas: [],
      cantidadValida: false,
    };

    this.maquinaServicioSimbolicaService = new MaquinaServicioSimbolicaService(Global.UsuarioService.getToken()!);

  }

  crearOrdenCompraMaquina = (): OrdenCompraMaquinaSimbolica => {
    return {
      id: -1,
      orden_compra: -1,
      maquina_simbolica: -1,
      cantidad: 1,
    };
  };

  componentDidMount = async () => {
    const { maquinasNoValidas } = this.props;
    const { maquinaOrdenCompra } = this.state;

    this.validar();
    this.props.onChange(this.state.maquinaOrdenCompra);
    let maquinas = await this.maquinaServicioSimbolicaService.listAll();

    if (maquinasNoValidas) {
      maquinas = maquinas.filter((m: MaquinaServicioSimbolicaGet) => maquinasNoValidas.indexOf(m.id) === -1);
    }

    if (maquinas.length < 1) {
      return;
    }

    const maquinaSeleccionada =
      maquinaOrdenCompra.maquina_simbolica === -1 ? maquinas[0].id : maquinaOrdenCompra.maquina_simbolica;

    this.setState({
      maquinas: maquinas,
      maquinaOrdenCompra: {
        ...maquinaOrdenCompra,
        maquina_simbolica: maquinaSeleccionada,
      },
    });
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { maquinaOrdenCompra, maquinaOrdenCompraValida, cantidadValida } = this.state;

    if (JSON.stringify(prevState.maquinaOrdenCompra) !== JSON.stringify(maquinaOrdenCompra)) {
      this.props.onChange(maquinaOrdenCompra);
      this.validar();
    }
    if (prevState.maquinaOrdenCompraValida !== maquinaOrdenCompraValida) {
      this.props.onValidationChange(maquinaOrdenCompraValida);
    }
    if (prevState.cantidadValida !== cantidadValida) {
      this.validar();
    }
    if (
      this.props.maquinaOrdenCompra &&
      JSON.stringify(prevProps.maquinaOrdenCompra) !== JSON.stringify(this.props.maquinaOrdenCompra)
    ) {
      this.setState({ maquinaOrdenCompra: this.props.maquinaOrdenCompra });
    }
  };

  validar = () => {
    const { maquinaOrdenCompra, cantidadValida } = this.state;
    let valido = maquinaOrdenCompra.maquina_simbolica !== -1 && cantidadValida;
    valido = valido && !!maquinaOrdenCompra.cantidad;
    this.setState({ maquinaOrdenCompraValida: valido });
  };

  setCantidad = (cantidad: number) => {
    const maquinaOrdenCompra = {
      ...this.state.maquinaOrdenCompra,
      cantidad: cantidad,
    };
    this.setState({ maquinaOrdenCompra: maquinaOrdenCompra });
  };

  setMaquina = (maquina: MaquinaServicioSimbolicaGet) => {
    const maquinaOrdenCompra = {
      ...this.state.maquinaOrdenCompra,
      maquina_simbolica: maquina.id,
    };
    this.setState({ maquinaOrdenCompra: maquinaOrdenCompra });
  };

  render = () => {
    const { maquinaOrdenCompra, maquinas } = this.state;

    if (maquinas.length < 1) {
      return <div>No hay máquinas para seleccionar</div>;
    }

    return (
      <>
        <Flex flexDirection="column" marginBottom="1rem">
          <span>Máquina</span>
          <SelectMaquinaServicioSimbolica
            maquinas={maquinas}
            idSeleccionado={maquinas[0].id}
            onSelect={(maquina: MaquinaServicioSimbolicaGet) => {
              this.setMaquina(maquina);
            }}
          />
        </Flex>

        <Flex flexDirection="column" marginBottom="1rem">
          <Input
            data={maquinaOrdenCompra.cantidad}
            label={"Cantidad"}
            placeholder={"Cantidad"}
            onChange={this.setCantidad}
            onValidationChange={(valida: boolean) => {
              this.setState({ cantidadValida: valida });
            }}
            type={InputTypes.Number}
            min={1}
          />
        </Flex>
      </>
    );
  };
}
