import React, { Component } from "react";
import { AlquilerService, CANALES_WEB_SOCKET, DatosAlquiler, WebSocketService } from "serviciossaintmichel";
import Swal from "sweetalert2";
import RequestButton from "../../../../../../compartido/components/request-button/RequestButton";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import { Global } from "../../../../../../Global";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../../libreria/containers/box/Box";
import H from "../../../../../../libreria/message/title/H";
import AlquilerLoad from "../../load/AlquilerLoad";

export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  datosAlquiler: DatosAlquiler;
}
interface State {
  datosAlquilerNew: DatosAlquiler;
  datosAlquilerOld: DatosAlquiler;
  screen: ScreenType;
  valid: boolean;
}

interface Message {
  type: string;
  idAlquiler: number;
}

export default class DatosAlquilerUpdate extends Component<Props, State> {
  private alquilerService: AlquilerService;

  constructor(props: Props) {
    super(props);
    debugger;
    this.state = {
      datosAlquilerOld: this.props.datosAlquiler,
      datosAlquilerNew: this.getCopy(this.props.datosAlquiler),
      screen: Screen.getType(window.innerWidth, window.innerHeight),
      valid: false,
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES,
      funcionCallback: this.onChannelMesagge,
    });

    this.alquilerService = new AlquilerService(Global.UsuarioService.getToken()!);
  }

  onChannelMesagge = async (e: MessageEvent) => {
    let message: Message = JSON.parse(e.data).message;

    if (message.idAlquiler == this.props.datosAlquiler.id) {
      const alquiler = await this.alquilerService.get(this.props.datosAlquiler.id);
      let datos: DatosAlquiler = { id: alquiler.id, nombre: alquiler.nombre };
      this.setState({
        datosAlquilerOld: datos,
        datosAlquilerNew: this.getCopy(datos),
      });
    }
  };

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  getCopy = (datos: DatosAlquiler): DatosAlquiler => {
    return { id: datos.id, nombre: datos.nombre };
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({
        datosAlquilerOld: this.props.datosAlquiler,
        datosAlquilerNew: this.getCopy(this.props.datosAlquiler),
      });
    }
  };

  onUpdate = async () => {
    try {
      await this.alquilerService.update(this.state.datosAlquilerNew, this.state.datosAlquilerNew.id);

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES, {
        type: "update",
        idAlquiler: this.state.datosAlquilerNew.id,
      });

      this.setState({
        datosAlquilerOld: this.state.datosAlquilerNew,
        datosAlquilerNew: this.getCopy(this.state.datosAlquilerNew),
      });

      await Swal.fire({
        icon: "success",
        text: "Datos Alquiler Modificados",
      });
    } catch (error) {
      this.alquilerService.manejarErrorHTTP(error, "Alquiler");
    }
  };

  renderizarPhone = () => (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex flexDirection="column" width="60%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex flexDirection="column" width="50%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  onChange = (datosAlquiler: DatosAlquiler) => {
    this.setState({ datosAlquilerNew: datosAlquiler });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizar = () => (
    <Box padding="20px">
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <H size={3} texto={"Datos"} />
        </Flex>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="10px">
          <AlquilerLoad
            alquiler={this.state.datosAlquilerNew}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
          />
        </Flex>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
          <RequestButton propsBoton={{ disabled: !this.state.valid }} texto={"Actualizar"} onClick={this.onUpdate} />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET ? this.renderizarTablet() : null}
      </Flex>
    );
  }
}
