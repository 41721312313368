import React, { Component } from "react";
import { LIMITES } from "../../../../../../compartido/components/select/SelectLimiteTabla";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import { Table } from "react-bootstrap";
import { Badge, Modal } from "@mantine/core";
import RequestButton from "../../../../../../libreria/action/request-button/RequestButton";
import { Box } from "../../../../../../libreria/containers/box/Box";
import FechaUtils from "../../../../../../compartido/utils/FechaUtils";
import ProgramacionEntregaShow from "../../../../programaciones-entrega/components/show/ProgramacionEntregaShow";

import {
  CANALES_WEB_SOCKET,
  WebSocketService,
  ProgramacionEntrega,
  ProgramacionEntregaService,
  EstadoProgramacion,
  PrioridadProgramacionEntrega,
  getColorEstadoProgramacion,
  getColorPrioridad,
} from "serviciossaintmichel";
import { Global } from "../../../../../../Global";
import ImagenesURLs from "../../../../../../compartido/utils/ImagenesURLs";

export interface Props {
  idPedido: number;
}

interface State {
  programacionesEntrega: Array<ProgramacionEntrega>;
  programacionEntrega: ProgramacionEntrega | null;
}

export default class ProgramacionesEntregaPedidoShow extends Component<
  Props,
  State
> {
  private programacionEntregaService: ProgramacionEntregaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      programacionesEntrega: [],
      programacionEntrega: null,
    };

    this.programacionEntregaService = new ProgramacionEntregaService(
      Global.UsuarioService.getToken()!
    );
  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PEDIDOS,
      funcionCallback: this.getProgramacionesEntrega,
    });
    this.programacionEntregaService.paramsURL.setearLimite(LIMITES[0]);
    this.getProgramacionesEntrega();
  }

  getProgramacionesEntrega = () => {
    const queryString = new URLSearchParams({
      pedido: this.props.idPedido.toString(),
    });

    this.programacionEntregaService
      .list(queryString)
      .then((result) => {
        this.setState({ programacionesEntrega: result });
      })
      .catch((error) => {
        this.programacionEntregaService.manejarErrorHTTP(
          error,
          "Programación Entrega"
        );
      });
  };

  botonDetalle = (programacionEntrega: ProgramacionEntrega) => {
    return (
      <RequestButton
        texto={"Ver Detalle"}
        onClick={() => {
          this.setOpened(programacionEntrega);
        }}
      />
    );
  };

  renderEstado = (estado: EstadoProgramacion) => {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Badge
          color={getColorEstadoProgramacion(estado.estado)}
          variant="filled"
          fullWidth
        >
          {estado.estado}
        </Badge>
      </Flex>
    );
  };

  renderPrioridad = (prioridad: PrioridadProgramacionEntrega) => {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Badge color={getColorPrioridad(prioridad)} variant="filled" fullWidth>
          {prioridad}
        </Badge>
      </Flex>
    );
  };

  setOpened = (programacionEntrega: ProgramacionEntrega | null) => {
    this.setState({ programacionEntrega: programacionEntrega });
  };

  renderizarFilas = () => {
    return this.state.programacionesEntrega.map(
      (programacionEntrega, index) => {
        return (
          <tr>
            <td className="text-center">{index}</td>
            <td className="text-center">
              {programacionEntrega.reparto
                ? programacionEntrega.reparto.nombre
                : "No tiene"}
            </td>
            <td className="text-center">
              {FechaUtils.normalizarFecha(
                programacionEntrega.fecha
              ).toLocaleDateString("es-AR")}
            </td>
            <td className="text-center">
              {this.renderEstado(programacionEntrega.estado_programacion)}
            </td>
            <td className="text-center">
              {this.renderPrioridad(programacionEntrega.prioridad)}
            </td>
            <td className="text-center">
              {this.botonDetalle(programacionEntrega)}
            </td>
          </tr>
        );
      }
    );
  };

  renderizarTabla = () => {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Table bordered={true} responsive>
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th className="text-center">Reparto</th>
              <th className="text-center">Fecha</th>
              <th className="text-center">Estado</th>
              <th className="text-center">Prioridad</th>
              <th className="text-center"></th>
            </tr>
          </thead>
          <tbody>{this.renderizarFilas()}</tbody>
        </Table>
        <Modal
          size="80%"
          styles={{
            title: {
              fontSize: "larger",
            },
            modal: {
              backgroundImage: ImagenesURLs.urlFondoModal(),
            },
          }}
          opened={this.state.programacionEntrega ? true : false}
          onClose={() => this.setOpened(null)}
          title="Programación Entrega"
        >
          {this.state.programacionEntrega ? (
            <Box>
              <ProgramacionEntregaShow
                idProgramacionEntrega={this.state.programacionEntrega.id}
              />
            </Box>
          ) : null}
        </Modal>
      </Flex>
    );
  };

  render() {
    if (this.state.programacionesEntrega.length > 0)
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          borderRadius="5px"
          padding="2px"
        >
          {this.state.programacionesEntrega.length > 0 ? (
            this.renderizarTabla()
          ) : (
            <>No tiene</>
          )}
        </Flex>
      );
    else return null;
  }
}
