import React, { Component } from "react";
import * as Icon from "react-bootstrap-icons";
import { Table, Button } from "react-bootstrap";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import EntregaBultoProductoLoad from "./EntregaBultoProductoLoad";
import { EntregaBultoProductoLectura } from "serviciossaintmichel";

export interface Props {
  entregasBultoProducto: Array<EntregaBultoProductoLectura>;
  onChange?: Function;
}

interface State {
  entregasBultoProducto: Array<EntregaBultoProductoLectura>;
}

export default class EntregasBultoProductoLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entregasBultoProducto: props.entregasBultoProducto,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (
      prevProps.entregasBultoProducto !== this.props.entregasBultoProducto &&
      this.state.entregasBultoProducto !== this.props.entregasBultoProducto
    ) {
      this.setState({ entregasBultoProducto: this.props.entregasBultoProducto });
    }

    if (prevState.entregasBultoProducto !== this.state.entregasBultoProducto && this.props.onChange) {
      this.props.onChange(this.state.entregasBultoProducto);
    }
  }

  eliminarEntregaBultoProducto = (idBultoProducto: number) => {
    const entregasBultoProducto = this.state.entregasBultoProducto.filter(
      (vbp: EntregaBultoProductoLectura) => vbp.bulto_producto.id !== idBultoProducto
    );
    this.setState({
      entregasBultoProducto: [...entregasBultoProducto],
    });
  };

  agregarEntregaBultoProducto = (nuevaEntregaBultoProducto: EntregaBultoProductoLectura): void => {
    this.setState((prevState) => ({
      entregasBultoProducto: [...prevState.entregasBultoProducto, nuevaEntregaBultoProducto],
    }));
  };

  actualizarEntregaBultoProducto = (idBultoProducto: number, infoEntrega: EntregaBultoProductoLectura) => {
    const entregas = this.state.entregasBultoProducto.map((ebp) =>
      ebp.bulto_producto.id === idBultoProducto ? infoEntrega : ebp
    );
    this.setState({
      entregasBultoProducto: entregas,
    });
  };

  render() {
    const { entregasBultoProducto } = this.state;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Bulto Producto</th>
              <th>Cantidad</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {entregasBultoProducto.map((entregaBultoProducto, idx) => (
              <tr key={entregaBultoProducto.bulto_producto.id}>
                <td>{`${entregaBultoProducto.bulto_producto.nombre} - Id ${entregaBultoProducto.bulto_producto.id}`}</td>
                <td>
                  <Input
                    type={InputTypes.Number}
                    data={entregaBultoProducto.cantidad}
                    onChange={(valor: number) => {
                      this.actualizarEntregaBultoProducto(entregaBultoProducto.bulto_producto.id, {
                        ...entregaBultoProducto,
                        cantidad: valor,
                      });
                    }}
                  />
                </td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      this.eliminarEntregaBultoProducto(entregaBultoProducto.bulto_producto.id);
                    }}
                  >
                    <Icon.Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <EntregaBultoProductoLoad
          onSubmitCallback={this.agregarEntregaBultoProducto}
          excluirBultosProducto={entregasBultoProducto.map((ep) => ep.bulto_producto.id)}
        />
      </>
    );
  }
}
