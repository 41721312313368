import React, { Component } from "react";
import Swal from "sweetalert2";
import RequestButton from "../../../../../../compartido/components/request-button/RequestButton";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../../libreria/containers/box/Box";
import H from "../../../../../../libreria/message/title/H";
import ConsumoLoad from "../../load/ConsumoLoad";


import { CANALES_WEB_SOCKET,  ConsumoGet,  ConsumoService,   WebSocketService,DatosConsumo } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";




export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  datosConsumo: DatosConsumo;
}
interface State {
  datosConsumoNew: DatosConsumo;
  datosConsumoOld: DatosConsumo;
  screen: ScreenType;
  valid: boolean;
}

interface Message {
  type: string;
  idConsumo: number;
}

export default class DatosConsumoUpdate extends Component<Props, State> {

  private consumoService:ConsumoService;

  constructor(props: Props) {
    super(props);
    debugger;
    this.state = {
      datosConsumoOld: this.props.datosConsumo,
      datosConsumoNew: this.getCopy(this.props.datosConsumo),
      screen: Screen.getType(window.innerWidth, window.innerHeight),
      valid: false,
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CONSUMOS,
      funcionCallback: this.onChannelMesagge,
    });

    this.consumoService =  new ConsumoService(Global.UsuarioService.getToken()!);

  }

  onChannelMesagge = async (e: MessageEvent) => {
    let message: Message = JSON.parse(e.data).message;

    if (message.idConsumo == this.props.datosConsumo.id) {
      const consumo = await this.consumoService.get(this.props.datosConsumo.id);
      let datos: DatosConsumo = {
        id: consumo.id,
        nombre: consumo.nombre,
        fecha_creacion: consumo.fecha_creacion,
        especial: consumo.especial,
      };
      this.setState({
        datosConsumoOld: datos,
        datosConsumoNew: this.getCopy(datos),
      });
    }
  };

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  getCopy = (datos: DatosConsumo): DatosConsumo => {
    return {
      id: datos.id,
      nombre: datos.nombre,
      fecha_creacion: datos.fecha_creacion,
      especial: datos.especial,
    };
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({
        datosConsumoOld: this.props.datosConsumo,
        datosConsumoNew: this.getCopy(this.props.datosConsumo),
      });
    }
  };

  onUpdate = async () => {
    try {
      await this.consumoService.update(
        this.state.datosConsumoNew,
        this.state.datosConsumoNew.id
      );

      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CONSUMOS,
        {
          type: "update",
          idConsumo: this.state.datosConsumoNew.id,
        }
      );

      this.setState({
        datosConsumoOld: this.state.datosConsumoNew,
        datosConsumoNew: this.getCopy(this.state.datosConsumoNew),
      });

      await Swal.fire({
        icon: "success",
        text: "Datos Consumo Modificados",
      });
    } catch (error) {
      this.consumoService.manejarErrorHTTP(error, "Consumo");
    }
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex
      flexDirection="column"
      width="50%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  onChange = (datosConsumo: DatosConsumo) => {
    this.setState({ datosConsumoNew: datosConsumo });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={3} texto={"Datos"} />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="10px"
        >
          <ConsumoLoad
            consumo={this.state.datosConsumoNew}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
          />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Actualizar"}
            onClick={this.onUpdate}
          />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
