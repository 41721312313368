import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Visibilizador from "../../../../../../libreria/action/visibilizador/Visibilizador";
import Flex from "../../../../../../libreria/appearance/flex/Flex";


import { CANALES_WEB_SOCKET,  ConsumoService,  ProductoConsumoGet, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";




export interface Props {
  id: number;
}
interface State {
  productos: Array<ProductoConsumoGet>;
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
}

export default class ProductosConsumoShow extends Component<Props, State> {

  private consumoService:ConsumoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      productos: [],
      visible: false,

      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    this.consumoService =  new ConsumoService(Global.UsuarioService.getToken()!);


    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CONSUMOS_PRODUCTOS,
      funcionCallback: this.onChannelMesagge,
    });

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {
    const productos = await this.consumoService.getProductos(this.props.id);
    this.setState({ productos: productos, cargando: false });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    this.setState({ cargando: true });
    const productos = await this.consumoService.getProductos(this.props.id);
    this.setState({ productos: productos, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({ cargando: true });
      const productos = await this.consumoService.getProductos(this.props.id);
      this.setState({ productos: productos, cargando: false });
    }
  };

  renderPrecios = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      {this.renderizarTabla()}
    </Flex>
  );

  renderizarFilas = () => {
    return this.state.productos.map((producto, index) => {
      return (
        <tr>
          <td className="text-center">{index + 1}</td>
          <td className="text-center">{producto.producto.nombre}</td>
          <td className="text-center">{producto.cantidad_minima}</td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Producto</th>
            <th className="text-center">Cantidad</th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  renderLoading = () => "Cargando Productos";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="20px"
      >
        <Visibilizador
          onChange={this.onChangeVisibilizador}
          label={"Productos"}
          labelAlignment={"flex-start"}
        >
          {this.state.cargando ? this.renderLoading() : this.renderPrecios()}
        </Visibilizador>
      </Flex>
    );
  }
}
