import React, { Component } from "react";
import Flex from "../../../../compartido/components/flex/Flex";
import Box from "../../../../compartido/components/box/Box";
import { Button, ListGroup } from "react-bootstrap";

import { BultoInsumoGet, BultoInsumoPost, BultoInsumoService, CANALES_WEB_SOCKET, InsumoGet, InsumoService, TipoInsumoGet, UnidadInsumoGet, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";






export interface Props {
  bultoInsumoId: number;
  ocultar: Function;
}

interface State {
  bultoInsumo: BultoInsumoGet;
  anchoBox: string;
}

export default class InsumoVerDetalle extends Component<Props, State> {

  private bultoInsumoService:BultoInsumoService;


  constructor(props: Props) {
    super(props);

    this.state = {
      bultoInsumo: this.crearBultoInsumoVacio(),
      anchoBox: window.innerWidth < 750 ? "90%" : "50%",
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_BULTO_INSUMOS,
      funcionCallback: this.cargarBultoInsumoCanal,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_INSUMOS,
      funcionCallback: this.cargarBultoInsumoCanal,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_INSUMOS,
      funcionCallback: this.cargarBultoInsumoCanal,
    });

    this.bultoInsumoService =  new BultoInsumoService(Global.UsuarioService.getToken()!);


  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 750) {
      this.setState({ anchoBox: "90%" });
    } else {
      this.setState({ anchoBox: "50%" });
    }
  };

  componentDidMount() {
    this.cargarBultoInsumo(this.props.bultoInsumoId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarBultoInsumo(this.props.bultoInsumoId);
    }
  }

  crearBultoInsumoVacio(): BultoInsumoGet {
    const tipo_insumo: TipoInsumoGet = {
      id: 0,
      tipo: "",
    };
    const unidad_insumo: UnidadInsumoGet = {
      id: 0,
      unidad: "",
    };
    const insumo: InsumoGet = {
      id: 0,
      nombre: "",
      tipo_insumo: tipo_insumo,
      unidad_insumo: unidad_insumo,
    };
    const bultoInsumo: BultoInsumoGet = {
      id: 0,
      nombre: "",
      cantidad: 0,
      insumo: insumo,
      proveedores: [],
    };
    return bultoInsumo;
  }

  cargarBultoInsumoCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.bultoInsumo &&
      dato.message.bultoInsumoId == this.state.bultoInsumo.id
    ) {
      this.cargarBultoInsumo(this.state.bultoInsumo.id);
    }
    if (
      this.state.bultoInsumo &&
      dato.message.insumoId == this.state.bultoInsumo.insumo.id
    ) {
      this.cargarBultoInsumo(this.state.bultoInsumo.id);
    }
    if (
      this.state.bultoInsumo &&
      dato.message.tipoInsumoId == this.state.bultoInsumo.insumo.tipo_insumo.id
    ) {
      this.cargarBultoInsumo(this.state.bultoInsumo.id);
    }
  };

  cargarBultoInsumo = async (id: number) => {
    const resBultoInsumo = await this.bultoInsumoService.get(id).catch((error) => {
      this.bultoInsumoService.manejarErrorHTTP(error, "Bulto Insumo");
      return null;
    });
    if (resBultoInsumo != null) {
      this.setState({
        bultoInsumo: resBultoInsumo,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  render() {
    if (this.state.bultoInsumo.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal={"10rem"} paddingVertical={"2rem"}>
            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h5>Nombre: {this.state.bultoInsumo.nombre}</h5>
                  <h5>Insumo: {this.state.bultoInsumo.insumo.nombre}</h5>
                  <h5>
                    Tipo Insumo:{" "}
                    {this.state.bultoInsumo.insumo.tipo_insumo.tipo}
                  </h5>
                  <h5>Cantidad: {this.state.bultoInsumo.cantidad}</h5>
                </div>
              </Flex>
            </Flex>

            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>

            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h4>
                    Proveedores Actuales
                    {this.state.bultoInsumo.proveedores.length == 0 ? (
                      " : No tiene"
                    ) : (
                      <Flex
                        container
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        paddingTop="10px"
                      >
                        <ListGroup>
                          {this.state.bultoInsumo.proveedores.map(
                            (relacion) => (
                              <ListGroup.Item style={{ textAlign: "center" }}>
                                {relacion.proveedor.nombre +
                                  " - Cantidad mínima que vende: " +
                                  relacion.cantidad_minima +
                                  " - A: " +
                                  relacion.precio +
                                  "$"}
                                <Button
                                  style={{
                                    marginLeft: "1rem",
                                  }}
                                >
                                  Ver Historial
                                </Button>
                              </ListGroup.Item>
                            )
                          )}
                        </ListGroup>
                      </Flex>
                    )}
                  </h4>
                </div>
              </Flex>
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <button
                className={"btn btn-outline-secondary"}
                onClick={this.ocultar}
                style={{ width: "150px" }}
              >
                Salir
              </button>
            </Flex>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
