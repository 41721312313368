import React, { Component } from "react";
import Flex from "../../../../compartido/components/flex/Flex";
import Box from "../../../../compartido/components/box/Box";
import { Button, ListGroup } from "react-bootstrap";

import { CANALES_WEB_SOCKET, IndumentariaGet, IndumentariaPost, IndumentariaService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";


export interface Props {
  indumentariaId: number;
  ocultar: Function;
}

interface State {
  indumentaria: IndumentariaGet;
  anchoBox: string;
}

export default class IndumentariaVerDetalle extends Component<Props, State> {

  private indumentariaService:IndumentariaService;

  constructor(props: Props) {
    super(props);

    this.state = {
      indumentaria: this.crearIndumentariaVacio(),
      anchoBox: window.innerWidth < 750 ? "90%" : "50%",
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_INDUMENTARIAS,
      funcionCallback: this.cargarIndumentariaCanal,
    });

    this.indumentariaService =  new IndumentariaService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 750) {
      this.setState({ anchoBox: "90%" });
    } else {
      this.setState({ anchoBox: "50%" });
    }
  };

  componentDidMount() {
    this.cargarIndumentaria(this.props.indumentariaId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarIndumentaria(this.props.indumentariaId);
    }
  }

  crearIndumentariaVacio(): IndumentariaGet {
    const indumentaria: IndumentariaGet = {
      id: 0,
      nombre: "",
      talle: "",
      marca: "",
      descripcion: "",
      proveedores: [],
    };
    return indumentaria;
  }

  cargarIndumentariaCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.indumentaria &&
      dato.message.indumentariaId == this.state.indumentaria.id
    ) {
      this.cargarIndumentaria(this.state.indumentaria.id);
    }
  };

  cargarIndumentaria = async (id: number) => {
    const resIndumentaria = await this.indumentariaService.get(id).catch((error) => {
      this.indumentariaService.manejarErrorHTTP(error, "Indumentaria");
      return null;
    });
    if (resIndumentaria != null) {
      this.setState({
        indumentaria: resIndumentaria,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  render() {
    if (this.state.indumentaria.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal={"10rem"} paddingVertical={"2rem"}>
            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h5>Nombre: {this.state.indumentaria.nombre}</h5>
                  <h5>Talle: {this.state.indumentaria.talle}</h5>
                  <h5>Marca: {this.state.indumentaria.marca}</h5>
                  <h5>Descripcion: {this.state.indumentaria.descripcion}</h5>
                </div>
              </Flex>
            </Flex>

            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>

            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h4>
                    Proveedores Actuales
                    {this.state.indumentaria.proveedores.length == 0 ? (
                      " : No tiene"
                    ) : (
                      <Flex
                        container
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        paddingTop="10px"
                      >
                        <ListGroup>
                          {this.state.indumentaria.proveedores.map(
                            (relacion) => (
                              <ListGroup.Item style={{ textAlign: "center" }}>
                                {relacion.proveedor.nombre +
                                  " - Cantidad mínima que vende: " +
                                  relacion.cantidad_minima +
                                  " - A: " +
                                  relacion.precio +
                                  "$"}
                                <Button
                                  style={{
                                    marginLeft: "1rem",
                                  }}
                                >
                                  Ver Historial
                                </Button>
                              </ListGroup.Item>
                            )
                          )}
                        </ListGroup>
                      </Flex>
                    )}
                  </h4>
                </div>
              </Flex>
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <button
                className={"btn btn-outline-secondary"}
                onClick={this.ocultar}
                style={{ width: "150px" }}
              >
                Salir
              </button>
            </Flex>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
