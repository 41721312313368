import Utils from "../../utils/utilidades";
import { FilaTablaGenerica } from "./TablaGenerica";
class OrdenadorTabla {
  getFilasOrdenada(
    filasTabla: Array<FilaTablaGenerica>,
    numeroColumna: number,
    ascedente: boolean
  ): Array<FilaTablaGenerica> {
    //Se arma a partir de cada de filas, un arreglo de string, con el id en la primer celda
    let filas: string[][] = [];
    for (let i = 0; i < filasTabla.length; i++) {
      filas.push([filasTabla[i].id.toString(), ...filasTabla[i].celdas]);
    }
    //Se realiza el metodo de ordenar las filas
    if (filas.length > 0 && numeroColumna < filasTabla[0].celdas.length) {
      numeroColumna = numeroColumna + 1; //Se le agrega 1, porque se le agrego al arreglo, el id
      if (Utils.esNumero(filas[0][numeroColumna])) {
        // si es un numero ordena por numero
        filas = filas.sort((a, b) => {
          return ascedente
            ? Number.parseFloat(a[numeroColumna]) -
                Number.parseFloat(b[numeroColumna])
            : Number.parseFloat(b[numeroColumna]) -
                Number.parseFloat(a[numeroColumna]);
        });
      } else {
        // si no es un numero ordena por string
        filas = filas.sort((a, b) => {
          if (ascedente) {
            if (a[numeroColumna] > b[numeroColumna]) return 1;
            if (a[numeroColumna] < b[numeroColumna]) return -1;
            return 0;
          } else {
            if (b[numeroColumna] > a[numeroColumna]) return 1;
            if (b[numeroColumna] < a[numeroColumna]) return -1;
            return 0;
          }
        });
      }
    }
    //Se arma un arreglo de filasTabla, ya ordenada
    let filasOrdenadaTabla: Array<FilaTablaGenerica> = [];
    for (let i = 0; i < filas.length; i++) {
      let filaResult: FilaTablaGenerica = {
        id: Number.parseInt(filas[i][0]),
        celdas: filas[i].slice(1), // Corre 1 y devuelve el arreglo (sin id)
      };
      filasOrdenadaTabla.push(filaResult);
    }
    return filasOrdenadaTabla;
  }
  //Version Vieja
  // Le llega un arreglo de columnas y lo de devuelve como filas (hace una transposicion)
  //Llega un arreglo de los datos de cada columna. Ejemplo:
  /*
    [Bidon,Botella]
    [retornable,descartable]
    [10,12]
    */
  //Retorno un arreglo de los datos de cada fila. Ejemplo:
  /*
    [Bidon,retornable,10]
    [Botella,descartable,12]
    */
  /*
  getFilas(datosColumnas: Array<Array<string>>): Array<Array<string>> {
    let filas: string[][] = [];
    let i, j: number;
    if (datosColumnas.length > 0) {
      for (
        i = 0;
        i < datosColumnas[0].length;
        i++ //filas
      ) {
        let fila: string[] = [];
        for (
          j = 0;
          j < datosColumnas.length;
          j++ //columnas
        ) {
          fila.push(datosColumnas[j][i]);
        }
        filas.push(fila);
      }
    }
    return filas;
  }
  getFilasOrdenada(
    datosColumnas: Array<Array<string>>,
    numeroColumna: number,
    ascedente: boolean
  ): Array<Array<string>> {
    let filas: string[][] = [];
    filas = this.getFilas(datosColumnas);

    if (filas.length > 0 && numeroColumna < datosColumnas.length) {
      if (Utils.esNumero(filas[0][numeroColumna])) {
        // si es un numero ordena por numero
        filas = filas.sort((a, b) => {
          return ascedente
            ? Number.parseFloat(a[numeroColumna]) -
                Number.parseFloat(b[numeroColumna])
            : Number.parseFloat(b[numeroColumna]) -
                Number.parseFloat(a[numeroColumna]);
        });
      } else {
        // si no es un numero ordena por string
        filas = filas.sort((a, b) => {
          if (ascedente) {
            if (a[numeroColumna] > b[numeroColumna]) return 1;
            if (a[numeroColumna] < b[numeroColumna]) return -1;
            return 0;
          } else {
            if (b[numeroColumna] > a[numeroColumna]) return 1;
            if (b[numeroColumna] < a[numeroColumna]) return -1;
            return 0;
          }
        });
      }
    }
    return filas;
  } */
}
export default new OrdenadorTabla();
