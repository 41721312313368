import React, { Component } from "react";
import { CANALES_WEB_SOCKET, MaquinaHerramientaGet, MaquinaHerramientaService, WebSocketService } from "serviciossaintmichel";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";
import { Global } from "../../../../Global";







export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  maquinasHerramienta: Array<MaquinaHerramientaGet>;
}

export default class MaquinaHerramientaLista extends Component<Props, State> {

  private maquinaHerramientaService:MaquinaHerramientaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      maquinasHerramienta: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_HERRAMIENTA,
      funcionCallback: this.getElementos,
    });

    this.maquinaHerramientaService = new MaquinaHerramientaService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.maquinaHerramientaService.list()
      .then((result) => {
        this.setState({ maquinasHerramienta: result });
      })
      .catch((error) => {
        this.maquinaHerramientaService.manejarErrorHTTP(
          error,
          "Máquina Herramienta"
        );
      });
  };

  componentDidMount() {
    this.maquinaHerramientaService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }

  armarFilas(
    maquinasHerramienta: Array<MaquinaHerramientaGet>
  ): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (maquinasHerramienta.length > 0) {
      for (let i = 0; i < maquinasHerramienta.length; i++) {
        let fila: Array<string> = [];
        fila.push(maquinasHerramienta[i].nombre);
        fila.push(maquinasHerramienta[i].proveedor.nombre);
        fila.push(maquinasHerramienta[i].marca);
        fila.push(maquinasHerramienta[i].descripcion);
        let filaResult: FilaTablaGenerica = {
          id: maquinasHerramienta[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }
  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Elementos.Maquinas.MaquinaHerramienta.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Elementos.Maquinas.MaquinaHerramienta.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.maquinaHerramientaService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.maquinasHerramienta)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.maquinaHerramientaService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaMaquinasHerramienta"
      />
    );
  }
}
