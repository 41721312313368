import React, { Component } from "react";
import * as Icon from "react-bootstrap-icons";
import { Table, Button } from "react-bootstrap";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import VentaProductoLoad from "./VentaProductoLoad";
import { VentaProductoLectura } from "serviciossaintmichel";

export interface Props {
  ventasProducto: Array<VentaProductoLectura>;
  onChange?: Function;
}

interface State {
  ventasProducto: Array<VentaProductoLectura>;
}

export default class VentasProductoLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ventasProducto: props.ventasProducto,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (
      prevProps.ventasProducto !== this.props.ventasProducto &&
      this.state.ventasProducto !== this.props.ventasProducto
    ) {
      this.setState({ ventasProducto: this.props.ventasProducto });
    }

    if (prevState.ventasProducto !== this.state.ventasProducto && this.props.onChange) {
      this.props.onChange(this.state.ventasProducto);
    }
  }

  eliminarVentaProducto = (idProducto: number) => {
    const ventasProducto = this.state.ventasProducto.filter(
      (vp: VentaProductoLectura) => vp.producto.id !== idProducto
    );
    this.setState({
      ventasProducto: [...ventasProducto],
    });
  };

  agregarVentaProducto = (nuevaVentaProducto: VentaProductoLectura): void => {
    this.setState((prevState) => ({
      ventasProducto: [...prevState.ventasProducto, nuevaVentaProducto],
    }));
  };

  actualizarVentaProducto = (idProducto: number, infoVenta: VentaProductoLectura) => {
    const ventas = this.state.ventasProducto.map((vp) => (vp.producto.id === idProducto ? infoVenta : vp));
    this.setState({
      ventasProducto: ventas,
    });
  };

  render() {
    const { ventasProducto } = this.state;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Producto</th>
              <th>Vendidos</th>
              <th>Bonificados</th>
              <th>Vacíos</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {ventasProducto.map((ventaProducto, idx) => (
              <tr key={ventaProducto.producto.id}>
                <td>{ventaProducto.producto.nombre}</td>
                <td>
                  <Input
                    type={InputTypes.Number}
                    data={ventaProducto.vendidos}
                    onChange={(valor: number) => {
                      this.actualizarVentaProducto(ventaProducto.producto.id, {
                        ...ventaProducto,
                        vendidos: valor,
                      });
                    }}
                  />
                </td>
                <td>
                  <Input
                    type={InputTypes.Number}
                    data={ventaProducto.bonificados}
                    onChange={(valor: number) => {
                      this.actualizarVentaProducto(ventaProducto.producto.id, {
                        ...ventaProducto,
                        bonificados: valor,
                      });
                    }}
                  />
                </td>
                <td>
                  <Input
                    type={InputTypes.Number}
                    data={ventaProducto.vacios}
                    onChange={(valor: number) => {
                      this.actualizarVentaProducto(ventaProducto.producto.id, {
                        ...ventaProducto,
                        vacios: valor,
                      });
                    }}
                  />
                </td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      this.eliminarVentaProducto(ventaProducto.producto.id);
                    }}
                  >
                    <Icon.Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <VentaProductoLoad
          onSubmitCallback={this.agregarVentaProducto}
          excluirProductos={ventasProducto.map((vP) => vP.producto.id)}
        />
      </>
    );
  }
}
