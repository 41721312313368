import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import TipoVehiculos from "./TipoVehiculo/components/TipoVehiculos";
import TipoCombustible from  "./TipoCombustible/components/TipoCombustibles";
import Vehiculos from "./Vehiculo/components/Vehiculos";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
export interface Props {}


export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];

  if (Global.UsuarioService.getPermisos().Elementos.Vehiculos.TipoCombustible.hasAccess()) {
    aux.push({name: "Tipo Combustibles",
    path: SeccionVehiculos.pathBase + "/tipo-combustible",
    component: TipoCombustible,})
  }
  if (Global.UsuarioService.getPermisos().Elementos.Vehiculos.TipoVehiculo.hasAccess()) {
    aux.push({name: "Tipo Vehiculos",
    path: SeccionVehiculos.pathBase + "/tipo-vehiculos",
    component: TipoVehiculos,})
  }
  if (Global.UsuarioService.getPermisos().Elementos.Vehiculos.Vehiculo.hasAccess()) {
    aux.push({
      name: "Vehiculos",
      path: SeccionVehiculos.pathBase + "/vehiculos",
      component: Vehiculos,
    })
  }  
  return aux;
}



export default class SeccionVehiculos extends Component<Props> {
  static pathBase = "/elementos/seccion-vehiculos";
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Vehiculos"}
      />
    );
  }
}
