import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import SelectProveedor from "../../../../produccion/proveedores/components/SelectProveedor";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";

import { CANALES_WEB_SOCKET,  MaquinaHerramientaPost,  MaquinaHerramientaService, Proveedor, ProveedorService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  ocultar: Function;
}

interface State {
  maquinaHerramienta: MaquinaHerramientaPost;
  proveedores: Array<Proveedor>;
  alerta: any;
}

export default class MaquinaHerramientaNuevo extends Component<Props, State> {

  private maquinaHerramientaService:MaquinaHerramientaService;
  private proveedorService:ProveedorService;

  constructor(props: Props) {
    super(props);
    this.state = {
      maquinaHerramienta: this.maquinaHerramientaCrear(),
      proveedores: [],
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    /* WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.PROVEEDORES,
      funcionCallback: this.cargarProveedor,
    }); */

    this.maquinaHerramientaService = new MaquinaHerramientaService(Global.UsuarioService.getToken()!);
    this.proveedorService = new ProveedorService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    await this.cargarProveedores();
    const maquinaHerramientaCrear: MaquinaHerramientaPost =
      this.state.maquinaHerramienta;
    if (this.state.proveedores.length > 0) {
      maquinaHerramientaCrear.proveedor = this.state.proveedores[0].id;
    } else {
      maquinaHerramientaCrear.proveedor = 0;
    }
    this.setState({
      maquinaHerramienta: maquinaHerramientaCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  cargarProveedores = async () => {
    const resProveedores = await this.proveedorService.listAll().catch((error) => {
      this.proveedorService.manejarErrorHTTP(error, "Proveedor");
      return null;
    });
    if (resProveedores != null) {
      this.setState({
        proveedores: resProveedores,
      });
    }
  };

  maquinaHerramientaCrear(): MaquinaHerramientaPost {
    return {
      nombre: "",
      proveedor: 0,
      marca: "",
      descripcion: "",
    };
  }
  setearNombre = (nombre: string) => {
    let maquinaHerramienta = this.state.maquinaHerramienta;
    maquinaHerramienta.nombre = nombre;
    this.setState({ maquinaHerramienta: maquinaHerramienta });
  };
  setearProveedor = (proveedor: Proveedor) => {
    let maquinaHerramienta = this.state.maquinaHerramienta;
    maquinaHerramienta.proveedor = proveedor.id;
    this.setState({ maquinaHerramienta: maquinaHerramienta });
  };
  setearMarca = (marca: string) => {
    let maquinaHerramienta = this.state.maquinaHerramienta;
    maquinaHerramienta.marca = marca;
    this.setState({ maquinaHerramienta: maquinaHerramienta });
  };
  setearDescripcion = (descripcion: string) => {
    let maquinaHerramienta = this.state.maquinaHerramienta;
    maquinaHerramienta.descripcion = descripcion;
    this.setState({ maquinaHerramienta: maquinaHerramienta });
  };

  handleCreate() {
    this.maquinaHerramientaService.create(this.state.maquinaHerramienta)
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_HERRAMIENTA
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Maquina Herramienta Creada!",
            titulo: "Atención",
            estado: "block",
          },
        });
        const maquinaHerramientaCrear: MaquinaHerramientaPost =
          this.maquinaHerramientaCrear();
        maquinaHerramientaCrear.proveedor =
          this.state.maquinaHerramienta.proveedor;
        this.setState({
          maquinaHerramienta: maquinaHerramientaCrear,
        });
      })
      .catch((error) => {
        this.maquinaHerramientaService.manejarErrorHTTP(
          error,
          "Máquina Herramienta"
        );
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.maquinaHerramienta.nombre &&
      this.state.maquinaHerramienta.proveedor &&
      this.state.maquinaHerramienta.proveedor != 0 &&
      this.state.maquinaHerramienta.marca &&
      this.state.maquinaHerramienta.descripcion
      ? false
      : true;
  };

  renderizarFormulario() {
    if (this.state.proveedores.length == 0) {
      return (
        <div>
          {"Aún no se poseen proveedores, vaya y cree uno."}
          <button
            type={"reset"}
            className={"btn btn-outline-secondary"}
            onClick={this.ocultar}
          >
            {"Ocultar"}
          </button>
        </div>
      );
    }
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="nombre">Nombre</label>
                <InputCaracteres
                  id="nombre"
                  name="nombre"
                  onChange={this.setearNombre}
                  value={this.state.maquinaHerramienta.nombre}
                  placeholder="Ingrese Nombre de la Maquina Herramienta"
                />
              </div>

              <div className={"form-group"}>
                <SelectProveedor
                  proveedores={this.state.proveedores}
                  seleccionado={this.state.proveedores[0]}
                  callbackParent={this.setearProveedor}
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="marca">Marca</label>
                <InputCaracteres
                  id="marca"
                  name="marca"
                  onChange={this.setearMarca}
                  value={this.state.maquinaHerramienta.marca}
                  placeholder="Ingrese Marca de la Maquina Herramienta"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="descripcion">Descripcion</label>
                <InputCaracteres
                  id="descripcion"
                  name="descripcion"
                  onChange={this.setearDescripcion}
                  value={this.state.maquinaHerramienta.descripcion}
                  placeholder="Ingrese Descripcion de  la Maquina Herramienta"
                />
              </div>

              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  ref="boton"
                  type="submit"
                  className={"btn btn-info"}
                  disabled={this.disableButton()}
                >
                  {"Crear"}
                </button>
                <button
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
              <div className={"form-group"}>
                <Alerta
                  tipo={this.state.alerta.tipo}
                  titulo={this.state.alerta.titulo}
                  mensaje={this.state.alerta.mensaje}
                  estado={this.state.alerta.estado}
                />
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
