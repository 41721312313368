import React, { Component } from "react";
import { Font } from "../../data/models/models";

interface Props {
  children?: any;
  color?: string;
  font?: string;
  bold?: string;
  size?: string;
  align?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
  textDecoration?: string;
}

interface State {}

class Text extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <span
        style={{
          color: this.props.color || "black",
          fontSize: this.props.size || "18px",
          fontFamily: this.props.font || Font.family,
          textAlign: this.props.align || "center",
          verticalAlign: "middle",
          textDecoration: this.props.textDecoration || "none",
        }}
      >
        {this.props.children}
      </span>
    );
  }
}
export default Text;
