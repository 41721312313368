import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";



import { BultoProductoGet, BultoProductoPost, BultoProductoService, CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";


export interface Props {
  bultoProductoId: number;
}

interface State {
  bultoProducto: BultoProductoGet;
}

export default class BultoProductoActualizarPropio extends Component<Props,State>
{

  private bultoProductoService:BultoProductoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      bultoProducto: this.crearBultoProductoVacio(),
    };
  
    this.bultoProductoService =  new BultoProductoService(Global.UsuarioService.getToken()!);

  
  }

  componentDidMount() {
    this.cargarBultoProducto(this.props.bultoProductoId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarBultoProducto(this.props.bultoProductoId);
    }
  }

  crearBultoProductoVacio(): BultoProductoGet {
    const bultoProducto: BultoProductoGet = {
      id: 0,
      nombre: "",
      insumos: [],
      productos: [],
    };
    return bultoProducto;
  }

  cargarBultoProducto = async (id: number) => {
    const resBultoProducto = await this.bultoProductoService.get(id).catch(
      (error) => {
        this.bultoProductoService.manejarErrorHTTP(error, "Bulto Producto");
        return null;
      }
    );
    if (resBultoProducto != null) {
      this.setState({
        bultoProducto: resBultoProducto,
      });
    }
  };

  setearNombre = (nombre: string) => {
    let bultoProducto = this.state.bultoProducto;
    bultoProducto.nombre = nombre;
    this.setState({ bultoProducto: bultoProducto });
  };

  handleUpdate() {
    const bultoProductoActualizar: BultoProductoPost = {
      nombre: this.state.bultoProducto.nombre,
    };
    return this.bultoProductoService.update(
      bultoProductoActualizar,
      this.state.bultoProducto.id
    )
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_BULTO_PRODUCTOS,
          {
            bultoProductoId: this.state.bultoProducto.id,
          }
        );
      })
      .catch((error) => {
        this.bultoProductoService.manejarErrorHTTP(error, "Bulto Producto");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.bultoProducto.nombre ? false : true;
  };

  render() {
    if (this.state.bultoProducto.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            <div className={"row"}>
              <div className={"column"}>
                <form
                  className={"form-horizontal"}
                  onSubmit={this.handleSubmit}
                >
                  <div className={"form-group"}>
                    <label htmlFor="nombre">Nombre</label>
                    <InputCaracteres
                      id="nombre"
                      name="nombre"
                      onChange={this.setearNombre}
                      value={this.state.bultoProducto.nombre}
                      placeholder="Ingrese Nombre de Producto"
                    />
                  </div>
                  <div
                    className={"form-group"}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <RequestButton
                      texto="Actualizar"
                      msgOnSuccess="Producto actualizado con exito"
                      msgOnFailure="Ocurrio un error al actualizar el producto"
                      propsBoton={{
                        variant: "info",
                        disabled: this.disableButton(),
                      }}
                      onClick={() => this.handleUpdate()}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
