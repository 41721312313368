import React, { Component } from "react";
import { Table } from "react-bootstrap";
import EntregaBultoProductoProgramacionEntregaLoad from "./EntregaBultoProductoProgramacionEntregaLoad";
import { EntregaProgramacionBultoProducto, EstadoLogisticaPaquete } from "serviciossaintmichel";
import { Select } from "@mantine/core";

interface ConfigCampo {
  disabled: Array<string>;
  hidden: Array<string>;
}

export interface Props {
  crearNuevos?: boolean;
  configCampos?: ConfigCampo;
  entregasBultoProducto: Array<EntregaProgramacionBultoProducto>;
  onChange?: Function;
}

interface State {
  entregasBultoProducto: Array<EntregaProgramacionBultoProducto>;
}

export default class EntregasBultoProductoProgramacionEntregaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entregasBultoProducto: props.entregasBultoProducto,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (
      prevProps.entregasBultoProducto !== this.props.entregasBultoProducto &&
      this.state.entregasBultoProducto !== this.props.entregasBultoProducto
    ) {
      this.setState({ entregasBultoProducto: this.props.entregasBultoProducto });
    }

    if (prevState.entregasBultoProducto !== this.state.entregasBultoProducto && this.props.onChange) {
      this.props.onChange(this.state.entregasBultoProducto);
    }
  }

  eliminarEntregaBultoProducto = (idBultoProducto: number) => {
    const entregasBultoProducto = this.state.entregasBultoProducto.filter(
      (vbp: EntregaProgramacionBultoProducto) => vbp.paquete.bulto_producto.id !== idBultoProducto
    );
    this.setState({
      entregasBultoProducto: [...entregasBultoProducto],
    });
  };

  agregarEntregaBultoProducto = (nuevaEntregaBultoProducto: EntregaProgramacionBultoProducto): void => {
    this.setState((prevState) => ({
      entregasBultoProducto: [...prevState.entregasBultoProducto, nuevaEntregaBultoProducto],
    }));
  };

  actualizarEntregaBultoProducto = (infoEntrega: EntregaProgramacionBultoProducto) => {
    const entregas = this.state.entregasBultoProducto.map((ebp) =>
      ebp.paquete.id === infoEntrega.paquete.id ? infoEntrega : ebp
    );
    this.setState({
      entregasBultoProducto: entregas,
    });
  };

  estaDeshabilitado = (id: string): boolean => {
    if (!this.props.configCampos) {
      return false;
    }

    return this.props.configCampos.disabled.indexOf(id) !== -1;
  };

  render() {
    const { entregasBultoProducto } = this.state;
    const { crearNuevos } = this.props;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Bulto Producto</th>
              <th>Cantidad</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {entregasBultoProducto.map((entregaBultoProducto, idx) => (
              <tr key={entregaBultoProducto.paquete.bulto_producto.id}>
                <td>{`${entregaBultoProducto.paquete.bulto_producto.nombre} - Id ${entregaBultoProducto.paquete.bulto_producto.id}`}</td>
                <td>{entregaBultoProducto.paquete.cantidad}</td>
                <td>
                  <Select
                    data={[
                      EstadoLogisticaPaquete.ENTREGADO,
                      EstadoLogisticaPaquete.NO_ENTREGADO,
                      EstadoLogisticaPaquete.RECHAZADO,
                    ]}
                    value={entregaBultoProducto.estado}
                    onChange={(seleccionado: EstadoLogisticaPaquete) => {
                      this.actualizarEntregaBultoProducto({
                        ...entregaBultoProducto,
                        estado: seleccionado,
                      });
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {crearNuevos ? (
          <EntregaBultoProductoProgramacionEntregaLoad
            onSubmitCallback={this.agregarEntregaBultoProducto}
            excluirBultosProducto={entregasBultoProducto.map((ep) => ep.paquete.bulto_producto.id)}
          />
        ) : null}
      </>
    );
  }
}
