import _ from "lodash";
import React, { Component } from "react";
import Swal from "sweetalert2";

import { CANALES_WEB_SOCKET, CriterioOrden, CuponService, WebSocketService, CuponLectura } from "serviciossaintmichel";
import { Global } from "../../../../../Global";
import CuponesFilter from "./CuponesFilter";
import { TipoCupon } from "serviciossaintmichel/lib/apisaintmichel/ventas/cupones/models/Cupon";
import CuponesTable from "./CuponesTable";
import { formatISO } from "date-fns";
import SelectComponent from "../../../../../compartido/components/select/SelectComponent";
import { PropsSelectGenerico } from "../../../../../compartido/components/select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../../../../../compartido/components/select/SelectBusqueda";
import { SelectProps } from "@mantine/core";
import { criteriosOrdenIguales } from "../../../../../compartido/utils/OrdenUtils";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
  filtros?: FiltrosCupones;
  version: VersionCuponesShow;
  propsSelect?: PropsSelectGenerico<CuponLectura> & PropsSelectGeneral & Omit<SelectProps, "data">;
}

export enum VersionCuponesShow {
  TABLE,
  SELECT,
}

export interface FiltrosCupones {
  criterioOrden: CriterioOrden | null;
  limite: number;
  tipo?: TipoCupon;
  vigenciaDesde?: Date;
  vigenciaHasta?: Date;
}

interface State {
  cupones: Array<CuponLectura> | null;
  filtros: FiltrosCupones;
}

const ordenInicial: CriterioOrden = { campo: "id", modo: "ASC" };
const PASO_LIMITE = 5;

export default class CuponesShow extends Component<Props, State> {
  private webSocket: WebSocket | null;

  private cuponService: CuponService;

  constructor(props: Props) {
    super(props);

    this.state = {
      cupones: null,
      filtros: {
        criterioOrden: props.filtros ? props.filtros.criterioOrden : ordenInicial,
        limite: props.filtros ? props.filtros.limite : PASO_LIMITE,
      },
    };

    this.webSocket = null;
    this.cuponService = new CuponService(Global.UsuarioService.getToken()!);
  }

  componentWillUnmount = () => {
    this.webSocket?.close();
  };

  componentDidMount = () => {
    this.setCupones();
    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_DESCUENTOS,
      funcionCallback: this.setCupones,
    });
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      prevState.filtros.criterioOrden !== this.state.filtros.criterioOrden ||
      prevState.filtros.limite !== this.state.filtros.limite
    ) {
      this.setCupones();
    }

    if (!_.isEqual(prevProps.filtros, this.props.filtros)) {
      const { filtros } = this.props;
      this.setState((prevState) => ({
        filtros: {
          ...prevState.filtros,
          criterioOrden: filtros ? filtros.criterioOrden : ordenInicial,
          limite: filtros ? filtros.limite : PASO_LIMITE,
        },
      }));
    }
  };

  setCupones = async () => {
    const { limite, criterioOrden, tipo, vigenciaDesde, vigenciaHasta } = this.state.filtros;

    try {
      const params = new URLSearchParams({ limite: limite.toString() });

      if (criterioOrden) {
        const orden = criterioOrden.modo === "DESC" ? `-${criterioOrden.campo}` : criterioOrden.campo;
        params.append("orden", orden);
      }

      if (tipo) {
        params.append("tipo", tipo);
      }

      if (vigenciaDesde) {
        params.append("vigencia_desde", formatISO(vigenciaDesde, { representation: "date" }));
      }

      if (vigenciaHasta) {
        params.append("vigencia_hasta", formatISO(vigenciaHasta, { representation: "date" }));
      }

      const cupones: Array<CuponLectura> = await this.cuponService.list(params);

      this.setState({ cupones: cupones });
    } catch (error) {
      this.cuponService.manejarErrorHTTP(error, "Cupones");
    }
  };

  onDelete = async (cuponId: number) => {
    try {
      await this.cuponService.delete(cuponId);

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.VENTAS_CUPONES);

      await Swal.fire({
        icon: "success",
        text: "Cupon eliminado",
      });
    } catch (error) {
      this.cuponService.manejarErrorHTTP(error, "Cupon");
    }
  };

  seleccionarCriterio = (criterio: CriterioOrden | null) => {
    const { criterioOrden } = this.state.filtros;

    if (criterioOrden === null || !criteriosOrdenIguales(criterioOrden, criterio)) {
      this.setState((prevState) => ({ filtros: { ...prevState.filtros, criterioOrden: criterio } }));
    } else {
      this.setState((prevState) => ({ filtros: { ...prevState.filtros, criterioOrden: null } }));
    }
  };

  aumentarLimite = () => {
    this.setState((prevState) => ({
      filtros: { ...prevState.filtros, limite: prevState.filtros.limite + PASO_LIMITE },
    }));
  };

  setFiltros = ({
    tipo = this.state.filtros.tipo,
    vigenciaDesde = this.state.filtros.vigenciaDesde,
    vigenciaHasta = this.state.filtros.vigenciaHasta,
  }) => {
    this.setState((prevState) => ({
      filtros: { ...prevState.filtros, tipo: tipo, vigenciaDesde: vigenciaDesde, vigenciaHasta: vigenciaHasta },
    }));
  };

  render = () => {
    const { cupones, filtros } = this.state;
    const { version, propsSelect } = this.props;

    if (cupones === null) {
      return <div>Cargando...</div>;
    }

    switch (version) {
      case VersionCuponesShow.TABLE: {
        return (
          <div>
            <CuponesFilter setFiltros={this.setFiltros} busquedaHandler={this.setCupones} filtros={filtros} />
            <CuponesTable
              onUpdate={this.props.onUpdate}
              onView={this.props.onView}
              onDelete={this.onDelete}
              cupones={cupones}
              criterioOrdenActual={filtros.criterioOrden}
              criterioOrdenHandler={this.seleccionarCriterio}
              aumentarLimite={this.aumentarLimite}
            />
          </div>
        );
      }
      case VersionCuponesShow.SELECT: {
        if (!propsSelect) {
          return null;
        }

        return (
          <SelectComponent
            {...propsSelect}
            items={cupones}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado}
            labelGetter={(obj: CuponLectura) => `Cupón ${obj.id} - ${obj.descuento.nombre}`}
          />
        );
      }
      default: {
        return null;
      }
    }
  };
}
