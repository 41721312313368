import React, { Component } from "react";
import { MaquinaServicioFisicaGet } from "serviciossaintmichel";
import { Data } from "../../../../libreria/data/models/models";
import Select from "../../../../libreria/data/select/Select";

interface Props {
  maquinas: Array<MaquinaServicioFisicaGet>;
  onSelected: Function;
  seleccionado?: number;
}

interface State {}

export default class SelectMaquinaServicioFisica extends Component<Props, State> {
  crearItemsSelect = (maquinas: Array<MaquinaServicioFisicaGet>): Array<Data> => {
    return maquinas.map((maquina: MaquinaServicioFisicaGet) => ({
      id: maquina.id,
      text: `${maquina.marca} - ${maquina.numero_serie}`,
    }));
  };

  onChange = (maquinaId: number) => {
    const maquinasConId = this.props.maquinas.filter((maquina) => maquina.id === maquinaId);
    const maquina = maquinasConId.length > 0 ? maquinasConId[0] : null;
    this.props.onSelected(maquina);
  };

  render = () => {
    const { maquinas, seleccionado } = this.props;
    const dataSelect = this.crearItemsSelect(maquinas);

    return <Select elements={dataSelect} onChange={this.onChange} selected={seleccionado} />;
  };
}
