import React, { Component } from "react";
import Flex from "../../../../compartido/components/flex/Flex";
import Box from "../../../../compartido/components/box/Box";

import { CANALES_WEB_SOCKET,       MaquinaServicioFisicaGet,       MaquinaServicioFisicaPost,       MaquinaServicioFisicaService,       MaquinaServicioSimbolicaGet,       MaquinaServicioSimbolicaService,       Proveedor, ProveedorService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  maquinaServicioFisicaId: number;
  ocultar: Function;
}

interface State {
  maquinaServicioFisica: MaquinaServicioFisicaGet;
  anchoBox: string;
}

export default class MaquinaServicioFisicaVerDetalle extends Component<Props, State> {

  private maquinaServicioFisicaService:MaquinaServicioFisicaService;

  constructor(props: Props) {
    super(props);

    this.state = {
      maquinaServicioFisica: this.crearMaquinaServicioFisicaVacio(),
      anchoBox: window.innerWidth < 750 ? "90%" : "50%",
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_FISICA,
      funcionCallback: this.cargarMaquinaServicioFisicaCanal,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_SIMBOLICA,
      funcionCallback: this.cargarMaquinaServicioFisicaCanal,
    });
    /* WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.PROVEEDORES,
      funcionCallback: this.cargarProveedores,
    }); */

    this.maquinaServicioFisicaService = new MaquinaServicioFisicaService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 750) {
      this.setState({ anchoBox: "90%" });
    } else {
      this.setState({ anchoBox: "50%" });
    }
  };

  componentDidMount() {
    this.cargarMaquinaServicioFisica(this.props.maquinaServicioFisicaId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarMaquinaServicioFisica(this.props.maquinaServicioFisicaId);
    }
  }

  crearMaquinaServicioFisicaVacio(): MaquinaServicioFisicaGet {
    const proveedor: Proveedor = { id: 0, nombre: "" };
    const fecha_fabricacion: Date = new Date();
    const maquina_simbolica: MaquinaServicioSimbolicaGet = {
      id: 0,
      nombre: "",
      productos: [],
      proveedores: [],
    };
    const maquinaServicioFisica: MaquinaServicioFisicaGet = {
      id: 0,
      marca: "",
      numero_serie: "",
      fecha_fabricacion: fecha_fabricacion,
      maquina_simbolica: maquina_simbolica,
      proveedor: proveedor,
      direccion: null,
    };
    return maquinaServicioFisica;
  }

  cargarMaquinaServicioFisicaCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.maquinaServicioFisica &&
      dato.message.maquinaServicioFisicaId == this.state.maquinaServicioFisica.id
    ) {
      this.cargarMaquinaServicioFisica(this.state.maquinaServicioFisica.id);
    }
    if (
      this.state.maquinaServicioFisica &&
      dato.message.maquinaServicioSimbolicaId == this.state.maquinaServicioFisica.maquina_simbolica.id
    ) {
      this.cargarMaquinaServicioFisica(this.state.maquinaServicioFisica.id);
    }
  };

  cargarMaquinaServicioFisica = async (id: number) => {
    const resMaquinaServicioFisica = await this.maquinaServicioFisicaService.get(id).catch((error) => {
      this.maquinaServicioFisicaService.manejarErrorHTTP(error, "Máquina Servicio Física");
      return null;
    });
    if (resMaquinaServicioFisica != null) {
      this.setState({
        maquinaServicioFisica: resMaquinaServicioFisica,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  render() {
    if (this.state.maquinaServicioFisica.id != 0) {
      return (
        <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <Box paddingHorizontal={"10rem"} paddingVertical={"2rem"}>
            <Flex container flexDirection="column">
              <Flex container flexDirection="row" alignItems="center" justifyContent="center">
                <div>
                  <h5>Número Serie: {this.state.maquinaServicioFisica.numero_serie}</h5>
                  <h5>Marca: {this.state.maquinaServicioFisica.marca}</h5>
                  <h5>
                    Fecha de Fabricación:
                    {this.state.maquinaServicioFisica.fecha_fabricacion.toString().split("T")[0]}
                  </h5>
                  <h5>
                    Maquina Simbólica:
                    {this.state.maquinaServicioFisica.maquina_simbolica.nombre}
                  </h5>
                  <h5>Proveedor: {this.state.maquinaServicioFisica.proveedor.nombre}</h5>
                </div>
              </Flex>
            </Flex>

            <Flex container flexDirection="row" alignItems="center" justifyContent="center">
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>
            <Flex container flexDirection="row" alignItems="center" justifyContent="center">
              <button className={"btn btn-outline-secondary"} onClick={this.ocultar} style={{ width: "150px" }}>
                Salir
              </button>
            </Flex>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
