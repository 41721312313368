import React, { Component } from "react";
import { Button, Flex, NumberInput } from "@mantine/core";
import { Factura, EstadoCobroFactura } from "serviciossaintmichel";
import { PagoFactura } from "serviciossaintmichel/lib/apisaintmichel/ventas/pagos/pagos/models/Pago";
import FacturasShow from "../../../../../administracion/seccion-facturacion/facturacion/FacturasShow";
import _ from "lodash";
import { Plus, TrashFill } from "react-bootstrap-icons";
import { excluirIds } from "../../../../../compartido/utils/filtros";

export interface Props {
  clienteId: number;
  montoPago: number;
  callback: (pagosFactura: Array<Pick<PagoFactura, "factura" | "monto">>) => void;
}

interface State {
  pagosFactura: Array<Pick<PagoFactura, "factura" | "monto">>;
  facturaSeleccionada: Factura | null;
  montoSeleccionado: number | undefined;
}

export default class DistribuidorPagoFacturas extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pagosFactura: [],
      facturaSeleccionada: null,
      montoSeleccionado: undefined,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (!_.isEqual(prevState.pagosFactura, this.state.pagosFactura)) {
      this.props.callback(this.state.pagosFactura);
    }
  }

  agregarAsignacion = () => {
    const { facturaSeleccionada, montoSeleccionado } = this.state;
    if (montoSeleccionado === undefined || facturaSeleccionada === null) {
      return;
    }

    const pagosResultantes = [
      ...this.state.pagosFactura,
      { factura: facturaSeleccionada.id, monto: montoSeleccionado },
    ];

    this.setState({ pagosFactura: pagosResultantes, facturaSeleccionada: null, montoSeleccionado: undefined });
  };

  quitarAsignacion = (facturaId: number) => {
    const resultado = this.state.pagosFactura.filter((pagoFactura) => pagoFactura.factura !== facturaId);
    this.setState({ pagosFactura: resultado });
  };

  asignacionValida = (montoAsignado: number) => {
    const { facturaSeleccionada, montoSeleccionado } = this.state;
    if (montoSeleccionado === undefined || facturaSeleccionada === null) {
      return false;
    }

    if (montoSeleccionado <= 0) {
      return false;
    }

    if (montoSeleccionado > facturaSeleccionada.monto_total_con_descuento - facturaSeleccionada.monto_pagado) {
      return false;
    }

    if (montoSeleccionado + montoAsignado > this.props.montoPago) {
      return false;
    }

    return true;
  };

  filtroConDeuda = (factura: Factura): boolean => {
    const deuda = factura.monto_total_con_descuento - factura.monto_pagado;
    return deuda > 0;
  };

  render = () => {
    const { clienteId } = this.props;
    const { facturaSeleccionada, montoSeleccionado, pagosFactura } = this.state;
    const montoAsignado = pagosFactura.reduce((total, pago) => total + pago.monto, 0);

    return (
      <Flex gap={"1rem"} direction={"column"}>
        <div>Monto sin asignar: {this.props.montoPago - montoAsignado}</div>
        <Flex align="center" gap={"1rem"} direction={"column"}>
          <FacturasShow
            version="select"
            propsSelect={{
              callbackParent: (factura: Factura | null) => {
                this.setState({ facturaSeleccionada: factura ? factura : null });
              },
              seleccionado: facturaSeleccionada ? facturaSeleccionada.id : null,
              clearable: true,
              label: "Factura",
              placeholder: "Seleccione una factura",
              labelGetter: (obj: Factura) =>
                `Factura ${obj.id} (${obj.estado_cobro}) - adeuda $${obj.monto_total_con_descuento - obj.monto_pagado}`,
            }}
            filtros={{
              clienteId: clienteId,
              limite: 0,
              estadosCobro: [EstadoCobroFactura.IMPAGA, EstadoCobroFactura.CON_PAGOS_PARCIALES],
              criterioOrden: { campo: "id", modo: "ASC" },
            }}
            filtrosLocales={[excluirIds(pagosFactura.map((pagoFactura) => pagoFactura.factura)), this.filtroConDeuda]}
          />
          <Flex direction={"row"} align={"flex-end"} gap={"xs"}>
            <NumberInput
              size="md"
              label="Monto a pagar"
              value={montoSeleccionado}
              onChange={(monto: number) => this.setState({ montoSeleccionado: monto })}
              min={1}
              hideControls
            />
            <Button
              color="green"
              onClick={() => this.agregarAsignacion()}
              disabled={!this.asignacionValida(montoAsignado)}
            >
              <Plus />
            </Button>
          </Flex>
        </Flex>
        <section>
          {pagosFactura.map((pagoFac) => (
            <Flex justify={"space-between"} align={"center"}>
              <span>
                {" "}
                Factura {pagoFac.factura} - ${pagoFac.monto}
              </span>
              <Button
                color="red"
                onClick={() => {
                  this.quitarAsignacion(pagoFac.factura);
                }}
              >
                <TrashFill color="white" />
              </Button>
            </Flex>
          ))}
        </section>
      </Flex>
    );
  };
}
