import React, { Component } from "react";
import { TipoMaterialGet } from "serviciossaintmichel";
import SelectGenerico from "../../../../compartido/components/select/SelectGenerico";

interface Props {
  callbackParent: Function;
  seleccionado: TipoMaterialGet;
  tiposMateriales: Array<TipoMaterialGet>;
}
interface State {}

export default class SelectTipoMaterial extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado,
    };
  }

  getPosicion = (material: TipoMaterialGet): number => {
    return this.props.tiposMateriales.findIndex((e) => e.id === material.id);
  };
  getSeleccionado = (indice: number) => {
    this.props.callbackParent(this.props.tiposMateriales[indice]);
  };

  render() {
    return (
      <SelectGenerico
        titulo="Tipo Materiales"
        seleccionado={this.getPosicion(this.props.seleccionado)}
        elementos={this.props.tiposMateriales.map((t) => t.tipo)}
        callback={this.getSeleccionado}
      />
    );
  }
}
