import React, { Component } from "react";
import ElementoComputacionActualizar from "./ElementoComputacionActualizar";
import ElementoComputacionLista from "./ElementoComputacionLista";
import ElementoComputacionNuevo from "./ElementoComputacionNuevo";
import ElementoComputacionVerDetalle from "./ElementoComputacionVerDetalle";
import Flex from "../../../../compartido/components/flex/Flex";
import { Tabs, Tab } from "react-bootstrap";
import { Global } from "../../../../Global";

interface Props {}

interface State {
  tabVisible: any;
  disabledActualizar: boolean;
  disabledVer: boolean;
  responsive: boolean;
  elementoComputacionId: number | null;
}

export default class ElementosComputacion extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista",
      disabledActualizar: true,
      disabledVer: true,
      responsive: window.innerWidth < 700 ? true : false,
      elementoComputacionId: null,
    };
    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  actualizar = (elementoComputacionId: number) => {
    this.setState({
      tabVisible: "actualizar",
      disabledActualizar: false,
      elementoComputacionId: elementoComputacionId,
    });
  };

  ver = (elementoComputacionId: number) => {
    this.setState({
      tabVisible: "ver",
      disabledVer: false,
      elementoComputacionId: elementoComputacionId,
    });
  };

  ocultarActualizar = () => {
    this.setState({
      tabVisible: "lista",
      disabledActualizar: true,
      elementoComputacionId: null,
    });
  };

  ocultarNuevo = () => {
    this.setState({
      tabVisible: "lista",
    });
  };

  ocultarVer = () => {
    this.setState({
      tabVisible: "lista",
      disabledVer: true,
      elementoComputacionId: null,
    });
  };

  renderizarNuevo = () => {
    if (
      Global.UsuarioService.getPermisos().Elementos.ElementosComputacion.ElementoComputacion.canAdd()
    ) {
      return (
        <Tab eventKey="nuevo" title="Nuevo">
          {this.state.tabVisible === "nuevo" ? (
            <ElementoComputacionNuevo ocultar={this.ocultarNuevo} />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarLista = () => {
    if (
      Global.UsuarioService.getPermisos().Elementos.ElementosComputacion.ElementoComputacion.canView()
    ) {
      return (
        <Tab eventKey="lista" title="Lista">
          <ElementoComputacionLista
            ver={this.ver}
            actualizar={this.actualizar}
          />
        </Tab>
      );
    }
  };

  renderizarActualizar = () => {
    if (
      Global.UsuarioService.getPermisos().Elementos.ElementosComputacion.ElementoComputacion.canChange()
    ) {
      return (
        <Tab
          eventKey="actualizar"
          title="Actualizar"
          disabled={this.state.disabledActualizar}
        >
          {this.state.elementoComputacionId &&
          this.state.tabVisible === "actualizar" ? (
            <ElementoComputacionActualizar
              elementoComputacionId={this.state.elementoComputacionId}
              ocultar={this.ocultarActualizar}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarVerDetalle = () => {
    if (
      Global.UsuarioService.getPermisos().Elementos.ElementosComputacion.ElementoComputacion.canView()
    ) {
      return (
        <Tab
          eventKey="ver"
          title="Ver Detalle"
          disabled={this.state.disabledVer}
        >
          {this.state.elementoComputacionId &&
          this.state.tabVisible === "ver" ? (
            <ElementoComputacionVerDetalle
              elementoComputacionId={this.state.elementoComputacionId}
              ocultar={this.ocultarVer}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey="lista"
      transition={false}
      activeKey={this.state.tabVisible}
      onSelect={this.onSelect}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "center" : "",
      }}
      unmountOnExit={true}
    >
      {this.renderizarNuevo()}
      {this.renderizarLista()}
      {this.renderizarActualizar()}
      {this.renderizarVerDetalle()}
    </Tabs>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {this.renderizar(this.state.responsive)}
      </Flex>
    );
  }
}
