import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";
import { BultoInsumoGet, BultoInsumoPost, BultoInsumoService, CANALES_WEB_SOCKET, InsumoGet, InsumoService, TipoInsumoGet, UnidadInsumoGet, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";

export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  bultoInsumos: Array<BultoInsumoGet>;
}

export default class BultoInsumoLista extends Component<Props, State> {

  private bultoInsumoService:BultoInsumoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      bultoInsumos: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_BULTO_INSUMOS,
      funcionCallback: this.getElementos,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_INSUMOS,
      funcionCallback: this.getElementos,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_INSUMOS,
      funcionCallback: this.getElementos,
    });

    this.bultoInsumoService =  new BultoInsumoService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.bultoInsumoService.list()
      .then((result) => {
        this.setState({ bultoInsumos: result });
      })
      .catch((error) => {
        this.bultoInsumoService.manejarErrorHTTP(error, "Bulto Insumo");
      });
  };

  componentDidMount() {
    this.bultoInsumoService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }

  armarFilas(bultoInsumos: Array<BultoInsumoGet>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (bultoInsumos.length > 0) {
      for (let i = 0; i < bultoInsumos.length; i++) {
        let fila: Array<string> = [];
        fila.push(bultoInsumos[i].nombre);
        fila.push(
          bultoInsumos[i].insumo.nombre +
            " - " +
            bultoInsumos[i].insumo.tipo_insumo.tipo
        );
        fila.push(bultoInsumos[i].cantidad.toString());
        let filaResult: FilaTablaGenerica = {
          id: bultoInsumos[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Elementos.Insumos.BultoInsumo.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (Global.UsuarioService.getPermisos().Elementos.Insumos.BultoInsumo.canView()) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.bultoInsumoService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.bultoInsumos)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.bultoInsumoService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaBultoInsumos"
      />
    );
  }
}
