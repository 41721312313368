import React, { Component } from "react";
import { TipoResponsabilidad } from "serviciossaintmichel";
import SelectGenerico from "../../../../compartido/components/select/SelectGenerico";

interface Props {
  callbackParent: Function;
  seleccionado: TipoResponsabilidad;
  responsabilidades: Array<TipoResponsabilidad>;
  disabled?:boolean;
}
interface State 
{
  disabled:boolean;
  seleccionado:TipoResponsabilidad;
}

export default class SelectTipoResponsabilidad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado,
      disabled:this.props.disabled||false
    };
  }

  getPosicion = (insumo: TipoResponsabilidad): number => {
    return this.props.responsabilidades.findIndex((i) => i.id === insumo.id);
  };

  getSeleccionado = (indice: number) => {
    this.props.callbackParent(this.props.responsabilidades[indice]);
  };

  render() {
    return (
        <SelectGenerico
                seleccionado={this.getPosicion(this.props.seleccionado)}
                elementos={this.props.responsabilidades.map(
                  (i) => i.tipo 
                )}
                callback={this.getSeleccionado}
                disabled={this.props.disabled}
        />
    );
  }
}
