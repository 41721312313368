import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import SelectProveedor from "../../../../produccion/proveedores/components/SelectProveedor";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import { CANALES_WEB_SOCKET, MaquinaHerramientaGet, MaquinaHerramientaPost,  MaquinaHerramientaService, Proveedor, ProveedorService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  maquinaHerramientaId: number;
  ocultar: Function;
}

interface State {
  maquinaHerramienta: MaquinaHerramientaGet;
  proveedores: Array<Proveedor>;
  alerta: any;
}

export default class MaquinaHerramientaActualizar extends Component<
  Props,
  State
> {

  private maquinaHerramientaService:MaquinaHerramientaService;
  private proveedorService:ProveedorService;

  constructor(props: Props) {
    super(props);
    this.state = {
      maquinaHerramienta: this.crearMaquinaHerramientaVacio(),
      proveedores: [],
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    /* WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.PROVEEDORES,
      funcionCallback: this.cargarProveedores,
    }); */

    this.maquinaHerramientaService = new MaquinaHerramientaService(Global.UsuarioService.getToken()!);
    this.proveedorService = new ProveedorService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.cargarMaquinaHerramienta(this.props.maquinaHerramientaId);
    this.cargarProveedores();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarMaquinaHerramienta(this.props.maquinaHerramientaId);
    }
  }

  crearMaquinaHerramientaVacio(): MaquinaHerramientaGet {
    const proveedor: Proveedor = {
      id: 0,
      nombre: "",
    };
    const maquinaHerramienta: MaquinaHerramientaGet = {
      id: 0,
      nombre: "",
      marca: "",
      descripcion: "",
      proveedor: proveedor,
    };
    return maquinaHerramienta;
  }

  cargarMaquinaHerramienta = async (id: number) => {
    const resMaquinaHerramienta = await this.maquinaHerramientaService.get(id).catch(
      (error) => {
        this.maquinaHerramientaService.manejarErrorHTTP(
          error,
          "Máquina Herramienta"
        );
        return null;
      }
    );
    if (resMaquinaHerramienta != null) {
      this.setState({
        maquinaHerramienta: resMaquinaHerramienta,
        alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
      });
    }
  };

  cargarProveedores = async () => {
    const resProveedores = await this.proveedorService.listAll().catch((error) => {
      this.proveedorService.manejarErrorHTTP(error, "Proveedor");
      return null;
    });
    if (resProveedores != null) {
      this.setState({
        proveedores: resProveedores,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  setearNombre = (nombre: string) => {
    let maquinaHerramienta = this.state.maquinaHerramienta;
    maquinaHerramienta.nombre = nombre;
    this.setState({ maquinaHerramienta: maquinaHerramienta });
  };

  setearProveedor = (proveedor: Proveedor) => {
    let maquinaHerramienta = this.state.maquinaHerramienta;
    maquinaHerramienta.proveedor = proveedor;
    this.setState({ maquinaHerramienta: maquinaHerramienta });
  };

  setearMarca = (marca: string) => {
    let maquinaHerramienta = this.state.maquinaHerramienta;
    maquinaHerramienta.marca = marca;
    this.setState({ maquinaHerramienta: maquinaHerramienta });
  };

  setearDescripcion = (descripcion: string) => {
    let maquinaHerramienta = this.state.maquinaHerramienta;
    maquinaHerramienta.descripcion = descripcion;
    this.setState({ maquinaHerramienta: maquinaHerramienta });
  };

  handleUpdate() {
    const maquinaHerramientaActualizar: MaquinaHerramientaPost = {
      nombre: this.state.maquinaHerramienta.nombre,
      marca: this.state.maquinaHerramienta.marca,
      descripcion: this.state.maquinaHerramienta.descripcion,
      proveedor: this.state.maquinaHerramienta.proveedor.id,
    };
    this.maquinaHerramientaService.update(
      maquinaHerramientaActualizar,
      this.state.maquinaHerramienta.id
    )
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_HERRAMIENTA,
          { maquinaHerramientaId: this.state.maquinaHerramienta.id }
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Maquina Herramienta Actualizado!",
            titulo: "Atención",
            estado: "block",
          },
        });
      })
      .catch((error) => {
        this.maquinaHerramientaService.manejarErrorHTTP(
          error,
          "Máquina Herramienta"
        );
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.maquinaHerramienta.nombre &&
      this.state.maquinaHerramienta.proveedor &&
      this.state.maquinaHerramienta.marca &&
      this.state.maquinaHerramienta.descripcion
      ? false
      : true;
  };

  render() {
    if (
      this.state.maquinaHerramienta.id != 0 &&
      this.state.proveedores.length > 0
    ) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            <div className={"row"}>
              <div className={"column"}>
                <form
                  className={"form-horizontal"}
                  onSubmit={this.handleSubmit}
                >
                  <div className={"form-group"}>
                    <label htmlFor="nombre">Nombre</label>
                    <InputCaracteres
                      id="nombre"
                      name="nombre"
                      onChange={this.setearNombre}
                      value={this.state.maquinaHerramienta.nombre}
                      placeholder="Ingrese Nombre de la Maquina Herramienta"
                    />
                  </div>

                  <div className={"form-group"}>
                    <SelectProveedor
                      proveedores={this.state.proveedores}
                      seleccionado={this.state.maquinaHerramienta.proveedor}
                      callbackParent={this.setearProveedor}
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="marca">Marca</label>
                    <InputCaracteres
                      id="marca"
                      name="marca"
                      onChange={this.setearMarca}
                      value={this.state.maquinaHerramienta.marca}
                      placeholder="Ingrese Marca de la Maquina Herramienta"
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="descripcion">Descripcion</label>
                    <InputCaracteres
                      id="descripcion"
                      name="descripcion"
                      onChange={this.setearDescripcion}
                      value={this.state.maquinaHerramienta.descripcion}
                      placeholder="Ingrese Descripcion de la Maquina Herramienta"
                    />
                  </div>
                  <div
                    className={"form-group"}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button
                      ref="boton"
                      type="submit"
                      className={"btn btn-info"}
                      disabled={this.disableButton()}
                    >
                      {"Actualizar"}
                    </button>
                    <button
                      className={"btn btn-outline-secondary"}
                      onClick={this.ocultar}
                    >
                      Ocultar
                    </button>
                  </div>
                  <div className={"form-group"}>
                    <Alerta
                      tipo={this.state.alerta.tipo}
                      titulo={this.state.alerta.titulo}
                      mensaje={this.state.alerta.mensaje}
                      estado={this.state.alerta.estado}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
