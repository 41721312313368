import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";

import { CANALES_WEB_SOCKET, HerramientaGet,HerramientaPost,HerramientaService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";

export interface Props {
  ocultar: Function;
}

interface State {
  herramienta: HerramientaPost;
  alerta: any;
}

export default class HerramientaNuevo extends Component<Props, State> {

  private herramientaService:HerramientaService;


  constructor(props: Props) {
    super(props);
    this.state = {
      herramienta: this.herramientaCrear(),
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };

    this.herramientaService =  new HerramientaService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    const herramientaCrear: HerramientaPost = this.state.herramienta;
    this.setState({
      herramienta: herramientaCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  herramientaCrear(): HerramientaPost {
    return {
      nombre: "",
      marca: "",
      descripcion: "",
    };
  }

  setearNombre = (nombre: string) => {
    let herramienta = this.state.herramienta;
    herramienta.nombre = nombre;
    this.setState({ herramienta: herramienta });
  };
  setearMarca = (marca: string) => {
    let herramienta = this.state.herramienta;
    herramienta.marca = marca;
    this.setState({ herramienta: herramienta });
  };
  setearDescripcion = (descripcion: string) => {
    let herramienta = this.state.herramienta;
    herramienta.descripcion = descripcion;
    this.setState({ herramienta: herramienta });
  };

  handleCreate() {
    this.herramientaService.create(this.state.herramienta)
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_HERRAMIENTAS
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Herramienta Creado!",
            titulo: "Atención",
            estado: "block",
          },
        });
        const herramientaCrear: HerramientaPost = this.herramientaCrear();
        this.setState({
          herramienta: herramientaCrear,
        });
      })
      .catch((error) => {
        this.herramientaService.manejarErrorHTTP(error, "Herramienta");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.herramienta.nombre &&
      this.state.herramienta.marca &&
      this.state.herramienta.descripcion
      ? false
      : true;
  };

  renderizarFormulario() {
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="nombre">Nombre</label>
                <InputCaracteres
                  id="nombre"
                  name="nombre"
                  onChange={this.setearNombre}
                  value={this.state.herramienta.nombre}
                  placeholder="Ingrese Nombre de Herramienta"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="marca">Marca</label>
                <InputCaracteres
                  id="marca"
                  name="marca"
                  onChange={this.setearMarca}
                  value={this.state.herramienta.marca}
                  placeholder="Ingrese Marca"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="descripcion">Descripcion</label>
                <InputCaracteres
                  id="descripcion"
                  name="descripcion"
                  onChange={this.setearDescripcion}
                  value={this.state.herramienta.descripcion}
                  placeholder="Ingrese Descripcion"
                />
              </div>
              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  ref="boton"
                  type="submit"
                  className={"btn btn-info"}
                  disabled={this.disableButton()}
                >
                  {"Crear"}
                </button>
                <button
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
              <div className={"form-group"}>
                <Alerta
                  tipo={this.state.alerta.tipo}
                  titulo={this.state.alerta.titulo}
                  mensaje={this.state.alerta.mensaje}
                  estado={this.state.alerta.estado}
                />
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
