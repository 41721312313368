export enum ScreenType {
  PC,
  PHONE,
  TABLET,
}

class Screen {
  getType = (width: number, heigth: number): ScreenType => {
    if (width <= 764) return ScreenType.PHONE;
    else if (764 < width && width <= 1200 && heigth < 800) return ScreenType.TABLET;
    else return ScreenType.PC;
  };
}

export default new Screen();
