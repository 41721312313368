import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";


import { CANALES_WEB_SOCKET,  InsumoService, ProductoGet, ProductoPost, ProductoService, TipoProductoGet, TipoProductoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  productos: Array<ProductoGet>;
}
export default class ProductoLista extends Component<Props, State> {

  private productoService:ProductoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      productos: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_PRODUCTOS,
      funcionCallback: this.getElementos,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS,
      funcionCallback: this.getElementos,
    });

    this.productoService =  new ProductoService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.productoService.list()
      .then((result) => {
        this.setState({ productos: result });
      })
      .catch((error) => {
        this.productoService.manejarErrorHTTP(error, "Producto");
      });
  };

  componentDidMount() {
    this.productoService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }

  armarFilas(productos: Array<ProductoGet>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (productos.length > 0) {
      for (let i = 0; i < productos.length; i++) {
        let fila: Array<string> = [];
        fila.push(productos[i].nombre);
        fila.push(productos[i].tipo_producto.tipo);
        fila.push(productos[i].volumen.toString());
        let filaResult: FilaTablaGenerica = {
          id: productos[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  // Evento que recibia de la tabla generica, bajo que orden debia ordenar sobre el total de datos
  /* eventoOrdenar = (criterio: string, orden: string) => {
    Object.entries(NOMBRES_COLUMNAS).forEach(([key, value]) => {
      if (criterio == value) {
        this.props.eventoOrdenar(key, orden);
      }
    });
  }; */

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (Global.UsuarioService.getPermisos().Elementos.Productos.Producto.canChange()) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (Global.UsuarioService.getPermisos().Elementos.Productos.Producto.canView()) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.productoService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.productos)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.productoService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaProductos"
      />
    );
  }
}
