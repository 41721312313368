import React, { Component } from "react";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import { LIMITES } from "../../../../../compartido/components/select/SelectLimiteTabla";
import ProductosShowSelect from "./ProductosShowSelect";
import { PropsSelectGeneral } from "../../../../../compartido/components/select/SelectBusqueda";
import { PropsSelectGenerico } from "../../../../../compartido/components/select/PropsSelectGenerico";
import ProductosShowTable from "./ProductosShowTable";
import { CANALES_WEB_SOCKET, DatosProducto, ProductoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";
import MultiSelectComponent, {
  PropsMultiSelect,
} from "../../../../../compartido/components/select/MultiSelectComponent";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
  version: Version;
  productosExcluidos?: Array<number>;
  productosUnicos?: Array<number>;
  propsSelect?: PropsSelectGenerico<DatosProducto> & PropsSelectGeneral;
  propsMultiSelect?: PropsMultiSelect<DatosProducto> & PropsSelectGeneral;
}

export enum Version {
  SELECT,
  TABLE,
  MULTI_SELECT,
}

interface State {
  productos: Array<DatosProducto>;
}

export default class ProductosShow extends Component<Props, State> {
  private productoService: ProductoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      productos: [],
    };

    this.productoService = new ProductoService(Global.UsuarioService.getToken()!);
  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS,
      funcionCallback: this.getProductos,
    });
    if (this.props.version === Version.TABLE) this.productoService.paramsURL.setearLimite(LIMITES[0]);
    this.getProductos();
  }

  getProductos = () => {
    switch (this.props.version) {
      case Version.MULTI_SELECT:
      case Version.SELECT: {
        this.productoService
          .listAll()
          .then((result) => {
            this.setState({ productos: result });
          })
          .catch((error) => {
            this.productoService.manejarErrorHTTP(error, "Producto");
          });
        break;
      }
      case Version.TABLE: {
        this.productoService
          .list()
          .then((result) => {
            if (this.props.productosUnicos) {
              const idsFiltrar = this.props.productosUnicos;
              result = result.filter((r) => idsFiltrar.indexOf(r.id) !== -1);
            }

            if (this.props.productosExcluidos) {
              const idsFiltrar = this.props.productosExcluidos;
              result = result.filter((r) => !(idsFiltrar.indexOf(r.id) === -1));
            }
            this.setState({ productos: result });
          })
          .catch((error) => {
            this.productoService.manejarErrorHTTP(error, "Producto");
          });
        break;
      }
    }
  };

  filtrarProductos = (productos: Array<DatosProducto>) => {
    if (this.props.productosUnicos) {
      const idsFiltrar = this.props.productosUnicos;
      productos = productos.filter((r) => idsFiltrar.indexOf(r.id) !== -1);
    }

    if (this.props.productosExcluidos) {
      const idsFiltrar = this.props.productosExcluidos;
      productos = productos.filter((r) => idsFiltrar.indexOf(r.id) === -1);
    }

    return productos;
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  render() {
    const { propsMultiSelect, version } = this.props;
    const { productos } = this.state;

    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.props.version === Version.SELECT && this.props.propsSelect ? (
          <ProductosShowSelect
            {...this.props.propsSelect}
            callbackParent={this.props.propsSelect.callbackParent}
            seleccionado={this.props.propsSelect.seleccionado ? this.props.propsSelect.seleccionado : null}
            items={this.filtrarProductos(this.state.productos)}
          />
        ) : null}
        {this.props.version === Version.TABLE ? (
          <ProductosShowTable productos={this.state.productos} productosFuncion={this.getProductos} />
        ) : null}
        {version === Version.MULTI_SELECT && propsMultiSelect ? (
          <MultiSelectComponent<DatosProducto>
            items={productos}
            seleccionados={propsMultiSelect.seleccionados}
            labelGetter={(prod: DatosProducto) => prod.nombre}
            valueGetter={(prod: DatosProducto) => prod.id.toString()}
            onChangeHandler={propsMultiSelect.callbackParent}
            clearable={propsMultiSelect.clearable}
          />
        ) : null}
      </Flex>
    );
  }
}
