import React, { Component } from "react";
import {  Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import H from "../../../../../../libreria/message/title/H";
import RequestButton from "../../../../../../compartido/components/request-button/RequestButton";
import * as Icon from "react-bootstrap-icons";
import Input, { InputTypes } from "../../../../../../libreria/data/input/Input";
import Habilitador from "../../../../../../libreria/action/habilitador/Habilitador";
import Swal from "sweetalert2";

import { CANALES_WEB_SOCKET,  ConsumoGet,  ConsumoService,               ProductoConsumoGet,               WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";

export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  consumo: ConsumoGet;
}

interface State {
  productosOld: Array<ProductoConsumoGet>;
  productosNew: Array<ProductoConsumoGet>;
  cargando: boolean;
  screen: ScreenType;
}

interface Message {
  type: string;
  idConsumo: number;
  idProducto: number;
}

export default class ProductosConsumoUpdate extends Component<Props, State> {

  private consumoService:ConsumoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
      productosOld: [],
      productosNew: [],
    };
    this.consumoService =  new ConsumoService(Global.UsuarioService.getToken()!);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CONSUMOS_PRODUCTOS,
      funcionCallback: this.onChannelMesagge,
    });

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  componentDidMount = async () => {
    const productos = await this.consumoService.getProductos(this.props.consumo.id);
    this.setState({
      productosOld: productos,
      productosNew: this.getCopy(productos),
      cargando: false,
    });
  };

  getCopy = (
    productos: Array<ProductoConsumoGet>
  ): Array<ProductoConsumoGet> => {
    let productosCopy: Array<ProductoConsumoGet> = [];
    productos.map((x) =>
      productosCopy.push({
        producto: x.producto,
        cantidad_minima: x.cantidad_minima,
      })
    );
    return productosCopy;
  };

  onChannelMesagge = async (e: MessageEvent) => {
    let message: Message = JSON.parse(e.data).message;

    if (
      message.idConsumo == this.props.consumo.id &&
      (message.type == "new" || message.type == "update")
    ) {
      const productos = await this.consumoService.getProductos(
        this.props.consumo.id
      );
      this.setState({
        productosOld: productos,
        productosNew: this.getCopy(productos),
        cargando: false,
      });
    }
    if (
      message.idConsumo == this.props.consumo.id &&
      message.type == "delete"
    ) {
      let id: number = message.idProducto;
      const productos = this.state.productosNew.filter(
        (x) => x.producto.id != id
      );
      this.setState({
        productosOld: productos,
        productosNew: this.getCopy(productos),
        cargando: false,
      });
    }
  };

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      //this.setState({x:X})
    }
  };

  eliminarProducto = async (id: number) => {
    try {
      await Swal.fire({
        icon: "question",
        title: "¿Está seguro en eliminar un producto ?",
        showDenyButton: true,
        confirmButtonText: "Confirmar",
        denyButtonText: "Cancelar",
      }).then(async (result) => {
        if (!result.isDenied) {
          debugger;

          let response = await this.consumoService.eliminarProducto(
            this.props.consumo.id,
            id
          );

          WebSocketService.enviarMensaje(
            CANALES_WEB_SOCKET.ADMINISTRACION_CONSUMOS_PRODUCTOS,
            {
              type: "delete",
              idConsumo: this.props.consumo.id,
              idProducto: id,
            }
          );

          const productos = this.state.productosNew.filter(
            (x) => x.producto.id != id
          );
          this.setState({
            productosOld: productos,
            productosNew: this.getCopy(productos),
            cargando: false,
          });

          await Swal.fire({
            icon: "success",
            text: "Producto Eliminado",
          });
        }
      });
    } catch (error) {
      this.consumoService.manejarErrorHTTP(error, "Eliminación de Producto Consumo");
    }
  };

  actualizarProducto = async (id: number) => {
    try {
      await this.consumoService.actualizarProducto(
        this.props.consumo.id,
        id,
        this.state.productosNew.filter((x) => x.producto.id == id)[0]
          .cantidad_minima
      );

      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CONSUMOS_PRODUCTOS,
        {
          type: "update",
          idConsumo: this.props.consumo.id,
          idProducto: id,
        }
      );

      await Swal.fire({
        icon: "success",
        text: "Producto Actualizado",
      });
    } catch (error) {
      this.consumoService.manejarErrorHTTP(error, "Actualización Producto Consumo");
    }
  };

  onChange = (id: number, cantidad: number) => {
    let productosNew = this.state.productosNew;
    try {
      debugger;
      productosNew.filter(
        (x: ProductoConsumoGet) => x.producto.id == id
      )[0].cantidad_minima = cantidad;
      this.setState({ productosNew: productosNew });
    } catch (e) {}
  };

  renderizarFilas = () => {
    return this.state.productosNew.map((producto, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{producto.producto.nombre}</td>
          <td className="text-center">
            <Habilitador>
              <Input
                type={InputTypes.Number}
                data={producto.cantidad_minima}
                placeholder="Cantidad"
                onChange={(cantidad: number) =>
                  this.onChange(producto.producto.id, cantidad)
                }
              />
            </Habilitador>
          </td>
          <td className="text-center">
            <RequestButton
              texto="Actualizar"
              propsBoton={{ variant: "light" }}
              icon={<Icon.ArrowRepeat />}
              onClick={() => this.actualizarProducto(producto.producto.id)}
            />
          </td>
          <td className="text-center">
            <RequestButton
              texto="Eliminar"
              propsBoton={{ variant: "light" }}
              icon={<Icon.ArrowRepeat />}
              onClick={() => this.eliminarProducto(producto.producto.id)}
            />
          </td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Producto</th>
            <th className="text-center">Cantidad Mínima</th>
            <th className="text-center">Actualizar</th>
            <th className="text-center">Eliminar</th>
          </tr>
        </thead>

        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={4} texto={"Productos Actuales"} />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="15px"
        >
          {this.renderizarTabla()}
        </Flex>
      </Flex>
    );
  }
}
