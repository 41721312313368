import React from "react";
import { SelectItem, MultiSelectProps, MultiSelect } from "@mantine/core";
import SelectItemUtils from "../../utils/SelectItemUtils";

export interface PropsMultiSelect<T> {
  seleccionados: Array<T>;
  callbackParent: (item: Array<T>) => void;
}

interface Props<T> extends Omit<MultiSelectProps, "data"> {
  items: Array<T>;
  seleccionados: Array<T>;
  labelGetter: (obj: T) => string; // funcion que indica como obtener el label
  valueGetter: (obj: T) => string; // funcion que indica como obtener el valor interno
  onChangeHandler: (items: Array<T>) => void; // Funcion que se ejecuta con el valor de elemento T o null
}

export default function MultiSelectComponent<T>(props: Props<T>) {
  const getValores = (items: Array<T> | null) => {
    return items?.map((item) => props.valueGetter(item));
  };

  const onChangeHandler = (valores: Array<string>): void => {
    const valoresSet = new Set(valores);
    const seleccionados = props.items.filter((item) => valoresSet.has(props.valueGetter(item)));
    props.onChangeHandler(seleccionados);
  };

  const crearSelectItems = (datos: Array<T>): Array<SelectItem> => {
    const items = datos.map((item) => ({
      value: props.valueGetter(item),
      label: props.labelGetter(item),
    }));

    return SelectItemUtils.ordenarLabelAlfabeticamente(items);
  };

  const data = crearSelectItems(props.items);
  const seleccionados = getValores(props.seleccionados);

  return <MultiSelect {...props} data={data} value={seleccionados} onChange={onChangeHandler} />;
}
