import React, { Component } from "react";
import CheckBoxItem from "./CheckBoxItem";
import { Item } from "./Item";
import Flex from "../flex/Flex";
import Contenedor from "../contenedor/Contenedor";

interface Props<T> {
  items?: Array<Item<T>>;
  height?: string;
  width?: string;
  borde?: string;
  onChange?(item: Item<T>): void;
  isOneSelected?(value: boolean): void;
  onChangeSeleccionados?(items: Array<Item<T>>): void;
}

interface State<T> {
  items: Array<Item<T>>;
  itemsSeleccionados: Array<Item<T>>;
}

class CheckBoxList<T> extends Component<Props<T>, State<T>> {
  constructor(props: Props<T>) {
    super(props);
    this.state = {
      items: this.props.items ? this.props.items : [],
      itemsSeleccionados: [],
    };
  }

  componentDidMount() {}

  iguales = (items1: Array<Item<T>>, items2: Array<Item<T>>): boolean => {
    if (items1.length == items2.length) {
      let aux = true;
      items1.map((x, index) => {
        if (items2.filter((y) => y.id == x.id).length == 0) aux = false;
      });
      return aux;
    } else return false;
  };

  componentDidUpdate(prevProps: Props<T>) {
    if (prevProps.items == undefined && this.props.items != undefined)
      this.setState({ items: this.props.items });
    else if (prevProps.items != undefined && this.props.items == undefined)
      this.setState({ items: [] });
    else if (prevProps.items != undefined && this.props.items != undefined) {
      if (this.iguales(prevProps.items, this.props.items) == false)
        this.setState({ items: this.props.items });
    } else {
    }
  }

  cargar = (lista: Array<Item<T>>) => {
    this.setState({ items: lista });
    if (this.props.onChangeSeleccionados != undefined) {
      this.props.onChangeSeleccionados(this.getSeleccionados(lista));
    }

    if (this.props.isOneSelected != undefined) {
      this.props.isOneSelected(this.isOneSelected(lista));
    }
  };

  agregar = (items: Array<Item<T>>) => {
    let lista: Array<Item<T>> = this.state.items;
    for (var i = 0; i < items.length; i++) {
      items[i].estado = false;
      lista.push(items[i]);
    }
    this.setState({ items: lista });
    if (this.props.onChangeSeleccionados != undefined) {
      this.props.onChangeSeleccionados(this.getSeleccionados(lista));
    }
    if (this.props.isOneSelected != undefined) {
      this.props.isOneSelected(this.isOneSelected(lista));
    }
  };

  itemChange = (item: Item<T>) => {
    let lista: Array<Item<T>> = this.state.items;
    lista.forEach((x: Item<T>) => {
      if (x.equalsTo(item)) x.copyTo(item);
    });
    this.setState({ items: lista });
    if (this.props.onChangeSeleccionados != undefined) {
      this.props.onChangeSeleccionados(this.getSeleccionados(lista));
    }
  };

  onChangeItem = (item: Item<T>) => {
    this.itemChange(item);
    if (this.props.onChange != undefined) {
      this.props.onChange(item);
    }
    if (this.props.isOneSelected != undefined) {
      this.props.isOneSelected(this.isOneSelected());
    }
  };

  getSeleccionados = (items?: Array<Item<T>>): Array<Item<T>> => {
    let seleccionados: Array<Item<T>> = [];
    let lista: Array<Item<T>> = items ? items : this.state.items;
    lista.forEach((x: Item<T>) => {
      if (x.estado) {
        seleccionados.push(x.getCopy());
      }
    });
    return seleccionados;
  };

  getItems = (): Array<Item<T>> => {
    let items: Array<Item<T>> = [];
    let lista: Array<Item<T>> = this.state.items;
    lista.forEach((x: Item<T>) => {
      items.push(x.getCopy());
    });
    return items;
  };

  delete = (item: Item<T>) => {
    let lista: Array<Item<T>> = this.state.items;
    lista = lista.filter((x) => x.id != item.id);
    this.setState({ items: lista });
  };

  deleteSeleccionados = () => {
    let lista_aux: Array<Item<T>> = [];
    let seleccionados: Array<Item<T>> = this.getSeleccionados();
    let lista: Array<Item<T>> = this.state.items;

    for (var i = 0; i < lista.length; i++) {
      let aux: boolean = true;
      for (var j = 0; j < seleccionados.length; j++) {
        if (lista[i].id == seleccionados[j].id) {
          aux = false;
          break;
        }
      }

      if (aux) {
        lista_aux.push(lista[i]);
      }
    }
    lista = lista_aux;
    lista = [];

    this.setState({ items: [] });
    this.setState({ items: lista_aux });

    if (this.props.onChangeSeleccionados != undefined) {
      this.props.onChangeSeleccionados([]);
    }

    if (this.props.isOneSelected != undefined) {
      this.props.isOneSelected(this.isOneSelected());
    }
  };

  isOneSelected = (items?: Array<Item<T>>): boolean => {
    let lista: Array<Item<T>> = items ? items : this.state.items;

    for (var i = 0; i < lista.length; i++) {
      if (lista[i].estado) {
        return true;
      }
    }
    return false;
  };

  render() {
    return (
      <Flex
        container
        flexDirection="row"
        width={this.props.width || "500px"}
        height={this.props.height || "200px"}
      >
        <Contenedor
          borde={this.props.borde || "0px solid grey"}
          radioBorde="5px"
          overflowY="auto"
        >
          {this.state.items.map((item: Item<T>) => (
            <CheckBoxItem<T> item={item} onChange={this.onChangeItem} />
          ))}
        </Contenedor>
      </Flex>
    );
  }
}

const Estilos = {
  contenedor: {
    display: "flex",
    flexDirection: "column",
    justifycontent: "flexstart",
    alignitems: "center",
  },
};

export default CheckBoxList;
