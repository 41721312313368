import React, { Component } from "react";
import Alerta from "../../../compartido/components/alerta/Alerta";
import InputCaracteres from "../../../compartido/components/input/InputCaracteres";
import { OverlayView } from "@react-google-maps/api";

interface Props {
  titulo: string;
  callbackSalir(): void;
  callbackRetornar(): void;
  callbackGuardar(nombre: string): void;
}

interface State {
  nombre: string;
  display: string;
  alerta: { tipo: string; mensaje: string; titulo: string; display: string };
  displayRetornar: string;
  displayGuardar: string;
  posicion: any;
}

class CuadroCrearFigura extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      nombre: "",
      display: "none",
      alerta: { tipo: "", mensaje: "", titulo: "", display: "none" },
      displayRetornar: "none",
      displayGuardar: "block",
      posicion: null,
    };
  }

  componentDidMount() {}

  guardar = () => {
    this.props.callbackGuardar(this.state.nombre);
  };

  retornar = () => {
    this.setState({
      nombre: "",
      display: "none",
      alerta: { tipo: "", mensaje: "", titulo: "", display: "none" },
      displayRetornar: "none",
      displayGuardar: "block",
      posicion: null,
    });
    this.props.callbackRetornar();
  };

  mostrar = (posicion: any) => {
    this.setState({
      display: "block",
      posicion: posicion,
    });
  };

  descartar = () => {
    this.setState({
      nombre: "",
      display: "none",
      alerta: { tipo: "", mensaje: "", titulo: "", display: "none" },
      displayRetornar: "none",
      displayGuardar: "block",
      posicion: null,
    });
    this.props.callbackSalir();
  };

  alertar = (estado: boolean, mensaje: string) => {
    if (estado) {
      this.setState({
        alerta: {
          tipo: "alert-success",
          mensaje: mensaje,
          titulo: "Atencion",
          display: "block",
        },
        displayRetornar: "block",
        displayGuardar: "none",
      });
    } else {
      this.setState({
        alerta: {
          tipo: "alert-danger",
          mensaje: mensaje,
          titulo: "Atencion",
          display: "block",
        },
        displayRetornar: "block",
        displayGuardar: "none",
      });
    }
  };

  setearNombre = (valor: string) => {
    let nombre = this.state.nombre;
    nombre = valor;
    this.setState({ nombre: nombre });
  };

  render() {
    return (
      <div style={{ display: this.state.display }}>
        {this.state.display == "block" ? (
          <OverlayView
            position={this.state.posicion}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div style={estilo.div}>
              <h3>{this.props.titulo}</h3>

              <div style={{ display: this.state.displayGuardar }}>
                <div className="form-group">
                  <label htmlFor="nombre">Nombre</label>

                  <InputCaracteres
                    id="nombre"
                    name="nombre"
                    onChange={this.setearNombre}
                    value={this.state.nombre}
                    placeholder="Ingrese Nombre"
                  />
                </div>
                <br />
                <button
                  onClick={this.guardar}
                  className="btn btn-outline-secondary"
                >
                  Guardar
                </button>
                <br />
                <br />
                <button
                  onClick={this.descartar}
                  className="btn btn-outline-secondary"
                >
                  Descartar
                </button>
                <br />
                <br />
              </div>
              <Alerta
                tipo={this.state.alerta.tipo}
                titulo={this.state.alerta.titulo}
                mensaje={this.state.alerta.mensaje}
                estado={this.state.alerta.display}
              />
              <div style={{ display: this.state.displayRetornar }}>
                <button
                  onClick={this.retornar}
                  className="btn btn-outline-secondary"
                >
                  Retornar
                </button>
              </div>
            </div>
          </OverlayView>
        ) : null}
      </div>
    );
  }
}
export default CuadroCrearFigura;

const estilo = {
  div: {
    background: "white",
    border: "1px solid #ccc",
    padding: 15,
  },
};
