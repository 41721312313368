import React, { Component } from "react";
import { ProductoGet } from "serviciossaintmichel";
import SelectGenerico from "../../../../compartido/components/select/SelectGenerico";


interface Props {
  callbackParent: Function;
  seleccionado: ProductoGet;
  productos: Array<ProductoGet>;
}

interface State {}

export default class SelectProducto extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado,
    };
  }

  getPosicion = (producto: ProductoGet): number => {
    return this.props.productos.findIndex((i) => i.id === producto.id);
  };

  getSeleccionado = (indice: number) => {
    this.props.callbackParent(this.props.productos[indice]);
  };

  render() {
    return (
      <SelectGenerico
        seleccionado={this.getPosicion(this.props.seleccionado)}
        elementos={this.props.productos.map((i) => i.nombre + " - " + i.tipo_producto.tipo)}
        callback={this.getSeleccionado}
      />
    );
  }
}
