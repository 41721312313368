import React, { Component } from "react";
import { PropsSelectGeneral } from "../../../compartido/components/select/SelectBusqueda";
import { PropsSelectGenerico } from "../../../compartido/components/select/PropsSelectGenerico";
import { LIMITES } from "../../../compartido/components/select/SelectLimiteTabla";
import Flex from "../../../libreria/appearance/flex/Flex";
import ListasPreciosShowSelect from "./ListasPreciosShowSelect";
import ListasPreciosShowTable from "./ListasPreciosShowTable";

import {
  CANALES_WEB_SOCKET,
  WebSocketService,
  ListaPrecio,
  ListaPrecioService,
} from "serviciossaintmichel";
import { Global } from "../../../Global";

export interface Props {
  listPrecioId?: number;
  onUpdate?: Function;
  onView?: Function;
  version: Version;
  propsSelect?: PropsSelectGenerico<ListaPrecio> & PropsSelectGeneral;
}

export enum Version {
  SELECT,
  TABLE,
}

interface State {
  listasPrecios: Array<ListaPrecio>;
}

export default class ListasPreciosShow extends Component<Props, State> {
  private listaPrecioService: ListaPrecioService;

  constructor(props: Props) {
    super(props);
    this.state = {
      listasPrecios: [],
    };

    this.listaPrecioService = new ListaPrecioService(
      Global.UsuarioService.getToken()!
    );
  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_LISTAS_PRECIOS,
      funcionCallback: this.getListasPrecios,
    });
    if (this.props.version == Version.TABLE)
      this.listaPrecioService.paramsURL.setearLimite(LIMITES[0]);
    this.getListasPrecios();
  }

  getListasPrecios = () => {
    if (this.props.listPrecioId) {
      this.listaPrecioService
        .getListasPrecios({
          nombre: null,
          descripcion: null,
        })
        .then((result) => {
          this.setState({ listasPrecios: result });
        })
        .catch((error) => {
          this.listaPrecioService.manejarErrorHTTP(error, "Lista Precio");
        });
    } else {
      this.listaPrecioService
        .listAll()
        .then((result) => {
          this.setState({ listasPrecios: result });
        })
        .catch((error) => {
          this.listaPrecioService.manejarErrorHTTP(error, "Lista Precio");
        });
    }
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems={Version.TABLE ? undefined : "center"}
        justifyContent="center"
      >
        {this.props.version == Version.SELECT && this.props.propsSelect ? (
          <ListasPreciosShowSelect
            {...this.props.propsSelect}
            callbackParent={this.props.propsSelect.callbackParent}
            seleccionado={
              this.props.propsSelect.seleccionado
                ? this.props.propsSelect.seleccionado
                : null
            }
            items={this.state.listasPrecios}
          />
        ) : null}
        {this.props.version == Version.TABLE ? (
          <ListasPreciosShowTable
            onView={this.onView}
            onUpdate={this.onUpdate}
          />
        ) : null}
      </Flex>
    );
  }
}
