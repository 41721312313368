import React, { Component } from "react";
import Flex from "../../../../compartido/components/flex/Flex";
import Box from "../../../../compartido/components/box/Box";

import { CANALES_WEB_SOCKET, InsumoGet, InsumoPost, InsumoService, TipoInsumoGet, TipoInsumoService, UnidadInsumoGet, UnidadInsumoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  insumoId: number;
  ocultar: Function;
}

interface State {
  insumo: InsumoGet;
  anchoBox: string;
}

export default class InsumoVerDetalle extends Component<Props, State> {


  private insumoService:InsumoService;


  constructor(props: Props) {
    super(props);

    this.state = {
      insumo: this.crearInsumoVacio(),
      anchoBox: window.innerWidth < 750 ? "90%" : "50%",
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_INSUMOS,
      funcionCallback: this.cargarInsumoCanal,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_UNIDAD_INSUMOS,
      funcionCallback: this.cargarInsumoCanal,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_INSUMOS,
      funcionCallback: this.cargarInsumoCanal,
    });

    this.insumoService =  new InsumoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.cargarInsumo(this.props.insumoId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarInsumo(this.props.insumoId);
    }
  }

  crearInsumoVacio(): InsumoGet {
    const tipo_insumo: TipoInsumoGet = {
      id: 0,
      tipo: "",
    };
    const unidad_insumo: UnidadInsumoGet = {
      id: 0,
      unidad: "",
    };
    const insumo: InsumoGet = {
      id: 0,
      nombre: "",
      tipo_insumo: tipo_insumo,
      unidad_insumo: unidad_insumo,
    };
    return insumo;
  }

  cargarInsumoCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (this.state.insumo && dato.message.insumoId == this.state.insumo.id) {
      this.cargarInsumo(this.state.insumo.id);
    }
    if (
      this.state.insumo &&
      dato.message.tipoInsumoId == this.state.insumo.tipo_insumo.id
    ) {
      this.cargarInsumo(this.state.insumo.id);
    }
    if (
      this.state.insumo &&
      dato.message.unidadInsumoId == this.state.insumo.unidad_insumo.id
    ) {
      this.cargarInsumo(this.state.insumo.id);
    }
  };

  cargarInsumo = async (id: number) => {
    const resInsumo = await this.insumoService.get(id).catch((error) => {
      this.insumoService.manejarErrorHTTP(error, "Insumo");
      return null;
    });
    if (resInsumo != null) {
      this.setState({
        insumo: resInsumo,
      });
    }
  };

  actualizarDimensiones = () => {
    if (window.innerWidth < 750) {
      this.setState({ anchoBox: "90%" });
    } else {
      this.setState({ anchoBox: "50%" });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  render() {
    if (this.state.insumo.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal={"10rem"} paddingVertical={"2rem"}>
            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h5>Nombre: {this.state.insumo.nombre}</h5>
                  <h5>Tipo: {this.state.insumo.tipo_insumo.tipo}</h5>
                  <h5>Unidad: {this.state.insumo.unidad_insumo.unidad}</h5>
                </div>
              </Flex>
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <button
                className={"btn btn-outline-secondary"}
                onClick={this.ocultar}
                style={{ width: "150px" }}
              >
                Salir
              </button>
            </Flex>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
