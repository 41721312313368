import React, { Component } from "react";
import InputDecimal from "../../../../compartido/components/input/InputDecimal";
import Habilitador from "../../../../compartido/components/habilitador/Habilitador";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";
import SelectInsumo from "../../../insumos/Insumo/components/SelectInsumo";
import { Table, Col, Row, Form, Container } from "react-bootstrap";
import { BultoProductoGet, BultoProductoService, CANALES_WEB_SOCKET,  InsumoGet, InsumoService, RelacionBultoProductoInsumo, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";







export interface Props {
  bultoProductoId: number;
}

interface State {
  bultoProducto: BultoProductoGet;
  insumosCandidatos: Array<InsumoGet>;
  alerta: any;
  cantidad: number;
  insumo: InsumoGet | null;
}

export default class ProductoActualizarInsumos extends Component<Props, State> {

  private bultoProductoService:BultoProductoService;
  private insumoService:InsumoService;



  constructor(props: Props) {
    super(props);
    this.state = {
      bultoProducto: this.crearBultoProductoVacio(),
      insumosCandidatos: [],
      cantidad: 0,
      insumo: null,
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_INSUMOS,
      funcionCallback: this.cargarInsumos,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_INSUMOS,
      funcionCallback: this.cargarBultoProductoCanal,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_BULTO_PRODUCTOS,
      funcionCallback: this.cargarBultoProductoCanal,
    });

    this.bultoProductoService =  new BultoProductoService(Global.UsuarioService.getToken()!);
    this.insumoService =  new InsumoService(Global.UsuarioService.getToken()!);



  }
  async componentDidMount() {
    await this.cargarBultoProducto(this.props.bultoProductoId);
    this.cargarInsumos();
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      await this.cargarBultoProducto(this.props.bultoProductoId);
      this.cargarInsumos();
    }
  }

  crearBultoProductoVacio(): BultoProductoGet {
    const bultoProducto: BultoProductoGet = {
      id: 0,
      nombre: "",
      insumos: [],
      productos: [],
    };
    return bultoProducto;
  }

  cargarBultoProducto = async (id: number) => {
    const resBultoProducto = await this.bultoProductoService.get(id).catch(
      (error) => {
        this.bultoProductoService.manejarErrorHTTP(error, "Bulto Producto");
        return null;
      }
    );
    if (resBultoProducto != null) {
      this.setState({
        bultoProducto: resBultoProducto,
        cantidad: 0,
        alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
      });
    }
  };

  cargarBultoProductoCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.bultoProducto &&
      dato.message.bultoProductoId == this.state.bultoProducto.id
    ) {
      await this.cargarBultoProducto(this.props.bultoProductoId);
      this.cargarInsumos();
    }
    if (
      this.state.bultoProducto &&
      this.state.bultoProducto.insumos.find(
        (i) => i.insumo.id === dato.message.insumoId
      )
    ) {
      await this.cargarBultoProducto(this.props.bultoProductoId);
      this.cargarInsumos();
    }
  };

  cargarInsumos = async () => {
    let insumos = await this.insumoService.listAll().catch((error) => {
      this.insumoService.manejarErrorHTTP(error, "Insumo");
      return null;
    });
    if (insumos != null) {
      this.actualizarInsumosCandidatos(insumos);
    }
  };

  actualizarInsumosCandidatos = (insumos: Array<InsumoGet>) => {
    const relacionados = this.state.bultoProducto.insumos.map(
      (relacion) => relacion.insumo.id
    );
    let insumosCandidatos = insumos.filter(
      (insumo) => !relacionados.includes(insumo.id)
    );
    this.setState({
      insumosCandidatos: insumosCandidatos,
      insumo: insumosCandidatos[0],
    });
  };

  setearCantidad = (cantidad: number) => {
    this.setState({ cantidad: cantidad });
  };

  setearInsumo = (insumo: InsumoGet) => {
    this.setState({ insumo: insumo });
  };

  eliminarInsumo = (insumo: RelacionBultoProductoInsumo) => {
    this.bultoProductoService.eliminarRelacionConInsumo(
      this.state.bultoProducto.id,
      insumo.insumo.id
    )
      .then(() => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_BULTO_PRODUCTOS,
          {
            bultoProductoId: this.state.bultoProducto.id,
          }
        );
      })
      .catch((error) => {
        this.bultoProductoService.manejarErrorHTTP(error, "Bulto Producto");
      });
  };

  agregarInsumo = (insumo: InsumoGet, cantidad: number) => {
    this.bultoProductoService.relacionarConInsumo(
      this.state.bultoProducto.id,
      insumo.id,
      cantidad
    )
      .then(() => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_BULTO_PRODUCTOS,
          {
            bultoProductoId: this.state.bultoProducto.id,
          }
        );
      })
      .catch((error) => {
        this.bultoProductoService.manejarErrorHTTP(error, "Bulto Producto");
      });
  };

  actualizarInsumo = (relacionInsumo: RelacionBultoProductoInsumo) => {
    return this.bultoProductoService.actualizarRelacionConInsumo(
      this.state.bultoProducto.id,
      relacionInsumo.insumo.id,
      relacionInsumo.cantidad
    )
      .then(() => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_BULTO_PRODUCTOS,
          {
            bultoProductoId: this.state.bultoProducto.id,
          }
        );
      })
      .catch((error) => {
        this.bultoProductoService.manejarErrorHTTP(error, "Bulto Producto");
      });
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.insumo && this.state.cantidad && this.state.cantidad != 0
      ? false
      : true;
  };

  renderizarFormulario = () => {
    if (this.state.insumosCandidatos.length < 1) {
      return null;
    }
    return (
      <Form>
        <Form.Group>
          <Form.Label>Cantidad</Form.Label>
          <InputDecimal
            id="cantidad"
            name="cantidad"
            onChange={this.setearCantidad}
            value={this.state.cantidad}
            cantEnteros={3}
            cantDecimales={3}
            placeholder={"Ingrese cantidad"}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Insumo</Form.Label>
          <SelectInsumo
            insumos={this.state.insumosCandidatos}
            seleccionado={
              this.state.insumo
                ? this.state.insumo
                : this.state.insumosCandidatos[0]
            }
            callbackParent={(insumo: InsumoGet) => {
              this.setearInsumo(insumo);
            }}
          />
        </Form.Group>
        <Form.Group>
          <RequestButton
            texto="Añadir"
            propsBoton={{
              variant: "info",
              disabled: !(!!this.state.insumo && this.state.cantidad != 0),
            }}
            onClick={() => {
              if (this.state.insumo && this.state.cantidad)
                return this.agregarInsumo(
                  this.state.insumo,
                  this.state.cantidad
                );
            }}
          />
        </Form.Group>
      </Form>
    );
  };

  renderizarFilas = () => {
    return this.state.bultoProducto.insumos.map((relacion, index) => {
      return (
        <tr>
          <td>{index}</td>
          <td>{relacion.insumo.nombre}</td>
          <td>{relacion.insumo.tipo_insumo.tipo}</td>
          <td>
            <Habilitador>
              <InputDecimal
                id={"cantidad" + index}
                name="cantidad"
                onChange={(valor: number) => {
                  let p = this.state.bultoProducto;
                  let l = p.insumos;
                  let i = l[index];
                  i.cantidad = valor;
                  this.setState({ bultoProducto: p });
                }}
                value={relacion.cantidad}
                cantEnteros={3}
                cantDecimales={3}
                placeholder={"Ingrese cantidad"}
              />
            </Habilitador>
          </td>
          <td>{relacion.insumo.unidad_insumo.unidad}</td>
          <td>
            <RequestButton
              texto="Actualizar"
              propsBoton={{
                variant: "info",
                disabled:
                  relacion.cantidad && relacion.cantidad != 0 ? false : true,
              }}
              onClick={() => this.actualizarInsumo(relacion)}
            />
          </td>
          <td>
            <RequestButton
              texto="Eliminar"
              confirmacion={true}
              propsBoton={{
                variant: "danger",
              }}
              onClick={() => this.eliminarInsumo(relacion)}
            />
          </td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true}>
        <thead>
          <tr>
            <th>#</th>
            <th>Insumo</th>
            <th>Tipo de Insumo</th>
            <th>Cantidad</th>
            <th>Unidades</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  render() {
    if (this.state.bultoProducto.id != 0) {
      return (
        <Container>
          <Row>
            <Col md={{ span: 6 }}>{this.renderizarFormulario()}</Col>
          </Row>
          <Row>
            <Col>{this.renderizarTabla()}</Col>
          </Row>
        </Container>
      );
    } else return null;
  }
}
