import React, { Component } from "react";
import Flex from "../../../../../compartido/components/flex/Flex";
import { Form } from "react-bootstrap";
import InputBase from "../../../../../compartido/components/input/InputBase";
import InputCaracteres from "../../../../../compartido/components/input/InputCaracteres";
import { Fuentes } from "../../../../../compartido/estilos/estilos";
import H from "../../../../../compartido/components/titulos/H";
import SelectCondicionIVA from "./SelectCondicionIVA";
import ComponenteDatoGeografico from "../../../../../compartido/components/direccion/ComponenteDatoGeografico";
import { CondicionIVA, CondicionIVAService, EnteEconomico,  GrupoTipoCliente, Localidad } from "serviciossaintmichel";
import { Global } from "../../../../../Global";




export interface Props {
  datosAfip: EnteEconomico;
  onChangeDatosAfip: Function;
  onChangeValidation: Function;
  grupoCliente: GrupoTipoCliente;
  grupoClienteConDireccionComercial: boolean;
}

interface State {
  datosAfip: EnteEconomico;
  pasoValidado: boolean;
  grupoCliente: GrupoTipoCliente;
  condicionesIVA: Array<CondicionIVA>;
  cargando: boolean;
}

export default class PasoDatosAfip extends Component<Props, State> {

  private condicionIVAService:CondicionIVAService;


  constructor(props: Props) {
    super(props);

    this.state = {
      datosAfip: this.props.datosAfip,
      pasoValidado: this.isPasoValidado(this.props.datosAfip),
      grupoCliente: this.props.grupoCliente,
      condicionesIVA: [],
      cargando: true,
    };

    this.condicionIVAService = new CondicionIVAService(Global.UsuarioService.getToken()!);


    this.props.onChangeValidation(this.state.pasoValidado);
  }

  async componentDidMount() {
    const condicionesIVA = await this.condicionIVAService.listAll().catch(
      (error) => {
        this.condicionIVAService.manejarErrorHTTP(error, "Condición Iva");
        return null;
      }
    );
    if (condicionesIVA != null) {
      this.setState({ condicionesIVA: condicionesIVA, cargando: false });
    }
  }

  isPasoValidado = (datosAfip: EnteEconomico): boolean => {
    return datosAfip.razon_social != "" &&
      datosAfip.cuit != "" &&
      datosAfip.condicionIVA.id > 0 &&
      (!this.props.grupoClienteConDireccionComercial ||
        (datosAfip.calle != "" &&
          datosAfip.numero != "" &&
          datosAfip.localidad > 0))
      ? true
      : false;
  };

  updateValidacion = () => {
    let x: boolean = this.isPasoValidado(this.state.datosAfip);
    this.setState({ pasoValidado: x });
    this.props.onChangeValidation(x);
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.setState({
        //Cliente: this.props.Cliente,
      });
    }
  }

  setearRazonSocial = (razonSocial: string) => {
    let x = this.state.datosAfip;
    x.razon_social = razonSocial;
    this.setState({ datosAfip: x });
    this.props.onChangeDatosAfip(x);
    this.updateValidacion();
  };

  setearCuit = (cuit: string) => {
    let x = this.state.datosAfip;
    x.cuit = cuit;
    this.setState({ datosAfip: x });
    this.props.onChangeDatosAfip(x);
    this.updateValidacion();
  };

  setearCondicionIVA = (condicionIVA: CondicionIVA) => {
    let x = this.state.datosAfip;
    x.condicionIVA = condicionIVA;
    this.setState({ datosAfip: x });
    this.props.onChangeDatosAfip(x);
    this.updateValidacion();
  };

  setearCalle = (calle: string) => {
    let x = this.state.datosAfip;
    x.calle = calle;
    this.setState({ datosAfip: x });
    this.props.onChangeDatosAfip(x);
    this.updateValidacion();
  };

  setearNumero = (numero: string) => {
    let x = this.state.datosAfip;
    x.numero = numero;
    this.setState({ datosAfip: x });
    this.props.onChangeDatosAfip(x);
    this.updateValidacion();
  };

  setearLocalidad = (localidad: Localidad) => {
    let x = this.state.datosAfip;
    x.localidad = localidad.id;
    this.setState({ datosAfip: x });
    this.props.onChangeDatosAfip(x);
    this.updateValidacion();
  };

  render() {
    const { condicionesIVA, cargando } = this.state;

    if (cargando) {
      return null;
    }

    return (
      <Flex container flexDirection="column" width="100%" padding="30px">
        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <H size={3} fuente={Fuentes.Titulos} texto="Datos Afip" />
        </Flex>

        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Form.Group style={{ width: "100%" }}>
            <Form.Label
              style={{ fontFamily: Fuentes.Labels }}
              htmlFor="razonsocial"
            >
              Razón Social
            </Form.Label>
            <InputCaracteres
              id="razonsocial"
              name="razonsocial"
              onChange={this.setearRazonSocial}
              value={this.state.datosAfip.razon_social}
              placeholder="Razón Social"
            />
          </Form.Group>
        </Flex>

        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Form.Group style={{ width: "100%" }}>
            <Form.Label style={{ fontFamily: Fuentes.Labels }} htmlFor="cuit">
              CUIT
            </Form.Label>
            <InputBase
              id="cuit"
              name="cuit"
              onChange={this.setearCuit}
              value={this.state.datosAfip.cuit}
              placeholder="Cuit"
              maxlength="13"
            />
          </Form.Group>
        </Flex>

        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Form.Group style={{ width: "100%" }}>
            <Form.Label
              style={{ fontFamily: Fuentes.Labels }}
              htmlFor="condicioniva"
            >
              Condición IVA
            </Form.Label>
            <SelectCondicionIVA
              condicionesIVA={condicionesIVA}
              seleccionado={this.state.datosAfip.condicionIVA}
              callbackParent={(condicionIVA: CondicionIVA) => {
                this.setearCondicionIVA(condicionIVA);
              }}
            />
          </Form.Group>
        </Flex>

        {this.props.grupoClienteConDireccionComercial
          ? this.renderDireccionComercial()
          : null}
      </Flex>
    );
  }

  renderDireccionComercial() {
    return (
      <Flex
        container
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        marginBottom="20px"
        marginTop="15px"
      >
        <Flex
          container
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          width="100%"
          marginBottom="15px"
        >
          <H
            size={4}
            alineacion="left"
            fuente={Fuentes.Titulos}
            texto="Dirección Comercial"
          />
        </Flex>

        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Form.Group style={{ width: "100%" }}>
            <Form.Label htmlFor="calle">Calle</Form.Label>
            <InputBase
              id="calle"
              name="calle"
              onChange={this.setearCalle}
              value={this.state.datosAfip.calle}
              placeholder="Calle"
            />
          </Form.Group>
        </Flex>

        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Form.Group style={{ width: "100%" }}>
            <Form.Label htmlFor="numero">Numero</Form.Label>
            <InputBase
              id="numero"
              name="numero"
              onChange={this.setearNumero}
              value={this.state.datosAfip.numero}
              placeholder="Número"
            />
          </Form.Group>
        </Flex>

        <ComponenteDatoGeografico
          localidadId={this.state.datosAfip.localidad}
          onChange={this.setearLocalidad}
        />
      </Flex>
    );
  }
}
