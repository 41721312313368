import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../../compartido/components/input/InputCaracteres";
import Box from "../../../../../compartido/components/box/Box";
import Flex from "../../../../../compartido/components/flex/Flex";
import RequestButton from "../../../../../compartido/components/request-button/RequestButton";
import { Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




import { CANALES_WEB_SOCKET, EmpleadoGet, EmpleadoPost, EmpleadoService, RelacionEmpleadoArea, RelacionEmpleadoSubArea, TipoResponsabilidad, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";







export interface Props {
  empleado: EmpleadoGet;
}

interface State {
  empleado: EmpleadoGet;
}

export default class EmpleadoActualizarDatosPersonales extends Component<Props, State> {

  private empleadoService:EmpleadoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      empleado: this.props.empleado,
    };

    this.empleadoService = new EmpleadoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {}

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.setState({
        empleado: this.props.empleado,
      });
    }
  }

  setearNombre = (nombre: string) => {
    let prod = this.state.empleado;
    prod.persona.nombre = nombre;
    this.setState({ empleado: prod });
  };

  setearApellido = (valor: string) => {
    let x = this.state.empleado;
    x.persona.apellido = valor;
    this.setState({ empleado: x });
  };

  setearDNI = (valor: string) => {
    let x = this.state.empleado;
    x.persona.dni = valor;
    this.setState({ empleado: x });
  };

  setearFechaNacimiento = (valor: Date) => {
    let x = this.state.empleado;

    x.persona.fecha_nacimiento = valor;

    this.setState({ empleado: x });
  };

  handleUpdate() {
    const empleadoActualizar: EmpleadoPost = {
      persona: {
        nombre: this.state.empleado.persona.nombre,
        apellido: this.state.empleado.persona.apellido,
        dni: this.state.empleado.persona.dni,
        fecha_nacimiento: this.state.empleado.persona.fecha_nacimiento,
      },
    };
    return this.empleadoService.update(empleadoActualizar, this.state.empleado.id).then((result) => {
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ADMINISTRACION_PERSONAL_EMPLEADOS, {
        empleadoId: this.state.empleado.id,
      });
    });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.empleado.persona.nombre ? false : true;
  };

  render() {
    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          <div className={"row"}>
            <div className={"column"}>
              <Card.Title style={{ textAlign: "center" }}>Datos Personales</Card.Title>

              <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
                <div className={"form-group"}>
                  <label htmlFor="nombre">Nombre</label>
                  <InputCaracteres
                    id="nombre"
                    name="nombre"
                    onChange={this.setearNombre}
                    value={this.state.empleado.persona.nombre}
                    placeholder="Ingrese Nombre de Empleado"
                  />
                </div>
                <div className={"form-group"}>
                  <label htmlFor="nombre">Apellido</label>
                  <InputCaracteres
                    id="apellido"
                    name="apellido"
                    onChange={this.setearApellido}
                    value={this.state.empleado.persona.apellido}
                    placeholder="Ingrese Apellido del Empleado"
                  />
                </div>

                <div className={"form-group"}>
                  <label htmlFor="nombre">DNI</label>
                  <InputCaracteres
                    id="dni"
                    name="dni"
                    onChange={this.setearDNI}
                    value={this.state.empleado.persona.dni}
                    placeholder="Ingrese DNI del Empleado"
                  />
                </div>

                <div className={"form-group"}>
                  <label htmlFor="fecha_nacimiento">Fecha de Nacimiento</label>

                  <DatePicker
                    id="fecha_nacimiento"
                    selected={new Date(this.state.empleado.persona.fecha_nacimiento)}
                    onChange={this.setearFechaNacimiento}
                  />
                </div>

                <div className={"form-group"} style={{ display: "flex", justifyContent: "space-between" }}>
                  <RequestButton
                    texto="Actualizar"
                    msgOnSuccess="Empleado actualizado con exito"
                    msgOnFailure="Ocurrio un error al actualizar el empleado"
                    propsBoton={{
                      variant: "info",
                      disabled: this.disableButton(),
                    }}
                    onClick={() => this.handleUpdate()}
                  />
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Flex>
    );
  }
}
