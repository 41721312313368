import React, { Component } from "react";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import RequestButton from "../../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import H from "../../../../../../libreria/message/title/H";
import ProductoPromocionLoad from "./ProductoPromocionLoad";

import { CANALES_WEB_SOCKET,  ProductoPromocion,  PromocionService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";



export interface Props {
  onSaveSuccess?: Function;
  onExit?: Function;
  idPromocion: number;
}

interface State {
  producto: ProductoPromocion;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class ProductoPromocionNew extends Component<Props, State> {

  private promocionService:PromocionService;

  constructor(props: Props) {
    super(props);
    this.state = {
      producto: this.createEmptyProducto(),
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.promocionService =  new PromocionService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  createEmptyProducto = (): ProductoPromocion => {
    return {
      producto: {
        id: -1,
        volumen: 0,
        nombre: "",
        tipo_producto: { id: -1, tipo: "" },
      },
      cantidad_cobrable: 0,
      cantidad_bonificada: 0,
      activo: true,
    };
  };

  componentDidUpdate(props: Props, state: State) {
    if (this.state.producto.producto.id !== state.producto.producto.id) {
      this.setState({ producto: this.state.producto });
    }
  }
  onSave = async () => {
    try {
      await this.promocionService.relacionarConProducto(
        this.props.idPromocion,
        this.state.producto.producto.id,
        this.state.producto.cantidad_bonificada,
        this.state.producto.cantidad_cobrable
      );

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS, {
        type: "update",
        productoId: this.state.producto.producto.id,
      });

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.VENTAS_PROMOCIONES, {
        type: "update",
        promocionId: this.props.idPromocion,
      });
      this.setState({ producto: this.createEmptyProducto(), valid: false });
      await Swal.fire({
        icon: "success",
        text: "Producto Actualizado",
      });
    } catch (error) {
      this.promocionService.manejarErrorHTTP(error, "Nuevo Producto Promocion");
    }
  };

  onExit = () => {
    if (this.props.onExit != undefined) this.props.onExit();
  };

  onChangeProducto = (producto: ProductoPromocion) => {
    this.setState({ producto: producto });
  };

  onChangeValidation = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border="1px solid grey"
        borderRadius="5px"
        padding="10px"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="10px"
        >
          <H size={4} texto={"Agregar Producto"} />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <ProductoPromocionLoad
            producto={this.state.producto}
            onChange={this.onChangeProducto}
            onValidationChange={this.onChangeValidation}
          />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Agregar"}
            onClick={this.onSave}
          />
        </Flex>
      </Flex>
    );
  }
}
