import React, { Component } from "react";
import SelectGenericoNull from "../../../../compartido/components/select/SelectGenericoNull";



import { CANALES_WEB_SOCKET,  ConsumoGet,  ConsumoService,               ProductoConsumoGet,               WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



interface Props {
  callbackParent: Function;
  seleccionado: ConsumoGet | null;
  excludeIds?: Array<number>;
  disabled?: boolean;
}

interface State {
  consumos: Array<ConsumoGet>;
  seleccionado: ConsumoGet | null;
  cargando: boolean;
}

export default class SelectConsumo extends Component<Props, State> {

  private consumoService:ConsumoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado,
      consumos: [],
      cargando: true,
    };

    this.consumoService =  new ConsumoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    try {
      const { excludeIds } = this.props;
      let resultado = await this.consumoService.listAll();
      if (excludeIds) {
        resultado = resultado.filter(
          (consumo) => excludeIds.indexOf(consumo.id) === -1
        );
      }
      this.setState({ consumos: resultado, cargando: false });
    } catch (error) {
      this.consumoService.manejarErrorHTTP(error, "Consumo");
    }
  };

  getPosicion = (valor: ConsumoGet, consumos: Array<ConsumoGet>): number => {
    return consumos.findIndex((i) => i.id === valor.id);
  };

  getSeleccionado = (indice: number) => {
    const { consumos } = this.state;
    if (indice >= 0) this.props.callbackParent(consumos[indice]);
    else this.props.callbackParent(null);
  };

  render() {
    const { consumos, seleccionado, cargando } = this.state;

    if (cargando) {
      return null;
    }

    const posicion = seleccionado
      ? this.getPosicion(seleccionado, consumos)
      : null;

    return (
      <SelectGenericoNull
        seleccionado={posicion}
        elementos={consumos.map((i) => i.nombre)}
        callback={this.getSeleccionado}
        disabled={this.props.disabled}
      />
    );
  }
}
