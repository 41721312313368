import React, { Component } from "react";
import ListBoxItem from "./ListBoxItem";
import { Item, TipoItem } from "./Item";
import Flex from "../../appearance/flex/Flex";
import Contenedor from "../../containers/contenedor/Contenedor";
import Text from "../../message/text/Text";

interface Props {
  items: Array<Item>;
  height?: string;
  width?: string;
  onSelect?(id: number | null): void;
  onDelete?(id: number | null): void;
  onView?(item: number): void;
  tipo: TipoItem;
  selected?: number;
  waiting?: Array<number>;
}

interface State {
  items: Array<Item>;
  selected: number;
  waiting: Array<number>;
}

class ListBox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      items: this.props.items,
      selected: this.props.selected ? this.props.selected : -1,
      waiting: this.props.waiting ? this.props.waiting : [],
    };
  }

  componentDidMount() {}

  igualesConjunto = (items1: Array<Item>, items2: Array<Item>): boolean => {
    if (items1.length == items2.length) {
      let aux = true;
      items1.map((x) => {
        if (items2.filter((y) => y.id == x.id && y.text == x.text).length == 0) aux = false;
      });
      return aux;
    } else return false;
  };

  igualesPosicion = (items1: Array<Item>, items2: Array<Item>): boolean => {
    if (items1.length == items2.length) {
      let aux = true;
      for (let i = 0; aux && i < items1.length; i++) {
        if (items1[i].id != items2[i].id || items1[i].text != items2[i].text) aux = false;
      }
      return aux;
    } else return false;
  };

  igualesWaiting = (waitings1: Array<number>, waitings2: Array<number>): boolean => {
    if (waitings1.length == waitings2.length) {
      let aux = true;
      waitings1.map((x) => {
        if (waitings2.filter((y) => y == x).length == 0) aux = false;
      });
      return aux;
    } else return false;
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.selected != undefined && this.props.selected == undefined) {
      this.setState({ selected: -1 });
    } else if (prevProps.selected == undefined && this.props.selected != undefined) {
      this.setState({ selected: this.props.selected });
    } else if (
      prevProps.selected != undefined &&
      this.props.selected != undefined &&
      prevProps.selected != this.props.selected
    ) {
      this.setState({ selected: this.props.selected });
    } else {
    }

    if (prevProps.waiting != undefined && this.props.waiting == undefined) {
      this.setState({ waiting: [] });
    } else if (prevProps.waiting == undefined && this.props.waiting != undefined) {
      this.setState({ waiting: this.props.waiting });
    } else if (prevProps.waiting != undefined && this.props.waiting != undefined) {
      if (this.igualesWaiting(prevProps.waiting, this.props.waiting) == false) {
        this.setState({ waiting: this.props.waiting });
      }
    } else {
    }

    if (this.igualesPosicion(prevProps.items, this.props.items) === false) {
      this.setState({ items: this.props.items });
    }

    if (prevProps.items !== this.props.items && this.props.items !== this.state.items) {
      this.setState({ items: this.props.items });
    }
  }

  // Metodos propios del Componente

  onClick = (id: number) => {
    if (this.props.tipo.select) {
      if (this.state.selected == id) {
        this.setState({ selected: -1 });
        if (this.props.onSelect != undefined) this.props.onSelect(null);
      } else {
        this.setState({ selected: id });
        if (this.props.onSelect != undefined) this.props.onSelect(id);
      }
    }
  };

  onDelete = (id: number) => {
    if (this.props.onDelete != undefined) this.props.onDelete(id);
  };

  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  render() {
    return (
      <Flex container flexDirection="row" width={this.props.width || "500px"} height={this.props.height || "300px"}>
        <Contenedor overflowY="auto">
          {this.state.items.length > 0 ? (
            <Flex
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
              borderBottom="1px solid black"
            >
              {this.state.items.map((item: Item, index: number) => (
                <ListBoxItem
                  key={item.id}
                  item={item}
                  tipo={this.props.tipo}
                  onClick={this.onClick}
                  onDelete={this.onDelete}
                  selected={this.state.selected == item.id ? true : false}
                  waiting={this.state.waiting.filter((x) => x == item.id).length > 0 ? true : false}
                />
              ))}
            </Flex>
          ) : (
            <Flex
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
              border="1px solid grey"
              height="150px"
            >
              <Text>No hay items para mostrar</Text>
            </Flex>
          )}
        </Contenedor>
      </Flex>
    );
  }
}

const Estilos = {
  contenedor: {
    display: "flex",
    flexDirection: "column",
    justifycontent: "flexstart",
    alignitems: "center",
  },
};

export default ListBox;
