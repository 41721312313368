import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Template from "./template/Template";
import Login from "./template/Login/Login.jsx";
import PrivateRoute from "./PrivateRoute.jsx";
import { Global } from "./Global";
import  {UsuarioService}  from "serviciossaintmichel"
require("bootstrap");



export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
    };
  }

  async componentDidMount() {

    Global.UsuarioService = new UsuarioService();

    const token=window.localStorage.getItem("token");

    if(token!=null)
      {
      await Global.UsuarioService.validarToken(token);  
      }

    //await UsuarioService.inicializarToken();

    this.setState({ cargando: false });
  }

  render() {
    if (this.state.cargando) {
      return null;
    }
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute path="/" component={Template} />
        </Switch>
      </BrowserRouter>
    );
  }
}
