import React, { Component } from "react";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import RequestButton from "../../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../../libreria/containers/box/Box";
import H from "../../../../../../libreria/message/title/H";
import PromocionLoad from "../../load/PromocionLoad";
import { CANALES_WEB_SOCKET, DatosPromocion, Promocion, PromocionService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";



export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  datosPromocion: DatosPromocion;
}
interface State {
  datosPromocionNew: DatosPromocion;
  datosPromocionOld: DatosPromocion;
  screen: ScreenType;
  valid: boolean;
}

export default class DatosPromocionUpdate extends Component<Props, State> {

  private promocionService:PromocionService;

  constructor(props: Props) {
    super(props);
    this.state = {
      datosPromocionOld: this.props.datosPromocion,
      datosPromocionNew: this.getCopy(this.props.datosPromocion),
      screen: Screen.getType(window.innerWidth, window.innerHeight),
      valid: false,
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.promocionService =  new PromocionService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  getCopy = (datos: DatosPromocion): DatosPromocion => {
    return { id: datos.id, nombre: datos.nombre };
  };

  async componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PROMOCIONES,
      funcionCallback: this.onChannelMesagge,
    });
  }

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.promocionId == this.props.datosPromocion.id) {
      let promocion = await this.promocionService.get(
        this.props.datosPromocion.id
      ).catch((error) => {
        this.promocionService.manejarErrorHTTP(error, "Promocion");
        return null;
      });
      let datos: DatosPromocion = {
        id: promocion ? promocion.id : -1,
        nombre: promocion ? promocion.nombre : "",
      };
      this.setState({
        datosPromocionOld: datos,
        datosPromocionNew: this.getCopy(datos),
      });
    }
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({
        datosPromocionOld: this.props.datosPromocion,
        datosPromocionNew: this.getCopy(this.props.datosPromocion),
      });
    }
  };

  onUpdate = async () => {
    return this.promocionService.update(
      this.state.datosPromocionNew,
      this.state.datosPromocionNew.id
    )
      .then((promocion) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.VENTAS_PROMOCIONES, {
          type: "UPDATE",
          promocionId: promocion.id,
        });
        this.setState({
          datosPromocionOld: this.state.datosPromocionNew,
          datosPromocionNew: this.getCopy(this.props.datosPromocion),
        });
        Swal.fire({
          icon: "success",
          text: "Datos Promocion Modificados",
        });
      })
      .catch((error) => {
        this.promocionService.manejarErrorHTTP(error, "Promocion");
      });
  };

  isValid = (nombre: string): boolean => {
    return nombre.length > 0 ? true : false;
  };

  onChange = (datosPromocion: DatosPromocion) => {
    this.setState({ datosPromocionNew: datosPromocion });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex
      flexDirection="column"
      width="50%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={3} texto={"Datos"} />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="10px"
        >
          <PromocionLoad
            promocion={this.state.datosPromocionNew}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
          />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Actualizar"}
            onClick={this.onUpdate}
          />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
