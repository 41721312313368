import React, { Component } from "react";
import Ruta from "../../compartido/models/Ruta";
import Alquileres from "./alquileres/Alquileres";
import Seccion from "../../compartido/components/seccion/Seccion";
import Consumos from "./consumos/Consumos";
import { Global } from "../../Global";

export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];
  if (
    Global.UsuarioService.getPermisos().Administracion.Servicios.Alquiler.hasAccess()
  ) {
    aux.push({
      name: "Alquileres",
      path: SeccionServicios.pathBase + "/alquileres",
      component: Alquileres,
    });
  }
  if (
    Global.UsuarioService.getPermisos().Administracion.Servicios.Consumo.hasAccess()
  ) {
    aux.push({
      name: "Consumos",
      path: SeccionServicios.pathBase + "/consumos",
      component: Consumos,
    });
  }
  return aux;
}

export default class SeccionServicios extends Component<Props> {
  static pathBase = "/administracion/seccion-servicios";
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Seccion rutasNavbar={getRutasAccesibles()} tituloNavbar={"Servicios"} />
    );
  }
}
