import React, { Component } from "react";



import { CANALES_WEB_SOCKET, TipoCombustibleGet, TipoCombustibleService, TipoVehiculoGet, TipoVehiculoService, VehiculoGet, VehiculoPost, VehiculoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  vehiculoId: number;
}

interface State {
  vehiculo: VehiculoGet | null;
}

export default class VehiculoShow extends Component<Props, State> {

  private vehiculoService:VehiculoService;

  constructor(props: Props) {
    super(props);
    this.state = { vehiculo: null };
    this.vehiculoService = new VehiculoService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_VEHICULOS,
      funcionCallback: this.vehiculoWSHandler,
    });
    const vehiculo = await this.getVehiculo();
    this.setState({ vehiculo: vehiculo });
  }

  getVehiculo = async () => {
    return await this.vehiculoService.get(this.props.vehiculoId);
  };

  vehiculoWSHandler = async (e: MessageEvent) => {
    const vehiculoId = JSON.parse(e.data).message.vehiculoId;
    if (vehiculoId === this.state.vehiculo?.id) {
      this.getVehiculo();
    }
  };

  render() {
    const { vehiculo } = this.state;

    if (vehiculo === null) {
      return null;
    }

    return <div>{`${vehiculo.id} - ${vehiculo.marca} - ${vehiculo.patente} - ${vehiculo.tipo_vehiculo.tipo}`}</div>;
  }
}
