import React, { Component } from "react";
import { Descuento } from "serviciossaintmichel";

export interface Props {
  descuento: Descuento;
}

export default class InfoDescuento extends Component<Props, {}> {
  render(): React.ReactNode {
    const { descuento } = this.props;
    return (
      <>
        <span>
          <strong>Nombre:</strong> {descuento.nombre}
        </span>
        <span>
          <strong>Monto:</strong> {descuento.monto ?? "-"}
        </span>
        <span>
          <strong>Porcentaje:</strong> {descuento.porcentaje ? `${descuento.porcentaje} %` : "-"}
        </span>
        <span>
          <strong>Acumulable:</strong> {descuento.acumulable ? "Si" : "No"}
        </span>
      </>
    );
  }
}
