import React, { Component } from "react";
import Flex from "../../../../../compartido/components/flex/Flex";
import PersonaVerDatosPersonales from "../../../../../compartido/components/persona/PersonaVerDatosPersonales";
import ClienteVerDatosAFIP from "./ClienteVerDatosAFIP";
import ClienteVerTipoCliente from "./ClienteVerTipoCliente";
import Margin from "../../../../../libreria/appearance/margin/Margin";
import { Tab, Tabs } from "react-bootstrap";
import H from "../../../../../libreria/message/title/H";
import ClienteDatosGeneralesLectura from "../actualizar/datosGenerales/ClienteDatosGeneralesLectura";
import ClienteDireccionesShow from "../actualizar/direcciones/ClienteDireccionesShow";
import ClienteVerDirecciones from "./ClienteVerDirecciones";
import ClienteVerServicios from "./ClienteVerServicios";
import { CANALES_WEB_SOCKET, Cliente, ClienteService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";




export interface Props {
  id: number;
  ocultar: Function;
}
interface State {
  cliente: Cliente | null;
  cargando: boolean;
  responsive: boolean;
}
export default class ClienteVerDetalle extends Component<Props, State> {

  private clienteService:ClienteService;

  constructor(props: Props) {
    super(props);

    this.state = {
      cliente: null,
      cargando: true,
      responsive: window.innerWidth < 700 ? true : false,
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES,
      funcionCallback: this.refrescarCliente,
    });

    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    let cliente: Cliente | null = await this.clienteService.get(this.props.id).catch((error) => {
      this.clienteService.manejarErrorHTTP(error, "Cliente");
      return null;
    });
    if (cliente != null) {
      this.setState({ cliente: cliente, cargando: false });
    }
  };

  refrescarCliente = async () => {
    let cliente: Cliente | null = await this.clienteService.get(this.props.id).catch((error) => {
      this.clienteService.manejarErrorHTTP(error, "Cliente");
      return null;
    });
    if (cliente != null) {
      this.setState({ cliente: cliente, cargando: false });
    }
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      // ?
    }
  }

  ocultar = () => {
    this.props.ocultar();
  };

  render() {
    const { cliente, responsive } = this.state;

    if (cliente === null || cliente.id === 0) {
      return null;
    }

    /* <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
      <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        <Margin top="40px" bottom="30px">
          <H size={3} texto={`Cliente ${cliente.id}`} />
        </Margin>
      </Flex>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        {this.renderizar(responsive, cliente)}
      </Flex>
    </Flex> */

    return (
      <Flex container flexDirection="column" width="100%" padding="30px">
        <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
          <Margin top="40px" bottom="30px">
            <H size={3} texto={cliente.razon_social} />
          </Margin>
        </Flex>
        <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
          <Tabs
            defaultActiveKey={"datosGenerales"}
            transition={false}
            variant={responsive ? "pills" : "tabs"}
            style={{
              marginBottom: "1rem",
              flexDirection: responsive ? "column" : "row",
              alignItems: responsive ? "center" : "",
            }}
            unmountOnExit={true}
          >
            <Tab eventKey="datosGenerales" title="Datos Generales">
              <ClienteDatosGeneralesLectura cliente={cliente} />
            </Tab>
            <Tab eventKey="direcciones" title="Direcciones">
              <ClienteVerDirecciones clienteId={cliente.id} />
            </Tab>
            <Tab eventKey="precios" title="Precios">
              {/* <ClienteActualizarDireccion Cliente={this.state.Cliente} />  */}
            </Tab>
            <Tab eventKey="servicios" title="Servicios">
              <ClienteVerServicios clienteId={cliente.id} />
            </Tab>
          </Tabs>
        </Flex>
      </Flex>
    );

    /* return (
      <Flex container flexDirection="column" width="100%" padding="30px">
        <Flex container alignItems="center" justifyContent="center" marginTop="30px">
          <h3> Datos Detallados del Cliente</h3>
        </Flex>

        <Flex container alignItems="flex-start" justifyContent="center" marginTop="30px">
          {<PersonaVerDatosPersonales personaId={this.state.cliente.persona_responsable} />}
        </Flex>

        <Flex container alignItems="flex-start" justifyContent="center" marginTop="30px">
          {<ClienteVerDatosAFIP clienteId={this.state.cliente.id} />}
        </Flex>
        <Flex container alignItems="flex-start" justifyContent="center" marginTop="30px">
          {<ClienteVerTipoCliente idTipoCliente={this.state.cliente.tipo_cliente} />}
        </Flex>
        <Flex container alignItems="flex-start" justifyContent="center" marginTop="30px">
          {<ClienteVerVinculaciones idCliente={this.state.cliente.id} />}
        </Flex>
      </Flex>
    ); */
  }
}
