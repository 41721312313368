import React, { Component } from "react";
import InputGenerico from "./InputGenerico";

export default class InputBase extends Component {
  constructor(props) {
    super(props);
    this.state = { valor: this.props.value };
  }

  componentWillReceiveProps(props) {
    this.setState({ valor: props.value });
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.valor !== this.state.valor) {
      if (this.props.onChange) {
        this.props.onChange(this.state.valor);
      }
    }
  }

  manejadorCambio(e) {
    let val = e.target.value;
    if (this.esValida(val)) {
      this.setState({ valor: val });
    } else {
      e.preventDefault();
    }
  }

  esValida(cadena) {
    return true;
  }

  render() {
    return <InputGenerico type="text" {...this.props} onChange={this.manejadorCambio.bind(this)} />;
  }
}
