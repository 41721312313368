import React, { Component } from "react";
import TipoMaterialActualizar from "./TipoMaterialActualizar";
import TipoMaterialLista from "./TipoMaterialLista";
import TipoMaterialNuevo from "./TipoMaterialNuevo";
import Flex from "../../../../compartido/components/flex/Flex";
import { Tabs, Tab } from "react-bootstrap";
import { Global } from "../../../../Global";

export interface Props {}

interface State {
  tabVisible: any;
  disabledActualizar: boolean;
  disabledVer: boolean;
  responsive: boolean;
  tipoMaterialId: number | null;
}

export default class TipoMateriales extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista",
      disabledActualizar: true,
      disabledVer: true,
      responsive: window.innerWidth < 700 ? true : false,
      tipoMaterialId: null,
    };

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  actualizar = (tipoMaterialId: number) => {
    this.setState({
      tabVisible: "actualizar",
      disabledActualizar: false,
      tipoMaterialId: tipoMaterialId,
    });
  };

  ver = (tipoMaterialId: number) => {
    this.setState({
      tabVisible: "ver",
      disabledVer: false,
      tipoMaterialId: tipoMaterialId,
    });
  };

  ocultarActualizar = () => {
    this.setState({
      tabVisible: "lista",
      disabledActualizar: true,
      tipoMaterialId: null,
    });
  };

  ocultarNuevo = () => {
    this.setState({
      tabVisible: "lista",
    });
  };

  renderizarNuevo = () => {
    if (
      Global.UsuarioService.getPermisos().Elementos.Materiales.TipoMaterial.canAdd()
    ) {
      return (
        <Tab eventKey="nuevo" title="Nuevo">
          {this.state.tabVisible === "nuevo" ? (
            <TipoMaterialNuevo ocultar={this.ocultarNuevo} />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarLista = () => {
    if (
      Global.UsuarioService.getPermisos().Elementos.Materiales.TipoMaterial.canView()
    ) {
      return (
        <Tab eventKey="lista" title="Lista">
          <TipoMaterialLista ver={this.ver} actualizar={this.actualizar} />
        </Tab>
      );
    }
  };

  renderizarActualizar = () => {
    if (
      Global.UsuarioService.getPermisos().Elementos.Materiales.TipoMaterial.canChange()
    ) {
      return (
        <Tab
          eventKey="actualizar"
          title="Actualizar"
          disabled={this.state.disabledActualizar}
        >
          {this.state.tipoMaterialId &&
          this.state.tabVisible === "actualizar" ? (
            <TipoMaterialActualizar
              tipoMaterialId={this.state.tipoMaterialId}
              ocultar={this.ocultarActualizar}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey="lista"
      transition={false}
      activeKey={this.state.tabVisible}
      onSelect={this.onSelect}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "center" : "",
      }}
      unmountOnExit={true}
    >
      {this.renderizarNuevo()}
      {this.renderizarLista()}
      {this.renderizarActualizar()}
    </Tabs>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {this.renderizar(this.state.responsive)}
      </Flex>
    );
  }
}
