import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Flex from "../../../compartido/components/flex/Flex";
import H from "../../../libreria/message/title/H";
import RepartoDiarioNuevoTabs from "./new/RepartoDiarioNuevoTabs";
import RepartoDiarioVerDetalle from "./show/RepartoDiarioVerDetalle";
import RepartosDiariosShow from "./show/RepartosDiariosShow";
import RepartoDiarioActualizar from "./update/RepartoDiarioActualizar";
import FechaUtils from "./../../../compartido/utils/FechaUtils";
import { RepartoDiarioLectura, RepartoDiarioService } from "serviciossaintmichel";
import { Global } from "../../../Global";
import RepartosDiariosShowV2, { VersionRepartosDiariosShow } from "./show/RepartosDiariosShowV2";

interface Props {}

interface State {
  tabVisible: any;
  disabledActualizar: boolean;
  disabledVer: boolean;
  responsive: boolean;
  repartoDiarioId: number | null;
  repartoDiario: RepartoDiarioLectura | null;
  recienCreado: boolean;
  permisoVer: boolean;
  permisoModificar: boolean;
}

export default class RepartosDiarios extends Component<Props, State> {
  private repartoDiarioService: RepartoDiarioService;

  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista",
      disabledActualizar: true,
      disabledVer: true,
      responsive: window.innerWidth < 700 ? true : false,
      repartoDiarioId: null,
      repartoDiario: null,
      recienCreado: false,
      permisoVer: this.puedeVer(),
      permisoModificar: this.puedeModificar(),
    };

    this.repartoDiarioService = new RepartoDiarioService(Global.UsuarioService.getToken()!);

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevState.repartoDiarioId !== this.state.repartoDiarioId) {
      if (this.state.repartoDiarioId === null) {
        this.setState({ repartoDiario: null });
      } else {
        this.cargarRepartoDiario(this.state.repartoDiarioId);
      }
    }
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  ocultarVer = () => {
    this.setState({
      tabVisible: "lista",
      disabledVer: true,
      repartoDiarioId: null,
    });
  };

  ocultarNuevo = () => {
    this.setState({
      tabVisible: "lista",
    });
  };

  /* <RepartoDiarioNew
    onExit={this.ocultarNuevo}
    onSaveSuccess={async (repartoDiarioId: number) => {
      this.setState({ recienCreado: true, repartoDiarioId: repartoDiarioId });
      this.actualizar(repartoDiarioId);
    }}
  /> */

  renderizarNuevo = () => {
    if (Global.UsuarioService.getPermisos().Distribucion.Repartos.Reparto.canAdd()) {
      return (
        <Tab eventKey="nuevo" title="Nuevo" width="100%">
          {this.state.tabVisible === "nuevo" ? <RepartoDiarioNuevoTabs onCreacionSimple={this.actualizar} /> : null}
        </Tab>
      );
    }
  };

  renderizarLista = () => {
    if (Global.UsuarioService.getPermisos().Distribucion.Repartos.Reparto.canView()) {
      return (
        <Tab eventKey="lista" title="Lista">
          {/* <RepartosDiariosShow onUpdate={this.actualizar} onView={this.ver} /> */}
          <RepartosDiariosShowV2
            onUpdate={this.state.permisoModificar ? this.actualizar : undefined}
            onView={this.state.permisoVer ? this.ver : undefined}
            version={VersionRepartosDiariosShow.TABLE}
          />
        </Tab>
      );
    }
  };

  renderHeader = (repartoDiario: RepartoDiarioLectura | null) => {
    if (repartoDiario === null || repartoDiario.fecha_realizacion === null) {
      return null;
    }

    const fechaRealizacion = FechaUtils.normalizarFecha(repartoDiario.fecha_realizacion).toLocaleDateString("es-AR");

    return (
      <H
        texto={`${repartoDiario.reparto.nombre} - ${fechaRealizacion} - Viaje ${repartoDiario.viaje_numero}`}
        size={3}
      />
    );
  };

  puedeVer = (): boolean => {
    return Global.UsuarioService.getPermisos().Distribucion.Repartos.Reparto.canView();
  };

  puedeModificar = (): boolean => {
    return Global.UsuarioService.getPermisos().Distribucion.Repartos.Reparto.canChange();
  };

  renderizarActualizar = () => {
    if (!this.state.permisoModificar) {
      return null;
    }

    const { repartoDiario, tabVisible, recienCreado, repartoDiarioId } = this.state;

    return (
      <Tab eventKey="actualizar" title="Actualizar" disabled={this.state.disabledActualizar}>
        {repartoDiarioId && tabVisible === "actualizar" ? (
          <>
            {this.renderHeader(repartoDiario)}
            <RepartoDiarioActualizar recienCreado={recienCreado} id={repartoDiarioId} />
          </>
        ) : null}
      </Tab>
    );
  };

  renderizarVerDetalle = () => {
    if (!this.state.permisoVer) {
      return null;
    }

    return (
      <Tab eventKey="ver" title="Ver Detalle" disabled={this.state.disabledVer}>
        {this.state.repartoDiarioId && this.state.tabVisible === "ver" ? (
          <>
            {this.renderHeader(this.state.repartoDiario)}
            <RepartoDiarioVerDetalle id={this.state.repartoDiarioId} />
          </>
        ) : null}
      </Tab>
    );
  };

  actualizar = (repartoDiarioId: number) => {
    this.setState({
      tabVisible: "actualizar",
      disabledActualizar: false,
      repartoDiarioId: repartoDiarioId,
    });
  };

  ver = (repartoDiarioId: number) => {
    this.setState({
      tabVisible: "ver",
      disabledVer: false,
      repartoDiarioId: repartoDiarioId,
      recienCreado: false,
    });
  };

  cargarRepartoDiario = async (repartoDiarioId: number) => {
    const repartoDiario = await this.repartoDiarioService.get(repartoDiarioId);
    this.setState({ repartoDiario: repartoDiario });
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey="lista"
      transition={false}
      activeKey={this.state.tabVisible}
      onSelect={this.onSelect}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "center" : "",
      }}
      unmountOnExit={true}
      width="100%"
    >
      {this.renderizarNuevo()}
      {this.renderizarLista()}
      {this.renderizarActualizar()}
      {this.renderizarVerDetalle()}
    </Tabs>
  );

  render() {
    return (
      <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        {this.renderizar(this.state.responsive)}
      </Flex>
    );
  }
}
