import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import RequestButton from "../../../../../../libreria/action/request-button/RequestButton";
import { Modal } from "@mantine/core";
import ProductoComboRetornableUpdate from "./ProductoComboRetornableUpdate";
import {
  CANALES_WEB_SOCKET,
  ComboRetornableService,
  ProductoComboRetornable,
  WebSocketService,
} from "serviciossaintmichel";
import { Global } from "../../../../../../Global";
import ImagenesURLs from "../../../../../../compartido/utils/ImagenesURLs";

export interface Props {
  idComboRetornable: number;
  productos: Array<ProductoComboRetornable>;
}
interface State {
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
  producto: ProductoComboRetornable | null;
}

export default class ProductosComboRetornableShow extends Component<
  Props,
  State
> {
  private comboRetornableService: ComboRetornableService;

  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      cargando: false,
      producto: null,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.comboRetornableService = new ComboRetornableService(
      Global.UsuarioService.getToken()!
    );
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {};

  eliminarProductoComboRetornable = async (
    idComboRetornable: number,
    idProducto: number
  ) => {
    try {
      this.comboRetornableService.eliminarRelacionConProducto(
        idComboRetornable,
        idProducto
      );
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.VENTAS_COMBOS_RETORNABLES,
        {
          comboRetornableId: this.props.idComboRetornable,
        }
      );
    } catch (error) {
      this.comboRetornableService.manejarErrorHTTP(
        "Eliminar Producto del Combo Retornable"
      );
    }
  };

  renderProductos = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      {this.props.productos.length > 0
        ? this.renderizarTabla()
        : this.renderSinProductos()}
      <Modal
        styles={{
          title: {
            fontSize: "larger",
          },
          modal: {
            backgroundImage: ImagenesURLs.urlFondoModal(),
          },
        }}
        opened={this.state.producto ? true : false}
        onClose={() => this.setOpened(null)}
        title="Actualizar Producto del Combo Retornable"
      >
        {this.state.producto ? (
          <ProductoComboRetornableUpdate
            producto={{
              cantidad_cobrable: this.state.producto.cantidad_cobrable,
              cantidad_vacio: this.state.producto.cantidad_vacio,
              activo: this.state.producto.activo,
              producto: this.state.producto.producto,
            }}
            idComboRetornable={this.props.idComboRetornable}
            onSaveSuccess={() => this.setOpened(null)}
            onExit={() => this.setOpened(null)}
          />
        ) : null}
      </Modal>
    </Flex>
  );

  renderSinProductos = () => {
    return "No posee Productos";
  };

  renderizarFilas = () => {
    return this.props.productos.map((relacion, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{relacion.producto.nombre}</td>
          <td className="text-center">{relacion.cantidad_cobrable}</td>
          <td className="text-center">{relacion.cantidad_vacio}</td>
          <td className="text-center">{this.botones(relacion)}</td>
        </tr>
      );
    });
  };

  botones = (relacion: ProductoComboRetornable) => {
    return (
      <Flex
        flexDirection="row"
        width="100%"
        alignItems="center"
        justifyContent="center"
        marginTop="20px"
        gap="1rem"
      >
        <RequestButton
          texto={"Actualizar"}
          onClick={() => {
            this.setOpened(relacion);
          }}
        />
        <RequestButton
          propsBoton={{
            variant: "danger",
          }}
          texto={"Eliminar"}
          onClick={() => {
            this.eliminarProductoComboRetornable(
              this.props.idComboRetornable,
              relacion.producto.id
            );
          }}
        />
      </Flex>
    );
  };
  setOpened = (producto: ProductoComboRetornable | null) => {
    this.setState({ producto: producto });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Producto</th>
            <th className="text-center">Cantidad</th>
            <th className="text-center">Vacios</th>
            <th className="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  renderLoading = () => "Cargando Productos";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="20px"
      >
        {this.state.cargando ? this.renderLoading() : this.renderProductos()}
      </Flex>
    );
  }
}
