import React, { Component } from "react";
import { CANALES_WEB_SOCKET, CuponEscritura, CuponService, WebSocketService } from "serviciossaintmichel";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import { Global } from "../../../../../Global";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Box from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import CuponLoad from "../load/CuponLoad";

export interface Props {
  onSaveSuccess?: Function;
  onExit?: Function;
}

interface State {
  cupon: CuponEscritura;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class CuponNew extends Component<Props, State> {
  private cuponService: CuponService;

  constructor(props: Props) {
    super(props);
    this.state = {
      cupon: this.createEmptyCupon(),
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    this.cuponService = new CuponService(Global.UsuarioService.getToken()!);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  createEmptyCupon = (): CuponEscritura => {
    return { tipo: "Unico", descuento: -1, automatico: false, fecha_inicio: null, fecha_fin: null };
  };

  onSave = async () => {
    try {
      const cupon = await this.cuponService.create(this.state.cupon);

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.VENTAS_CUPONES, { type: "new", id: cupon.id });

      await Swal.fire({
        icon: "success",
        text: "Descuento creado",
      });

      if (this.props.onSaveSuccess) this.props.onSaveSuccess(cupon.id);
      this.setState({ cupon: this.createEmptyCupon() });
    } catch (error) {
      this.cuponService.manejarErrorHTTP(error, "Descuento");
    }
  };

  onExit = () => {
    if (this.props.onExit !== undefined) this.props.onExit();
  };

  onChange = (cupon: CuponEscritura) => {
    this.setState({ cupon: cupon });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex flexDirection="column" width="60%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex flexDirection="column" width="50%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <H size={3} texto={"Crear cupon"} />
        </Flex>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <CuponLoad cupon={this.state.cupon} onChange={this.onChange} onValidationChange={this.onValidationChange} />
        </Flex>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
          <RequestButton propsBoton={{ disabled: !this.state.valid }} texto={"Crear"} onClick={this.onSave} />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.screen === ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen === ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen === ScreenType.TABLET ? this.renderizarTablet() : null}
      </Flex>
    );
  }
}
