import React, { Component } from "react";
import { CANALES_WEB_SOCKET, ConsumoCliente, ConsumoClienteService, ConsumoGet, ConsumoService, WebSocketService } from "serviciossaintmichel";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import RequestButton from "../../../../../../../compartido/components/request-button/RequestButton";
import { Global } from "../../../../../../../Global";
import ConsumoClienteLoad from "./ConsumoClienteLoad";




export interface Props {
  consumoClienteId: number;
  fechaFinMax?: Date;
}

interface State {
  consumo: ConsumoGet | null;
  consumoCliente: ConsumoCliente | null;
  consumoClienteValido: boolean;
}

export default class ConsumoClienteUpdate extends Component<Props, State> {

  private consumoClienteService:ConsumoClienteService;
  private consumoService:ConsumoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      consumo: null,
      consumoCliente: null,
      consumoClienteValido: true,
    };

    this.consumoClienteService = new ConsumoClienteService(Global.UsuarioService.getToken()!);
    this.consumoService = new ConsumoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CONSUMOS,
      funcionCallback: this.consumoWSHandler,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_SERVICIO_ACTIVO,
      funcionCallback: this.servicioActivoWSHandler,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_CONSUMOS,
      funcionCallback: this.consumoClienteWSHandler,
    });
    this.getConsumoCliente();
  };

  getConsumoCliente = async () => {
    try {
      const consumoCliente = await this.consumoClienteService.get(
        this.props.consumoClienteId
      );
      const consumo = await this.consumoService.get(consumoCliente.consumo);

      this.setState({
        consumo: consumo,
        consumoCliente: consumoCliente,
      });
    } catch (error) {
      this.consumoClienteService.manejarErrorHTTP(error, "ConsumosCliente");
    }
  };

  consumoWSHandler = async (e: MessageEvent) => {
    const consumoId = JSON.parse(e.data).message.consumoId;
    if (consumoId === this.state.consumoCliente?.consumo) {
      this.getConsumoCliente();
    }
  };

  consumoClienteWSHandler = async (e: MessageEvent) => {
    const consumoClienteId = JSON.parse(e.data).message.consumoClienteId;
    if (consumoClienteId === this.state.consumoCliente?.id) {
      this.getConsumoCliente();
    }
  };

  servicioActivoWSHandler = async (e: MessageEvent) => {
    const clienteId = JSON.parse(e.data).message.clienteId;
    if (clienteId === this.state.consumoCliente?.cliente) {
      this.getConsumoCliente();
    }
  };

  actualizarConsumoCliente = async (consumoCliente: ConsumoCliente) => {
    try {
      await this.consumoClienteService.update(consumoCliente, consumoCliente.id);
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_CONSUMOS,
        {
          consumoClienteId: consumoCliente.id,
        }
      );
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_SERVICIO_ACTIVO,
        {
          clienteId: consumoCliente.cliente,
        }
      );
    } catch (error) {
      this.consumoClienteService.manejarErrorHTTP(error, "ConsumosCliente");
    }
  };

  onChange = (consumoCliente: ConsumoCliente) => {
    this.setState({ consumoCliente: consumoCliente });
  };

  onValidationChange = (valido: boolean) => {
    this.setState({ consumoClienteValido: valido });
  };

  render = () => {
    const { fechaFinMax } = this.props;
    const { consumoCliente, consumo, consumoClienteValido } = this.state;

    if (consumoCliente === null || consumo === null) {
      return null;
    }

    return (
      <>
        <ConsumoClienteLoad
          consumoCliente={consumoCliente}
          consumo={consumo}
          clienteId={consumoCliente.cliente}
          updating={true}
          onChange={this.onChange}
          onValidationChange={this.onValidationChange}
          fechaFinMax={fechaFinMax}
        />

        <Flex container justifyContent="space-around" marginTop="1rem">
          <RequestButton
            onClick={() => {
              this.actualizarConsumoCliente(consumoCliente);
            }}
            propsBoton={{ variant: "info", disabled: !consumoClienteValido }}
            texto="Actualizar"
            confirmacion={true}
          />
        </Flex>
      </>
    );
  };
}
