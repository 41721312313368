import React, { Component } from "react";
import { CANALES_WEB_SOCKET, ClienteService, VinculacionCliente, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../../Global";
import Flex from "../../../../../../../libreria/appearance/flex/Flex";
import ClienteVerVinculacion from "./VinculacionClienteShow";







export interface Props {
  idCliente: number;
}

interface State {
  vinculaciones: Array<VinculacionCliente> | null;
  visible: boolean;
}

export default class VinculacionesClienteShow extends Component<Props, State> {

  private clienteService:ClienteService;

  constructor(props: Props) {
    super(props);

    this.state = {
      vinculaciones: null,
      visible: false,
    };

    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    let vinculaciones: Array<VinculacionCliente> | null = await this.clienteService.getVinculaciones(
      this.props.idCliente
    ).catch((error) => {
      this.clienteService.manejarErrorHTTP(error, "Cliente");
      return null;
    });
    if (vinculaciones != null) {
      this.setState({ vinculaciones: vinculaciones });
    }
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_VINCULACIONES,
      funcionCallback: this.refrescarVinculaciones,
    });
  };

  refrescarVinculaciones = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (dato.message.clienteId === this.props.idCliente) {
      let vinculaciones: Array<VinculacionCliente> | null = await this.clienteService.getVinculaciones(
        this.props.idCliente
      ).catch((error) => {
        this.clienteService.manejarErrorHTTP(error, "Cliente");
        return null;
      });
      if (vinculaciones != null) {
        this.setState({ vinculaciones: vinculaciones });
      }
    }
  };

  renderVinculacion(vinculacion: VinculacionCliente) {
    return (
      <Flex container flexDirection="column" alignItems="flex-start" justifyContent="center" marginTop="10px">
        <ClienteVerVinculacion idVinculacionCliente={vinculacion.id} />
        <Flex container flexDirection="row" alignItems="center" justifyContent="center">
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              height: "1px",
              width: "100%",
              borderTop: "1px solid gray",
            }}
          />
        </Flex>
      </Flex>
    );
  }

  render() {
    if (this.state.vinculaciones != null && this.state.vinculaciones.length > 0) {
      return (
        <Flex container flexDirection="column" width="100%" alignItems="flex-start" justifyContent="center">
          {this.state.vinculaciones.map((vinculacion) => this.renderVinculacion(vinculacion))}
        </Flex>
      );
    } else return null;
  }
}
