import React, { Component } from "react";
import { TipoCliente, TipoClienteService } from "serviciossaintmichel";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import { Fuentes } from "../../../../../../../compartido/estilos/estilos";
import { Global } from "../../../../../../../Global";
import H from "../../../../../../../libreria/message/title/H";





interface Props {
  tipoClienteId: number;
}

interface State {
  tipoCliente: TipoCliente | null;
}

export default class TipoClienteShow extends Component<Props, State> {

  private tipoClienteService:TipoClienteService;

  constructor(props: Props) {
    super(props);
    this.state = { tipoCliente: null };
    this.tipoClienteService = new TipoClienteService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    try {
      const tipoCliente = await this.tipoClienteService.get(this.props.tipoClienteId);
      this.setState({ tipoCliente });
    } catch (error) {
      this.tipoClienteService.manejarErrorHTTP(error, "Tipo Cliente");
    }
  };

  render() {
    const { tipoCliente } = this.state;

    if (tipoCliente === null) {
      return null;
    }

    return (
      <Flex container flexDirection="column" justifyContent="center" alignItems="center" width="auto">
        <H size={3} fuente={Fuentes.Titulos} texto="Tipo de cliente" />
        <span>{tipoCliente.tipo}</span>
      </Flex>
    );
  }
}
