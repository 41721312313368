import React, { Component } from "react";
import Seccion from "../../../compartido/components/seccion/Seccion";
import Ruta from "../../../compartido/models/Ruta";
import Mapa from "../components/Mapa";

export interface Props {}

export default class SeccionMapas extends Component<Props> {
  static pathBase = "/mapas/seccion-mapas";

  static rutas: Array<Ruta> = [
    {
      name: "Seguimientos",
      path: SeccionMapas.pathBase + "/seguimientos",
      component: Mapa,
    },
  ];

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Seccion rutasNavbar={SeccionMapas.rutas} tituloNavbar={"Menú Mapas"} />
    );
  }
}
