import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";




import { CANALES_WEB_SOCKET, MaquinaServicioSimbolicaGet, MaquinaServicioSimbolicaPost, MaquinaServicioSimbolicaService, ProductoGet, ProductoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";


export interface Props {
  ocultar: Function;
  callbackParent?: Function;
}

interface State {
  maquinaServicioSimbolica: MaquinaServicioSimbolicaPost;
  alerta: any;
}

export default class MaquinaServicioSimbolicaNuevo extends Component<
  Props,
  State
> {

  private maquinaServicioSimbolicaService:MaquinaServicioSimbolicaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      maquinaServicioSimbolica: this.maquinaServicioSimbolicaCrear(),
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };

    this.maquinaServicioSimbolicaService = new MaquinaServicioSimbolicaService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    const maquinaServicioSimbolicaCrear: MaquinaServicioSimbolicaPost =
      this.state.maquinaServicioSimbolica;
    this.setState({
      maquinaServicioSimbolica: maquinaServicioSimbolicaCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  maquinaServicioSimbolicaCrear(): MaquinaServicioSimbolicaPost {
    return {
      nombre: "",
    };
  }

  setearNombre = (nombre: string) => {
    let maquinaServicioSimbolica = this.state.maquinaServicioSimbolica;
    maquinaServicioSimbolica.nombre = nombre;
    this.setState({ maquinaServicioSimbolica: maquinaServicioSimbolica });
  };

  handleCreate() {
    this.maquinaServicioSimbolicaService.create(this.state.maquinaServicioSimbolica)
      .then((maquinaServicioSimbolica) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_SIMBOLICA
        );
        if (this.props.callbackParent) {
          this.props.callbackParent(maquinaServicioSimbolica.id);
        } else {
          this.setState({
            alerta: {
              tipo: "alert-success",
              mensaje: "Maquina Servicio Simbolica Creada!",
              titulo: "Atención",
              estado: "block",
            },
          });
          const maquinaServicioSimbolicaCrear: MaquinaServicioSimbolicaPost =
            this.maquinaServicioSimbolicaCrear();
          this.setState({
            maquinaServicioSimbolica: maquinaServicioSimbolicaCrear,
          });
        }
      })
      .catch((error) => {
        this.maquinaServicioSimbolicaService.manejarErrorHTTP(
          error,
          "Máquina Servicio Simbólica"
        );
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.maquinaServicioSimbolica.nombre ? false : true;
  };

  renderizarFormulario() {
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="nombre">Nombre</label>
                <InputCaracteres
                  id="nombre"
                  name="nombre"
                  onChange={this.setearNombre}
                  value={this.state.maquinaServicioSimbolica.nombre}
                  placeholder="Ingrese Nombre de la Maquina Servicio Simbolica"
                />
              </div>
              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  ref="boton"
                  type="submit"
                  className={"btn btn-info"}
                  disabled={this.disableButton()}
                >
                  {"Crear"}
                </button>
                <button
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
              <div className={"form-group"}>
                <Alerta
                  tipo={this.state.alerta.tipo}
                  titulo={this.state.alerta.titulo}
                  mensaje={this.state.alerta.mensaje}
                  estado={this.state.alerta.estado}
                />
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
