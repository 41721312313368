import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import Flex from "../flex/Flex";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Fuentes } from "../../estilos/estilos";
import SelectDia from "./SelectDia";
import Time from "../../utils/Time";
import { Dia, FranjaHoraria } from "serviciossaintmichel";

interface Props {
  onChangeFranja?: Function;
  onChangeValidation?: Function;
  franja?: FranjaHoraria;
  accion?: boolean;
  onClickAccion?: Function;
  textoAccion?: string;
}

interface State {
  franja: FranjaHoraria;
  validado: boolean;
}

export class ComponenteFranjaHoraria extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      franja: this.props.franja ? this.props.franja : this.crearFranja(),
      validado: this.props.franja ? this.isValidado(this.props.franja) : false,
    };
    let maxTime = new Date();
    maxTime.setHours(22);
    maxTime.setMinutes(0);
    maxTime.setSeconds(0);
    this.maxTime = maxTime;

    let minTime = new Date();
    minTime.setHours(7);
    minTime.setMinutes(0);
    minTime.setSeconds(0);
    this.minTime = minTime;
  }

  maxTime: Date;
  minTime: Date;

  componentDidMount() {
    let t = new Time();
  }

  crearFranja = (): FranjaHoraria => {
    let inicio = new Time();
    inicio.setHours(7);
    inicio.setMinutes(0);
    inicio.setSeconds(0);

    let fin = new Time();
    fin.setHours(22);
    fin.setMinutes(0);
    fin.setSeconds(0);

    return { inicio: inicio, fin: fin, dia: null };
  };

  newFranja = () => {
    let franja: FranjaHoraria = this.crearFranja();
    this.setState({ franja: franja, validado: false });
  };

  isValidado = (franja: FranjaHoraria): boolean => {
    return this.state.franja.dia != null &&
      this.state.franja.inicio &&
      this.state.franja.fin
      ? true
      : false;
  };

  updateValidacion = () => {
    let x: boolean = this.isValidado(this.state.franja);
    this.setState({ validado: x });
    if (this.props.onChangeValidation) this.props.onChangeValidation(x);
  };

  setearHoraInicio = (hora: Date) => {
    let franja = this.state.franja;
    franja.inicio.setTimeFromDate(hora);

    if (franja.inicio > franja.fin) {
      franja.fin.setHours(22);
      franja.fin.setMinutes(0);
      franja.fin.setSeconds(0);
    }

    this.setState({ franja: franja });
    this.updateValidacion();
    if (this.props.onChangeFranja) this.props.onChangeFranja(franja);
  };

  setearHoraFin = (hora: Date) => {
    let franja = this.state.franja;
    franja.fin.setTimeFromDate(hora);
    this.setState({ franja: franja });
    this.updateValidacion();
    if (this.props.onChangeFranja) this.props.onChangeFranja(franja);
  };

  onChangeDia = (dia: Dia | null) => {
    let franja = this.state.franja;
    franja.dia = dia;
    this.setState({ franja: franja });
    this.updateValidacion();
    if (this.props.onChangeFranja) this.props.onChangeFranja(franja);
  };

  accion = () => {
    if (this.props.onClickAccion) this.props.onClickAccion(this.state.franja);
  };

  render() {
    return (
      <Flex container flexDirection="column">
        <Form.Group>
          <Form.Label
            style={{ fontFamily: Fuentes.Labels, marginRight: "10px" }}
          >
            Hora Inicio
          </Form.Label>
          <DatePicker
            onChange={this.setearHoraInicio}
            selected={this.state.franja.inicio}
            maxTime={this.maxTime}
            minTime={this.minTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label
            style={{ fontFamily: Fuentes.Labels, marginRight: "10px" }}
          >
            Hora Fin
          </Form.Label>
          <DatePicker
            onChange={this.setearHoraFin}
            selected={this.state.franja.fin}
            minTime={this.state.franja.inicio}
            maxTime={this.maxTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label style={{ fontFamily: Fuentes.Labels }}>Día</Form.Label>
          <SelectDia
            seleccionado={this.state.franja.dia}
            onChangeDia={this.onChangeDia}
          />
        </Form.Group>

        {this.props.accion ? (
          <Form.Group style={{ marginTop: "10px" }}>
            <Button onClick={this.accion} disabled={!this.state.validado}>
              {this.props.textoAccion ? this.props.textoAccion : "Agregar"}
            </Button>
          </Form.Group>
        ) : null}
      </Flex>
    );
  }
}
export default ComponenteFranjaHoraria;
