import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";

import { CANALES_WEB_SOCKET, TipoCombustibleGet, TipoCombustibleService, TipoVehiculoGet, TipoVehiculoService, VehiculoGet, VehiculoPost, VehiculoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  vehiculos: Array<VehiculoGet>;
}

export default class VehiculoLista extends Component<Props, State> {

  private vehiculoService:VehiculoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      vehiculos: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_VEHICULOS,
      funcionCallback: this.getElementos,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_COMBUSTIBLES,
      funcionCallback: this.getElementos,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_VEHICULOS,
      funcionCallback: this.getElementos,
    });

    this.vehiculoService = new VehiculoService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.vehiculoService.list()
      .then((result) => {
        this.setState({ vehiculos: result });
      })
      .catch((error) => {
        this.vehiculoService.manejarErrorHTTP(error, "Vehículo");
      });
  };

  componentDidMount() {
    this.vehiculoService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }

  armarFilas(vehiculos: Array<VehiculoGet>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (vehiculos.length > 0) {
      for (let i = 0; i < vehiculos.length; i++) {
        let fila: Array<string> = [];
        fila.push(vehiculos[i].patente);
        fila.push(vehiculos[i].nombre);
        fila.push(vehiculos[i].tipo_vehiculo.tipo);
        fila.push(vehiculos[i].tipo_combustible.tipo);
        fila.push(vehiculos[i].marca);
        fila.push(vehiculos[i].capacidad_carga.toString());
        fila.push(vehiculos[i].descripcion);

        let filaResult: FilaTablaGenerica = {
          id: vehiculos[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (Global.UsuarioService.getPermisos().Elementos.Vehiculos.Vehiculo.canChange()) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (Global.UsuarioService.getPermisos().Elementos.Vehiculos.Vehiculo.canView()) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.vehiculoService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.vehiculos)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.vehiculoService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaVehiculos"
      />
    );
  }
}
