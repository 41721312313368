import React, { Component } from "react";
import { Proveedor } from "serviciossaintmichel";
import SelectGenerico from "../../../compartido/components/select/SelectGenerico";

interface Props {
  callbackParent: Function;
  seleccionado: Proveedor;
  proveedores: Array<Proveedor>;
}
interface State {}

export default class SelectProveedor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado,
    };
  }

  getPosicion = (proveedor: Proveedor): number => {
    return this.props.proveedores.findIndex((e) => e.id === proveedor.id);
  };
  getSeleccionado = (indice: number) => {
    this.props.callbackParent(this.props.proveedores[indice]);
  };

  render() {
    return (
      <SelectGenerico
        titulo="Proveedores"
        seleccionado={this.getPosicion(this.props.seleccionado)}
        elementos={this.props.proveedores.map((t) => t.nombre)}
        callback={this.getSeleccionado}
      />
    );
  }
}
