import React, { Component } from "react";
import "../template.css";
import "./cuerpo.css";
import { Switch, Redirect } from "react-router-dom";
import Footer from "../Footer/Footer.jsx";
import PrivateRoute from "../../PrivateRoute";
import Ruta from "../../compartido/models/Ruta";
import Flex from "../../libreria/appearance/flex/Flex";
import Box from "../../libreria/containers/box/Box";

export interface Props {
  rutasSidebar: Array<Ruta>;
}

export default class Cuerpo extends Component<Props> {
  getRoutes = () => {
    return this.props.rutasSidebar.map((ruta) => {
      return <PrivateRoute key={ruta.path} path={ruta.path} component={ruta.component} />;
    });
  };

  render() {
    return (
      <Flex flexDirection="column" justifyContent="center" alignItems="center" width="85%" marginTop="0px">
        <Flex flexDirection="column" width="100%">
          <Box>
            <div
              style={{
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                background: "#1f90d1",
                height: "5px",
                width: "100%",
              }}
            ></div>

            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <Switch>
                {this.getRoutes()}
                <Redirect from="/" to="/inicio"></Redirect>
              </Switch>
            </Flex>

            <Footer />
          </Box>
        </Flex>
      </Flex>
    );
  }
}
