import React, { Component } from "react";
import * as Icon from "react-bootstrap-icons";
import { CANALES_WEB_SOCKET, CuentaBancaria, WebSocketService } from "serviciossaintmichel";
import { CuentaBancariaService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";
import { Button, Flex, Loader, Modal } from "@mantine/core";
import ImagenesURLs from "../../../../../../compartido/utils/ImagenesURLs";
import CuentaBancariaNew from "./CuentaBancariaNew";
import CuentaBancariaUpdate from "./CuentaBancariaUpdate";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";

interface Props {
  clienteId: number;
}

interface State {
  cuentasBancarias: Array<CuentaBancaria> | null;
  cuentaBancariaId: number | null;
}

export default class CuentaBancariaClienteActualizar extends Component<Props, State> {
  private cuentasBancariasService: CuentaBancariaService;
  private webSockets: Array<WebSocket>;

  constructor(props: Props) {
    super(props);
    this.state = {
      cuentasBancarias: null,
      cuentaBancariaId: null,
    };
    this.cuentasBancariasService = new CuentaBancariaService(Global.UsuarioService.getToken()!);
    this.webSockets = [];
  }

  componentDidMount() {
    this.setCuentasBancarias();
    this.webSockets.push(
      WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.VENTAS_CUENTAS_BANCARIAS,
        funcionCallback: this.setCuentasBancarias,
      })
    );
  }

  setCuentasBancarias = async () => {
    const filtros = new URLSearchParams({ cliente_id: this.props.clienteId.toString(), limite: "0" });
    const cuentasBancarias = await this.cuentasBancariasService.list(filtros);
    this.setState({ cuentasBancarias: cuentasBancarias });
  };

  eliminarCuentaBancaria = async (cuentaBancariaId: number) => {
    const modalResult = await Swal.fire({
      icon: "question",
      title: "Desea eliminar esta cuenta bancaria?",
      showDenyButton: true,
      confirmButtonText: "Confirmar",
      denyButtonText: "Cancelar",
    });

    if (modalResult.isDenied) {
      return;
    }

    try {
      await this.cuentasBancariasService.delete(cuentaBancariaId);
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.VENTAS_CUENTAS_BANCARIAS, {
        idCuentaBancaria: cuentaBancariaId,
      });
      Swal.fire({
        icon: "success",
        text: "Cuenta bancaria eliminada con éxito",
      });
    } catch (error) {
      this.cuentasBancariasService.manejarErrorHTTP(error);
    }
  };

  renderFormularioCuentaBancaria = () => {
    const { clienteId } = this.props;
    const { cuentaBancariaId } = this.state;

    if (cuentaBancariaId === null) {
      return null;
    }

    if (cuentaBancariaId === -1) {
      return <CuentaBancariaNew clienteId={clienteId} onSubmit={() => this.setState({ cuentaBancariaId: null })} />;
    }

    return (
      <CuentaBancariaUpdate
        cuentaBancariaId={cuentaBancariaId}
        onSubmit={() => this.setState({ cuentaBancariaId: null })}
      />
    );
  };

  renderTablaCuentasBancarias = (cuentasBancarias: Array<CuentaBancaria>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Tipo de cuenta</th>
            <th>CUIL</th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Banco</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {cuentasBancarias.map((cuenta: CuentaBancaria) => {
            return (
              <tr>
                <td>{cuenta.tipo}</td>
                <td>{cuenta.cuit}</td>
                <td>{cuenta.nombre}</td>
                <td>{cuenta.apellido}</td>
                <td>{cuenta.banco.nombre}</td>
                <td>
                  <Button
                    onClick={() => {
                      this.setState({ cuentaBancariaId: cuenta.id });
                    }}
                    color="blue"
                  >
                    <Icon.Pencil />
                  </Button>
                </td>
                <td>
                  <Button color="red" onClick={() => this.eliminarCuentaBancaria(cuenta.id)}>
                    <Icon.Trash />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  render = () => {
    const { cuentasBancarias, cuentaBancariaId } = this.state;

    if (cuentasBancarias === null) {
      return <Loader />;
    }

    return (
      <Flex direction={"column"} align={"center"} gap={"md"}>
        {cuentasBancarias.length > 0 ? (
          this.renderTablaCuentasBancarias(cuentasBancarias)
        ) : (
          <div>El cliente no posee cuentas bancarias registradas</div>
        )}
        <Button
          onClick={() => {
            this.setState({ cuentaBancariaId: -1 });
          }}
        >
          Registrar nueva cuenta
        </Button>
        <Modal
          centered
          size="sm"
          transitionDuration={0}
          opened={cuentaBancariaId !== null}
          onClose={() => {
            this.setState({ cuentaBancariaId: null });
          }}
          styles={{
            modal: {
              backgroundImage: ImagenesURLs.urlFondoModal(),
            },
          }}
          title={cuentaBancariaId === -1 ? "Registro de cuenta bancaria" : "Edición de cuenta bancaria"}
        >
          {this.renderFormularioCuentaBancaria()}
        </Modal>
      </Flex>
    );
  };
}
