import { Divider, Badge } from "@mantine/core";
import _ from "lodash";
import Swal from "sweetalert2";
import React, { Component } from "react";
import { Table, Button } from "react-bootstrap";
import FechaDisplay from "../../../../compartido/components/fecha-display/FechaDisplay";
import RequestButton from "../../../../libreria/action/request-button/RequestButton";
import Box from "../../../../libreria/containers/box/Box";
import ManejadorErroresHTTP from "../../../../compartido/utils/ManejadorErroresHTTP";
import RealizacionProgramacionEntregaLoad from "../load/RealizacionProgramacionEntregaLoad";
import H from "../../../../libreria/message/title/H";
import Flex from "../../../../libreria/appearance/flex/Flex";

import {
  EntregaProgramacionBultoProducto,
  EntregaProgramacionMaquina,
  EntregaProgramacionProducto,
  EstadoLogisticaPaquete,
  EstadoProgramacionEnum,
  getColorEstadoLogisticaPaquete,
  getColorEstadoProgramacion,
  getColorPrioridad,
  ProgramacionEntrega,
  ProgramacionEntregaService,
  RealizacionProgramacionEntrega,
  RepartoDiarioService,
  VentaProgramacionBultoProducto,
  VentaProgramacionMaquina,
  VentaProgramacionProducto,
  VentaProgramacionPromocion,
  VisitaService,
} from "serviciossaintmichel";
import { Global } from "../../../../Global";

export interface Props {
  visitaId: number;
  repartoDiarioId: number;
}

interface State {
  programacionEntrega: ProgramacionEntrega | null;
  programacionEntregaFormulario: ProgramacionEntrega | null;
  programacionEntregaFormularioValida: boolean;
  cargando: boolean;
}

export default class ProgramacionEntregaVisitaUpdate extends Component<Props, State> {
  private webSockets: Array<WebSocket>;
  private repartoDiarioService: RepartoDiarioService;
  private visitaService: VisitaService;
  private programacionEntregaService: ProgramacionEntregaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      programacionEntrega: null,
      programacionEntregaFormulario: null,
      programacionEntregaFormularioValida: false,
      cargando: true,
    };
    this.webSockets = [];
    this.repartoDiarioService = new RepartoDiarioService(Global.UsuarioService.getToken()!);
    this.visitaService = new VisitaService(Global.UsuarioService.getToken()!);
    this.programacionEntregaService = new ProgramacionEntregaService(Global.UsuarioService.getToken()!);
  }

  cargarVisita = async () => {
    try {
      const visita = await this.repartoDiarioService.getVisita(this.props.repartoDiarioId, this.props.visitaId);
      this.setState({ programacionEntrega: visita.programacion_entrega });
    } catch (error) {
      this.visitaService.manejarErrorHTTP(error);
    } finally {
      this.setState({ cargando: false });
    }
  };

  componentDidMount = async () => {
    await this.cargarVisita();
    this.actualizarValidezFormulario();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!_.isEqual(prevState.programacionEntregaFormulario, this.state.programacionEntregaFormulario)) {
      this.actualizarValidezFormulario();
    }
  };

  componentWillUnmount = () => {
    this.webSockets.forEach((ws: WebSocket) => ws.close());
  };

  renderVentasProductos = (productosVendidos: Array<VentaProgramacionProducto>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Producto</th>
            <th>Cantidad</th>
            <th>Bonificados</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {productosVendidos.map(({ paquete, estado }) => (
            <tr key={paquete.producto.id}>
              <td>{paquete.producto.nombre}</td>
              <td>{paquete.cantidad}</td>
              <td>{paquete.bonificados}</td>
              <td>
                {estado ? (
                  <Badge color={getColorEstadoLogisticaPaquete(estado)} variant={"filled"}>
                    {estado}
                  </Badge>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderVentasBultosProductos = (bultosProductosVendidos: Array<VentaProgramacionBultoProducto>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Bulto Producto</th>
            <th>Cantidad</th>
            <th>Bonificados</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {bultosProductosVendidos.map(({ paquete, estado }) => (
            <tr key={paquete.bulto_producto.id}>
              <td>{paquete.bulto_producto.nombre}</td>
              <td>{paquete.cantidad}</td>
              <td>{paquete.bonificados}</td>
              <td>
                {estado ? (
                  <Badge color={getColorEstadoLogisticaPaquete(estado)} variant={"filled"}>
                    {estado}
                  </Badge>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderVentasPromociones = (promocionesVendidas: Array<VentaProgramacionPromocion>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Promoción</th>
            <th>Cantidad</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {promocionesVendidas.map(({ paquete, estado }) => (
            <tr key={paquete.promocion.id}>
              <td>{paquete.promocion.nombre}</td>
              <td>{paquete.cantidad}</td>
              <td>
                {estado ? (
                  <Badge color={getColorEstadoLogisticaPaquete(estado)} variant={"filled"}>
                    {estado}
                  </Badge>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderVentasMaquinas = (maquinasVendidas: Array<VentaProgramacionMaquina>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Máquina</th>
            <th>Cantidad</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {maquinasVendidas.map(({ paquete, estado }) => (
            <tr key={paquete.maquina.id}>
              <td>{paquete.maquina.nombre}</td>
              <td>{paquete.cantidad}</td>
              <td>
                {estado ? (
                  <Badge color={getColorEstadoLogisticaPaquete(estado)} variant={"filled"}>
                    {estado}
                  </Badge>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderEntregasProductos = (productosEntregados: Array<EntregaProgramacionProducto>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Producto</th>
            <th>Cantidad</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {productosEntregados.map(({ paquete, estado }) => (
            <tr key={paquete.producto.id}>
              <td>{paquete.producto.nombre}</td>
              <td>{paquete.cantidad}</td>
              <td>
                {estado ? (
                  <Badge color={getColorEstadoLogisticaPaquete(estado)} variant={"filled"}>
                    {estado}
                  </Badge>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderEntregasBultosProductos = (bultosProductosEntregados: Array<EntregaProgramacionBultoProducto>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Bulto de Producto</th>
            <th>Cantidad</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {bultosProductosEntregados.map(({ paquete, estado }) => (
            <tr key={paquete.bulto_producto.id}>
              <td>{paquete.bulto_producto.nombre}</td>
              <td>{paquete.cantidad}</td>
              <td>
                {estado ? (
                  <Badge color={getColorEstadoLogisticaPaquete(estado)} variant={"filled"}>
                    {estado}
                  </Badge>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderEntregasMaquinas = (maquinasEntregadas: Array<EntregaProgramacionMaquina>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Máquina</th>
            <th>Cantidad</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {maquinasEntregadas.map(({ paquete, estado }) => (
            <tr key={paquete.maquina.id}>
              <td>{paquete.maquina.nombre}</td>
              <td>{paquete.cantidad}</td>
              <td>
                {estado ? (
                  <Badge color={getColorEstadoLogisticaPaquete(estado)} variant={"filled"}>
                    {estado}
                  </Badge>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderProgramacionEntrega = (programacionEntrega: ProgramacionEntrega) => {
    return (
      <div>
        <Box padding="1rem">
          <Flex justifyContent="space-between">
            <H size={3} texto="Programación de entrega" />
            {programacionEntrega.estado_programacion.estado === EstadoProgramacionEnum.EN_CAMINO ? (
              <Button
                onClick={() => {
                  this.setState({ programacionEntregaFormulario: _.cloneDeep(programacionEntrega) });
                }}
                style={{ marginBottom: "1rem" }}
              >
                Registrar realización
              </Button>
            ) : (
              <div></div>
            )}
          </Flex>
          <div>
            <strong>Fecha: </strong>
            <FechaDisplay fecha={programacionEntrega.fecha} />
          </div>

          <div>
            <strong>Prioridad: </strong>
            <Badge color={getColorPrioridad(programacionEntrega.prioridad)} variant="filled">
              {programacionEntrega.prioridad}
            </Badge>
          </div>

          <div>
            <strong>Estado: </strong>
            <Badge color={getColorEstadoProgramacion(programacionEntrega.estado_programacion.estado)} variant="filled">
              {programacionEntrega.estado_programacion.estado}
            </Badge>
          </div>

          {programacionEntrega.estado_programacion.motivo !== null && (
            <div>
              <div>
                <strong>Motivo de rechazo: </strong>
                <span>{programacionEntrega.estado_programacion.motivo.motivo}</span>
              </div>
              <div>
                <strong>Detalle: </strong>
                <span>{programacionEntrega.estado_programacion.detalle}</span>
              </div>
            </div>
          )}

          {programacionEntrega.entregas_producto.length > 0 && (
            <>
              <Divider label="Entrega de Productos" labelPosition="center" variant="dashed" />
              {this.renderEntregasProductos(programacionEntrega.entregas_producto)}
            </>
          )}

          {programacionEntrega.entregas_bulto_producto.length > 0 && (
            <>
              <Divider label="Entrega de Bultos de Producto" labelPosition="center" variant="dashed" />
              {this.renderEntregasBultosProductos(programacionEntrega.entregas_bulto_producto)}
            </>
          )}

          {programacionEntrega.entregas_maquina.length > 0 && (
            <>
              <Divider label="Entrega de Máquinas" labelPosition="center" variant="dashed" />
              {this.renderEntregasMaquinas(programacionEntrega.entregas_maquina)}
            </>
          )}

          {programacionEntrega.ventas_producto.length > 0 && (
            <>
              <Divider label="Venta de Productos" labelPosition="center" variant="dashed" />
              {this.renderVentasProductos(programacionEntrega.ventas_producto)}
            </>
          )}

          {programacionEntrega.ventas_bulto_producto.length > 0 && (
            <>
              <Divider label="Venta de Bultos de Producto" labelPosition="center" variant="dashed" />
              {this.renderVentasBultosProductos(programacionEntrega.ventas_bulto_producto)}
            </>
          )}

          {programacionEntrega.ventas_promocion.length > 0 && (
            <>
              <Divider label="Venta de Promociones" labelPosition="center" variant="dashed" />
              {this.renderVentasPromociones(programacionEntrega.ventas_promocion)}
            </>
          )}

          {programacionEntrega.ventas_maquina.length > 0 && (
            <>
              <Divider label="Venta de Máquinas" labelPosition="center" variant="dashed" />
              {this.renderVentasMaquinas(programacionEntrega.ventas_maquina)}
            </>
          )}
        </Box>
      </div>
    );
  };

  actualizarValidezFormulario = () => {
    const { programacionEntregaFormulario } = this.state;
    if (programacionEntregaFormulario === null) {
      return;
    }

    const esValida = this.esProgramacionValida(programacionEntregaFormulario);
    this.setState({ programacionEntregaFormularioValida: esValida });
  };

  esProgramacionValida = (programacionEntrega: ProgramacionEntrega) => {
    const {
      entregas_producto,
      entregas_bulto_producto,
      entregas_maquina,
      ventas_producto,
      ventas_bulto_producto,
      ventas_promocion,
      ventas_maquina,
      estado_programacion,
    } = programacionEntrega;

    if (estado_programacion.estado === null) {
      return false;
    }

    if (estado_programacion.estado === EstadoProgramacionEnum.CON_RECHAZOS_PARCIALES) {
      // valida que todos los paquetes tengan un estado definido y que al menos un estado sea distinto de "Entregado"
      let distintoEntregado = false;

      for (const { estado } of entregas_producto) {
        if (estado === null) {
          return false;
        }
        if (estado !== EstadoLogisticaPaquete.ENTREGADO) {
          distintoEntregado = true;
          break;
        }
      }

      for (const { estado } of entregas_bulto_producto) {
        if (estado === null) {
          return false;
        }
        if (estado !== EstadoLogisticaPaquete.ENTREGADO) {
          distintoEntregado = true;
          break;
        }
      }

      for (const { estado } of entregas_maquina) {
        if (estado === null) {
          return false;
        }
        if (estado !== EstadoLogisticaPaquete.ENTREGADO) {
          distintoEntregado = true;
          break;
        }
      }

      for (const { estado } of ventas_producto) {
        if (estado === null) {
          return false;
        }
        if (estado !== EstadoLogisticaPaquete.ENTREGADO) {
          distintoEntregado = true;
          break;
        }
      }

      for (const { estado } of ventas_bulto_producto) {
        if (estado === null) {
          return false;
        }
        if (estado !== EstadoLogisticaPaquete.ENTREGADO) {
          distintoEntregado = true;
          break;
        }
      }

      for (const { estado } of ventas_maquina) {
        if (estado === null) {
          return false;
        }
        if (estado !== EstadoLogisticaPaquete.ENTREGADO) {
          distintoEntregado = true;
          break;
        }
      }

      for (const { estado } of ventas_promocion) {
        if (estado === null) {
          return false;
        }
        if (estado !== EstadoLogisticaPaquete.ENTREGADO) {
          distintoEntregado = true;
          break;
        }
      }

      return distintoEntregado === true;
    }

    if (
      estado_programacion.estado === EstadoProgramacionEnum.RECHAZADA ||
      estado_programacion.estado === EstadoProgramacionEnum.NO_ENTREGADA
    ) {
      return estado_programacion.motivo !== null;
    }

    return true;
  };

  onProgramacionLoadChange = (programacionEntrega: ProgramacionEntrega) => {
    this.setState({ programacionEntregaFormulario: programacionEntrega });
  };

  guardarProgramacion = async () => {
    const programacionEntrega = this.state.programacionEntregaFormulario;

    if (programacionEntrega === null) {
      return;
    }

    try {
      await this.manejarRealizacion(programacionEntrega);
      Swal.fire({
        icon: "success",
        text: "Datos guardados con éxito",
      });
      await this.cargarVisita();
      this.setState({ programacionEntregaFormulario: this.state.programacionEntrega });
    } catch (error) {
      const msjError = ManejadorErroresHTTP.getMensajeError(error);
      Swal.fire({
        icon: "error",
        text: "Ocurrió un error - " + msjError,
      });
    }
  };

  manejarRealizacion = async (programacionEntrega: ProgramacionEntrega) => {
    if (programacionEntrega.estado_programacion.estado === EstadoProgramacionEnum.CON_RECHAZOS_PARCIALES) {
      const payload: RealizacionProgramacionEntrega = {
        fecha: programacionEntrega.fecha,
        estado_programacion: {
          ...programacionEntrega.estado_programacion,
          motivo: programacionEntrega.estado_programacion.motivo
            ? programacionEntrega.estado_programacion.motivo.id
            : null,
        },
        entregas_producto: programacionEntrega.entregas_producto.map((elem: any) => ({
          paquete: elem.paquete.id,
          estado: elem.estado,
        })),
        entregas_bulto_producto: programacionEntrega.entregas_bulto_producto.map((elem: any) => ({
          paquete: elem.paquete.id,
          estado: elem.estado,
        })),
        entregas_maquina: programacionEntrega.entregas_maquina.map((elem: any) => ({
          paquete: elem.paquete.id,
          estado: elem.estado,
        })),
        ventas_producto: programacionEntrega.ventas_producto.map((elem: any) => ({
          paquete: elem.paquete.id,
          estado: elem.estado,
        })),
        ventas_bulto_producto: programacionEntrega.ventas_bulto_producto.map((elem: any) => ({
          paquete: elem.paquete.id,
          estado: elem.estado,
        })),
        ventas_maquina: programacionEntrega.ventas_maquina.map((elem: any) => ({
          paquete: elem.paquete.id,
          estado: elem.estado,
        })),
        ventas_promocion: programacionEntrega.ventas_promocion.map((elem: any) => ({
          paquete: elem.paquete.id,
          estado: elem.estado,
        })),
      };
      return await this.programacionEntregaService.realizacionProgramacion(programacionEntrega.id, payload);
    } else {
      const payload = {
        estado_programacion: {
          ...programacionEntrega.estado_programacion,
          motivo: programacionEntrega.estado_programacion.motivo
            ? programacionEntrega.estado_programacion.motivo.id
            : null,
        },
      };
      return await this.programacionEntregaService.realizacionEstadoProgramacion(programacionEntrega.id, payload);
    }
  };

  render() {
    const { programacionEntrega, programacionEntregaFormulario, cargando, programacionEntregaFormularioValida } =
      this.state;

    if (cargando) {
      return <div>Cargando...</div>;
    }

    if (programacionEntregaFormulario !== null) {
      return (
        <Box
          padding="1rem"
          showCloseBtn
          onCloseCallback={() => {
            this.setState({ programacionEntregaFormulario: null });
          }}
          title={"Registro de realización de programación de entrega"}
        >
          <RealizacionProgramacionEntregaLoad
            programacionEntrega={programacionEntregaFormulario}
            onChange={this.onProgramacionLoadChange}
          />

          <RequestButton
            propsBoton={{
              style: { marginTop: "1rem" },
              variant: "success",
              disabled: !programacionEntregaFormularioValida,
            }}
            texto={"Guardar"}
            onClick={this.guardarProgramacion}
          />
        </Box>
      );
    }

    if (programacionEntrega !== null) {
      return this.renderProgramacionEntrega(programacionEntrega);
    }

    return <div>La visita no posee una programacion de entrega asociada</div>;
  }
}
