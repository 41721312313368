import React, { Component } from "react";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import RequestButton from "../../../../../../../compartido/components/request-button/RequestButton";
import ClienteAlquilerLoad from "./AlquilerClienteLoad";
import { AlquilerCliente, AlquilerClienteService, AlquilerGet, AlquilerService, CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../../Global";



export interface Props {
  alquilerClienteId: number;
  fechaFinMax?: Date;
}

interface State {
  alquiler: AlquilerGet | null;
  alquilerCliente: AlquilerCliente | null;
  alquilerClienteValido: boolean;
}

export default class AlquilerClienteUpdate extends Component<Props, State> {

  private alquilerClienteService:AlquilerClienteService;
  private alquilerService:AlquilerService;


  constructor(props: Props) {
    super(props);
    this.state = {
      alquiler: null,
      alquilerCliente: null,
      alquilerClienteValido: true,
    };

    this.alquilerClienteService = new AlquilerClienteService(Global.UsuarioService.getToken()!);
    this.alquilerService = new AlquilerService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES,
      funcionCallback: this.alquilerWSHandler,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_SERVICIO_ACTIVO,
      funcionCallback: this.servicioActivoWSHandler,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_ALQUILERES,
      funcionCallback: this.alquilerClienteWSHandler,
    });
    this.getAlquilerCliente();
  };

  getAlquilerCliente = async () => {
    try {
      const alquilerCliente = await this.alquilerClienteService.get(
        this.props.alquilerClienteId
      );
      const alquiler = await this.alquilerService.get(alquilerCliente.alquiler);

      this.setState({
        alquiler: alquiler,
        alquilerCliente: alquilerCliente,
      });
    } catch (error) {
      this.alquilerClienteService.manejarErrorHTTP(error, "AlquileresCliente");
    }
  };

  alquilerWSHandler = async (e: MessageEvent) => {
    const alquilerId = JSON.parse(e.data).message.alquilerId;
    if (alquilerId === this.state.alquilerCliente?.alquiler) {
      this.getAlquilerCliente();
    }
  };

  alquilerClienteWSHandler = async (e: MessageEvent) => {
    const alquilerClienteId = JSON.parse(e.data).message.alquilerClienteId;
    if (alquilerClienteId === this.state.alquilerCliente?.id) {
      this.getAlquilerCliente();
    }
  };

  servicioActivoWSHandler = async (e: MessageEvent) => {
    const clienteId = JSON.parse(e.data).message.clienteId;
    if (clienteId === this.state.alquilerCliente?.cliente) {
      this.getAlquilerCliente();
    }
  };

  actualizarAlquilerCliente = async (alquilerCliente: AlquilerCliente) => {
    try {
      await this.alquilerClienteService.update(alquilerCliente, alquilerCliente.id);
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_ALQUILERES,
        {
          alquilerClienteId: alquilerCliente.id,
        }
      );
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_SERVICIO_ACTIVO,
        {
          clienteId: alquilerCliente.cliente,
        }
      );
    } catch (error) {
      this.alquilerClienteService.manejarErrorHTTP(error, "AlquileresCliente");
    }
  };

  onChange = (alquilerCliente: AlquilerCliente) => {
    this.setState({ alquilerCliente: alquilerCliente });
  };

  onValidationChange = (valido: boolean) => {
    this.setState({ alquilerClienteValido: valido });
  };

  render = () => {
    const { fechaFinMax } = this.props;
    const { alquilerCliente, alquiler, alquilerClienteValido } = this.state;

    if (alquilerCliente === null || alquiler === null) {
      return null;
    }

    return (
      <>
        <ClienteAlquilerLoad
          alquilerCliente={alquilerCliente}
          alquiler={alquiler}
          clienteId={alquilerCliente.cliente}
          updating={true}
          onChange={this.onChange}
          onValidationChange={this.onValidationChange}
          fechaFinMax={fechaFinMax}
        />

        <Flex container justifyContent="space-around" marginTop="1rem">
          <RequestButton
            onClick={() => {
              this.actualizarAlquilerCliente(alquilerCliente);
            }}
            propsBoton={{ variant: "info", disabled: !alquilerClienteValido }}
            texto="Actualizar"
            confirmacion={true}
          />
        </Flex>
      </>
    );
  };
}
