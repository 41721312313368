import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { BultoProductoGet, DatosBultoProducto, EntregaBultoProductoLectura } from "serviciossaintmichel";
import { excluirIds } from "../../../../compartido/utils/filtros";
import BultosProductoShow, {
  Version,
} from "../../../../elementos/productos/BultoProducto/components/BultosProductoShow";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";



export interface Props {
  onSubmitCallback?: Function;
  excluirBultosProducto?: Array<number>;
}

interface State {
  nuevaEntregaBultoProducto: EntregaBultoProductoLectura;
  bultoProductoSeleccionado: DatosBultoProducto | null;
}

export default class EntregaBultoProductoLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getEstadoInicial();
  }

  bultoProductoNuevo = (): BultoProductoGet => {
    return { id: -1, nombre: "", productos: [], insumos: [] };
  };

  getEstadoInicial = (): State => ({
    nuevaEntregaBultoProducto: {
      bulto_producto: this.bultoProductoNuevo(),
      cantidad: 0,
    },
    bultoProductoSeleccionado: null,
  });

  setBultoProducto = (bultoProd: DatosBultoProducto | null) => {
    const bultoProducto: BultoProductoGet =
      bultoProd !== null ? { ...bultoProd, insumos: [], productos: [] } : this.bultoProductoNuevo();
    this.setState((prevState) => ({
      nuevaEntregaBultoProducto: {
        ...prevState.nuevaEntregaBultoProducto,
        bulto_producto: bultoProd ? bultoProducto : this.state.nuevaEntregaBultoProducto.bulto_producto,
      },
      bultoProductoSeleccionado: bultoProd,
    }));
  };

  onSubmit = () => {
    const { onSubmitCallback = () => {} } = this.props;
    onSubmitCallback(this.state.nuevaEntregaBultoProducto);
    const estadoInicial = this.getEstadoInicial();
    this.setState(estadoInicial);
  };

  validarEntrega = (entregaBultoProducto: EntregaBultoProductoLectura) => {
    return entregaBultoProducto.bulto_producto.id > 0 && entregaBultoProducto.cantidad > 0;
  };

  render() {
    const { nuevaEntregaBultoProducto, bultoProductoSeleccionado } = this.state;
    const filtrosShow = this.props.excluirBultosProducto ? [excluirIds(this.props.excluirBultosProducto)] : undefined;
    return (
      <Flex borderRadius="5px" border="1px solid black" gap="1rem" padding="1rem" alignItems="center">
        <BultosProductoShow
          version={Version.SELECT}
          propsSelect={{
            callbackParent: this.setBultoProducto,
            seleccionado: bultoProductoSeleccionado,
            placeholder: "Seleccione un bulto de producto",
            label: "Bultos de producto",
          }}
          filtros={filtrosShow}
        />
        <div>
          <span>Cantidad</span>
          <Input
            type={InputTypes.Number}
            data={nuevaEntregaBultoProducto.cantidad}
            onChange={(data: number) => {
              this.setState((prevState) => ({
                nuevaEntregaBultoProducto: { ...prevState.nuevaEntregaBultoProducto, cantidad: data },
              }));
            }}
          />
        </div>
        <Button onClick={this.onSubmit} disabled={!this.validarEntrega(nuevaEntregaBultoProducto)}>
          Añadir
        </Button>
      </Flex>
    );
  }
}
