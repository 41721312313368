import { Divider } from "@mantine/core";
import React, { Component } from "react";
import { VentaBultoProductoLectura, VentaComboRetornableLectura, VentaMaquinaLectura, VentaProductoLectura, VentaPromocionLectura, VentaVisitaLectura } from "serviciossaintmichel";

import VentasBultoProductoLoad from "./VentasBultoProductoLoad";
import VentasComboRetornableLoad from "./VentasComboRetornableLoad";
import VentasMaquinaLoad from "./VentasMaquinaLoad";
import VentasProductoLoad from "./VentasProductoLoad";
import VentasPromocionLoad from "./VentasPromocionLoad";

export interface Props {
  ventaVisita: VentaVisitaLectura;
  onChange?: Function;
}

interface State {
  ventaFormulario: VentaVisitaLectura;
}

export default class VentaVisitaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ventaFormulario: props.ventaVisita,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.props.onChange && prevState.ventaFormulario !== this.state.ventaFormulario) {
      this.props.onChange(this.state.ventaFormulario);
    }
  }

  setVentasProducto = (ventasProducto: Array<VentaProductoLectura>) => {
    this.setState((prevState) => ({
      ventaFormulario: { ...prevState.ventaFormulario, productos_vendidos: ventasProducto },
    }));
  };

  setVentasBultoProducto = (ventasBultoProducto: Array<VentaBultoProductoLectura>) => {
    this.setState((prevState) => ({
      ventaFormulario: { ...prevState.ventaFormulario, bultos_producto_vendidos: ventasBultoProducto },
    }));
  };

  setVentasCombo = (ventasCombo: Array<VentaComboRetornableLectura>) => {
    this.setState((prevState) => ({
      ventaFormulario: { ...prevState.ventaFormulario, combos_retornables_vendidos: ventasCombo },
    }));
  };

  setVentasPromo = (ventasPromo: Array<VentaPromocionLectura>) => {
    this.setState((prevState) => ({
      ventaFormulario: { ...prevState.ventaFormulario, promociones_vendidas: ventasPromo },
    }));
  };

  setVentasMaquina = (ventasMaquina: Array<VentaMaquinaLectura>) => {
    this.setState((prevState) => ({
      ventaFormulario: { ...prevState.ventaFormulario, maquinas_vendidas: ventasMaquina },
    }));
  };

  render() {
    const { ventaFormulario } = this.state;

    return (
      <div>
        <Divider label="Productos" labelPosition="center" variant="dashed" />
        <VentasProductoLoad ventasProducto={ventaFormulario.productos_vendidos} onChange={this.setVentasProducto} />

        <Divider label="Bultos de productos" labelPosition="center" variant="dashed" />
        <VentasBultoProductoLoad
          ventasBultoProducto={ventaFormulario.bultos_producto_vendidos}
          onChange={this.setVentasBultoProducto}
        />

        <Divider label="Combos retornables" labelPosition="center" variant="dashed" />
        <VentasComboRetornableLoad
          ventasCombo={ventaFormulario.combos_retornables_vendidos}
          onChange={this.setVentasCombo}
        />

        <Divider label="Promociones" labelPosition="center" variant="dashed" />
        <VentasPromocionLoad ventasPromo={ventaFormulario.promociones_vendidas} onChange={this.setVentasPromo} />

        <Divider label="Máquinas" labelPosition="center" variant="dashed" />
        <VentasMaquinaLoad ventasMaquina={ventaFormulario.maquinas_vendidas} onChange={this.setVentasMaquina} />
      </div>
    );
  }
}
