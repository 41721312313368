import React, { Component } from "react";
import { AlquilerService, CANALES_WEB_SOCKET, DatosAlquiler, WebSocketService } from "serviciossaintmichel";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import { Global } from "../../../../../Global";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Box from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import AlquilerLoad from "../load/AlquilerLoad";




export interface Props {
  onSaveSuccess: Function;
  onExit?: Function;
}

interface State {
  alquiler: DatosAlquiler;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class AlquilerNew extends Component<Props, State> {

  private alquilerService:AlquilerService;

  constructor(props: Props) {
    super(props);
    this.state = {
      alquiler: this.createEmptyAlquiler(),
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    this.alquilerService =  new AlquilerService(Global.UsuarioService.getToken()!);

  
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  createEmptyAlquiler = () => {
    let x: DatosAlquiler = { id: -1, nombre: "" };
    return x;
  };

  onSave = async () => {
    try {
      let x = await this.alquilerService.create(this.state.alquiler);

      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES,
        { type: "new", id: x.id }
      );

      await Swal.fire({
        icon: "success",
        text: "Alquiler Creado",
      });

      this.props.onSaveSuccess(x.id);
    } catch (error) {
      this.alquilerService.manejarErrorHTTP(error, "Alquiler");
    }
  };

  onExit = () => {
    if (this.props.onExit != undefined) this.props.onExit();
  };

  onChange = (alquiler: DatosAlquiler) => {
    this.setState({ alquiler: alquiler });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex
      flexDirection="column"
      width="50%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={3} texto={"Alquiler Nuevo"} />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <AlquilerLoad
            alquiler={this.state.alquiler}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
          />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Crear"}
            onClick={this.onSave}
          />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
