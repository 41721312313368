import React, { Component } from "react";
import Flex from "../../../../compartido/components/flex/Flex";
import Box from "../../../../compartido/components/box/Box";

import { Button, ListGroup } from "react-bootstrap";

import { ArticuloOficinaGet, ArticuloOficinaPost, ArticuloOficinaService, CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";





export interface Props {
  articuloOficinaId: number;
  ocultar: Function;
}

interface State {
  articuloOficina: ArticuloOficinaGet;
  anchoBox: string;
}

export default class ArticuloOficinaVerDetalle extends Component<Props, State> {
  private articuloOficinaService:ArticuloOficinaService;

  constructor(props: Props) {
    super(props);

    this.state = {
      articuloOficina: this.crearArticuloOficinaVacio(),
      anchoBox: window.innerWidth < 750 ? "90%" : "50%",
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_ARTICULOS_OFICINA,
      funcionCallback: this.cargarArticuloOficinaCanal,
    });

    this.articuloOficinaService = new ArticuloOficinaService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 750) {
      this.setState({ anchoBox: "90%" });
    } else {
      this.setState({ anchoBox: "50%" });
    }
  };

  componentDidMount() {
    this.cargarArticuloOficina(this.props.articuloOficinaId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarArticuloOficina(this.props.articuloOficinaId);
    }
  }

  crearArticuloOficinaVacio(): ArticuloOficinaGet {
    const articuloOficina: ArticuloOficinaGet = {
      id: 0,
      nombre: "",
      marca: "",
      descripcion: "",
      proveedores: [],
    };
    return articuloOficina;
  }

  cargarArticuloOficinaCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.articuloOficina &&
      dato.message.articuloOficinaId == this.state.articuloOficina.id
    ) {
      this.cargarArticuloOficina(this.state.articuloOficina.id);
    }
  };

  cargarArticuloOficina = async (id: number) => {
    const resArticuloOficina = await this.articuloOficinaService.get(id).catch(
      (error) => {
        this.articuloOficinaService.manejarErrorHTTP(error, "Artículo Oficina");
        return null;
      }
    );
    if (resArticuloOficina != null) {
      this.setState({
        articuloOficina: resArticuloOficina,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  render() {
    if (this.state.articuloOficina.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal={"10rem"} paddingVertical={"2rem"}>
            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h5>Nombre: {this.state.articuloOficina.nombre}</h5>
                  <h5>Marca: {this.state.articuloOficina.marca}</h5>
                  <h5>Descripcion: {this.state.articuloOficina.descripcion}</h5>
                </div>
              </Flex>
            </Flex>

            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>

            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h4>
                    Proveedores Actuales
                    {this.state.articuloOficina.proveedores.length == 0 ? (
                      " : No tiene"
                    ) : (
                      <Flex
                        container
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        paddingTop="10px"
                      >
                        <ListGroup>
                          {this.state.articuloOficina.proveedores.map(
                            (relacion) => (
                              <ListGroup.Item style={{ textAlign: "center" }}>
                                {relacion.proveedor.nombre +
                                  " - Cantidad mínima que vende: " +
                                  relacion.cantidad_minima +
                                  " - A: " +
                                  relacion.precio +
                                  "$"}
                                <Button
                                  style={{
                                    marginLeft: "1rem",
                                  }}
                                >
                                  Ver Historial
                                </Button>
                              </ListGroup.Item>
                            )
                          )}
                        </ListGroup>
                      </Flex>
                    )}
                  </h4>
                </div>
              </Flex>
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <button
                className={"btn btn-outline-secondary"}
                onClick={this.ocultar}
                style={{ width: "150px" }}
              >
                Salir
              </button>
            </Flex>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
