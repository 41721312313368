import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { CANALES_WEB_SOCKET, Cliente, ClienteService, CondicionIVA, CondicionIVAService, EnteEconomicoDTO, WebSocketService } from "serviciossaintmichel";
import SelectCondicionIVA from "../../../administracion/seccion-clientes/clientes/components/nuevo/SelectCondicionIVA";
import { Global } from "../../../Global";
import { Fuentes } from "../../estilos/estilos";
import Flex from "../flex/Flex";
import InputBase from "../input/InputBase";
import InputCaracteres from "../input/InputCaracteres";
import RequestButton from "../request-button/RequestButton";
import H from "../titulos/H";
import FormularioDireccionComercial from "./FormularioDireccionComercial";




export interface Props {
  cliente?: Cliente;
  titulo?: string;
  onChangeDatosAfip?: Function;
  onChangeValidation?: Function;
  showSubmitButton?: boolean;
  grupoClienteId: number;
}

interface State {
  datosAfip: EnteEconomicoDTO;
  valido: boolean;
  condicionesIVA: Array<CondicionIVA>;
  cargando: boolean;
  clienteConDireccionComercial: boolean;
}

export default class FormularioAFIP extends Component<Props, State> {

  private clienteService:ClienteService;
  private condicionIVAService:CondicionIVAService;

  static defaultProps = {
    datosAfip: {
      razon_social: "",
      cuit: "",
      calle: "",
      numero: "",
      localidad: -1,
      condicion_iva: -1,
    },
    titulo: "Datos de la Persona",
    onChangeDatosAfip: () => {},
    onChangeValidation: () => {},
    showSubmitButton: true,
  };

  constructor(props: Props) {
    super(props);

    const datosAfip = {
      razon_social: "",
      cuit: "",
      calle: "",
      numero: "",
      localidad: -1,
      condicion_iva: -1,
    };

    this.state = {
      datosAfip: props.cliente || datosAfip,
      valido: false,
      condicionesIVA: [],
      cargando: true,
      clienteConDireccionComercial: false,
    };

    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);
    this.condicionIVAService = new CondicionIVAService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    const valido = this.validar(this.state.datosAfip);
    const condicionesIVA = await this.condicionIVAService.listAll();
    const clienteConDireccionComercial =
      await this.clienteService.clienteConDireccionComercial(
        this.props.grupoClienteId
      );
    this.setState({
      condicionesIVA: condicionesIVA,
      cargando: false,
      valido: valido,
      clienteConDireccionComercial: clienteConDireccionComercial,
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { datosAfip, valido } = this.state;
    const {
      onChangeDatosAfip = FormularioAFIP.defaultProps.onChangeDatosAfip,
      onChangeValidation = FormularioAFIP.defaultProps.onChangeValidation,
    } = this.props;

    if (prevState.datosAfip !== datosAfip) {
      onChangeDatosAfip(datosAfip);
      this.setState({ valido: this.validar(datosAfip) });
    }

    if (prevState.valido !== valido) {
      onChangeValidation(valido);
    }
  }

  getCondicionIVA = (
    condicionesIVA: Array<CondicionIVA>,
    condicionIVAId: number
  ) => {
    return condicionesIVA.filter((cond) => cond.id === condicionIVAId)[0];
  };

  validar(datosAfip: EnteEconomicoDTO) {
    return (
      datosAfip.razon_social !== "" &&
      datosAfip.cuit !== "" &&
      datosAfip.calle !== "" &&
      datosAfip.numero !== "" &&
      datosAfip.localidad > 0 &&
      datosAfip.condicion_iva > 0
    );
  }

  datosAfipStateSetter = (propiedad: string) => {
    return (valor: any) =>
      this.setState({
        datosAfip: {
          ...this.state.datosAfip,
          [propiedad]: valor,
        },
      });
  };

  direccionComercialCallback = (dato: Record<string, any>) => {
    const { datosAfip } = this.state;
    this.setState({ datosAfip: { ...datosAfip, ...dato } });
  };

  setCondicionIVA = (condicionIVA: CondicionIVA) => {
    const { datosAfip } = this.state;
    this.setState({
      datosAfip: { ...datosAfip, condicion_iva: condicionIVA.id },
    });
  };

  handleSubmit = async (cliente: Cliente, datosAfip: EnteEconomicoDTO) => {
    cliente = { ...cliente, ...datosAfip };
    try {
      const clienteResultante = await this.clienteService.update(
        cliente,
        cliente.id
      );
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES,
        {
          clienteId: clienteResultante.id,
        }
      );
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error, "Cliente");
    }
  };

  render() {
    const {
      cliente,
      showSubmitButton = FormularioAFIP.defaultProps.showSubmitButton,
    } = this.props;
    const {
      condicionesIVA,
      datosAfip,
      cargando,
      valido,
      clienteConDireccionComercial,
    } = this.state;
    if (cargando) {
      return null;
    }
    const condicionIVA = this.getCondicionIVA(
      condicionesIVA,
      datosAfip.condicion_iva
    );

    return (
      <Flex container flexDirection="column" width="100%" padding="30px">
        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <H size={3} fuente={Fuentes.Titulos} texto="Datos Afip" />
        </Flex>
        <Form>
          <Form.Group style={{ width: "100%" }}>
            <Form.Label
              style={{ fontFamily: Fuentes.Labels }}
              htmlFor="razonsocial"
            >
              Razón Social
            </Form.Label>
            <InputCaracteres
              id="razonsocial"
              name="razonsocial"
              onChange={this.datosAfipStateSetter("razon_social")}
              value={datosAfip.razon_social}
              placeholder="Razón Social"
            />
          </Form.Group>
          <Form.Group style={{ width: "100%" }}>
            <Form.Label style={{ fontFamily: Fuentes.Labels }} htmlFor="cuit">
              CUIT
            </Form.Label>
            <InputBase
              id="cuit"
              name="cuit"
              onChange={this.datosAfipStateSetter("cuit")}
              value={datosAfip.cuit}
              placeholder="Cuit"
              maxlength="13"
            />
          </Form.Group>
          <Form.Group style={{ width: "100%" }}>
            <Form.Label
              style={{ fontFamily: Fuentes.Labels }}
              htmlFor="condicioniva"
            >
              Condición IVA
            </Form.Label>
            <SelectCondicionIVA
              condicionesIVA={condicionesIVA}
              seleccionado={condicionIVA}
              callbackParent={this.setCondicionIVA}
            />
            {clienteConDireccionComercial && (
              <FormularioDireccionComercial
                localidadId={datosAfip.localidad}
                calle={datosAfip.calle}
                numero={datosAfip.numero}
                onChangeCallback={this.direccionComercialCallback}
              />
            )}
          </Form.Group>
          {showSubmitButton && (
            <Flex
              container
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <RequestButton
                texto={"Guardar cambios"}
                onClick={() => {
                  if (cliente) this.handleSubmit(cliente, datosAfip);
                }}
                propsBoton={{
                  variant: "success",
                  disabled: !valido,
                }}
              />
            </Flex>
          )}
        </Form>
      </Flex>
    );
  }
}
