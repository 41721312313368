import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import RepartoDiarioUpdate from "./RepartoDiarioUpdate";
import RepartoDiarioOrdenVisitasUpdate from "./RepartoDiarioOrdenVisitasUpdate";
import RepartoDiarioVisitasUpdate from "./RepartoDiarioVisitasUpdate";
import CargamentoTabs from "./CargamentoTabs";
import { RepartoDiario } from "serviciossaintmichel";

interface Props {
  id: number;
  recienCreado: boolean;
}

interface State {
  responsive: boolean;
  repartoDiario: RepartoDiario | null;
  cargando: boolean;
}

export default class RepartoDiarioActualizar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      responsive: window.innerWidth < 700 ? true : false,
      repartoDiario: null,
      cargando: true,
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.actualizarDimensiones);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.actualizarDimensiones);
  };

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey={"datosGenerales"}
      transition={false}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "center" : "",
      }}
      unmountOnExit={true}
    >
      <Tab eventKey="datosGenerales" title="Datos Generales">
        <RepartoDiarioUpdate repartoDiarioId={this.props.id} onUpdateSuccess={() => {}} />
      </Tab>
      <Tab eventKey="cargamentos" title="Cargamento">
        <CargamentoTabs idRepartoDiario={this.props.id} />
      </Tab>
      <Tab eventKey="ordenVisitas" title="Orden de Visitas">
        <RepartoDiarioOrdenVisitasUpdate idRepartoDiario={this.props.id} />
      </Tab>
      <Tab eventKey="gestionVisitas" title="Gestión de Visitas">
        <RepartoDiarioVisitasUpdate idRepartoDiario={this.props.id} />
      </Tab>
      <Tab eventKey="rendicion" title="Rendición">
        <div>Rendición del reparto diario</div>
      </Tab>
    </Tabs>
  );

  render() {
    return this.renderizar(this.state.responsive);
  }
}
