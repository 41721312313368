import React, { Component } from "react";
import { DatosRecorrido } from "serviciossaintmichel";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  recorrido: DatosRecorrido;
}
interface State {
  recorrido: DatosRecorrido;
  validation: boolean;
}

export default class RecorridoLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      recorrido: this.props.recorrido,
      validation: false,
    };
  }

  componentDidMount = () => {
    this.updateValidation(this.state.recorrido);
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps != this.props) {
      this.setState({ recorrido: this.props.recorrido });
    }
  };

  isValid = (recorrido: DatosRecorrido) => {
    return recorrido.nombre.length > 0 ? true : false;
  };

  updateValidation = (recorrido: DatosRecorrido) => {
    let validation: boolean = this.isValid(recorrido);
    this.setState({ validation: validation });
    this.props.onValidationChange(validation);
  };

  setearNombre = (nombre: string) => {
    let recorrido = this.state.recorrido;
    recorrido.nombre = nombre;
    this.setState({ recorrido: recorrido });
    this.updateValidation(recorrido);
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Input
          data={this.state.recorrido.nombre}
          label={"Nombre"}
          placeholder={"Nombre"}
          onChange={this.setearNombre}
          type={InputTypes.Text}
        />
      </Flex>
    );
  }
}
