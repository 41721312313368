import React, { Component } from "react";
import Flex from "../../../../../compartido/components/flex/Flex";
import CheckBoxList from "../../../../../compartido/components/checkboxlist/CheckBoxList";
import { Item } from "../../../../../compartido/components/checkboxlist/Item";
import ItemGrupoPermisos from "../../models/ItemGrupoPermisos";
import RequestButton from "../../../../../compartido/components/request-button/RequestButton";

import { CANALES_WEB_SOCKET, EmpleadoGet, EmpleadoPost, EmpleadoService, GrupoPermisos, RelacionEmpleadoArea, RelacionEmpleadoSubArea, TipoResponsabilidad, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";






export interface Props {
  empleado: EmpleadoGet;
}

interface State {
  empleado: EmpleadoGet;

  estadoAdicionarPermisos: boolean;
  estadoQuitarPermisos: boolean;
}

export default class EmpleadoActualizarPermisos extends Component<
  Props,
  State
> {

  private empleadoService:EmpleadoService;

  constructor(props: Props) {
    super(props);

    this.state = {
      empleado: this.props.empleado,

      estadoAdicionarPermisos: false,
      estadoQuitarPermisos: false,
    };

    this.grupos = React.createRef();
    this.gruposActuales = React.createRef();

    this.empleadoService = new EmpleadoService(Global.UsuarioService.getToken()!);

  }

  grupos: any;
  gruposActuales: any;

  componentDidMount() {
    this.empleadoService.getGruposPermisos().then((result) => {
      let lista: Array<Item<GrupoPermisos>> = [];
      let listaActual: Array<Item<GrupoPermisos>> = [];

      if (this.state.empleado.grupos_permisos != undefined) {
        let gruposActuales: Array<GrupoPermisos> =
          this.state.empleado.grupos_permisos;

        for (var i = 0; i < result.length; i++) {
          let aux: boolean = true;
          for (var j = 0; j < gruposActuales.length; j++) {
            if (result[i].id == gruposActuales[j].id) {
              aux = false;
              break;
            }
          }

          if (aux) {
            lista.push(
              new ItemGrupoPermisos(
                result[i].id,
                result[i].name,
                false,
                result[i]
              )
            );
          }
        }

        for (var i = 0; i < gruposActuales.length; i++) {
          listaActual.push(
            new ItemGrupoPermisos(
              gruposActuales[i].id,
              gruposActuales[i].name,
              false,
              gruposActuales[i]
            )
          );
        }
      } else {
        for (var i = 0; i < result.length; i++) {
          lista.push(
            new ItemGrupoPermisos(
              result[i].id,
              result[i].name,
              false,
              result[i]
            )
          );
        }
      }

      this.grupos.current.cargar(lista);
      this.gruposActuales.current.cargar(listaActual);
    });
  }

  isOneSelectedPermisos = (valor: boolean) => {
    this.setState({ estadoAdicionarPermisos: valor });
  };

  adicionarPermisos = () => {
    let aux: Array<GrupoPermisos> = [];

    let itemsSeleccionados: Array<Item<GrupoPermisos>> =
      this.grupos.current.getSeleccionados();

    itemsSeleccionados.forEach((element: Item<GrupoPermisos>) => {
      aux.push({ id: element.id, name: element.nombre });
    });

    return this.empleadoService
      .adicionarPermisos(this.state.empleado.id, aux)
      .then(() => {
        /*
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ADMINISTRACION_PERSONAL_EMPLEADOS, {
          empleadoId: this.state.empleado.id,
        });*/

        this.gruposActuales.current.agregar(itemsSeleccionados);
        this.grupos.current.deleteSeleccionados();

        let items: Array<Item<GrupoPermisos>> =
          this.gruposActuales.current.getItems();
        let grupos_permisos: Array<GrupoPermisos> = [];

        items.forEach((item: Item<GrupoPermisos>) => {
          grupos_permisos.push(item.objeto);
        });

        let empleado: EmpleadoGet = this.state.empleado;
        empleado.grupos_permisos = grupos_permisos;
        this.setState({ empleado: empleado });
      });
  };

  disableAdicionarPermisos = (): boolean => {
    return !this.state.estadoAdicionarPermisos;
  };

  isOneSelectedPermisosActuales = (valor: boolean) => {
    this.setState({ estadoQuitarPermisos: valor });
  };

  quitarPermisos = () => {
    let aux: Array<GrupoPermisos> = [];

    let itemsSeleccionados: Array<Item<GrupoPermisos>> =
      this.gruposActuales.current.getSeleccionados();

    itemsSeleccionados.forEach((element: Item<GrupoPermisos>) => {
      aux.push({ id: element.id, name: element.nombre });
    });

    return this.empleadoService
      .quitarPermisos(this.state.empleado.id, aux)
      .then(() => {
        /*
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ADMINISTRACION_PERSONAL_EMPLEADOS, {
          empleadoId: this.state.empleado.id,
        });*/

        this.grupos.current.agregar(itemsSeleccionados);
        this.gruposActuales.current.deleteSeleccionados();

        let items: Array<Item<GrupoPermisos>> =
          this.gruposActuales.current.getItems();
        let grupos_permisos: Array<GrupoPermisos> = [];

        items.forEach((item: Item<GrupoPermisos>) => {
          grupos_permisos.push(item.objeto);
        });

        let empleado: EmpleadoGet = this.state.empleado;
        empleado.grupos_permisos = grupos_permisos;
        this.setState({ empleado: empleado });
      });
  };

  disableQuitarPermisos = (): boolean => {
    return !this.state.estadoQuitarPermisos;
  };

  render() {
    return (
      <Flex
        container
        flexDirection="row"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex container flexDirection="column" width="450px" padding="15px">
          <Flex
            container
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <div>
              <h5>Permisos</h5>
            </div>
          </Flex>
          <CheckBoxList<GrupoPermisos>
            height="300px"
            width="100%"
            //items={this.state.grupos}
            borde="1px solid grey"
            isOneSelected={this.isOneSelectedPermisos}
            ref={this.grupos}
          />
          <Flex
            container
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            marginTop="15px"
          >
            <RequestButton
              texto="Adicionar"
              msgOnSuccess="Permisos Adicionados con Éxito"
              msgOnFailure="Ocurrio un error al adicionar los permisos"
              propsBoton={{
                variant: "info",
                disabled: this.disableAdicionarPermisos(),
              }}
              onClick={() => this.adicionarPermisos()}
            />
          </Flex>
        </Flex>
        <Flex
          container
          padding="15px"
          flexDirection="column"
          width="450px"
          marginLeft="25px"
        >
          <Flex
            container
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <div>
              <h5>Permisos Actuales</h5>
            </div>
          </Flex>
          <CheckBoxList<GrupoPermisos>
            height="300px"
            width="100%"
            //items={this.state.gruposActuales}
            borde="1px solid grey"
            isOneSelected={this.isOneSelectedPermisosActuales}
            ref={this.gruposActuales}
          />
          <Flex
            container
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            marginTop="15px"
          >
            <RequestButton
              texto="Quitar"
              msgOnSuccess="Permisos Quitados con Éxito"
              msgOnFailure="Ocurrio un error al adicionar los permisos"
              propsBoton={{
                variant: "info",
                disabled: this.disableQuitarPermisos(),
              }}
              onClick={() => this.quitarPermisos()}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  }
}
