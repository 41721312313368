import _ from "lodash";
import React, { Component } from "react";
import { MotivoRechazo, ProgramacionEntregaService } from "serviciossaintmichel";
import { PropsSelectGenerico } from "../../../../compartido/components/select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../../../../compartido/components/select/SelectBusqueda";
import SelectComponent from "../../../../compartido/components/select/SelectComponent";
import { Global } from "../../../../Global";




export interface Props {
  version: Version;
  propsSelect?: PropsSelectGenerico<MotivoRechazo> & PropsSelectGeneral;
  filtrarIds?: Array<number>;
  excluirIds?: Array<number>;
}

export enum Version {
  SELECT,
}

interface State {
  motivosRechazo: Array<MotivoRechazo> | null;
}

export default class MotivosRechazoShow extends Component<Props, State> {

  private programacionEntregaService:ProgramacionEntregaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      motivosRechazo: null,
    };

    this.programacionEntregaService =  new ProgramacionEntregaService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    await this.setData();
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      !_.isEqual(prevProps.filtrarIds, this.props.filtrarIds) ||
      !_.isEqual(prevProps.excluirIds, this.props.excluirIds)
    ) {
      this.setData();
    }
  }

  onChannelMesagge = async (message: MessageEvent) => {
    await this.setData();
  };

  async setData() {
    const { filtrarIds, excluirIds } = this.props;
    let motivosRechazo = await this.programacionEntregaService.getMotivosRechazo();

    if (filtrarIds) {
      motivosRechazo = motivosRechazo.filter((motivo) => filtrarIds.indexOf(motivo.id) !== -1);
    }

    if (excluirIds) {
      motivosRechazo = motivosRechazo.filter((motivo) => excluirIds.indexOf(motivo.id) === -1);
    }

    this.setState({ motivosRechazo: motivosRechazo });
  }

  render() {
    const { version, propsSelect } = this.props;
    const { motivosRechazo } = this.state;

    if (motivosRechazo === null) {
      return <div>Cargando...</div>;
    }

    switch (version) {
      case Version.SELECT: {
        if (!propsSelect) {
          return null;
        }

        return (
          <SelectComponent
            {...propsSelect}
            items={motivosRechazo}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado}
            labelGetter={(obj: MotivoRechazo) => obj.motivo}
          />
        );
      }
      default:
        return null;
    }
  }
}
