import React, { Component } from "react";

import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";
import { CANALES_WEB_SOCKET, HerramientaGet,HerramientaPost,HerramientaService, WebSocketService } from "serviciossaintmichel";

import { Global } from "../../../../Global";

export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  herramientas: Array<HerramientaGet>;
}

export default class HerramientaLista extends Component<Props, State> {

  private herramientaService:HerramientaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      herramientas: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_HERRAMIENTAS,
      funcionCallback: this.getElementos,
    });
    this.herramientaService =  new HerramientaService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.herramientaService.list()
      .then((result) => {
        this.setState({ herramientas: result });
      })
      .catch((error) => {
        this.herramientaService.manejarErrorHTTP(error, "Herramienta");
      });
  };

  componentDidMount() {
    this.herramientaService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }
  armarFilas(herramientas: Array<HerramientaGet>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (herramientas.length > 0) {
      for (let i = 0; i < herramientas.length; i++) {
        let fila: Array<string> = [];
        fila.push(herramientas[i].nombre);
        fila.push(herramientas[i].marca);
        fila.push(herramientas[i].descripcion);
        let filaResult: FilaTablaGenerica = {
          id: herramientas[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Elementos.Herramientas.Herramienta.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Elementos.Herramientas.Herramienta.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.herramientaService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.herramientas)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.herramientaService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaHerramientas"
      />
    );
  }
}
