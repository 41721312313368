import React, { Component, FormEvent } from "react";
import Flex from "../../../../../compartido/components/flex/Flex";

import { Card } from "react-bootstrap";
import TablaAreas from "./TablaAreas";
import TablaSubAreas from "./TablaSubAreas";
import { EmpleadoGet } from "serviciossaintmichel";

export interface Props {
  empleado: EmpleadoGet;
}

interface State {
  empleado: EmpleadoGet;
  alerta: any;
  }

export default class EmpleadoActualizarAreas extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      empleado: this.props.empleado,
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
 
  }

  componentDidMount() {
   //alert("actualizar areas");
  }


  render() {
    return (

      <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >

      <Flex
            container
            flexDirection="column"
            width="90%"
            alignItems="center"
            justifyContent="center"
            marginTop="20px"
          >
        <Card.Title>Areas</Card.Title>

        <TablaAreas empleado={this.state.empleado}/>

      </Flex>

      <Flex
            container
            flexDirection="column"
            width="90%"
            alignItems="center"
            justifyContent="center"
            marginTop="20px"

          >
        <Card.Title>SubAreas</Card.Title>
        <TablaSubAreas empleado={this.state.empleado}/>

      </Flex>


        </Flex>


      
    );
  }
}
