import React, { Component } from "react";
import { Reparto, RepartoService } from "serviciossaintmichel";
import SelectGenericoNull from "../../../compartido/components/select/SelectGenericoNull";
import { Global } from "../../../Global";

interface Props {
  onChangeSelect: Function;
  seleccionado: number | null;
  disabled: boolean;
}
interface State {
  repartos: Array<Reparto>;
  seleccionado: number | null;
}

export default class SelectReparto extends Component<Props, State> {

  private repartoService:RepartoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado,
      repartos: [],
    };

    this.repartoService =  new RepartoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    const repartos = await this.repartoService.listAll().catch((error) => {
      this.repartoService.manejarErrorHTTP(error, "Reparto");
      return null;
    });
    if (repartos != null) {
      this.setState({ repartos: repartos });
    }
  };

  getPosicion = (valor: number): number => {
    return this.state.repartos.findIndex((i) => i.id === valor);
  };

  getSeleccionado = (indice: number) => {
    if (indice >= 0) this.props.onChangeSelect(this.state.repartos[indice].id);
    else this.props.onChangeSelect(null);
  };

  render() {
    if (this.state.repartos.length > 0) {
      return (
        <SelectGenericoNull
          seleccionado={
            this.props.seleccionado
              ? this.getPosicion(this.props.seleccionado)
              : null
          }
          elementos={this.state.repartos.map((i) => i.nombre)}
          callback={this.getSeleccionado}
          disabled={this.props.disabled}
        />
      );
    } else return null;
  }
}
