import React, { Component } from "react";
import { DireccionDTO } from "serviciossaintmichel";
import { Data } from "../../../../../../libreria/data/models/models";
import Select from "../../../../../../libreria/data/select/Select";

interface Props {
  direcciones: Array<DireccionDTO>;
  onSelected: Function;
  seleccionado?: number;
}

interface State {}

export default class SelectDireccionesCliente extends Component<Props, State> {
  crearItemsSelect = (direcciones: Array<DireccionDTO>): Array<Data> => {
    return direcciones.map((dir: DireccionDTO) => ({ id: dir.id, text: `Calle ${dir.calle} - N°${dir.numero}` }));
  };

  render = () => {
    const { direcciones, onSelected, seleccionado } = this.props;
    const dataSelect = this.crearItemsSelect(direcciones);

    return <Select elements={dataSelect} onChange={onSelected} selected={seleccionado} />;
  };
}
