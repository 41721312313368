import React, { Component } from "react";
import { Button, ListGroup } from "react-bootstrap";
import Flex from "../../../../../../compartido/components/flex/Flex";
import * as Icon from "react-bootstrap-icons";
import { DireccionDTO } from "serviciossaintmichel";

interface Props {
  direcciones: Array<DireccionDTO>;
  direccionDetalleId?: number;
  onUpdate?: Function;
  onDelete?: Function;
  onDetail?: Function;
  showDelete?: boolean;
  showUpdate?: boolean;
  showDetail?: boolean;
}

interface State {}

export default class ListDireccionesCliente extends Component<Props, State> {
  render = () => {
    const {
      onUpdate = () => {},
      onDetail = () => {},
      onDelete = () => {},
      showUpdate = false,
      showDelete = false,
      showDetail = false,
      direccionDetalleId,
      direcciones,
    } = this.props;

    if (direcciones.length < 1) {
      return <div> {"El cliente no posee direcciones registradas."}</div>;
    }

    return (
      <ListGroup>
        {direcciones.map((direccion, index) => (
          <ListGroup.Item
            style={{ backgroundColor: direccion.id === direccionDetalleId ? "blanchedalmond" : undefined }}
          >
            <Flex container flexDirection="row" alignItems="center" justifyContent="space-between">
              <div>{index + 1}</div>
              <div>
                <b>Calle</b> {direccion.calle} - <b>N°</b> {direccion.numero}{" "}
                {direccion.tiene_maquinas && <span>(tiene máquinas)</span>}
              </div>
              <div>
                {showUpdate && (
                  <Button style={{ marginRight: "1rem" }} onClick={() => onUpdate(direccion)}>
                    <Icon.Pencil />
                  </Button>
                )}
                {showDelete && (
                  <Button variant="danger" onClick={() => onDelete(direccion.id)}>
                    <Icon.Trash />
                  </Button>
                )}
                {showDetail && direccion.id !== direccionDetalleId && (
                  <Button onClick={() => onDetail(direccion.id)}>
                    <Icon.Eye />
                  </Button>
                )}
              </div>
            </Flex>
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  };
}
