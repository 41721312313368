import React, { Component } from "react";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../libreria/appearance/margin/Margin";
import { Box } from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import BultoProductosPromocionShow from "./bultoProducto/BultoProductoPromocionShow";
import ProductosPromocionShow from "./producto/ProductoPromocionShow";
import { CANALES_WEB_SOCKET, Promocion, PromocionService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";



export interface Props {
  idPromocion: number;
}

interface State {
  promocion: Promocion | null;
  cargando: boolean;
}

export default class PromocionShow extends Component<Props, State> {

  private promocionService:PromocionService;

  constructor(props: Props) {
    
    super(props);
    this.state = {
      promocion: null,
      cargando: true,
    };

    this.promocionService =  new PromocionService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    await this.cargarPromocion();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PROMOCIONES,
      funcionCallback: this.onChannelMesagge,
    });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.promocionId == this.props.idPromocion) {
      await this.cargarPromocion();
    }
  };

  cargarPromocion = async () => {
    let promocion = await this.promocionService.get(this.props.idPromocion).catch(
      (error) => {
        this.promocionService.manejarErrorHTTP(error, "Promocion");
        return null;
      }
    );
    this.setState({ promocion: promocion, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      await this.setState({ cargando: true });
      await this.cargarPromocion();
    }
  };

  renderPromocion = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="10px"
    >
      <H alineacion={"center"} size={3} texto={this.state.promocion!.nombre} />
      <Margin top="25px" />
      {<ProductosPromocionShow productos={this.state.promocion!.productos} />}
      <Margin top="25px" />
      {
        <BultoProductosPromocionShow
          bultoProductos={this.state.promocion!.bulto_productos}
        />
      }
      <Margin bottom="50px" />
    </Flex>
  );
  renderLoading = () => "Cargando Datos";

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Margin top="20px" />
        <Box padding="15px">
          {this.state.cargando ? this.renderLoading() : this.renderPromocion()}
        </Box>
      </Flex>
    );
  }
}
