import React, { Component } from "react";
import { Partido } from "serviciossaintmichel";
import SelectGenerico from "../select/SelectGenerico";

interface Props {
  callbackParent: Function;
  seleccionado?: Partido;
  partidos: Array<Partido>;
}
interface State {
  partidos: Array<Partido>;
  seleccionado: Partido;
}

export default class SelectPartido extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado
        ? this.props.seleccionado
        : this.props.partidos[0],
      partidos: this.props.partidos,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.partidos !== this.props.partidos) {
      this.setState({ partidos: this.props.partidos });
    }
  }

  getPosicion = (valor: Partido): number => {
    return this.state.partidos.findIndex((i) => i.id === valor.id);
  };

  getSeleccionado = (indice: number) => {
    this.props.callbackParent(this.state.partidos[indice]);
  };

  render() {
    return (
      <SelectGenerico
        seleccionado={
          this.props.seleccionado
            ? this.getPosicion(this.props.seleccionado)
            : 0
        }
        elementos={this.state.partidos.map((i) => i.nombre)}
        callback={this.getSeleccionado}
      />
    );
  }
}
