import React, { Component } from "react";
import Ruta from "../../compartido/models/Ruta";
import Seccion from "../../compartido/components/seccion/Seccion";
import { Global } from "../../Global";
import Cupones from "./cupones/Cupones";
import AsignacionCupones from "./cupones/AsignacionCupones";

export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  if (!Global.UsuarioService.getPermisos().Administracion.Cupones.Cupon.hasAccess()) {
    return [];
  }

  return [
    {
      name: "Cupones",
      path: SeccionCupones.pathBase + "/cupones",
      component: Cupones,
    },
    {
      name: "Asignación de cupones",
      path: SeccionCupones.pathBase + "/asignacion-cupones",
      component: AsignacionCupones,
    },
  ];
}

export default class SeccionCupones extends Component<Props> {
  static pathBase = "/administracion/seccion-cupones";

  render() {
    return <Seccion rutasNavbar={getRutasAccesibles()} tituloNavbar={"Sección Cupones"} />;
  }
}
