import React from "react";
import { Select } from "@mantine/core";
import Swal from "sweetalert2";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";
import ManejadorErroresHTTP from "../../../../compartido/utils/ManejadorErroresHTTP";
import Flex from "../../../../libreria/appearance/flex/Flex";
import {     VisitaService,     EstadoVisita, WebSocketService, CANALES_WEB_SOCKET } from "serviciossaintmichel";
import { Global } from "../../../../Global";


export interface Props {
  idVisita: number;
  idRepartoDiario: number;
}

interface State {
  estadoSeleccionado: EstadoVisita | null;
}

export default class VisitaEstadoUpdate extends React.Component<Props, State> {
  private visitaService:VisitaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      estadoSeleccionado: null,
    };
  
    this.visitaService =  new VisitaService(Global.UsuarioService.getToken()!);

  }

  setEstado = (estado: EstadoVisita) => {
    this.setState({ estadoSeleccionado: estado });
  };

  guardarEstado = async () => {
    const { estadoSeleccionado } = this.state;

    if (estadoSeleccionado === null) {
      return;
    }

    try {
      await this.visitaService.agregarEstado(this.props.idVisita, estadoSeleccionado);
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS, {
        idRepartoDiario: this.props.idRepartoDiario,
        idVisita: this.props.idVisita,
      });
      await Swal.fire({
        icon: "success",
        text: "Estado añadido",
      });
    } catch (error) {
      const errorMsg = ManejadorErroresHTTP.getMensajeError(error);
      await Swal.fire({
        icon: "error",
        text: "Error al añadir el estado - " + errorMsg,
      });
    }
  };

  render() {
    const { estadoSeleccionado } = this.state;
    return (
      <Flex gap="1rem">
        <Select
          placeholder="Seleccione estado"
          value={estadoSeleccionado}
          data={[
            { value: EstadoVisita.EN_ESPERA, label: EstadoVisita.EN_ESPERA },
            { value: EstadoVisita.EN_CAMINO, label: EstadoVisita.EN_CAMINO },
            { value: EstadoVisita.NO_ENCONTRADO, label: EstadoVisita.NO_ENCONTRADO },
            { value: EstadoVisita.NO_VISITADO, label: EstadoVisita.NO_VISITADO },
            { value: EstadoVisita.VISITADO, label: EstadoVisita.VISITADO },
          ]}
          onChange={this.setEstado}
        />
        <RequestButton
          propsBoton={{ disabled: estadoSeleccionado === null, style: { marginTop: "1rem" } }}
          texto={"Añadir estado"}
          onClick={this.guardarEstado}
        />
      </Flex>
    );
  }
}
