import React, { Component } from "react";

export default class InputGenerico extends Component {
  render() {
    return (
      <input
        class={"form-control"}
        onKeyUp={(e) => {
          if (e.keyCode === 13 && this.props.callbackOnEnter) this.props.callbackOnEnter(); //13 es Enter
        }}
        onInput={(e) => {
          if (e.currentTarget.value.length == 0 && this.props.callbackOnEmpty) {
            this.props.callbackOnEmpty();
          }
        }}
        {...this.props} //Pisa a todos los demas
        value={this.props.value === null ? "" : this.props.value}
      ></input>
    );
  }
}
