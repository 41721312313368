import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import RepartoDiarioCreacionGrupal from "./RepartoDiarioCreacionGrupal";
import RepartoDiarioNew from "./RepartoDiarioNew";

interface Props {
  onCreacionSimple?: Function;
}

interface State {
  responsive: boolean;
}

export default class RepartoDiarioNuevoTabs extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      responsive: window.innerWidth < 700 ? true : false,
    };

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  renderizar = (responsive: boolean) => {
    const { onCreacionSimple = () => {} } = this.props;
    return (
      <Tabs
        defaultActiveKey={"individual"}
        transition={false}
        variant={responsive ? "pills" : "tabs"}
        style={{
          marginBottom: "1rem",
          flexDirection: responsive ? "column" : "row",
          alignItems: responsive ? "center" : "",
        }}
        unmountOnExit={true}
      >
        <Tab eventKey="individual" title="Creación individual">
          <RepartoDiarioNew onSaveSuccess={onCreacionSimple} />
        </Tab>
        <Tab eventKey="grupal" title="Creación grupal">
          <RepartoDiarioCreacionGrupal />
        </Tab>
      </Tabs>
    );
  };

  render() {
    return this.renderizar(this.state.responsive);
  }
}
