import React, { Component } from "react";
import { Table } from "react-bootstrap";
import EntregaMaquinaProgramacionEntregaLoad from "./EntregaMaquinaProgramacionEntregaLoad";
import { EntregaProgramacionMaquina, EstadoLogisticaPaquete } from "serviciossaintmichel";
import { Select } from "@mantine/core";

interface ConfigCampo {
  disabled: Array<string>;
  hidden: Array<string>;
}

export interface Props {
  crearNuevos?: boolean;
  configCampos?: ConfigCampo;
  entregasMaquina: Array<EntregaProgramacionMaquina>;
  onChange?: Function;
}

interface State {
  entregasMaquina: Array<EntregaProgramacionMaquina>;
}

export default class EntregasMaquinaProgramacionEntregaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entregasMaquina: props.entregasMaquina,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (
      prevProps.entregasMaquina !== this.props.entregasMaquina &&
      this.state.entregasMaquina !== this.props.entregasMaquina
    ) {
      this.setState({ entregasMaquina: this.props.entregasMaquina });
    }

    if (prevState.entregasMaquina !== this.state.entregasMaquina && this.props.onChange) {
      this.props.onChange(this.state.entregasMaquina);
    }
  }

  eliminarEntregaMaquina = (idMaquina: number) => {
    const entregasMaquina = this.state.entregasMaquina.filter(
      (em: EntregaProgramacionMaquina) => em.paquete.maquina.id !== idMaquina
    );
    this.setState({
      entregasMaquina: [...entregasMaquina],
    });
  };

  agregarEntregaMaquina = (nuevaEntregaMaquina: EntregaProgramacionMaquina): void => {
    this.setState((prevState) => ({
      entregasMaquina: [...prevState.entregasMaquina, nuevaEntregaMaquina],
    }));
  };

  actualizarEntregaMaquina = (infoEntrega: EntregaProgramacionMaquina) => {
    const entregas = this.state.entregasMaquina.map((em) =>
      em.paquete.id === infoEntrega.paquete.id ? infoEntrega : em
    );
    this.setState({
      entregasMaquina: entregas,
    });
  };

  estaDeshabilitado = (id: string): boolean => {
    if (!this.props.configCampos) {
      return false;
    }

    return this.props.configCampos.disabled.indexOf(id) !== -1;
  };

  render() {
    const { entregasMaquina } = this.state;
    const { crearNuevos } = this.props;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Máquina</th>
              <th>Cantidad</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {entregasMaquina.map((entregaMaquina, idx) => (
              <tr key={entregaMaquina.paquete.maquina.id}>
                <td>{entregaMaquina.paquete.maquina.nombre}</td>
                <td>{entregaMaquina.paquete.cantidad}</td>
                <td>
                  <Select
                    data={[
                      EstadoLogisticaPaquete.ENTREGADO,
                      EstadoLogisticaPaquete.NO_ENTREGADO,
                      EstadoLogisticaPaquete.RECHAZADO,
                    ]}
                    value={entregaMaquina.estado}
                    onChange={(seleccionado: EstadoLogisticaPaquete) => {
                      this.actualizarEntregaMaquina({
                        ...entregaMaquina,
                        estado: seleccionado,
                      });
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {crearNuevos ? (
          <EntregaMaquinaProgramacionEntregaLoad
            onSubmitCallback={this.agregarEntregaMaquina}
            excluirMaquinas={entregasMaquina.map((vm) => vm.paquete.maquina.id)}
          />
        ) : null}
      </>
    );
  }
}
