import React, { Component } from "react";

import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";


import { ArticuloOficinaGet, ArticuloOficinaPost, ArticuloOficinaService, CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";


export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  articulosOficina: Array<ArticuloOficinaGet>;
}

export default class ArticuloOficinaLista extends Component<Props, State> {

  private articuloOficinaService:ArticuloOficinaService;


  constructor(props: Props) {
    super(props);
    this.state = {
      articulosOficina: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_ARTICULOS_OFICINA,
      funcionCallback: this.getElementos,
    });

    this.articuloOficinaService = new ArticuloOficinaService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.articuloOficinaService.list()
      .then((result) => {
        this.setState({ articulosOficina: result });
      })
      .catch((error) => {
        this.articuloOficinaService.manejarErrorHTTP(error, "Artículo Oficina");
      });
  };

  componentDidMount() {
    this.articuloOficinaService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }
  armarFilas(
    articulosOficina: Array<ArticuloOficinaGet>
  ): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (articulosOficina.length > 0) {
      for (let i = 0; i < articulosOficina.length; i++) {
        let fila: Array<string> = [];
        fila.push(articulosOficina[i].nombre);
        fila.push(articulosOficina[i].marca);
        fila.push(articulosOficina[i].descripcion);
        let filaResult: FilaTablaGenerica = {
          id: articulosOficina[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Elementos.ArticulosOficina.ArticuloOficina.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Elementos.ArticulosOficina.ArticuloOficina.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.articuloOficinaService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.articulosOficina)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.articuloOficinaService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaArticulosOficina"
      />
    );
  }
}
