import React, { Component, FormEvent } from "react";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";

import { CANALES_WEB_SOCKET, UnidadInsumoGet, UnidadInsumoPost, UnidadInsumoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  ocultar: Function;
}

interface State {
  unidad: string;
  alerta: any;
}

export default class UnidadInsumoCrear extends Component<Props, State> {

  private unidadInsumoService:UnidadInsumoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      unidad: "",
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };

    this.unidadInsumoService =  new UnidadInsumoService(Global.UsuarioService.getToken()!);

  }

  ocultar = () => {
    this.props.ocultar();
  };

  handleCreate() {
    this.unidadInsumoService.create({
      unidad: this.state.unidad,
    })
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_UNIDAD_INSUMOS
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Unidad Insumo Creado!",
            titulo: "Atención",
            estado: "block",
          },
        });
        this.setState({ unidad: "" });
      })
      .catch((error) => {
        this.unidadInsumoService.manejarErrorHTTP(error, "Unidad Insumo");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  setearUnidad = (unidad: string) => {
    let unidadInsumo = this.state.unidad;
    unidadInsumo = unidad;
    this.setState({ unidad: unidadInsumo });
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.unidad ? false : true;
  };

  renderizarFormulario() {
    return (
      <div>
        <div className={"row"}>
          <div className={"column"}>
            <h3>{"Nuevo Unidad Insumo"}</h3>
          </div>
        </div>
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="unidad">Nombre de Unidad Insumo</label>
                <InputCaracteres
                  id="unidad"
                  name="unidad"
                  onChange={this.setearUnidad}
                  value={this.state.unidad}
                  placeholder="Ingrese Nombre de UnidadInsumo"
                />
              </div>

              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  ref="boton"
                  type="submit"
                  className={"btn btn-info"}
                  disabled={this.disableButton()}
                >
                  {"Crear"}
                </button>
                <button
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
              <div className={"form-group"}>
                <Alerta
                  tipo={this.state.alerta.tipo}
                  titulo={this.state.alerta.titulo}
                  mensaje={this.state.alerta.mensaje}
                  estado={this.state.alerta.estado}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
