import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Data, Font } from "../models/models";



interface Props {
  checked?: boolean;
  data: Data;
  onChange: Function;
  fontFamily?: string;
}

interface State {
  checked: boolean;
  data: Data;
  fontFamily: string;
}

class CheckBox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      checked: this.props.checked ? this.props.checked : false,
      data: this.props.data,
      fontFamily: this.props.fontFamily ? this.props.fontFamily : Font.family
    };
  }

  componentDidMount() { }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.checked != undefined && this.props.checked == undefined) {
      this.setState({ checked: false });
    }
    else if (prevProps.checked == undefined && this.props.checked != undefined) {
      this.setState({ checked: this.props.checked });
    }
    else if (prevProps.checked != undefined && this.props.checked != undefined) {
      if (prevProps.checked != this.props.checked)
        this.setState({ checked: this.props.checked! })
    }
    else { }

    if (prevProps.data.id != this.props.data.id || prevProps.data.text != this.props.data.text)
      this.setState({ data: this.props.data })

  }

  onChange = () => {
    let x = this.state.checked;
    x = !x;
    this.setState({ checked: x })
    this.props.onChange(this.state.data.id, x);
  };

  render() {
    return (
      <Form.Check checked={this.state.checked} onChange={this.onChange} type="checkbox" label={this.state.data.text} style={{ fontFamily: this.state.fontFamily }} />
    );
  }
}

const Estilos = {};

export default CheckBox;
