import React, { Component, FormEvent } from "react";
import InputDecimal from "../../../../compartido/components/input/InputDecimal";
import Habilitador from "../../../../compartido/components/habilitador/Habilitador";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";
import SelectProducto from "../../Producto/components/SelectProducto";
import { Table, Col, Row, Form, Container } from "react-bootstrap";

import { BultoProductoGet, BultoProductoService, CANALES_WEB_SOCKET,  InsumoService, ProductoGet, ProductoService, RelacionBultoProductoProducto, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";


export interface Props {
  bultoProductoId: number;
}

interface State {
  bultoProducto: BultoProductoGet;
  productosCandidatos: Array<ProductoGet>;
  alerta: any;
  cantidad: number;
  producto: ProductoGet | null;
}

export default class ProductoActualizarProductos extends Component<Props,State> 
{


  private bultoProductoService:BultoProductoService;
  private productoService:ProductoService;


  constructor(props: Props) {
    super(props);
    this.state = {
      bultoProducto: this.crearBultoProductoVacio(),
      productosCandidatos: [],
      cantidad: 0,
      producto: null,
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS,
      funcionCallback: this.cargarProductos,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS,
      funcionCallback: this.cargarBultoProductoCanal,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_BULTO_PRODUCTOS,
      funcionCallback: this.cargarBultoProductoCanal,
    });



    this.bultoProductoService =  new BultoProductoService(Global.UsuarioService.getToken()!);


    this.productoService =  new ProductoService(Global.UsuarioService.getToken()!);



  }

  async componentDidMount() {
    await this.cargarBultoProducto(this.props.bultoProductoId);
    this.cargarProductos();
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      await this.cargarBultoProducto(this.props.bultoProductoId);
      this.cargarProductos();
    }
  }

  crearBultoProductoVacio(): BultoProductoGet {
    const bultoProducto: BultoProductoGet = {
      id: 0,
      nombre: "",
      insumos: [],
      productos: [],
    };
    return bultoProducto;
  }

  cargarBultoProducto = async (id: number) => {
    const resBultoProducto = await this.bultoProductoService.get(id).catch(
      (error) => {
        this.bultoProductoService.manejarErrorHTTP(error, "Bulto Producto");
        return null;
      }
    );
    if (resBultoProducto != null) {
      this.setState({
        bultoProducto: resBultoProducto,
        cantidad: 0,
        alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
      });
    }
  };

  cargarBultoProductoCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.bultoProducto &&
      dato.message.bultoProductoId == this.state.bultoProducto.id
    ) {
      await this.cargarBultoProducto(this.props.bultoProductoId);
      this.cargarProductos();
    }
    if (
      this.state.bultoProducto &&
      this.state.bultoProducto.productos.find(
        (i) => i.producto.id === dato.message.productoId
      )
    ) {
      await this.cargarBultoProducto(this.props.bultoProductoId);
      this.cargarProductos();
    }
  };

  cargarProductos = async () => {
    let productos = await this.productoService.listAll().catch((error) => {
      this.productoService.manejarErrorHTTP(error, "Producto");
      return null;
    });
    if (productos != null) {
      this.actualizarProductosCandidatos(productos);
    }
  };

  actualizarProductosCandidatos = (productos: Array<ProductoGet>) => {
    const relacionados = this.state.bultoProducto.productos.map(
      (relacion) => relacion.producto.id
    );
    let productosCandidatos = productos.filter(
      (producto) => !relacionados.includes(producto.id)
    );
    this.setState({
      productosCandidatos: productosCandidatos,
      producto: productosCandidatos[0],
    });
  };

  setearCantidad = (cantidad: number) => {
    this.setState({ cantidad: cantidad });
  };

  setearProducto = (producto: ProductoGet) => {
    this.setState({ producto: producto });
  };

  eliminarProducto = (producto: RelacionBultoProductoProducto) => {
    this.bultoProductoService.eliminarRelacionConProducto(
      this.state.bultoProducto.id,
      producto.producto.id
    )
      .then(() => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_BULTO_PRODUCTOS,
          {
            bultoProductoId: this.state.bultoProducto.id,
          }
        );
      })
      .catch((error) => {
        this.bultoProductoService.manejarErrorHTTP(error, "Bulto Producto");
      });
  };

  agregarProducto = (producto: ProductoGet, cantidad: number) => {
    this.bultoProductoService.relacionarConProducto(
      this.state.bultoProducto.id,
      producto.id,
      cantidad
    )
      .then(() => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_BULTO_PRODUCTOS,
          {
            bultoProductoId: this.state.bultoProducto.id,
          }
        );
      })
      .catch((error) => {
        this.bultoProductoService.manejarErrorHTTP(error, "Bulto Producto");
      });
  };

  actualizarProducto = (relacionProducto: RelacionBultoProductoProducto) => {
    return this.bultoProductoService.actualizarRelacionConProducto(
      this.state.bultoProducto.id,
      relacionProducto.producto.id,
      relacionProducto.cantidad
    )
      .then(() => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_BULTO_PRODUCTOS,
          {
            bultoProductoId: this.state.bultoProducto.id,
          }
        );
      })
      .catch((error) => {
        this.bultoProductoService.manejarErrorHTTP(error, "Bulto Producto");
      });
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.producto &&
      this.state.cantidad &&
      this.state.cantidad != 0
      ? false
      : true;
  };

  renderizarFormulario = () => {
    if (this.state.productosCandidatos.length < 1) {
      return null;
    }
    return (
      <Form>
        <Form.Group>
          <Form.Label>Cantidad</Form.Label>
          <InputDecimal
            id="cantidad"
            name="cantidad"
            onChange={this.setearCantidad}
            value={this.state.cantidad}
            cantEnteros={3}
            cantDecimales={3}
            placeholder={"Ingrese cantidad"}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Producto</Form.Label>
          <SelectProducto
            productos={this.state.productosCandidatos}
            seleccionado={
              this.state.producto
                ? this.state.producto
                : this.state.productosCandidatos[0]
            }
            callbackParent={(producto: ProductoGet) => {
              this.setearProducto(producto);
            }}
          />
        </Form.Group>
        <Form.Group>
          <RequestButton
            texto="Añadir"
            propsBoton={{
              variant: "info",
              disabled: !(!!this.state.producto && this.state.cantidad != 0),
            }}
            onClick={() => {
              if (this.state.producto && this.state.cantidad)
                return this.agregarProducto(
                  this.state.producto,
                  this.state.cantidad
                );
            }}
          />
        </Form.Group>
      </Form>
    );
  };

  renderizarFilas = () => {
    return this.state.bultoProducto.productos.map((relacion, index) => {
      return (
        <tr>
          <td>{index}</td>
          <td>{relacion.producto.nombre}</td>
          <td>{relacion.producto.tipo_producto.tipo}</td>
          <td>
            <Habilitador>
              <InputDecimal
                id={"cantidad" + index}
                name="cantidad"
                onChange={(valor: number) => {
                  let p = this.state.bultoProducto;
                  let l = p.productos;
                  let i = l[index];
                  i.cantidad = valor;
                  this.setState({ bultoProducto: p });
                }}
                value={relacion.cantidad}
                cantEnteros={3}
                cantDecimales={3}
                placeholder={"Ingrese cantidad"}
              />
            </Habilitador>
          </td>
          <td>
            <RequestButton
              texto="Actualizar"
              propsBoton={{
                variant: "info",
                disabled:
                  relacion.cantidad && relacion.cantidad != 0 ? false : true,
              }}
              onClick={() => this.actualizarProducto(relacion)}
            />
          </td>
          <td>
            <RequestButton
              texto="Eliminar"
              confirmacion={true}
              propsBoton={{
                variant: "danger",
              }}
              onClick={() => this.eliminarProducto(relacion)}
            />
          </td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true}>
        <thead>
          <tr>
            <th>#</th>
            <th>Producto</th>
            <th>Tipo Producto</th>
            <th>Cantidad</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  render() {
    if (this.state.bultoProducto.id != 0) {
      return (
        <Container>
          <Row>
            <Col md={{ span: 6 }}>{this.renderizarFormulario()}</Col>
          </Row>
          <Row>
            <Col>{this.renderizarTabla()}</Col>
          </Row>
        </Container>
      );
    } else return null;
  }
}
