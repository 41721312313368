import React, { Component } from "react";
import ProductoActualizarPropio from "./ProductoActualizarPropio";
import ProductoActualizarInsumos from "./ProductoActualizarInsumos";
import Flex from "../../../../compartido/components/flex/Flex";
import { Tabs, Tab } from "react-bootstrap";

interface Props {
  productoId: number;
  recienCreado: boolean;
}

interface State {
  responsive: boolean;
}

export default class Productos extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      responsive: window.innerWidth < 700 ? true : false,
    };

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey={this.props.recienCreado ? "insumos" : "producto"}
      transition={false}
      variant="pills"
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "center" : "",
      }}
      unmountOnExit={true}
    >
      <Tab eventKey="producto" title="Producto">
        <ProductoActualizarPropio productoId={this.props.productoId} />
      </Tab>
      <Tab eventKey="insumos" title="Insumos del producto">
        <ProductoActualizarInsumos productoId={this.props.productoId} />
      </Tab>
    </Tabs>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {this.renderizar(this.state.responsive)}
      </Flex>
    );
  }
}
