import React, { Component } from "react";
import { PropsSelectGeneral } from "../../../../../compartido/components/select/SelectBusqueda";
import { PropsSelectGenerico } from "../../../../../compartido/components/select/PropsSelectGenerico";
import { LIMITES } from "../../../../../compartido/components/select/SelectLimiteTabla";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import PedidosShowSelect from "./PedidosShowSelect";
import PedidosShowTable from "./PedidosShowTable";

import {
  CANALES_WEB_SOCKET,
  WebSocketService,
  Pedido,
  PedidoService,
  getColorEstado,
} from "serviciossaintmichel";
import { Global } from "../../../../../Global";

export interface Props {
  clienteId?: number;
  onUpdate?: Function;
  onView?: Function;
  version: Version;
  propsSelect?: PropsSelectGenerico<Pedido> & PropsSelectGeneral;
}

export enum Version {
  SELECT,
  TABLE,
}

interface State {
  pedidos: Array<Pedido>;
}

export default class PedidosShow extends Component<Props, State> {
  private pedidoService: PedidoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      pedidos: [],
    };

    this.pedidoService = new PedidoService(Global.UsuarioService.getToken()!);
  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PEDIDOS,
      funcionCallback: this.getPedidos,
    });
    if (this.props.version == Version.TABLE)
      this.pedidoService.paramsURL.setearLimite(LIMITES[0]);
    this.getPedidos();
  }

  getPedidos = () => {
    if (this.props.clienteId) {
      this.pedidoService
        .getPedidos({
          clienteId: this.props.clienteId,
          desde: null,
          hasta: null,
          cliente: null,
          cuit: null,
          estados: null,
          opcionEstado: null,
        })
        .then((result) => {
          this.setState({ pedidos: result });
        })
        .catch((error) => {
          this.pedidoService.manejarErrorHTTP(error, "Pedido");
        });
    } else {
      this.pedidoService
        .listAll()
        .then((result) => {
          this.setState({ pedidos: result });
        })
        .catch((error) => {
          this.pedidoService.manejarErrorHTTP(error, "Pedido");
        });
    }
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems={Version.TABLE ? undefined : "center"}
        justifyContent="center"
      >
        {this.props.version == Version.SELECT && this.props.propsSelect ? (
          <PedidosShowSelect
            {...this.props.propsSelect}
            callbackParent={this.props.propsSelect.callbackParent}
            seleccionado={
              this.props.propsSelect.seleccionado
                ? this.props.propsSelect.seleccionado
                : null
            }
            items={this.state.pedidos}
          />
        ) : null}
        {this.props.version == Version.TABLE ? (
          <PedidosShowTable onView={this.onView} onUpdate={this.onUpdate} />
        ) : null}
      </Flex>
    );
  }
}
