import React, { Component } from "react";
import { Redirect, Switch } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Ruta from "../../models/Ruta";
import PrivateRoute from "../../../PrivateRoute";
import AlertaError from "../errors/AlertaError";

export interface Props {
  rutasNavbar: Array<Ruta>;
  tituloNavbar?: string;
  tituloSeccion?: string;
}

export default class Seccion extends Component<Props> {
  getRutasNavbar = (rutas: Array<Ruta>) => {
    if (rutas.length < 1) {
      return [];
    }

    let componentes = [];

    for (let ruta of rutas) {
      componentes.push(<PrivateRoute key={ruta.path} path={ruta.path} component={ruta.component} />);
    }
    componentes.push(<Redirect key={"/"} from="/" to={rutas[0].path} />);

    return componentes;
  };

  renderizarTituloSeccion = () => {
    if (!this.props.tituloSeccion) {
      return null;
    }
    return (
      <div className="row">
        <h1>{this.props.tituloSeccion}</h1>
      </div>
    );
  };

  render() {
    return (
      <div id="content">
        <AlertaError />
        {this.renderizarTituloSeccion()}
        <div>
          <Navbar rutas={this.props.rutasNavbar} titulo={this.props.tituloNavbar} />
        </div>
        <div>
          <Switch>{this.getRutasNavbar(this.props.rutasNavbar)}</Switch>
        </div>
      </div>
    );
  }
}
