import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Flex from "../flex/Flex";
import InputBase from "../input/InputBase";
import ComponenteDatoGeografico from "./ComponenteDatoGeografico";
import SelectTipoLugar from "./SelectTipoLugar";
import InputDigitos from "../input/InputDigitos";
import ComponenteLocalizacion2 from "./ComponenteLocalizacion2";
import ComponenteZonas2 from "./ComponenteZonas2";
import { Direccion, DireccionDTO, Localidad, Localizacion, TipoLugar, TipoLugarService } from "serviciossaintmichel";
import { Global } from "../../../Global";



interface Props {
  direccion: DireccionDTO | null;
  onChangeDireccion?: Function;
  onChangeValidation?: Function;
}

interface State {
  direccion: DireccionDTO;
  estado: boolean;
  tiposLugar: Array<TipoLugar>;
  cargando: boolean;
  tipoLugarSeleccionado: TipoLugar | null;
}

export default class DireccionLoad extends Component<Props, State> {
  private localizador: any;
  private zonas: any;

  private tipoLugarService:TipoLugarService;

  constructor(props: Props) {
    super(props);

    this.state = {
      direccion: props.direccion || this.crearDireccion(),
      estado: false,
      tiposLugar: [],
      cargando: true,
      tipoLugarSeleccionado: null,
    };
    this.localizador = React.createRef();
    this.zonas = React.createRef();

    this.tipoLugarService = new TipoLugarService(Global.UsuarioService.getToken()!);

  }

  crearDireccion(): DireccionDTO {
    return {
      id: -1,
      calle: "",
      numero: "",
      entre_1: null,
      entre_2: null,
      departamento: null,
      piso: null,
      localizacion: null,
      localidad: -1,
      tipo_lugar: -1,
      zonas: [],
      descripcion: null,
    };
  }

  componentDidMount = async () => {
    this.updateValidacion();
    // TODO deberia hacerlo este componente la carga de los tipos lugar?
    const tiposLugar = await this.cargarTiposLugar();
    if (tiposLugar != null) {
      const tipoLugarSeleccionado =
        tiposLugar.find((tipoLugar) => tipoLugar.id === this.state.direccion.tipo_lugar) || null;
      this.setState({ tiposLugar: tiposLugar, cargando: false, tipoLugarSeleccionado: tipoLugarSeleccionado });
    }
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { estado, direccion, tiposLugar } = this.state;
    const { onChangeValidation, onChangeDireccion } = this.props;

    if (prevProps.direccion !== this.props.direccion && this.props.direccion !== null) {
      if (JSON.stringify(this.props.direccion) !== JSON.stringify(direccion)) {
        const tipoLugarSeleccionado =
          tiposLugar.find((tipoLugar) => tipoLugar.id === this.props.direccion?.tipo_lugar) || null;
        this.setState({
          direccion: this.props.direccion,
          tiposLugar: tiposLugar,
          tipoLugarSeleccionado: tipoLugarSeleccionado,
        });
      }
    }

    if (prevState.estado !== estado) {
      if (onChangeValidation) {
        onChangeValidation(estado);
      }
    }

    if (prevState.direccion !== direccion) {
      this.updateValidacion();
      if (onChangeDireccion) {
        onChangeDireccion(direccion);
      }
    }
  };

  cargarTiposLugar = async () => {
    return await this.tipoLugarService.listAll().catch((error) => {
      this.tipoLugarService.manejarErrorHTTP(error, "Tipo Lugar");
      return null;
    });
  };

  esValido = (): boolean => {
    const { direccion } = this.state;
    return (
      direccion.calle !== "" && direccion.numero !== "" && direccion.localizacion !== null && direccion.tipo_lugar > -1
    );
  };

  updateValidacion = () => {
    let validacion = this.esValido();
    this.setState({ estado: validacion });
  };

  setearCalle = async (calle: string) => {
    await this.setState({ direccion: { ...this.state.direccion, calle: calle } });
    this.localizar();
  };

  setearNumero = async (numero: string) => {
    await this.setState({ direccion: { ...this.state.direccion, numero: numero } });
    this.localizar();
  };

  setearEntre1 = (entre_1: string) => {
    this.setState({ direccion: { ...this.state.direccion, entre_1: entre_1 } });
  };

  setearEntre2 = (entre_2: string) => {
    this.setState({ direccion: { ...this.state.direccion, entre_2: entre_2 } });
  };

  setearDepartamento = (departamento: string) => {
    this.setState({ direccion: { ...this.state.direccion, departamento: departamento } });
  };

  setearPiso = (piso: number) => {
    this.setState({ direccion: { ...this.state.direccion, piso: piso } });
  };

  setearTipoLugar = (tipo_lugar: TipoLugar) => {
    this.setState({
      direccion: { ...this.state.direccion, tipo_lugar: tipo_lugar ? tipo_lugar.id : -1 },
      tipoLugarSeleccionado: tipo_lugar,
    });
  };

  setearDescripcion = (descripcion: string) => {
    this.setState({ direccion: { ...this.state.direccion, descripcion: descripcion } });
  };

  // Idea : estado direccion , estado dato geografico ---> startLocalizar()
  // estado localizar ---> startZonas()

  // Componente que se encargue de la lógica del dato geográfico
  // Componente que se encargue de la localizacion

  // Componente que se encargue de las zonas

  setearLocalidad = async (localidad: Localidad) => {
    await this.setState({ direccion: { ...this.state.direccion, localidad: localidad.id } });
    this.localizar();
  };

  setearLocalizacion = (localizacion: Localizacion | null) => {
    this.setState({ direccion: { ...this.state.direccion, localizacion: localizacion } });

    if (localizacion != null) {
      this.zonas.current.buscarZonasLocalizacion(localizacion);
    }
  };

  setearZonas = (zonas: Array<number>) => {
    this.setState({ direccion: { ...this.state.direccion, zonas: zonas } });
  };

  localizar = () => {
    if (this.localizador.current) {
      this.localizador.current.geoLocalizar(this.state.direccion);
    }
  };

  getDireccionDTO = (direccion: Direccion): DireccionDTO => {
    const zonasIds = direccion.zonas ? direccion.zonas.map((zona) => zona.id) : [];
    const tipoLugarId = direccion.tipo_lugar || -1;
    return { ...direccion, localidad: direccion.localidad.id, zonas: zonasIds, tipo_lugar: tipoLugarId };
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return !this.state.estado;
  };

  render() {
    const { tiposLugar, cargando, tipoLugarSeleccionado, direccion, estado } = this.state;

    if (cargando) {
      return null;
    }

    return (
      <Flex container flexDirection="column">
        <Flex container flexDirection="row" alignItems="center" justifyContent="center">
          <div>
            <h3>Dirección</h3>
          </div>
        </Flex>
        {estado === false ? (
          <Flex container flexDirection="row" alignItems="center" justifyContent="center">
            <div>
              <p>Faltan completar datos de la dirección</p>
            </div>
          </Flex>
        ) : null}
        <Flex marginTop="15px" container flexDirection="row" alignItems="center" justifyContent="center">
          <Flex container flexDirection="column" width="47.5%" alignItems="center">
            <Form.Group style={{ width: "100%" }}>
              <Form.Label htmlFor="calle">Calle</Form.Label>
              <InputBase
                id="calle"
                name="calle"
                onChange={this.setearCalle}
                value={direccion.calle}
                placeholder="Calle"
              />
            </Form.Group>
          </Flex>

          <Flex container flexDirection="column" width="5%"></Flex>

          <Flex container flexDirection="column" width="47.5%">
            <Form.Group style={{ width: "100%" }}>
              <Form.Label htmlFor="numero">Numero</Form.Label>
              <InputBase
                id="numero"
                name="numero"
                onChange={this.setearNumero}
                value={direccion.numero}
                placeholder="Número"
              />
            </Form.Group>
          </Flex>
        </Flex>
        <Flex container flexDirection="row" alignItems="center" justifyContent="center">
          <Flex container flexDirection="column" width="47.5%" alignItems="center">
            <Form.Group style={{ width: "100%" }}>
              <label htmlFor="entre1">Entre1</label>
              <InputBase
                id="entre1"
                name="entre1"
                onChange={this.setearEntre1}
                value={direccion.entre_1}
                placeholder="Entre1"
              />
            </Form.Group>
          </Flex>

          <Flex container flexDirection="column" width="47.5%" alignItems="center" marginLeft="5%">
            <Form.Group style={{ width: "100%" }}>
              <label htmlFor="entre2">Entre2</label>
              <InputBase
                id="entre2"
                name="entre2"
                onChange={this.setearEntre2}
                value={direccion.entre_2}
                placeholder="Entre2"
              />
            </Form.Group>
          </Flex>
        </Flex>

        <Flex container flexDirection="row" alignItems="center" justifyContent="center">
          <Flex container flexDirection="column" width="47.5%" alignItems="center">
            <Form.Group style={{ width: "100%" }}>
              <label htmlFor="departamento">Departamento</label>
              <InputBase
                id="departamento"
                name="departamento"
                onChange={this.setearDepartamento}
                value={direccion.departamento}
                placeholder="Departamento"
              />
            </Form.Group>
          </Flex>

          <Flex container flexDirection="column" width="47.5%" alignItems="center" marginLeft="5%">
            <Form.Group style={{ width: "100%" }}>
              <label htmlFor="piso">Piso</label>
              <InputDigitos
                id="piso"
                name="piso"
                onChange={this.setearPiso}
                value={direccion.piso}
                placeholder="Piso"
              />
            </Form.Group>
          </Flex>
        </Flex>

        <Flex container flexDirection="column" alignItems="center" justifyContent="flex-start">
          <Form.Group style={{ width: "100%" }}>
            <label htmlFor="tipolugar">Tipo de Lugar</label>
            <SelectTipoLugar
              tiposLugar={tiposLugar}
              seleccionado={tipoLugarSeleccionado}
              onChange={(tipoLugar: TipoLugar) => {
                this.setearTipoLugar(tipoLugar);
              }}
            />
          </Form.Group>
        </Flex>

        <Flex container flexDirection="column" alignItems="center" justifyContent="flex-start">
          <Form.Group style={{ width: "100%" }}>
            <label htmlFor="descripcion">Descripción</label>
            <InputBase
              id="descripcion"
              name="descripcion"
              onChange={this.setearDescripcion}
              value={direccion.descripcion}
              placeholder="Descripcion de la dirección"
            />
          </Form.Group>
        </Flex>

        <ComponenteDatoGeografico onChange={this.setearLocalidad} localidadId={direccion.localidad} />
        <br />

        <ComponenteLocalizacion2 ref={this.localizador} direccion={direccion} onChange={this.setearLocalizacion} />
        <br />

        <ComponenteZonas2 ref={this.zonas} zonasId={direccion.zonas} onChange={this.setearZonas} />
      </Flex>
    );
  }
}
