import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import Indumentarias from "./Indumentaria/components/Indumentarias";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];
  
  if (Global.UsuarioService.getPermisos().Elementos.Indumentarias.hasAccess()) {
    aux.push({
      name: "Indumentarias",
      path: SeccionIndumentarias.pathBase + "/indumentarias",
      component: Indumentarias,
    })
  }
  return aux;
}

export default class SeccionIndumentarias extends Component<Props> {
  static pathBase = "/elementos/seccion-indumentarias";
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Indumentarias"}
      />
    );
  }
}
