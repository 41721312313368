import React, { Component } from "react";
import { DatosConsumo } from "serviciossaintmichel";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../../libreria/data/input/Input";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  consumo: DatosConsumo;
}
interface State {
  consumo: DatosConsumo;
  validation: boolean;
}

export default class ConsumoLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      consumo: this.props.consumo,
      validation: false,
    };
  }

  componentDidMount = () => {
    this.updateValidation(this.state.consumo);
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps != this.props) {
      this.setState({ consumo: this.props.consumo });
    }
  };

  isValid = (consumo: DatosConsumo) => {
    return consumo.nombre.length > 0 ? true : false;
  };

  updateValidation = (consumo: DatosConsumo) => {
    let validation: boolean = this.isValid(consumo);
    this.setState({ validation: validation });
    this.props.onValidationChange(validation);
  };

  onChange = (nombre: string) => {
    let consumo = this.state.consumo;
    consumo.nombre = nombre;
    this.setState({ consumo: consumo });
    this.updateValidation(consumo);
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Input
          data={this.state.consumo.nombre}
          label={"Nombre"}
          placeholder={"Nombre"}
          onChange={this.onChange}
          type={InputTypes.Text}
        />
      </Flex>
    );
  }
}
