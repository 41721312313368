import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";



import { BultoProductoGet,  BultoProductoPost,  BultoProductoService, CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  ocultar: Function;
  callbackParent?: Function;
}

interface State {
  bultoProducto: BultoProductoPost;
}

export default class ProductoCrear extends Component<Props, State> {

  private bultoProductoService:BultoProductoService;


  constructor(props: Props) {
    super(props);
    this.state = {
      bultoProducto: this.bultoProductoCrear(),
    };

    this.bultoProductoService =  new BultoProductoService(Global.UsuarioService.getToken()!);


  }

  async componentDidMount() {
    const bultoProductoCrear: BultoProductoPost = this.state.bultoProducto;
    this.setState({
      bultoProducto: bultoProductoCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  bultoProductoCrear(): BultoProductoPost {
    return {
      nombre: "",
    };
  }
  setearNombre = (nombre: string) => {
    let bultoProducto = this.state.bultoProducto;
    bultoProducto.nombre = nombre;
    this.setState({ bultoProducto: bultoProducto });
  };

  handleCreate() {
    return this.bultoProductoService.create(this.state.bultoProducto)
      .then((bultoProducto) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_BULTO_PRODUCTOS
        );
        if (this.props.callbackParent) {
          this.props.callbackParent(bultoProducto.id);
        } else {
          const bultoProductoCrear: BultoProductoPost =
            this.bultoProductoCrear();
          this.setState({
            bultoProducto: bultoProducto,
          });
        }
      })
      .catch((error) => {
        this.bultoProductoService.manejarErrorHTTP(error, "Bulto Producto");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.bultoProducto.nombre ? false : true;
  };

  renderizarFormulario() {
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="nombre">Nombre</label>
                <InputCaracteres
                  id="nombre"
                  name="nombre"
                  onChange={this.setearNombre}
                  value={this.state.bultoProducto.nombre}
                  placeholder="Ingrese Nombre de Producto"
                />
              </div>
              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <RequestButton
                  texto="Crear"
                  msgOnSuccess="Producto creado con exito"
                  msgOnFailure="Ocurrio un error al crear el producto"
                  propsBoton={{
                    variant: "info",
                    disabled: this.disableButton(),
                  }}
                  onClick={() => this.handleCreate()}
                />
                <button
                  type={"reset"}
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
