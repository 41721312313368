import _ from "lodash";
import React, { Component } from "react";

import { CANALES_WEB_SOCKET, CriterioOrden, WebSocketService, PagoService, Pago } from "serviciossaintmichel";
import { Global } from "../../../Global";
import { Loader } from "@mantine/core";
import { criteriosOrdenIguales } from "../../../compartido/utils/OrdenUtils";
import PagosFilter from "./PagosFilter";
import TablaPagos from "./TablaPagos";
import { FiltrosPagos } from "serviciossaintmichel/lib/apisaintmichel/ventas/pagos/pagos/services/PagoService";
import { formatISO } from "date-fns";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
  filtros?: FiltrosPagos;
  criterioOrden?: CriterioOrden;
  limite?: number;
  version: VersionPagosShow;
}

export type VersionPagosShow = "tabla";

interface State {
  pagos: Array<Pago> | null;
  filtros: FiltrosPagos;
  criterioOrden: CriterioOrden | null;
  limite: number;
}

const ordenInicial: CriterioOrden = { campo: "id", modo: "ASC" };
const PASO_LIMITE = 5;

export default class PagosShow extends Component<Props, State> {
  private webSocket: WebSocket | null;

  private pagoService: PagoService;

  constructor(props: Props) {
    super(props);

    this.state = {
      pagos: null,
      filtros: props.filtros ?? {
        desde: null,
        hasta: null,
        pedidoId: null,
        facturaId: null,
        clienteId: null,
        tipo: null,
        tipoPagoId: null,
        fuente: null,
      },
      criterioOrden: props.criterioOrden ?? ordenInicial,
      limite: props.limite ?? PASO_LIMITE,
    };

    this.webSocket = null;
    this.pagoService = new PagoService(Global.UsuarioService.getToken()!);
  }

  componentWillUnmount = () => {
    this.webSocket?.close();
  };

  componentDidMount = () => {
    this.setPagos();
    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PAGOS,
      funcionCallback: this.setPagos,
    });
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevState.criterioOrden !== this.state.criterioOrden || prevState.limite !== this.state.limite) {
      this.setPagos();
    }

    if (!_.isEqual(prevProps.filtros, this.props.filtros)) {
      this.setState((prevState) => ({
        filtros: {
          ...prevState.filtros,
        },
        criterioOrden: prevState.criterioOrden ?? ordenInicial,
        limite: prevState.limite ?? PASO_LIMITE,
      }));
    }
  };

  setPagos = async () => {
    const { filtros, limite, criterioOrden } = this.state;

    try {
      const params = new URLSearchParams({ limite: limite.toString() });

      if (filtros.desde) {
        params.append("desde", formatISO(filtros.desde, { representation: "date" }));
      }

      if (filtros.hasta) {
        params.append("hasta", formatISO(filtros.hasta, { representation: "date" }));
      }

      if (filtros.tipoPagoId) {
        params.append("tipo_pago_id", filtros.tipoPagoId.toString());
      }

      if (filtros.fuente) {
        params.append("fuente", filtros.fuente);
      }

      if (criterioOrden) {
        const orden = criterioOrden.modo === "DESC" ? `-${criterioOrden.campo}` : criterioOrden.campo;
        params.append("orden", orden);
      }

      const pagos: Array<Pago> = await this.pagoService.list(params);

      this.setState({ pagos: pagos });
    } catch (error) {
      this.pagoService.manejarErrorHTTP(error, "Pagos");
    }
  };

  seleccionarCriterio = (criterio: CriterioOrden | null) => {
    const { criterioOrden } = this.state;

    if (criterioOrden === null || !criteriosOrdenIguales(criterioOrden, criterio)) {
      this.setState({ criterioOrden: criterio });
    } else {
      this.setState((prevState) => ({ filtros: { ...prevState.filtros, criterioOrden: null } }));
    }
  };

  aumentarLimite = () => {
    this.setState((prevState) => ({
      limite: prevState.limite + PASO_LIMITE,
    }));
  };

  setFiltros = (valor: Record<string, any>) => {
    this.setState((prevState) => ({
      filtros: {
        ...prevState.filtros,
        ...valor,
      },
    }));
  };

  render = () => {
    const { pagos, filtros, criterioOrden } = this.state;
    const { version, onView } = this.props;

    if (pagos === null) {
      return <Loader />;
    }

    const componentes = {
      tabla: (
        <div>
          <PagosFilter setFiltros={this.setFiltros} busquedaHandler={this.setPagos} filtros={filtros} />
          <TablaPagos
            onView={onView}
            pagos={pagos}
            criterioOrdenActual={criterioOrden}
            criterioOrdenHandler={this.seleccionarCriterio}
            aumentarLimite={this.aumentarLimite}
          />
        </div>
      ),
    };

    return componentes[version];
  };
}
