import React, { Component } from "react";
import { DatosReparto, TipoReparto } from "serviciossaintmichel";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import TipoRepartoRadio from "../TipoRepartoRadio";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  reparto: DatosReparto;
  camposDeshabilitados?: Array<string>;
}
interface State {
  reparto: DatosReparto;
  validation: boolean;
}

export default class RepartoLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      reparto: this.props.reparto,
      validation: false,
    };
  }

  componentDidMount = () => {
    this.updateValidation(this.state.reparto);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevProps !== this.props) {
      this.setState({ reparto: this.props.reparto });
    }

    if (prevState.reparto !== this.state.reparto) {
      if (this.props.onChange) {
        this.props.onChange(this.state.reparto);
      }
    }
  };

  isValid = (reparto: DatosReparto) => {
    return reparto.nombre.length > 0;
  };

  updateValidation = (reparto: DatosReparto) => {
    let validation: boolean = this.isValid(reparto);
    this.setState({ validation: validation });
    this.props.onValidationChange(validation);
  };

  setearNombre = (nombre: string) => {
    let reparto = this.state.reparto;
    reparto.nombre = nombre;
    this.setState({ reparto: reparto });
    this.updateValidation(reparto);
  };

  setearTipo = (tipoReparto: TipoReparto) => {
    this.setState((prevState) => ({
      reparto: { ...prevState.reparto, tipo: tipoReparto },
    }));
  };

  render() {
    const { camposDeshabilitados = [] } = this.props;
    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Input
          data={this.state.reparto.nombre}
          disabled={camposDeshabilitados.indexOf("nombre") !== -1}
          label={"Nombre"}
          placeholder={"Nombre"}
          onChange={this.setearNombre}
          type={InputTypes.Text}
        />
        {camposDeshabilitados.indexOf("tipo") === -1 ? (
          <TipoRepartoRadio tipoRepartoSeleccionado={this.state.reparto.tipo} onChange={this.setearTipo} />
        ) : (
          <span>Tipo: {this.state.reparto.tipo}</span>
        )}
      </Flex>
    );
  }
}
