import React, { Component } from "react";
import { CANALES_WEB_SOCKET, Cartera, CarteraService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../Global";
import SelectComponent from "../select/SelectComponent";
import { Loader, SelectProps } from "@mantine/core";
import { PropsSelectGenericoConId } from "../select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../select/SelectBusqueda";

export interface Props {
  version: VersionCarterasShow;
  propsSelect?: PropsSelectGenericoConId<Cartera> & PropsSelectGeneral & Omit<SelectProps, "data">;
}

export type VersionCarterasShow = "select";

interface State {
  carteras: Array<Cartera> | null;
}

export default class CarterasShow extends Component<Props, State> {
  private webSocket: WebSocket | null;

  private carteraService: CarteraService;

  constructor(props: Props) {
    super(props);

    this.state = {
      carteras: null,
    };

    this.webSocket = null;
    this.carteraService = new CarteraService(Global.UsuarioService.getToken()!);
  }

  componentWillUnmount = () => {
    this.webSocket?.close();
  };

  componentDidMount = () => {
    this.setCarteras();
    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_CARTERAS,
      funcionCallback: this.setCarteras,
    });
  };

  setCarteras = async () => {
    try {
      const carteras: Array<Cartera> = await this.carteraService.listAll();

      this.setState({ carteras: carteras });
    } catch (error) {
      this.carteraService.manejarErrorHTTP(error, "Carteras");
    }
  };

  render = () => {
    const { carteras } = this.state;
    const { version, propsSelect } = this.props;

    if (carteras === null) {
      return <Loader />;
    }

    if (version === "select" && propsSelect) {
      return (
        <SelectComponent
          {...propsSelect}
          items={carteras}
          callbackParent={propsSelect.callbackParent}
          seleccionado={propsSelect.seleccionado}
          labelGetter={(obj: Cartera) => `${obj.nombre}`}
        />
      );
    }

    return null;
  };
}
