import React, { Component } from "react";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Divider } from "@mantine/core";
import LogisticaPedidosProgramaciones from "./LogisticaPedidosProgramaciones";
import LogisticaPedidosRepartosDiarios from "./LogisticaPedidosRepartosDiarios";

interface Props {}

interface State {
  fecha: Date | null;
}

export default class LogisticaPedidos extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fecha: null,
    };
  }

  render() {
    const { fecha } = this.state;

    <h3>Programaciones de entrega</h3>;

    if (fecha === null) {
      return (
        <div>
          <div>
            <strong>Fecha de entrega</strong>
          </div>
          <DatePicker
            placeholderText="Seleccione una fecha de entrega"
            onChange={(fecha: Date) => {
              this.setState({ fecha: fecha });
            }}
            selected={fecha}
            dateFormat="dd/MM/yyyy"
          />
        </div>
      );
    }

    return (
      <div>
        <Button
          onClick={() => {
            this.setState({ fecha: null });
          }}
        >
          Seleccionar nueva fecha
        </Button>

        <LogisticaPedidosProgramaciones fecha={fecha} />

        <Divider labelPosition="center" size="md" style={{ margin: "1rem 0" }} />

        <LogisticaPedidosRepartosDiarios fecha={fecha} />
      </div>
    );
  }
}
