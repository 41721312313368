import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Flex from "../../../libreria/appearance/flex/Flex";
import { Global } from "../../../Global";
import DescuentosShow, { VersionDescuentosShow } from "./components/show/DescuentosShow";
import DescuentoNew from "./components/new/DescuentoNew";
import DescuentoUpdate from "./components/update/DescuentoUpdate";
import DescuentoShow from "./components/show/DescuentoShow";

interface Props {}

interface State {
  tabVisible: any;
  disabledActualizar: boolean;
  disabledVer: boolean;
  responsive: boolean;
  idDescuento: number | null;
  recienCreado: boolean;
}

export default class Descuentos extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista",
      disabledActualizar: true,
      disabledVer: true,
      responsive: window.innerWidth < 700 ? true : false,
      idDescuento: null,
      recienCreado: false,
    };

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  actualizar = (idDescuento: number) => {
    this.setState({
      tabVisible: "actualizar",
      disabledActualizar: false,
      idDescuento: idDescuento,
    });
  };

  ver = (idDescuento: number) => {
    this.setState({
      tabVisible: "ver",
      disabledVer: false,
      idDescuento: idDescuento,
      recienCreado: false,
    });
  };

  ocultarVer = () => {
    this.setState({
      tabVisible: "lista",
      disabledVer: true,
      idDescuento: null,
    });
  };

  ocultarNuevo = () => {
    this.setState({
      tabVisible: "lista",
    });
  };

  renderizarNuevo = () => {
    if (Global.UsuarioService.getPermisos().Administracion.Descuentos.Descuento.canAdd()) {
      return (
        <Tab eventKey="nuevo" title="Nuevo" width="100%">
          {this.state.tabVisible === "nuevo" ? <DescuentoNew /> : null}
        </Tab>
      );
    }
  };

  renderizarLista = () => {
    if (Global.UsuarioService.getPermisos().Administracion.Descuentos.Descuento.canView()) {
      return (
        <Tab eventKey="lista" title="Lista" width="100%">
          <DescuentosShow
            version={VersionDescuentosShow.TABLE}
            onUpdate={(descuentoId: number) => {
              this.setState({ tabVisible: "actualizar", idDescuento: descuentoId });
            }}
            onView={(descuentoId: number) => {
              this.setState({ tabVisible: "ver", idDescuento: descuentoId });
            }}
          />
        </Tab>
      );
    }
  };

  renderizarActualizar = () => {
    if (Global.UsuarioService.getPermisos().Administracion.Descuentos.Descuento.canChange()) {
      return (
        <Tab width="100%" eventKey="actualizar" title="Actualizar" disabled={this.state.disabledActualizar}>
          {this.state.idDescuento && this.state.tabVisible === "actualizar" ? (
            <DescuentoUpdate descuentoId={this.state.idDescuento} />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarVerDetalle = () => {
    if (Global.UsuarioService.getPermisos().Administracion.Descuentos.Descuento.canView()) {
      return (
        <Tab width="100%" eventKey="ver" title="Ver Detalle" disabled={this.state.disabledVer}>
          {this.state.idDescuento && this.state.tabVisible === "ver" ? (
            <DescuentoShow descuentoId={this.state.idDescuento} />
          ) : null}
        </Tab>
      );
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey="lista"
      transition={false}
      activeKey={this.state.tabVisible}
      onSelect={this.onSelect}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "flex-start" : "",
      }}
      unmountOnExit={true}
    >
      {this.renderizarNuevo()}
      {this.renderizarLista()}
      {this.renderizarActualizar()}
      {this.renderizarVerDetalle()}
    </Tabs>
  );

  render() {
    return (
      <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        <div style={{ display: "block", width: "100%" }}>{this.renderizar(this.state.responsive)}</div>
      </Flex>
    );
  }
}
