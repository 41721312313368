import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { Checkbox, Radio } from "@mantine/core";
import { CuponEscritura, DescuentoService, Descuento } from "serviciossaintmichel";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import { TipoCupon } from "serviciossaintmichel/lib/apisaintmichel/ventas/cupones/models/Cupon";
import DescuentosShow, {
  VersionDescuentosShow,
} from "../../../../seccion-descuentos/descuentos/components/show/DescuentosShow";
import { Global } from "../../../../../Global";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  cupon: CuponEscritura;
}

interface State {
  cupon: CuponEscritura;
  descuentoSeleccionado: Descuento | null;
  validation: boolean;
}

export default class CuponLoad extends Component<Props, State> {
  private descuentoService: DescuentoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      cupon: props.cupon,
      validation: false,
      descuentoSeleccionado: null,
    };
    this.descuentoService = new DescuentoService(Global.UsuarioService.getToken()!);
  }

  componentDidMount = async () => {
    if (this.state.cupon.descuento > 0) {
      const descuento = await this.descuentoService.get(this.state.cupon.descuento);
      this.setState({ descuentoSeleccionado: descuento });
    }
    this.updateValidation(this.state.cupon);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevProps !== this.props) {
      this.setState({ cupon: this.props.cupon });
    }

    if (prevState.cupon !== this.state.cupon) {
      this.updateValidation(this.state.cupon);
      this.props.onChange(this.state.cupon);
    }

    if (prevState.validation !== this.state.validation) {
      this.props.onValidationChange(this.state.validation);
    }
  };

  isValid = (cupon: CuponEscritura) => {
    return !!(cupon.descuento > -1);
  };

  updateValidation = (cupon: CuponEscritura) => {
    const validacion = this.isValid(cupon);
    this.setState({ validation: validacion });
  };

  setTipo = (tipo: TipoCupon) => {
    this.setState((prevState) => ({
      cupon: {
        ...prevState.cupon,
        tipo: tipo,
        automatico: tipo === "Unico" ? false : prevState.cupon.automatico,
      },
    }));
  };

  setAutomatico = (automatico: boolean) => {
    this.setState((prevState) => ({
      cupon: {
        ...prevState.cupon,
        automatico: automatico,
      },
    }));
  };

  setFechaInicio = (fechaInicio: Date | null) => {
    this.setState((prevState) => ({
      cupon: {
        ...prevState.cupon,
        fecha_inicio: fechaInicio,
      },
    }));
  };

  setFechaFin = (fechaFin: Date | null) => {
    this.setState((prevState) => ({
      cupon: {
        ...prevState.cupon,
        fecha_fin: fechaFin,
      },
    }));
  };

  onDescuentoSelectChange = (descuento: Descuento | null) => {
    this.setState((prevState) => ({
      descuentoSeleccionado: descuento,
      cupon: {
        ...prevState.cupon,
        descuento: descuento === null ? -1 : descuento.id,
      },
    }));
  };

  render() {
    const { cupon, descuentoSeleccionado } = this.state;

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" gap="1rem">
        <Radio.Group name="tipoCupon" label="Tipo de cupon" value={cupon.tipo} onChange={this.setTipo}>
          <Radio value="Unico" label="Unico" />
          <Radio value="Perpetuo" label="Perpetuo" />
        </Radio.Group>
        {cupon.tipo === "Perpetuo" ? (
          <Checkbox
            checked={cupon.automatico}
            label="Aplicacion automatica"
            onChange={(event) => this.setAutomatico(event.currentTarget.checked)}
          />
        ) : null}
        <div>
          <DescuentosShow
            version={VersionDescuentosShow.SELECT}
            propsSelect={{
              callbackParent: this.onDescuentoSelectChange,
              seleccionado: descuentoSeleccionado,
              label: "Descuento seleccionado",
            }}
          />
        </div>
        <div>
          <div>Inicio de vigencia</div>
          <DatePicker
            placeholderText="Inicio de vigencia"
            onChange={this.setFechaInicio}
            selected={cupon.fecha_inicio}
            dateFormat="dd/MM/yyyy"
            isClearable
            filterDate={(date: Date) => (cupon.fecha_fin === null ? true : date <= cupon.fecha_fin)}
          />
        </div>
        <div>
          <div>Fin de vigencia</div>
          <DatePicker
            placeholderText="Fin de vigencia"
            onChange={this.setFechaFin}
            selected={cupon.fecha_fin}
            dateFormat="dd/MM/yyyy"
            isClearable
            filterDate={(date: Date) => (cupon.fecha_inicio === null ? true : date >= cupon.fecha_inicio)}
          />
        </div>
      </Flex>
    );
  }
}
