import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import SelectTipoMaterial from "../../TipoMaterial/components/SelectTipoMaterial";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import { CANALES_WEB_SOCKET, MaterialGet, MaterialPost, MaterialService, TipoMaterialGet, TipoMaterialService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";

export interface Props {
  materialId: number;
  ocultar: Function;
}

interface State {
  material: MaterialGet;
  tiposMaterial: Array<TipoMaterialGet>;
  alerta: any;
}

export default class MaterialActualizar extends Component<Props, State> {

  private materialService:MaterialService;
  private tipoMaterialService:TipoMaterialService;

  constructor(props: Props) {
    super(props);
    this.state = {
      material: this.crearMaterialVacio(),
      tiposMaterial: [],
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_MATERIALES,
      funcionCallback: this.cargarTiposMaterial,
    });

    this.materialService = new MaterialService(Global.UsuarioService.getToken()!);
    this.tipoMaterialService = new TipoMaterialService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.cargarMaterial(this.props.materialId);
    this.cargarTiposMaterial();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarMaterial(this.props.materialId);
    }
  }

  crearMaterialVacio(): MaterialGet {
    const tipoMaterial: TipoMaterialGet = {
      id: 0,
      tipo: "",
    };
    const material: MaterialGet = {
      id: 0,
      nombre: "",
      tipo_material: tipoMaterial,
      marca: "",
      descripcion: "",
      proveedores: [],
    };
    return material;
  }

  cargarMaterial = async (id: number) => {
    const resMaterial = await this.materialService.get(id).catch((error) => {
      this.materialService.manejarErrorHTTP(error, "Material");
      return null;
    });
    if (resMaterial != null) {
      this.setState({
        material: resMaterial,
      });
    }
  };

  cargarTiposMaterial = async () => {
    const resTiposMaterial = await this.tipoMaterialService.listAll().catch(
      (error) => {
        this.tipoMaterialService.manejarErrorHTTP(error, "Tipo Material");
        return null;
      }
    );
    if (resTiposMaterial != null) {
      this.setState({
        tiposMaterial: resTiposMaterial,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  setearNombre = (nombre: string) => {
    let material = this.state.material;
    material.nombre = nombre;
    this.setState({ material: material });
  };

  setearTipoMaterial = (tipoMaterial: TipoMaterialGet) => {
    let material = this.state.material;
    material.tipo_material = tipoMaterial;
    this.setState({ material: material });
  };

  setearMarca = (marca: string) => {
    let material = this.state.material;
    material.marca = marca;
    this.setState({ material: material });
  };

  setearDescripcion = (descripcion: string) => {
    let material = this.state.material;
    material.descripcion = descripcion;
    this.setState({ material: material });
  };

  handleUpdate() {
    const materialActualizar: MaterialPost = {
      nombre: this.state.material.nombre,
      tipo_material: this.state.material.tipo_material.id,
      marca: this.state.material.marca,
      descripcion: this.state.material.descripcion,
    };
    this.materialService.update(materialActualizar, this.state.material.id)
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_MATERIALES,
          { materialId: this.state.material.id }
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Material Actualizado!",
            titulo: "Atención",
            estado: "block",
          },
        });
      })
      .catch((error) => {
        this.materialService.manejarErrorHTTP(error, "Material");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.material.nombre &&
      this.state.material.tipo_material &&
      this.state.material.marca &&
      this.state.material.descripcion
      ? false
      : true;
  };

  render() {
    if (this.state.material.id != 0 && this.state.tiposMaterial.length > 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            <div className={"row"}>
              <div className={"column"}>
                <form
                  className={"form-horizontal"}
                  onSubmit={this.handleSubmit}
                >
                  <div className={"form-group"}>
                    <label htmlFor="nombre">Nombre</label>
                    <InputCaracteres
                      id="nombre"
                      name="nombre"
                      onChange={this.setearNombre}
                      value={this.state.material.nombre}
                      placeholder="Ingrese Nombre de Material"
                    />
                  </div>

                  <div className={"form-group"}>
                    <SelectTipoMaterial
                      tiposMateriales={this.state.tiposMaterial}
                      seleccionado={this.state.material.tipo_material}
                      callbackParent={this.setearTipoMaterial}
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="marca">Marca</label>
                    <InputCaracteres
                      id="marca"
                      name="marca"
                      onChange={this.setearMarca}
                      value={this.state.material.marca}
                      placeholder="Ingrese Marca de Material"
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="descripcion">Descripcion</label>
                    <InputCaracteres
                      id="descripcion"
                      name="descripcion"
                      onChange={this.setearDescripcion}
                      value={this.state.material.descripcion}
                      placeholder="Ingrese Descripcion de Material"
                    />
                  </div>
                  <div
                    className={"form-group"}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button
                      ref="boton"
                      type="submit"
                      className={"btn btn-info"}
                      disabled={this.disableButton()}
                    >
                      {"Actualizar"}
                    </button>
                    <button
                      className={"btn btn-outline-secondary"}
                      onClick={this.ocultar}
                    >
                      Ocultar
                    </button>
                  </div>
                  <div className={"form-group"}>
                    <Alerta
                      tipo={this.state.alerta.tipo}
                      titulo={this.state.alerta.titulo}
                      mensaje={this.state.alerta.mensaje}
                      estado={this.state.alerta.estado}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
