class ManejadorErroresHTTP {
  evaluarError = (error: any, entidad?: string) => {
    let mensaje: string = this.getMensajeError(error, entidad);
    PubSub.publish("error_http", mensaje);
  };

  getMensajeError(error: any, entidad?: string) {
    let mensaje: string = "";
    if (error.message === "Network Error") {
      mensaje = "Se perdió conexión con la base de datos. (" + error.message + ")";
    } else {
      switch (error.response.status) {
        case 404: {
          mensaje = entidad ? "No se encontro el/la " + entidad : "No se encontro lo buscado.";
          break;
        }
        case 400: {
          mensaje = "Data Invalida: \n" + this.evaluar400(error.response.data);
          break;
        }
        case 500: {
          mensaje = "Falla del servidor (enviar a soporte)";
          break;
        }
        default: {
          mensaje = "Mensaje no controlado (enviar a soporte): \n" + error.response.data;
          break;
        }
      }
    }
    return mensaje;
  }

  evaluar400(datoError: any) {
    let mensaje = "";
    if (datoError != undefined) {
      Object.entries(datoError).forEach(([key, value]) => {
        const texto: string = (value as Array<string>)[0];
        if (texto == undefined) {
          //Este seria el caso, de un error dentro de un dato, que es objeto.
          mensaje = mensaje + "\nError en " + key + ":\n" + this.evaluar400(value);
        } else {
          if (key == "non_field_errors") {
            if (texto.includes(" must make a unique set")) {
              //Error de conjunto de datos no unicos
              let textoFinal = texto.replace(" must make a unique set", "");
              textoFinal = textoFinal.replace("The fields ", "");
              mensaje = mensaje + "\n Estos valores de " + textoFinal + " en conjunto no son unicos (ya existen).";
            } else {
              mensaje = mensaje + "\n " + texto;
            }
          } else {
            if (key === "detail") {
              //Errores seteados por nosotros, van con en el campo detail
              mensaje = mensaje + "\n " + value;
            } else {
              if (texto.endsWith(" already exists.")) {
                //Error de ya existencia de clave unica
                mensaje = mensaje + "\n" + "El/La " + key + " ya existe.";
              } else {
                if (texto.startsWith("Incorrect type")) {
                  //Error de dato de tipo invalido
                  mensaje = mensaje + "\n" + "El/La " + key + " es de tipo incorrecto.";
                } else {
                  if (texto.startsWith("Invalid pk")) {
                    //Error de que no existe el id enviado (dentro de un dato)
                    mensaje = mensaje + "\n" + "El/La " + key + " no existe.";
                  } else {
                    if (texto.startsWith("Valid number")) {
                      //Error de que no es un numero valido (el dato)
                      mensaje = mensaje + "\n" + "Para el/la " + key + " Se requiere un número válido.";
                    } else {
                      if (texto.endsWith("not be null.")) {
                        //Error de que el dato no puede ser nulo
                        mensaje = mensaje + "\n" + "El/La " + key + " no puede estar en blanco.";
                      } else {
                        mensaje =
                          mensaje +
                          "\n" +
                          "Error inesperado en " +
                          key +
                          ". Con el valor " +
                          value +
                          ". Contactarse con el area de programacion.";
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }
    return mensaje;
  }
}
export default new ManejadorErroresHTTP();
