import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";

import Flex from "../flex/Flex";
import H from "../titulos/H";
import { Fuentes } from "../../estilos/estilos";
import FranjaHorariaLoad from "./FranjaHorariaLoad";
import ListBox from "../../../libreria/data/listbox/ListBox";
import { Item } from "../../../libreria/data/listbox/Item";
import { Button } from "react-bootstrap";
import Time from "../../utils/Time";
import { FranjaHoraria } from "serviciossaintmichel";

export interface Props {
  franjas: Array<FranjaHoraria>;
  onChange?: Function;
  onValidationChange?: Function;
}

interface FranjaItem {
  item: Item;
  franja: FranjaHoraria;
}

interface State {
  franja: FranjaHoraria | null;
  franjasItems: Array<FranjaItem>;
  contadorFranjas: number;
  franjaValida: boolean;
  validado: boolean;
}

export default class FranjasHorariasLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const franjasItems = this.crearFranjasItems(props.franjas, 0, []);
    const contador = franjasItems.length;

    this.state = {
      franja: null,
      contadorFranjas: contador,
      franjasItems: franjasItems,
      franjaValida: false,
      validado: false,
    };
  }

  validar = (cantidadFranjas: number) => {
    this.setState({ validado: cantidadFranjas > 0 });
  };

  componentDidMount() {
    this.validar(this.state.franjasItems.length);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { franjasItems, contadorFranjas, validado } = this.state;
    const { franjas } = this.props;

    if (prevProps.franjas !== franjas) {
      const franjasState = this.state.franjasItems.map((fi) => fi.franja);

      if (JSON.stringify(franjasState) !== JSON.stringify(franjas)) {
        const nuevasFranjasItems = this.crearFranjasItems(
          franjas,
          contadorFranjas,
          []
        );
        this.setState({
          franjasItems: nuevasFranjasItems,
          contadorFranjas: contadorFranjas + franjas.length,
        });
      }
    }

    if (prevState.franjasItems !== franjasItems && this.props.onChange) {
      this.props.onChange(
        this.state.franjasItems.map((franjaItem) => franjaItem.franja)
      );
      this.validar(franjasItems.length);
    }

    if (prevState.validado !== validado && this.props.onValidationChange) {
      this.props.onValidationChange(validado);
    }
  }

  existeFranja = (franja: FranjaHoraria | null): boolean => {
    if (franja === null) {
      return false;
    }

    const franjas = this.state.franjasItems.filter(
      (fi) => JSON.stringify(fi.franja) === JSON.stringify(franja)
    );
    return franjas.length > 0;
  };

  agregarFranja = (franja: FranjaHoraria | null) => {
    if (franja === null) {
      return;
    }

    const { franjasItems, contadorFranjas } = this.state;
    const nuevoContador = contadorFranjas + 1;
    const nuevaFranjaItem = this.crearListBoxItem(franja, nuevoContador);
    const nuevasFranjasItems = [
      ...franjasItems,
      { franja: franja, item: nuevaFranjaItem },
    ];

    this.setState({
      franjasItems: nuevasFranjasItems,
      franja: null,
      contadorFranjas: nuevoContador,
    });
  };

  eliminarFranja = (idFranja: number) => {
    const nuevasFranjasItems = this.state.franjasItems.filter(
      (franjaItem) => franjaItem.item.id !== idFranja
    );
    this.setState({ franjasItems: nuevasFranjasItems });
  };

  onFranjaChange = (franja: FranjaHoraria) => {
    this.setState({ franja: franja });
  };

  onFranjaValidationChange = (validez: boolean) => {
    this.setState({ franjaValida: validez });
  };

  crearListBoxItem = (franja: FranjaHoraria, franjaId: number): Item => {
    let inicio = franja.inicio;
    if (typeof franja.inicio === "string") {
      inicio = Time.getTimeFromString(franja.inicio);
    }

    let fin = franja.fin;
    if (typeof franja.fin === "string") {
      fin = Time.getTimeFromString(franja.fin);
    }

    return {
      id: franjaId,
      text: `${
        franja.dia
      } - ${inicio.toLocaleTimeString()}  - ${fin.toLocaleTimeString()}`,
    };
  };

  crearFranjasItems = (
    franjas: Array<FranjaHoraria>,
    contadorActual: number,
    franjasItems: Array<FranjaItem>
  ): Array<FranjaItem> => {
    const nuevasFranjasItems = franjas.map((franja, index) => ({
      franja: franja,
      item: this.crearListBoxItem(franja, contadorActual + index + 1),
    }));
    return [...franjasItems, ...nuevasFranjasItems];
  };

  render() {
    const { franja, franjasItems, franjaValida } = this.state;
    return (
      <Flex
        container
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding="30px"
        marginBottom="20px"
      >
        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <H size={3} fuente={Fuentes.Titulos} texto="Franjas Horarias" />
        </Flex>

        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          marginBottom="1rem"
        >
          <Flex
            container
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            width="100%"
          >
            <H size={5} fuente={Fuentes.Titulos} texto="Datos Nueva Franja" />
          </Flex>

          <Flex
            container
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            marginTop="15px"
          >
            <Flex
              container
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="50%"
            >
              <FranjaHorariaLoad
                onChange={this.onFranjaChange}
                onValidationChange={this.onFranjaValidationChange}
                franja={franja}
              />
              <Button
                onClick={() => this.agregarFranja(franja)}
                disabled={!franjaValida || this.existeFranja(franja)}
              >
                Agregar franja
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Flex
            container
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
          >
            <H size={5} fuente={Fuentes.Titulos} texto="Franjas Creadas" />

            <ListBox
              height="auto"
              items={franjasItems.map((franjaItem) => franjaItem.item)}
              tipo={{ select: false, view: false, delete: true }}
              onDelete={this.eliminarFranja}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  }
}
