import React, { Component } from "react";
import { EntregaBultoProducto, EntregaMaquina, EntregaProducto } from "serviciossaintmichel";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import EntregaBultoProductosPedidoShow from "./../entregas/EntregaBultoProductosPedidoShow";
import EntregaMaquinasPedidoShow from "./../entregas/EntregaMaquinasPedidoShow";
import EntregaProductosPedidoShow from "./../entregas/EntregaProductosPedidoShow";
export interface Props {
  entregasProducto: Array<EntregaProducto>;
  entregasBultoProducto: Array<EntregaBultoProducto>;
  entregasMaquina: Array<EntregaMaquina>;
}

interface State {
  cargando: boolean;
}

export default class EntregasPedidoShow extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cargando: false,
    };
  }

  componentDidMount = async () => {};

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      /* await this.setState({ cargando: false }); */
    }
  };

  renderEntregasPedido = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <EntregaProductosPedidoShow
        entregaProductos={this.props.entregasProducto}
      />
      <EntregaBultoProductosPedidoShow
        entregaBultoProductos={this.props.entregasBultoProducto}
      />
      <EntregaMaquinasPedidoShow entregaMaquinas={this.props.entregasMaquina} />
    </Flex>
  );

  renderLoading = () => "Cargando Datos";
  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        borderRadius="5px"
      >
        {this.state.cargando
          ? this.renderLoading()
          : this.renderEntregasPedido()}
      </Flex>
    );
  }
}
