import { SelectItem } from "@mantine/core";

class SelectItemUtils {
  /**
   * Utils para la clase SelectItem de la libreria mantine
   * ordena alfabeticamente los label del arreglo, no los grupos
   * */
  ordenarLabelAlfabeticamente(arreglo: SelectItem[]): SelectItem[] {
    let result: SelectItem[] = arreglo.map((x) => x);
    return result.sort((a, b) => {
      if (a.label && b.label && a.label < b.label) {
        return -1;
      }
      if (a.label && b.label && a.label > b.label) {
        return 1;
      }
      return 0;
    });
  }
}
export default new SelectItemUtils();
