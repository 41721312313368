import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Cliente, ClienteService, TipoCliente, TipoClienteService } from "serviciossaintmichel";
import Swal from "sweetalert2";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import { Fuentes } from "../../../../../../../compartido/estilos/estilos";
import { Global } from "../../../../../../../Global";
import H from "../../../../../../../libreria/message/title/H";
import SelectTipoCliente from "../../../nuevo/vinculacionytipocliente/SelectTipoCliente";






interface Props {
  clienteId: number;
}

interface State {
  cliente: Cliente | null;
  tipoClienteSeleccionado: TipoCliente | null;
  tiposCliente: Array<TipoCliente>;
}

export default class TipoClienteUpdate extends Component<Props, State> {

  private tipoClienteService:TipoClienteService;
  private clienteService:ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = { cliente: null, tipoClienteSeleccionado: null, tiposCliente: [] };

    this.tipoClienteService = new TipoClienteService(Global.UsuarioService.getToken()!);
    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    const cliente = await this.clienteService.get(this.props.clienteId);
    const tipoCliente = await this.tipoClienteService.get(cliente.tipo_cliente);

    try {
      const queryString = new URLSearchParams({ grupo: tipoCliente.grupo.toString() });
      const tiposCliente = await this.tipoClienteService.list(queryString);
      const res = tiposCliente.filter((tc) => tc.id === cliente.tipo_cliente);
      const tipoClienteSeleccionado = res.length > 0 ? res[0] : null;
      this.setState({ cliente, tiposCliente, tipoClienteSeleccionado });
    } catch (error) {
      this.tipoClienteService.manejarErrorHTTP(error, "Tipo Cliente");
    }
  };

  onChange = (tipoClienteSeleccionado: TipoCliente) => {
    this.setState({ tipoClienteSeleccionado: tipoClienteSeleccionado });
  };

  onSave = async () => {
    const { cliente, tipoClienteSeleccionado } = this.state;

    if (cliente == null || tipoClienteSeleccionado == null) {
      return;
    }

    try {
      const payload = { ...cliente, tipo_cliente: tipoClienteSeleccionado.id };
      await this.clienteService.update(payload, cliente.id);

      await Swal.fire({
        icon: "success",
        text: "Cliente Actualizado",
      });
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error, "Cliente");
    }
  };

  render() {
    const { cliente, tiposCliente, tipoClienteSeleccionado } = this.state;
    if (cliente === null || !tiposCliente) {
      return null;
    }

    return (
      <Flex container flexDirection="column" justifyContent="center" alignItems="center" width="auto">
        <H size={3} fuente={Fuentes.Titulos} texto="Tipo de cliente" />
        <Flex container flexDirection="column" justifyContent="center" alignItems="center" width="auto">
          <SelectTipoCliente
            tiposCliente={tiposCliente}
            seleccionado={tipoClienteSeleccionado}
            callbackParent={this.onChange}
            opcionNull={false}
          />
        </Flex>
        <Flex container flexDirection="column" justifyContent="center" alignItems="center" width="auto">
          <Button onClick={this.onSave}>Guardar</Button>
        </Flex>
      </Flex>
    );
  }
}
