import React, { Component } from "react";
import { DatosAlquiler } from "serviciossaintmichel";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../../libreria/data/input/Input";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  alquiler: DatosAlquiler;
}
interface State {
  alquiler: DatosAlquiler;
  validation: boolean;
}

export default class AlquilerLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      alquiler: this.props.alquiler,
      validation: false,
    };
  }

  componentDidMount = () => {
    this.updateValidation(this.state.alquiler);
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps != this.props) {
      this.setState({ alquiler: this.props.alquiler });
    }
  };

  isValid = (alquiler: DatosAlquiler) => {
    return alquiler.nombre.length > 0 ? true : false;
  };

  updateValidation = (alquiler: DatosAlquiler) => {
    let validation: boolean = this.isValid(alquiler);
    this.setState({ validation: validation });
    this.props.onValidationChange(validation);
  };

  onChange = (nombre: string) => {
    let alquiler = this.state.alquiler;
    alquiler.nombre = nombre;
    this.setState({ alquiler: alquiler });
    this.updateValidation(alquiler);
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Input
          data={this.state.alquiler.nombre}
          label={"Nombre"}
          placeholder={"Nombre"}
          onChange={this.onChange}
          type={InputTypes.Text}
        />
      </Flex>
    );
  }
}
