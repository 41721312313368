import React, { Component } from "react";
import "../template.css";
import "./footer.css";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <footer className="footer">
        <div className="contenedor">
          <div className="contenido">{/* <h1>Soy el Footer</h1> */}</div>
        </div>
      </footer>
    );
  }
}

export default Footer;
