import { Radio } from "@mantine/core";
import React from "react";
import { TipoReparto, TIPOS_REPARTO } from "serviciossaintmichel";

export interface Props {
  tipoRepartoSeleccionado: TipoReparto;
  onChange: (valor: TipoReparto) => void;
}

export default class TipoRepartoRadio extends React.Component<Props, {}> {
  render(): React.ReactNode {
    return (
      <Radio.Group
        name="Tipo de reparto"
        label="Tipo de reparto"
        value={this.props.tipoRepartoSeleccionado}
        onChange={this.props.onChange}
        size="md"
        orientation="horizontal"
      >
        {Object.values(TIPOS_REPARTO).map((tipo) => (
          <Radio value={tipo} label={tipo} />
        ))}
      </Radio.Group>
    );
  }
}
