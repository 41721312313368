import React, { Component } from "react";
import Flex from "../../../../compartido/components/flex/Flex";

interface Props {}

interface State {

  responsive: boolean;
}

export default class Salarios extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      responsive: window.innerWidth < 700 ? true : false,
    };

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };


  renderizar = (responsive: boolean) => (
    ""
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {this.renderizar(this.state.responsive)}
      </Flex>
    );
  }
}
