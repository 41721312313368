import React, { Component } from "react";
import { CANALES_WEB_SOCKET, ClienteService, Telefono, TelefonoLectura, WebSocketService } from "serviciossaintmichel";
import Swal from "sweetalert2";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import Screen, { ScreenType } from "../../../../../../../compartido/models/Screen";
import { Global } from "../../../../../../../Global";
import RequestButton from "../../../../../../../libreria/action/request-button/RequestButton";
import Box from "../../../../../../../libreria/containers/box/Box";
import H from "../../../../../../../libreria/message/title/H";
import TelefonoClienteLoad from "./TelefonoClienteLoad";




export interface Props {
  clienteId: number;
  onCloseCallback?: Function;
}

interface State {
  telefono: TelefonoLectura | null;
  screen: ScreenType;
  valid: boolean;
}

export default class TelefonoClienteNew extends Component<Props, State> {

  private clienteService:ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      telefono: null,
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = () => {
    window.addEventListener("resize", this.actualizarDimensiones);
  };

  actualizarDimensiones = () => {
    this.setState({ screen: Screen.getType(window.innerWidth, window.innerHeight) });
  };

  onSave = async (telefono: TelefonoLectura) => {
    try {
      const payload: Telefono = { ...telefono, tipo_telefono: telefono.tipo_telefono.id };
      const resultado = await this.clienteService.crearTelefono(this.props.clienteId, payload);

      this.setState({ telefono: null });

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_TELEFONOS, {
        id: resultado.id,
        clienteId: resultado.cliente,
      });

      await Swal.fire({
        icon: "success",
        text: "Teléfono Creado",
      });
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error, "Teléfono");
    }
  };

  onChange = (telefono: TelefonoLectura) => {
    this.setState({ telefono: telefono });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex flexDirection="column" width="60%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex flexDirection="column" width="50%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  onClose = () => {
    if (this.props.onCloseCallback) {
      this.props.onCloseCallback();
    }
  };

  renderizar = () => (
    <Box paddingVertical={"2rem"} paddingHorizontal={"2rem"} showCloseBtn={true} onCloseCallback={this.onClose}>
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <H size={3} texto={"Nuevo teléfono"} />
        </Flex>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <TelefonoClienteLoad
            telefono={this.state.telefono}
            clienteId={this.props.clienteId}
            onChange={this.onChange}
            onChangeValidation={this.onValidationChange}
          />
        </Flex>
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Crear"}
            onClick={() => {
              if (this.state.telefono !== null) this.onSave(this.state.telefono);
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.screen === ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen === ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen === ScreenType.TABLET ? this.renderizarTablet() : null}
      </Flex>
    );
  }
}
