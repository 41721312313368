import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";


import { CANALES_WEB_SOCKET, ProductoLimpiezaGet, ProductoLimpiezaPost, ProductoLimpiezaService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";


export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  productoLimpiezas: Array<ProductoLimpiezaGet>;
}

export default class ProductoLimpiezaLista extends Component<Props, State> {

  private productoLimpiezaService:ProductoLimpiezaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      productoLimpiezas: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS_LIMPIEZA,
      funcionCallback: this.getElementos,
    });

    this.productoLimpiezaService = new ProductoLimpiezaService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.productoLimpiezaService.list()
      .then((result) => {
        this.setState({ productoLimpiezas: result });
      })
      .catch((error) => {
        this.productoLimpiezaService.manejarErrorHTTP(error, "Producto Limpieza");
      });
  };

  componentDidMount() {
    this.productoLimpiezaService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }
  armarFilas(
    productoLimpiezas: Array<ProductoLimpiezaGet>
  ): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (productoLimpiezas.length > 0) {
      for (let i = 0; i < productoLimpiezas.length; i++) {
        let fila: Array<string> = [];
        fila.push(productoLimpiezas[i].nombre);
        fila.push(productoLimpiezas[i].litros.toString());
        fila.push(productoLimpiezas[i].marca);
        fila.push(productoLimpiezas[i].descripcion);
        let filaResult: FilaTablaGenerica = {
          id: productoLimpiezas[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Elementos.ProductosLimpieza.ProductoLimpieza.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Elementos.ProductosLimpieza.ProductoLimpieza.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.productoLimpiezaService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.productoLimpiezas)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.productoLimpiezaService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaProductosLimpieza"
      />
    );
  }
}
