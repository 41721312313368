import React, { Component } from "react";
import {
  GoogleMap,
  LoadScript,
} from "@react-google-maps/api"; /* global google */

import Flex from "../../../compartido/components/flex/Flex";

import Marcador from "./Marcador";

interface Props {}

interface State {}

class Mapa extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  mapa: any;

  componentDidMount() {}

  load = (map: any) => {
    this.mapa = map;
  };

  render() {
    return (
      <Flex container flexDirection="column" width="100%">
        <Flex
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <h3>Mapa de Seguimiento</h3>
          </div>
        </Flex>
        <br />
        <br />

        <Flex container flexDirection="column" height="800px" width="100%">
          <LoadScript
            id="script-loader"
            googleMapsApiKey="AIzaSyCDsnMxHafUo0xkHuJfTxoAHcinkNDSi9c"
            libraries={["drawing"]}
          >
            <GoogleMap
              id="example-map"
              mapContainerStyle={{
                height: "100%",
                width: "100%",
                margin: "auto",
              }}
              ref={(map) => (this.mapa = map)}
              zoom={12}
              center={{ lat: -34.912185, lng: -57.957313 }}
              onLoad={this.load}
            >
              <Marcador id={1} />
            </GoogleMap>
          </LoadScript>
        </Flex>
      </Flex>
    );
  }
}
export default Mapa;
