import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import SelectTipoVehiculo from "../../TipoVehiculo/components/SelectTipoVehiculo";
import SelectTipoCombustible from "../../TipoCombustible/components/SelectTipoCombustible";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import InputDecimal from "../../../../compartido/components/input/InputDecimal";
import InputBase from "../../../../compartido/components/input/InputBase";


import { CANALES_WEB_SOCKET, TipoCombustibleGet, TipoCombustibleService, TipoVehiculoGet, TipoVehiculoService, VehiculoGet, VehiculoPost, VehiculoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";






export interface Props {
  ocultar: Function;
}

interface State {
  vehiculo: VehiculoPost;
  tiposVehiculo: Array<TipoVehiculoGet>;
  tiposCombustible: Array<TipoCombustibleGet>;
  alerta: any;
}

export default class VehiculoNuevo extends Component<Props, State> {


  private tipoCombustibleService:TipoCombustibleService;
  private tipoVehiculoService:TipoVehiculoService;
  private vehiculoService:VehiculoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      vehiculo: this.vehiculoCrear(),
      tiposVehiculo: [],
      tiposCombustible: [],
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_VEHICULOS,
      funcionCallback: this.cargarTiposVehiculo,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_COMBUSTIBLES,
      funcionCallback: this.cargarTiposCombustible,
    });

    this.tipoCombustibleService = new TipoCombustibleService(Global.UsuarioService.getToken()!);
    this.tipoVehiculoService = new TipoVehiculoService(Global.UsuarioService.getToken()!);
    this.vehiculoService = new VehiculoService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    await Promise.all([
      this.cargarTiposVehiculo(),
      this.cargarTiposCombustible(),
    ]);
    const vehiculoCrear: VehiculoPost = this.state.vehiculo;
    if (this.state.tiposVehiculo.length > 0) {
      vehiculoCrear.tipo_vehiculo = this.state.tiposVehiculo[0].id;
    } else {
      vehiculoCrear.tipo_vehiculo = 0;
    }
    if (this.state.tiposCombustible.length > 0) {
      vehiculoCrear.tipo_combustible = this.state.tiposCombustible[0].id;
    } else {
      vehiculoCrear.tipo_combustible = 0;
    }
    this.setState({
      vehiculo: vehiculoCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  cargarTiposVehiculo = async () => {
    const resTiposVehiculo = await this.tipoVehiculoService.listAll().catch(
      (error) => {
        this.tipoVehiculoService.manejarErrorHTTP(error, "Tipo Vehículo");
        return null;
      }
    );
    if (resTiposVehiculo != null) {
      this.setState({
        tiposVehiculo: resTiposVehiculo,
      });
    }
  };

  cargarTiposCombustible = async () => {
    const resTiposCombustible = await this.tipoCombustibleService.listAll().catch(
      (error) => {
        this.tipoCombustibleService.manejarErrorHTTP(error, "Tipo Combustible");
        return null;
      }
    );
    if (resTiposCombustible != null) {
      this.setState({
        tiposCombustible: resTiposCombustible,
      });
    }
  };

  vehiculoCrear(): VehiculoPost {
    return {
      patente: "",
      nombre: "",
      tipo_vehiculo: 0,
      tipo_combustible: 0,
      marca: "",
      capacidad_carga: 0,
      descripcion: "",
    };
  }

  setearPatente = (patente: string) => {
    let vehiculo = this.state.vehiculo;
    vehiculo.patente = patente;
    console.log(patente);
    this.setState({ vehiculo: vehiculo });
  };
  setearNombre = (nombre: string) => {
    let vehiculo = this.state.vehiculo;
    vehiculo.nombre = nombre;
    console.log(nombre);
    this.setState({ vehiculo: vehiculo });
  };

  setearTipoVehiculo = (tipoVehiculo: TipoVehiculoGet) => {
    let vehiculo = this.state.vehiculo;
    vehiculo.tipo_vehiculo = tipoVehiculo.id;
    console.log(tipoVehiculo.id);
    this.setState({ vehiculo: vehiculo });
  };

  setearTipoCombustible = (tipoCombustible: TipoCombustibleGet) => {
    let vehiculo = this.state.vehiculo;
    vehiculo.tipo_combustible = tipoCombustible.id;
    console.log(tipoCombustible.id);
    this.setState({ vehiculo: vehiculo });
  };

  setearMarca = (marca: string) => {
    let vehiculo = this.state.vehiculo;
    vehiculo.marca = marca;
    console.log(marca);
    this.setState({ vehiculo: vehiculo });
  };

  setearCapacidadCarga = (capacidad_carga: number) => {
    let vehiculo = this.state.vehiculo;
    vehiculo.capacidad_carga = capacidad_carga;
    console.log(capacidad_carga);
    this.setState({ vehiculo: vehiculo });
  };

  setearDescripcion = (descripcion: string) => {
    let vehiculo = this.state.vehiculo;
    vehiculo.descripcion = descripcion;
    console.log(descripcion);
    this.setState({ vehiculo: vehiculo });
  };

  handleCreate() {
    this.vehiculoService.create(this.state.vehiculo)
      .then((result) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_VEHICULOS);
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Vehiculo Creado!",
            titulo: "Atención",
            estado: "block",
          },
        });
        const vehiculoCrear: VehiculoPost = this.vehiculoCrear();
        vehiculoCrear.tipo_vehiculo = this.state.vehiculo.tipo_vehiculo;
        vehiculoCrear.tipo_combustible = this.state.vehiculo.tipo_combustible;
        this.setState({
          vehiculo: vehiculoCrear,
        });
      })
      .catch((error) => {
        this.vehiculoService.manejarErrorHTTP(error, "Vehículo");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.vehiculo.patente &&
      this.state.vehiculo.nombre &&
      this.state.vehiculo.tipo_vehiculo &&
      this.state.vehiculo.tipo_vehiculo != 0 &&
      this.state.vehiculo.tipo_combustible &&
      this.state.vehiculo.tipo_combustible != 0 &&
      this.state.vehiculo.marca &&
      this.state.vehiculo.capacidad_carga != 0 &&
      this.state.vehiculo.descripcion
      ? false
      : true;
  };

  renderizarFormulario() {
    if (
      this.state.tiposVehiculo.length == 0 ||
      this.state.tiposCombustible.length == 0
    ) {
      return (
        <div>
          {
            "Aún no se poseen tipos de vehiculo o de combustible, vaya y cree uno."
          }
          <button
            type={"reset"}
            className={"btn btn-outline-secondary"}
            onClick={this.ocultar}
          >
            {"Ocultar"}
          </button>
        </div>
      );
    }
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="patente">Patente</label>
                <InputBase
                  id="patente"
                  name="patente"
                  onChange={this.setearPatente}
                  value={this.state.vehiculo.patente}
                  placeholder="Ingrese Patente de Vehiculo"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="nombre">Nombre</label>
                <InputCaracteres
                  id="nombre"
                  name="nombre"
                  onChange={this.setearNombre}
                  value={this.state.vehiculo.nombre}
                  placeholder="Ingrese Nombre de Vehiculo"
                />
              </div>

              <div className={"form-group"}>
                <SelectTipoVehiculo
                  tiposVehiculos={this.state.tiposVehiculo}
                  seleccionado={this.state.tiposVehiculo[0]}
                  callbackParent={this.setearTipoVehiculo}
                />
              </div>

              <div className={"form-group"}>
                <SelectTipoCombustible
                  tiposCombustibles={this.state.tiposCombustible}
                  seleccionado={this.state.tiposCombustible[0]}
                  callbackParent={this.setearTipoCombustible}
                />
              </div>

              <div className={"form-group"}>
                <label htmlFor="marca">Marca</label>
                <InputCaracteres
                  id="marca"
                  name="marca"
                  onChange={this.setearMarca}
                  value={this.state.vehiculo.marca}
                  placeholder="Ingrese Marca"
                />
              </div>

              <div className={"form-group"}>
                <label htmlFor="capacidad_carga">Capacidad de Carga</label>{" "}
                <InputDecimal
                  id="capacidad_carga"
                  name="capacidad_carga"
                  onChange={this.setearCapacidadCarga}
                  value={this.state.vehiculo.capacidad_carga}
                  cantEnteros={3}
                  cantDecimales={3}
                  placeholder={"Ingrese la Capacidad de Carga"}
                />
              </div>

              <div className={"form-group"}>
                <label htmlFor="descripcion">Descripcion</label>
                <InputCaracteres
                  id="descripcion"
                  name="descripcion"
                  onChange={this.setearDescripcion}
                  value={this.state.vehiculo.descripcion}
                  placeholder="Ingrese Descripcion"
                />
              </div>

              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  ref="boton"
                  type="submit"
                  className={"btn btn-info"}
                  disabled={this.disableButton()}
                >
                  {"Crear"}
                </button>
                <button
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
              <div className={"form-group"}>
                <Alerta
                  tipo={this.state.alerta.tipo}
                  titulo={this.state.alerta.titulo}
                  mensaje={this.state.alerta.mensaje}
                  estado={this.state.alerta.estado}
                />
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
