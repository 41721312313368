import React, { Component } from "react";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import Visibilizador from "../../../../../libreria/action/visibilizador/Visibilizador";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Text from "../../../../../libreria/message/text/Text";

import { CANALES_WEB_SOCKET, Reparto, RepartoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";

export interface Props {
  idReparto: number;
}
interface State {
  reparto: Reparto | null;
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
}

export default class DatosRepartoShow extends Component<Props, State> {
  private repartoService: RepartoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      reparto: null,
      cargando: true,
      visible: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.repartoService = new RepartoService(Global.UsuarioService.getToken()!);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {
    await this.cargarReparto();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS,
      funcionCallback: this.onChannelMesagge,
    });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.repartoId === this.props.idReparto) {
      await this.cargarReparto();
    }
  };

  cargarReparto = async () => {
    let reparto = await this.repartoService.get(this.props.idReparto).catch((error) => {
      this.repartoService.manejarErrorHTTP(error, "Reparto");
      return null;
    });
    this.setState({ reparto: reparto, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props !== oldProps) {
      this.setState({ cargando: true });
      await this.cargarReparto();
    }
  };

  renderDatos = () => (
    <Flex container flexDirection="column" width="100%" alignItems="flex-start" justifyContent="center">
      <Text>Nombre: {this.state.reparto!.nombre}</Text>
      <Text>Tipo: {this.state.reparto!.tipo}</Text>
    </Flex>
  );

  renderLoading = () => "Cargando Datos";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="20px"
      >
        <Visibilizador onChange={this.onChangeVisibilizador} label={"Datos"} labelAlignment={"flex-start"}>
          {this.state.cargando ? this.renderLoading() : this.renderDatos()}
        </Visibilizador>
      </Flex>
    );
  }
}
