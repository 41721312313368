import React, { Component } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import Swal from "sweetalert2";

export interface Props {
  onClick: Function;
  propsBoton?: ButtonProps;
  icon:any;
  size?:number;
}

interface State {
}

export default class IconButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }



  componentDidUpdate = (prevProps:Props) =>
  {
    if(prevProps.propsBoton?.disabled!=this.props.propsBoton?.disabled)
    {

    }
   

  }


  render() {
    return (
        <Button
          {...this.props.propsBoton}
          onClick={() => {
            this.props.onClick();
          }}
          disabled= {this.props.propsBoton?.disabled}

        >
          {this.props.icon}
        </Button>
    );
  }
}

