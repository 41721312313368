import { Badge, Menu } from "@mantine/core";
import React, { Component } from "react";
import { Table, Button } from "react-bootstrap";
import BotonOrden from "../../../../administracion/seccion-clientes/clientes/components/BotonOrden";
import FechaUtils from "../../../../compartido/utils/FechaUtils";
import Flex from "../../../../libreria/appearance/flex/Flex";

import { CriterioOrden, RepartoDiarioLectura } from "serviciossaintmichel";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
  criterioOrdenActual: CriterioOrden | null;
  criterioOrdenHandler?: (critOrden: CriterioOrden | null) => void;
  aumentarLimite?: () => void;
  repartosDiarios: Array<RepartoDiarioLectura>;
}

interface State {}

export default class RepartosDiariosTable extends Component<Props, State> {
  manejadorScroll = () => {
    const divTabla = document.getElementById("contenedor-scroll");
    if (divTabla === null) {
      return;
    }
    let movimientoTope = divTabla.scrollHeight - divTabla.clientHeight;

    if (movimientoTope === divTabla.scrollTop) {
      if (this.props.aumentarLimite) {
        this.props.aumentarLimite();
      }
    }
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };

  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  renderAccionMenu = (repartoDiarioId: number) => {
    return (
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Button>Acciones</Button>
        </Menu.Target>

        <Menu.Dropdown>
          {this.props.onView ? (
            <Menu.Item
              onClick={() => {
                this.onView(repartoDiarioId);
              }}
            >
              Ver detalle
            </Menu.Item>
          ) : null}
          {this.props.onUpdate ? (
            <Menu.Item
              onClick={() => {
                this.onUpdate(repartoDiarioId);
              }}
            >
              Actualizar
            </Menu.Item>
          ) : null}
          <Menu.Item
            onClick={() => {
              alert("Falta implementar la eliminacion");
            }}
          >
            Eliminar
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  };

  getCriterioHandler = (campo: string) => {
    return (modo: "ASC" | "DESC" | null) => {
      const criterioOrden = modo ? { modo: modo, campo: campo } : null;
      if (this.props.criterioOrdenHandler) {
        this.props.criterioOrdenHandler(criterioOrden);
      }
    };
  };

  getControlCriterio = (campo: string) =>
    this.props.criterioOrdenActual?.campo === campo ? this.props.criterioOrdenActual.modo : null;

  renderBotonOrden = (campo: string) => {
    if (!this.props.criterioOrdenHandler) {
      return null;
    }
    return <BotonOrden criterio={this.getControlCriterio(campo)} onCriterioClicked={this.getCriterioHandler(campo)} />;
  };

  render = () => {
    const { repartosDiarios } = this.props;

    return (
      <div style={{ maxHeight: "350px", overflowY: "scroll" }} onScroll={this.manejadorScroll} id="contenedor-scroll">
        <Table striped bordered>
          <thead>
            <tr>
              <th className="text-center" style={{ position: "sticky", top: "0" }}>
                #
              </th>
              <th className="text-center" style={{ position: "sticky", top: "0" }}>
                <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                  <span>Id </span>
                  {this.renderBotonOrden("id")}
                </Flex>
              </th>
              <th className="text-center" style={{ position: "sticky", top: "0" }}>
                <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                  <span>Fecha de realización </span>
                  {this.renderBotonOrden("fecha_realizacion")}
                </Flex>
              </th>
              <th className="text-center" style={{ position: "sticky", top: "0" }}>
                N° de viaje
              </th>
              <th className="text-center" style={{ position: "sticky", top: "0" }}>
                <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                  <span>Hora de salida </span>
                  {this.renderBotonOrden("hora_salida")}
                </Flex>
              </th>
              <th className="text-center" style={{ position: "sticky", top: "0" }}>
                <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                  <span>Hora de llegada </span>
                  {this.renderBotonOrden("hora_llegada")}
                </Flex>
              </th>
              <th className="text-center" style={{ position: "sticky", top: "0" }}>
                Reparto
              </th>
              <th className="text-center" style={{ position: "sticky", top: "0" }}>
                Estado actual
              </th>
              <th className="text-center" style={{ position: "sticky", top: "0" }}></th>
            </tr>
          </thead>

          <tbody>
            {repartosDiarios.map((repartoDiario, idx) => (
              <tr>
                <td className="text-center">{idx + 1}</td>
                <td className="text-center">{repartoDiario.id}</td>
                <td className="text-center">
                  {repartoDiario.fecha_realizacion
                    ? FechaUtils.normalizarFecha(repartoDiario.fecha_realizacion).toLocaleDateString("es-AR")
                    : "-"}
                </td>
                <td className="text-center">{repartoDiario.viaje_numero}</td>
                <td className="text-center">{repartoDiario.hora_salida ? repartoDiario.hora_salida : "-"}</td>
                <td className="text-center">{repartoDiario.hora_llegada ? repartoDiario.hora_llegada : "-"}</td>
                <td className="text-center">
                  <Badge variant="filled" radius="xs">
                    {repartoDiario.reparto.nombre}
                  </Badge>
                </td>
                <td className="text-center">
                  <Badge color="dark" variant="filled" radius="xs">
                    {repartoDiario.estado_actual}
                  </Badge>
                </td>
                <td className="text-center">{this.renderAccionMenu(repartoDiario.id)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };
}
