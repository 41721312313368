import { Divider } from "@mantine/core";
import React, { Component } from "react";
import * as Icon from "react-bootstrap-icons";
import { Button, Table } from "react-bootstrap";
import CargaProductoLoad from "./CargaProductoLoad";
import CargaBultoProductoLoad from "./CargaBultoProductoLoad";
import CargaMaquinaLoad from "./CargaMaquinaLoad";
import DatePicker from "react-datepicker";
import FechaUtils from "../../../../compartido/utils/FechaUtils";
import { CargaBultoProductoLectura, CargaMaquinaSimbolicaLectura, CargamentoLectura, CargaProductoLectura } from "serviciossaintmichel";




export interface Props {
  carga: CargamentoLectura;
  onChange?: Function;
  onValidezChange?: Function;
}

interface State {
  cargaFormulario: CargamentoLectura;
  validez: boolean;
}

export default class CargaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cargaFormulario: props.carga,
      validez: false,
    };
  }

  componentDidMount = () => {
    this.actualizarValidez();
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevState.cargaFormulario !== this.state.cargaFormulario) {
      if (this.props.onChange) {
        this.props.onChange(this.state.cargaFormulario);
      }
      this.actualizarValidez();
    }

    if (prevProps.carga !== this.props.carga) {
      this.setState({ cargaFormulario: this.props.carga });
    }

    if (prevState.validez !== this.state.validez) {
      if (this.props.onValidezChange) {
        this.props.onValidezChange(this.state.validez);
      }
    }
  }

  actualizarValidez = () => {
    const cargasProdutoValidas = this.state.cargaFormulario.cargas_producto.filter((cp) => cp.cantidad > 0);
    const cargasBultoProdutoValidas = this.state.cargaFormulario.cargas_bulto_producto.filter(
      (cbp) => cbp.cantidad > 0
    );
    const cargasMaquinaValidas = this.state.cargaFormulario.cargas_maquina_simbolica.filter((cm) => cm.cantidad > 0);

    const validez = cargasProdutoValidas.length + cargasBultoProdutoValidas.length + cargasMaquinaValidas.length > 0;
    this.setState({ validez: validez });
  };

  renderProductos = (cargasProducto: Array<CargaProductoLectura>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Producto</th>
            <th>Cantidad</th>
            <th>Estado del producto</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {cargasProducto.map((cargaProducto) => (
            <tr key={cargaProducto.producto.id}>
              <td>{cargaProducto.producto.nombre}</td>
              <td>{cargaProducto.cantidad}</td>
              <td>{cargaProducto.estado_producto}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => {
                    this.removerCargaProducto(cargaProducto);
                  }}
                >
                  <Icon.Trash />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderBultosProducto = (cargasBultoProducto: Array<CargaBultoProductoLectura>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Bulto de Producto</th>
            <th>Cantidad</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {cargasBultoProducto.map((cargaBultoProducto) => (
            <tr key={cargaBultoProducto.bulto_producto.id}>
              <td>{cargaBultoProducto.bulto_producto.nombre}</td>
              <td>{cargaBultoProducto.cantidad}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => {
                    this.removerCargaBultoProducto(cargaBultoProducto);
                  }}
                >
                  <Icon.Trash />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderMaquinas = (cargasMaquina: Array<CargaMaquinaSimbolicaLectura>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Máquina</th>
            <th>Cantidad</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {cargasMaquina.map((cargaMaquina) => (
            <tr key={cargaMaquina.maquina_simbolica.id}>
              <td>{cargaMaquina.maquina_simbolica.nombre}</td>
              <td>{cargaMaquina.cantidad}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => {
                    this.removerCargaMaquina(cargaMaquina);
                  }}
                >
                  <Icon.Trash />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  agregarCargaProducto = (cargaProducto: CargaProductoLectura) => {
    const nuevasCargasProducto = this.state.cargaFormulario.cargas_producto.map((cp) => cp);
    nuevasCargasProducto.push(cargaProducto);
    this.setState((prevState) => ({
      cargaFormulario: { ...prevState.cargaFormulario, cargas_producto: nuevasCargasProducto },
    }));
  };

  removerCargaProducto = (cargaProducto: CargaProductoLectura) => {
    const nuevasCargasProducto = this.state.cargaFormulario.cargas_producto.filter(
      (cp) => cp.producto.id !== cargaProducto.producto.id
    );
    this.setState((prevState) => ({
      cargaFormulario: { ...prevState.cargaFormulario, cargas_producto: nuevasCargasProducto },
    }));
  };

  agregarCargaBultoProducto = (cargaBultoProducto: CargaBultoProductoLectura) => {
    const nuevasCargasBultoProducto = this.state.cargaFormulario.cargas_bulto_producto.map((cbp) => cbp);
    nuevasCargasBultoProducto.push(cargaBultoProducto);
    this.setState((prevState) => ({
      cargaFormulario: { ...prevState.cargaFormulario, cargas_bulto_producto: nuevasCargasBultoProducto },
    }));
  };

  removerCargaBultoProducto = (cargaBultoProducto: CargaBultoProductoLectura) => {
    const nuevasCargasBultoProducto = this.state.cargaFormulario.cargas_bulto_producto.filter(
      (cbp) => cbp.bulto_producto.id !== cargaBultoProducto.bulto_producto.id
    );
    this.setState((prevState) => ({
      cargaFormulario: { ...prevState.cargaFormulario, cargas_bulto_producto: nuevasCargasBultoProducto },
    }));
  };

  agregarCargaMaquina = (cargaMaquina: CargaMaquinaSimbolicaLectura) => {
    const nuevasCargasProducto = this.state.cargaFormulario.cargas_maquina_simbolica.map((cm) => cm);
    nuevasCargasProducto.push(cargaMaquina);
    this.setState((prevState) => ({
      cargaFormulario: { ...prevState.cargaFormulario, cargas_maquina_simbolica: nuevasCargasProducto },
    }));
  };

  removerCargaMaquina = (cargaMaquina: CargaMaquinaSimbolicaLectura) => {
    const nuevasCargasMaquina = this.state.cargaFormulario.cargas_maquina_simbolica.filter(
      (cm) => cm.maquina_simbolica.id !== cargaMaquina.maquina_simbolica.id
    );
    this.setState((prevState) => ({
      cargaFormulario: { ...prevState.cargaFormulario, cargas_maquina_simbolica: nuevasCargasMaquina },
    }));
  };

  render() {
    const { cargaFormulario } = this.state;

    return (
      <div>
        <div>
          <strong>Momento de realización</strong>
          <br />
          <DatePicker
            showTimeSelect
            placeholderText="Ingrese momento de realización"
            onChange={(fecha: Date) => {
              this.setState((prevState) => ({
                cargaFormulario: { ...prevState.cargaFormulario, momento_realizacion: fecha },
              }));
            }}
            selected={
              cargaFormulario.momento_realizacion
                ? FechaUtils.normalizarFecha(cargaFormulario.momento_realizacion)
                : cargaFormulario.momento_realizacion
            }
            dateFormat="dd/MM/yyyy - HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            isClearable
          />
        </div>

        <Divider label="Productos" labelPosition="center" variant="dashed" />
        {this.renderProductos(cargaFormulario.cargas_producto)}
        <CargaProductoLoad
          onSubmitCallback={this.agregarCargaProducto}
          excluirProductos={cargaFormulario.cargas_producto.map((cp) => cp.producto.id)}
        />

        <Divider label="Bultos de productos" labelPosition="center" variant="dashed" />
        {this.renderBultosProducto(cargaFormulario.cargas_bulto_producto)}
        <CargaBultoProductoLoad
          onSubmitCallback={this.agregarCargaBultoProducto}
          excluirBultosProducto={cargaFormulario.cargas_bulto_producto.map((cbp) => cbp.bulto_producto.id)}
        />

        <Divider label="Máquinas" labelPosition="center" variant="dashed" />
        {this.renderMaquinas(cargaFormulario.cargas_maquina_simbolica)}
        <CargaMaquinaLoad
          onSubmitCallback={this.agregarCargaMaquina}
          excluirMaquinas={cargaFormulario.cargas_maquina_simbolica.map((cm) => cm.maquina_simbolica.id)}
        />
      </div>
    );
  }
}
