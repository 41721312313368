import InputBase from "./InputBase";

export default class InputDecimal extends InputBase {
  esValida(cadena) {
    const { cantEnteros, cantDecimales } = this.props;
    const expresionRegular = new RegExp("^\\d{1," + cantEnteros + "}(\\.{1}\\d{0," + cantDecimales + "}){0,1}$", "gi");
    console.log("CADENA", cadena);
    return expresionRegular.test(cadena) || cadena == "";
  }

  render() {
    return super.render();
  }
}
