import React, { Component } from "react";
import * as Icon from "react-bootstrap-icons";
import { Table, Button } from "react-bootstrap";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import VentaMaquinaLoad from "./VentaMaquinaLoad";
import { VentaMaquinaLectura } from "serviciossaintmichel";

export interface Props {
  ventasMaquina: Array<VentaMaquinaLectura>;
  onChange?: Function;
}

interface State {
  ventasMaquina: Array<VentaMaquinaLectura>;
}

export default class VentasMaquinaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ventasMaquina: props.ventasMaquina,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (prevProps.ventasMaquina !== this.props.ventasMaquina && this.state.ventasMaquina !== this.props.ventasMaquina) {
      this.setState({ ventasMaquina: this.props.ventasMaquina });
    }

    if (prevState.ventasMaquina !== this.state.ventasMaquina && this.props.onChange) {
      this.props.onChange(this.state.ventasMaquina);
    }
  }

  eliminarVentaMaquina = (idMaquina: number) => {
    const ventasMaquina = this.state.ventasMaquina.filter(
      (vm: VentaMaquinaLectura) => vm.maquina_simbolica.id !== idMaquina
    );
    this.setState({
      ventasMaquina: [...ventasMaquina],
    });
  };

  agregarVentaMaquina = (nuevaVentaMaquina: VentaMaquinaLectura): void => {
    this.setState((prevState) => ({
      ventasMaquina: [...prevState.ventasMaquina, nuevaVentaMaquina],
    }));
  };

  actualizarVentaMaquina = (idMaquina: number, infoVenta: VentaMaquinaLectura) => {
    const ventas = this.state.ventasMaquina.map((vm) => (vm.maquina_simbolica.id === idMaquina ? infoVenta : vm));
    this.setState({
      ventasMaquina: ventas,
    });
  };

  render() {
    const { ventasMaquina } = this.state;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Máquina</th>
              <th>Vendidas</th>
              <th>Bonificadas</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {ventasMaquina.map((ventaMaquina, idx) => (
              <tr key={ventaMaquina.maquina_simbolica.id}>
                <td>{ventaMaquina.maquina_simbolica.nombre}</td>
                <td>
                  <Input
                    type={InputTypes.Number}
                    data={ventaMaquina.vendidas}
                    onChange={(valor: number) => {
                      this.actualizarVentaMaquina(ventaMaquina.maquina_simbolica.id, {
                        ...ventaMaquina,
                        vendidas: valor,
                      });
                    }}
                  />
                </td>
                <td>
                  <Input
                    type={InputTypes.Number}
                    data={ventaMaquina.bonificadas}
                    onChange={(valor: number) => {
                      this.actualizarVentaMaquina(ventaMaquina.maquina_simbolica.id, {
                        ...ventaMaquina,
                        bonificadas: valor,
                      });
                    }}
                  />
                </td>

                <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      this.eliminarVentaMaquina(ventaMaquina.maquina_simbolica.id);
                    }}
                  >
                    <Icon.Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <VentaMaquinaLoad
          onSubmitCallback={this.agregarVentaMaquina}
          excluirMaquinas={ventasMaquina.map((vm) => vm.maquina_simbolica.id)}
        />
      </>
    );
  }
}
