import React, { Component } from "react";
import { CANALES_WEB_SOCKET, ClienteService, WebSocketService,ServiciosCliente } from "serviciossaintmichel";
import { Fuentes } from "../../../../../../compartido/estilos/estilos";
import FechaUtils from "../../../../../../compartido/utils/FechaUtils";
import { Global } from "../../../../../../Global";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import H from "../../../../../../libreria/message/title/H";
import ServicioActual from "./ServicioActual";
import ServicioProximo from "./ServicioProximo";


export interface Props {
  clienteId: number;
}

interface State {
  serviciosCliente: ServiciosCliente | null;
  renderNewServicio: boolean;
}

export default class ServicioClienteActualizar extends Component<Props, State> {


  private clienteService:ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      serviciosCliente: null,
      renderNewServicio: false,
    };

    this.clienteService =  new ClienteService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = () => {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_SERVICIO_ACTIVO,
      funcionCallback: this.manejarMensajeWS,
    });
    this.getServiciosCliente();
  };

  manejarMensajeWS = (e: MessageEvent) => {
    const clienteId = JSON.parse(e.data).message.clienteId;
    if (clienteId === this.props.clienteId) {
      this.getServiciosCliente();
    }
  };

  getServiciosCliente = async () => {
    try {
      const serviciosCliente = await this.clienteService.getServiciosActivos(this.props.clienteId);
      this.setState({ serviciosCliente: serviciosCliente });
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error, "ServiciosCliente");
    }
  };

  render = () => {
    const { clienteId } = this.props;
    const { serviciosCliente } = this.state;

    if (serviciosCliente === null) {
      return <div>El cliente no posee un servicio actual o futuro</div>;
    }

    let fechaInicioProximoServicio;
    if (serviciosCliente.proximo) {
      fechaInicioProximoServicio = FechaUtils.normalizarFecha(serviciosCliente.proximo.fecha_inicio);
    }

    let fechaFinServicioActual;
    if (serviciosCliente.actual) {
      fechaFinServicioActual = FechaUtils.normalizarFecha(serviciosCliente.actual.fecha_fin);
    }

    return (
      <Flex alignItems="center" flexDirection="column">
        <Flex justifyContent="space-around" flexDirection="row" flexWrap="wrap">
          <Flex flexDirection="column" alignItems="center" width="auto">
            <H size={3} fuente={Fuentes.Titulos} texto="Servicio actual" />
            <ServicioActual
              clienteId={clienteId}
              servicioCliente={serviciosCliente.actual}
              fechaInicioProximoServicio={fechaInicioProximoServicio}
            />
          </Flex>
          <Flex flexDirection="column" alignItems="center" flexWrap="nowrap" width="auto">
            <H size={3} fuente={Fuentes.Titulos} texto="Servicio próximo" />
            <ServicioProximo
              clienteId={clienteId}
              servicioCliente={serviciosCliente.proximo}
              fechaFinServicioActual={fechaFinServicioActual}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  };
}
