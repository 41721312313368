import React, { Component } from "react";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ProgramacionEntrega } from "serviciossaintmichel";
import Flex from "../../../libreria/appearance/flex/Flex";

interface Props {
  programaciones: Array<ProgramacionEntrega>;
  callbackParent: Function; // funcion ejecutada una vez que se realiza alguna accion
}

interface State {
  fechaReprogramacion: Date | null;
}

export default class PanelReprogramacion extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fechaReprogramacion: null,
    };
  }

  render(): React.ReactNode {
    const { fechaReprogramacion } = this.state;

    return (
      <Flex gap="1rem">
        <Button
          onClick={() => {
            alert("Actualizar el estado de las programaciones recibidas");
          }}
        >
          Marcar para reprogramar
        </Button>

        <Flex gap="1rem">
          <div>
            <strong>Reprogramar para una fecha posterior</strong>
          </div>
          <DatePicker
            placeholderText="Seleccione una fecha"
            onChange={(fecha: Date) => {
              this.setState({ fechaReprogramacion: fecha });
            }}
            selected={fechaReprogramacion}
            dateFormat="dd/MM/yyyy"
          />
          <Button
            disabled={fechaReprogramacion === null}
            onClick={() => {
              alert("Enviar a reprogramar con la nueva fecha");
            }}
          >
            Reprogramar
          </Button>
        </Flex>
      </Flex>
    );
  }
}
