import React, { Component } from "react";
import { SelectItem } from "@mantine/core";
import { PropsSelectGenericoConIdItems } from "../../../../compartido/components/select/PropsSelectGenerico";
import SelectBusqueda, { PropsSelectGeneral } from "../../../../compartido/components/select/SelectBusqueda";
import Flex from "../../../../libreria/appearance/flex/Flex";
import SelectItemUtils from "../../../../compartido/utils/SelectItemUtils";
import { Reparto } from "serviciossaintmichel";

interface State {}

export default class RepartosSelect extends Component<
  PropsSelectGenericoConIdItems<Reparto> & PropsSelectGeneral,
  State
> {
  constructor(props: PropsSelectGenericoConIdItems<Reparto> & PropsSelectGeneral) {
    super(props);
    this.state = {};
  }

  getPosicion = (valor: number | null): string | null => {
    if (valor != null) return this.props.items.findIndex((item) => item.id === valor).toString();
    else return valor;
  };

  getSeleccionado = (indice: number | null) => {
    if (indice != null) this.props.callbackParent(this.props.items[indice]);
    else this.props.callbackParent(indice);
  };

  armarDatosSelect(): SelectItem[] {
    let arreglo: SelectItem[] = [];
    arreglo = this.props.items.map((item, index) => ({
      value: index.toString(),
      label: item.nombre,
    }));
    return SelectItemUtils.ordenarLabelAlfabeticamente(arreglo);
  }
  render() {
    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <SelectBusqueda
          {...this.props}
          placeholder="Seleccione un reparto"
          data={this.armarDatosSelect()}
          callbackParent={this.getSeleccionado}
          value={this.getPosicion(this.props.seleccionado)}
        />
      </Flex>
    );
  }
}
