import React, { Component } from "react";
import { PropsSelectGenerico } from "../../../../compartido/components/select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../../../../compartido/components/select/SelectBusqueda";
import SelectComponent from "../../../../compartido/components/select/SelectComponent";
import Flex from "../../../../libreria/appearance/flex/Flex";

import {
  BultoProductoGet,
  BultoProductoService,
  CANALES_WEB_SOCKET,
  DatosBultoProducto,
  WebSocketService,
} from "serviciossaintmichel";
import { Global } from "../../../../Global";
import MultiSelectComponent, { PropsMultiSelect } from "../../../../compartido/components/select/MultiSelectComponent";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
  version: Version;
  propsSelect?: PropsSelectGenerico<DatosBultoProducto> & PropsSelectGeneral;
  propsMultiSelect?: PropsMultiSelect<DatosBultoProducto> & PropsSelectGeneral;
  filtros?: Array<Function>; // lista de filtros sobre los datos (solo sirve para el select)
}

export enum Version {
  SELECT,
  MULTI_SELECT,
}

interface State {
  bultosProducto: Array<DatosBultoProducto> | null;
}

export default class BultosProductoShow extends Component<Props, State> {
  private bultoProductoService: BultoProductoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      bultosProducto: null,
    };

    this.bultoProductoService = new BultoProductoService(Global.UsuarioService.getToken()!);
  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_BULTO_PRODUCTOS,
      funcionCallback: this.getBultosProducto,
    });
    this.getBultosProducto();
  }

  getBultosProducto = () => {
    switch (this.props.version) {
      case Version.MULTI_SELECT:
      case Version.SELECT: {
        this.bultoProductoService
          .listAll()
          .then((result) => {
            this.setState({ bultosProducto: result });
          })
          .catch((error) => {
            this.bultoProductoService.manejarErrorHTTP(error, "Bulto Producto");
          });
        break;
      }
      default:
        break;
    }
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };

  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  render() {
    const { bultosProducto } = this.state;
    const { propsSelect, propsMultiSelect, version, filtros } = this.props;

    if (bultosProducto === null) {
      return null;
    }

    // si hay filtros, chequea aquellos combos que cumplen con todos los filtros
    const items = filtros ? bultosProducto.filter((bp) => filtros.every((fnc) => fnc(bp))) : bultosProducto;

    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {version === Version.SELECT && propsSelect ? (
          <SelectComponent
            {...propsSelect}
            items={items}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado ? propsSelect.seleccionado : null}
            labelGetter={(obj: BultoProductoGet) => obj.nombre}
          />
        ) : null}
        {version === Version.MULTI_SELECT && propsMultiSelect ? (
          <MultiSelectComponent<DatosBultoProducto>
            items={items}
            seleccionados={propsMultiSelect.seleccionados}
            labelGetter={(bultoProd: DatosBultoProducto) => bultoProd.nombre}
            valueGetter={(bultoProd: DatosBultoProducto) => bultoProd.id.toString()}
            onChangeHandler={propsMultiSelect.callbackParent}
            clearable={propsMultiSelect.clearable}
          />
        ) : null}
      </Flex>
    );
  }
}
