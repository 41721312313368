import React, { Component } from "react";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Visibilizador from "../../../../../../libreria/action/visibilizador/Visibilizador";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../../libreria/containers/box/Box";
import H from "../../../../../../libreria/message/title/H";
import ProductoPromocionNew from "./ProductoPromocionNew";
import ProductosPromocionShow from "./ProductosPromocionShow";
import { CANALES_WEB_SOCKET,  ProductoPromocion,  PromocionService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";



export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  idPromocion: number;
}
interface State {
  productosPromocion: Array<ProductoPromocion>;
  screen: ScreenType;
}

export default class ProductosPromocionUpdate extends Component<Props, State> {

  private promocionService:PromocionService;

  constructor(props: Props) {
    super(props);
    this.state = {
      productosPromocion: [],
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    window.addEventListener("resize", this.actualizarDimensiones);

    this.promocionService =  new PromocionService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  async componentDidMount() {
    await this.cargarPromocion();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PROMOCIONES,
      funcionCallback: this.onChannelMesagge,
    });
  }

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.promocionId == this.props.idPromocion) {
      this.cargarPromocion();
    }
  };

  cargarPromocion = async () => {
    let promocion = await this.promocionService.get(this.props.idPromocion).catch(
      (error) => {
        this.promocionService.manejarErrorHTTP(error, "Promocion");
        return null;
      }
    );
    if (promocion) {
      this.setState({ productosPromocion: promocion.productos });
    } else this.setState({ productosPromocion: [] /* , cargando: false */ });
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      /* this.setState({ promocion: this.props.idPromocion }); */
    }
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="70%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={3} texto={"Productos"} />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Flex
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
            marginTop="20px"
            marginBottom="20px"
          >
            <Visibilizador label="Nuevo Producto" labelAlignment="center">
              <ProductoPromocionNew idPromocion={this.props.idPromocion} />
            </Visibilizador>
          </Flex>
          <Flex
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
            marginTop="40px"
          >
            <ProductosPromocionShow
              idPromocion={this.props.idPromocion}
              productos={this.state.productosPromocion}
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
