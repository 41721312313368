import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
import RepartosDiarios from "./components/RepartosDiarios";

export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];

  if (Global.UsuarioService.getPermisos().Distribucion.RepartosDiarios.RepartoDiario.hasAccess()) {
    aux.push({
      name: "RepartoDiario",
      path: SeccionRepartosDiarios.pathBase + "/reparto-diario",
      component: RepartosDiarios,
    });
  }
  return aux;
}

export default class SeccionRepartosDiarios extends Component<Props> {
  static pathBase = "/distribucion/seccion-repartos-diarios";
  render() {
    return <Seccion rutasNavbar={getRutasAccesibles()} tituloNavbar={"Sección Repartos Diarios"} />;
  }
}
