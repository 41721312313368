import { Divider } from "@mantine/core";
import React, { Component } from "react";
import { CargamentoLectura, RepartoDiarioService } from "serviciossaintmichel";
import { Global } from "../../../../Global";
import H from "../../../../libreria/message/title/H";
import CargamentoDetalle from "./CargamentoDetalle";
import RepartoDiarioCargasShow from "./RepartoDiarioCargasShow";
import RepartoDiarioDescargasShow from "./RepartoDiarioDescargasShow";

export interface Props {
  idRepartoDiario: number;
}

interface State {
  descargas: Array<CargamentoLectura> | null;
}

export default class RepartoDiarioCargamentoShow extends Component<Props, State> {
  private webSockets: Array<WebSocket>;

  private repartoDiarioService:RepartoDiarioService;


  constructor(props: Props) {
    super(props);
    this.webSockets = [];
    this.state = {
      descargas: null,
    };

    this.repartoDiarioService =  new RepartoDiarioService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    const descargas = await this.repartoDiarioService.getDescargas(this.props.idRepartoDiario);

    this.setState({ descargas: descargas });
  };

  componentWillUnmount = () => {
    this.webSockets.forEach((ws: WebSocket) => ws.close());
  };

  renderCarga = (carga: CargamentoLectura) => {
    return (
      <div style={{ backgroundColor: "white", border: "1px solid black", borderRadius: "5px", padding: "1rem" }}>
        <CargamentoDetalle cargamento={carga} />
      </div>
    );
  };

  render() {
    const { idRepartoDiario } = this.props;
    const { descargas } = this.state;

    if (descargas === null) {
      return <div>Cargando...</div>;
    }

    return (
      <div>
        <H size={4} texto="Cargas" />
        <RepartoDiarioCargasShow idRepartoDiario={idRepartoDiario} renderMethod={this.renderCarga} />
        <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />
        <H size={4} texto="Descargas" />
        <RepartoDiarioDescargasShow idRepartoDiario={idRepartoDiario} renderMethod={this.renderCarga} />
      </div>
    );
  }
}
