import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import ProductosSeguridadHigiene from "./ProductoSeguridadHigiene/components/ProductosSeguridadHigiene";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];
  
  if (Global.UsuarioService.getPermisos().Elementos.ProductosSeguridadHigiene.hasAccess()) {
    aux.push({
      name: "Productos Seguridad & Higiene",
      path: SeccionProductosSeguridadHigiene.pathBase + "/productos-s&h",
      component: ProductosSeguridadHigiene,
    })
  }
  return aux;
}

export default class SeccionProductosSeguridadHigiene extends Component<Props> {
  static pathBase = "/elementos/seccion-productos-s&h";
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Productos Seguridad & Higiene"}
      />
    );
  }
}
