import React, { Component } from "react";
import { OrdenVisitaRepartoDiarioLectura } from "serviciossaintmichel";
import { Item } from "../../../../libreria/data/listbox/Item";
import ListBox from "../../../../libreria/data/listbox/ListBox";

interface Props {
  onSelect: Function;
  seleccionado?: number;
  ordenesVisita: Array<OrdenVisitaRepartoDiarioLectura>;
}

interface State {}

export class RepartoDiarioOrdenVisitasListBox extends Component<Props, State> {
  armarListBox = (ordenesVisita: Array<OrdenVisitaRepartoDiarioLectura>): Array<Item> => {
    let list: Array<Item> = ordenesVisita.map((ordenVisita) => {
      const { visita, orden } = ordenVisita;
      return {
        id: ordenVisita.id,
        text: `ID VISITA: ${visita.id} - PRIORIDAD: ${visita.prioridad} - DIRECCIÓN: ${visita.direccion.calle} e/ ${visita.direccion.entre_1} y ${visita.direccion.entre_2} - ORDEN: ${orden}`,
      };
    });
    return list;
  };

  onSelect = (id: number | null) => {
    if (this.props.onSelect) this.props.onSelect(id);
  };

  render() {
    const { ordenesVisita } = this.props;
    return (
      <ListBox
        height="auto"
        width="100%"
        items={this.armarListBox(ordenesVisita)}
        tipo={{ select: true, view: false, delete: false }}
        onSelect={this.onSelect}
        selected={this.props.seleccionado}
      />
    );
  }
}
