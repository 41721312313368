import React, { Component } from "react";
import ManejadorErroresHTTP from "../../../../../../compartido/utils/ManejadorErroresHTTP";
import Swal from "sweetalert2";
import ListDireccionesCliente from "./ListDireccionesCliente";
import SelectDireccionesCliente from "./SelectDireccionesCliente";
import { CANALES_WEB_SOCKET, ClienteService, DireccionDTO, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";





interface Props {
  clienteId: number;
  direccionDetalleId?: number;
  idSeleccionado?: number;
  onUpdate?: Function;
  onDelete?: Function;
  onDetail?: Function;
  onSelect?: Function;
  showDelete?: boolean;
  showUpdate?: boolean;
  showDetail?: boolean;
  version: Version;
}

export enum Version {
  SELECT,
  LIST,
}

interface State {
  direcciones: Array<DireccionDTO> | null;
}

export default class ClienteDireccionesShow extends Component<Props, State> {

  private clienteService:ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      direcciones: null,
    };

    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    this.getDireccionesCliente();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.COMPARTIDO_DIRECCION,
      funcionCallback: this.refrescarDirecciones,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_DIRECCIONES_CLIENTES,
      funcionCallback: this.refrescarDirecciones,
    });
  };

  getDireccionesCliente = async () => {
    const direcciones = await this.clienteService.getDirecciones(this.props.clienteId);
    this.setState({ direcciones: direcciones });
  };

  refrescarDirecciones = async (e: MessageEvent) => {
    let clienteId = JSON.parse(e.data).message.clienteId;
    if (clienteId === this.props.clienteId) {
      this.getDireccionesCliente();
    }
  };

  displayModalConfirmacion = async () => {
    const result = await Swal.fire({
      icon: "question",
      title: "Borrar direccion?",
      showDenyButton: true,
      confirmButtonText: "Confirmar",
      denyButtonText: "Cancelar",
    });

    return result.isDenied;
  };

  onDelete = async (direccionId: number) => {
    const { clienteId } = this.props;

    const cancelar = await this.displayModalConfirmacion();

    if (cancelar) {
      return;
    }

    try {
      await this.clienteService.desvincularDireccion(clienteId, direccionId);

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ADMINISTRACION_DIRECCIONES_CLIENTES, {
        clienteId: clienteId,
        direccionId: direccionId,
      });

      if (this.state.direcciones !== null) {
        const direccionesRes = this.state.direcciones.filter((direccion: DireccionDTO) => direccion.id !== direccionId);
        this.setState({ direcciones: direccionesRes });
      }

      if (this.props.onDelete) {
        this.props.onDelete();
      }
    } catch (error) {
      ManejadorErroresHTTP.evaluarError(error);
    }
  };

  render = () => {
    const { direcciones } = this.state;
    const {
      onUpdate = () => {},
      onDetail = () => {},
      onSelect = () => {},
      showUpdate = false,
      showDelete = false,
      showDetail = false,
      direccionDetalleId,
      version,
      idSeleccionado,
    } = this.props;

    if (direcciones === null) {
      return <div> {"Cargando..."}</div>;
    }

    if (direcciones.length < 1) {
      return <div> {"El cliente no posee direcciones registradas."}</div>;
    }

    switch (version) {
      case Version.LIST: {
        return (
          <ListDireccionesCliente
            direcciones={direcciones}
            showDelete={showDelete}
            onDelete={this.onDelete}
            showUpdate={showUpdate}
            onUpdate={onUpdate}
            showDetail={showDetail}
            onDetail={onDetail}
            direccionDetalleId={direccionDetalleId}
          />
        );
      }
      case Version.SELECT: {
        return (
          <SelectDireccionesCliente direcciones={direcciones} onSelected={onSelect} seleccionado={idSeleccionado} />
        );
      }
      default:
        return null;
    }
  };
}
