import React, { Component } from "react";
import * as Icon from "react-bootstrap-icons";
import { Table, Button } from "react-bootstrap";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import VentaComboRetornableLoad from "./VentaComboRetornableLoad";
import { VentaComboRetornableLectura } from "serviciossaintmichel";

export interface Props {
  ventasCombo: Array<VentaComboRetornableLectura>;
  onChange?: Function;
}

interface State {
  ventasCombo: Array<VentaComboRetornableLectura>;
}

export default class VentasComboRetornableLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ventasCombo: props.ventasCombo,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (prevProps.ventasCombo !== this.props.ventasCombo && this.state.ventasCombo !== this.props.ventasCombo) {
      this.setState({ ventasCombo: this.props.ventasCombo });
    }

    if (prevState.ventasCombo !== this.state.ventasCombo && this.props.onChange) {
      this.props.onChange(this.state.ventasCombo);
    }
  }

  eliminarVentaCombo = (idCombo: number) => {
    const ventasCombo = this.state.ventasCombo.filter(
      (vc: VentaComboRetornableLectura) => vc.combo_retornable.id !== idCombo
    );
    this.setState({
      ventasCombo: [...ventasCombo],
    });
  };

  agregarVentaCombo = (nuevaVentaCombo: VentaComboRetornableLectura): void => {
    this.setState((prevState) => ({
      ventasCombo: [...prevState.ventasCombo, nuevaVentaCombo],
    }));
  };

  actualizarVentaCombo = (idCombo: number, infoVenta: VentaComboRetornableLectura) => {
    const ventas = this.state.ventasCombo.map((vc) => (vc.combo_retornable.id === idCombo ? infoVenta : vc));
    this.setState({
      ventasCombo: ventas,
    });
  };

  render() {
    const { ventasCombo } = this.state;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Combo Retornable</th>
              <th>Cantidad</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {ventasCombo.map((ventaCombo, idx) => (
              <tr key={ventaCombo.combo_retornable.id}>
                <td>{ventaCombo.combo_retornable.nombre}</td>
                <td>
                  <Input
                    type={InputTypes.Number}
                    data={ventaCombo.cantidad}
                    onChange={(valor: number) => {
                      this.actualizarVentaCombo(ventaCombo.combo_retornable.id, {
                        ...ventaCombo,
                        cantidad: valor,
                      });
                    }}
                    min={1}
                  />
                </td>

                <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      this.eliminarVentaCombo(ventaCombo.combo_retornable.id);
                    }}
                  >
                    <Icon.Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <VentaComboRetornableLoad
          onSubmitCallback={this.agregarVentaCombo}
          excluirCombos={ventasCombo.map((vc) => vc.combo_retornable.id)}
        />
      </>
    );
  }
}
