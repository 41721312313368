import React, { Component } from "react";
import Visibilizador from "../../../../../../libreria/action/visibilizador/Visibilizador";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Text from "../../../../../../libreria/message/text/Text";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import { CANALES_WEB_SOCKET, Cheque, ChequePost, WebSocketService, ChequeService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";

export interface Props {
  id: number;
  soloDatos?: boolean;
}
interface State {
  cheque: Cheque | null;
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
}

export default class DatosChequeShow extends Component<Props, State> {
  private chequeService: ChequeService;

  constructor(props: Props) {
    super(props);
    this.state = {
      cheque: null,
      cargando: true,
      visible: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    this.chequeService = new ChequeService(Global.UsuarioService.getToken()!);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_CHEQUES,
      funcionCallback: this.onChannelMesagge,
    });

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {
    const cheque = await this.chequeService.get(this.props.id);
    this.setState({ cheque: cheque, cargando: false });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    const cheque = await this.chequeService.get(this.props.id);
    this.setState({ cheque: cheque, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({ cargando: true });
      const cheque = await this.chequeService.get(this.props.id);
      this.setState({ cheque: cheque, cargando: false });
    }
  };

  renderDatos = () => (
    <Flex container flexDirection="column" width="100%" alignItems="flex-start" justifyContent="center">
      <Text>Número: {this.state.cheque!.numero}</Text>
      <Text>Fecha de emisión: {this.state.cheque!.fecha_emision}</Text>
    </Flex>
  );

  renderLoading = () => "Cargando Datos";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="2px"
      >
        {this.props.soloDatos ? (
          this.state.cargando ? (
            this.renderLoading()
          ) : (
            this.renderDatos()
          )
        ) : (
          <Visibilizador onChange={this.onChangeVisibilizador} label={"Datos"} labelAlignment={"flex-start"}>
            {this.state.cargando ? this.renderLoading() : this.renderDatos()}
          </Visibilizador>
        )}
      </Flex>
    );
  }
}
