import React, { Component } from "react";
import DatePicker from "react-datepicker";
import {
  Cheque,
  Pago,
  TipoPago,
  EsConCheque,
  CrearChequeVacio,
  PagoFactura,
  CrearTransferenciaBancariaVacia,
  TransferenciaBancaria,
  pagaConTransferencia,
} from "serviciossaintmichel";
import ChequeLoad from "../../../cheques/components/load/ChequeLoad";
import TipoPagosShow, { Version } from "../show/TipoPagosShow";
import DistribuidorPagoFacturas from "./DistribuidorPagoFacturas";
import { NumberInput, Flex, Divider } from "@mantine/core";
import TransferenciaBancariaLoad from "../../../transferencias-bancarias/TransferenciaBancariaLoad";

export interface Props {
  clienteId: number;
  onChange: Function;
  onValidationChange: Function;
  pago: Pago;
}
interface State {
  pago: Pago;
  validation: boolean;
}

export default class PagoVoluntarioLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pago: this.props.pago,
      validation: false,
    };
  }

  componentDidMount = () => {
    this.updateValidation(this.state.pago);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevProps.pago !== this.props.pago) {
      this.setState({ pago: this.props.pago });
    }
    if (prevState.validation !== this.state.validation) {
      this.props.onValidationChange(this.state.validation);
    }
    if (prevState.pago !== this.state.pago) {
      this.updateValidation(this.state.pago);
      this.props.onChange(this.state.pago);
    }
  };

  medioPagoValido = (pago: Pago): boolean => {
    if (EsConCheque(pago.tipo_pago)) {
      return pago.cheque !== null && pago.cheque.banco.id > -1 && pago.cheque.fecha_emision && pago.cheque.numero > -1;
    }

    if (pagaConTransferencia(pago.tipo_pago)) {
      return (
        pago.transferencia_bancaria !== null &&
        pago.transferencia_bancaria.cuenta_origen.id > 0 &&
        pago.transferencia_bancaria.cuenta_destino.id > 0 &&
        pago.transferencia_bancaria.numero_comprobante > 0
      );
    }

    return true;
  };

  isValid = (pago: Pago) => {
    const asignadoAFacturas = pago.pagos_factura.reduce((total, pago) => total + pago.monto, 0);
    return (
      pago.monto > 0 &&
      pago.monto === asignadoAFacturas &&
      pago.fecha &&
      pago.tipo_pago.id > -1 &&
      this.medioPagoValido(pago)
    );
  };

  updateValidation = (pago: Pago) => {
    let validation: boolean = this.isValid(pago);
    this.setState({ validation: validation });
    if (this.props.onValidationChange) this.props.onValidationChange(validation);
  };

  setearMonto = (monto: number) => {
    this.setState((prevState) => ({
      pago: { ...prevState.pago, monto: monto },
    }));
  };

  setearFecha = (fecha: Date) => {
    this.setState((prevState) => ({
      pago: { ...prevState.pago, fecha: fecha },
    }));
  };

  setearPagosFactura = (pagosFactura: Array<Pick<PagoFactura, "factura" | "monto">>) => {
    this.setState((prevState) => ({
      pago: { ...prevState.pago, pagos_factura: pagosFactura },
    }));
  };

  onChangeTipoPago = (tipoPago: TipoPago | null) => {
    let cheque: Cheque | null = null;
    if (tipoPago && EsConCheque(tipoPago)) {
      cheque = CrearChequeVacio();
    }

    let transferenciaBancaria: TransferenciaBancaria | null = null;
    if (tipoPago && pagaConTransferencia(tipoPago)) {
      transferenciaBancaria = CrearTransferenciaBancariaVacia();
    }

    this.setState((prevState) => ({
      pago: {
        ...prevState.pago,
        tipo_pago: tipoPago ? tipoPago : { id: -1, tipo: "" },
        cheque: cheque,
        transferencia_bancaria: transferenciaBancaria,
      },
    }));
  };

  onChangeCheque = (cheque: Cheque | null) => {
    this.setState((prevState) => ({
      pago: { ...prevState.pago, cheque: cheque },
    }));
  };

  onChangeTransferencia = (transferenciaBancaria: TransferenciaBancaria | null) => {
    this.setState((prevState) => ({
      pago: { ...prevState.pago, transferencia_bancaria: transferenciaBancaria },
    }));
  };

  setearComprobante = (comprobante: string) => {
    // TODO consultar con Rodo
    let pago = this.state.pago;
    pago.comprobante = comprobante;
    this.setState({ pago: pago });
    this.props.onChange(pago);
    this.updateValidation(pago);
  };

  renderFormularioTipoPago = () => {
    const { pago } = this.state;

    if (EsConCheque(pago.tipo_pago)) {
      return (
        <>
          <Divider label="Datos del cheque" labelPosition="center" size={"lg"} />
          <ChequeLoad
            cheque={pago.cheque!}
            onChange={this.onChangeCheque}
            onValidationChange={() => {
              this.updateValidation(pago);
            }}
          />
        </>
      );
    }

    if (pagaConTransferencia(pago.tipo_pago)) {
      return (
        <>
          <Divider label="Datos de la transferencia" labelPosition="center" size={"lg"} />
          <TransferenciaBancariaLoad
            clienteId={this.props.clienteId}
            transferenciaBancaria={
              pago.transferencia_bancaria === null ? CrearTransferenciaBancariaVacia() : pago.transferencia_bancaria
            }
            onChange={this.onChangeTransferencia}
            onValidationChange={() => {
              this.updateValidation(pago);
            }}
          />
        </>
      );
    }
  };

  render() {
    const { pago } = this.state;

    return (
      <Flex direction={"column"} align={"center"} justify={"center"} gap={"xs"}>
        <NumberInput
          size="md"
          label="Monto"
          value={pago.monto}
          onChange={this.setearMonto}
          min={1}
          hideControls
          style={{ width: "100%" }}
        />
        <Flex direction={"column"} style={{ width: "100%" }}>
          <div>Fecha</div>
          <DatePicker selected={pago.fecha} onChange={this.setearFecha} dateFormat="dd/MM/yyyy" />
        </Flex>
        <TipoPagosShow
          version={Version.SELECT}
          propsSelect={{
            seleccionado: {
              id: pago.tipo_pago.id,
              tipo: pago.tipo_pago.tipo,
            },
            callbackParent: this.onChangeTipoPago,
            sinTitulo: true,
          }}
        />
        {this.renderFormularioTipoPago()}
        <Divider label="Asignacion a facturas" labelPosition="center" size={"lg"} />

        <DistribuidorPagoFacturas
          clienteId={this.props.clienteId}
          montoPago={pago.monto}
          callback={this.setearPagosFactura}
        />
      </Flex>
    );
  }
}
