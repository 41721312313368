import React, { Component } from "react";
import { PropsSelectGenerico } from "../select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../select/SelectBusqueda";
import PropietariosSelect from "./PropietariosSelect";
import { Global } from "../../../Global";
import {
  CANALES_WEB_SOCKET,
  Propietario,
  PropietarioService,
  WebSocketService,
} from "serviciossaintmichel";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
  version: Version;
  excluirIds?: Array<number>;
  propsSelect?: PropsSelectGenerico<Propietario> & PropsSelectGeneral;
}

export enum Version {
  SELECT,
}

interface State {
  propietarios: Array<Propietario> | null;
}

export default class PropietariosShow extends Component<Props, State> {
  private propietarioService: PropietarioService;
  constructor(props: Props) {
    super(props);
    this.state = { propietarios: null };
    this.propietarioService = new PropietarioService(
      Global.UsuarioService.getToken()!
    );
  }

  async componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_PERSONAL_PROPIETARIOS,
      funcionCallback: this.getPropietarios,
    });
    const propietarios = await this.getPropietarios();
    this.setState({ propietarios: propietarios });
  }

  getPropietarios = async () => {
    return await this.propietarioService.listAll();
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  render() {
    const { version, excluirIds = [], propsSelect } = this.props;
    const { propietarios } = this.state;

    if (propietarios === null) {
      return null;
    }

    const propietariosFiltrados = propietarios.filter(
      (e) => excluirIds.indexOf(e.id) === -1
    );

    switch (version) {
      case Version.SELECT: {
        if (!propsSelect) {
          return null;
        }
        return (
          <PropietariosSelect
            {...this.props.propsSelect}
            callbackParent={propsSelect.callbackParent}
            seleccionado={
              propsSelect.seleccionado ? propsSelect.seleccionado : null
            }
            items={propietariosFiltrados}
          />
        );
      }
      default:
        return null;
    }
  }
}
