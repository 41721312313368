import React, { Component } from "react";
import { LIMITES } from "../../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../../compartido/components/tabla/TablaGenerica";
import Flex from "../../../../../libreria/appearance/flex/Flex";



import { CANALES_WEB_SOCKET,  ConsumoGet,  ConsumoService,   WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";



export interface Props {
  onUpdate?: Function;
  onView?: Function;
  onSelect?: Function;
  version: Version;
}

export enum Version {
  SELECT,
  TABLE,
}

export default class ConsumosShow extends Component<Props, {}> {


  constructor(props: Props) {
    super(props);


  }

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };
  onSelect = (id: number) => {
    if (this.props.onSelect) this.props.onSelect(id);
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.props.version == Version.SELECT ? "" : null}
        {this.props.version == Version.TABLE ? (
          <ConsumosTable onUpdate={this.onUpdate} onView={this.onView} />
        ) : null}
      </Flex>
    );
  }
}

// ------------------------------------------

// Version Table

interface PropsTable {
  onUpdate: Function;
  onView: Function;
}

interface StateTable {
  Consumoes: Array<ConsumoGet>;
}

class ConsumosTable extends Component<PropsTable, StateTable> {

  private consumoService:ConsumoService;

  constructor(props: PropsTable) {
    super(props);
    this.state = {
      Consumoes: [],
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CONSUMOS,
      funcionCallback: this.getConsumoes,
    });

    this.consumoService =  new ConsumoService(Global.UsuarioService.getToken()!);

  }

  getConsumoes = () => {
    this.consumoService.list()
      .then((result) => {
        this.setState({ Consumoes: result });
      })
      .catch((error) => {
        this.consumoService.manejarErrorHTTP(error, "Consumo");
      });
  };

  componentDidMount() {
    this.consumoService.paramsURL.setearLimite(LIMITES[0]);
    this.getConsumoes();
  }

  armarFilas(Consumoes: Array<ConsumoGet>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (Consumoes.length > 0) {
      for (let i = 0; i < Consumoes.length; i++) {
        let fila: Array<string> = [];
        fila.push(Consumoes[i].nombre);
        let filaResult: FilaTablaGenerica = {
          id: Consumoes[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.onUpdate(id);
  };

  handleVer = (id: number) => {
    this.props.onView(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Administracion.Servicios.Consumo.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Administracion.Servicios.Consumo.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.consumoService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.Consumoes)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.consumoService.paramsURL}
        listarElementos={this.getConsumoes}
        id="TablaConsumoes"
      />
    );
  }
}
