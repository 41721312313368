import React, { Component } from "react";
import Swal from "sweetalert2";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import Screen, { ScreenType } from "../../../../../../../compartido/models/Screen";
import RequestButton from "../../../../../../../libreria/action/request-button/RequestButton";
import Box from "../../../../../../../libreria/containers/box/Box";
import H from "../../../../../../../libreria/message/title/H";
import EmailClienteLoad from "./EmailClienteLoad";


import { CANALES_WEB_SOCKET, ClienteService, Email, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../../Global";


export interface Props {
  clienteId: number;
  emailId: number;
  onCloseCallback?: Function;
}

interface State {
  email: Email | null;
  screen: ScreenType;
  valid: boolean;
}

export default class EmailClienteUpdate extends Component<Props, State> {

  private clienteService:ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      email: null,
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = () => {
    window.addEventListener("resize", this.actualizarDimensiones);
    this.getEmail();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevProps.emailId !== this.props.emailId) {
      this.getEmail();
    }
  };

  getEmail = async () => {
    const { clienteId, emailId } = this.props;
    try {
      const email = await this.clienteService.getEmail(clienteId, emailId);
      this.setState({ email: email });
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error, "Email");
    }
  };

  actualizarDimensiones = () => {
    this.setState({ screen: Screen.getType(window.innerWidth, window.innerHeight) });
  };

  onSave = async (email: Email) => {
    try {
      const resultado = await this.clienteService.actualizarEmail(this.props.clienteId, email.id, email);

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_EMAILS, {
        id: resultado.id,
        clienteId: resultado.cliente,
      });

      await Swal.fire({
        icon: "success",
        text: "Email Actualizado",
      });
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error, "Email");
    }
  };

  onChange = (email: Email) => {
    this.setState({ email: email });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex flexDirection="column" width="60%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex flexDirection="column" width="50%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  onClose = () => {
    if (this.props.onCloseCallback) {
      this.props.onCloseCallback();
    }
  };

  renderizar = () => {
    const { email, valid } = this.state;
    const { clienteId } = this.props;
    return (
      <Box paddingVertical={"2rem"} paddingHorizontal={"2rem"} showCloseBtn={true} onCloseCallback={this.onClose}>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
            <H size={3} texto={"Actualizar email"} />
          </Flex>
          <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
            <EmailClienteLoad
              email={email}
              clienteId={clienteId}
              onChange={this.onChange}
              onChangeValidation={this.onValidationChange}
            />
          </Flex>
          <Flex
            container
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
            marginTop="20px"
          >
            <RequestButton
              confirmacion={true}
              propsBoton={{ disabled: !valid }}
              texto={"Guardar cambios"}
              onClick={() => {
                if (email !== null) this.onSave(email);
              }}
            />
          </Flex>
        </Flex>
      </Box>
    );
  };

  render() {
    const { email } = this.state;

    if (email === null) {
      return null;
    }

    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.screen === ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen === ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen === ScreenType.TABLET ? this.renderizarTablet() : null}
      </Flex>
    );
  }
}
