import { Badge, MultiSelect } from "@mantine/core";
import React, { Component } from "react";
import { Button, Table, Form, Col } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import BotonOrden from "../../../../../administracion/seccion-clientes/clientes/components/BotonOrden";
import { CriterioOrden } from "../../../../../compartido/models/CriterioOrden";
import FechaUtils from "../../../../../compartido/utils/FechaUtils";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../../libreria/data/input/Input";
import {
  ProgramacionEntrega,
  EstadoProgramacion,
  EstadoProgramacionEnum,
  getColorEstadoProgramacion,
  PrioridadProgramacionEntrega,
  getColorPrioridad,
  WebSocketService,
  CANALES_WEB_SOCKET,
  ProgramacionEntregaService,
} from "serviciossaintmichel";
import { Global } from "../../../../../Global";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
}

interface State {
  programacionEntrega: Array<ProgramacionEntrega> | null;
  desde: Date | null;
  hasta: Date | null;
  cliente: string | null;
  cuit: string | null;
  reparto: string | null;
  estados: Array<EstadoProgramacionEnum>;
  prioridades: Array<PrioridadProgramacionEntrega>;
  criterioOrden: CriterioOrden | null;
  limite: number;
}

const ordenInicial: CriterioOrden = { campo: "fecha", modo: "DESC" };

export default class ProgramacionesEntregaShowTable extends Component<Props, State> {
  private webSocket: WebSocket | null;

  private programacionEntregaService: ProgramacionEntregaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      programacionEntrega: null,
      desde: null,
      hasta: null,
      cliente: null,
      cuit: null,
      reparto: null,
      estados: [],
      prioridades: [],
      criterioOrden: ordenInicial,
      limite: 5,
    };
    this.webSocket = null;

    this.programacionEntregaService = new ProgramacionEntregaService(Global.UsuarioService.getToken()!);
  }

  componentWillUnmount = () => {
    this.webSocket?.close();
  };

  componentDidMount = () => {
    this.setProgramacionesEntrega();
    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PROGRAMACIONES_ENTREGA,
      funcionCallback: this.setProgramacionesEntrega,
    });
  };

  setProgramacionesEntrega = async () => {
    const { desde, hasta, cliente, cuit, reparto, estados, prioridades, criterioOrden, limite } = this.state;

    try {
      let orden = criterioOrden !== null ? criterioOrden : undefined;

      const filtros = {
        desde: desde,
        hasta: hasta,
        cliente: cliente,
        cuit: cuit,
        reparto: reparto,
        estados: estados,
        prioridades: prioridades,
        sinVisita: null,
        sinReparto: null,
        zonasId: null,
      };
      const programacionesEntrega: Array<ProgramacionEntrega> = await this.programacionEntregaService.getProgramaciones(
        filtros,
        orden,
        limite
      );

      this.setState({ programacionEntrega: programacionesEntrega });
    } catch (error) {
      this.programacionEntregaService.manejarErrorHTTP(error, "Programaciones Entrega");
    }
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevState.criterioOrden !== this.state.criterioOrden || prevState.limite !== this.state.limite) {
      this.setProgramacionesEntrega();
    }
  };

  criteriosOrdenIguales = (criterioA: CriterioOrden | null, criterioB: CriterioOrden | null): boolean => {
    if (criterioA === null || criterioB === null) {
      return false;
    }
    return criterioA.campo === criterioB.campo && criterioA.modo === criterioB.modo;
  };

  seleccionarCriterio = (criterio: CriterioOrden | null) => {
    const { criterioOrden } = this.state;

    if (criterioOrden === null || !this.criteriosOrdenIguales(criterioOrden, criterio)) {
      this.setState({ criterioOrden: criterio });
    } else {
      this.setState({ criterioOrden: null });
    }
  };

  getCriterioHandler = (campo: string) => {
    return (modo: "ASC" | "DESC" | null) => {
      const criterioOrden = modo ? { modo: modo, campo: campo } : null;
      this.seleccionarCriterio(criterioOrden);
    };
  };

  getControlCriterio = (campo: string) =>
    this.state.criterioOrden?.campo === campo ? this.state.criterioOrden.modo : null;

  manejadorScroll = () => {
    const divTabla = document.getElementById("contenedor-scroll");
    if (divTabla === null) {
      return;
    }
    let movimientoTope = divTabla.scrollHeight - divTabla.clientHeight;

    if (movimientoTope === divTabla.scrollTop) {
      this.aumentarLimite();
    }
  };

  aumentarLimite = () => {
    this.setState((prevState) => ({ limite: prevState.limite + 5 }));
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };

  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  renderEstado = (estado: EstadoProgramacion) => {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Badge color={getColorEstadoProgramacion(estado.estado)} variant="filled" fullWidth>
          {estado.estado}
        </Badge>
      </Flex>
    );
  };
  renderPrioridad = (prioridad: PrioridadProgramacionEntrega) => {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Badge color={getColorPrioridad(prioridad)} variant="filled" fullWidth>
          {prioridad}
        </Badge>
      </Flex>
    );
  };

  render = () => {
    const { programacionEntrega, cliente, cuit, reparto, desde, hasta, estados, prioridades } = this.state;
    const minColWidth = "250px";

    const arregloEstados = [
      EstadoProgramacionEnum.NO_ASIGNADA,
      EstadoProgramacionEnum.EN_ESPERA,
      EstadoProgramacionEnum.EN_CAMINO,
      EstadoProgramacionEnum.RECHAZADA,
      EstadoProgramacionEnum.CON_RECHAZOS_PARCIALES,
      EstadoProgramacionEnum.ENTREGADA,
      EstadoProgramacionEnum.NO_ENTREGADA,
      EstadoProgramacionEnum.CANCELADA,
    ];
    const arregloPrioridades = [
      PrioridadProgramacionEntrega.BAJA,
      PrioridadProgramacionEntrega.NORMAL,
      PrioridadProgramacionEntrega.ALTA,
      PrioridadProgramacionEntrega.URGENTE,
    ];
    if (programacionEntrega === null) {
      return <div>Cargando...</div>;
    }

    return (
      <>
        <Form
          style={{
            padding: "1rem",
            backgroundColor: "gainsboro",
            borderRadius: "5px",
            marginBottom: "1rem",
          }}
        >
          <Form.Row className="align-items-center" style={{ rowGap: "1rem" }}>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <Form.Label as="strong">Cliente</Form.Label>
                <Input
                  data={cliente}
                  placeholder={"Ingrese nombre / apellido / razón social"}
                  onChange={(cliente: string) => {
                    this.setState({ cliente: cliente });
                  }}
                  type={InputTypes.Text}
                />
                <Input
                  data={cuit}
                  placeholder={"Ingrese cuit / cuil"}
                  onChange={(cuit: string) => {
                    this.setState({ cuit: cuit });
                  }}
                  type={InputTypes.Number}
                />
                <Form.Label as="strong">Reparto</Form.Label>
                <Input
                  data={reparto}
                  placeholder={"Ingrese reparto"}
                  onChange={(reparto: string) => {
                    this.setState({ reparto: reparto });
                  }}
                  type={InputTypes.Text}
                />
              </Flex>
            </Col>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <Form.Label as="strong">Desde</Form.Label>
                <DatePicker
                  placeholderText="Ingrese fecha"
                  onChange={(fecha: Date) => {
                    this.setState({ desde: fecha });
                  }}
                  selected={desde}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                />
              </Flex>
              <Flex flexDirection="column" alignItems="center">
                <Form.Label as="strong">Hasta</Form.Label>
                <DatePicker
                  placeholderText="Ingrese fecha"
                  onChange={(fecha: Date) => {
                    this.setState({ hasta: fecha });
                  }}
                  selected={hasta}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                />
              </Flex>
            </Col>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <Form.Label as="strong">Estados</Form.Label>
                <MultiSelect
                  style={{ flex: "auto" }}
                  placeholder="Seleccione Estados"
                  clearable
                  value={estados}
                  onChange={(estados: Array<EstadoProgramacionEnum>) => {
                    this.setState({ estados: estados });
                  }}
                  data={arregloEstados}
                />
                <Form.Label as="strong">Prioridades</Form.Label>
                <MultiSelect
                  style={{ flex: "auto" }}
                  placeholder="Seleccione Prioridades"
                  clearable
                  value={prioridades}
                  onChange={(prioridades: Array<PrioridadProgramacionEntrega>) => {
                    this.setState({ prioridades: prioridades });
                  }}
                  data={arregloPrioridades}
                />
              </Flex>
            </Col>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <RequestButton onClick={() => this.setProgramacionesEntrega()} texto="Buscar" icon={<Icon.Search />} />
              </Flex>
            </Col>
          </Form.Row>
        </Form>

        <div style={{ maxHeight: "350px", overflowY: "scroll" }} onScroll={this.manejadorScroll} id="contenedor-scroll">
          <Table striped bordered>
            <thead>
              <tr>
                <th className="text-center" style={{ position: "sticky", top: "0" }}>
                  #
                </th>
                <th className="text-center" style={{ position: "sticky", top: "0" }}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                    <span>Id </span>
                    <BotonOrden
                      criterio={this.getControlCriterio("id")}
                      onCriterioClicked={this.getCriterioHandler("id")}
                    />
                  </Flex>
                </th>
                <th className="text-center" style={{ position: "sticky", top: "0" }}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                    <span>Reparto</span>
                    <BotonOrden
                      criterio={this.getControlCriterio("reparto")}
                      onCriterioClicked={this.getCriterioHandler("reparto")}
                    />
                  </Flex>
                </th>
                <th className="text-center" style={{ position: "sticky", top: "0" }}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                    <span>Fecha </span>
                    <BotonOrden
                      criterio={this.getControlCriterio("fecha")}
                      onCriterioClicked={this.getCriterioHandler("fecha")}
                    />
                  </Flex>
                </th>
                <th className="text-center" style={{ position: "sticky", top: "0" }}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                    <span>Estado</span>
                    <BotonOrden
                      criterio={this.getControlCriterio("estado")}
                      onCriterioClicked={this.getCriterioHandler("estado")}
                    />
                  </Flex>
                </th>
                <th className="text-center" style={{ position: "sticky", top: "0" }}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
                    <span>Prioridad</span>
                    <BotonOrden
                      criterio={this.getControlCriterio("prioridad")}
                      onCriterioClicked={this.getCriterioHandler("prioridad")}
                    />
                  </Flex>
                </th>
                <th className="text-center" style={{ position: "sticky", top: "0" }}></th>
                <th className="text-center" style={{ position: "sticky", top: "0" }}></th>
              </tr>
            </thead>

            <tbody>
              {programacionEntrega.map((programacionEntrega, idx) => (
                <tr>
                  <td className="text-center">{idx + 1}</td>
                  <td className="text-center">{programacionEntrega.id}</td>
                  <td className="text-center">
                    {programacionEntrega.reparto ? programacionEntrega.reparto.nombre : "No tiene"}
                  </td>
                  <td className="text-center">
                    {programacionEntrega.fecha
                      ? FechaUtils.normalizarFecha(programacionEntrega.fecha).toLocaleDateString("es-AR")
                      : "-"}
                  </td>
                  <td className="text-center">{this.renderEstado(programacionEntrega.estado_programacion)}</td>
                  <td className="text-center">{this.renderPrioridad(programacionEntrega.prioridad)}</td>
                  <td className="text-center">
                    <Button
                      onClick={() => {
                        this.onView(programacionEntrega.id);
                      }}
                    >
                      Ver detalle
                    </Button>
                  </td>
                  <td className="text-center">
                    <Button
                      onClick={() => {
                        this.onUpdate(programacionEntrega.id);
                      }}
                    >
                      Actualizar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  };
}
