import React, { Component } from "react";
import { DatosProducto, ProductoAlquiler } from "serviciossaintmichel";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import ProductosShow, {
  Version,
} from "../../../../../../elementos/productos/Producto/components/show/ProductosShow";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../../../libreria/data/input/Input";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  producto: ProductoAlquiler;
}

interface State {
  producto: ProductoAlquiler;
  productoSeleccionado: DatosProducto | null;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class ProductoAlquilerLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      producto: this.props.producto,
      productoSeleccionado: null,
      error: "",
      valid: this.isValid(this.props.producto),
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  isValid = (producto: ProductoAlquiler): boolean => {
    // alert(producto.producto > 0 && producto.cantidad > 0 ? true:false)
    return producto.producto > 0 && producto.cantidad > 0 ? true : false;
  };

  onChangeCantidad = (cantidad: string) => {
    debugger;
    let producto = this.state.producto;
    producto.cantidad = parseInt(cantidad);
    let valid = this.isValid(producto);
    this.setState({ producto: producto, valid: valid });
    this.props.onChange(producto);
    if (this.props.onValidationChange) this.props.onValidationChange(valid);
  };

  onChangeProducto = (x: DatosProducto | null) => {
    let producto = this.state.producto;
    producto.producto = x ? x.id : 0;
    let valid = this.isValid(producto);
    this.setState({
      producto: producto,
      productoSeleccionado: x,
      valid: valid,
    });
    this.props.onChange(producto);
    if (this.props.onValidationChange) this.props.onValidationChange(valid);
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="flex-start"
          justifyContent="center"
          marginTop="20px"
        >
          <ProductosShow
            version={Version.SELECT}
            propsSelect={{
              seleccionado: this.state.productoSeleccionado,
              callbackParent: this.onChangeProducto,
            }}
          />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <Input
            data={this.state.producto.cantidad}
            onChange={this.onChangeCantidad}
            type={InputTypes.Number}
            min={1}
            label="Cantidad"
            placeholder="Cantidad"
          />
        </Flex>
      </Flex>
    );
  }
}
