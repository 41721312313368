import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";

import { CANALES_WEB_SOCKET, IndumentariaGet, IndumentariaPost, IndumentariaService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  ocultar: Function;
}

interface State {
  indumentaria: IndumentariaPost;
  alerta: any;
}

export default class IndumentariaNuevo extends Component<Props, State> {

  private indumentariaService:IndumentariaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      indumentaria: this.indumentariaCrear(),
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };

    this.indumentariaService =  new IndumentariaService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    const indumentariaCrear: IndumentariaPost = this.state.indumentaria;
    this.setState({
      indumentaria: indumentariaCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  indumentariaCrear(): IndumentariaPost {
    return {
      nombre: "",
      talle: "",
      marca: "",
      descripcion: "",
    };
  }

  setearNombre = (nombre: string) => {
    let indumentaria = this.state.indumentaria;
    indumentaria.nombre = nombre;
    this.setState({ indumentaria: indumentaria });
  };
  setearTalle = (talle: string) => {
    let indumentaria = this.state.indumentaria;
    indumentaria.talle = talle;
    this.setState({ indumentaria: indumentaria });
  };
  setearMarca = (marca: string) => {
    let indumentaria = this.state.indumentaria;
    indumentaria.marca = marca;
    this.setState({ indumentaria: indumentaria });
  };
  setearDescripcion = (descripcion: string) => {
    let indumentaria = this.state.indumentaria;
    indumentaria.descripcion = descripcion;
    this.setState({ indumentaria: indumentaria });
  };

  handleCreate() {
    this.indumentariaService.create(this.state.indumentaria)
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_INDUMENTARIAS
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Indumentaria Creado!",
            titulo: "Atención",
            estado: "block",
          },
        });
        const indumentariaCrear: IndumentariaPost = this.indumentariaCrear();
        this.setState({
          indumentaria: indumentariaCrear,
        });
      })
      .catch((error) => {
        this.indumentariaService.manejarErrorHTTP(error, "Indumentaria");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.indumentaria.nombre &&
      this.state.indumentaria.talle &&
      this.state.indumentaria.marca &&
      this.state.indumentaria.descripcion
      ? false
      : true;
  };

  renderizarFormulario() {
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="nombre">Nombre</label>
                <InputCaracteres
                  id="nombre"
                  name="nombre"
                  onChange={this.setearNombre}
                  value={this.state.indumentaria.nombre}
                  placeholder="Ingrese Nombre de Indumentaria"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="talle">Talle</label>
                <InputCaracteres
                  id="talle"
                  name="talle"
                  onChange={this.setearTalle}
                  value={this.state.indumentaria.talle}
                  placeholder="Ingrese talle"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="marca">Marca</label>
                <InputCaracteres
                  id="marca"
                  name="marca"
                  onChange={this.setearMarca}
                  value={this.state.indumentaria.marca}
                  placeholder="Ingrese Marca"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="descripcion">Descripcion</label>
                <InputCaracteres
                  id="descripcion"
                  name="descripcion"
                  onChange={this.setearDescripcion}
                  value={this.state.indumentaria.descripcion}
                  placeholder="Ingrese Descripcion"
                />
              </div>
              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  ref="boton"
                  type="submit"
                  className={"btn btn-info"}
                  disabled={this.disableButton()}
                >
                  {"Crear"}
                </button>
                <button
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
              <div className={"form-group"}>
                <Alerta
                  tipo={this.state.alerta.tipo}
                  titulo={this.state.alerta.titulo}
                  mensaje={this.state.alerta.mensaje}
                  estado={this.state.alerta.estado}
                />
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
