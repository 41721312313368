import React, { Component, FormEvent } from "react";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";
import "react-datepicker/dist/react-datepicker.css";
import Screen, { ScreenType } from "../../../../compartido/models/Screen";
import H from "../../../../libreria/message/title/H";
import RecorridoLoad from "../load/RecorridoLoad";
import Flex from "../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../libreria/containers/box/Box";
import Swal from "sweetalert2";

import { CANALES_WEB_SOCKET, DatosRecorrido, Recorrido, RecorridoService,  WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  onSaveSucces: Function;
  onExit?: Function;
}

interface State {
  recorrido: DatosRecorrido;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class RecorridoNew extends Component<Props, State> {

  private recorridoService:RecorridoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      recorrido: this.createEmptyRecorrido(),
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);
  
    this.recorridoService =  new RecorridoService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  createEmptyRecorrido(): DatosRecorrido {
    let x: DatosRecorrido = { id: -1, nombre: "" };
    return x;
  }

  onSave = () => {
    return this.recorridoService.create(this.state.recorrido)
      .then((recorrido) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS,
          { type: "NEW", idRecorrido: recorrido.id }
        );
        Swal.fire({
          icon: "success",
          text: "Recorrido Creado",
        });
        this.props.onSaveSucces(recorrido.id);
      })
      .catch((error) => {
        this.recorridoService.manejarErrorHTTP(error, "Recorrido");
      });
  };

  onExit = () => {
    if (this.props.onExit != undefined) this.props.onExit();
  };

  onChange = (recorrido: DatosRecorrido) => {
    this.setState({ recorrido: recorrido });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex
      flexDirection="column"
      width="50%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={3} texto={"Recorrido Nuevo"} />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <RecorridoLoad
            recorrido={this.state.recorrido}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
          />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Crear"}
            onClick={this.onSave}
          />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
