import React, { Component } from "react";
import { CANALES_WEB_SOCKET, OrdenVisitaRepartoDiarioLectura, RepartoDiarioService, WebSocketService } from "serviciossaintmichel";
import Swal from "sweetalert2";
import RequestButton from "../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../libreria/appearance/margin/Margin";
import DireccionesShow, { Version } from "../../../recorridos/components/update/direcciones/DireccionesShow";
import RepartoDiarioOrdenVisitasShow from "../show/RepartoDiarioOrdenVisitasShow";
import { Version as VersionRepartoDiarioShow } from "../show/RepartoDiarioOrdenVisitasShow";


import { Global } from "../../../../Global";

export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  idRepartoDiario: number;
}

interface State {
  ordenVisitas: Array<OrdenVisitaRepartoDiarioLectura>;
  ordenVisitaSeleccionado: OrdenVisitaRepartoDiarioLectura | null;
  cargando: boolean;
}

export default class RepartoDiarioOrdenVisitasUpdate extends Component<Props, State> {
  private webSockets: Array<WebSocket>;
  private repartoDiarioService:RepartoDiarioService;

  constructor(props: Props) {
    super(props);
    this.state = {
      ordenVisitas: [],
      ordenVisitaSeleccionado: null,
      cargando: true,
    };
    this.webSockets = [];
    this.repartoDiarioService =  new RepartoDiarioService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    this.setOrdenesVisita();
    this.webSockets.push(
      WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
        funcionCallback: this.onChannelMessage,
      })
    );
  };

  componentWillUnmount = () => {
    this.webSockets.forEach((ws: WebSocket) => ws.close());
  };

  onChannelMessage = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.idRepartoDiario !== this.props.idRepartoDiario) {
      return;
    }
    this.setOrdenesVisita();
  };

  async setOrdenesVisita() {
    const ordenesVisita = await this.repartoDiarioService.getOrdenesVisita(this.props.idRepartoDiario);
    ordenesVisita.sort((a, b) => a.orden - b.orden);
    this.setState({ ordenVisitas: ordenesVisita });
  }

  modificarOrden = async (movimientoOrden: number) => {
    const { ordenVisitaSeleccionado } = this.state;
    if (!ordenVisitaSeleccionado) {
      return;
    }

    const { idRepartoDiario } = this.props;
    const nuevoOrden = ordenVisitaSeleccionado.orden + movimientoOrden;

    try {
      await this.repartoDiarioService.modificarOrdenVisita(idRepartoDiario, ordenVisitaSeleccionado.id, nuevoOrden);
      const nuevoOrdenSeleccionado = ordenVisitaSeleccionado;
      nuevoOrdenSeleccionado.orden = nuevoOrden;
      this.setState({ ordenVisitaSeleccionado: nuevoOrdenSeleccionado });
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS, {
        type: "UPDATE",
        idRepartoDiario: idRepartoDiario,
      });
      Swal.fire({
        icon: "success",
        text: "Orden de la visita modificado",
      });
    } catch (error) {
      this.repartoDiarioService.manejarErrorHTTP(error, "Reparto Diario");
    }
  };

  onSelectDireccion = (direccionId: number | null) => {
    const ordenVisitaSeleccionado = this.state.ordenVisitas.find(
      (ordenVisita: OrdenVisitaRepartoDiarioLectura) => ordenVisita.visita.direccion.id === direccionId
    );
    this.setState({
      ordenVisitaSeleccionado: ordenVisitaSeleccionado ? ordenVisitaSeleccionado : null,
    });
  };

  onSelectOrdenVisita = (ordenVisitaId: number | null) => {
    const ordenVisitaSeleccionado = this.state.ordenVisitas.find(
      (ordenVisita: OrdenVisitaRepartoDiarioLectura) => ordenVisita.id === ordenVisitaId
    );
    this.setState({
      ordenVisitaSeleccionado: ordenVisitaSeleccionado ? ordenVisitaSeleccionado : null,
    });
  };

  getDireccionesId = (ordenVisitas: Array<OrdenVisitaRepartoDiarioLectura>) => {
    return ordenVisitas.map((ordenVisita: OrdenVisitaRepartoDiarioLectura) => ordenVisita.visita.direccion.id);
  };

  renderizar = () => {
    const { ordenVisitas, ordenVisitaSeleccionado } = this.state;
    const { idRepartoDiario } = this.props;

    if (ordenVisitas.length === 0) {
      return <div>Este reparto diario no posee visitas asignadas</div>;
    }

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <DireccionesShow
            version={Version.LOCATIONMAP}
            direccionesMostrar={this.getDireccionesId(ordenVisitas)}
            onSelect={this.onSelectDireccion}
            seleccionado={ordenVisitaSeleccionado?.visita.direccion.id}
          />
          <Margin top="30px" />
          <RepartoDiarioOrdenVisitasShow
            version={VersionRepartoDiarioShow.LISTBOX}
            repartoDiarioId={idRepartoDiario}
            onSelect={this.onSelectOrdenVisita}
            seleccionado={ordenVisitaSeleccionado?.id}
          />
          <Flex flexDirection="row" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
            <RequestButton
              propsBoton={{
                disabled: !ordenVisitaSeleccionado || ordenVisitaSeleccionado.orden === 1,
              }}
              texto={"Bajar"}
              onClick={() => this.modificarOrden(-1)}
            />
            <RequestButton
              propsBoton={{
                disabled: !ordenVisitaSeleccionado || ordenVisitaSeleccionado.orden === ordenVisitas.length,
              }}
              texto={"Subir"}
              onClick={() => this.modificarOrden(1)}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  };

  render() {
    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.renderizar()}
      </Flex>
    );
  }
}
