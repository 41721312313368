import React, { Component } from "react";
import { DireccionDTO } from "serviciossaintmichel";
import DireccionLoad from "../../../../../compartido/components/direccion/DireccionLoad";

export interface Props {
  direccion: DireccionDTO | null;
  onChangeDireccion: Function;
  onChangeValidation: Function;
}

interface State {
  direccion: DireccionDTO | null;
  pasoValidado: boolean;
}

export default class PasoDireccion extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      direccion: this.props.direccion,
      pasoValidado: this.isPasoValidado(this.props.direccion),
    };

    this.props.onChangeValidation(this.state.pasoValidado);
  }

  isPasoValidado = (direccion: DireccionDTO | null): boolean => {
    if (direccion != null)
      return direccion.calle != "" &&
        direccion.numero != "" &&
        direccion.localizacion != null &&
        direccion.tipo_lugar != null
        ? true
        : false;
    else return false;
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.setState({
        //Cliente: this.props.Cliente,
      });
    }
  }

  onChangeDireccion = (direccion: DireccionDTO) => {
    this.props.onChangeDireccion(direccion);
  };

  onChangeValidacion = (estado: boolean) => {
    this.props.onChangeValidation(estado);
  };

  render() {
    return (
      <DireccionLoad
        direccion={this.props.direccion}
        onChangeValidation={this.onChangeValidacion}
        onChangeDireccion={this.onChangeDireccion}
      />
    );
  }
}
