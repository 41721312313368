import React, { Component, FormEvent } from "react";
import { Col, Form } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

import Flex from "../../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../../libreria/data/input/Input";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";

export interface Props {
  busquedaHandler: Function;
  setFiltros: Function;
  filtros: {
    nombre: string | null;
  };
}

interface State {}

export default class DescuentosFilter extends Component<Props, State> {
  submitHandler = (event: FormEvent<HTMLFormElement>) => {
    this.props.busquedaHandler();
    event.preventDefault();
  };
  render = () => {
    const { filtros } = this.props;
    const minColWidth = "250px";

    return (
      <Form
        onSubmit={this.submitHandler}
        style={{
          padding: "1rem",
          backgroundColor: "gainsboro",
          borderRadius: "5px",
          marginBottom: "1rem",
        }}
      >
        <Form.Row className="align-items-center" style={{ rowGap: "1rem" }}>
          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <Form.Label as="strong">Nombre</Form.Label>
              <Input
                type={InputTypes.Text}
                data={filtros.nombre}
                placeholder="Nombre"
                onChange={(nombre: string) => this.props.setFiltros({ nombre: nombre })}
              />
            </Flex>
          </Col>
          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <RequestButton
                onClick={() => {
                  this.props.busquedaHandler();
                }}
                texto="Buscar"
                icon={<Icon.Search />}
              />
            </Flex>
          </Col>
        </Form.Row>
      </Form>
    );
  };
}
