import React, { Component } from "react";
import { Card } from "react-bootstrap";
import Flex from "../../../../../../../libreria/appearance/flex/Flex";
import { Cliente, CondicionIVA, CondicionIVAService } from "serviciossaintmichel";
import { Global } from "../../../../../../../Global";


export interface Props {
  cliente: Cliente;
}

interface State {
  condicionIVA: CondicionIVA | null;
}

export default class DatosAfipShow extends Component<Props, State> {

  private condicionIVAService:CondicionIVAService;

  constructor(props: Props) {
    super(props);

    this.state = {
      condicionIVA: null,
    };

    this.condicionIVAService = new CondicionIVAService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    try {
      const condicionIVA = await this.condicionIVAService.get(this.props.cliente.condicion_iva);
      this.setState({ condicionIVA: condicionIVA });
    } catch (error) {
      this.condicionIVAService.manejarErrorHTTP(error, "Condición IVA");
    }
  }

  render() {
    const { cliente } = this.props;
    const { condicionIVA } = this.state;

    if (condicionIVA === null) {
      return null;
    }

    return (
      <Flex container flexDirection="column" alignItems="flex-start" justifyContent="center" marginTop="10px">
        <Card.Text style={{ color: "black" }}>Razón Social: {cliente.razon_social}</Card.Text>
        <Card.Text style={{ color: "black" }}>CUIT: {cliente.cuit}</Card.Text>
        <Card.Text style={{ color: "black" }}>Condicion frente al IVA: {condicionIVA.tipo}</Card.Text>
      </Flex>
    );
  }
}
