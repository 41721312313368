import React, { Component } from "react";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";

import Flex from "../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../libreria/appearance/margin/Margin";
import { Box } from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";

import DatosComboRetornableUpdate from "./datos/DatosComboRetornableUpdate";
import ProductosComboRetornableUpdate from "./productos/ProductosComboRetornableUpdate";



import { CANALES_WEB_SOCKET, ComboRetornable, ComboRetornableService, ProductoComboRetornable, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";


export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  idComboRetornable: number;
  recienCreado: boolean;
}

interface State {
  comboRetornable: ComboRetornable | null;
  cargando: boolean;
  screen: ScreenType;
}

export default class ComboRetornableUpdate extends Component<Props, State> {

  private comboRetornableService:ComboRetornableService;

  constructor(props: Props) {
    super(props);
    this.state = {
      comboRetornable: null,
      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.comboRetornableService =  new ComboRetornableService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  async componentDidMount() {
    await this.cargarComboRetornable();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_COMBOS_RETORNABLES,
      funcionCallback: this.onChannelMesagge,
    });
  }

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.comboRetornableId == this.props.idComboRetornable) {
      this.cargarComboRetornable();
    }
  };

  cargarComboRetornable = async () => {
    let comboRetornable = await this.comboRetornableService.get(
      this.props.idComboRetornable
    ).catch((error) => {
      this.comboRetornableService.manejarErrorHTTP(error, "ComboRetornable");
      return null;
    });
    if (comboRetornable) {
      this.setState({ comboRetornable: comboRetornable });
    } else this.setState({ comboRetornable: comboRetornable, cargando: false });
  };

  renderizar = () =>
    this.state.comboRetornable != null ? (
      <Margin top="20px">
        <Box padding="15px">
          <H
            alineacion={"center"}
            size={3}
            texto={this.state.comboRetornable.nombre}
          />
          <Margin top="20px" />
          <Tabs
            defaultActiveKey={this.props.recienCreado ? "productos" : "datos"}
            transition={false}
            variant={this.state.screen == ScreenType.PHONE ? "pills" : "tabs"}
            style={{
              marginBottom: "1rem",
              flexDirection:
                this.state.screen == ScreenType.PHONE ? "column" : "row",
              alignItems: this.state.screen == ScreenType.PHONE ? "center" : "",
            }}
            unmountOnExit={true}
          >
            <Tab eventKey="datos" title="Datos">
              {
                <DatosComboRetornableUpdate
                  datosComboRetornable={this.state.comboRetornable}
                />
              }
            </Tab>
            <Tab eventKey="productos" title="Productos">
              {
                <ProductosComboRetornableUpdate
                  idComboRetornable={this.state.comboRetornable.id}
                />
              }
            </Tab>
          </Tabs>
          <Margin top="20px" />
        </Box>
      </Margin>
    ) : null;

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <div style={{ display: "block", width: "100%" }}>
          {this.renderizar()}
        </div>
      </Flex>
    );
  }
}
