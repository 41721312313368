import React, { Component } from "react";
import { PropsSelectGenerico } from "../../../../compartido/components/select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../../../../compartido/components/select/SelectBusqueda";
import VehiculosSelect from "./VehiculosSelect";

import { CANALES_WEB_SOCKET, TipoCombustibleGet, TipoCombustibleService, TipoVehiculoGet, TipoVehiculoService, VehiculoGet, VehiculoPost, VehiculoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  onUpdate?: Function;
  onView?: Function;
  onSelect?: Function;
  version: Version;
  excluirIds?: Array<number>;
  seleccionado?: number;
  propsSelect?: PropsSelectGenerico<VehiculoGet> & PropsSelectGeneral;
}

export enum Version {
  SELECT,
}

interface State {
  vehiculos: Array<VehiculoGet> | null;
}

export default class VehiculosShow extends Component<Props, State> {
  private vehiculoService:VehiculoService;

  constructor(props: Props) {
    super(props);
    this.state = { vehiculos: null };

    this.vehiculoService = new VehiculoService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_VEHICULOS,
      funcionCallback: this.getVehiculos,
    });
    const vehiculos = await this.getVehiculos();
    this.setState({ vehiculos: vehiculos });
  }

  getVehiculos = async () => {
    return await this.vehiculoService.listAll();
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };
  onSelect = (id: number) => {
    if (this.props.onSelect) this.props.onSelect(id);
  };

  render() {
    const { version, excluirIds = [], propsSelect } = this.props;

    const { vehiculos } = this.state;

    if (vehiculos === null) {
      return null;
    }

    const vehiculosFiltrados = vehiculos.filter((v) => excluirIds.indexOf(v.id) === -1);

    switch (version) {
      case Version.SELECT: {
        if (!propsSelect) {
          return null;
        }
        return (
          <VehiculosSelect
            {...this.props.propsSelect}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado ? propsSelect.seleccionado : null}
            items={vehiculosFiltrados}
          />
        );
      }
    }
  }
}
