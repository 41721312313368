import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { EstadoLogisticaPaquete, Promocion, VentaProgramacionPromocion } from "serviciossaintmichel";
import { excluirIds } from "../../../../compartido/utils/filtros";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import PromocionesShow, { Version } from "../../../../ventas/seccion-otros/Promocion/components/show/PromocionesShow";

export interface Props {
  onSubmitCallback?: Function;
  excluirPromos?: Array<number>;
}

interface State {
  nuevaVentaPromo: VentaProgramacionPromocion;
  promoSeleccionada: Promocion | null;
}

export default class VentaPromocionProgramacionEntregaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getEstadoInicial();
  }

  promoNueva = (): Promocion => {
    return { id: -1, nombre: "", productos: [], bulto_productos: [] };
  };

  getEstadoInicial = () => ({
    nuevaVentaPromo: {
      id: -1,
      paquete: {
        id: -1,
        promocion: this.promoNueva(),
        cantidad: 0,
      },
      estado: EstadoLogisticaPaquete.PENDIENTE_DE_REPARTO,
    },
    promoSeleccionada: null,
  });

  setPromo = (promo: Promocion | null) => {
    const promocion: Promocion = promo !== null ? { ...promo, productos: [], bulto_productos: [] } : this.promoNueva();
    this.setState((prevState) => ({
      nuevaVentaPromo: {
        ...prevState.nuevaVentaPromo,
        promocion: promo ? promocion : this.state.nuevaVentaPromo.paquete.promocion,
      },
      promoSeleccionada: promo,
    }));
  };

  onSubmit = () => {
    const { onSubmitCallback = () => {} } = this.props;
    onSubmitCallback(this.state.nuevaVentaPromo);
    const estadoInicial = this.getEstadoInicial();
    this.setState(estadoInicial);
  };

  validarVenta = (ventaPromo: VentaProgramacionPromocion) => {
    return ventaPromo.paquete.promocion.id > 0 && ventaPromo.paquete.cantidad > 0;
  };

  render() {
    const { nuevaVentaPromo, promoSeleccionada } = this.state;
    const filtrosShow = this.props.excluirPromos ? [excluirIds(this.props.excluirPromos)] : undefined;

    return (
      <Flex borderRadius="5px" border="1px solid black" gap="1rem" padding="1rem" alignItems="center">
        <PromocionesShow
          version={Version.SELECT}
          propsSelect={{
            callbackParent: this.setPromo,
            seleccionado: promoSeleccionada,
            placeholder: "Seleccione una promoción",
            label: "Promociones",
          }}
          filtros={filtrosShow}
        />
        <div>
          <span>Cantidad</span>
          <Input
            type={InputTypes.Number}
            data={nuevaVentaPromo.paquete.cantidad}
            onChange={(data: number) => {
              this.setState((prevState) => ({
                nuevaVentaPromo: { ...prevState.nuevaVentaPromo, cantidad: data },
              }));
            }}
            min={1}
          />
        </div>

        <Button onClick={this.onSubmit} disabled={!this.validarVenta(nuevaVentaPromo)}>
          Añadir
        </Button>
      </Flex>
    );
  }
}
