import { Divider } from "@mantine/core";
import React, { Component } from "react";
import {
  EntregaProgramacionBultoProducto,
  EntregaProgramacionMaquina,
  EntregaProgramacionProducto,
  EstadoProgramacion,
  EstadoProgramacionEnum,
  ProgramacionEntrega,
  VentaProgramacionBultoProducto,
  VentaProgramacionMaquina,
  VentaProgramacionProducto,
  VentaProgramacionPromocion,
} from "serviciossaintmichel";

import EntregasBultoProductoProgramacionEntregaLoad from "./EntregasBultoProductoProgramacionEntregaLoad";
import EntregasMaquinaProgramacionEntregaLoad from "./EntregasMaquinaProgramacionEntregaLoad";
import EntregasProductoProgramacionEntregaLoad from "./EntregasProductoProgramacionEntregaLoad";
import EstadoProgramacionEntregaLoad from "./EstadoProgramacionEntregaLoad";
import VentasBultoProductoProgramacionEntregaLoad from "./VentasBultoProductoProgramacionEntregaLoad";
import VentasMaquinaProgramacionEntregaLoad from "./VentasMaquinaProgramacionEntregaLoad";
import VentasProductoProgramacionEntregaLoad from "./VentasProductoProgramacionEntregaLoad";
import VentasPromocionProgramacionEntregaLoad from "./VentasPromocionProgramacionEntregaLoad";

export interface Props {
  programacionEntrega: ProgramacionEntrega;
  onChange?: Function;
}

interface State {
  programacionEntregaFormulario: ProgramacionEntrega;
}

export default class RealizacionProgramacionEntregaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      programacionEntregaFormulario: props.programacionEntrega,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.props.onChange && prevState.programacionEntregaFormulario !== this.state.programacionEntregaFormulario) {
      this.props.onChange(this.state.programacionEntregaFormulario);
      console.log("PROGRAMACION ENTREGA", this.state.programacionEntregaFormulario);
    }
  }

  setVentasProducto = (ventasProducto: Array<VentaProgramacionProducto>) => {
    this.setState((prevState) => ({
      programacionEntregaFormulario: { ...prevState.programacionEntregaFormulario, ventas_producto: ventasProducto },
    }));
  };

  setVentasBultoProducto = (ventasBultoProducto: Array<VentaProgramacionBultoProducto>) => {
    this.setState((prevState) => ({
      programacionEntregaFormulario: {
        ...prevState.programacionEntregaFormulario,
        ventas_bulto_producto: ventasBultoProducto,
      },
    }));
  };

  setVentasPromo = (ventasPromo: Array<VentaProgramacionPromocion>) => {
    this.setState((prevState) => ({
      programacionEntregaFormulario: { ...prevState.programacionEntregaFormulario, ventas_promocion: ventasPromo },
    }));
  };

  setVentasMaquina = (ventasMaquina: Array<VentaProgramacionMaquina>) => {
    this.setState((prevState) => ({
      programacionEntregaFormulario: { ...prevState.programacionEntregaFormulario, ventas_maquina: ventasMaquina },
    }));
  };

  setEntregasProducto = (entregasProducto: Array<EntregaProgramacionProducto>) => {
    this.setState((prevState) => ({
      programacionEntregaFormulario: {
        ...prevState.programacionEntregaFormulario,
        entregas_producto: entregasProducto,
      },
    }));
  };

  setEntregasBultoProducto = (entregasBultoProducto: Array<EntregaProgramacionBultoProducto>) => {
    this.setState((prevState) => ({
      programacionEntregaFormulario: {
        ...prevState.programacionEntregaFormulario,
        entregas_bulto_producto: entregasBultoProducto,
      },
    }));
  };

  setEntregasMaquina = (entregasMaquina: Array<EntregaProgramacionMaquina>) => {
    this.setState((prevState) => ({
      programacionEntregaFormulario: {
        ...prevState.programacionEntregaFormulario,
        entregas_maquina: entregasMaquina,
      },
    }));
  };

  setEstadoProgramacion = (estadoProgramacion: EstadoProgramacion) => {
    const {
      ventas_producto,
      ventas_bulto_producto,
      ventas_maquina,
      ventas_promocion,
      entregas_producto,
      entregas_bulto_producto,
      entregas_maquina,
    } = this.state.programacionEntregaFormulario;

    let ventasProducto = ventas_producto;
    let ventasBultoProducto = ventas_bulto_producto;
    let ventasMaquina = ventas_maquina;
    let ventasPromocion = ventas_promocion;

    let entregasProducto = entregas_producto;
    let entregasBultoProducto = entregas_bulto_producto;
    let entregasMaquina = entregas_maquina;

    this.setState((prevState) => ({
      programacionEntregaFormulario: {
        ...prevState.programacionEntregaFormulario,
        ventas_producto: ventasProducto,
        ventas_bulto_producto: ventasBultoProducto,
        ventas_maquina: ventasMaquina,
        ventas_promocion: ventasPromocion,
        entregas_producto: entregasProducto,
        entregas_bulto_producto: entregasBultoProducto,
        entregas_maquina: entregasMaquina,
        estado_programacion: estadoProgramacion,
      },
    }));
  };

  render() {
    const {
      estado_programacion,
      entregas_producto,
      entregas_bulto_producto,
      entregas_maquina,
      ventas_producto,
      ventas_bulto_producto,
      ventas_promocion,
      ventas_maquina,
    } = this.state.programacionEntregaFormulario;

    return (
      <div>
        <EstadoProgramacionEntregaLoad estadoProgramacion={estado_programacion} onChange={this.setEstadoProgramacion} />

        {estado_programacion.estado === EstadoProgramacionEnum.CON_RECHAZOS_PARCIALES && (
          <>
            {entregas_producto.length > 0 && (
              <>
                <Divider label="Entrega de Productos" labelPosition="center" variant="dashed" />
                <EntregasProductoProgramacionEntregaLoad
                  entregasProducto={entregas_producto}
                  onChange={this.setEntregasProducto}
                />
              </>
            )}

            {entregas_bulto_producto.length > 0 && (
              <>
                <Divider label="Entrega de Bultos de productos" labelPosition="center" variant="dashed" />
                <EntregasBultoProductoProgramacionEntregaLoad
                  entregasBultoProducto={entregas_bulto_producto}
                  onChange={this.setEntregasBultoProducto}
                />
              </>
            )}

            {entregas_maquina.length > 0 && (
              <>
                <Divider label="Entrega de Máquinas" labelPosition="center" variant="dashed" />
                <EntregasMaquinaProgramacionEntregaLoad
                  entregasMaquina={entregas_maquina}
                  onChange={this.setEntregasMaquina}
                />
              </>
            )}

            {ventas_producto.length > 0 && (
              <>
                <Divider label="Venta de Productos" labelPosition="center" variant="dashed" />
                <VentasProductoProgramacionEntregaLoad
                  ventasProducto={ventas_producto}
                  onChange={this.setVentasProducto}
                />
              </>
            )}

            {ventas_bulto_producto.length > 0 && (
              <>
                <Divider label="Venta de Bultos de productos" labelPosition="center" variant="dashed" />
                <VentasBultoProductoProgramacionEntregaLoad
                  ventasBultoProducto={ventas_bulto_producto}
                  onChange={this.setVentasBultoProducto}
                />
              </>
            )}

            {ventas_promocion.length > 0 && (
              <>
                <Divider label="Venta de Promociones" labelPosition="center" variant="dashed" />
                <VentasPromocionProgramacionEntregaLoad ventasPromo={ventas_promocion} onChange={this.setVentasPromo} />
              </>
            )}

            {ventas_maquina.length > 0 && (
              <>
                <Divider label="Venta de Máquinas" labelPosition="center" variant="dashed" />
                <VentasMaquinaProgramacionEntregaLoad ventasMaquina={ventas_maquina} onChange={this.setVentasMaquina} />
              </>
            )}
          </>
        )}
      </div>
    );
  }
}
