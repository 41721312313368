import { Divider } from "@mantine/core";
import React, { Component } from "react";
import { EntregaBultoProductoLectura, EntregaComboRetornableLectura, EntregaMaquinaLectura, EntregaProductoLectura, EntregaVisitaLectura } from "serviciossaintmichel";
import EntregasBultoProductoLoad from "./EntregasBultoProductoLoad";
import EntregasComboRetornableLoad from "./EntregasComboRetornableLoad";
import EntregasMaquinaLoad from "./EntregasMaquinaLoad";
import EntregasProductoLoad from "./EntregasProductoLoad";

export interface Props {
  entregaVisita: EntregaVisitaLectura;
  onChange?: Function;
}

interface State {
  entregaFormulario: EntregaVisitaLectura;
}

export default class EntregaVisitaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entregaFormulario: props.entregaVisita,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.props.onChange && prevState.entregaFormulario !== this.state.entregaFormulario) {
      this.props.onChange(this.state.entregaFormulario);
    }
  }

  setEntregasProducto = (entregasProducto: Array<EntregaProductoLectura>) => {
    this.setState((prevState) => ({
      entregaFormulario: { ...prevState.entregaFormulario, productos_entregados: entregasProducto },
    }));
  };

  setEntregasBultoProducto = (entregasBultoProducto: Array<EntregaBultoProductoLectura>) => {
    this.setState((prevState) => ({
      entregaFormulario: { ...prevState.entregaFormulario, bultos_producto_entregados: entregasBultoProducto },
    }));
  };

  setEntregasCombo = (entregasCombo: Array<EntregaComboRetornableLectura>) => {
    this.setState((prevState) => ({
      entregaFormulario: { ...prevState.entregaFormulario, combos_retornables_entregados: entregasCombo },
    }));
  };

  setEntregasMaquina = (entregasMaquina: Array<EntregaMaquinaLectura>) => {
    this.setState((prevState) => ({
      entregaFormulario: { ...prevState.entregaFormulario, maquinas_entregadas: entregasMaquina },
    }));
  };

  render() {
    const { entregaFormulario } = this.state;

    return (
      <div>
        <Divider label="Productos" labelPosition="center" variant="dashed" />
        <EntregasProductoLoad
          entregasProducto={entregaFormulario.productos_entregados}
          onChange={this.setEntregasProducto}
        />

        <Divider label="Bultos de productos" labelPosition="center" variant="dashed" />
        <EntregasBultoProductoLoad
          entregasBultoProducto={entregaFormulario.bultos_producto_entregados}
          onChange={this.setEntregasBultoProducto}
        />

        <Divider label="Combos retornables" labelPosition="center" variant="dashed" />
        <EntregasComboRetornableLoad
          entregasCombo={entregaFormulario.combos_retornables_entregados}
          onChange={this.setEntregasCombo}
        />

        <Divider label="Máquinas" labelPosition="center" variant="dashed" />
        <EntregasMaquinaLoad
          entregasMaquina={entregaFormulario.maquinas_entregadas}
          onChange={this.setEntregasMaquina}
        />
      </div>
    );
  }
}
