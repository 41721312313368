import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import Empleados from "./empleados/components/Empleados";
import Ruta from "../../compartido/models/Ruta";
import Areas from "./areas/components/Areas";
import Salarios from "./salarios/components/Salarios";
import { Global } from "../../Global";

export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];
  if (
    Global.UsuarioService.getPermisos().Administracion.Personal.Empleado.hasAccess()
  ) {
    aux.push({
      name: "Empleados",
      path: SeccionPersonal.pathBase + "/empleados",
      component: Empleados,
    });
  }
  //if ( UsuarioService.getPermisos().Administracion.Personal.Salarios.hasAccess()) {
  aux.push({
    name: "Salarios",
    path: SeccionPersonal.pathBase + "/salarios",
    component: Salarios,
  });
  //}
  //if (UsuarioService.getPermisos().Administracion.Personal.Area.hasAccess()) {
  aux.push({
    name: "Areas",
    path: SeccionPersonal.pathBase + "/areas",
    component: Areas,
  });
  // }

  return aux;
}

export default class SeccionPersonal extends Component<Props> {
  static pathBase = "/administracion/seccion-personal";
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Personal"}
      />
    );
  }
}
