import React, { Component } from "react";
import { PropsSelectGeneral } from "../../../../../compartido/components/select/SelectBusqueda";
import { PropsSelectGenerico } from "../../../../../compartido/components/select/PropsSelectGenerico";
import { LIMITES } from "../../../../../compartido/components/select/SelectLimiteTabla";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import ComboRetornablesShowTable from "./ComboRetornablesShowTable";
import SelectComponent from "../../../../../compartido/components/select/SelectComponent";
import { CANALES_WEB_SOCKET, ComboRetornable, ComboRetornableService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";




export interface Props {
  onUpdate?: Function;
  onView?: Function;
  version: Version;
  propsSelect?: PropsSelectGenerico<any> & PropsSelectGeneral;
  filtros?: Array<Function>; // lista de filtros sobre los datos (solo sirve para el select)
}

export enum Version {
  SELECT,
  TABLE,
}

interface State {
  comboRetornables: Array<ComboRetornable>;
}

export default class ComboRetornablesShow extends Component<Props, State> {

  private comboRetornableService:ComboRetornableService;

  constructor(props: Props) {
    super(props);
    this.state = {
      comboRetornables: [],
    };

    this.comboRetornableService =  new ComboRetornableService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_COMBOS_RETORNABLES,
      funcionCallback: this.getComboRetornables,
    });
    if (this.props.version === Version.TABLE) this.comboRetornableService.paramsURL.setearLimite(LIMITES[0]);
    this.getComboRetornables();
  }

  getComboRetornables = () => {
    this.comboRetornableService.listAll()
      .then((result) => {
        this.setState({ comboRetornables: result });
      })
      .catch((error) => {
        this.comboRetornableService.manejarErrorHTTP(error, "ComboRetornable");
      });
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  /* <ComboRetornablesShowSelect
    {...this.props.propsSelect}
    callbackParent={this.props.propsSelect.callbackParent}
    seleccionado={this.props.propsSelect.seleccionado ? this.props.propsSelect.seleccionado : null}
    items={this.state.comboRetornables}
  /> */

  render() {
    const { filtros, propsSelect, version } = this.props;
    const { comboRetornables } = this.state;

    // si hay filtros, chequea aquellos combos que cumplen con todos los filtros
    const items = filtros ? comboRetornables.filter((cr) => filtros.every((fnc) => fnc(cr))) : comboRetornables;

    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {version === Version.SELECT && propsSelect ? (
          <SelectComponent
            {...propsSelect}
            items={items}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado ? propsSelect.seleccionado : null}
            labelGetter={(obj: ComboRetornable) => obj.nombre}
          />
        ) : null}
        {version === Version.TABLE ? (
          <ComboRetornablesShowTable
            onView={this.onView}
            onUpdate={this.onUpdate}
            comboRetornables={this.state.comboRetornables}
            comboRetornablesFuncion={this.getComboRetornables}
          />
        ) : null}
      </Flex>
    );
  }
}
