import React, { Component, FormEvent } from "react";
import InputDecimal from "../../../../compartido/components/input/InputDecimal";
import Habilitador from "../../../../compartido/components/habilitador/Habilitador";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";
import SelectInsumo from "../../../insumos/Insumo/components/SelectInsumo";
import { Table, Col, Row, Form, Container } from "react-bootstrap";
import { CANALES_WEB_SOCKET, InsumoGet, InsumoService, ProductoGet, ProductoService,RelacionProductoInsumo, TipoProductoGet, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  productoId: number;
}

interface State {
  producto: ProductoGet;
  insumosCandidatos: Array<InsumoGet>;
  alerta: any;
  cantidad: number;
  insumo: InsumoGet | null;
}

export default class ProductoActualizarInsumos extends Component<Props, State> {


  private productoService:ProductoService;
  private insumoService:InsumoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      producto: this.crearProductoVacio(),
      insumosCandidatos: [],
      cantidad: 0,
      insumo: null,
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_INSUMOS,
      funcionCallback: this.cargarInsumos,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_INSUMOS,
      funcionCallback: this.cargarProductoCanal,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS,
      funcionCallback: this.cargarProductoCanal,
    });

    this.productoService =  new ProductoService(Global.UsuarioService.getToken()!);
    this.insumoService =  new InsumoService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    await this.cargarProducto(this.props.productoId);
    this.cargarInsumos();
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      await this.cargarProducto(this.props.productoId);
      this.cargarInsumos();
    }
  }

  crearProductoVacio(): ProductoGet {
    const tipoProducto: TipoProductoGet = {
      id: 0,
      tipo: "",
    };
    const producto: ProductoGet = {
      id: 0,
      nombre: "",
      tipo_producto: tipoProducto,
      volumen: 0,
      insumos: [],
    };
    return producto;
  }

  cargarProductoCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.producto &&
      dato.message.productoId == this.state.producto.id
    ) {
      await this.cargarProducto(this.state.producto.id);
      this.cargarInsumos();
    }
    if (
      this.state.producto &&
      this.state.producto.insumos.find(
        (i) => i.insumo.id === dato.message.insumoId
      )
    ) {
      await this.cargarProducto(this.state.producto.id);
      this.cargarInsumos();
    }
  };

  cargarProducto = async (id: number) => {
    const resProducto = await this.productoService.get(id).catch((error) => {
      this.productoService.manejarErrorHTTP(error, "Producto");
      return null;
    });
    if (resProducto != null) {
      this.setState({
        producto: resProducto,
        cantidad: 0,
        alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
      });
    }
  };

  cargarInsumos = async () => {
    let insumos = await this.insumoService.listAll().catch((error) => {
      this.insumoService.manejarErrorHTTP(error, "Insumos");
      return null;
    });
    if (insumos != null) {
      this.actualizarInsumosCandidatos(insumos);
    }
  };

  actualizarInsumosCandidatos = (insumos: Array<InsumoGet>) => {
    const relacionados = this.state.producto.insumos.map(
      (relacion) => relacion.insumo.id
    );
    let insumosCandidatos = insumos.filter(
      (insumo) => !relacionados.includes(insumo.id)
    );
    this.setState({
      insumosCandidatos: insumosCandidatos,
      insumo: insumosCandidatos[0],
    });
  };

  setearCantidad = (cantidad: number) => {
    this.setState({ cantidad: cantidad });
  };

  setearInsumo = (insumo: InsumoGet) => {
    this.setState({ insumo: insumo });
  };

  eliminarInsumo = (insumo: RelacionProductoInsumo) => {
    this.productoService.eliminarRelacionConInsumo(
      this.state.producto.id,
      insumo.insumo.id
    ).catch((error) => {
      this.productoService.manejarErrorHTTP(error, "Producto");
    });
    WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS, {
      productoId: this.state.producto.id,
    });
  };

  agregarInsumo = (insumo: InsumoGet, cantidad: number) => {
    this.productoService.relacionarConInsumo(
      this.state.producto.id,
      insumo.id,
      cantidad
    ).catch((error) => {
      this.productoService.manejarErrorHTTP(error, "Producto");
    });
    WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS, {
      productoId: this.state.producto.id,
    });
  };

  actualizarInsumo = (relacionInsumo: RelacionProductoInsumo) => {
    return this.productoService.actualizarRelacionConInsumo(
      this.state.producto.id,
      relacionInsumo.insumo.id,
      relacionInsumo.cantidad
    )
      .then(() => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS, {
          productoId: this.state.producto.id,
        });
      })
      .catch((error) => {
        this.productoService.manejarErrorHTTP(error, "Producto");
      });
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.insumo && this.state.cantidad && this.state.cantidad != 0
      ? false
      : true;
  };

  renderizarFormulario = () => {
    if (this.state.insumosCandidatos.length < 1) {
      return null;
    }
    return (
      <Form>
        <Form.Group>
          <Form.Label>Cantidad</Form.Label>
          <InputDecimal
            id="cantidad"
            name="cantidad"
            onChange={this.setearCantidad}
            value={this.state.cantidad}
            cantEnteros={3}
            cantDecimales={3}
            placeholder={"Ingrese cantidad"}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Insumos</Form.Label>
          <SelectInsumo
            insumos={this.state.insumosCandidatos}
            seleccionado={
              this.state.insumo
                ? this.state.insumo
                : this.state.insumosCandidatos[0]
            }
            callbackParent={(insumo: InsumoGet) => {
              this.setearInsumo(insumo);
            }}
          />
        </Form.Group>
        <Form.Group>
          <RequestButton
            texto="Añadir"
            propsBoton={{
              variant: "info",
              disabled: !(!!this.state.insumo && this.state.cantidad != 0),
            }}
            onClick={() => {
              if (this.state.insumo && this.state.cantidad)
                return this.agregarInsumo(
                  this.state.insumo,
                  this.state.cantidad
                );
            }}
          />
        </Form.Group>
      </Form>
    );
  };

  renderizarFilas = () => {
    return this.state.producto.insumos.map((relacion, index) => {
      return (
        <tr>
          <td>{index}</td>
          <td>{relacion.insumo.nombre}</td>
          <td>{relacion.insumo.tipo_insumo.tipo}</td>
          <td>
            <Habilitador>
              <InputDecimal
                id={"cantidad" + index}
                name="cantidad"
                onChange={(valor: number) => {
                  let p = this.state.producto;
                  let l = p.insumos;
                  let i = l[index];
                  i.cantidad = valor;
                  this.setState({ producto: p });
                }}
                value={relacion.cantidad}
                cantEnteros={3}
                cantDecimales={3}
                placeholder={"Ingrese cantidad"}
              />
            </Habilitador>
          </td>
          <td>{relacion.insumo.unidad_insumo.unidad}</td>
          <td>
            <RequestButton
              texto="Actualizar"
              propsBoton={{
                variant: "info",
                disabled:
                  relacion.cantidad && relacion.cantidad != 0 ? false : true,
              }}
              onClick={() => this.actualizarInsumo(relacion)}
            />
          </td>
          <td>
            <RequestButton
              texto="Eliminar"
              confirmacion={true}
              propsBoton={{
                variant: "danger",
              }}
              onClick={() => this.eliminarInsumo(relacion)}
            />
          </td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true}>
        <thead>
          <tr>
            <th>#</th>
            <th>Insumo</th>
            <th>Tipo de Insumo</th>
            <th>Cantidad</th>
            <th>Unidades</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  render() {
    return (
      <Container>
        <Row>
          <Col md={{ span: 6 }}>{this.renderizarFormulario()}</Col>
        </Row>
        <Row>
          <Col>{this.renderizarTabla()}</Col>
        </Row>
      </Container>
    );
  }
}
