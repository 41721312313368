import React, { Component } from "react";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import RequestButton from "../../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../../libreria/containers/box/Box";
import H from "../../../../../../libreria/message/title/H";
import ComboRetornableLoad from "../../load/ComboRetornableLoad";
import { CANALES_WEB_SOCKET,  ComboRetornableService, DatosComboRetornable, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";



export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  datosComboRetornable: DatosComboRetornable;
}
interface State {
  datosComboRetornableNew: DatosComboRetornable;
  datosComboRetornableOld: DatosComboRetornable;
  screen: ScreenType;
  valid: boolean;
}

export default class DatosComboRetornableUpdate extends Component<
  Props,
  State
> {

  private comboRetornableService:ComboRetornableService;

  constructor(props: Props) {


    super(props);
    this.state = {
      datosComboRetornableOld: this.props.datosComboRetornable,
      datosComboRetornableNew: this.getCopy(this.props.datosComboRetornable),
      screen: Screen.getType(window.innerWidth, window.innerHeight),
      valid: false,
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    this.comboRetornableService =  new ComboRetornableService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  getCopy = (datos: DatosComboRetornable): DatosComboRetornable => {
    return { id: datos.id, nombre: datos.nombre };
  };

  async componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_COMBOS_RETORNABLES,
      funcionCallback: this.onChannelMesagge,
    });
  }

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.comboRetornableId == this.props.datosComboRetornable.id) {
      let comboRetornable = await this.comboRetornableService.get(
        this.props.datosComboRetornable.id
      ).catch((error) => {
        this.comboRetornableService.manejarErrorHTTP(error, "Combo Retornable");
        return null;
      });
      let datos: DatosComboRetornable = {
        id: comboRetornable ? comboRetornable.id : -1,
        nombre: comboRetornable ? comboRetornable.nombre : "",
      };
      this.setState({
        datosComboRetornableOld: datos,
        datosComboRetornableNew: this.getCopy(datos),
      });
    }
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({
        datosComboRetornableOld: this.props.datosComboRetornable,
        datosComboRetornableNew: this.getCopy(this.props.datosComboRetornable),
      });
    }
  };

  onUpdate = async () => {
    return this.comboRetornableService.update(
      this.state.datosComboRetornableNew,
      this.state.datosComboRetornableNew.id
    )
      .then((comboRetornable) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.VENTAS_COMBOS_RETORNABLES,
          { type: "UPDATE", comboRetornableId: comboRetornable.id }
        );
        this.setState({
          datosComboRetornableOld: this.state.datosComboRetornableNew,
          datosComboRetornableNew: this.getCopy(
            this.props.datosComboRetornable
          ),
        });
        Swal.fire({
          icon: "success",
          text: "Datos Combo Retornable Modificados",
        });
      })
      .catch((error) => {
        this.comboRetornableService.manejarErrorHTTP(error, "Combo Retornable");
      });
  };

  isValid = (nombre: string): boolean => {
    return nombre.length > 0 ? true : false;
  };

  onChange = (datosComboRetornable: DatosComboRetornable) => {
    this.setState({ datosComboRetornableNew: datosComboRetornable });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex
      flexDirection="column"
      width="50%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={3} texto={"Datos"} />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="10px"
        >
          <ComboRetornableLoad
            comboRetornable={this.state.datosComboRetornableNew}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
          />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Actualizar"}
            onClick={this.onUpdate}
          />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
