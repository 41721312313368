import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";



import { CANALES_WEB_SOCKET,   TipoProductoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  ocultar: Function;
}

interface State {
  tipo: string;
}

export default class TipoProductoNuevo extends Component<Props, State> {

  private tipoProductoService:TipoProductoService;


  constructor(props: Props) {
    super(props);
    this.state = {
      tipo: "",
    };

    this.tipoProductoService =  new TipoProductoService(Global.UsuarioService.getToken()!);

  }

  ocultar = () => {
    this.props.ocultar();
  };

  handleCreate() {
    return this.tipoProductoService.create({
      tipo: this.state.tipo,
    })
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_TIPO_PRODUCTOS
        );
        this.setState({ tipo: "" });
      })
      .catch((error) => {
        this.tipoProductoService.manejarErrorHTTP(error, "Tipo Producto");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  setearTipo = (tipo: string) => {
    let tipoProd = this.state.tipo;
    tipoProd = tipo;
    this.setState({ tipo: tipoProd });
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.tipo ? false : true;
  };

  renderizarFormulario() {
    return (
      <div>
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="tipo">Nombre de Tipo Producto</label>
                <InputCaracteres
                  id="tipo"
                  name="tipo"
                  onChange={this.setearTipo}
                  value={this.state.tipo}
                  placeholder="Ingrese Nombre de TipoProducto"
                />
              </div>

              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <RequestButton
                  texto="Crear"
                  msgOnSuccess="Tipo de producto creado con exito"
                  msgOnFailure="Ocurrio un error al crear el tipo de producto"
                  propsBoton={{
                    variant: "info",
                    disabled: this.disableButton(),
                  }}
                  onClick={() => this.handleCreate()}
                />
                <button
                  type={"reset"}
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
