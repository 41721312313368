import React, { Component } from "react";
import OrdenadorTabla from "./OrdenadorTabla";
import "./tabla.css";
import InputGenerico from "../input/InputGenerico";
import SelectCriteriosBusqueda from "../select/SelectCriteriosBusqueda";
import SelectLimiteTabla from "../select/SelectLimiteTabla";
import ParamsURL from "../../models/ParamsURL";
import Utils from "../../utils/utilidades";
import utilidades from "../../utils/utilidades";

export interface Props {
  nombresColumnas: Array<string>;
  filas: Array<FilaTablaGenerica>;
  accionesBoton?: Array<AccionTabla>;
  eventoOrdenarGlobal?: Function; // Evento que se podria usar para ordenar segun el global de datos
  // posibles nuevas columnas: imagenes, links, inputs...
  parametrosURL: ParamsURL | any;
  listarElementos: Function;
  id: string;
}
export interface FilaTablaGenerica {
  celdas: Array<string>;
  id: number;
}

interface State {
  columnaSeleccionada: number;
  ascendente: boolean;
}

export interface AccionTabla {
  nombre: string;
  evento: Function;
}

class TablaGenerica extends Component<Props, State> {
  private idBusqueda: string;
  constructor(props: Props) {
    super(props);
    this.state = {
      columnaSeleccionada: 0,
      ascendente: true,
    };
    this.idBusqueda = props.id + "busqueda";
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState !== this.state) {
      if (this.props.eventoOrdenarGlobal) {
        this.props.eventoOrdenarGlobal(
          this.props.nombresColumnas[this.state.columnaSeleccionada],
          this.state.ascendente ? "" : "-"
        );
      }
    }
  }

  armarNombresColumnas(nombresColumnas: Array<string>): Array<string> {
    return nombresColumnas.map((nombre) => {
      return utilidades.normalizarCadenas(nombre, "_");
    });
  }

  nombresColumnasExtra(acciones: Array<AccionTabla> | undefined) {
    if (acciones) {
      return acciones.map((accion) => <th>{accion.nombre}</th>);
    } else {
      return null;
    }
  }

  botonesColumnasExtra(acciones: Array<AccionTabla> | undefined, id: number) {
    if (acciones) {
      return acciones.map((accion) => (
        <td>
          <button
            onClick={() => {
              accion.evento(id);
            }}
            className="btn btn-outline-secondary"
          >
            {accion.nombre}
          </button>
        </td>
      ));
    } else {
      return null;
    }
  }

  manejadorScroll = () => {
    var divTabla = document.getElementById(this.props.id);
    if (divTabla != null) {
      // divTabla.scrollHeight es el tamaño total que ocuparia la tabla sin scroll
      // divTabla.clientHeight es el tamaño visible de la tabla
      // movimientoTope es cuanto se debe mover el scroll, para llegar al final
      let movimientoTope = divTabla.scrollHeight - divTabla.clientHeight;
      if (movimientoTope === divTabla.scrollTop) {
        // divTabla.scrollTop es cuanto se movio el scroll, tomando de referencia la parte superior
        console.log("LLEGUE AL FINAL!!!");
        this.aumentarLimiteElementos();
      }
      if (divTabla.scrollTop === 0) {
        console.log("LLEGUE AL INICIO!!!");
      }
    }
  };
  //Hace el pedido sobre el orden Global, en desuso
  ordenarGlobal = (criterio: string, orden: string) => {
    this.props.parametrosURL.setearOrden(orden + criterio); // orden = '-', criterio = 'nombre'
    this.props.listarElementos();
  };

  aumentarLimiteElementos = () => {
    this.props.parametrosURL.aumentarLimite();
    this.props.listarElementos();
  };

  cambiarLimite = (limite: number) => {
    // Al pasar a un limite mas chico se pide 2 veces
    this.props.parametrosURL.setearLimite(limite);
    this.props.listarElementos();
  };
  filtrarBusqueda = () => {
    const busqueda = document.getElementById(this.idBusqueda) as HTMLInputElement;
    if (busqueda != null) {
      Utils.normalizarCadenas(busqueda.value, "_");
      this.props.parametrosURL.setearBusqueda(busqueda.value);
      this.props.listarElementos();
    }
  };
  cambiarCriteriosBusqueda = async (criterio: string) => {
    this.props.parametrosURL.setearFiltro(criterio);
  };

  renderizarControlesOrden = (numeroColumna: number) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "0.5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor:
              this.state.columnaSeleccionada === numeroColumna && this.state.ascendente ? "#0C71C1" : "transparent",
          }}
          onClick={() => {
            this.setState({
              columnaSeleccionada: numeroColumna,
              ascendente: true,
            });
          }}
        >
          <i className="fas fa-caret-square-up"></i>
        </div>
        <div
          style={{
            display: "flex",
            backgroundColor:
              this.state.columnaSeleccionada === numeroColumna && !this.state.ascendente ? "#0C71C1" : "transparent",
          }}
          onClick={() => {
            this.setState({
              columnaSeleccionada: numeroColumna,
              ascendente: false,
            });
          }}
        >
          <i className="fas fa-caret-square-down"></i>
        </div>
      </div>
    );
  };

  renderizarEncabezadoTabla = () => {
    return (
      <thead>
        <tr>
          <th>#</th>
          {/* Columnas con el nombre de cada variable */}
          {this.armarNombresColumnas(this.props.nombresColumnas).map((nombreColumna, numeroColumna) => {
            return (
              <th key={nombreColumna}>
                <div
                  className={
                    numeroColumna === this.state.columnaSeleccionada
                      ? "columna-seleccionada"
                      : "columna-no-seleccionada"
                  }
                >
                  <div>{nombreColumna}</div>
                  {this.renderizarControlesOrden(numeroColumna)}
                </div>
              </th>
            );
          })}
          {/* Columnas extras con botones */}
          {this.nombresColumnasExtra(this.props.accionesBoton)}
        </tr>
      </thead>
    );
  };
  renderizarCuerpoTabla = () => {
    if (this.props.filas.length === 0) {
      return null;
    }
    return (
      <tbody>
        {OrdenadorTabla.getFilasOrdenada(this.props.filas, this.state.columnaSeleccionada, this.state.ascendente).map(
          (dato, indice) => (
            <tr key={indice}>
              <td>{indice} </td>
              {dato.celdas.map((elemento) => (
                <td> {elemento} </td>
              ))}
              {this.botonesColumnasExtra(this.props.accionesBoton, dato.id)}
            </tr>
          )
        )}
      </tbody>
    );
  };

  render() {
    return (
      <div style={{ width: "100%", display: "flex", flexDirection: "column", flex: 1 }}>
        <div className="mb-3" style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 0.5, marginRight: "1rem" }}>
            <InputGenerico
              type="text"
              id={this.idBusqueda}
              name="busqueda"
              placeholder="Ingrese texto para buscar..."
              callbackOnEnter={this.filtrarBusqueda}
              callbackOnEmpty={this.filtrarBusqueda}
            />
          </div>
          {this.props.parametrosURL.getCriteriosFiltro().length > 1 ? (
            <div style={{ flex: 0.4, marginRight: "1rem" }}>
              <SelectCriteriosBusqueda
                criterios={this.props.parametrosURL.getCriteriosFiltro()}
                callbackParent={this.cambiarCriteriosBusqueda}
              />
            </div>
          ) : null}

          <div style={{ flex: 0.1 }}>
            <button className="btn btn-info" onClick={this.filtrarBusqueda}>
              Buscar
            </button>
          </div>
        </div>
        <div className="mb-3" style={{ display: "flex" }}>
          <SelectLimiteTabla callbackParent={this.cambiarLimite} />
        </div>
        <div
          className="table-responsive-xl tableFixHead" //TODO hacer la cantidad de px acorde a la pantalla
          onScroll={this.manejadorScroll}
          id={this.props.id}
        >
          <table className="table">
            {this.renderizarEncabezadoTabla()}
            {this.renderizarCuerpoTabla()}
          </table>
        </div>
        {this.props.filas.length === 0 ? <div>No se encontraron resultados</div> : null}
      </div>
    );
  }
}
export default TablaGenerica;
