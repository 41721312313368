import React, { Component } from "react";

import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";

import { Global } from "../../../../Global";

import { ArticuloGraficoGet, ArticuloGraficoService, CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel"


export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  articulosGraficos: Array<ArticuloGraficoGet>;
}

export default class ArticuloGraficoLista extends Component<Props, State> {


  private articuloGraficoService:ArticuloGraficoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      articulosGraficos: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_ARTICULOS_GRAFICO,
      funcionCallback: this.getElementos,
    });
    this.articuloGraficoService = new ArticuloGraficoService(Global.UsuarioService.getToken()!);
  }

  getElementos = () => {

    this.articuloGraficoService.list()
      .then((result) => {
        this.setState({ articulosGraficos: result });
      })
      .catch((error) => {
        this.articuloGraficoService.manejarErrorHTTP(error, "Árticulo Gráfico");
      });
  };

  componentDidMount() {
    this.articuloGraficoService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }
  armarFilas(
    articulosGraficos: Array<ArticuloGraficoGet>
  ): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (articulosGraficos.length > 0) {
      for (let i = 0; i < articulosGraficos.length; i++) {
        let fila: Array<string> = [];
        fila.push(articulosGraficos[i].nombre);
        fila.push(articulosGraficos[i].agencia_diseño);
        fila.push(articulosGraficos[i].descripcion);
        let filaResult: FilaTablaGenerica = {
          id: articulosGraficos[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Elementos.ArticulosGraficos.ArticuloGrafico.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Elementos.ArticulosGraficos.ArticuloGrafico.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.articuloGraficoService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.articulosGraficos)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.articuloGraficoService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaArticulosGraficos"
      />
    );
  }
}
