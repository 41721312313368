import React, { Component } from "react";
import * as Icon from "react-bootstrap-icons";
import { Table, Button } from "react-bootstrap";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import EntregaMaquinaLoad from "./EntregaMaquinaLoad";
import { EntregaMaquinaLectura } from "serviciossaintmichel";

export interface Props {
  entregasMaquina: Array<EntregaMaquinaLectura>;
  onChange?: Function;
}

interface State {
  entregasMaquina: Array<EntregaMaquinaLectura>;
}

export default class EntregasMaquinaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entregasMaquina: props.entregasMaquina,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (
      prevProps.entregasMaquina !== this.props.entregasMaquina &&
      this.state.entregasMaquina !== this.props.entregasMaquina
    ) {
      this.setState({ entregasMaquina: this.props.entregasMaquina });
    }

    if (prevState.entregasMaquina !== this.state.entregasMaquina && this.props.onChange) {
      this.props.onChange(this.state.entregasMaquina);
    }
  }

  eliminarEntregaMaquina = (idMaquina: number) => {
    const entregasMaquina = this.state.entregasMaquina.filter(
      (em: EntregaMaquinaLectura) => em.maquina_simbolica.id !== idMaquina
    );
    this.setState({
      entregasMaquina: [...entregasMaquina],
    });
  };

  agregarEntregaMaquina = (nuevaEntregaMaquina: EntregaMaquinaLectura): void => {
    this.setState((prevState) => ({
      entregasMaquina: [...prevState.entregasMaquina, nuevaEntregaMaquina],
    }));
  };

  actualizarEntregaMaquina = (idMaquina: number, infoEntrega: EntregaMaquinaLectura) => {
    const entregas = this.state.entregasMaquina.map((em) => (em.maquina_simbolica.id === idMaquina ? infoEntrega : em));
    this.setState({
      entregasMaquina: entregas,
    });
  };

  render() {
    const { entregasMaquina } = this.state;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Máquina</th>
              <th>Cantidad</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {entregasMaquina.map((entregaMaquina, idx) => (
              <tr key={entregaMaquina.maquina_simbolica.id}>
                <td>{entregaMaquina.maquina_simbolica.nombre}</td>
                <td>
                  <Input
                    type={InputTypes.Number}
                    data={entregaMaquina.cantidad}
                    onChange={(valor: number) => {
                      this.actualizarEntregaMaquina(entregaMaquina.maquina_simbolica.id, {
                        ...entregaMaquina,
                        cantidad: valor,
                      });
                    }}
                  />
                </td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      this.eliminarEntregaMaquina(entregaMaquina.maquina_simbolica.id);
                    }}
                  >
                    <Icon.Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <EntregaMaquinaLoad
          onSubmitCallback={this.agregarEntregaMaquina}
          excluirMaquinas={entregasMaquina.map((vm) => vm.maquina_simbolica.id)}
        />
      </>
    );
  }
}
