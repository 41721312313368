import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import InputDecimal from "../../../../compartido/components/input/InputDecimal";


import { CANALES_WEB_SOCKET, ProductoLimpiezaGet, ProductoLimpiezaPost, ProductoLimpiezaService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";








export interface Props {
  ocultar: Function;
}

interface State {
  productoLimpieza: ProductoLimpiezaPost;
  alerta: any;
}

export default class ProductoLimpiezaNuevo extends Component<Props, State> {

  private productoLimpiezaService:ProductoLimpiezaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      productoLimpieza: this.productoLimpiezaCrear(),
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    this.productoLimpiezaService = new ProductoLimpiezaService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    const productoLimpiezaCrear: ProductoLimpiezaPost =
      this.state.productoLimpieza;
    this.setState({
      productoLimpieza: productoLimpiezaCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  productoLimpiezaCrear(): ProductoLimpiezaPost {
    return {
      nombre: "",
      litros: 0,
      marca: "",
      descripcion: "",
    };
  }

  setearNombre = (nombre: string) => {
    let productoLimpieza = this.state.productoLimpieza;
    productoLimpieza.nombre = nombre;
    this.setState({ productoLimpieza: productoLimpieza });
  };
  setearLitros = (litros: number) => {
    let productoLimpieza = this.state.productoLimpieza;
    productoLimpieza.litros = litros;
    this.setState({ productoLimpieza: productoLimpieza });
  };
  setearMarca = (marca: string) => {
    let productoLimpieza = this.state.productoLimpieza;
    productoLimpieza.marca = marca;
    this.setState({ productoLimpieza: productoLimpieza });
  };
  setearDescripcion = (descripcion: string) => {
    let productoLimpieza = this.state.productoLimpieza;
    productoLimpieza.descripcion = descripcion;
    this.setState({ productoLimpieza: productoLimpieza });
  };

  handleCreate() {
    this.productoLimpiezaService.create(this.state.productoLimpieza)
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS_LIMPIEZA
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "ProductoLimpieza Creado!",
            titulo: "Atención",
            estado: "block",
          },
        });
        const productoLimpiezaCrear: ProductoLimpiezaPost =
          this.productoLimpiezaCrear();
        this.setState({
          productoLimpieza: productoLimpiezaCrear,
        });
      })
      .catch((error) => {
        this.productoLimpiezaService.manejarErrorHTTP(error, "Producto Limpieza");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.productoLimpieza.nombre &&
      this.state.productoLimpieza.litros > 0 &&
      this.state.productoLimpieza.marca &&
      this.state.productoLimpieza.descripcion
      ? false
      : true;
  };

  renderizarFormulario() {
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="nombre">Nombre</label>
                <InputCaracteres
                  id="nombre"
                  name="nombre"
                  onChange={this.setearNombre}
                  value={this.state.productoLimpieza.nombre}
                  placeholder="Ingrese Nombre de ProductoLimpieza"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="litros">Litros</label>
                <InputDecimal
                  id="litros"
                  name="litros"
                  onChange={this.setearLitros}
                  value={this.state.productoLimpieza.litros}
                  cantEnteros={3}
                  cantDecimales={3}
                  placeholder="Ingrese litros"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="marca">Marca</label>
                <InputCaracteres
                  id="marca"
                  name="marca"
                  onChange={this.setearMarca}
                  value={this.state.productoLimpieza.marca}
                  placeholder="Ingrese Marca"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="descripcion">Descripcion</label>
                <InputCaracteres
                  id="descripcion"
                  name="descripcion"
                  onChange={this.setearDescripcion}
                  value={this.state.productoLimpieza.descripcion}
                  placeholder="Ingrese Descripcion"
                />
              </div>
              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  ref="boton"
                  type="submit"
                  className={"btn btn-info"}
                  disabled={this.disableButton()}
                >
                  {"Crear"}
                </button>
                <button
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
              <div className={"form-group"}>
                <Alerta
                  tipo={this.state.alerta.tipo}
                  titulo={this.state.alerta.titulo}
                  mensaje={this.state.alerta.mensaje}
                  estado={this.state.alerta.estado}
                />
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
