import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Flex from "../../../libreria/appearance/flex/Flex";
import { Global } from "../../../Global";
import { VersionCuponesShow } from "./components/show/CuponesShow";
import DescuentoShow from "./components/show/CuponShow";
import CuponNew from "./components/new/CuponNew";
import CuponesShow from "./components/show/CuponesShow";
import CuponUpdate from "./components/update/CuponUpdate";

interface Props {}

interface State {
  tabVisible: any;
  disabledActualizar: boolean;
  disabledVer: boolean;
  responsive: boolean;
  idEntidad: number | null;
  recienCreado: boolean;
}

export default class Cupones extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista",
      disabledActualizar: true,
      disabledVer: true,
      responsive: window.innerWidth < 700 ? true : false,
      idEntidad: null,
      recienCreado: false,
    };

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  actualizar = (idEntidad: number) => {
    this.setState({
      tabVisible: "actualizar",
      disabledActualizar: false,
      idEntidad: idEntidad,
    });
  };

  ver = (idEntidad: number) => {
    this.setState({
      tabVisible: "ver",
      disabledVer: false,
      idEntidad: idEntidad,
      recienCreado: false,
    });
  };

  ocultarVer = () => {
    this.setState({
      tabVisible: "lista",
      disabledVer: true,
      idEntidad: null,
    });
  };

  ocultarNuevo = () => {
    this.setState({
      tabVisible: "lista",
    });
  };

  renderizarNuevo = () => {
    if (Global.UsuarioService.getPermisos().Administracion.Cupones.Cupon.canAdd()) {
      return (
        <Tab eventKey="nuevo" title="Nuevo" width="100%">
          {this.state.tabVisible === "nuevo" ? <CuponNew /> : null}
        </Tab>
      );
    }
  };

  renderizarLista = () => {
    if (Global.UsuarioService.getPermisos().Administracion.Cupones.Cupon.canView()) {
      return (
        <Tab eventKey="lista" title="Lista" width="100%">
          <CuponesShow
            version={VersionCuponesShow.TABLE}
            onUpdate={(cuponId: number) => {
              this.setState({ tabVisible: "actualizar", idEntidad: cuponId });
            }}
            onView={(cuponId: number) => {
              this.setState({ tabVisible: "ver", idEntidad: cuponId });
            }}
          />
        </Tab>
      );
    }
  };

  renderizarActualizar = () => {
    if (Global.UsuarioService.getPermisos().Administracion.Cupones.Cupon.canChange()) {
      return (
        <Tab width="100%" eventKey="actualizar" title="Actualizar" disabled={this.state.disabledActualizar}>
          {this.state.idEntidad && this.state.tabVisible === "actualizar" ? (
            <CuponUpdate cuponId={this.state.idEntidad} />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarVerDetalle = () => {
    if (Global.UsuarioService.getPermisos().Administracion.Cupones.Cupon.canView()) {
      return (
        <Tab width="100%" eventKey="ver" title="Ver Detalle" disabled={this.state.disabledVer}>
          {this.state.idEntidad && this.state.tabVisible === "ver" ? (
            <DescuentoShow cuponId={this.state.idEntidad} />
          ) : null}
        </Tab>
      );
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey="lista"
      transition={false}
      activeKey={this.state.tabVisible}
      onSelect={this.onSelect}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "flex-start" : "",
      }}
      unmountOnExit={true}
    >
      {this.renderizarNuevo()}
      {this.renderizarLista()}
      {this.renderizarActualizar()}
      {this.renderizarVerDetalle()}
    </Tabs>
  );

  render() {
    return (
      <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        <div style={{ display: "block", width: "100%" }}>{this.renderizar(this.state.responsive)}</div>
      </Flex>
    );
  }
}
