import React, { Component } from "react";
import {  Button } from "react-bootstrap";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import { Fuentes } from "../../../../../../../compartido/estilos/estilos";
import H from "../../../../../../../libreria/message/title/H";
import TelefonoClienteNew from "./TelefonoClienteNew";
import TelefonosClienteUpdate from "./TelefonosClienteUpdate";





export interface Props {
  clienteId: number;
}

interface State {
  renderNew: boolean;
}

export default class TelefonosClienteActualizar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      renderNew: false,
    };
  }

  onTelefonoNewClose = () => {
    this.setState({ renderNew: false });
  };

  render() {
    const { clienteId } = this.props;
    const { renderNew } = this.state;
    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="2rem"
        >
          <H size={3} fuente={Fuentes.Titulos} texto="Teléfonos del cliente" />
          <TelefonosClienteUpdate clienteId={clienteId} />
        </Flex>

        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="2rem"
        >
          {renderNew ? (
            <TelefonoClienteNew clienteId={clienteId} onCloseCallback={this.onTelefonoNewClose} />
          ) : (
            <Button onClick={() => this.setState({ renderNew: true })}> Agregar teléfono </Button>
          )}
        </Flex>
      </Flex>
    );
  }
}
