import React, { Component } from "react";
import Box from "../../../../../compartido/components/box/Box";
import Flex from "../../../../../compartido/components/flex/Flex";
import RequestButton from "../../../../../compartido/components/request-button/RequestButton";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import PasoDatosPersona from "./PasoDatosPersona";
import IconButton from "../../../../../compartido/components/icon-button/IconButton";
import H from "../../../../../compartido/components/titulos/H";
import { Fuentes } from "../../../../../compartido/estilos/estilos";
import PasoDatosAfip from "./PasoDatosAfip";
import PasoSeleccionDeGrupo from "./PasoSeleccionDeGrupo";
import PasoVinculacionYTipoCliente from "./vinculacionytipocliente/PasoVinculacionYTipoCliente";
import PasoDireccion from "./PasoDireccion";
import PasoFranjasHorarias from "./PasoFranjasHorarias";
import PasoRecorridos from "./PasoRecorridos";
import {
  DireccionDTO,
  CANALES_WEB_SOCKET,
  ClienteNuevo,
  ClienteService,
  EnteEconomico,
  FranjaHoraria,
  GrupoTipoCliente,
  PersonaNueva,
  Recorrido,
  RegistroCliente,
  TipoCliente,
  VinculacionCliente,
  WebSocketService,
  Persona,
} from "serviciossaintmichel";
import { Global } from "../../../../../Global";

export interface Props {
  clienteCreado: Function;
}

interface State {
  cliente: ClienteNuevo;
  paso: number;
  pasoValidado: boolean;
  grupoCliente: GrupoTipoCliente;
  ancho: string;
  grupoClienteConDireccionComercial: boolean;
}

export default class DatosEnPasos extends Component<Props, State> {
  private clienteService: ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      cliente: this.ClienteCrear(),
      grupoClienteConDireccionComercial: false,
      paso: 1,
      pasoValidado: false,
      grupoCliente: { id: -1, nombre: "" },
      ancho: window.innerWidth < 700 ? "98%" : "60%",
    };
    this.npasos = 8;
    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState !== this.state) {
      console.log("ESTADO -> ", this.state);
    }
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ ancho: "98%" });
    } else {
      this.setState({ ancho: "60%" });
    }
  };

  npasos: number;

  ClienteCrear(): ClienteNuevo {
    return {
      direccion: { direccion: null, franjas: [], recorridos: [] },
      vinculacion: {
        id: -1,
        fecha_alta: new Date(),
        fecha_baja: null,
        motivos_vinculacion: null,
        empleado: null,
        propietario: null,
        medio_comunicacion: null,
      },
      razon_social: "",
      cuit: "",
      condicionIVA: { id: 1, tipo: "Responsable Inscripto" },
      calle: "",
      numero: "",
      /* localidad: {
        id: 1,
        nombre: "Casco Urbano",
        partido: {
          id: 1,
          nombre: "La Plata",
          provincia: { id: 1, nombre: "Buenos Aires" },
        },
      }, */
      localidad: 1,
      persona_responsable: {
        nombre: "",
        apellido: "",
        dni: "",
        fecha_nacimiento: new Date(),
      },
      tipoCliente: { id: -1, tipo: "", grupo: -1 },
    };
  }

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return !this.state.pasoValidado;
  };

  renderPaso(paso: number) {
    const pasoFunction: { [name: string]: Function } = {
      1: this.renderPasoSeleccionDeGrupo,
      2: this.renderPasoDatosPersona,
      3: this.renderPasoDatosAfip,
      4: this.renderPasoTipoClienteYVinculacion,
      5: this.renderPasoDireccion,
      6: this.renderPasoFranjasHorarias,
      7: this.renderPasoRecorrido,
      8: this.renderPasoGuardar,
    };

    let defaultFunction = () => null;

    const renderFunction = pasoFunction[paso] || defaultFunction;

    return (
      <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%" marginTop="20px">
        {renderFunction(paso)}
      </Flex>
    );
  }

  setterValidezPaso(paso: number) {
    return (valido: boolean) => {
      if (this.state.paso === paso) {
        this.setState({ pasoValidado: valido });
      }
    };
  }

  render() {
    const { paso } = this.state;

    if (!paso) {
      return null;
    }

    return (
      <Box paddingHorizontal="20px" width={this.state.ancho}>
        <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <Flex
            container
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
            marginBottom="50px"
            marginTop="30px"
          >
            <H size={2} fuente="Segoe Print" texto="Cliente Nuevo" />
          </Flex>

          {this.renderBarraSuperior()}

          {this.renderPaso(paso)}

          {this.renderBarraInferior()}
        </Flex>
      </Box>
    );
  }

  // -----------------------------------------------------------
  // ---------------- Paso Seleccion De Grupo ------------------

  renderPasoSeleccionDeGrupo = (paso: number) => {
    return (
      <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%">
        <PasoSeleccionDeGrupo grupoSeleccionado={this.grupoSeleccionado} />
      </Flex>
    );
  };

  grupoSeleccionado = async (grupoTipoCliente: GrupoTipoCliente) => {
    let paso: number = this.state.paso;
    paso++;
    let grupoClienteConDireccionComercial = await this.clienteService.clienteConDireccionComercial(grupoTipoCliente.id);
    this.setState({
      grupoCliente: grupoTipoCliente,
      grupoClienteConDireccionComercial: grupoClienteConDireccionComercial,
      paso: paso,
      pasoValidado: false,
    });
  };

  // ------------------------------------------------------
  // ---------------- Paso Datos Persona ------------------

  renderPasoDatosPersona = (paso: number) => {
    const { cliente } = this.state;
    return (
      <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%">
        <PasoDatosPersona
          persona={cliente.persona_responsable}
          onChangePersona={this.onChangePersona}
          onChangeValidation={this.setterValidezPaso(paso)}
        />
      </Flex>
    );
  };

  onChangePersona = (persona: PersonaNueva) => {
    let x = this.state.cliente;
    x.persona_responsable = persona;
    this.setState({ cliente: x });
  };

  // ---------------------------------------------------
  // ---------------- Paso Datos Afip ------------------

  renderPasoDatosAfip = (paso: number) => {
    const { cliente, grupoCliente } = this.state;

    return (
      <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%">
        <PasoDatosAfip
          datosAfip={this.armarDatosAfip(cliente)}
          grupoCliente={grupoCliente}
          onChangeDatosAfip={this.onChangeDatosAfip}
          onChangeValidation={this.setterValidezPaso(paso)}
          grupoClienteConDireccionComercial={this.state.grupoClienteConDireccionComercial}
        />
      </Flex>
    );
  };

  armarDatosAfip = (cliente: ClienteNuevo): EnteEconomico => {
    const datosAfip: EnteEconomico = {
      calle: cliente.calle,
      condicionIVA: cliente.condicionIVA,
      cuit: cliente.cuit,
      localidad: cliente.localidad,
      numero: cliente.numero,
      razon_social: cliente.razon_social,
    };
    return datosAfip;
  };

  onChangeDatosAfip = (datosAfip: EnteEconomico) => {
    let cliente = this.state.cliente;
    cliente = { ...cliente, ...datosAfip }; //Merge entre dos objetos
    this.setState({ cliente: cliente });
  };

  // --------------------------------------------------------------------
  // ---------------- Paso Vinculacion y Tipo Cliente  ------------------

  renderPasoTipoClienteYVinculacion = (paso: number) => {
    const { grupoCliente, cliente } = this.state;

    return (
      <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%">
        <PasoVinculacionYTipoCliente
          grupoCliente={grupoCliente}
          vinculacion={cliente.vinculacion}
          tipoCliente={cliente.tipoCliente.id !== -1 ? cliente.tipoCliente : null}
          onChangeVinculacion={this.onChangeVinculacion}
          onChangeTipoCliente={this.onChangeTipoCliente}
          onChangeValidation={this.setterValidezPaso(paso)}
        />
      </Flex>
    );
  };

  onChangeVinculacion = (vinculacion: VinculacionCliente) => {
    let x = this.state.cliente;
    x.vinculacion = vinculacion;
    this.setState({ cliente: x });
  };

  onChangeTipoCliente = (tipoCliente: TipoCliente) => {
    let x = this.state.cliente;
    x.tipoCliente = tipoCliente;
    this.setState({ cliente: x });
  };

  // ---------------------------------------------------
  // ---------------- Paso Dirección  ------------------

  renderPasoDireccion = (paso: number) => {
    return (
      <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%">
        <PasoDireccion
          direccion={this.state.cliente.direccion.direccion}
          onChangeDireccion={this.onChangeDireccion}
          onChangeValidation={this.setterValidezPaso(paso)}
        />
      </Flex>
    );
  };

  onChangeDireccion = (direccion: DireccionDTO) => {
    let x = this.state.cliente;
    x.direccion.direccion = direccion;
    if (!this.state.grupoClienteConDireccionComercial) {
      x.calle = direccion.calle;
      x.numero = direccion.numero;
      x.localidad = direccion.localidad;
    }
    this.setState({ cliente: x });
  };

  // ---------------------------------------------------------
  // ---------------- Paso Franjas Horarias  ------------------

  renderPasoFranjasHorarias = (paso: number) => {
    return (
      <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%">
        <PasoFranjasHorarias
          franjas={this.state.cliente.direccion.franjas}
          onChangeFranjas={this.onChangeFranjas}
          onChangeValidation={this.setterValidezPaso(paso)}
        />
      </Flex>
    );
  };

  onChangeFranjas = (franjas: Array<FranjaHoraria>) => {
    let x = this.state.cliente;
    x.direccion.franjas = franjas;
    this.setState({ cliente: x });
  };

  // ---------------------------------------------------------
  // ---------------- Paso Recorrido  ------------------

  renderPasoRecorrido = (paso: number) => {
    return (
      <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%">
        <PasoRecorridos
          recorridos={this.state.cliente.direccion.recorridos}
          onChangeRecorridos={this.onChangeRecorridos}
          onChangeValidation={this.setterValidezPaso(paso)}
        />
      </Flex>
    );
  };

  onChangeRecorridos = (recorridos: Array<Recorrido>) => {
    let x = this.state.cliente;
    x.direccion.recorridos = recorridos;
    this.setState({ cliente: x });
  };

  // ---------------------------------------------------------
  // ---------------- Paso Guardar  ------------------

  renderPasoGuardar = () => {
    return (
      <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="300px">
        <RequestButton
          texto="Guardar"
          msgOnSuccess="Cliente creado con exito"
          msgOnFailure="Ocurrio un error al crear el Cliente"
          propsBoton={{
            variant: "info",
            disabled: false,
          }}
          onClick={() => this.handleCreate()}
        />
      </Flex>
    );
  };

  async handleCreate() {
    const registroClientePayload = this.generarRegistroCliente(this.state.cliente);
    try {
      await this.clienteService.registrarCliente(registroClientePayload);
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES);
      this.setState({ cliente: this.ClienteCrear(), paso: 1 });
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error, "Cliente");
    }
  }

  generarVinculacionCliente(vinculacion: VinculacionCliente): VinculacionCliente {
    return {
      id: vinculacion.id,
      fecha_alta: vinculacion.fecha_alta,
      fecha_baja: vinculacion.fecha_baja,
      empleado: vinculacion.empleado,
      propietario: vinculacion.propietario,
      medio_comunicacion: vinculacion.medio_comunicacion,
      motivos_vinculacion: vinculacion.motivos_vinculacion !== null ? vinculacion.motivos_vinculacion : [],
    };
  }

  generarRegistroCliente(cliente: ClienteNuevo): RegistroCliente {
    const { vinculacion } = cliente;

    const vinculacionCliente = this.generarVinculacionCliente(vinculacion);

    const recorridosIds = cliente.direccion.recorridos.map((recorrido) => {
      return { id: recorrido.id };
    });

    const franjas_horarias = cliente.direccion.franjas.map((franja_horaria) => franja_horaria);

    const personaResponsable: Persona = {
      ...cliente.persona_responsable,
      id: -1,
      fecha_nacimiento: cliente.persona_responsable.fecha_nacimiento ?? new Date(), // nunca deberia venir la fecha de nacimiento en nulo
    };

    return {
      cliente: {
        id: -1,
        cuit: cliente.cuit,
        razon_social: cliente.razon_social,
        calle: cliente.calle,
        numero: cliente.numero,
        persona_responsable: -1,
        localidad: cliente.localidad,
        condicion_iva: cliente.condicionIVA.id,
        tipo_cliente: cliente.tipoCliente.id,
        usuario: -1,
      },
      persona_responsable: personaResponsable,
      direccion: cliente.direccion.direccion,
      vinculacion_cliente: vinculacionCliente,
      franjas_horarias: franjas_horarias,
      recorridos: recorridosIds,
    };
  }

  // ---------------------------------------------------------
  // ---------------------------------------------------------

  siguiente = () => {
    let paso: number = this.state.paso;
    paso++;
    this.setState({ paso: paso, pasoValidado: false });
  };

  disableSiguiente = (): boolean => {
    return !this.state.pasoValidado;
  };

  isPasoValidado = (paso: number): boolean => {
    return false;
  };

  anterior = () => {
    let paso: number = this.state.paso;
    paso--;
    let validado: boolean = this.isPasoValidado(paso);

    this.setState({ paso: paso, pasoValidado: validado });
  };

  renderBarraInferior = () => {
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
        marginBottom="20px"
        marginTop="10px"
      >
        <Flex
          container
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          width="25%"
          paddingLeft="5px"
        >
          <H
            size={5}
            fuente="Segoe Print"
            texto={"Paso: " + this.state.paso.toString() + " de " + this.npasos.toString()}
          />
        </Flex>

        <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="50%"></Flex>

        <Flex
          container
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="flex-end"
          width="25%"
          paddingRight="5px"
        >
          {this.state.paso > 1 ? (
            <Button style={{ marginRight: "10px" }} onClick={this.anterior}>
              <Icon.ArrowBarLeft />
            </Button>
          ) : null}

          {this.state.paso > 1 && this.state.paso < this.npasos ? (
            <IconButton
              onClick={this.siguiente}
              propsBoton={{
                variant: "primary",
                disabled: this.disableSiguiente(),
              }}
              icon={<Icon.ArrowBarRight />}
            />
          ) : null}
        </Flex>
      </Flex>
    );
  };

  renderIconoBarraSuperior(nombre: string) {
    const size = 30;
    const marginLeft = "10px";
    const marginBottom = "20px";

    const nombreIcono: { [name: string]: JSX.Element } = {
      Empresa: <Icon.Building size={size} style={{ marginLeft: marginLeft, marginBottom: marginBottom }} />,
      Comercio: <Icon.Shop size={size} style={{ marginLeft: marginLeft, marginBottom: marginBottom }} />,
      Domicilio: <Icon.HouseDoor size={size} style={{ marginLeft: marginLeft, marginBottom: marginBottom }} />,
      Particular: <Icon.Person size={size} style={{ marginLeft: marginLeft, marginBottom: marginBottom }} />,
    };
    const iconoDefault = (
      <Icon.QuestionSquare size={size} style={{ marginLeft: marginLeft, marginBottom: marginBottom }} />
    );

    return nombreIcono[nombre] || iconoDefault;
  }

  renderBarraSuperior = () => {
    const { paso, grupoCliente } = this.state;

    if (paso > 1)
      return (
        <Flex
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
          height="50px"
          marginBottom="20px"
          marginTop="10px"
        >
          <H size={5} fuente={Fuentes.Titulos} texto={"Grupo Cliente: " + grupoCliente.nombre} />

          {this.renderIconoBarraSuperior(grupoCliente.nombre)}
        </Flex>
      );
  };
}
