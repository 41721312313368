import React, { Component } from "react";
import Flex from "../../../../../compartido/components/flex/Flex";
import * as Icon from 'react-bootstrap-icons';
import { Button,Table } from "react-bootstrap";


import { CANALES_WEB_SOCKET, EmpleadoGet, EmpleadoPost, EmpleadoService, RelacionEmpleadoArea, RelacionEmpleadoSubArea, TipoResponsabilidad, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";



export interface Props {
  empleado: EmpleadoGet;
}

interface State {
  empleado: EmpleadoGet;
  visible:boolean;
}

export default class EmpleadoVerAreas extends Component<Props, State> {

  private empleadoService:EmpleadoService;

  constructor(props: Props) {
    super(props);

    this.state = {
      empleado: this.props.empleado,
      visible:true
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_PERSONAL_EMPLEADOS,
      funcionCallback: this.refrescarEmpleado,
    });
    this.empleadoService = new EmpleadoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
  }

  refrescarEmpleado = async (e:MessageEvent) => {
    let id:number = JSON.parse(e.data).message.empleadoId;
    if (id==this.state.empleado.id)
    {
    let aux:EmpleadoGet = await this.empleadoService.get(id);
    this.setState({ empleado: aux });
    }
  };

  renderizarFilas = () => {
    return this.state.empleado.areas.map((relacion, index) => {
      return (
        <tr>
          <td className="text-center" >{index}</td>
          <td className="text-center" >{relacion.area.nombre}</td>
          <td className="text-center">{relacion.tipo_responsabilidad.tipo}</td>
          <td className="text-center">{ new Date(relacion.fecha_inicio).toLocaleDateString()}</td>

          
          
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Area</th>
            <th className="text-center">Responsabilidad</th>
            <th className="text-center">Fecha de Inicio</th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };


 

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="flex-start"
        justifyContent="center"
      >

        <Flex container flexDirection="row">
            <Flex
              container
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              marginTop="5px"
            >
              <h5>Areas</h5>
            </Flex>
            <Flex
              container
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              marginRigth="10px"

            >
              
            <Button
            variant="ligth" 
              onClick={() => {
                this.setState({ visible: !this.state.visible });
              }}
            >
                          {this.state.visible ? <Icon.Dash size={"25px"}/> :  <Icon.Plus size={"25px"}/>}

            </Button>



            
            </Flex>
          </Flex>
            
            {this.state.visible ?
          <Flex
              container
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
              marginTop="10px"

            >



              {this.renderizarTabla()}



            </Flex>
          :null}



      </Flex>
    );
  }
}
