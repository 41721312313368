import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { diaNumToId } from "../../../../compartido/utils/dias";
import RequestButton from "../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../libreria/appearance/flex/Flex";
import { Item } from "../../../../libreria/data/checkboxlist/Item";
import RepartosCheckbox from "../../../repartos/components/show/RepartosCheckbox";
import { CANALES_WEB_SOCKET, Reparto, RepartoDiarioService, RepartoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {}

interface State {
  fecha: Date | null;
  repartos: Array<Reparto>;
  repartosSeleccionados: Array<number>;
  valido: boolean;
}

export default class RepartoDiarioCreacionGrupal extends Component<Props, State> {

  private repartoDiarioService:RepartoDiarioService;
  private repartoService:RepartoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      fecha: null,
      repartos: [],
      repartosSeleccionados: [],
      valido: false,
    };

    this.repartoDiarioService =  new RepartoDiarioService(Global.UsuarioService.getToken()!);
    this.repartoService =  new RepartoService(Global.UsuarioService.getToken()!);

  }

  setFechaRealizacion = async (fecha: Date) => {
    const diaSemana = fecha.getDay();
    const dia = diaNumToId(diaSemana);

    const queryParams = new URLSearchParams({ dia_reparto: dia, sin_realizacion_en_fecha: fecha.toISOString() });
    const repartos = await this.repartoService.listAll(queryParams);
    this.setState({ fecha: fecha, repartos: repartos });
  };

  setRepartosSeleccionados = (itemsRepartos: Array<Item>) => {
    const seleccionados = itemsRepartos.filter((item: Item) => item.checked).map((item: Item) => item.item.id);
    this.setState({ repartosSeleccionados: seleccionados });
  };

  esValido() {
    return !!this.state.fecha && this.state.repartosSeleccionados.length > 0;
  }

  crearRepartosDiarios = async () => {
    const { fecha, repartosSeleccionados } = this.state;
    if (fecha === null) {
      return;
    }

    try {
      await this.repartoDiarioService.creacionMultiple(fecha, repartosSeleccionados);
      this.setState({ fecha: null, repartosSeleccionados: [] });
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS, {});
    } catch (error) {
      this.repartoDiarioService.manejarErrorHTTP(error, "Repartos Diarios");
    }
  };

  render() {
    const { fecha, repartos } = this.state;
    const valido = this.esValido();

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" gap="1rem">
        <Flex flexDirection="row" alignItems="center">
          <strong style={{ width: "100%" }}>Fecha de realización</strong>
          <DatePicker
            placeholderText="Ingrese fecha"
            onChange={(fecha: Date) => {
              this.setFechaRealizacion(fecha);
            }}
            selected={fecha}
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
          />
        </Flex>

        <Flex flexDirection="column">
          <strong>Repartos</strong>
          {fecha ? (
            <RepartosCheckbox repartos={repartos} onChange={this.setRepartosSeleccionados} />
          ) : (
            <div>Seleccione una fecha de realizacion</div>
          )}
        </Flex>

        <Flex flexDirection="column" marginTop="1rem" alignItems="center">
          <RequestButton
            propsBoton={{ disabled: !valido }}
            texto={"Crear repartos diarios"}
            onClick={this.crearRepartosDiarios}
          />
        </Flex>
      </Flex>
    );
  }
}
