import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { OrdenCompraMaquinaSimbolica } from "serviciossaintmichel";
import { Fuentes } from "../../../../../../../../compartido/estilos/estilos";
import Flex from "../../../../../../../../libreria/appearance/flex/Flex";
import H from "../../../../../../../../libreria/message/title/H";
import MaquinaSimbolicaOrdenCompraLoad from "./MaquinaSimbolicaOrdenCompraLoad";
import MaquinasSimbolicasOrdenCompraShow from "./MaquinasSimbolicasOrdenCompraShow";




export interface Props {
  ordenCompraId: number;
  maquinasOrdenCompra: Array<OrdenCompraMaquinaSimbolica>;
  onChange: Function;
  onValidationChange: Function;
}

interface State {
  maquinaOrdenCompra: OrdenCompraMaquinaSimbolica | null;
  maquinaOrdenCompraValida: boolean;
  maquinasOrdenCompra: Array<OrdenCompraMaquinaSimbolica>;
  valido: boolean;
  agregandoMaquina: boolean;
}

export default class MaquinasSimbolicasOrdenCompraLoad extends Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    let ordenes: Array<OrdenCompraMaquinaSimbolica> = [];

    if (props.maquinasOrdenCompra) {
      ordenes = props.maquinasOrdenCompra;
    }

    this.state = {
      maquinaOrdenCompra: null,
      maquinasOrdenCompra: ordenes,
      valido: false,
      agregandoMaquina: false,
      maquinaOrdenCompraValida: false,
    };
  }

  componentDidMount = () => {
    this.validar();
  };

  validar = () => {
    const valido = this.state.maquinasOrdenCompra.length > 0;
    this.setState({ valido: valido });
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      JSON.stringify(prevState.maquinasOrdenCompra) !==
      JSON.stringify(this.state.maquinasOrdenCompra)
    ) {
      this.validar();
      this.props.onChange(this.state.maquinasOrdenCompra);
    }

    if (prevState.valido !== this.state.valido) {
      this.props.onValidationChange(this.state.valido);
    }
  };

  agregarMaquinaOrdenCompra = () => {
    if (this.state.maquinaOrdenCompra === null) {
      return;
    }

    const newMaquinasOrdenCompra = [
      ...this.state.maquinasOrdenCompra,
      this.state.maquinaOrdenCompra,
    ];

    this.setState({
      maquinasOrdenCompra: newMaquinasOrdenCompra,
      maquinaOrdenCompra: null,
      maquinaOrdenCompraValida: false,
      agregandoMaquina: false,
    });
  };

  descartarMaquinaOrdenCompra = (indice: number) => {
    const newMaquinasOrdenCompra = this.state.maquinasOrdenCompra.filter(
      (_, idx) => idx !== indice
    );
    this.setState({
      maquinasOrdenCompra: newMaquinasOrdenCompra,
    });
  };

  render = () => {
    const {
      maquinasOrdenCompra,
      agregandoMaquina,
      maquinaOrdenCompra,
      maquinaOrdenCompraValida,
    } = this.state;

    return (
      <>
        <H size={4} texto={"Máquinas"} fuente={Fuentes.Titulos} />
        {maquinasOrdenCompra.length > 0 ? (
          <MaquinasSimbolicasOrdenCompraShow
            maquinasOrdenCompra={maquinasOrdenCompra}
            showDelete={true}
            deleteHandler={(idx: number) => {
              this.descartarMaquinaOrdenCompra(idx);
            }}
          />
        ) : (
          <span>Sin máquinas asignadas</span>
        )}
        {agregandoMaquina ? (
          <Flex
            border="1px solid grey"
            borderRadius="5px"
            padding="1rem"
            marginTop="1rem"
            marginBottom="1rem"
            flexDirection="column"
          >
            <MaquinaSimbolicaOrdenCompraLoad
              maquinaOrdenCompra={maquinaOrdenCompra}
              onChange={(moc: OrdenCompraMaquinaSimbolica) => {
                this.setState({ maquinaOrdenCompra: moc });
              }}
              onValidationChange={(valido: boolean) => {
                this.setState({ maquinaOrdenCompraValida: valido });
              }}
              maquinasNoValidas={maquinasOrdenCompra.map(
                (moc) => moc.maquina_simbolica
              )}
            />
            <Flex container justifyContent="space-around">
              <Button
                variant="success"
                onClick={this.agregarMaquinaOrdenCompra}
                disabled={!maquinaOrdenCompraValida}
              >
                Agregar
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  this.setState({ agregandoMaquina: false });
                }}
              >
                Descartar
              </Button>
            </Flex>
          </Flex>
        ) : (
          <Flex container justifyContent="center" marginTop="1rem">
            <Button
              variant="success"
              onClick={() => {
                this.setState({ agregandoMaquina: true });
              }}
            >
              Agregar máquina
            </Button>
          </Flex>
        )}
      </>
    );
  };
}
