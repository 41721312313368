import React, { Component } from "react";
import * as Icon from "react-bootstrap-icons";
import { Table, Button } from "react-bootstrap";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import EntregaComboRetornableLoad from "./EntregaComboRetornableLoad";
import { EntregaComboRetornableLectura } from "serviciossaintmichel";

export interface Props {
  entregasCombo: Array<EntregaComboRetornableLectura>;
  onChange?: Function;
}

interface State {
  entregasCombo: Array<EntregaComboRetornableLectura>;
}

export default class EntregasComboRetornableLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entregasCombo: props.entregasCombo,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (prevProps.entregasCombo !== this.props.entregasCombo && this.state.entregasCombo !== this.props.entregasCombo) {
      this.setState({ entregasCombo: this.props.entregasCombo });
    }

    if (prevState.entregasCombo !== this.state.entregasCombo && this.props.onChange) {
      this.props.onChange(this.state.entregasCombo);
    }
  }

  eliminarEntregaCombo = (idCombo: number) => {
    const entregasCombo = this.state.entregasCombo.filter(
      (ec: EntregaComboRetornableLectura) => ec.combo_retornable.id !== idCombo
    );
    this.setState({
      entregasCombo: [...entregasCombo],
    });
  };

  agregarEntregaCombo = (nuevaEntregaCombo: EntregaComboRetornableLectura): void => {
    this.setState((prevState) => ({
      entregasCombo: [...prevState.entregasCombo, nuevaEntregaCombo],
    }));
  };

  actualizarEntregaCombo = (idCombo: number, infoEntrega: EntregaComboRetornableLectura) => {
    const ventas = this.state.entregasCombo.map((ec) => (ec.combo_retornable.id === idCombo ? infoEntrega : ec));
    this.setState({
      entregasCombo: ventas,
    });
  };

  render() {
    const { entregasCombo } = this.state;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Combo Retornable</th>
              <th>Cantidad</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {entregasCombo.map((entregaCombo, idx) => (
              <tr key={entregaCombo.combo_retornable.id}>
                <td>{entregaCombo.combo_retornable.nombre}</td>
                <td>
                  <Input
                    type={InputTypes.Number}
                    data={entregaCombo.cantidad}
                    onChange={(valor: number) => {
                      this.actualizarEntregaCombo(entregaCombo.combo_retornable.id, {
                        ...entregaCombo,
                        cantidad: valor,
                      });
                    }}
                    min={1}
                  />
                </td>

                <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      this.eliminarEntregaCombo(entregaCombo.combo_retornable.id);
                    }}
                  >
                    <Icon.Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <EntregaComboRetornableLoad
          onSubmitCallback={this.agregarEntregaCombo}
          excluirCombos={entregasCombo.map((vc) => vc.combo_retornable.id)}
        />
      </>
    );
  }
}
