import React, { Component } from "react";
import { Form, ListGroup } from "react-bootstrap";
import { Fuentes } from "../../estilos/estilos";
import { Item } from "./Item";

interface Props<T> {
  item: Item<T>;
  onChange(item: Item<T>): void;
}

interface State<T> {
  item: Item<T>;
}

class CheckBoxItem<T> extends Component<Props<T>, State<T>> {
  constructor(props: Props<T>) {
    super(props);
    this.state = {
      item: this.props.item,
    };
  }

  componentDidMount() {}


  componentDidUpdate(prevProps:Props<T>) 
  {
    if(prevProps.item.id!= this.props.item.id || prevProps.item.estado!= this.props.item.estado)
      this.setState({item:this.props.item});
  }



  onChange = () => {
    let item: Item<T> = this.state.item;
    item.estado = !item.estado;
    this.setState({ item: item });
    this.props.onChange(item);
  };

  render() {
    return (
    <ListGroup.Item >
        <Form.Check checked={this.state.item.estado ? true : false}  isValid = {this.state.item.estado ? true : false} onChange={this.onChange} type="checkbox" label={this.state.item.nombre} style={{fontSize:19,fontFamily:Fuentes.Labels}}/>
    </ListGroup.Item>
      
    );
  }
}

const Estilos = {};

export default CheckBoxItem;
