import React, { Component, FormEvent } from "react";
import "react-datepicker/dist/react-datepicker.css";
import PromocionLoad from "../load/PromocionLoad";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import { CANALES_WEB_SOCKET, Promocion, PromocionPost, PromocionService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";



export interface Props {
  onSaveSuccess: Function;
  onExit?: Function;
}

interface State {
  promocion: PromocionPost;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class PromocionNew extends Component<Props, State> {

  private promocionService:PromocionService;

  constructor(props: Props) {
    super(props);
    this.state = {
      promocion: this.createEmptyPromocion(),
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    this.promocionService =  new PromocionService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  createEmptyPromocion(): PromocionPost {
    let x: PromocionPost = {
      nombre: "",
    };
    return x;
  }

  onSave = () => {
    return this.promocionService.create(this.state.promocion)
      .then((promocion) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.VENTAS_PROMOCIONES, {
          type: "NEW",
          idPromocion: promocion.id,
        });
        Swal.fire({
          icon: "success",
          text: "Promocion Creada",
        });
        this.props.onSaveSuccess(promocion.id);
      })
      .catch((error) => {
        this.promocionService.manejarErrorHTTP(error, "Promocion");
      });
  };

  onExit = () => {
    if (this.props.onExit != undefined) this.props.onExit();
  };

  onChange = (promocion: Promocion) => {
    this.setState({ promocion: promocion });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex
      flexDirection="column"
      width="50%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={3} texto={"Promocion Nueva"} />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <PromocionLoad
            promocion={this.state.promocion}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
          />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Crear"}
            onClick={this.onSave}
          />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
