import React, { Component } from "react";

import Header from "./Header/Header";
import Cuerpo from "./Cuerpo/Cuerpo";
import { getRutas, getRutasAccesibles } from "./rutasSidebar";
import PubSub from "pubsub-js";

import Flex from "../libreria/appearance/flex/Flex";
import Menu from "./Menu/Menu";
import { Global } from "../Global";

import "./template.css";

import * as $ from "jquery";
import Background from "../compartido/components/background/Background.tsx";

export default class Template extends Component {
  constructor(props) {
    super(props);
    this.sidebar = React.createRef();
    this.cuerpo = React.createRef();
    this.state = {
      opciones: getRutasAccesibles(),
      rutasSidebar: getRutas(),
      usuario: Global.UsuarioService.getUsuario(),
    };
    PubSub.subscribe("CARGA USUARIO", () =>
      this.setState({
        opciones: getRutasAccesibles(),
        rutasSidebar: getRutas(),
        usuario: Global.UsuarioService.getUsuario(),
      })
    );
  }

  componentDidMount() {
    $("#sidebarCollapse").on("click", function () {
      $("#sidebar").toggleClass("active");
      $(this).toggleClass("active");
    });
  }

  render() {
    return (
      <Background>
        <Flex flexDirection="column" justifyContent="flex-start" alignItems="center" width="100%" height="100%">
          <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
            <Header rutas={[]} history={this.props.history} usuario={this.state.usuario} />

            <Menu rutas={getRutasAccesibles()} />
          </Flex>

          <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%" marginBottom="2rem">
            <Cuerpo rutasSidebar={this.state.rutasSidebar} rutasNavbar={[]} />
          </Flex>
        </Flex>
      </Background>
    );
  }
}
