import { Checkbox, Flex, NumberInput, Select, TextInput } from "@mantine/core";
import _ from "lodash";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { CuentaBancaria, MedioTransferenciaBancaria, TransferenciaBancaria } from "serviciossaintmichel";
import CuentasBancariasShow from "../../../administracion/seccion-clientes/clientes/components/actualizar/cuentas-bancarias/CuentasBancariasShow";

export interface Props {
  onChange: Function;
  onValidationChange: Function;
  transferenciaBancaria: TransferenciaBancaria;
  clienteId: number;
}

interface State {
  transferenciaBancaria: TransferenciaBancaria;
  validation: boolean;
}

export default class TransferenciaBancariaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      transferenciaBancaria: props.transferenciaBancaria,
      validation: this.esValido(props.transferenciaBancaria),
    };
  }

  componentDidMount = () => {
    this.props.onValidationChange(this.state.validation);
  };

  componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<State>) => {
    if (!_.isEqual(prevState.transferenciaBancaria, this.state.transferenciaBancaria)) {
      this.setState({ validation: this.esValido(this.state.transferenciaBancaria) });
      this.props.onChange(this.state.transferenciaBancaria);
    }

    if (prevState.validation !== this.state.validation) {
      this.props.onValidationChange(this.state.validation);
    }
  };

  esValido = (transferenciaBancaria: TransferenciaBancaria) => {
    return (
      transferenciaBancaria.cuenta_origen.id > 0 &&
      transferenciaBancaria.cuenta_destino.id > 0 &&
      transferenciaBancaria.numero_comprobante > 0
    );
  };

  setValidada = (validada: boolean) => {
    this.setState((prevState: State) => ({
      transferenciaBancaria: { ...prevState.transferenciaBancaria, validada: validada },
    }));
  };

  getMediosTransferencia = () => {
    const medios = Object.values(MedioTransferenciaBancaria).map((medio) => medio);
    return medios;
  };

  setFecha = (fecha: Date) => {
    this.setState((prevState: State) => ({
      transferenciaBancaria: { ...prevState.transferenciaBancaria, fecha: fecha },
    }));
  };

  setMedioTransferencia = (medio: MedioTransferenciaBancaria) => {
    this.setState((prevState: State) => ({
      transferenciaBancaria: { ...prevState.transferenciaBancaria, medio: medio },
    }));
  };

  setNumeroComprobante = (numeroComprobante: number) => {
    this.setState((prevState: State) => ({
      transferenciaBancaria: { ...prevState.transferenciaBancaria, numero_comprobante: numeroComprobante },
    }));
  };

  setNumeroTerminal = (numeroTerminal: number) => {
    this.setState((prevState: State) => ({
      transferenciaBancaria: { ...prevState.transferenciaBancaria, numero_terminal: numeroTerminal },
    }));
  };

  setConcepto = (concepto: string) => {
    this.setState((prevState: State) => ({
      transferenciaBancaria: { ...prevState.transferenciaBancaria, concepto: concepto },
    }));
  };

  setObservacion = (observacion: string) => {
    this.setState((prevState: State) => ({
      transferenciaBancaria: { ...prevState.transferenciaBancaria, observacion: observacion },
    }));
  };

  setCuentaBancariaOrigen = (cuenta: CuentaBancaria | null) => {
    if (cuenta === null) {
      return;
    }

    this.setState((prevState: State) => ({
      transferenciaBancaria: { ...prevState.transferenciaBancaria, cuenta_origen: cuenta },
    }));
  };

  setCuentaBancariaDestino = (cuenta: CuentaBancaria | null) => {
    if (cuenta === null) {
      return;
    }

    this.setState((prevState: State) => ({
      transferenciaBancaria: { ...prevState.transferenciaBancaria, cuenta_destino: cuenta },
    }));
  };

  render() {
    const { transferenciaBancaria } = this.state;
    const { clienteId } = this.props;

    return (
      <Flex direction={"column"} align={"center"} gap={"md"}>
        <Flex direction={"column"}>
          <span>Fecha</span>
          <DatePicker
            placeholderText="Ingrese fecha"
            onChange={this.setFecha}
            selected={transferenciaBancaria.fecha}
            dateFormat="dd/MM/yyyy"
          />
        </Flex>
        <CuentasBancariasShow
          version="select"
          filtros={new URLSearchParams({ limite: "0", cliente_id: clienteId.toString() })}
          propsSelect={{
            callbackParent: this.setCuentaBancariaOrigen,
            seleccionado: transferenciaBancaria.cuenta_origen.id,
            label: "Cuenta origen",
            placeholder: "Seleccione cuenta bancaria de origen",
            style: { width: "auto" },
          }}
        />
        <CuentasBancariasShow
          version="select"
          filtros={new URLSearchParams({ limite: "0", cuenta_empresa: "1" })}
          propsSelect={{
            callbackParent: this.setCuentaBancariaDestino,
            seleccionado: transferenciaBancaria.cuenta_destino.id,
            label: "Cuenta destino",
            placeholder: "Seleccione cuenta bancaria de destino",
            style: { width: "auto" },
          }}
        />
        <Select
          label={"Medio de la transferencia"}
          data={this.getMediosTransferencia()}
          value={transferenciaBancaria.medio}
          onChange={this.setMedioTransferencia}
        />
        <Checkbox
          label={"Transferencia validada"}
          checked={transferenciaBancaria.validada}
          onChange={(event) => this.setValidada(event.currentTarget.checked)}
        />
        <NumberInput
          label={"Número de comprobante"}
          value={transferenciaBancaria.numero_comprobante}
          onChange={this.setNumeroComprobante}
        />
        <NumberInput
          label={"Número de terminal"}
          value={transferenciaBancaria.numero_terminal}
          onChange={this.setNumeroTerminal}
        />
        <TextInput
          label={"Concepto"}
          value={transferenciaBancaria.concepto}
          onChange={(event) => this.setConcepto(event.currentTarget.value)}
        />
        <TextInput
          label={"Observación"}
          value={transferenciaBancaria.observacion}
          onChange={(event) => this.setObservacion(event.currentTarget.value)}
        />
      </Flex>
    );
  }
}
