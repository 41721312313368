import React, { Component } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  Cliente,
  EmpleadoGet,
  Fuente,
  FuentePost,
  MedioComunicacion,
  MedioIngreso,
  Propietario,
} from "serviciossaintmichel";
import CheckBoxSelectEmpleado from "../../../../../administracion/seccion-clientes/clientes/components/nuevo/vinculacionytipocliente/CheckBoxSelectEmpleado";
import CheckBoxSelectPropietario from "../../../../../administracion/seccion-clientes/clientes/components/nuevo/vinculacionytipocliente/CheckBoxSelectPropietario";
import { Fuentes } from "../../../../../compartido/estilos/estilos";
import Time, {
  getDateFromTimeString,
  getTimeStringFromDate,
} from "../../../../../compartido/utils/Time";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import H from "../../../../../libreria/message/title/H";
import MedioComunicacionShow from "../show/mediosComunicacion/show/MedioComunicacionShow";
import MedioIngresoShow, {
  Version,
} from "../show/mediosIngreso/show/MedioIngresoShow";
import CheckBoxSelectCliente from "./CheckBoxSelectCliente";

export interface Props {
  onChange?: Function;
  onValidationChange?: Function;
  fuente: Fuente;
}

interface State {
  fuente: Fuente;
  validation: boolean;
  medioIngresoSeleccionado: MedioIngreso | null;
  medioComunicacionSeleccionado: MedioComunicacion | null;
  estadoEmpleado: boolean;
  estadoPropietario: boolean;
  estadoCliente: boolean;
}

export default class FuenteLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fuente: this.props.fuente,
      validation: this.isValid(this.props.fuente),
      medioIngresoSeleccionado: props.fuente.medio_ingreso,
      medioComunicacionSeleccionado: props.fuente.medio_comunicacion,
      estadoEmpleado: props.fuente
        ? props.fuente.empleado
          ? true
          : false
        : false,
      estadoPropietario: props.fuente
        ? props.fuente.propietario
          ? true
          : false
        : false,
      estadoCliente: props.fuente
        ? props.fuente.cliente
          ? true
          : false
        : false,
    };
    this.checkBoxSelectEmpleado = React.createRef();
    this.checkBoxSelectPropietario = React.createRef();
    this.checkBoxSelectCliente = React.createRef();
  }

  checkBoxSelectEmpleado: any;
  checkBoxSelectPropietario: any;
  checkBoxSelectCliente: any;

  componentDidMount = async () => {
    this.updateValidation(this.state.fuente);
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    if (prevProps.fuente !== this.props.fuente) {
      this.setState({ fuente: this.props.fuente });
    }
    if (
      prevState.validation !== this.state.validation &&
      this.props.onValidationChange
    ) {
      this.props.onValidationChange(this.state.validation);
    }
    if (prevState.fuente !== this.state.fuente) {
      this.updateValidation(this.state.fuente);
      if (this.props.onChange) this.props.onChange(this.state.fuente);
    }
  };

  isValid = (fuente: Fuente) => {
    return (
      ((fuente.empleado != null && fuente.empleado.id > -1) ||
        (fuente.cliente != null && fuente.cliente.id > -1) ||
        (fuente.propietario != null && fuente.propietario.id > -1)) &&
      fuente.medio_comunicacion.id > -1 &&
      fuente.medio_ingreso.id > -1
    );
  };

  updateValidation = (fuente: Fuente) => {
    const validation = this.isValid(fuente);
    this.setState({ validation: validation });
  };

  setFecha = (fecha: Date) => {
    this.setState((prevState) => ({
      fuente: { ...prevState.fuente, fecha: fecha },
    }));
  };

  setHora = (hora: Date | null) => {
    const horaInString = getTimeStringFromDate(hora);
    const horaFinal = horaInString ? horaInString : "00:00";
    this.setState((prevState) => ({
      fuente: { ...prevState.fuente, hora: horaFinal },
    }));
  };

  onChangeMedioIngreso = (medio_ingreso: MedioIngreso | null) => {
    this.setState((prevState) => ({
      fuente: {
        ...prevState.fuente,
        medio_ingreso: medio_ingreso ? medio_ingreso : { id: -1, medio: "" },
      },
      medioIngresoSeleccionado: medio_ingreso,
    }));
  };

  onChangeMedioComunicacion = (
    medio_comunicacion: MedioComunicacion | null
  ) => {
    this.setState((prevState) => ({
      fuente: {
        ...prevState.fuente,
        medio_comunicacion: medio_comunicacion
          ? medio_comunicacion
          : { id: -1, medio: "" },
      },
      medioComunicacionSeleccionado: medio_comunicacion,
    }));
  };

  setearEmpleado = (empleado: EmpleadoGet | null) => {
    this.setState((prevState) => ({
      fuente: {
        ...prevState.fuente,
        empleado: empleado ? empleado : undefined,
      },
    }));
  };

  setearEstadoEmpleado = (estado: boolean) => {
    let x = this.state.fuente;
    if (estado) {
      x.propietario = undefined;
      x.cliente = undefined;
      this.setState({
        estadoEmpleado: estado,
        estadoPropietario: false,
        estadoCliente: false,
        fuente: x,
      });
      this.checkBoxSelectPropietario.current.setEstado(false);
      this.checkBoxSelectCliente.current.setEstado(false);
    } else {
      x.empleado = undefined;
      this.setState({ estadoEmpleado: false, fuente: x });
    }
  };

  setearPropietario = (propietario: Propietario | null) => {
    this.setState((prevState) => ({
      fuente: {
        ...prevState.fuente,
        propietario: propietario ? propietario : undefined,
      },
    }));
  };

  setearEstadoPropietario = (estado: boolean) => {
    let x = this.state.fuente;
    if (estado) {
      x.empleado = undefined;
      x.cliente = undefined;
      this.setState({
        estadoEmpleado: false,
        estadoCliente: false,
        estadoPropietario: estado,
        fuente: x,
      });
      this.checkBoxSelectEmpleado.current.setEstado(false);
      this.checkBoxSelectCliente.current.setEstado(false);
    } else {
      x.propietario = undefined;
      this.setState({ estadoPropietario: false, fuente: x });
    }
  };

  setearFuenteCliente = (cliente: Cliente | null) => {
    this.setState((prevState) => ({
      fuente: {
        ...prevState.fuente,
        cliente: cliente ? cliente : undefined,
      },
    }));
  };

  setearEstadoCliente = (estado: boolean) => {
    let x = this.state.fuente;
    if (estado) {
      x.empleado = undefined;
      x.propietario = undefined;
      this.setState({
        estadoEmpleado: false,
        estadoPropietario: false,
        estadoCliente: estado,
        fuente: x,
      });
      this.checkBoxSelectEmpleado.current.setEstado(false);
      this.checkBoxSelectPropietario.current.setEstado(false);
    } else {
      x.cliente = undefined;
      this.setState({ estadoCliente: false, fuente: x });
    }
  };

  render() {
    const { fuente } = this.state;
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        gap="1rem"
      >
        <Flex flexDirection="row" alignItems="center">
          <strong style={{ width: "100%" }}>Fecha</strong>
          <DatePicker
            placeholderText="Ingrese fecha"
            onChange={(fecha: Date) => {
              this.setFecha(fecha);
            }}
            selected={fuente.fecha} //TODO cuando se recibe la fecha llega ya formateada, y no debe matchear para ser Date
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
          />
        </Flex>

        <Flex flexDirection="row" alignItems="center">
          <strong style={{ width: "100%" }}>Hora</strong>
          <DatePicker
            selected={getDateFromTimeString(fuente.hora)}
            onChange={(hora: Time) => this.setHora(hora)}
            showTimeSelect
            showTimeSelectOnly
            isClearable
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="H:mm"
          />
        </Flex>

        <Flex flexDirection="row" alignItems="center">
          <MedioIngresoShow
            version={Version.SELECT}
            propsSelect={{
              callbackParent: this.onChangeMedioIngreso,
              seleccionado: this.state.medioIngresoSeleccionado,
              placeholder: "Seleccione un Medio de Ingreso",
              label: "Medios de Ingreso",
            }}
          />
        </Flex>
        <Flex flexDirection="row" alignItems="center">
          <MedioComunicacionShow
            version={Version.SELECT}
            propsSelect={{
              callbackParent: this.onChangeMedioComunicacion,
              seleccionado: this.state.medioComunicacionSeleccionado,
              placeholder: "Seleccione un Medio de Comunicación",
              label: "Medios de Comunicación",
            }}
          />
        </Flex>

        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          marginTop="10px"
        >
          <Flex
            container
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            width="100%"
          >
            <H size={5} fuente={Fuentes.Titulos} texto="Realizado Por:" />
          </Flex>

          <Form.Group style={{ width: "100%" }}>
            <CheckBoxSelectEmpleado
              ref={this.checkBoxSelectEmpleado}
              estado={this.state.estadoEmpleado}
              empleado={
                this.state.fuente.empleado
                  ? this.state.fuente.empleado.id
                  : null
              }
              onChangeEmpleado={this.setearEmpleado}
              onChangeEstado={this.setearEstadoEmpleado}
            />
          </Form.Group>

          <Form.Group style={{ width: "100%" }}>
            <CheckBoxSelectPropietario
              ref={this.checkBoxSelectPropietario}
              estado={this.state.estadoPropietario}
              propietario={
                this.state.fuente.propietario
                  ? this.state.fuente.propietario.id
                  : null
              }
              onChangePropietario={this.setearPropietario}
              onChangeEstado={this.setearEstadoPropietario}
            />
          </Form.Group>
          <Form.Group style={{ width: "100%" }}>
            <CheckBoxSelectCliente
              ref={this.checkBoxSelectCliente}
              estado={this.state.estadoCliente}
              cliente={
                this.state.fuente.cliente ? this.state.fuente.cliente.id : null
              }
              onChangeCliente={this.setearFuenteCliente}
              onChangeEstado={this.setearEstadoCliente}
            />
          </Form.Group>
        </Flex>
      </Flex>
    );
  }
}
