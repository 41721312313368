import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { OrdenCompraProducto } from "serviciossaintmichel";
import { Fuentes } from "../../../../../../../../compartido/estilos/estilos";
import Flex from "../../../../../../../../libreria/appearance/flex/Flex";
import H from "../../../../../../../../libreria/message/title/H";
import OrdenCompraProductoLoad from "./OrdenCompraProductoLoad";
import OrdenesCompraProductoShow from "./OrdenesCompraProductoShow";

export interface Props {
  ordenCompraId: number;
  ordenesCompraProductos: Array<OrdenCompraProducto>;
  onChange: Function;
  onValidationChange: Function;
}

interface State {
  ordenCompraProducto: OrdenCompraProducto | null;
  ordenCompraProductoValida: boolean;
  ordenesCompraProductos: Array<OrdenCompraProducto>;
  valido: boolean;
  agregandoProducto: boolean;
}

export default class OrdenesCompraProductosLoad extends Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    let ordenes: Array<OrdenCompraProducto> = [];

    if (props.ordenesCompraProductos) {
      ordenes = props.ordenesCompraProductos;
    }

    this.state = {
      ordenCompraProducto: null,
      ordenesCompraProductos: ordenes,
      valido: false,
      agregandoProducto: false,
      ordenCompraProductoValida: false,
    };
  }

  componentDidMount = () => {
    this.validar();
  };

  validar = () => {
    const valido = this.state.ordenesCompraProductos.length > 0;
    this.setState({ valido: valido });
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      JSON.stringify(prevState.ordenesCompraProductos) !==
      JSON.stringify(this.state.ordenesCompraProductos)
    ) {
      this.validar();
      this.props.onChange(this.state.ordenesCompraProductos);
    }

    if (prevState.valido !== this.state.valido) {
      this.props.onValidationChange(this.state.valido);
    }
  };

  agregarOrdenCompraProducto = () => {
    if (this.state.ordenCompraProducto === null) {
      return;
    }

    const newOrdenesCompraProductos = [
      ...this.state.ordenesCompraProductos,
      this.state.ordenCompraProducto,
    ];

    this.setState({
      ordenesCompraProductos: newOrdenesCompraProductos,
      ordenCompraProducto: null,
      ordenCompraProductoValida: false,
      agregandoProducto: false,
    });
  };

  descartarOrdenCompraProducto = (indice: number) => {
    const newOrdenesCompraProductos = this.state.ordenesCompraProductos.filter(
      (_, idx) => idx !== indice
    );
    this.setState({
      ordenesCompraProductos: newOrdenesCompraProductos,
    });
  };

  render = () => {
    const {
      ordenesCompraProductos,
      agregandoProducto,
      ordenCompraProducto,
      ordenCompraProductoValida,
    } = this.state;

    return (
      <>
        <H size={4} texto={"Productos"} fuente={Fuentes.Titulos} />
        {ordenesCompraProductos.length > 0 ? (
          <OrdenesCompraProductoShow
            ordenesCompraProducto={ordenesCompraProductos}
            showDelete={true}
            deleteHandler={(idx: number) => {
              this.descartarOrdenCompraProducto(idx);
            }}
          />
        ) : (
          <span>Sin productos asignados</span>
        )}
        {agregandoProducto ? (
          <Flex
            border="1px solid grey"
            borderRadius="5px"
            padding="1rem"
            marginTop="1rem"
            marginBottom="1rem"
            flexDirection="column"
          >
            <OrdenCompraProductoLoad
              ordenCompraProducto={ordenCompraProducto}
              onChange={(ocp: OrdenCompraProducto) => {
                this.setState({ ordenCompraProducto: ocp });
              }}
              onValidationChange={(valido: boolean) => {
                this.setState({ ordenCompraProductoValida: valido });
              }}
              productosNoValidos={ordenesCompraProductos.map(
                (ocp) => ocp.producto
              )}
            />
            <Flex container justifyContent="space-around">
              <Button
                variant="success"
                onClick={this.agregarOrdenCompraProducto}
                disabled={!ordenCompraProductoValida}
              >
                Agregar
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  this.setState({ agregandoProducto: false });
                }}
              >
                Descartar
              </Button>
            </Flex>
          </Flex>
        ) : (
          <Flex container justifyContent="center" marginTop="1rem">
            <Button
              variant="success"
              onClick={() => {
                this.setState({ agregandoProducto: true });
              }}
            >
              Agregar producto
            </Button>
          </Flex>
        )}
      </>
    );
  };
}
