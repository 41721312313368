import React, { Component } from "react";
import { PropsSelectGeneral } from "../../../../../compartido/components/select/SelectBusqueda";
import { PropsSelectGenerico } from "../../../../../compartido/components/select/PropsSelectGenerico";
import { LIMITES } from "../../../../../compartido/components/select/SelectLimiteTabla";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import PromocionesShowSelect from "./PromocionesShowSelect";
import PromocionesShowTable from "./PromocionesShowTable";
import { cumpleFiltros } from "../../../../../compartido/utils/filtros";
import { CANALES_WEB_SOCKET, Promocion, PromocionService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";



export interface Props {
  onUpdate?: Function;
  onView?: Function;
  version: Version;
  propsSelect?: PropsSelectGenerico<Promocion> & PropsSelectGeneral;
  filtros?: Array<Function>; // lista de filtros sobre los datos (solo sirve para el select)
}

export enum Version {
  SELECT,
  TABLE,
}

interface State {
  promociones: Array<Promocion>;
}

export default class PromocionesShow extends Component<Props, State> {

  private promocionService:PromocionService;

  constructor(props: Props) {
    super(props);
    this.state = {
      promociones: [],
    };

    this.promocionService =  new PromocionService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PROMOCIONES,
      funcionCallback: this.getPromociones,
    });
    if (this.props.version === Version.TABLE) this.promocionService.paramsURL.setearLimite(LIMITES[0]);
    this.getPromociones();
  }

  getPromociones = () => {
    this.promocionService.listAll()
      .then((result) => {
        this.setState({ promociones: result });
      })
      .catch((error) => {
        this.promocionService.manejarErrorHTTP(error, "Promocion");
      });
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  render() {
    const { filtros, propsSelect, version } = this.props;
    const { promociones } = this.state;
    const items = filtros ? promociones.filter((cr) => cumpleFiltros(cr, filtros)) : promociones;

    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {version === Version.SELECT && propsSelect ? (
          <PromocionesShowSelect
            {...propsSelect}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado ? propsSelect.seleccionado : null}
            items={items}
          />
        ) : null}
        {version === Version.TABLE ? (
          <PromocionesShowTable
            onView={this.onView}
            onUpdate={this.onUpdate}
            promociones={this.state.promociones}
            promocionesFuncion={this.getPromociones}
          />
        ) : null}
      </Flex>
    );
  }
}
