import { Item } from "./Item";

export default class CheckBoxListAdapter<T> {
  lista: Array<Item<T>>;

  constructor() {
    this.lista = [];
  }


  isOneSelected = ():boolean => 
  {
    for(var i=0;i<this.lista.length;i++)
  {

    if(this.lista[i].estado)
    {
    return true;
    }

  }
  return false;
  }


  deleteSeleccionados = () => 
  {
  let lista_aux: Array<Item<T>> = [];
  let seleccionados: Array<Item<T>> = this.getSeleccionados();


  for(var i=0;i<this.lista.length;i++)
  {
    let aux:boolean = true;
    for(var j=0;j<seleccionados.length;j++)
    {
      if(this.lista[i].id == seleccionados[j].id)
      {
        aux=false;
        break;
      }
    }

    if(aux)
    {
      lista_aux.push(this.lista[i]);
    }

  }
  this.lista = lista_aux;


  }


  cargar = (lista: Array<Item<T>>) => {
    this.lista = lista;
  };



  agregar = (items: Array<Item<T>>) => {

    for(var i=1;i<items.length;i++)
    {
    items[i].estado=false
    this.lista.push(items[i]);
    }


  };


  renovar = (lista: Array<Item<T>>) => {
    let auxLista: Array<Item<T>> = this.lista;
    this.lista = lista;

    auxLista.forEach((x: Item<T>) => {
      if (x.estado) {
        this.lista.forEach((y: Item<T>) => {
          if (x.equalsTo(y)) y.estado = x.estado;
        });
      }
    });
  };

  getSeleccionados = (): Array<Item<T>> => {
    let seleccionados: Array<Item<T>> = [];
    this.lista.forEach((x: Item<T>) => {
      if (x.estado) seleccionados.push(x);
    });

    return seleccionados;
  };

  getLista = (): Array<Item<T>> => {
    return this.lista;
  };

  itemChange = (item: Item<T>) => {
    this.lista.forEach((x: Item<T>) => {
      if (x.equalsTo(item)) x.copyTo(item);
    });
  };
}
