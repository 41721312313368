import React, { Component } from "react";
import Flex from "../../../../compartido/components/flex/Flex";
import Box from "../../../../compartido/components/box/Box";
import { ListGroup } from "react-bootstrap";

import { CANALES_WEB_SOCKET,  InsumoService, ProductoGet, ProductoPost, ProductoService, TipoProductoGet, TipoProductoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  productoId: number;
  ocultar: Function;
}

interface State {
  producto: ProductoGet;
  anchoBox: string;
}

export default class ProductoVerDetalle extends Component<Props, State> {


  private productoService:ProductoService;


  constructor(props: Props) {
    super(props);

    this.state = {
      producto: this.crearProductoVacio(),
      anchoBox: window.innerWidth < 750 ? "90%" : "50%",
    };
    window.addEventListener("resize", this.actualizarDimensiones);
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS,
      funcionCallback: this.cargarProductoCanal,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_PRODUCTOS,
      funcionCallback: this.cargarProductoCanal,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_INSUMOS,
      funcionCallback: this.cargarProductoCanal,
    });


    this.productoService =  new ProductoService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 750) {
      this.setState({ anchoBox: "90%" });
    } else {
      this.setState({ anchoBox: "50%" });
    }
  };

  componentDidMount() {
    this.cargarProducto(this.props.productoId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarProducto(this.props.productoId);
    }
  }

  crearProductoVacio(): ProductoGet {
    const tipoProducto: TipoProductoGet = {
      id: 0,
      tipo: "",
    };
    const producto: ProductoGet = {
      id: 0,
      nombre: "",
      tipo_producto: tipoProducto,
      volumen: 0,
      insumos: [],
    };
    return producto;
  }

  cargarProductoCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.producto &&
      dato.message.productoId == this.state.producto.id
    ) {
      this.cargarProducto(this.state.producto.id);
    }
    if (
      this.state.producto &&
      dato.message.tipoProductoId == this.state.producto.tipo_producto.id
    ) {
      this.cargarProducto(this.state.producto.id);
    }
    if (
      this.state.producto &&
      this.state.producto.insumos.find(
        (i) => i.insumo.id === dato.message.insumoId
      )
    ) {
      this.cargarProducto(this.state.producto.id);
    }
  };

  cargarProducto = async (id: number) => {
    const resProducto = await this.productoService.get(id).catch((error) => {
      this.productoService.manejarErrorHTTP(error, "Producto");
      return null;
    });
    if (resProducto) {
      this.setState({
        producto: resProducto,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  render() {
    if (this.state.producto.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal={"10rem"} paddingVertical={"2rem"}>
            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h5>Nombre: {this.state.producto.nombre}</h5>
                  <h5>
                    TipoProducto: {this.state.producto.tipo_producto.tipo}
                  </h5>
                  <h5>Volumen: {this.state.producto.volumen}</h5>
                </div>
              </Flex>
            </Flex>

            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>

            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h4>
                    Insumos Asociados
                    {this.state.producto.insumos.length == 0
                      ? " : No tiene"
                      : null}
                  </h4>
                </div>
              </Flex>

              {this.state.producto.insumos.length > 0 ? (
                <Flex
                  container
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  paddingTop="10px"
                >
                  <ListGroup>
                    {this.state.producto.insumos.map((x) => (
                      <ListGroup.Item style={{ textAlign: "center" }}>
                        {x.cantidad.toString() +
                          " [" +
                          x.insumo.unidad_insumo.unidad +
                          "] - " +
                          x.insumo.nombre +
                          " - " +
                          x.insumo.tipo_insumo.tipo}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Flex>
              ) : null}
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <button
                className={"btn btn-outline-secondary"}
                onClick={this.ocultar}
                style={{ width: "150px" }}
              >
                Salir
              </button>
            </Flex>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
