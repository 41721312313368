import InputBase from "./InputBase";

export default class InputCaracteres extends InputBase {
  esValida(cadena) {
    debugger;
    const expresionRegular = new RegExp("^[A-Za-z\\u0020]+$", "gi");
    return expresionRegular.test(cadena) || cadena === "";
  }
  render() {
    return super.render();
  }
}
