import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Margin from "../../../../libreria/appearance/margin/Margin";
import Screen, { ScreenType } from "../../../../compartido/models/Screen";
import H from "../../../../libreria/message/title/H";
import DatosRecorridoUpdate from "./datos/DatosRecorridoUpdate";
import { Box } from "../../../../libreria/containers/box/Box";
import Flex from "../../../../libreria/appearance/flex/Flex";
import { Tab, Tabs } from "react-bootstrap";
import DireccionesRecorridoSeccion from "./direcciones/DireccionesRecorridoSeccion";

import { CANALES_WEB_SOCKET,   Recorrido, RecorridoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";

export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  idRecorrido: number;
  recienCreado: boolean;
}

interface State {
  recorrido: Recorrido | null;
  cargando: boolean;
  screen: ScreenType;
}

export default class RecorridoUpdate extends Component<Props, State> {

  private recorridoService:RecorridoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      recorrido: null,
      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.recorridoService =  new RecorridoService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  async componentDidMount() {
    await this.cargarRecorrido();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS,
      funcionCallback: this.onChannelMesagge,
    });
  }

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.recorridoId == this.props.idRecorrido) {
      this.cargarRecorrido();
    }
  };

  cargarRecorrido = async () => {
    let recorrido = await this.recorridoService.get(this.props.idRecorrido).catch(
      (error) => {
        this.recorridoService.manejarErrorHTTP(error, "Recorrido");
        return null;
      }
    );
    if (recorrido) {
      this.setState({ recorrido: recorrido });
    } else this.setState({ recorrido: recorrido, cargando: false });
  };

  renderizar = () =>
    this.state.recorrido != null ? (
      <Margin top="20px">
        <Box padding="15px">
          <H
            alineacion={"center"}
            size={3}
            texto={this.state.recorrido.nombre}
          />
          <Margin top="20px" />
          <Tabs
            defaultActiveKey={this.props.recienCreado ? "direcciones" : "datos"}
            transition={false}
            variant={this.state.screen == ScreenType.PHONE ? "pills" : "tabs"}
            style={{
              marginBottom: "1rem",
              flexDirection:
                this.state.screen == ScreenType.PHONE ? "column" : "row",
              alignItems: this.state.screen == ScreenType.PHONE ? "center" : "",
            }}
            unmountOnExit={true}
          >
            <Tab eventKey="datos" title="Datos">
              {<DatosRecorridoUpdate datosRecorrido={this.state.recorrido} />}
            </Tab>
            <Tab eventKey="direcciones" title="Direcciones">
              {
                <DireccionesRecorridoSeccion
                  idRecorrido={this.props.idRecorrido}
                />
              }
            </Tab>
          </Tabs>
          <Margin top="20px" />
        </Box>
      </Margin>
    ) : null;

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <div style={{ display: "block", width: "100%" }}>
          {this.renderizar()}
        </div>
      </Flex>
    );
  }
}
