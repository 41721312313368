import { DirectionsService } from "@react-google-maps/api";
import React, { Component } from "react";
import Contenedor from "../compartido/components/contenedor/Contenedor";
import { Fondos } from "../compartido/components/contenedor/Fondos";
import Flex from "../compartido/components/flex/Flex";
import TestOrganizacionRecorrido from "../distribucion/recorridos/components/organizacion/TestOrganizacionRecorrido";
import TestCheckBox from "../libreria/data/checkbox/TestCheckBox";
import TestCheckBoxList from "../libreria/data/checkboxlist/TestCheckBoxList";
import TestInput from "../libreria/data/input/TestInput";
import TestListBox from "../libreria/data/listbox/TestListBox";
import TestSelect from "../libreria/data/select/TestSelect";
import Mapa from "../mapas/mapas/components/Mapa";
import Text from "../libreria/message/text/Text";
import { Border } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import Input, { InputTypes } from "../libreria/data/input/Input";
import Habilitador from "../libreria/action/habilitador/Habilitador";
import TestLocationsMap from "../mapas/libreria/locationsmap/TestLocationsMap";
import TestMenu from "../template/Menu/TestMenu";

// modifique

//     "typescript": "^3.8"
// "react-bootstrap": "^1.3.0",
//     "bootstrap": "^4.5.2",

// npm install react-bootstrap bootstrap
//   npm install @types/react-bootstrap
//  npm install --save bootstrap

export interface Props {}
export interface State {
  show: boolean;
  activeKey: any;
  latitude: any;
  longitude: any;
}

export default class PruebasVisuales extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeKey: "home",
      show: false,
      latitude: null,
      longitude: null,
    };
  }

  componentDidMount() {
    /*
    let latitude:any;
    let longitude:any;
    navigator.geolocation.getCurrentPosition(function(position) {
      latitude=position.coords.latitude;
      longitude=position.coords.longitude;

      alert(position.coords.latitude);

    });

    this.setState({latitude:latitude,longitude:longitude});
    */
  }

  onClick = async () => {
    let latitude: any;
    let longitude: any;
    debugger;
    try {
      await navigator.geolocation.getCurrentPosition(function (position) {
        debugger;
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;

        alert(position.coords.latitude);
      });
    } catch (e) {
      alert(e);
    }

    this.setState({ latitude: latitude, longitude: longitude });
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        //borde="1px solid black"
      >
        <br />
        <br />
        <br />
        <br />

        {/* <TestOrganizacionRecorrido /> */}

        {/* <TestListBox /> */}

        {/* <TestCheckBox/> */}

        {/* <TestSelect/> */}

        {/* <TestCheckBoxList/> */}

        {/* <TestInput/> */}

        {/*<TestInput/>*/}

        {/*<TestListBox/>*/}

        {/*<TestCheckBoxList/>*/}

        {/* <TestInput/> */}

        {/* <Mapa /> */}

        {/* <TestLocationsMap /> */} 

        {/*

         <TestListBox/>

          <Text> Latitude:{this.state.latitude}</Text>
          <Text> Longitude:{this.state.longitude}</Text>

          <Button onClick={this.onClick} > Posicion</Button>
*/}

        <TestMenu />






      </Flex>
    );
  }
}
