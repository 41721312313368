import React, { Component } from "react";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../libreria/appearance/margin/Margin";
import { Box } from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import DatosPromocionUpdate from "./datos/DatosPromocionUpdate";
import ProductosPromocionUpdate from "./productos/ProductosPromocionUpdate";

import { CANALES_WEB_SOCKET,  ProductoPromocion,  Promocion,  PromocionService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";


export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  idPromocion: number;
  recienCreado: boolean;
}

interface State {
  promocion: Promocion | null;
  cargando: boolean;
  screen: ScreenType;
}

export default class PromocionUpdate extends Component<Props, State> {

  private promocionService:PromocionService;

  constructor(props: Props) {
    super(props);
    this.state = {
      promocion: null,
      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.promocionService =  new PromocionService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  async componentDidMount() {
    await this.cargarPromocion();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PROMOCIONES,
      funcionCallback: this.onChannelMesagge,
    });
  }

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.promocionId == this.props.idPromocion) {
      this.cargarPromocion();
    }
  };

  cargarPromocion = async () => {
    let promocion = await this.promocionService.get(this.props.idPromocion).catch(
      (error) => {
        this.promocionService.manejarErrorHTTP(error, "Promocion");
        return null;
      }
    );
    if (promocion) {
      this.setState({ promocion: promocion });
    } else this.setState({ promocion: promocion, cargando: false });
  };

  renderizar = () =>
    this.state.promocion != null ? (
      <Margin top="20px">
        <Box padding="15px">
          <H
            alineacion={"center"}
            size={3}
            texto={this.state.promocion.nombre}
          />
          <Margin top="20px" />
          <Tabs
            defaultActiveKey={this.props.recienCreado ? "productos" : "datos"}
            transition={false}
            variant={this.state.screen == ScreenType.PHONE ? "pills" : "tabs"}
            style={{
              marginBottom: "1rem",
              flexDirection:
                this.state.screen == ScreenType.PHONE ? "column" : "row",
              alignItems: this.state.screen == ScreenType.PHONE ? "center" : "",
            }}
            unmountOnExit={true}
          >
            <Tab eventKey="datos" title="Datos">
              {<DatosPromocionUpdate datosPromocion={this.state.promocion} />}
            </Tab>
            <Tab eventKey="productos" title="Productos">
              {
                <ProductosPromocionUpdate
                  idPromocion={this.state.promocion.id}
                />
              }
            </Tab>
          </Tabs>
          <Margin top="20px" />
        </Box>
      </Margin>
    ) : null;

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <div style={{ display: "block", width: "100%" }}>
          {this.renderizar()}
        </div>
      </Flex>
    );
  }
}
