import React, { Component } from "react";
import { Button, Image } from "react-bootstrap";
import { PagoService } from "serviciossaintmichel";
import { API_URL } from "serviciossaintmichel";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../libreria/appearance/margin/Margin";
import { Global } from "../../../../../Global";

export interface Props {
  idPago: number;
  comprobante: string | null;
}

interface State {}

export default class ComprobanteShow extends Component<Props, State> {
  private pagoService: PagoService;

  constructor(props: Props) {
    super(props);
    this.state = {};
    console.log(props);

    this.pagoService = new PagoService(Global.UsuarioService.getToken()!);
  }

  descargarArchivo = () => {
    if (this.props.comprobante) {
      this.pagoService.descargarArchivo(this.props.idPago, this.props.comprobante, "comprobante").catch((error) => {
        this.pagoService.manejarErrorHTTP(error, "Comprobante Pago");
      });
    }
  };

  renderComprobante = () => (
    <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="10px">
      {this.props.comprobante ? (
        <Flex container flexDirection="column" alignItems="center">
          {!this.props.comprobante.endsWith(".pdf") ? (
            <Image src={API_URL + this.props.comprobante} fluid />
          ) : (
            <div>
              <p>
                Abrir pdf en <a href={API_URL + this.props.comprobante}>nueva pestaña</a>.
              </p>
              <iframe src={API_URL + this.props.comprobante + "#toolbar=0"} width="100%" height="500px"></iframe>
            </div>
          )}
          <Button variant="success" style={{ marginTop: "0.5rem" }} onClick={this.descargarArchivo}>
            Descargar
          </Button>
        </Flex>
      ) : (
        <>No tiene</>
      )}
      <Margin top="30px" />
    </Flex>
  );

  render() {
    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Margin top="20px" />
        {this.renderComprobante()}
      </Flex>
    );
  }
}
