import React, { Component } from "react";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import RecorridoLoad from "../../load/RecorridoLoad";


import { CANALES_WEB_SOCKET, DatosRecorrido, Recorrido, RecorridoService,  WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";





export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  datosRecorrido: DatosRecorrido;
}

interface State {
  datosRecorridoNew: DatosRecorrido;
  datosRecorridoOld: DatosRecorrido;
  screen: ScreenType;
  valid: boolean;
}

export default class DatosRecorridoUpdate extends Component<Props, State> {

  private recorridoService:RecorridoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      datosRecorridoOld: this.props.datosRecorrido,
      datosRecorridoNew: this.getCopy(this.props.datosRecorrido),
      screen: Screen.getType(window.innerWidth, window.innerHeight),
      valid: false,
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.recorridoService =  new RecorridoService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  getCopy = (datos: DatosRecorrido): DatosRecorrido => {
    return { id: datos.id, nombre: datos.nombre };
  };

  async componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS,
      funcionCallback: this.onChannelMesagge,
    });
  }

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.recorridoId == this.props.datosRecorrido.id) {
      let recorrido = await this.recorridoService.get(
        this.props.datosRecorrido.id
      ).catch((error) => {
        this.recorridoService.manejarErrorHTTP(error, "Recorrido");
        return null;
      });
      let datos: DatosRecorrido = {
        id: recorrido ? recorrido.id : -1,
        nombre: recorrido ? recorrido.nombre : "",
      };
      this.setState({
        datosRecorridoOld: datos,
        datosRecorridoNew: this.getCopy(datos),
      });
    }
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({
        datosRecorridoOld: this.props.datosRecorrido,
        datosRecorridoNew: this.getCopy(this.props.datosRecorrido),
      });
    }
  };

  onUpdate = async () => {
    let datosRecorridoNew = {
      nombre: this.state.datosRecorridoNew.nombre,
    };
    return this.recorridoService.update(
      datosRecorridoNew,
      this.state.datosRecorridoNew.id
    )
      .then((recorrido) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS,
          { type: "UPDATE", recorridoId: recorrido.id }
        );
        this.setState({
          datosRecorridoOld: this.state.datosRecorridoNew,
          datosRecorridoNew: this.getCopy(this.state.datosRecorridoNew),
        });
        Swal.fire({
          icon: "success",
          text: "Datos Recorrido Modificados",
        });
      })
      .catch((error) => {
        this.recorridoService.manejarErrorHTTP(error, "Recorrido");
      });
  };

  onChange = (datosRecorrido: DatosRecorrido) => {
    this.setState({ datosRecorridoNew: datosRecorrido });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex
      flexDirection="column"
      width="50%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={3} texto={"Datos"} />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="10px"
        >
          <RecorridoLoad
            recorrido={this.state.datosRecorridoNew}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
          />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Actualizar"}
            onClick={this.onUpdate}
          />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen == ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen == ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen == ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
