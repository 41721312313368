import React, { Component } from "react";
import Flex from "../flex/Flex";
import {
  GoogleMap,
  LoadScript,
  Marker,
} from "@react-google-maps/api"; /* global google */ /* eslint-disable no-undef */
import { AdaptadorGisGeosGoogleMap, Localizacion } from "serviciossaintmichel";

interface Props {
  localizacion: Localizacion;
  onMapLoad?: Function;
}

interface State {}

export default class LocalizacionShow extends Component<Props, State> {
  render() {
    const { localizacion } = this.props;

    return (
      <Flex container flexDirection="column" width="100%">
        <Flex container flexDirection="column" height="400px" width="100%">
          <LoadScript
            id="script-loader"
            googleMapsApiKey="AIzaSyDG1j7AQa8_IW7_cxmRTH0kMsZdLf3akpg"
            libraries={["drawing", "geometry"]}
          >
            <GoogleMap
              id="google-map"
              mapContainerStyle={{
                height: "100%",
                width: "100%",
                margin: "auto",
              }}
              zoom={12}
              center={new AdaptadorGisGeosGoogleMap().toGMLatLng(localizacion)}
              onLoad={(map: google.maps.Map) => {
                if (this.props.onMapLoad) {
                  this.props.onMapLoad(map);
                }
              }}
            >
              <Marker position={new AdaptadorGisGeosGoogleMap().toGMLatLng(localizacion)} />
            </GoogleMap>
          </LoadScript>
        </Flex>
      </Flex>
    );
  }
}
