import { Select } from "@mantine/core";
import _ from "lodash";
import React from "react";
import {
  Cliente,
  DireccionDTO,
  VisitaLectura,
  direccionVacia,
  localidadVacia,
  Direccion,
  PrioridadVisita,
} from "serviciossaintmichel";
import ClientesShow, {
  VersionClientesShow,
} from "../../../../administracion/seccion-clientes/clientes/components/ClientesShow";
import { excluirIds } from "../../../../compartido/utils/filtros";
import InputText from "../../../../libreria/data/input/text/InputText";
import DireccionesClienteShow, {
  Version as VersionDireccionesClienteShow,
} from "../../../recorridos/components/update/direcciones/DireccionesClienteShow";

export interface Props {
  visita: VisitaLectura;
  ocultarCampos?: Array<string>;
  exluirDirecciones?: Array<number>;
  onChange?: Function;
}

interface State {
  clienteSeleccionado: Cliente | null;
  direccionSeleccionada: DireccionDTO | null;
  visitaLoad: VisitaLectura;
}

export default class VisitaLoad extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      clienteSeleccionado: null,
      direccionSeleccionada: null,
      visitaLoad: props.visita,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.props.onChange && !_.isEqual(prevState.visitaLoad, this.state.visitaLoad)) {
      this.props.onChange(this.state.visitaLoad);
    }

    if (!_.isEqual(prevProps.visita, this.props.visita)) {
      this.setState({ visitaLoad: this.props.visita });
    }
  }

  setClienteSeleccionado = (cliente: Cliente | null) => {
    this.setState({
      clienteSeleccionado: cliente,
    });
    this.setDireccionSeleccionada(null);
  };

  setDireccionSeleccionada = (dir: DireccionDTO | null) => {
    let direccion: Direccion = this.state.visitaLoad.direccion;

    direccion =
      dir !== null
        ? {
            id: dir.id,
            calle: dir.calle,
            entre_1: dir.entre_1,
            entre_2: dir.entre_2,
            numero: dir.numero,
            departamento: dir.departamento,
            piso: dir.piso,
            localizacion: dir.localizacion,
            zonas: [],
            tipo_lugar: dir.tipo_lugar,
            descripcion: dir.descripcion,
            localidad: localidadVacia(),
          }
        : direccionVacia();

    this.setState((prevState) => ({
      direccionSeleccionada: dir,
      visitaLoad: { ...prevState.visitaLoad, direccion: direccion },
    }));
  };

  setObservacion = (observacion: string) => {
    this.setState((prevState) => ({
      visitaLoad: { ...prevState.visitaLoad, observacion: observacion },
    }));
  };

  setPrioridad = (prioridad: PrioridadVisita) => {
    this.setState((prevState) => ({
      visitaLoad: { ...prevState.visitaLoad, prioridad: prioridad },
    }));
  };

  render() {
    const { clienteSeleccionado, direccionSeleccionada, visitaLoad } = this.state;
    const { exluirDirecciones = [], ocultarCampos = [] } = this.props;

    return (
      <div>
        {ocultarCampos.findIndex((campo) => "direccion") === -1 ? (
          <ClientesShow
            version={VersionClientesShow.SELECT}
            propsSelect={{
              callbackParent: this.setClienteSeleccionado,
              seleccionado: clienteSeleccionado,
              placeholder: "Seleccione un cliente",
              label: "Cliente",
            }}
          />
        ) : null}
        {clienteSeleccionado ? (
          <DireccionesClienteShow
            version={VersionDireccionesClienteShow.SELECT}
            propsSelect={{
              callbackParent: this.setDireccionSeleccionada,
              seleccionado: direccionSeleccionada,
              placeholder: "Seleccione una dirección",
              label: "Dirección",
            }}
            idCliente={clienteSeleccionado.id}
            filtros={[excluirIds(exluirDirecciones)]}
          />
        ) : null}
        <div>Prioridad de la visita</div>
        <Select
          placeholder="Seleccione prioridad"
          value={visitaLoad.prioridad}
          data={[
            { value: PrioridadVisita.BAJA, label: PrioridadVisita.BAJA },
            { value: PrioridadVisita.MEDIA, label: PrioridadVisita.MEDIA },
            { value: PrioridadVisita.ALTA, label: PrioridadVisita.ALTA },
            { value: PrioridadVisita.URGENTE, label: PrioridadVisita.URGENTE },
          ]}
          onChange={this.setPrioridad}
        />
        <div>Observación</div>
        <InputText onChange={this.setObservacion} data={visitaLoad.observacion} />
      </div>
    );
  }
}
