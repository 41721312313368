import React, { Component } from "react";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";
import "react-datepicker/dist/react-datepicker.css";
import Screen, { ScreenType } from "../../../../compartido/models/Screen";
import Flex from "../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../libreria/containers/box/Box";
import Swal from "sweetalert2";
import RepartoDiarioLoad from "../load/RepartoDiarioLoad";
import FechaUtils from "../../../../compartido/utils/FechaUtils";
import { CANALES_WEB_SOCKET, RepartoDiario, RepartoDiarioService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  onUpdateSuccess: Function;
  repartoDiarioId: number;
}

interface State {
  repartoDiario: RepartoDiario | null;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class RepartoDiarioUpdate extends Component<Props, State> {
  private repartoDiarioService:RepartoDiarioService;

  constructor(props: Props) {
    super(props);
    this.state = {
      repartoDiario: null,
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    this.repartoDiarioService =  new RepartoDiarioService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = () => {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
      funcionCallback: this.refrescarRepartoDiario,
    });
    window.addEventListener("resize", this.actualizarDimensiones);
    this.refrescarRepartoDiario();
  };

  refrescarRepartoDiario = async () => {
    const repartoDiario = await this.repartoDiarioService.get(this.props.repartoDiarioId);
    repartoDiario.fecha_realizacion = repartoDiario.fecha_realizacion
      ? FechaUtils.normalizarFecha(repartoDiario.fecha_realizacion)
      : null;

    this.setState({ repartoDiario: { ...repartoDiario, reparto: repartoDiario.reparto.id } });
  };

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  onUpdate = async (repartoDiario: RepartoDiario) => {
    try {
      await this.repartoDiarioService.update(repartoDiario, repartoDiario.id);
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS, {
        type: "UPDATE",
        idRepartoDiario: repartoDiario.id,
      });
      Swal.fire({
        icon: "success",
        text: "Reparto diario actualizado",
      });
      this.props.onUpdateSuccess(repartoDiario.id);
    } catch (error) {
      this.repartoDiarioService.manejarErrorHTTP(error, "Reparto diario");
    }
  };

  onChange = (repartoDiario: RepartoDiario) => {
    this.setState({ repartoDiario: repartoDiario });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex flexDirection="column" width="60%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex flexDirection="column" width="50%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizar = () => {
    const { repartoDiario, valid } = this.state;

    if (repartoDiario === null) {
      return null;
    }

    return (
      <Box padding="20px">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
            <RepartoDiarioLoad
              repartoDiario={repartoDiario}
              onChange={this.onChange}
              onValidationChange={this.onValidationChange}
              inputsSoloLectura={["fechaRealizacion", "reparto"]}
            />
          </Flex>
          <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
            <RequestButton
              propsBoton={{ disabled: !valid }}
              texto={"Actualizar"}
              onClick={() => this.onUpdate(repartoDiario)}
            />
          </Flex>
        </Flex>
      </Box>
    );
  };

  render() {
    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.screen === ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen === ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen === ScreenType.TABLET ? this.renderizarTablet() : null}
      </Flex>
    );
  }
}
