import React from "react";
import { Button } from "react-bootstrap";
import Flex from "../flex/Flex";
import * as Icon from 'react-bootstrap-icons';

export interface Props {}

interface State {
  habilitado: boolean;
}

export default class Habilitador extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      habilitado: false,
    };
  }


  disabled = () => 
  {
    this.setState({habilitado:false});

  }

  render() {
    return (
      <Flex container={true} justifyContent={"center"} alignItems={"center"} flexWrap={"nowrap"}>
        {React.cloneElement(this.props.children as React.ReactElement<any>, {
          disabled: !this.state.habilitado,
        })}
        
        <Button
        variant="light" 
          onClick={() => {
            this.setState({ habilitado: !this.state.habilitado });
            console.log("ESTADO", !this.state.habilitado);
          }}
        >
        <Icon.Pencil />
        </Button>
   

       



      </Flex>
    );
  }
}
