import React, { Component } from "react";
import { TipoLugar } from "serviciossaintmichel";
import SelectGenericoNull from "../select/SelectGenericoNull";

interface Props {
  onChange: Function;
  seleccionado: TipoLugar | null;
  tiposLugar: Array<TipoLugar>;
}
interface State {
  seleccionado: TipoLugar | null;
}

export default class SelectTipoLugar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado,
    };
  }

  getPosicion = (valor: TipoLugar, tiposLugar: Array<TipoLugar>): number => {
    return tiposLugar.findIndex((i) => i.id === valor.id);
  };

  getSeleccionado = (indice: number) => {
    const { tiposLugar } = this.props;
    if (indice >= 0) this.props.onChange(tiposLugar[indice]);
    else this.props.onChange(null);
  };

  render() {
    const { tiposLugar, seleccionado } = this.props;
    const elementoSeleccionado = seleccionado ? this.getPosicion(seleccionado, tiposLugar) : null;

    return (
      <SelectGenericoNull
        seleccionado={elementoSeleccionado}
        elementos={tiposLugar.map((i) => i.tipo)}
        callback={this.getSeleccionado}
      />
    );
  }
}
