import React from "react";
import { ClienteService, DireccionDTO, MaquinaServicioFisicaGet, MaquinaServicioFisicaService, ServicioCliente } from "serviciossaintmichel";
import { Fuentes } from "../../../../../../compartido/estilos/estilos";

import { Global } from "../../../../../../Global";
import H from "../../../../../../libreria/message/title/H";
import MaquinaClienteUpdate from "./MaquinaClienteUpdate";
import MaquinasClienteShow, { Version } from "./MaquinasClienteShow";


interface Props {
  clienteId: number;
}

interface State {
  maquinasFisicas: Array<MaquinaServicioFisicaGet> | null;
  servicioActual: ServicioCliente | null;
  direcciones: Array<DireccionDTO> | null;
}

export default class MaquinaClienteActualizar extends React.Component<Props, State> {

  private clienteService:ClienteService;
  private maquinaServicioFisicaService:MaquinaServicioFisicaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      servicioActual: null,
      maquinasFisicas: null,
      direcciones: null,
    };

    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);
    this.maquinaServicioFisicaService = new MaquinaServicioFisicaService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    const { clienteId } = this.props;

    try {
      const direccionesCliente = await this.clienteService.getDirecciones(clienteId);
      const direccionesId = direccionesCliente.map((dirCliente) => dirCliente.id);
      const maquinasEnPosesion = await this.maquinaServicioFisicaService.getMaquinasEnDirecciones(direccionesId);

      const serviciosActivos = await this.clienteService.getServiciosActivos(clienteId);
      const servicioActual = serviciosActivos.actual;

      this.setState({
        servicioActual: servicioActual,
        maquinasFisicas: maquinasEnPosesion,
        direcciones: direccionesCliente,
      });
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error, "ServiciosActivos");
    }
  }

  desvinvularMaquinaDireccion = (maquina: MaquinaServicioFisicaGet) => {
    const payload = {
      ...maquina,
      proveedor: maquina.proveedor.id,
      maquina_simbolica: maquina.maquina_simbolica.id,
      direccion: null,
    };

    try {
      this.maquinaServicioFisicaService.update(payload, maquina.id);
    } catch (error) {
      this.maquinaServicioFisicaService.manejarErrorHTTP(error);
    }

    PubSub.publish("MAQUINAS_CLIENTE", { cliente_id: this.props.clienteId });
  };

  getTipoTitulo(tipoServicio: string) {
    switch (tipoServicio) {
      case "orden_compra":
        return "Orden de compra";
      case "alquiler":
        return "Alquiler";
      case "consumo":
        return "Consumo";
      default:
        return "Desconocido";
    }
  }

  render = () => {
    const { servicioActual, maquinasFisicas, direcciones } = this.state;
    const { clienteId } = this.props;

    if (direcciones === null || maquinasFisicas === null) {
      return <div>Cargando...</div>;
    }

    if (servicioActual === null) {
      return <div>No posee servicio actualmente</div>;
    }

    const tipoServicio = this.getTipoTitulo(servicioActual.tipo);

    const titulo = `Servicio actual: ${tipoServicio}`;

    return (
      <div>
        <H size={4} fuente={Fuentes.Titulos} texto={titulo} />
        <H size={5} fuente={Fuentes.Titulos} texto="Asignación de máquinas pendientes" />
        <MaquinaClienteUpdate
          clienteId={clienteId}
          direccionesCliente={direcciones}
          maquinasCliente={maquinasFisicas}
          servicio={servicioActual}
        />
        <H size={5} fuente={Fuentes.Titulos} texto="Máquinas en posesión" />
        <MaquinasClienteShow
          clienteId={clienteId}
          version={Version.TABLE}
          onDelete={this.desvinvularMaquinaDireccion}
        />
      </div>
    );
  };
}
