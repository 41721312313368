import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";
import { CANALES_WEB_SOCKET, TipoProductoGet, TipoProductoPost, TipoProductoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";





export interface Props {
  ocultar: Function;
  tipoProductoId: number;
}

interface State {
  tipoProducto: TipoProductoGet;
}

export default class TipoProductoActualizar extends Component<Props, State> {

  private tipoProductoService:TipoProductoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      tipoProducto: this.crearTipoProductoVacio(),
    };

    this.tipoProductoService =  new TipoProductoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.cargarTipoProducto(this.props.tipoProductoId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarTipoProducto(this.props.tipoProductoId);
    }
  }

  crearTipoProductoVacio(): TipoProductoGet {
    const tipoProducto: TipoProductoGet = {
      id: 0,
      tipo: "",
    };
    return tipoProducto;
  }

  cargarTipoProducto = async (id: number) => {
    const resTipoProducto = await this.tipoProductoService.get(id).catch((error) => {
      this.tipoProductoService.manejarErrorHTTP(error, "Tipo Producto");
      return null;
    });
    if (resTipoProducto != null) {
      this.setState({
        tipoProducto: resTipoProducto,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  setearTipo = (tipo: string) => {
    let tipoProd = this.state.tipoProducto;
    tipoProd.tipo = tipo;
    this.setState({ tipoProducto: tipoProd });
  };

  handleUpdate() {
    const tipoProductoActualizar: TipoProductoPost = {
      tipo: this.state.tipoProducto.tipo,
    };
    return this.tipoProductoService.update(
      tipoProductoActualizar,
      this.state.tipoProducto.id
    )
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_TIPO_PRODUCTOS,
          { tipoProductoId: this.state.tipoProducto.id }
        );
      })
      .catch((error) => {
        this.tipoProductoService.manejarErrorHTTP(error, "Tipo Producto");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.tipoProducto.tipo ? false : true;
  };

  render() {
    if (this.state.tipoProducto.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            <div className={"row"}>
              <div className={"column"}>
                <form
                  className={"form-horizontal"}
                  onSubmit={this.handleSubmit}
                >
                  <div className={"form-group"}>
                    <label htmlFor="tipo">Nombre de Tipo Producto</label>
                    <InputCaracteres
                      id="tipo"
                      name="tipo"
                      onChange={this.setearTipo}
                      value={this.state.tipoProducto.tipo}
                      placeholder="Ingrese Nombre de TipoProducto"
                    />
                  </div>

                  <div
                    className={"form-group"}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <RequestButton
                      texto="Actualizar"
                      msgOnSuccess="Tipo de producto actualizado con exito"
                      msgOnFailure="Ocurrio un error al actualizar el tipo de producto"
                      propsBoton={{
                        variant: "info",
                        disabled: this.disableButton(),
                      }}
                      onClick={() => this.handleUpdate()}
                    />
                    <button
                  type={"reset"}
                  className={"btn btn-outline-secondary"}
                      onClick={this.ocultar}
                    >
                      Ocultar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
