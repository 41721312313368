import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Font } from "../../models/models";

interface Props {
  data?: string;
  onChange: Function;
  fontFamily?: string;
  label?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
}

interface State {
  data: string;
  fontFamily: string;
  error: string;
  disabled: boolean;
}

class InputText extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let data: string = this.props.data ? this.props.data : "";
    this.state = {
      data: data,
      disabled: this.props.disabled ? this.props.disabled : false,

      error: this.props.error ? this.props.error : "",
      fontFamily: this.props.fontFamily ? this.props.fontFamily : Font.family,
    };
  }

  componentDidMount() {}

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps != this.props) {
      /*       debugger;
       */
      let data: string = this.props.data ? this.props.data : "";
      let error: string = this.props.error ? this.props.error : "";

      let disabled: boolean = this.props.disabled ? this.props.disabled : false;
      /*if(prevProps.disabled!=this.props.disabled)
        disabled = this.props.disabled? this.props.disabled:false;
      */

      this.setState({ data: data, error: error, disabled: disabled });
    }

    /*
    if (prevProps.data !=  this.props.data) {
      let data:string = this.props.data ? this.props.data:"";
      this.setState({ data: data });
    }
    if (prevProps.error != undefined && this.props.error == undefined) {
      this.setState({ error: "" });
    }
    else if (prevProps.error == undefined && this.props.error != undefined) {
      this.setState({ error: this.props.error });
    }
    else if (prevProps.error != undefined && this.props.error != undefined) {
      if (prevProps.error != this.props.error)
        this.setState({ error: this.props.error })
    }
    else { }
*/
  };

  onChange = (data: any) => {
    let x: string = data.target.value;
    this.setState({ data: x });
    this.props.onChange(x);
  };

  onClick = () => {
    this.setState({ disabled: false });
  };

  render() {
    return (
      <Form.Group /* onClick={this.onClick} */ style={{ width: "100%" }}>
        {this.props.label ? <Form.Label>{this.props.label}</Form.Label> : null}
        <Form.Control
          disabled={this.state.disabled}
          style={{ fontFamily: this.state.fontFamily }}
          type="text"
          value={this.state.data}
          placeholder={this.props.placeholder ? this.props.placeholder : ""}
          onChange={this.onChange}
        />
        {this.state.error != "" ? (
          <Form.Control.Feedback type="invalid">
            {this.state.error}
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>
    );
  }
}

export default InputText;
