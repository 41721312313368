import React from "react";
import { Table } from "react-bootstrap";
import { DireccionDTO, MaquinaServicioFisicaGet } from "serviciossaintmichel";
import RequestButton from "../../../../../../libreria/action/request-button/RequestButton";




interface Props {
  maquinasFisicas: Array<MaquinaServicioFisicaGet>;
  direcciones: Array<DireccionDTO>;
  onDelete?: Function;
}

interface State {}

/**
 * Muestra las máquinas que posee un cliente en formato de tabla
 */
export default class TablaMaquinasCliente extends React.Component<Props, State> {
  private direccionesMap: Map<number, DireccionDTO>;

  constructor(props: Props) {
    super(props);
    this.direccionesMap = new Map();
    props.direcciones.forEach((dir) => this.direccionesMap.set(dir.id, dir));
  }

  getInfoDireccion = (direccionId: number | null): string => {
    if (direccionId === null) {
      return "Sin dirección";
    }

    const direccion = this.direccionesMap.get(direccionId);

    return direccion ? `${direccion.calle} N ${direccion.numero}` : "No encontrada";
  };

  render = () => {
    const { maquinasFisicas, onDelete } = this.props;

    if (maquinasFisicas.length < 1) {
      return <div>Sin máquinas...</div>;
    }

    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th className="text-center">Id</th>
            <th className="text-center">Marca</th>
            <th className="text-center">Número de serie</th>
            <th className="text-center">Tipo</th>
            <th className="text-center">Dirección</th>
            <th className="text-center"></th>
          </tr>
        </thead>
        <tbody>
          {maquinasFisicas.map((maquina) => (
            <tr>
              <td className="text-center">{maquina.id}</td>
              <td className="text-center">{maquina.marca}</td>
              <td className="text-center">{maquina.numero_serie}</td>
              <td className="text-center">{maquina.maquina_simbolica.nombre}</td>
              <td className="text-center">{this.getInfoDireccion(maquina.direccion)}</td>
              <td className="text-center">
                {onDelete && (
                  <RequestButton
                    texto="Liberar"
                    confirmacion
                    propsBoton={{
                      variant: "danger",
                    }}
                    onClick={() => onDelete(maquina)}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };
}
