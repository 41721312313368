import React, { Component, FormEvent } from "react";
import { Col, Form } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

import Flex from "../../../libreria/appearance/flex/Flex";
import RequestButton from "../../../libreria/action/request-button/RequestButton";
import { FiltrosPagos } from "serviciossaintmichel/lib/apisaintmichel/ventas/pagos/pagos/services/PagoService";
import DatePicker from "react-datepicker";
import TipoPagosShow, { Version } from "../../../ventas/seccion-pagos/pagos/components/show/TipoPagosShow";
import { TipoPago } from "serviciossaintmichel";
import { Select } from "@mantine/core";
import { FuentePago } from "serviciossaintmichel/lib/apisaintmichel/ventas/pagos/pagos/models/Pago";

export interface Props {
  busquedaHandler: Function;
  setFiltros: Function;
  filtros: FiltrosPagos;
}

interface State {
  tipoPagoSeleccionado: TipoPago | null;
}

export default class PagosFilter extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tipoPagoSeleccionado: null,
    };
  }
  submitHandler = (event: FormEvent<HTMLFormElement>) => {
    this.props.busquedaHandler();
    event.preventDefault();
  };
  render = () => {
    const { filtros } = this.props;
    const minColWidth = "250px";

    return (
      <Form
        onSubmit={this.submitHandler}
        style={{
          padding: "1rem",
          backgroundColor: "gainsboro",
          borderRadius: "5px",
          marginBottom: "1rem",
        }}
      >
        <Form.Row className="align-items-center" style={{ rowGap: "1rem" }}>
          <Col style={{ minWidth: minColWidth }}>
            <TipoPagosShow
              version={Version.SELECT}
              propsSelect={{
                callbackParent: (tipoPago: TipoPago | null) => {
                  this.props.setFiltros({ tipoPagoId: tipoPago ? tipoPago.id : null });
                  this.setState({ tipoPagoSeleccionado: tipoPago });
                },
                seleccionado: this.state.tipoPagoSeleccionado,
                clearable: true,
                label: "Tipo de pago",
                placeholder: "Seleccione un tipo de pago",
              }}
            />
          </Col>
          <Col style={{ minWidth: minColWidth }}>
            <Select
              label="Fuente de pago"
              placeholder="Seleccione fuente"
              data={Object.values(FuentePago).map((fuente) => ({ value: fuente, label: fuente }))}
              value={filtros.fuente}
              onChange={(fuente: string) => this.props.setFiltros({ fuente: fuente })}
              clearable
            />
          </Col>
          <Col style={{ minWidth: minColWidth }}>
            <div>Desde fecha </div>
            <DatePicker
              placeholderText="Ingrese fecha"
              selected={filtros.desde}
              isClearable
              onChange={(fecha: Date | null) => this.props.setFiltros({ desde: fecha })}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
          <Col style={{ minWidth: minColWidth }}>
            <div>Hasta fecha </div>
            <DatePicker
              placeholderText="Ingrese fecha"
              selected={filtros.hasta}
              isClearable
              onChange={(fecha: Date | null) => this.props.setFiltros({ hasta: fecha })}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <RequestButton
                onClick={() => {
                  this.props.busquedaHandler();
                }}
                texto="Buscar"
                icon={<Icon.Search />}
              />
            </Flex>
          </Col>
        </Form.Row>
      </Form>
    );
  };
}
