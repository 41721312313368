import React, { Component } from "react";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import RequestButton from "../../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import H from "../../../../../../libreria/message/title/H";
import ProductoPromocionLoad from "./ProductoPromocionLoad";
import { CANALES_WEB_SOCKET,  ProductoPromocion,  PromocionService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";




export interface Props {
  onSaveSuccess?: Function;
  onExit?: Function;
  idPromocion: number;
  producto: ProductoPromocion;
}

interface State {
  producto: ProductoPromocion;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class ProductoPromocionUpdate extends Component<Props, State> {

  private promocionService:PromocionService;

  constructor(props: Props) {
    super(props);
    this.state = {
      producto: this.props.producto,
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.promocionService =  new PromocionService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  onSave = async () => {
    try {
      await this.promocionService.actualizarRelacionConProducto(
        this.props.idPromocion,
        this.state.producto.producto.id,
        this.state.producto.cantidad_bonificada,
        this.state.producto.cantidad_cobrable
      );

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS, {
        type: "update",
        productoId: this.state.producto.producto.id,
      });

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.VENTAS_PROMOCIONES, {
        type: "update",
        promocionId: this.props.idPromocion,
      });
      await Swal.fire({
        icon: "success",
        text: "Promocion Actualizada",
      });
      if (this.props.onSaveSuccess) this.props.onSaveSuccess();
    } catch (error) {
      this.promocionService.manejarErrorHTTP(
        error,
        "Actualización Producto Promocion"
      );
    }
  };

  onExit = () => {
    if (this.props.onExit != undefined) this.props.onExit();
  };

  onChangeProducto = (producto: ProductoPromocion) => {
    this.setState({ producto: producto });
  };

  onChangeValidation = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border="1px solid grey"
        borderRadius="5px"
        padding="10px"
      >
        {/*  <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="10px"
        >
          <H size={4} texto={"Actualizar Producto de la Promoción"} />
        </Flex>
 */}
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <ProductoPromocionLoad
            producto={this.state.producto}
            onChange={this.onChangeProducto}
            onValidationChange={this.onChangeValidation}
            productoFijo={true}
          />
        </Flex>

        <Flex
          flexDirection="row"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
          gap="1rem"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Actualizar"}
            onClick={this.onSave}
          />
          <RequestButton texto={"Cancelar"} onClick={this.onExit} />
        </Flex>
      </Flex>
    );
  }
}
