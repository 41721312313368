import React, { Component } from "react";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Visibilizador from "../../../../../../libreria/action/visibilizador/Visibilizador";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../../libreria/appearance/margin/Margin";
import Text from "../../../../../../libreria/message/text/Text";
import PreciosHistoricosAlquilerShow from "./PreciosHistoricosAlquilerShow";



import { AlquilerGet, AlquilerService, CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";





export interface Props {
  id: number;
}
interface State {
  alquiler: AlquilerGet | null;
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
}

interface Message {
  type: string;
  idAlquiler: number;
}

export default class PreciosAlquilerShow extends Component<Props, State> {

  private alquilerService:AlquilerService;

  constructor(props: Props) {
    super(props);
    this.state = {
      alquiler: null,
      cargando: true,
      visible: false,

      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES_PRECIOS,
      funcionCallback: this.onChannelMesagge,
    });
    this.alquilerService =  new AlquilerService(Global.UsuarioService.getToken()!);


    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {
    const alquiler = await this.alquilerService.get(this.props.id);
    this.setState({ alquiler: alquiler, cargando: false });
  };

  onChannelMesagge = async (e: MessageEvent) => {
    let message: Message = JSON.parse(e.data).message;

    if (message.idAlquiler == this.props.id && message.type == "update") {
      const alquiler = await this.alquilerService.get(this.props.id);
      this.setState({ alquiler: alquiler, cargando: false });
    }
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({ cargando: true });
      const alquiler = await this.alquilerService.get(this.props.id);
      this.setState({ alquiler: alquiler, cargando: false });
    }
  };

  renderPrecios = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      <Text>Precio Actual:{this.state.alquiler!.precio}</Text>
      <Margin top="20px" />
      <PreciosHistoricosAlquilerShow id={this.props.id} />
    </Flex>
  );

  renderLoading = () => "Cargando Precios";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="20px"
      >
        <Visibilizador
          onChange={this.onChangeVisibilizador}
          label={"Precios"}
          labelAlignment={"flex-start"}
        >
          {this.state.cargando ? this.renderLoading() : this.renderPrecios()}
        </Visibilizador>
      </Flex>
    );
  }
}
