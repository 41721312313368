import React, { Component } from "react";
import {
  GoogleMap,
  LoadScript,
} from "@react-google-maps/api"; /* global google */
import Flex from "../../../libreria/appearance/flex/Flex";
import { ItemPoint, Point } from "../models";
import LocationMarker from "./LocationMarker";

interface Props {
  height?: string;
  width?: string;
  center?: Point;
  items: Array<ItemPoint>;
  selected?: ItemPoint;
  onSelect: Function;
  clickable?: boolean;
}

interface State {
  items: Array<ItemPoint>;
  selected: ItemPoint;
}

class LocationsMap extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      items: this.props.items,
      selected: this.props.selected
        ? this.props.selected
        : { id: -1, point: { lat: 0, lng: 0 } },
    };
  }

  mapa: any;

  componentDidMount() {}

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps != this.props) {
      let selected: ItemPoint = this.props.selected
        ? this.props.selected
        : { id: -1, point: { lat: 0, lng: 0 } };
      this.setState({ items: this.props.items, selected: selected });
    }
  };

  onSelect = (item: ItemPoint, selected: boolean) => {
    if (selected == false) {
      this.setState({ selected: { id: -1, point: { lat: 0, lng: 0 } } });
      this.props.onSelect(null);
    } else {
      this.setState({ selected: item });
      this.props.onSelect(item);
    }
  };

  load = (map: any) => {
    this.mapa = map;
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        height={this.props.height ? this.props.height : "100%"}
        width={this.props.width ? this.props.width : "100%"}
      >
        <LoadScript
          id="script-loader"
          googleMapsApiKey="AIzaSyCDsnMxHafUo0xkHuJfTxoAHcinkNDSi9c"
          libraries={["drawing"]}
        >
          <GoogleMap
            id="example-map"
            mapContainerStyle={{
              height: "100%",
              width: "100%",
              margin: "auto",
            }}
            ref={(map) => (this.mapa = map)}
            zoom={12}
            center={
              this.props.center
                ? this.props.center
                : { lat: -34.912185, lng: -57.957313 }
            }
            onLoad={this.load}
          >
            {this.state.items.map((item, index) => {
              if (item.id == this.state.selected.id) {
                return (
                  <LocationMarker
                    onSelect={this.onSelect}
                    selected={true}
                    item={item}
                    order={index + 1}
                    clickable={
                      this.props.clickable == undefined
                        ? true
                        : this.props.clickable
                    }
                  />
                );
              } else {
                return (
                  <LocationMarker
                    onSelect={this.onSelect}
                    selected={false}
                    item={item}
                    order={index + 1}
                    clickable={
                      this.props.clickable == undefined
                        ? true
                        : this.props.clickable
                    }
                  />
                );
              }
            })}
          </GoogleMap>
        </LoadScript>
      </Flex>
    );
  }
}
export default LocationsMap;
