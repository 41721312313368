import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { CriterioOrden, Factura } from "serviciossaintmichel";
import FechaDisplay from "../../../compartido/components/fecha-display/FechaDisplay";
import Flex from "../../../libreria/appearance/flex/Flex";
import BotonOrden from "../../seccion-clientes/clientes/components/BotonOrden";
import { getControlCriterio, getCriterioHandler, manejadorScroll } from "../../../compartido/utils/OrdenUtils";
import { Badge, Button } from "@mantine/core";

interface Props {
  onView?: Function;
  facturas: Array<Factura>;
  criterioOrdenActual: CriterioOrden | null;
  criterioOrdenHandler?: (critOrden: CriterioOrden | null) => void;
  aumentarLimite?: () => void;
}

interface State {}

export default class TablaFacturas extends Component<Props, State> {
  generarFilas = (facturas: Array<Factura>) => {
    if (facturas.length === 0) {
      return (
        <tr>
          <td colSpan={12}>Sin resultados</td>
        </tr>
      );
    }

    return facturas.map((factura, idx) => this.generarFila(factura));
  };

  generarFila = (factura: Factura) => (
    <tr key={factura.id}>
      <td className="text-center">{factura.id}</td>
      <td className="text-center">
        <Badge variant={"filled"}>{factura.fuente}</Badge>
      </td>
      <td className="text-center">{factura.tipo}</td>
      <td className="text-center">{factura.numero_factura}</td>
      <td className="text-center">{factura.razon_social}</td>
      <td className="text-center">{factura.cliente_id}</td>
      <td className="text-center">
        <FechaDisplay fecha={factura.fecha_creacion} />
      </td>
      <td className="text-center">{factura.monto_total_con_descuento}</td>
      <td className="text-center">{factura.monto_pagado}</td>
      <td className="text-center">
        <Badge variant={"filled"}>{factura.estado_afip}</Badge>
      </td>
      <td className="text-center">
        <Badge variant={"filled"}>{factura.estado_cobro}</Badge>
      </td>
      <td className="text-center">
        <Button
          onClick={() => {
            if (this.props.onView) {
              this.props.onView(factura.id);
            }
          }}
        >
          Ver
        </Button>
      </td>
    </tr>
  );

  renderBotonOrden = (campo: string) => {
    if (!this.props.criterioOrdenHandler) {
      return null;
    }
    return (
      <BotonOrden
        criterio={getControlCriterio(campo, this.props.criterioOrdenActual)}
        onCriterioClicked={getCriterioHandler(campo, this.props.criterioOrdenHandler)}
      />
    );
  };

  generarEncabezados = () => (
    <tr>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
          <span>Id </span>
          {this.renderBotonOrden("id")}
        </Flex>
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        <span>Fuente </span>
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
          <span>Tipo </span>
          {this.renderBotonOrden("tipo")}
        </Flex>
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
          <span>Número de factura </span>
          {this.renderBotonOrden("numero_factura")}
        </Flex>
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
          <span>Cliente </span>
          {this.renderBotonOrden("razon_social")}
        </Flex>
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        Cliente Id
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        <Flex justifyContent="center" alignItems="center" flexWrap="nowrap" gap="0.5rem">
          <span>Fecha de creación </span>
          {this.renderBotonOrden("fecha_creacion")}
        </Flex>
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        Monto total
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        Monto pagado
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        Estado en AFIP
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0" }}>
        Estado del cobro
      </th>
      <th className="text-center" style={{ position: "sticky", top: "0", zIndex: "2" }}></th>
    </tr>
  );

  render() {
    const { facturas, aumentarLimite } = this.props;

    return (
      <div
        style={{ maxHeight: "300px", overflowY: "scroll" }}
        onScroll={() => manejadorScroll("contenedor-scroll", aumentarLimite)}
        id="contenedor-scroll"
      >
        <Table striped bordered>
          <thead>{this.generarEncabezados()}</thead>
          <tbody>{this.generarFilas(facturas)}</tbody>
        </Table>
      </div>
    );
  }
}
