const diaSemanaIdMap = new Map<string, number>([
  ["DOM", 0],
  ["LUN", 1],
  ["MAR", 2],
  ["MIE", 3],
  ["JUE", 4],
  ["VIE", 5],
  ["SAB", 6],
]);

export function diaIdToNum(diaId: string): number {
  const valor = diaSemanaIdMap.get(diaId);
  if (valor) {
    return valor;
  }
  throw new Error("Id de dia invalido");
}

const diaSemanaNumMap = new Map<number, string>([
  [0, "DOM"],
  [1, "LUN"],
  [2, "MAR"],
  [3, "MIE"],
  [4, "JUE"],
  [5, "VIE"],
  [6, "SAB"],
]);

export function diaNumToId(diaNum: number): string {
  const valor = diaSemanaNumMap.get(diaNum);
  if (valor) {
    return valor;
  }
  throw new Error("Numero de dia invalido (minimo=0, maximo=6)");
}
