import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import ListMaquinaServicioFisica from "./ListMaquinaServicioFisica";
import SelectMaquinaServicioFisica from "./SelectMaquinaServicioFisica";
import { CANALES_WEB_SOCKET,       MaquinaServicioFisicaGet,       MaquinaServicioFisicaPost,       MaquinaServicioFisicaService,       MaquinaServicioSimbolicaGet,       MaquinaServicioSimbolicaService,       Proveedor, ProveedorService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  direccionId?: number;
  onSelect?: Function;
  version: Version;
  maquinaSimbolicaId?: number; // filtra por aquellas maquinas fisicas que sean de una maquina simbolica en particular
  soloDisponibles?: boolean; // filtra por aquellas maquinas que estan disponibles
}

export enum Version {
  LIST,
  SELECT,
}

interface State {
  maquinas: Array<MaquinaServicioFisicaGet> | null;
}

export default class MaquinasServicioFisicaShow extends Component<Props, State> {
  private maquinaServicioFisicaService:MaquinaServicioFisicaService;
  private webSocket: WebSocket | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      maquinas: null,
    };
    this.webSocket = null;
    this.maquinaServicioFisicaService = new MaquinaServicioFisicaService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_FISICA,
      funcionCallback: this.getMaquinas,
    });
    this.maquinaServicioFisicaService.paramsURL.setearLimite(LIMITES[0]);
    this.getMaquinas();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.maquinaSimbolicaId !== this.props.maquinaSimbolicaId) {
      this.getMaquinas();
    }
  }

  componentWillUnmount() {
    this.webSocket?.close();
  }

  getMaquinas = async () => {
    try {
      const { direccionId, maquinaSimbolicaId, soloDisponibles } = this.props;

      const promesa = direccionId
        ? this.maquinaServicioFisicaService.getMaquinasEnDireccion(direccionId, maquinaSimbolicaId, soloDisponibles)
        : this.maquinaServicioFisicaService.getMaquinasDisponibles(maquinaSimbolicaId, soloDisponibles);
      const resultado = await promesa;
      this.setState({ maquinas: resultado });
    } catch (error) {
      this.maquinaServicioFisicaService.manejarErrorHTTP(error, "Máquina Servicio Física");
    }
  };

  render() {
    const { version, onSelect = () => {} } = this.props;
    const { maquinas } = this.state;

    if (maquinas === null) {
      return <div>Cargando...</div>;
    }

    switch (version) {
      case Version.LIST: {
        return <ListMaquinaServicioFisica maquinas={maquinas} />;
      }
      case Version.SELECT: {
        return <SelectMaquinaServicioFisica maquinas={maquinas} onSelected={onSelect} />;
      }
      default:
        return null;
    }
  }
}
