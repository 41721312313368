import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Flex from "../../../libreria/appearance/flex/Flex";
import ComboRetornableNew from "./components/new/ComboRetornableNew";
import ComboRetornablesShow, {
  Version,
} from "./components/show/ComboRetornablesShow";
import ComboRetornableShow from "./components/show/ComboRetornableShow";
import ComboRetornableUpdate from "./components/update/ComboRetornableUpdate";
import { CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../Global";

interface Props {}

interface State {
  tabVisible: any;
  disabledActualizar: boolean;
  disabledVer: boolean;
  responsive: boolean;
  idComboRetornable: number | null;
  recienCreado: boolean;
}

export default class ComboRetornable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista",
      disabledActualizar: true,
      disabledVer: true,
      responsive: window.innerWidth < 700 ? true : false,
      idComboRetornable: null,
      recienCreado: false,
    };

    window.addEventListener("resize", this.actualizarDimensiones);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_COMBOS_RETORNABLES,
      funcionCallback: this.cargarComboRetornable,
    });
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  cargarComboRetornable = async (e: MessageEvent) => {};

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  actualizar = (idComboRetornable: number) => {
    this.setState({
      tabVisible: "actualizar",
      disabledActualizar: false,
      idComboRetornable: idComboRetornable,
    });
  };

  ver = (idComboRetornable: number) => {
    this.setState({
      tabVisible: "ver",
      disabledVer: false,
      idComboRetornable: idComboRetornable,
      recienCreado: false,
    });
  };

  ocultarVer = () => {
    this.setState({
      tabVisible: "lista",
      disabledVer: true,
      idComboRetornable: null,
    });
  };

  ocultarNuevo = () => {
    this.setState({
      tabVisible: "lista",
    });
  };

  renderizarNuevo = () => {
    if (Global.UsuarioService.getPermisos().Ventas.Otros.ComboRetornable.canAdd()) {
      return (
        <Tab eventKey="nuevo" title="Nuevo" width="100%">
          {this.state.tabVisible === "nuevo" ? (
            <ComboRetornableNew
              onExit={this.ocultarNuevo}
              onSaveSuccess={async (idComboRetornable: number) => {
                this.setState({
                  recienCreado: true,
                  idComboRetornable: idComboRetornable,
                });
                this.actualizar(idComboRetornable);
              }}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarLista = () => {
    if (Global.UsuarioService.getPermisos().Ventas.Otros.ComboRetornable.canAdd()) {
      return (
        <Tab eventKey="lista" title="Lista" width="100%">
          <ComboRetornablesShow
            version={Version.TABLE}
            onView={this.ver}
            onUpdate={this.actualizar}
          />
        </Tab>
      );
    }
  };

  renderizarActualizar = () => {
    if (Global.UsuarioService.getPermisos().Ventas.Otros.ComboRetornable.canAdd()) {
      return (
        <Tab
          width="100%"
          eventKey="actualizar"
          title="Actualizar"
          disabled={this.state.disabledActualizar}
        >
          {this.state.idComboRetornable &&
          this.state.tabVisible === "actualizar" ? (
            <ComboRetornableUpdate
              idComboRetornable={this.state.idComboRetornable}
              recienCreado={this.state.recienCreado}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarVerDetalle = () => {
    if (Global.UsuarioService.getPermisos().Ventas.Otros.ComboRetornable.canAdd()) {
      return (
        <Tab
          width="100%"
          eventKey="ver"
          title="Ver Detalle"
          disabled={this.state.disabledVer}
        >
          {this.state.idComboRetornable && this.state.tabVisible === "ver" ? (
            <ComboRetornableShow
              idComboRetornable={this.state.idComboRetornable}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey="lista"
      transition={false}
      activeKey={this.state.tabVisible}
      onSelect={this.onSelect}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "flex-start" : "",
      }}
      unmountOnExit={true}
    >
      {this.renderizarNuevo()}
      {this.renderizarLista()}
      {this.renderizarActualizar()}
      {this.renderizarVerDetalle()}
    </Tabs>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <div style={{ display: "block", width: "100%" }}>
          {this.renderizar(this.state.responsive)}
        </div>
      </Flex>
    );
  }
}
