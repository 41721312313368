import React, { Component } from "react";
import { Provincia } from "serviciossaintmichel";
import SelectGenerico from "../select/SelectGenerico";


interface Props {
  callbackParent: Function;
  seleccionado?: Provincia;
  provincias: Array<Provincia>;
}
interface State {
  seleccionado: Provincia;
}

export default class SelectProvincia extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: props.seleccionado || props.provincias[0],
    };
  }

  getPosicion = (valor: Provincia, provincias: Array<Provincia>): number => {
    return provincias.findIndex((i) => i.id === valor.id);
  };

  getSeleccionado = (indice: number) => {
    const { provincias } = this.props;
    this.props.callbackParent(provincias[indice]);
  };

  render() {
    const { provincias } = this.props;
    const { seleccionado } = this.state;
    const elementoSeleccionado = this.getPosicion(seleccionado, provincias);

    return (
      <SelectGenerico
        seleccionado={elementoSeleccionado}
        elementos={provincias.map((provincia) => provincia.nombre)}
        callback={this.getSeleccionado}
      />
    );
  }
}
