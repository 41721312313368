import React, { Component } from "react";
import BultoProductoActualizarPropio from "./BultoProductoActualizarPropio";
import BultoProductoActualizarInsumos from "./BultoProductoActualizarInsumos";
import BultoProductoActualizarProductos from "./BultoProductoActualizarProductos";
import Flex from "../../../../compartido/components/flex/Flex";
import { Tabs, Tab } from "react-bootstrap";




interface Props {
  bultoProductoId: number;
  recienCreado: boolean;
}

interface State {
  responsive: boolean;
}

export default class Productos extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      responsive: window.innerWidth < 700 ? true : false,
    };

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };
  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey={this.props.recienCreado ? "insumos" : "bultoproducto"}
      transition={false}
      variant="pills"
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "center" : "",
      }}
      unmountOnExit={true}
    >
      <Tab eventKey="bultoproducto" title="Bulto Producto">
        <BultoProductoActualizarPropio
          bultoProductoId={this.props.bultoProductoId}
        />
      </Tab>
      <Tab eventKey="insumos" title="Insumos del bulto producto">
        <BultoProductoActualizarInsumos
          bultoProductoId={this.props.bultoProductoId}
        />
      </Tab>
      <Tab eventKey="productos" title="Productos del bulto producto">
        <BultoProductoActualizarProductos
          bultoProductoId={this.props.bultoProductoId}
        />
      </Tab>
    </Tabs>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {this.renderizar(this.state.responsive)}
      </Flex>
    );
  }
}
