import React, { Component } from "react";
import { PropsSelectGenerico } from "../../../../compartido/components/select/PropsSelectGenerico";
import { PropsSelectGeneral } from "../../../../compartido/components/select/SelectBusqueda";
import EmpleadosSelect from "./EmpleadosSelect";

import { CANALES_WEB_SOCKET, EmpleadoGet, EmpleadoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  onUpdate?: Function;
  onView?: Function;
  version: Version;
  excluirIds?: Array<number>;
  propsSelect?: PropsSelectGenerico<EmpleadoGet> & PropsSelectGeneral;
}

export enum Version {
  SELECT,
}

interface State {
  empleados: Array<EmpleadoGet> | null;
}

export default class EmpleadosShow extends Component<Props, State> {

  private empleadoService:EmpleadoService;

  constructor(props: Props) {
    super(props);
    this.state = { empleados: null };

    this.empleadoService = new EmpleadoService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_PERSONAL_EMPLEADOS,
      funcionCallback: this.getEmpleados,
    });
    const empleados = await this.getEmpleados();
    this.setState({ empleados: empleados });
  }

  getEmpleados = async () => {
    return await this.empleadoService.listAll();
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  render() {
    const { version, excluirIds = [], propsSelect } = this.props;
    const { empleados } = this.state;

    if (empleados === null) {
      return null;
    }

    const empleadosFiltrados = empleados.filter((e) => excluirIds.indexOf(e.id) === -1);

    switch (version) {
      case Version.SELECT: {
        if (!propsSelect) {
          return null;
        }
        return (
          <EmpleadosSelect
            {...this.props.propsSelect}
            callbackParent={propsSelect.callbackParent}
            seleccionado={propsSelect.seleccionado ? propsSelect.seleccionado : null}
            items={empleadosFiltrados}
          />
        );
      }
      default:
        return null;
    }
  }
}
