import React, { Component } from "react";
import Flex from "../../../../../../compartido/components/flex/Flex";
import { Form } from "react-bootstrap";
import { Fuentes } from "../../../../../../compartido/estilos/estilos";
import H from "../../../../../../compartido/components/titulos/H";
import SelectTipoCliente from "./SelectTipoCliente";
import CheckBoxSelectEmpleado from "./CheckBoxSelectEmpleado";
import CheckBoxSelectPropietario from "./CheckBoxSelectPropietario";
import SelectMedioComunicacion from "./SelectMedioComunicacion";
import CheckBoxListMotivo from "./CheckBoxListMotivo";
import { EmpleadoGet, EmpleadoService, GrupoTipoCliente,  MedioComunicacion,  MedioComunicacionService,  Propietario,  PropietarioService,  TipoCliente, TipoClienteService, TipoMotivo, UsuarioService, VinculacionCliente } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";




export interface Props {
  onChangeTipoCliente: Function;
  onChangeVinculacion: Function;
  onChangeValidation: Function;
  grupoCliente: GrupoTipoCliente;
  tipoCliente: TipoCliente | null;
  vinculacion: VinculacionCliente;
}

interface State {
  tipoCliente: TipoCliente | null;
  tiposCliente: Array<TipoCliente>;
  mediosComunicacion: Array<MedioComunicacion>;
  empleados: Array<EmpleadoGet>;
  propietarios: Array<Propietario>;
  pasoValidado: boolean;
  grupoCliente: GrupoTipoCliente;
  vinculacion: VinculacionCliente;
  estadoEmpleado: boolean;
  estadoPropietario: boolean;
  cargando: boolean;
}

export default class PasoVinculacionYTipoCliente extends Component<Props, State> {



  private empleadoService:EmpleadoService;
  private medioComunicacionService:MedioComunicacionService;
  private propietarioService:PropietarioService;
  private tipoClienteService:TipoClienteService;


  constructor(props: Props) {
    super(props);

    this.state = {
      tipoCliente: this.props.tipoCliente,
      vinculacion: this.props.vinculacion,
      pasoValidado: this.isPasoValidado(this.props.tipoCliente, this.props.vinculacion),
      grupoCliente: this.props.grupoCliente,
      estadoEmpleado: this.props.vinculacion.empleado ? true : false,
      estadoPropietario: this.props.vinculacion.propietario ? true : false,
      cargando: true,
      tiposCliente: [],
      mediosComunicacion: [],
      empleados: [],
      propietarios: [],
    };
    this.checkBoxSelectEmpleado = React.createRef();
    this.checkBoxSelectPropietario = React.createRef();
    this.props.onChangeValidation(this.state.pasoValidado);

    this.empleadoService = new EmpleadoService(Global.UsuarioService.getToken()!);
    this.medioComunicacionService = new MedioComunicacionService(Global.UsuarioService.getToken()!);
    this.propietarioService = new PropietarioService(Global.UsuarioService.getToken()!);
    this.tipoClienteService = new TipoClienteService(Global.UsuarioService.getToken()!);



  }

  componentDidMount = async () => {
    const { grupoCliente } = this.props;
    const [tiposCliente, mediosComunicacion, empleados, propietarios] = await Promise.all([
      this.cargarTipoCliente(grupoCliente.id),
      this.cargarMediosComunicacion(),
      this.cargarEmpleados(),
      this.cargarPropietarios(),
    ]);

    if (tiposCliente != null && mediosComunicacion != null && empleados != null && propietarios != null) {
      this.setState({
        tiposCliente: tiposCliente,
        mediosComunicacion: mediosComunicacion,
        empleados: empleados,
        propietarios: propietarios,
        cargando: false,
      });
    }
  };

  cargarTipoCliente = async (grupoClienteId: number) => {
    const queryParams = new URLSearchParams({ grupo: grupoClienteId.toString() });
    return await this.tipoClienteService.list(queryParams).catch((error) => {
      this.tipoClienteService.manejarErrorHTTP(error, "Tipo Cliente");
    });
  };

  cargarMediosComunicacion = async () => {
    return await this.medioComunicacionService.listAll().catch((error) => {
      this.medioComunicacionService.manejarErrorHTTP(error, "Medio Comunicacion");
    });
  };

  cargarEmpleados = async () => {
    return await this.empleadoService.list().catch((error) => {
      this.empleadoService.manejarErrorHTTP(error, "Empleado");
    });
  };

  cargarPropietarios = async () => {
    return await this.propietarioService.listAll().catch((error) => {
      this.propietarioService.manejarErrorHTTP(error, "Propietario");
    });
  };

  checkBoxSelectEmpleado: any;
  checkBoxSelectPropietario: any;

  isPasoValidado = (tipoCliente: TipoCliente | null, vinculacion: VinculacionCliente): boolean => {
    return tipoCliente != null &&
      vinculacion.motivos_vinculacion != null &&
      vinculacion.motivos_vinculacion.length > 0 &&
      vinculacion.medio_comunicacion != null &&
      (vinculacion.empleado != null || vinculacion.propietario != null)
      ? true
      : false;
  };

  updateValidacion = () => {
    let x: boolean = this.isPasoValidado(this.state.tipoCliente, this.state.vinculacion);
    this.setState({ pasoValidado: x });
    this.props.onChangeValidation(x);
  };

  setearTipoCliente = (tipoCliente: TipoCliente) => {
    this.setState({ tipoCliente: tipoCliente });
    this.props.onChangeTipoCliente(tipoCliente);
    this.updateValidacion();
  };

  setearMotivos = (motivos: Array<number>) => {
    let x = this.state.vinculacion;
    x.motivos_vinculacion = motivos;
    this.setState({ vinculacion: x });
    this.props.onChangeVinculacion(x);
    this.updateValidacion();
  };

  setearMedioComunicacion = (medioComunicacion: MedioComunicacion) => {
    let x = this.state.vinculacion;
    x.medio_comunicacion = medioComunicacion.id;
    this.setState({ vinculacion: x });
    this.props.onChangeVinculacion(x);
    this.updateValidacion();
  };

  setearEmpleado = (empleado: EmpleadoGet) => {
    let x = this.state.vinculacion;
    x.empleado = empleado.id;
    this.setState({ vinculacion: x });
    this.props.onChangeVinculacion(x);
    this.updateValidacion();
  };

  setearEstadoEmpleado = (estado: boolean) => {
    if (estado) {
      let x = this.state.vinculacion;
      x.propietario = null;
      this.setState({
        estadoEmpleado: estado,
        estadoPropietario: false,
        vinculacion: x,
      });
      this.props.onChangeVinculacion(x);
      this.updateValidacion();
      this.checkBoxSelectPropietario.current.setEstado(false);
    } else {
      let x = this.state.vinculacion;
      x.empleado = null;
      this.setState({ estadoEmpleado: false, vinculacion: x });
      this.updateValidacion();
    }
  };

  setearPropietario = (propietario: Propietario) => {
    let x = this.state.vinculacion;
    x.propietario = propietario.id;
    this.setState({ vinculacion: x });
    this.props.onChangeVinculacion(x);
    this.updateValidacion();
  };

  setearEstadoPropietario = (estado: boolean) => {
    if (estado) {
      let x = this.state.vinculacion;
      x.empleado = null;
      this.setState({
        estadoEmpleado: false,
        estadoPropietario: estado,
        vinculacion: x,
      });
      this.props.onChangeVinculacion(x);
      this.updateValidacion();
      this.checkBoxSelectEmpleado.current.setEstado(false);
    } else {
      let x = this.state.vinculacion;
      x.propietario = null;
      this.setState({ estadoPropietario: false, vinculacion: x });
      this.updateValidacion();
    }
  };

  render() {
    const { tiposCliente, mediosComunicacion, empleados, propietarios, cargando } = this.state;

    if (cargando) {
      return null;
    }

    return (
      <Flex container flexDirection="column" width="100%" padding="30px">
        <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%">
          <H size={3} fuente={Fuentes.Titulos} texto="Tipo de Cliente" />
        </Flex>

        <Flex container flexDirection="column" alignItems="center" justifyContent="center" width="100%">
          <Form.Group style={{ width: "100%" }}>
            <Form.Label style={{ fontFamily: Fuentes.Labels }}>Tipo Cliente</Form.Label>
            <SelectTipoCliente
              tiposCliente={tiposCliente}
              seleccionado={this.state.tipoCliente}
              callbackParent={(tipoCliente: TipoCliente) => {
                this.setearTipoCliente(tipoCliente);
              }}
            />
          </Form.Group>
        </Flex>

        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          marginTop="20px"
        >
          <H size={3} fuente={Fuentes.Titulos} texto="Vinculacion del Cliente" />
        </Flex>

        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          marginTop="10px"
        >
          <Flex container flexDirection="column" alignItems="flex-start" justifyContent="center" width="100%">
            <H size={5} fuente={Fuentes.Titulos} texto="Motivo Vinculación" />
          </Flex>

          <Form.Group style={{ width: "100%" }}>
            <Form.Label style={{ fontFamily: Fuentes.Labels }}>Motivo</Form.Label>
            <CheckBoxListMotivo
              motivosVinculacionIDs={
                this.state.vinculacion.motivos_vinculacion ? this.state.vinculacion.motivos_vinculacion : []
              }
              tipoMotivo={TipoMotivo.ALTA}
              callbackParent={(motivos: Array<number>) => {
                this.setearMotivos(motivos);
              }}
            />
          </Form.Group>
        </Flex>

        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          marginTop="10px"
        >
          <Flex container flexDirection="column" alignItems="flex-start" justifyContent="center" width="100%">
            <H size={5} fuente={Fuentes.Titulos} texto="Comunicación" />
          </Flex>

          <Form.Group style={{ width: "100%" }}>
            <Form.Label style={{ fontFamily: Fuentes.Labels }}>Tipo de Comunicación</Form.Label>
            <SelectMedioComunicacion
              mediosComunicacion={mediosComunicacion}
              seleccionado={this.state.vinculacion.medio_comunicacion}
              callbackParent={(medioComunicacion: MedioComunicacion) => {
                this.setearMedioComunicacion(medioComunicacion);
              }}
            />
          </Form.Group>
        </Flex>

        <Flex
          container
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          marginTop="10px"
        >
          <Flex container flexDirection="column" alignItems="flex-start" justifyContent="center" width="100%">
            <H size={5} fuente={Fuentes.Titulos} texto="Venta Realizada Por:" />
          </Flex>

          <Form.Group style={{ width: "100%" }}>
            <CheckBoxSelectEmpleado
              empleados={empleados}
              ref={this.checkBoxSelectEmpleado}
              estado={this.state.estadoEmpleado}
              empleado={this.state.vinculacion.empleado}
              onChangeEmpleado={this.setearEmpleado}
              onChangeEstado={this.setearEstadoEmpleado}
            />
          </Form.Group>

          <Form.Group style={{ width: "100%" }}>
            <CheckBoxSelectPropietario
              propietarios={propietarios}
              ref={this.checkBoxSelectPropietario}
              estado={this.state.estadoPropietario}
              propietario={this.state.vinculacion.propietario}
              onChangePropietario={this.setearPropietario}
              onChangeEstado={this.setearEstadoPropietario}
            />
          </Form.Group>
        </Flex>
      </Flex>
    );
  }
}
