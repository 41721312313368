import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";


import { CANALES_WEB_SOCKET, TipoProductoGet, TipoProductoPost, TipoProductoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";





export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  tipoProductos: Array<TipoProductoGet>;
}

export default class TipoProductoLista extends Component<Props, State> {

  private tipoProductoService:TipoProductoService;


  constructor(props: Props) {
    super(props);
    this.state = {
      tipoProductos: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_PRODUCTOS,
      funcionCallback: this.getElementos,
    });

    this.tipoProductoService =  new TipoProductoService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.tipoProductoService.list()
      .then((result) => {
        this.setState({
          tipoProductos: result,
        });
      })
      .catch((error) => {
        this.tipoProductoService.manejarErrorHTTP(error, "Tipo Producto");
      });
  };

  componentDidMount() {
    this.tipoProductoService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }

  armarFilas(tipoProductos: Array<TipoProductoGet>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (tipoProductos.length > 0) {
      for (let i = 0; i < tipoProductos.length; i++) {
        let fila: Array<string> = [];
        fila.push(tipoProductos[i].tipo);
        let filaResult: FilaTablaGenerica = {
          id: tipoProductos[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Elementos.Productos.TipoProducto.canChange()
    ) {
      let accionActualizar: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accionActualizar);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.tipoProductoService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.tipoProductos)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.tipoProductoService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaTipoProductos"
      />
    );
  }
}
