import React, { Component } from "react";
import Flex from "../../../../compartido/components/flex/Flex";
import Box from "../../../../compartido/components/box/Box";

import { CANALES_WEB_SOCKET,  MaquinaHerramientaGet,    MaquinaHerramientaService, Proveedor, ProveedorService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  maquinaHerramientaId: number;
  ocultar: Function;
}

interface State {
  maquinaHerramienta: MaquinaHerramientaGet;
  anchoBox: string;
}

export default class MaquinaHerramientaVerDetalle extends Component<
  Props,
  State
> {
  private maquinaHerramientaService:MaquinaHerramientaService;

  constructor(props: Props) {
    super(props);

    this.state = {
      maquinaHerramienta: this.crearMaquinaHerramientaVacio(),
      anchoBox: window.innerWidth < 750 ? "90%" : "50%",
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_HERRAMIENTA,
      funcionCallback: this.cargarMaquinaHerramientaCanal,
    });
    /* WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.PROVEEDORES,
      funcionCallback: this.cargarProveedores,
    }); */

    this.maquinaHerramientaService = new MaquinaHerramientaService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 750) {
      this.setState({ anchoBox: "90%" });
    } else {
      this.setState({ anchoBox: "50%" });
    }
  };

  componentDidMount() {
    this.cargarMaquinaHerramienta(this.props.maquinaHerramientaId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarMaquinaHerramienta(this.props.maquinaHerramientaId);
    }
  }

  crearMaquinaHerramientaVacio(): MaquinaHerramientaGet {
    const proveedor: Proveedor = {
      id: 0,
      nombre: "",
    };
    const maquinaHerramienta: MaquinaHerramientaGet = {
      id: 0,
      nombre: "",
      marca: "",
      descripcion: "",
      proveedor: proveedor,
    };
    return maquinaHerramienta;
  }

  cargarMaquinaHerramientaCanal = async (e: MessageEvent) => {
    var dato = JSON.parse(e.data);
    if (
      this.state.maquinaHerramienta &&
      dato.message.maquinaHerramientaId == this.state.maquinaHerramienta.id
    ) {
      this.cargarMaquinaHerramienta(this.state.maquinaHerramienta.id);
    }
  };

  cargarMaquinaHerramienta = async (id: number) => {
    const resMaquinaHerramienta = await this.maquinaHerramientaService.get(id).catch(
      (error) => {
        this.maquinaHerramientaService.manejarErrorHTTP(
          error,
          "Máquina Herramienta"
        );
        return null;
      }
    );
    if (resMaquinaHerramienta != null) {
      this.setState({
        maquinaHerramienta: resMaquinaHerramienta,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  render() {
    if (this.state.maquinaHerramienta.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal={"10rem"} paddingVertical={"2rem"}>
            <Flex container flexDirection="column">
              <Flex
                container
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div>
                  <h5>Nombre: {this.state.maquinaHerramienta.nombre}</h5>
                  <h5>
                    Proveedor: {this.state.maquinaHerramienta.proveedor.nombre}
                  </h5>
                  <h5>Marca: {this.state.maquinaHerramienta.marca}</h5>
                  <h5>
                    Descripcion: {this.state.maquinaHerramienta.descripcion}
                  </h5>
                </div>
              </Flex>
            </Flex>

            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "1px",
                  width: "100%",
                  borderTop: "1px solid gray",
                }}
              />
            </Flex>
            <Flex
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <button
                className={"btn btn-outline-secondary"}
                onClick={this.ocultar}
                style={{ width: "150px" }}
              >
                Salir
              </button>
            </Flex>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
