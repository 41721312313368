import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";


import { CANALES_WEB_SOCKET, InsumoGet, InsumoPost, InsumoService, TipoInsumoGet, TipoInsumoPost, TipoInsumoService, UnidadInsumoGet, UnidadInsumoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  tipoInsumos: Array<TipoInsumoGet>;
}

export default class TipoInsumoLista extends Component<Props, State> {

  private tipoInsumoService:TipoInsumoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      tipoInsumos: [],
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_INSUMOS,
      funcionCallback: this.getElementos,
    });

    this.tipoInsumoService =  new TipoInsumoService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.tipoInsumoService.list()
      .then((result) => {
        this.setState({
          tipoInsumos: result,
        });
      })
      .catch((error) => {
        this.tipoInsumoService.manejarErrorHTTP(error, "Tipo Insumo");
      });
  };

  componentDidMount() {
    this.tipoInsumoService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }

  armarFilas(tipo_insumos: Array<TipoInsumoGet>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (tipo_insumos.length > 0) {
      for (let i = 0; i < tipo_insumos.length; i++) {
        let fila: Array<string> = [];
        fila.push(tipo_insumos[i].tipo);
        let filaResult: FilaTablaGenerica = {
          id: tipo_insumos[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (Global.UsuarioService.getPermisos().Elementos.Insumos.TipoInsumo.canChange()) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.tipoInsumoService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.tipoInsumos)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.tipoInsumoService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaTipoInsumos"
      />
    );
  }
}
