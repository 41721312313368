import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";


import { CANALES_WEB_SOCKET, EmpleadoGet, EmpleadoPost, EmpleadoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";





export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  empleados: Array<EmpleadoGet>;
}
export default class EmpleadoLista extends Component<Props, State> {

  private empleadoService:EmpleadoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      empleados: [],
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_PERSONAL_EMPLEADOS,
      funcionCallback: this.getEmpleados,
    });

    this.empleadoService = new EmpleadoService(Global.UsuarioService.getToken()!);

  }

  getEmpleados = () => {
    this.empleadoService.list()
      .then((result) => {
        this.setState({ empleados: result });
      })
      .catch((error) => {
        this.empleadoService.manejarErrorHTTP(error, "Empleado");
      });
  };

  componentDidMount() {
    this.empleadoService.paramsURL.setearLimite(LIMITES[0]);
    this.getEmpleados();
  }

  armarFilas(empleados: Array<EmpleadoGet>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (empleados.length > 0) {
      for (let i = 0; i < empleados.length; i++) {
        let fila: Array<string> = [];
        fila.push(empleados[i].persona.nombre);
        fila.push(empleados[i].persona.apellido);
        let filaResult: FilaTablaGenerica = {
          id: empleados[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(
      //busco cual el empleado que tenga el id que me pasaron por parametro y lo paso
      this.state.empleados.find((p) => p.id === id)
    );
  };

  handleVer = (id: number) => {
    this.props.ver(this.state.empleados.find((p) => p.id === id));
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Administracion.Personal.Empleado.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Administracion.Personal.Empleado.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.empleadoService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.empleados)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.empleadoService.paramsURL}
        listarElementos={this.getEmpleados}
        id="TablaEmpleados"
      />
    );
  }
}
