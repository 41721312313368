import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { EntregaMaquinaLectura, MaquinaServicioSimbolicaGet } from "serviciossaintmichel";
import MaquinasSimbolicasShow, {
  Version,
} from "../../../../elementos/maquinas/MaquinaServicioSimbolica/components/show/MaquinasSimbolicasShow";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";

export interface Props {
  onSubmitCallback?: Function;
  excluirMaquinas?: Array<number>;
}

interface State {
  nuevaEntregaMaquina: EntregaMaquinaLectura;
  maquinaSeleccionada: MaquinaServicioSimbolicaGet | null;
}

export default class EntregaMaquinaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getEstadoInicial();
  }

  maquinaNueva = (): MaquinaServicioSimbolicaGet => {
    return { id: -1, nombre: "", productos: [], proveedores: [] };
  };

  getEstadoInicial = () => ({
    nuevaEntregaMaquina: {
      maquina_simbolica: this.maquinaNueva(),
      cantidad: 0,
    },
    maquinaSeleccionada: null,
  });

  setMaquina = (maq: MaquinaServicioSimbolicaGet | null) => {
    const maquinaSimbolica: MaquinaServicioSimbolicaGet =
      maq !== null ? { ...maq, productos: [], proveedores: [] } : this.maquinaNueva();
    this.setState((prevState) => ({
      nuevaEntregaMaquina: {
        ...prevState.nuevaEntregaMaquina,
        maquina_simbolica: maq ? maquinaSimbolica : this.state.nuevaEntregaMaquina.maquina_simbolica,
      },
      maquinaSeleccionada: maq,
    }));
  };

  onSubmit = () => {
    const { onSubmitCallback = () => {} } = this.props;
    onSubmitCallback(this.state.nuevaEntregaMaquina);
    const estadoInicial = this.getEstadoInicial();
    this.setState(estadoInicial);
  };

  validarEntrega = (entregaMaquina: EntregaMaquinaLectura) => {
    return entregaMaquina.maquina_simbolica.id > 0 && entregaMaquina.cantidad > 0;
  };

  render() {
    const { nuevaEntregaMaquina, maquinaSeleccionada } = this.state;

    return (
      <Flex borderRadius="5px" border="1px solid black" gap="1rem" padding="1rem" alignItems="center">
        <MaquinasSimbolicasShow
          version={Version.SELECT}
          excluirIds={this.props.excluirMaquinas}
          propsSelect={{
            label: "Máquinas",
            placeholder: "Seleccine una máquina",
            seleccionado: maquinaSeleccionada,
            callbackParent: this.setMaquina,
          }}
        />

        <div>
          <span>Cantidad</span>
          <Input
            type={InputTypes.Number}
            data={nuevaEntregaMaquina.cantidad}
            onChange={(data: number) => {
              this.setState((prevState) => ({
                nuevaEntregaMaquina: { ...prevState.nuevaEntregaMaquina, cantidad: data },
              }));
            }}
          />
        </div>

        <Button onClick={this.onSubmit} disabled={!this.validarEntrega(nuevaEntregaMaquina)}>
          Añadir
        </Button>
      </Flex>
    );
  }
}
