import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Localidad } from "serviciossaintmichel";
import { Fuentes } from "../../estilos/estilos";
import ComponenteDatoGeografico from "../direccion/ComponenteDatoGeografico";
import Flex from "../flex/Flex";
import InputBase from "../input/InputBase";
import H from "../titulos/H";

export interface Props {
  localidadId: number;
  titulo?: string;
  calle: string;
  numero: string;
  onChangeCallback?: Function;
}

interface State {
  calle: string;
  numero: string;
  localidad: number;
}

export default class FormularioDireccionComercial extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      calle: props.calle,
      numero: props.numero,
      localidad: props.localidadId,
    };
  }

  static defaultProps = {
    titulo: "Dirección Comercial",
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState !== this.state) {
      console.log("DIRECCION COMERCIAL:", this.state);
      const { onChangeCallback } = this.props;
      if (onChangeCallback) {
        onChangeCallback(this.state);
      }
    }
  }

  stateSetter = (propiedad: string) => {
    return (valor: any) =>
      this.setState({
        ...this.state,
        [propiedad]: valor,
      });
  };

  setLocalidad = (localidad: Localidad) => {
    this.setState({ localidad: localidad.id });
  };

  render = () => {
    const { calle, numero } = this.state;
    const { localidadId, titulo = FormularioDireccionComercial.defaultProps.titulo } = this.props;
    return (
      <Flex
        container
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        marginBottom="20px"
        marginTop="15px"
      >
        <Flex
          container
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          width="100%"
          marginBottom="15px"
        >
          <H size={4} alineacion="left" fuente={Fuentes.Titulos} texto={titulo} />
        </Flex>

        <Form>
          <Form.Group style={{ width: "100%" }}>
            <Form.Label htmlFor="calle">Calle</Form.Label>
            <InputBase id="calle" name="calle" onChange={this.stateSetter("calle")} value={calle} placeholder="Calle" />
          </Form.Group>

          <Form.Group style={{ width: "100%" }}>
            <Form.Label htmlFor="numero">Numero</Form.Label>
            <InputBase
              id="numero"
              name="numero"
              onChange={this.stateSetter("numero")}
              value={numero}
              placeholder="Número"
            />
          </Form.Group>

          <ComponenteDatoGeografico localidadId={localidadId} onChange={this.setLocalidad} />
        </Form>
      </Flex>
    );
  };
}
