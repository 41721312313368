import React, { Component } from "react";
import ArticuloGraficoActualizar from "./ArticuloGraficoActualizar";
import ArticuloGraficoLista from "./ArticuloGraficoLista";
import ArticuloGraficoNuevo from "./ArticuloGraficoNuevo";
import ArticuloGraficoVerDetalle from "./ArticuloGraficoVerDetalle";
import Flex from "../../../../compartido/components/flex/Flex";
import { Tabs, Tab, } from "react-bootstrap";
import { Global } from "../../../../Global";

interface Props {}

interface State {
  tabVisible: any;
  disabledActualizar: boolean;
  disabledVer: boolean;
  responsive: boolean;
  articuloGraficoId: number | null;
}

export default class ArticulosGrafico extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista",
      disabledActualizar: true,
      disabledVer: true,
      responsive: window.innerWidth < 700 ? true : false,
      articuloGraficoId: null,
    };
    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  actualizar = (articuloGraficoId: number) => {
    this.setState({
      tabVisible: "actualizar",
      disabledActualizar: false,
      articuloGraficoId: articuloGraficoId,
    });
  };

  ver = (articuloGraficoId: number) => {
    this.setState({
      tabVisible: "ver",
      disabledVer: false,
      articuloGraficoId: articuloGraficoId,
    });
  };

  ocultarActualizar = () => {
    this.setState({
      tabVisible: "lista",
      disabledActualizar: true,
      articuloGraficoId: null,
    });
  };

  ocultarNuevo = () => {
    this.setState({
      tabVisible: "lista",
    });
  };

  ocultarVer = () => {
    this.setState({
      tabVisible: "lista",
      disabledVer: true,
      articuloGraficoId: null,
    });
  };

   renderizarNuevo = () => {
    if (Global.UsuarioService.getPermisos().Elementos.ArticulosGraficos.ArticuloGrafico.canAdd())
    {
      return(
        <Tab eventKey="nuevo" title="Nuevo">
        {this.state.tabVisible === "nuevo" ? (
          <ArticuloGraficoNuevo ocultar={this.ocultarNuevo} />
        ) : null}
        </Tab>
    );}
  }

  renderizarLista = () => {
    if (Global.UsuarioService.getPermisos().Elementos.ArticulosGraficos.ArticuloGrafico.canView())
    {
      return(
        <Tab eventKey="lista" title="Lista">
          <ArticuloGraficoLista ver={this.ver} actualizar={this.actualizar} />
        </Tab>
      );
    }
  }

  renderizarActualizar = () => {
    if (Global.UsuarioService.getPermisos().Elementos.ArticulosGraficos.ArticuloGrafico.canChange())
    {
      return(
        <Tab
          eventKey="actualizar"
          title="Actualizar"
          disabled={this.state.disabledActualizar}
        >
          {this.state.articuloGraficoId && this.state.tabVisible === "actualizar" ? (
            <ArticuloGraficoActualizar articuloGraficoId={this.state.articuloGraficoId}
            ocultar={this.ocultarActualizar}
            />
          ) : null}
       </Tab>
      );
    }
  }

  renderizarVerDetalle=()=>{
    if (Global.UsuarioService.getPermisos().Elementos.ArticulosGraficos.ArticuloGrafico.canView())
    {
      return(
        <Tab eventKey="ver" title="Ver Detalle" disabled={this.state.disabledVer}>
          {this.state.articuloGraficoId && this.state.tabVisible === "ver" ? (
            <ArticuloGraficoVerDetalle
            articuloGraficoId={this.state.articuloGraficoId}
              ocultar={this.ocultarVer}
            />
          ) : null}
        </Tab>
    );
    }
  }

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey="lista"
      transition={false}
      activeKey={this.state.tabVisible}
      onSelect={this.onSelect}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "center" : "",
      }}
      unmountOnExit={true}
    >
      {this.renderizarNuevo()}
      {this.renderizarLista()}
      {this.renderizarActualizar()}
      {this.renderizarVerDetalle()}

    </Tabs>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {this.renderizar(this.state.responsive)}
      </Flex>
    );
  }
}
