import React, { Component, FormEvent } from "react";
import Flex from "../../../../../compartido/components/flex/Flex";
import DatosEnPasos from "./DatosEnPasos";

export interface Props {
  ocultar: Function;
  callbackParent?: Function;
}

interface State {}

export default class ClienteNuevo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  ocultar = () => {
    this.props.ocultar();
  };

  clienteCreado = (id: number) => {};

  render() {
    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <DatosEnPasos clienteCreado={this.clienteCreado} />
      </Flex>
    );
  }
}
