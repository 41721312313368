import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";
import { Checkbox, Badge } from "@mantine/core";
import { getColorEstadoProgramacion, getColorPrioridad, ProgramacionEntrega } from "serviciossaintmichel";

interface Props {
  data: Array<ProgramacionEntrega>;
  onChangeSeleccionados?: (seleccionados: Array<ProgramacionEntrega>) => void;
  onVerDetalle?: (seleccionada: ProgramacionEntrega) => void;
  permitirSeleccion?: boolean;
  permitirVerDetalle?: boolean;
}

interface State {
  seleccionados: Map<number, boolean> | null;
}

export default class TablaProgramaciones extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionados: null,
    };
  }

  componentDidMount(): void {
    const selMap = new Map();
    this.props.data.forEach((item) => {
      selMap.set(item.id, false);
    });

    this.setState({ seleccionados: selMap });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevState.seleccionados !== this.state.seleccionados) {
      if (this.props.onChangeSeleccionados && this.state.seleccionados) {
        const idSeleccionados: Array<number> = [];
        this.state.seleccionados.forEach((seleccionado, id) => {
          if (seleccionado) {
            idSeleccionados.push(id);
          }
        });
        const programacionesSeleccionadas = this.props.data.filter((item) => idSeleccionados.indexOf(item.id) !== -1);
        this.props.onChangeSeleccionados(programacionesSeleccionadas);
      }
    }
  }

  render() {
    const { seleccionados } = this.state;
    const { data, permitirSeleccion = true, permitirVerDetalle = true } = this.props;

    if (data === null || seleccionados === null) {
      return <div>Cargando...</div>;
    }

    return (
      <Table striped bordered>
        <thead>
          <tr>
            {permitirSeleccion ? (
              <th className="text-center" style={{ position: "sticky", top: "0" }}>
                <Checkbox
                  onChange={(e) => {
                    const nuevosSeleccionados = new Map(seleccionados);

                    nuevosSeleccionados.forEach((value, key) => {
                      nuevosSeleccionados.set(key, e.target.checked);
                    });

                    this.setState({ seleccionados: nuevosSeleccionados });
                  }}
                />
              </th>
            ) : null}
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Cliente
            </th>
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Direccion
            </th>
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Zona
            </th>
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Entrega programada
            </th>
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Peso(kg)
            </th>
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Precio(AR$)
            </th>
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Reparto diario
            </th>
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Prioridad
            </th>
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Estado
            </th>
            <th className="text-center" style={{ position: "sticky", top: "0" }}>
              Observacion
            </th>
            {permitirVerDetalle ? <th className="text-center" style={{ position: "sticky", top: "0" }}></th> : null}
          </tr>
        </thead>

        <tbody>
          {data.length > 0 ? (
            data.map((item, idx) => (
              <tr style={{ backgroundColor: seleccionados.get(item.id) ? "silver" : "white" }}>
                {permitirSeleccion ? (
                  <td className="text-center">
                    <Checkbox
                      checked={seleccionados.get(item.id)}
                      onChange={(e) => {
                        const nuevosSeleccionados = new Map(seleccionados);
                        const actual = nuevosSeleccionados.get(item.id);
                        nuevosSeleccionados.set(item.id, !actual);
                        this.setState({ seleccionados: nuevosSeleccionados });
                      }}
                    />
                  </td>
                ) : null}
                <td className="text-center">{item.pedido.cliente.razon_social}</td>
                <td className="text-center">{`${item.direccion.calle} N ${item.direccion.numero} - ${item.direccion.localidad.nombre}`}</td>
                <td className="text-center">
                  {item.direccion.zonas !== null && item.direccion.zonas.length > 0
                    ? item.direccion.zonas[0].nombre
                    : "-"}
                </td>
                <td className="text-center">
                  <div style={{ height: "50px", overflowY: "scroll" }}>
                    <span>
                      <strong>Productos:</strong>
                    </span>
                    <ul>
                      {item.productos_requeridos.map((infoProducto) => (
                        <li>
                          {infoProducto.producto.nombre} x {infoProducto.cantidad}
                        </li>
                      ))}
                    </ul>
                    <span>
                      <strong>Bultos producto:</strong>
                    </span>
                    <ul>
                      {item.bultos_producto_requeridos.map((infoBultoProducto) => (
                        <li>
                          {infoBultoProducto.bulto_producto.nombre} x {infoBultoProducto.cantidad}
                        </li>
                      ))}
                    </ul>
                  </div>
                </td>
                <td className="text-center">{item.peso_total}</td>
                <td className="text-center">{item.precio_total}</td>
                <td className="text-center">
                  {item.visita === null
                    ? "-"
                    : `${item.visita.reparto_diario.reparto.nombre} - Viaje ${item.visita.reparto_diario.viaje_numero}`}
                </td>
                <td className="text-center">
                  <Badge color={getColorPrioridad(item.prioridad)} variant={"filled"}>
                    {item.prioridad}
                  </Badge>
                </td>
                <td className="text-center">
                  <Badge color={getColorEstadoProgramacion(item.estado_programacion.estado)} variant={"filled"}>
                    {item.estado_programacion.estado}
                  </Badge>
                </td>
                <td className="text-center">{item.observacion ? item.observacion : "-"}</td>
                {permitirVerDetalle ? (
                  <td className="text-center">
                    <Button
                      onClick={() => {
                        if (this.props.onVerDetalle) {
                          this.props.onVerDetalle(item);
                        }
                      }}
                    >
                      Ver detalle
                    </Button>
                  </td>
                ) : null}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={12}>Sin resultados</td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}
