import React, { Component } from "react";

class Alerta extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <br style={{ display: this.props.estado }} />
        <div
          class={"alert alert-dismissible " + this.props.tipo}
          style={{ display: this.props.estado }}
        >
          <button type="button" class="close" data-dismiss="alert">
            &times;
          </button>
          <strong>{this.props.titulo}</strong> {this.props.mensaje}
        </div>
        <br style={{ display: this.props.estado }} />
      </div>
    );
  }
}
export default Alerta;
