import React, { Component } from "react";
import DireccionShow from "../../../../../compartido/components/direccion/DireccionShow";
import Box from "../../../../../libreria/containers/box/Box";
import ClienteDireccionesShow, { Version } from "../actualizar/direcciones/ClienteDireccionesShow";

export interface Props {
  clienteId: number;
}

interface State {
  direccionIdDetalle: number | null;
}

export default class ClienteVerDirecciones extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      direccionIdDetalle: null,
    };
  }

  render() {
    const { clienteId } = this.props;
    const { direccionIdDetalle } = this.state;
    return (
      <div>
        <ClienteDireccionesShow
          clienteId={clienteId}
          showDetail
          onDetail={(direccionId: number) => {
            this.setState({ direccionIdDetalle: direccionId });
          }}
          direccionDetalleId={direccionIdDetalle || undefined}
          version={Version.LIST}
        />

        {direccionIdDetalle && (
          <div style={{ marginTop: "1rem" }}>
            <Box
              padding="1rem"
              showCloseBtn
              closeConfirmation={false}
              onCloseCallback={() => this.setState({ direccionIdDetalle: null })}
            >
              <DireccionShow direccionId={direccionIdDetalle} />
            </Box>
          </div>
        )}
      </div>
    );
  }
}
