import React, { Component } from "react";
import { Button, ListGroup } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { RolEmpleado } from "serviciossaintmichel";
import RolEmpleadoShow from "./RolEmpleadoShow";

export interface Props {
  rolesEmpleados: Array<RolEmpleado>;
  onDelete?: Function;
  showDelete?: boolean;
}

export default class RolesEmpleadosShow extends Component<Props, {}> {
  render() {
    const { rolesEmpleados } = this.props;

    if (rolesEmpleados.length <= 0) {
      return <div>Sin empleados</div>;
    }

    return (
      <ListGroup as="ol" style={{ width: "100%" }}>
        {rolesEmpleados.map((re: RolEmpleado, idx: number) => (
          <ListGroup.Item
            as="li"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <RolEmpleadoShow rolEmpleado={re} />
            {this.props.showDelete && (
              <Button
                variant="danger"
                onClick={() => {
                  if (this.props.onDelete) {
                    this.props.onDelete(re);
                  }
                }}
              >
                <Icon.Trash />
              </Button>
            )}
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  }
}
