import { subDays } from "date-fns";
import React, { Component } from "react";
import { ServicioCliente } from "serviciossaintmichel";
import ServicioClienteUpdate from "./ServicioClienteUpdate";

export interface Props {
  clienteId: number;
  servicioCliente: ServicioCliente | null;
  fechaInicioProximoServicio?: Date;
}

interface State {}

export default class ServicioActual extends Component<Props, State> {
  render = () => {
    const { servicioCliente, clienteId, fechaInicioProximoServicio } = this.props;

    if (servicioCliente === null) {
      return <div>El cliente no posee un servicio actualmente</div>;
    }

    let fechaFinMax;
    if (fechaInicioProximoServicio) {
      fechaFinMax = subDays(fechaInicioProximoServicio, 1);
    }

    return <ServicioClienteUpdate clienteId={clienteId} servicioCliente={servicioCliente} fechaFinMax={fechaFinMax} />;
  };
}
