import React, { Component } from "react";
import FormularioAFIP from "../../../../../../../compartido/components/formularios/FormularioAFIP";
import { Global } from "../../../../../../../Global";
import { Cliente, TipoClienteService } from "serviciossaintmichel";



export interface Props {
  cliente: Cliente;
}
interface State {
  grupoClienteId: number | null;
}
export default class DatosAfip extends Component<Props, State> {

  private tipoClienteService:TipoClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      grupoClienteId: null,
    };

    this.tipoClienteService = new TipoClienteService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    const tipoCliente = await this.tipoClienteService.get(this.props.cliente.tipo_cliente);
    this.setState({ grupoClienteId: tipoCliente.grupo });
  };

  render() {
    const { grupoClienteId } = this.state;
    const { cliente } = this.props;

    if (!grupoClienteId) {
      return null;
    }

    return (
      <FormularioAFIP
        cliente={cliente}
        grupoClienteId={grupoClienteId}
        onChangeDatosAfip={() => {}}
        onChangeValidation={() => {}}
      />
    );
  }
}
