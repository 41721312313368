import React, { Component } from "react";
import { MaquinaServicioSimbolicaGet } from "serviciossaintmichel";
import Flex from "../../../../libreria/appearance/flex/Flex";
import { Data } from "../../../../libreria/data/models/models";
import Select from "../../../../libreria/data/select/Select";

export interface Props {
  onSelect?: Function;
  idSeleccionado?: number;
  maquinas: Array<MaquinaServicioSimbolicaGet>;
}

interface State {}

export default class SelectMaquinaServicioSimbolica extends Component<Props, State> {
  adaptador = (maquinas: Array<MaquinaServicioSimbolicaGet>): Array<Data> => {
    return maquinas.map((maquina) => ({ id: maquina.id, text: maquina.nombre }));
  };

  getIndexFromSelected = (data: Array<Data>, id: number) => {
    const ids = data.map((item) => item.id);
    return ids.indexOf(id);
  };

  onSelect = (id: number) => {
    if (this.props.onSelect) {
      this.props.onSelect(this.props.maquinas.filter((maquina) => maquina.id === id)[0]);
    }
  };

  render() {
    const elementos = this.adaptador(this.props.maquinas);

    return (
      <Flex container flexDirection="column" width="auto" alignItems="center" justifyContent="center">
        <Select onChange={this.onSelect} elements={elementos} selected={this.props.idSeleccionado} />
      </Flex>
    );
  }
}
