import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { CANALES_WEB_SOCKET, ClienteService, DireccionDTO, DireccionRecorridoService, DireccionService, FranjaHoraria, Recorrido, WebSocketService } from "serviciossaintmichel";
import DireccionLoad from "../../../../../../compartido/components/direccion/DireccionLoad";
import Flex from "../../../../../../compartido/components/flex/Flex";
import FranjasHorariasLoad from "../../../../../../compartido/components/franjahoraria/FranjasHorariasLoad";
import ManejadorErroresHTTP from "../../../../../../compartido/utils/ManejadorErroresHTTP";
import RecorridosLoad from "../../../../../../distribucion/recorridos/components/RecorridosLoad";
import { Global } from "../../../../../../Global";
import { Box } from "../../../../../../libreria/containers/box/Box";





export interface Props {
  clienteId: number;
  direccionId: number;
  onClose?: Function;
}

interface State {
  direccion: DireccionDTO | null;
  franjasHorarias: Array<FranjaHoraria>;
  direccionValida: boolean;
  franjasHorariasValidas: boolean;
  recorridos: Array<Recorrido>;
  recorridosValidos: boolean;
}

export default class ClienteDireccionUpdate extends Component<Props, State> {


  private direccionService:DireccionService;
  private clienteService:ClienteService;
  private direccionRecorridoService:DireccionRecorridoService;


  constructor(props: Props) {
    super(props);
    this.state = {
      direccion: null,
      franjasHorarias: [],
      franjasHorariasValidas: false,
      direccionValida: false,
      recorridos: [],
      recorridosValidos: false,
    };

    this.direccionService = new DireccionService(Global.UsuarioService.getToken()!);
    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);
    this.direccionRecorridoService = new DireccionRecorridoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    this.cargarData(this.props.direccionId);
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.COMPARTIDO_DIRECCION,
      funcionCallback: this.callbackWebSocket,
    });
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevProps.direccionId !== this.props.direccionId) {
      this.cargarData(this.props.direccionId);
    }
  };

  callbackWebSocket = async (e: MessageEvent) => {
    const direccionId = JSON.parse(e.data).message.direccionId;

    if (direccionId === this.props.direccionId) {
      this.cargarData(direccionId);
    }
  };

  cargarData = async (direccionId: number) => {
    const [direccion, franjasHorarias, relacionesRecorrido] = await Promise.all([
      this.direccionService.get(direccionId),
      this.direccionService.getFranjasHorarias(direccionId),
      this.direccionRecorridoService.getRecorridosDeDireccion(direccionId),
    ]);
    const recorridos = relacionesRecorrido.map((relacion) => relacion.recorrido);
    this.setState({ direccion: direccion, franjasHorarias: franjasHorarias, recorridos: recorridos });
  };

  onChangeFranjas = (franjasHorarias: Array<FranjaHoraria>) => {
    this.setState({ franjasHorarias: franjasHorarias });
  };

  onFranjasValidationChange = (valido: boolean) => {
    this.setState({ franjasHorariasValidas: valido });
  };

  onChangeDireccion = (direccion: DireccionDTO) => {
    this.setState({ direccion: direccion });
  };

  onDireccionValidationChange = (valido: boolean) => {
    this.setState({ direccionValida: valido });
  };

  onChangeRecorridos = (recorridos: Array<Recorrido>) => {
    this.setState({ recorridos: recorridos });
  };

  onRecorridosValidationChange = (valido: boolean) => {
    this.setState({ recorridosValidos: valido });
  };

  onSubmit = async () => {
    const { direccion, franjasHorarias, recorridos } = this.state;
    const { clienteId, onClose = () => {}, direccionId } = this.props;

    if (direccion === null) {
      return;
    }

    try {
      const resultado = await this.clienteService.editarDireccion(
        clienteId,
        direccionId,
        direccion,
        franjasHorarias,
        recorridos
      );
      const data = { direccionId: resultado.id, clienteId: clienteId };
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.COMPARTIDO_DIRECCION, data);
      onClose();
    } catch (error) {
      ManejadorErroresHTTP.evaluarError(error, "DireccionCliente");
    }
  };

  render = () => {
    const { franjasHorarias, direccionValida, franjasHorariasValidas, direccion, recorridos, recorridosValidos } =
      this.state;
    const { onClose = () => {} } = this.props;

    if (direccion === null) {
      return <div>Cargando...</div>;
    }

    return (
      <Box
        width={"75%"}
        paddingVertical={"2rem"}
        paddingHorizontal={"2rem"}
        showCloseBtn={true}
        onCloseCallback={onClose}
      >
        <Flex container flexDirection={"column"} alignItems={"center"} padding={"2rem"}>
          <DireccionLoad
            direccion={direccion}
            onChangeDireccion={(direccion: DireccionDTO) => {
              this.onChangeDireccion(direccion);
            }}
            onChangeValidation={(valido: boolean) => {
              this.onDireccionValidationChange(valido);
            }}
          />
          <hr style={{ width: "100%" }} />
          <FranjasHorariasLoad
            franjas={franjasHorarias}
            onChange={(franjas: Array<FranjaHoraria>) => {
              this.onChangeFranjas(franjas);
            }}
            onValidationChange={(valido: boolean) => {
              this.onFranjasValidationChange(valido);
            }}
          />
          <RecorridosLoad
            recorridos={recorridos}
            onChangeRecorridos={(recorridos: Array<Recorrido>) => {
              this.onChangeRecorridos(recorridos);
            }}
            onChangeValidation={(valido: boolean) => {
              this.onRecorridosValidationChange(valido);
            }}
          />
          <Button
            onClick={() => {
              this.onSubmit();
            }}
            disabled={!direccionValida || !franjasHorariasValidas || !recorridosValidos}
          >
            Guardar dirección
          </Button>
        </Flex>
      </Box>
    );
  };
}
