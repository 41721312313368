import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import ManejadorErroresHTTP from "../../../../compartido/utils/ManejadorErroresHTTP";
import CargaLoad from "../load/CargaLoad";
import { CargamentoLectura,CANALES_WEB_SOCKET, RepartoDiarioService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  idRepartoDiario: number;
}

interface State {
  descargaFormulario: CargamentoLectura;
  descargaValida: boolean;
}

export default class DescargaNew extends Component<Props, State> {

  private repartoDiarioService:RepartoDiarioService;

  constructor(props: Props) {
    super(props);
    this.state = {
      descargaFormulario: this.nuevaDescarga(),
      descargaValida: false,
    };

    this.repartoDiarioService =  new RepartoDiarioService(Global.UsuarioService.getToken()!);

  }

  nuevaDescarga = (): CargamentoLectura => {
    return {
      id: -1,
      cargas_maquina_simbolica: [],
      cargas_bulto_producto: [],
      cargas_producto: [],
      fecha_creacion: new Date(),
      fecha_modificacion: new Date(),
      es_descarga: false,
      momento_realizacion: null,
      reparto_diario: this.props.idRepartoDiario,
    };
  };

  onChange = (descarga: CargamentoLectura) => {
    this.setState({ descargaFormulario: descarga });
  };

  onValidezChange = (validez: boolean) => {
    this.setState({ descargaValida: validez });
  };

  guardarCambios = async () => {
    try {
      await this.repartoDiarioService.crearDescarga(this.props.idRepartoDiario, this.state.descargaFormulario);
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS, {
        idRepartoDiario: this.props.idRepartoDiario,
      });
      this.setState({ descargaFormulario: this.nuevaDescarga() });
      Swal.fire({
        icon: "success",
        text: "Datos guardados con éxito",
      });
    } catch (error) {
      const msjError = ManejadorErroresHTTP.getMensajeError(error);
      Swal.fire({
        icon: "error",
        text: "Ocurrió un error - " + msjError,
      });
    }
  };

  render() {
    const { descargaFormulario, descargaValida } = this.state;

    return (
      <div>
        <CargaLoad carga={descargaFormulario} onChange={this.onChange} onValidezChange={this.onValidezChange} />
        <Button
          variant="success"
          style={{ marginTop: "1rem" }}
          disabled={!descargaValida}
          onClick={this.guardarCambios}
        >
          Guardar
        </Button>
      </div>
    );
  }
}
