import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Flex from "../../../../../../../libreria/appearance/flex/Flex";
import { Card } from "react-bootstrap";

import { Global } from "../../../../../../../Global";
import { CANALES_WEB_SOCKET, Persona, PersonaService, WebSocketService } from "serviciossaintmichel";



interface Props {
  personaId: number;
}
interface State {
  persona: Persona | null;
}
export default class PersonaResponsableShow extends Component<Props, State> {
  private webSocket: WebSocket | null;

  private personaService:PersonaService;


  constructor(props: Props) {
    super(props);
    this.state = { persona: null };
    this.webSocket = null;

    this.personaService = new PersonaService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.cargarPersona(this.props.personaId);
    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.COMPARTIDO_PERSONAS,
      funcionCallback: this.procesarMensajeCanal,
    });
  }

  componentWillUnmount() {
    this.webSocket?.close();
  }

  procesarMensajeCanal = (e: MessageEvent) => {
    const { personaId } = this.props;
    let payload = JSON.parse(e.data);
    if (payload.message.personaId === personaId) {
      this.cargarPersona(personaId);
    }
  };

  cargarPersona = async (personaId: number) => {
    const persona = await this.personaService.get(personaId);
    this.setState({ persona: persona });
  };

  render() {
    const { persona } = this.state;

    if (persona === null) {
      return null;
    }

    return (
      <Flex container flexDirection="column" alignItems="flex-start" justifyContent="center" marginTop="10px">
        <Card.Text style={{ color: "black" }}>Nombre: {persona.nombre}</Card.Text>
        <Card.Text style={{ color: "black" }}>Apellido: {persona.apellido}</Card.Text>
        <Card.Text style={{ color: "black" }}>DNI: {persona.dni}</Card.Text>
        <Card.Text style={{ color: "black" }}>Fecha Nacimiento: {persona.fecha_nacimiento}</Card.Text>
      </Flex>
    );
  }
}
