import React, { Component } from "react";
import RequestButton from "../../../../compartido/components/request-button/RequestButton";
import "react-datepicker/dist/react-datepicker.css";
import Screen, { ScreenType } from "../../../../compartido/models/Screen";
import Flex from "../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../libreria/containers/box/Box";
import Swal from "sweetalert2";
import RepartoDiarioLoad from "../load/RepartoDiarioLoad";
import {
  CANALES_WEB_SOCKET,
  RepartoDiario,
  RepartoDiarioService,
  WebSocketService,
} from "serviciossaintmichel";
import { Global } from "../../../../Global";

export interface Props {
  onSaveSuccess: Function;
  onExit?: Function;
}

interface State {
  repartoDiario: RepartoDiario;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class RepartoDiarioNew extends Component<Props, State> {
  private repartoDiarioService: RepartoDiarioService;

  constructor(props: Props) {
    super(props);
    this.state = {
      repartoDiario: this.createEmptyRepartoDiario(),
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.repartoDiarioService = new RepartoDiarioService(
      Global.UsuarioService.getToken()!
    );
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  createEmptyRepartoDiario(): RepartoDiario {
    return {
      id: -1,
      reparto: -1,
      fecha_realizacion: null,
      descripcion: null,
      hora_salida: null,
      hora_llegada: null,
      viaje_numero: 0,
      vehiculos: [],
      roles_empleados: [],
    };
  }

  onSave = async () => {
    try {
      const repartoDiario = await this.repartoDiarioService.create(
        this.state.repartoDiario
      );
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
        {
          type: "NEW",
          idRepartoDiario: repartoDiario.id,
        }
      );
      Swal.fire({
        icon: "success",
        text: "Reparto diario creado",
      });
      this.props.onSaveSuccess(repartoDiario.id);
    } catch (error) {
      this.repartoDiarioService.manejarErrorHTTP(error, "Reparto diario");
    }
  };

  onExit = () => {
    if (this.props.onExit !== undefined) this.props.onExit();
  };

  onChange = (repartoDiario: RepartoDiario) => {
    this.setState({ repartoDiario: repartoDiario });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => this.renderizar();

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <RepartoDiarioLoad
            repartoDiario={this.state.repartoDiario}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
            inputsOcultos={["horaSalida", "horaLlegada"]}
            sinRealizacionesEnFecha
          />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Crear"}
            onClick={this.onSave}
          />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen === ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen === ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen === ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
