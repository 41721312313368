import React, { Component } from "react";
import { SelectItem } from "@mantine/core";
import SelectBusqueda, {
  PropsSelectGeneral,
} from "../../../../../compartido/components/select/SelectBusqueda";
import SelectItemUtils from "../../../../../compartido/utils/SelectItemUtils";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import { PropsSelectGenericoItems } from "../../../../../compartido/components/select/PropsSelectGenerico";
import { Pedido } from "serviciossaintmichel";
import FechaUtils from "../../../../../compartido/utils/FechaUtils";

interface State {}

export default class PedidosShowSelect extends Component<
  PropsSelectGenericoItems<Pedido> & PropsSelectGeneral,
  State
> {
  constructor(props: PropsSelectGenericoItems<Pedido> & PropsSelectGeneral) {
    super(props);
    this.state = {};
  }

  getPosicion = (valor: Pedido | null): string | null => {
    if (valor != null)
      return this.props.items.findIndex((e) => e.id === valor.id).toString();
    else return valor;
  };

  getSeleccionado = (indice: number | null) => {
    if (indice != null) this.props.callbackParent(this.props.items[indice]);
    else this.props.callbackParent(indice);
  };

  armarDatosSelect(): SelectItem[] {
    let arreglo: SelectItem[] = [];
    arreglo = this.props.items.map((item, index) => ({
      value: index.toString(),
      label:
        item.id.toString() +
        " - " +
        (item.fuente.fecha
          ? FechaUtils.normalizarFecha(item.fuente.fecha).toLocaleDateString(
              "es-AR"
            )
          : "-") +
        " - " +
        item.cliente.razon_social,
    }));
    return SelectItemUtils.ordenarLabelAlfabeticamente(arreglo);
  }
  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <SelectBusqueda
          {...this.props}
          label={"Pedidos"}
          placeholder="Seleccione Pedido"
          data={this.armarDatosSelect()}
          callbackParent={this.getSeleccionado}
          value={this.getPosicion(this.props.seleccionado)}
        />
      </Flex>
    );
  }
}
