import { Button, Select } from "@mantine/core";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import Flex from "../../../libreria/appearance/flex/Flex";
import Swal from "sweetalert2";
import { Factura, FacturaService } from "serviciossaintmichel";
import { Global } from "../../../Global";

interface Props {}

type ServicioFacturable = "alquiler" | "consumo";

interface State {
  tipoServicio: ServicioFacturable | null;
  periodo: Date | null;
}

export default class FacturadorServicios extends Component<Props, State> {
  private facturaService: FacturaService;
  constructor(props: Props) {
    super(props);
    this.state = {
      tipoServicio: null,
      periodo: null,
    };
    this.facturaService = new FacturaService(Global.UsuarioService.getToken()!);
  }

  generarFacturas = async () => {
    const { tipoServicio, periodo } = this.state;

    if (tipoServicio === null || periodo === null) {
      return;
    }

    const month = periodo.getMonth();
    const year = periodo.getFullYear();

    const eleccion = await Swal.fire({
      icon: "question",
      title: `Generar facturas de ${tipoServicio} para el periodo ${month}/${year}`,
      showDenyButton: true,
      confirmButtonText: "Confirmar",
      denyButtonText: "Cancelar",
    });

    if (eleccion.isConfirmed) {
      try {
        const facturasCreadas = await this.crearFacturas(tipoServicio, month, year);
        await Swal.fire({
          icon: "success",
          text: `Se crearon ${facturasCreadas.length} facturas`,
        });
      } catch (error) {
        this.facturaService.manejarErrorHTTP(error, "Facturacion");
      }
    }
  };

  crearFacturas = async (tipoServicio: ServicioFacturable, month: number, year: number) => {
    let facturasCreadas: Array<Factura> = [];
    if (tipoServicio === "alquiler") {
      facturasCreadas = await this.facturaService.facturarAlquiler(month, year);
    }

    if (tipoServicio === "consumo") {
      facturasCreadas = await this.facturaService.facturarConsumo(month, year);
    }

    return facturasCreadas;
  };

  render() {
    const { tipoServicio, periodo } = this.state;

    return (
      <Flex flexDirection="column" alignItems="center" gap="1rem">
        <div>
          <div>Período a facturar</div>
          <DatePicker
            placeholderText="Seleccione un período"
            selected={periodo}
            onChange={(fecha: Date) => this.setState({ periodo: fecha })}
            dateFormat="MM/yyyy"
            showMonthYearPicker
          />
        </div>
        <Select
          label="Servicio a facturar"
          placeholder="Seleccione un servicio"
          data={["alquiler", "consumo"]}
          value={tipoServicio}
          onChange={(valor: ServicioFacturable) => {
            this.setState({ tipoServicio: valor });
          }}
        />
        <Button disabled={tipoServicio === null || periodo === null} onClick={this.generarFacturas}>
          Generar facturas
        </Button>
      </Flex>
    );
  }
}
