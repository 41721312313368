import React, { Component, ChangeEvent } from "react";
import { Form } from "react-bootstrap";
import { Fuentes } from "../../estilos/estilos";
import Flex from "../flex/Flex";

interface Props {
  callback: Function;
  titulo?: string;
  seleccionado: number | null;
  elementos: Array<string>;
  disabled?: boolean;
}
interface State {}
export default class SelectGenericoNull extends Component<Props, State> {
  onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    let val = event.target.value;
    this.props.callback(val);
  };
  render() {
    return (
      <Flex container alignItems="center" justifyContent="flex-start" width="100%">
        <Form.Group style={{ width: "100%" }}>
          {this.props.titulo ? (
            <Form.Label style={{ fontFamily: Fuentes.Titulos }}>{this.props.titulo}</Form.Label>
          ) : null}
          <Form.Control onChange={this.onChangeHandler} disabled={this.props.disabled} as="select">
            {this.props.elementos.map((elemento, index) => (
              <option
                selected={index === this.props.seleccionado ? true : false}
                id={index.toString()}
                value={index}
                style={{ width: "100%" }}
              >
                {elemento}
              </option>
            ))}
            <option
              selected={this.props.seleccionado === null || this.props.seleccionado === -1 ? true : false}
              id={"-1"}
              value={-1}
              style={{ width: "100%" }}
            >
              {"No Seleccionado"}
            </option>
          </Form.Control>
        </Form.Group>
      </Flex>

      /*
      <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start",width:"100%" }}>
        {this.props.titulo ? (
          <label style={{ marginRight: "1rem" }}>{this.props.titulo}</label>
        ) : null}
        <select
          style={{width:"100%" }}
          className="form-control"
          onChange={this.onChangeHandler}
          disabled={this.props.disabled}
        >
          {this.props.elementos.map((elemento, index) => (
            <option
              selected={index === this.props.seleccionado ? true : false}
              id={index.toString()}
              value={index}
              style={{ width:"100%" }}
            >
              {elemento}
            </option>
          ))}
        </select>
      </div>
      */
    );
  }
}
