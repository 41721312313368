import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import SelectTipoInsumo from "../../TipoInsumo/components/SelectTipoInsumo";
import SelectUnidadInsumo from "../../UnidadInsumo/components/SelectUnidadInsumo";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import { CANALES_WEB_SOCKET, InsumoGet, InsumoPost, InsumoService, TipoInsumoGet, TipoInsumoService, UnidadInsumoGet, UnidadInsumoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";





export interface Props {
  insumoId: number;
  ocultar: Function;
}

interface State {
  insumo: InsumoGet;
  tiposInsumo: Array<TipoInsumoGet>;
  unidadesInsumo: Array<UnidadInsumoGet>;
  alerta: any;
}

export default class InsumoActualizar extends Component<Props, State> {


  private tipoInsumoService:TipoInsumoService;
  private unidadInsumoService:UnidadInsumoService;
  private insumoService:InsumoService;



  constructor(props: Props) {
    super(props);
    this.state = {
      insumo: this.crearInsumoVacio(),
      tiposInsumo: [],
      unidadesInsumo: [],
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_INSUMOS,
      funcionCallback: this.cargarTiposInsumo,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_UNIDAD_INSUMOS,
      funcionCallback: this.cargarUnidadesInsumo,
    });

    this.tipoInsumoService =  new TipoInsumoService(Global.UsuarioService.getToken()!);
    this.unidadInsumoService =  new UnidadInsumoService(Global.UsuarioService.getToken()!);
    this.insumoService =  new InsumoService(Global.UsuarioService.getToken()!);



  }

  componentDidMount() {
    this.cargarInsumo(this.props.insumoId);
    this.cargarTiposInsumo();
    this.cargarUnidadesInsumo();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarInsumo(this.props.insumoId);
      this.cargarTiposInsumo();
      this.cargarUnidadesInsumo();
    }
  }

  crearInsumoVacio(): InsumoGet {
    const tipo_insumo: TipoInsumoGet = {
      id: 0,
      tipo: "",
    };
    const unidad_insumo: UnidadInsumoGet = {
      id: 0,
      unidad: "",
    };
    const insumo: InsumoGet = {
      id: 0,
      nombre: "",
      tipo_insumo: tipo_insumo,
      unidad_insumo: unidad_insumo,
    };
    return insumo;
  }

  cargarInsumo = async (id: number) => {
    const resInsumo = await this.insumoService.get(id).catch((error) => {
      this.insumoService.manejarErrorHTTP(error, "Insumo");
      return null;
    });
    if (resInsumo != null) {
      this.setState({
        insumo: resInsumo,
        alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
      });
    }
  };

  cargarTiposInsumo = async () => {
    const resTiposInsumo = await this.tipoInsumoService.listAll().catch((error) => {
      this.tipoInsumoService.manejarErrorHTTP(error, "Tipo Insumo");
      return null;
    });
    if (resTiposInsumo != null) {
      this.setState({
        tiposInsumo: resTiposInsumo,
      });
    }
  };

  cargarUnidadesInsumo = async () => {
    const resUnidadesInsumo = await this.unidadInsumoService.listAll().catch(
      (error) => {
        this.unidadInsumoService.manejarErrorHTTP(error, "Unidad Insumo");
        return null;
      }
    );
    if (resUnidadesInsumo != null) {
      this.setState({
        unidadesInsumo: resUnidadesInsumo,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  setearNombre = (nombre: string) => {
    let insumo = this.state.insumo;
    insumo.nombre = nombre;
    this.setState({ insumo: insumo });
  };

  setearTipoInsumo = (tipoInsumo: TipoInsumoGet) => {
    let insumo = this.state.insumo;
    insumo.tipo_insumo = tipoInsumo;
    this.setState({ insumo: insumo });
  };
  setearUnidadInsumo = (unidadInsumo: UnidadInsumoGet) => {
    let insumo = this.state.insumo;
    insumo.unidad_insumo = unidadInsumo;
    this.setState({ insumo: insumo });
  };

  handleUpdate() {
    const insumoActualizar: InsumoPost = {
      nombre: this.state.insumo.nombre,
      tipo_insumo: this.state.insumo.tipo_insumo.id,
      unidad_insumo: this.state.insumo.unidad_insumo.id,
    };
    this.insumoService.update(insumoActualizar, this.state.insumo.id)
      .then((result) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_INSUMOS, {
          insumoId: this.state.insumo.id,
        });
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Insumo Actualizado!",
            titulo: "Atención",
            estado: "block",
          },
        });
      })
      .catch((error) => {
        this.insumoService.manejarErrorHTTP(error, "Insumo");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.insumo.nombre && this.state.insumo.tipo_insumo
      ? false
      : true && this.state.insumo.unidad_insumo
      ? false
      : true;
  };

  render() {
    if (
      this.state.insumo.id != 0 &&
      this.state.tiposInsumo.length > 0 &&
      this.state.unidadesInsumo.length > 0
    ) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            <div className={"row"}>
              <div className={"column"}>
                <form
                  className={"form-horizontal"}
                  onSubmit={this.handleSubmit}
                >
                  <div className={"form-group"}>
                    <label htmlFor="nombre">Nombre</label>
                    <InputCaracteres
                      id="nombre"
                      name="nombre"
                      onChange={this.setearNombre}
                      value={this.state.insumo.nombre}
                      placeholder="Ingrese Nombre de Insumo"
                    />
                  </div>

                  <div className={"form-group"}>
                    <SelectTipoInsumo
                      tiposInsumos={this.state.tiposInsumo}
                      seleccionado={this.state.insumo.tipo_insumo}
                      callbackParent={this.setearTipoInsumo}
                    />
                  </div>
                  <div className={"form-group"}>
                    <SelectUnidadInsumo
                      unidadesInsumos={this.state.unidadesInsumo}
                      seleccionado={this.state.insumo.unidad_insumo}
                      callbackParent={this.setearUnidadInsumo}
                    />
                  </div>
                  <div
                    className={"form-group"}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button
                      ref="boton"
                      type="submit"
                      className={"btn btn-info"}
                      disabled={this.disableButton()}
                    >
                      {"Actualizar"}
                    </button>
                    <button
                      className={"btn btn-outline-secondary"}
                      onClick={this.ocultar}
                    >
                      Ocultar
                    </button>
                  </div>
                  <div className={"form-group"}>
                    <Alerta
                      tipo={this.state.alerta.tipo}
                      titulo={this.state.alerta.titulo}
                      mensaje={this.state.alerta.mensaje}
                      estado={this.state.alerta.estado}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
