import React, { Component } from "react";
import { CANALES_WEB_SOCKET, CuponLectura, Factura, FacturaService, WebSocketService } from "serviciossaintmichel";
import { Divider, Loader } from "@mantine/core";
import Screen, { ScreenType } from "../../../compartido/models/Screen";
import { Global } from "../../../Global";
import Flex from "../../../libreria/appearance/flex/Flex";
import Box from "../../../libreria/containers/box/Box";
import H from "../../../libreria/message/title/H";
import FechaDisplay from "../../../compartido/components/fecha-display/FechaDisplay";
import {
  FacturaMaquina,
  FacturaProducto,
} from "serviciossaintmichel/lib/apisaintmichel/ventas/facturas/models/Factura";

export interface Props {
  facturaId: number;
}

interface State {
  factura: Factura | null;
  screen: ScreenType;
  valid: boolean;
}

interface Message {
  type: string;
  idFactura: number;
}

export default class FacturaShow extends Component<Props, State> {
  private facturaService: FacturaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      factura: null,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
      valid: false,
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_FACTURAS,
      funcionCallback: this.onChannelMesagge,
    });

    this.facturaService = new FacturaService(Global.UsuarioService.getToken()!);
  }

  async componentDidMount() {
    this.setFactura(this.props.facturaId);
  }

  setFactura = async (id: number) => {
    const factura = await this.facturaService.get(this.props.facturaId);
    this.setState({
      factura: factura,
    });
  };

  onChannelMesagge = async (e: MessageEvent) => {
    const message: Message = JSON.parse(e.data).message;

    if (message.idFactura === this.props.facturaId) {
      this.setFactura(this.props.facturaId);
    }
  };

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  renderizarPhone = () => (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex flexDirection="column" width="60%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex flexDirection="column" width="50%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderProductosFactura = (facturasProducto: Array<FacturaProducto>) => {
    if (facturasProducto.length === 0) {
      return <div>Sin productos</div>;
    }

    return (
      <ul>
        {facturasProducto.map((facturaProducto: FacturaProducto) => (
          <li>
            {`${facturaProducto.producto.nombre} - ${facturaProducto.cantidad} unidades - ${facturaProducto.bonificados} bonificadas - $${facturaProducto.precio}`}
          </li>
        ))}
      </ul>
    );
  };

  renderMaquinasFactura = (facturasMaquina: Array<FacturaMaquina>) => {
    if (facturasMaquina.length === 0) {
      return <div>Sin máquinas</div>;
    }

    return (
      <ul>
        {facturasMaquina.map((facturaMaquina: FacturaMaquina) => (
          <li>{`${facturaMaquina.maquina.nombre} - ${facturaMaquina.cantidad} unidades - $${facturaMaquina.precio}`}</li>
        ))}
      </ul>
    );
  };

  renderCuponesFactura = (cupones: Array<CuponLectura>) => {
    if (cupones.length === 0) {
      return <div>Sin cupones</div>;
    }

    return (
      <ul>
        {cupones.map((cupon: CuponLectura) => (
          <li>{`Cupón ID ${cupon.id} - ${cupon.descuento.nombre}`}</li>
        ))}
      </ul>
    );
  };

  renderizar = () => {
    const { factura } = this.state;

    if (factura === null) {
      return null;
    }

    return (
      <Box padding="20px">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <H size={3} texto={`Detalle factura ${factura.id}`} />

          <Divider label="Datos básicos" size="md" style={{ margin: "1rem 0" }} />
          <Flex flexDirection="column" width="100%" alignItems="flex-start">
            <strong>Tipo: {factura.tipo}</strong>
            <strong>Número: {factura.numero_factura}</strong>
            <strong>
              Fecha de emisión:
              <FechaDisplay fecha={factura.fecha_emision} />
            </strong>
            <strong>Punto de venta: {factura.punto_venta}</strong>
            <strong>CAE: {factura.cae ?? "-"}</strong>
            <strong>Vencimiento CAE: {factura.vencimiento_cae ?? "-"}</strong>
            <strong>Estado en AFIP: {factura.estado_afip}</strong>
            <strong>Estado del cobro: {factura.estado_cobro}</strong>
            <strong>Monto total: ${factura.monto_total}</strong>
          </Flex>
          <Divider label="Datos del destinatario" size="md" style={{ margin: "1rem 0" }} />
          <Flex flexDirection="column" width="100%" alignItems="flex-start">
            <strong>Razón social: {factura.razon_social}</strong>
            <strong>CUIT: {factura.cuit}</strong>
            <strong>Condición IVA: {factura.condicion_iva.tipo}</strong>
            <strong>Calle: {factura.calle}</strong>
            <strong>Número: {factura.numero}</strong>
            <strong>
              Localidad: {factura.localidad.nombre} - {factura.localidad.partido.nombre} -{" "}
              {factura.localidad.partido.provincia.nombre}
            </strong>
          </Flex>
          <Divider label="Productos" size="md" style={{ margin: "1rem 0" }} />
          <Flex flexDirection="column" width="100%" alignItems="flex-start">
            {this.renderProductosFactura(factura.producto)}
          </Flex>
          <Divider label="Máquinas" size="md" style={{ margin: "1rem 0" }} />
          <Flex flexDirection="column" width="100%" alignItems="flex-start">
            {this.renderMaquinasFactura(factura.maquina)}
          </Flex>
          <Divider label="Cupones aplicados" size="md" style={{ margin: "1rem 0" }} />
          <Flex flexDirection="column" width="100%" alignItems="flex-start">
            {this.renderCuponesFactura(factura.cupones_aplicados)}
          </Flex>
        </Flex>
      </Box>
    );
  };

  render() {
    const { factura } = this.state;

    if (factura === null) {
      return <Loader />;
    }

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.screen === ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen === ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen === ScreenType.TABLET ? this.renderizarTablet() : null}
      </Flex>
    );
  }
}
