import React, { Component } from "react";
import * as Icon from "react-bootstrap-icons";
import Flex from "../../../../libreria/appearance/flex/Flex";

export interface Props {
  criterio: "ASC" | "DESC" | null;
  onCriterioClicked: Function;
}
interface State {}

export default class BotonOrden extends Component<Props, State> {
  render() {
    const { criterio, onCriterioClicked } = this.props;
    return (
      <Flex flexDirection="column" width="auto" gap={"0.2rem"}>
        <Icon.ArrowUpSquareFill
          style={{ color: criterio === "ASC" ? "#0C71C1" : "black", cursor: "pointer" }}
          onClick={() => {
            onCriterioClicked(criterio === "ASC" ? null : "ASC");
          }}
        />
        <Icon.ArrowDownSquareFill
          style={{ color: criterio === "DESC" ? "#0C71C1" : "black", cursor: "pointer" }}
          onClick={() => {
            onCriterioClicked(criterio === "DESC" ? null : "DESC");
          }}
        />
      </Flex>
    );
  }
}
