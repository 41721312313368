import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
import Recorridos from "./components/Recorridos";

export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];

  if (Global.UsuarioService.getPermisos().Distribucion.Recorridos.Recorrido.hasAccess()) {
    aux.push({
      name: "Recorrido",
      path: SeccionRecorrido.pathBase + "/recorrido",
      component: Recorridos,
    });
  }
  return aux;
}

export default class SeccionRecorrido extends Component<Props> {
  static pathBase = "/distribucion/seccion-recorridos";
  render() {
    return <Seccion rutasNavbar={getRutasAccesibles()} tituloNavbar={"Sección Recorridos"} />;
  }
}
