import React, { Component } from "react";
import { EmpleadoGet, EmpleadoService } from "serviciossaintmichel";
import CheckBox from "../../../../../../compartido/components/checkbox/CheckBox";
import Flex from "../../../../../../compartido/components/flex/Flex";
import { Global } from "../../../../../../Global";
import EmpleadosShow, {
  Version,
} from "../../../../../seccion-personal/empleados/components/EmpleadosShow";

interface Props {
  onChangeEmpleado: Function;
  empleado: number | null;
  empleados?: Array<EmpleadoGet>;
  estado: boolean;
  onChangeEstado: Function;
}
interface State {
  seleccionado: EmpleadoGet | null;
  empleados: Array<EmpleadoGet>;
  estado: boolean;
}

export default class CheckBoxSelectEmpleado extends Component<Props, State> {
  private empleadoService: EmpleadoService;
  constructor(props: Props) {
    super(props);
    this.state = {
      empleados: this.props.empleados ? this.props.empleados : [],
      seleccionado: this.encontrarEmpleado(this.props.empleado),
      estado: this.props.estado,
    };
    this.empleadoService = new EmpleadoService(
      Global.UsuarioService.getToken()!
    );
  }

  componentDidMount = async () => {
    const [empleados] = await Promise.all([this.cargarEmpleados()]);
    if (empleados != null)
      this.setState({
        empleados: empleados,
      });
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    if (prevState.empleados !== this.state.empleados) {
      this.setState({
        seleccionado: this.encontrarEmpleado(this.props.empleado),
      });
    }
  };

  cargarEmpleados = async () => {
    return await this.empleadoService.listAll().catch((error) => {
      this.empleadoService.manejarErrorHTTP(error, "Empleado");
    });
  };

  encontrarEmpleado(valor: number | null) {
    if (valor != null) {
      const posicion = this.props.empleados
        ? this.props.empleados.findIndex((i) => i.id === valor)
        : this.state
        ? this.state.empleados.findIndex((i) => i.id === valor)
        : -1;
      return posicion != -1
        ? this.props.empleados
          ? this.props.empleados[posicion]
          : this.state.empleados[posicion]
        : null;
    } else return null;
  }

  onChangeEmpleado = (empleado: EmpleadoGet | null) => {
    this.props.onChangeEmpleado(empleado);
    this.setState({ seleccionado: empleado });
  };

  onChangeEstado = (estado: boolean) => {
    this.setState({ estado: estado });
    this.props.onChangeEstado(estado);
  };

  setEstado = (estado: boolean) => {
    this.setState({ estado: estado });
  };

  render() {
    const { empleados, empleado } = this.props;
    const empleadosState = this.state.empleados;

    return (
      <Flex
        container
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        <CheckBox
          label={"Personal"}
          estado={this.props.estado ? this.props.estado : false}
          onChange={this.onChangeEstado}
        />

        {this.state.estado ? (
          <EmpleadosShow
            version={Version.SELECT}
            propsSelect={{
              callbackParent: this.onChangeEmpleado,
              seleccionado: this.state.seleccionado,
              placeholder: "Seleccione una Persona",
              sinTitulo: true,
            }}
          />
        ) : null}
      </Flex>
    );
  }
}
