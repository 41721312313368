import React, { Component } from "react";
import { EmpleadoGet, EmpleadoService, RolEmpleado } from "serviciossaintmichel";
import { Global } from "../../../../Global";

export interface Props {
  rolEmpleado: RolEmpleado;
}

interface State {
  empleado: EmpleadoGet | null;
}

export default class RolEmpleadoShow extends Component<Props, State> {

  private empleadoService:EmpleadoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      empleado: null,
    };

    this.empleadoService =  new EmpleadoService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    const empleado = await this.empleadoService.get(this.props.rolEmpleado.empleado);
    this.setState({ empleado: empleado });
  }

  render() {
    const { rolEmpleado } = this.props;
    const { empleado } = this.state;
    return (
      <div>
        <div>{`${empleado?.persona.nombre} ${empleado?.persona.apellido} - ${rolEmpleado.rol}`}</div>
      </div>
    );
  }
}
