import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";

import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import { CANALES_WEB_SOCKET, ElementoComputacionGet, ElementoComputacionPost, ElementoComputacionService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";

export interface Props {
  elementoComputacionId: number;
  ocultar: Function;
}

interface State {
  elementoComputacion: ElementoComputacionGet;
  alerta: any;
}

export default class ElementoComputacionActualizar extends Component<
  Props,
  State
> {

  private elementoComputacionService:ElementoComputacionService;

  constructor(props: Props) {
    super(props);
    this.state = {
      elementoComputacion: this.crearElementoComputacionVacio(),
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };

    this.elementoComputacionService =  new ElementoComputacionService(Global.UsuarioService.getToken()!);
  }

  componentDidMount() {
    this.cargarElementoComputacion(this.props.elementoComputacionId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarElementoComputacion(this.props.elementoComputacionId);
    }
  }

  crearElementoComputacionVacio(): ElementoComputacionGet {
    const elementoComputacion: ElementoComputacionGet = {
      id: 0,
      nombre: "",
      marca: "",
      descripcion: "",
      proveedores: [],
    };
    return elementoComputacion;
  }

  cargarElementoComputacion = async (id: number) => {
    const resElementoComputacion = await this.elementoComputacionService.get(
      id
    ).catch((error) => {
      this.elementoComputacionService.manejarErrorHTTP(
        error,
        "Elemento Computación"
      );
      return null;
    });
    if (resElementoComputacion != null) {
      this.setState({
        elementoComputacion: resElementoComputacion,
      });
    }
  };

  ocultar = () => {
    this.props.ocultar();
  };

  setearNombre = (nombre: string) => {
    let elementoComputacion = this.state.elementoComputacion;
    elementoComputacion.nombre = nombre;
    this.setState({ elementoComputacion: elementoComputacion });
  };
  setearMarca = (marca: string) => {
    let elementoComputacion = this.state.elementoComputacion;
    elementoComputacion.marca = marca;
    this.setState({ elementoComputacion: elementoComputacion });
  };
  setearDescripcion = (descripcion: string) => {
    let elementoComputacion = this.state.elementoComputacion;
    elementoComputacion.descripcion = descripcion;
    this.setState({ elementoComputacion: elementoComputacion });
  };

  handleUpdate() {
    const elementoComputacionActualizar: ElementoComputacionPost = {
      nombre: this.state.elementoComputacion.nombre,
      marca: this.state.elementoComputacion.marca,
      descripcion: this.state.elementoComputacion.descripcion,
    };
    this.elementoComputacionService.update(
      elementoComputacionActualizar,
      this.state.elementoComputacion.id
    )
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_ELEMENTOS_COMPUTACION,
          { elementoComputacionId: this.state.elementoComputacion.id }
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Elemento de Computacion Actualizado!",
            titulo: "Atención",
            estado: "block",
          },
        });
      })
      .catch((error) => {
        this.elementoComputacionService.manejarErrorHTTP(
          error,
          "Elemento Computación"
        );
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.elementoComputacion.nombre &&
      this.state.elementoComputacion.marca &&
      this.state.elementoComputacion.descripcion
      ? false
      : true;
  };

  render() {
    if (this.state.elementoComputacion.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            <div className={"row"}>
              <div className={"column"}>
                <form
                  className={"form-horizontal"}
                  onSubmit={this.handleSubmit}
                >
                  <div className={"form-group"}>
                    <label htmlFor="nombre">Nombre</label>
                    <InputCaracteres
                      id="nombre"
                      name="nombre"
                      onChange={this.setearNombre}
                      value={this.state.elementoComputacion.nombre}
                      placeholder="Ingrese Nombre de Elemento de Computacion"
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="marca">Marca</label>
                    <InputCaracteres
                      id="marca"
                      name="marca"
                      onChange={this.setearMarca}
                      value={this.state.elementoComputacion.marca}
                      placeholder="Ingrese Marca"
                    />
                  </div>
                  <div className={"form-group"}>
                    <label htmlFor="descripcion">Descripcion</label>
                    <InputCaracteres
                      id="descripcion"
                      name="descripcion"
                      onChange={this.setearDescripcion}
                      value={this.state.elementoComputacion.descripcion}
                      placeholder="Ingrese Descripcion"
                    />
                  </div>
                  <div
                    className={"form-group"}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button
                      ref="boton"
                      type="submit"
                      className={"btn btn-info"}
                      disabled={this.disableButton()}
                    >
                      {"Actualizar"}
                    </button>
                    <button
                      className={"btn btn-outline-secondary"}
                      onClick={this.ocultar}
                    >
                      Ocultar
                    </button>
                  </div>
                  <div className={"form-group"}>
                    <Alerta
                      tipo={this.state.alerta.tipo}
                      titulo={this.state.alerta.titulo}
                      mensaje={this.state.alerta.mensaje}
                      estado={this.state.alerta.estado}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
