class Utils {
  esNumero(cadena: string): boolean {
    if (cadena.match(/^-{0,1}\d+$/)) {
      //valid integer (positive or negative)
      return true;
    } else if (cadena.match(/^\d+\.\d+$/)) {
      //valid float
      return true;
    } else {
      //not valid number
      return false;
    }
  }
  armarParametrosURL<T>(paramsURL: T): string {
    let parametros = "/?";
    if (paramsURL != null) {
      Object.entries(paramsURL).forEach(([key, value]) => {
        if (value != "" && !Number.isNaN(value))
          parametros = parametros + key + "=" + value + "&";
      });
    }
    return parametros;
  }
  normalizarCadenas(cadena: string, caracterDivisor: string): string {
    //input: tipo_producto
    //Input:    tiPO___prodUCTO
    //Result:Tipo Prod4ucto
    let cadenaNueva: string = "";
    cadena = cadena.trim();
    let arreglos = cadena.split(caracterDivisor);
    const arregloNormalizado = arreglos.map((palabra) => {
      let minusculas = palabra.trim().toLowerCase();
      return minusculas.charAt(0).toUpperCase() + minusculas.slice(1);
    });
    arregloNormalizado.forEach((value) => {
      if (value != "") cadenaNueva = cadenaNueva + value + " ";
    });
    return cadenaNueva.trim();
  }
}
export default new Utils();
