import React, { Component } from "react";
import FechaUtils from "../../utils/FechaUtils";

export interface Props {
  fecha: Date | string | null;
  incluirTiempo?: boolean;
}

interface State {}

export default class FechaDisplay extends Component<Props, State> {
  render() {
    const { fecha, incluirTiempo } = this.props;
    if (fecha === null) {
      return <span>-</span>;
    }

    const fechaNormalizada = FechaUtils.normalizarFecha(fecha);
    let contenido = fechaNormalizada.toLocaleDateString("es-AR");

    if (incluirTiempo) {
      contenido = contenido.concat(
        " - " + fechaNormalizada.toLocaleTimeString("es-AR", { hour: "2-digit", minute: "2-digit" })
      );
    }

    return <span>{contenido}</span>;
  }
}
