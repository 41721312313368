import React, { Component } from "react";
import { Button, Table, Form, Col } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import BotonOrden from "../../../administracion/seccion-clientes/clientes/components/BotonOrden";
import { CriterioOrden } from "../../../compartido/models/CriterioOrden";
import RequestButton from "../../../libreria/action/request-button/RequestButton";
import Flex from "../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../libreria/data/input/Input";

import {
  CANALES_WEB_SOCKET,
  WebSocketService,
  ListaPrecio,
  ListaPrecioService,
} from "serviciossaintmichel";
import { Global } from "../../../Global";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
}

interface State {
  listaPrecio: Array<ListaPrecio> | null;
  nombre: string | null;
  descripcion: string | null;
  criterioOrden: CriterioOrden | null;
  limite: number;
}

const ordenInicial: CriterioOrden = { campo: "fecha", modo: "DESC" };

export default class ListasPreciosShowTable extends Component<Props, State> {
  private listaPrecioService: ListaPrecioService;

  private webSocket: WebSocket | null;
  constructor(props: Props) {
    super(props);
    this.state = {
      listaPrecio: null,
      nombre: null,
      descripcion: null,
      criterioOrden: ordenInicial,
      limite: 5,
    };
    this.webSocket = null;

    this.listaPrecioService = new ListaPrecioService(
      Global.UsuarioService.getToken()!
    );
  }

  componentWillUnmount = () => {
    this.webSocket?.close();
  };

  componentDidMount = () => {
    this.setListasPrecios();
    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_LISTAS_PRECIOS,
      funcionCallback: this.setListasPrecios,
    });
  };

  setListasPrecios = async () => {
    const { nombre, descripcion, criterioOrden, limite } = this.state;

    try {
      let orden = criterioOrden !== null ? criterioOrden : undefined;

      const filtros = {
        nombre: nombre,
        descripcion: descripcion,
      };
      const listasPrecios: Array<ListaPrecio> =
        await this.listaPrecioService.getListasPrecios(filtros, orden, limite);

      this.setState({ listaPrecio: listasPrecios });
    } catch (error) {
      this.listaPrecioService.manejarErrorHTTP(error, "Lista Precio");
    }
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      prevState.criterioOrden !== this.state.criterioOrden ||
      prevState.limite !== this.state.limite
    ) {
      this.setListasPrecios();
    }
  };

  criteriosOrdenIguales = (
    criterioA: CriterioOrden | null,
    criterioB: CriterioOrden | null
  ): boolean => {
    if (criterioA === null || criterioB === null) {
      return false;
    }
    return (
      criterioA.campo === criterioB.campo && criterioA.modo === criterioB.modo
    );
  };

  seleccionarCriterio = (criterio: CriterioOrden | null) => {
    const { criterioOrden } = this.state;

    if (
      criterioOrden === null ||
      !this.criteriosOrdenIguales(criterioOrden, criterio)
    ) {
      this.setState({ criterioOrden: criterio });
    } else {
      this.setState({ criterioOrden: null });
    }
  };

  getCriterioHandler = (campo: string) => {
    return (modo: "ASC" | "DESC" | null) => {
      const criterioOrden = modo ? { modo: modo, campo: campo } : null;
      this.seleccionarCriterio(criterioOrden);
    };
  };

  getControlCriterio = (campo: string) =>
    this.state.criterioOrden?.campo === campo
      ? this.state.criterioOrden.modo
      : null;

  manejadorScroll = () => {
    const divTabla = document.getElementById("contenedor-scroll");
    if (divTabla === null) {
      return;
    }
    let movimientoTope = divTabla.scrollHeight - divTabla.clientHeight;

    if (movimientoTope === divTabla.scrollTop) {
      this.aumentarLimite();
    }
  };

  aumentarLimite = () => {
    this.setState((prevState) => ({ limite: prevState.limite + 5 }));
  };

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };

  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };

  render = () => {
    const { listaPrecio, nombre, descripcion } = this.state;
    const minColWidth = "250px";

    if (listaPrecio === null) {
      return <div>Cargando...</div>;
    }

    return (
      <>
        <Form
          style={{
            padding: "1rem",
            backgroundColor: "gainsboro",
            borderRadius: "5px",
            marginBottom: "1rem",
          }}
        >
          <Form.Row className="align-items-center" style={{ rowGap: "1rem" }}>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <Form.Label as="strong">Nombre</Form.Label>
                <Input
                  data={nombre}
                  placeholder={"Ingrese nombre"}
                  onChange={(nombre: string) => {
                    this.setState({ nombre: nombre });
                  }}
                  type={InputTypes.Text}
                />
                <Input
                  data={descripcion}
                  placeholder={"Ingrese descripcion"}
                  onChange={(descripcion: string) => {
                    this.setState({ descripcion: descripcion });
                  }}
                  type={InputTypes.Text}
                />
              </Flex>
            </Col>
            <Col style={{ minWidth: minColWidth }}>
              <Flex flexDirection="column" alignItems="center">
                <RequestButton
                  onClick={() => this.setListasPrecios()}
                  texto="Buscar"
                  icon={<Icon.Search />}
                />
              </Flex>
            </Col>
          </Form.Row>
        </Form>

        <div
          style={{ maxHeight: "350px", overflowY: "scroll" }}
          onScroll={this.manejadorScroll}
          id="contenedor-scroll"
        >
          <Table striped bordered>
            <thead>
              <tr>
                <th
                  className="text-center"
                  style={{ position: "sticky", top: "0" }}
                >
                  #
                </th>
                <th
                  className="text-center"
                  style={{ position: "sticky", top: "0" }}
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="nowrap"
                    gap="0.5rem"
                  >
                    <span>Id </span>
                    <BotonOrden
                      criterio={this.getControlCriterio("id")}
                      onCriterioClicked={this.getCriterioHandler("id")}
                    />
                  </Flex>
                </th>
                <th
                  className="text-center"
                  style={{ position: "sticky", top: "0" }}
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="nowrap"
                    gap="0.5rem"
                  >
                    <span>Nombre</span>
                    <BotonOrden
                      criterio={this.getControlCriterio("nombre")}
                      onCriterioClicked={this.getCriterioHandler("nombre")}
                    />
                  </Flex>
                </th>
                <th
                  className="text-center"
                  style={{ position: "sticky", top: "0" }}
                ></th>
                <th
                  className="text-center"
                  style={{ position: "sticky", top: "0" }}
                ></th>
              </tr>
            </thead>

            <tbody>
              {listaPrecio.map((listaPrecio, idx) => (
                <tr>
                  <td className="text-center">{idx + 1}</td>
                  <td className="text-center">{listaPrecio.id}</td>
                  <td className="text-center">{listaPrecio.nombre}</td>
                  <td className="text-center">
                    <Button
                      onClick={() => {
                        this.onView(listaPrecio.id);
                      }}
                    >
                      Ver detalle
                    </Button>
                  </td>
                  <td className="text-center">
                    <Button
                      onClick={() => {
                        this.onUpdate(listaPrecio.id);
                      }}
                    >
                      Actualizar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  };
}
