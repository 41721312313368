import { Divider } from "@mantine/core";
import _ from "lodash";
import Swal from "sweetalert2";
import React, { Component } from "react";
import { Table, Button } from "react-bootstrap";
import FechaDisplay from "../../../../compartido/components/fecha-display/FechaDisplay";
import RequestButton from "../../../../libreria/action/request-button/RequestButton";
import Box from "../../../../libreria/containers/box/Box";
import VentaVisitaLoad from "../load/VentaVisitaLoad";
import ManejadorErroresHTTP from "../../../../compartido/utils/ManejadorErroresHTTP";
import Flex from "../../../../libreria/appearance/flex/Flex";
import H from "../../../../libreria/message/title/H";

import {  RepartoDiarioService,  VentaVisitaLectura, VisitaService, VentaProductoLectura, VentaBultoProductoLectura, VentaComboRetornableLectura, VentaPromocionLectura, VentaMaquinaLectura, VentaVisitaEscritura } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  visitaId: number;
  repartoDiarioId: number;
}

interface State {
  venta: VentaVisitaLectura | null;
  ventaFormulario: VentaVisitaLectura | null;
  ventaFormularioValida: boolean;
  cargando: boolean;
}

export default class VentaVisitaUpdate extends Component<Props, State> {
  private webSockets: Array<WebSocket>;
  private repartoDiarioService:RepartoDiarioService;
  private visitaService:VisitaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      venta: null,
      ventaFormulario: null,
      ventaFormularioValida: false,
      cargando: true,
    };
    this.webSockets = [];
    this.repartoDiarioService =  new RepartoDiarioService(Global.UsuarioService.getToken()!);
    this.visitaService =  new VisitaService(Global.UsuarioService.getToken()!);

  }

  cargarVisita = async () => {
    try {
      const visita = await this.repartoDiarioService.getVisita(this.props.repartoDiarioId, this.props.visitaId);
      this.setState({ venta: visita.venta });
    } catch (error) {
      this.visitaService.manejarErrorHTTP(error);
    } finally {
      this.setState({ cargando: false });
    }
  };

  componentDidMount = async () => {
    await this.cargarVisita();
    this.actualizarValidezVentaFormulario();
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!_.isEqual(prevState.ventaFormulario, this.state.ventaFormulario)) {
      this.actualizarValidezVentaFormulario();
    }
  };

  componentWillUnmount = () => {
    this.webSockets.forEach((ws: WebSocket) => ws.close());
  };

  renderVentasProductos = (productosVendidos: Array<VentaProductoLectura>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Producto</th>
            <th>Vendidos</th>
            <th>Bonificados</th>
            <th>Vacíos</th>
          </tr>
        </thead>
        <tbody>
          {productosVendidos.map((ventaProducto) => (
            <tr key={ventaProducto.producto.id}>
              <td>{ventaProducto.producto.nombre}</td>
              <td>{ventaProducto.vendidos}</td>
              <td>{ventaProducto.bonificados}</td>
              <td>{ventaProducto.vacios}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderVentasBultosProductos = (bultosProductosVendidos: Array<VentaBultoProductoLectura>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Bulto Producto</th>
            <th>Vendidos</th>
            <th>Bonificados</th>
          </tr>
        </thead>
        <tbody>
          {bultosProductosVendidos.map((ventaBultoProducto) => (
            <tr key={ventaBultoProducto.bulto_producto.id}>
              <td>{ventaBultoProducto.bulto_producto.nombre}</td>
              <td>{ventaBultoProducto.vendidos}</td>
              <td>{ventaBultoProducto.bonificados}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderVentasCombosRetornables = (combosRetornablesVendidos: Array<VentaComboRetornableLectura>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Combo Retornable</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>
          {combosRetornablesVendidos.map((ventaComboRetornable) => (
            <tr key={ventaComboRetornable.combo_retornable.id}>
              <td>{ventaComboRetornable.combo_retornable.nombre}</td>
              <td>{ventaComboRetornable.cantidad}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderVentasPromociones = (promocionesVendidas: Array<VentaPromocionLectura>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Promoción</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>
          {promocionesVendidas.map((ventaPromocion) => (
            <tr key={ventaPromocion.promocion.id}>
              <td>{ventaPromocion.promocion.nombre}</td>
              <td>{ventaPromocion.cantidad}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderVentasMaquinas = (maquinasVendidas: Array<VentaMaquinaLectura>) => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Máquina</th>
            <th>Vendidas</th>
            <th>Bonificadas</th>
          </tr>
        </thead>
        <tbody>
          {maquinasVendidas.map((ventaMaquina) => (
            <tr key={ventaMaquina.maquina_simbolica.id}>
              <td>{ventaMaquina.maquina_simbolica.nombre}</td>
              <td>{ventaMaquina.vendidas}</td>
              <td>{ventaMaquina.bonificadas}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderVenta = (venta: VentaVisitaLectura) => {
    return (
      <div>
        <Box padding="1rem">
          <Flex justifyContent="space-between">
            <H size={3} texto="Venta" />
            <Button
              onClick={() => {
                this.setState({ ventaFormulario: _.cloneDeep(venta) });
              }}
            >
              Modificar
            </Button>
          </Flex>

          <div>
            <span>Creada: </span>
            <FechaDisplay fecha={venta.fecha_creacion} />
          </div>
          <div>
            <span>Última modificación: </span>
            <FechaDisplay fecha={venta.fecha_modificacion} />
          </div>

          <Divider label="Productos" labelPosition="center" variant="dashed" />
          {this.renderVentasProductos(venta.productos_vendidos)}

          <Divider label="Bultos de productos" labelPosition="center" variant="dashed" />
          {this.renderVentasBultosProductos(venta.bultos_producto_vendidos)}

          <Divider label="Combos retornables" labelPosition="center" variant="dashed" />
          {this.renderVentasCombosRetornables(venta.combos_retornables_vendidos)}

          <Divider label="Promociones" labelPosition="center" variant="dashed" />
          {this.renderVentasPromociones(venta.promociones_vendidas)}

          <Divider label="Máquinas" labelPosition="center" variant="dashed" />
          {this.renderVentasMaquinas(venta.maquinas_vendidas)}
        </Box>
      </div>
    );
  };

  buildVenta = (): VentaVisitaLectura => {
    return {
      id: -1,
      fecha_creacion: new Date(),
      fecha_modificacion: new Date(),
      pago: null,
      productos_vendidos: [],
      bultos_producto_vendidos: [],
      combos_retornables_vendidos: [],
      promociones_vendidas: [],
      maquinas_vendidas: [],
    };
  };

  actualizarValidezVentaFormulario = () => {
    if (this.state.ventaFormulario === null) {
      return;
    }

    const esValida = this.ventaEsValida(this.state.ventaFormulario);
    this.setState({ ventaFormularioValida: esValida });
  };

  onVentaLoadChange = (ventaLoad: VentaVisitaLectura) => {
    this.setState({ ventaFormulario: ventaLoad });
  };

  ventaEsValida = (venta: VentaVisitaLectura) => {
    return (
      venta.productos_vendidos.length +
        venta.bultos_producto_vendidos.length +
        venta.combos_retornables_vendidos.length +
        venta.promociones_vendidas.length +
        venta.maquinas_vendidas.length >
        0 &&
      venta.productos_vendidos.every((vp) => vp.vendidos + vp.bonificados > 0) &&
      venta.bultos_producto_vendidos.every((vbp) => vbp.vendidos + vbp.bonificados > 0) &&
      venta.combos_retornables_vendidos.every((vc) => vc.cantidad > 0) &&
      venta.promociones_vendidas.every((vp) => vp.cantidad > 0) &&
      venta.maquinas_vendidas.every((vm) => vm.vendidas + vm.bonificadas > 0)
    );
  };

  guardarVenta = async () => {
    const venta = this.state.ventaFormulario;

    if (venta === null) {
      return;
    }

    const ventaEscritura: VentaVisitaEscritura = {
      id: venta.id,
      pago: venta.pago,
      productos_vendidos: venta.productos_vendidos.map((vp) => ({ ...vp, producto: vp.producto.id })),
      bultos_producto_vendidos: venta.bultos_producto_vendidos.map((vbp) => ({
        ...vbp,
        bulto_producto: vbp.bulto_producto.id,
      })),
      combos_retornables_vendidos: venta.combos_retornables_vendidos.map((vc) => ({
        ...vc,
        combo_retornable: vc.combo_retornable.id,
      })),
      promociones_vendidas: venta.promociones_vendidas.map((vp) => ({ ...vp, promocion: vp.promocion.id })),
      maquinas_vendidas: venta.maquinas_vendidas.map((vm) => ({ ...vm, maquina_simbolica: vm.maquina_simbolica.id })),
    };

    try {
      const metodoService = venta.id > 0 ? this.visitaService.actualizarVenta : this.visitaService.crearVenta;
      await metodoService(this.props.visitaId, ventaEscritura);
      Swal.fire({
        icon: "success",
        text: "Datos guardados con éxito",
      });
      await this.cargarVisita();
      this.setState({ ventaFormulario: this.state.venta });
    } catch (error) {
      const msjError = ManejadorErroresHTTP.getMensajeError(error);
      Swal.fire({
        icon: "error",
        text: "Ocurrió un error - " + msjError,
      });
    }
  };

  render() {
    const { venta, ventaFormulario, cargando, ventaFormularioValida } = this.state;

    if (cargando) {
      return <div>Cargando...</div>;
    }

    if (ventaFormulario !== null) {
      return (
        <Box
          padding="1rem"
          showCloseBtn
          onCloseCallback={() => {
            this.setState({ ventaFormulario: null });
          }}
          title={ventaFormulario.id > 0 ? `Modificación de venta` : "Registro de venta"}
        >
          <VentaVisitaLoad ventaVisita={ventaFormulario} onChange={this.onVentaLoadChange} />

          <RequestButton
            propsBoton={{ style: { marginTop: "1rem" }, variant: "success", disabled: !ventaFormularioValida }}
            texto={"Guardar"}
            onClick={this.guardarVenta}
          />
        </Box>
      );
    }

    if (venta !== null) {
      return this.renderVenta(venta);
    }

    return (
      <>
        <div>La visita no posee una venta registrada</div>
        <Button
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
          variant="success"
          onClick={() => {
            this.setState({ ventaFormulario: this.buildVenta() });
          }}
        >
          Registrar venta
        </Button>
      </>
    );
  }
}
