import React, { Component } from "react";
import SelectGenericoNull from "../../../../compartido/components/select/SelectGenericoNull";


import { AlquilerGet,  AlquilerService } from "serviciossaintmichel";
import { Global } from "../../../../Global";


interface Props {
  callbackParent: Function;
  seleccionado: AlquilerGet | null;
  excludeIds?: Array<number>;
  disabled?: boolean;
}

interface State {
  alquileres: Array<AlquilerGet>;
  seleccionado: AlquilerGet | null;
  cargando: boolean;
}

export default class SelectAlquiler extends Component<Props, State> {

  private alquilerService:AlquilerService;

  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado,
      alquileres: [],
      cargando: true,
    };

    this.alquilerService =  new AlquilerService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    try {
      const { excludeIds } = this.props;
      let resultado = await this.alquilerService.listAll();
      if (excludeIds) {
        resultado = resultado.filter(
          (alquiler) => excludeIds.indexOf(alquiler.id) === -1
        );
      }
      this.setState({ alquileres: resultado, cargando: false });
    } catch (error) {
      this.alquilerService.manejarErrorHTTP(error, "Alquiler");
    }
  };

  getPosicion = (
    valor: AlquilerGet,
    alquileres: Array<AlquilerGet>
  ): number => {
    return alquileres.findIndex((i) => i.id === valor.id);
  };

  getSeleccionado = (indice: number) => {
    const { alquileres } = this.state;
    if (indice >= 0) this.props.callbackParent(alquileres[indice]);
    else this.props.callbackParent(null);
  };

  render() {
    const { alquileres, seleccionado, cargando } = this.state;

    if (cargando) {
      return null;
    }

    const posicion = seleccionado
      ? this.getPosicion(seleccionado, alquileres)
      : null;

    return (
      <SelectGenericoNull
        seleccionado={posicion}
        elementos={alquileres.map((i) => i.nombre)}
        callback={this.getSeleccionado}
        disabled={this.props.disabled}
      />
    );
  }
}
