import React, { Component } from "react";
import AlquilerUpdate from "./components/update/AlquilerUpdate";
import AlquilerShow from "./components/show/AlquilerShow";
import AlquileresShow, { Version } from "./components/show/AlquileresShow";
import AlquilerNew from "./components/new/AlquilerNew";
import { Tabs, Tab } from "react-bootstrap";
import Flex from "../../../libreria/appearance/flex/Flex";
import {  CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../Global";

interface Props {}

interface State {
  tabVisible: any;
  disabledActualizar: boolean;
  disabledVer: boolean;
  responsive: boolean;
  idAlquiler: number | null;
  recienCreado: boolean;
}

export default class Alquilers extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista",
      disabledActualizar: true,
      disabledVer: true,
      responsive: window.innerWidth < 700 ? true : false,
      idAlquiler: null,
      recienCreado: false,
    };

    window.addEventListener("resize", this.actualizarDimensiones);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS,
      funcionCallback: this.cargarAlquiler,
    });
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  cargarAlquiler = async (e: MessageEvent) => {};

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  actualizar = (idAlquiler: number) => {
    this.setState({
      tabVisible: "actualizar",
      disabledActualizar: false,
      idAlquiler: idAlquiler,
    });
  };

  ver = (idAlquiler: number) => {
    this.setState({
      tabVisible: "ver",
      disabledVer: false,
      idAlquiler: idAlquiler,
      recienCreado: false,
    });
  };

  ocultarVer = () => {
    this.setState({
      tabVisible: "lista",
      disabledVer: true,
      idAlquiler: null,
    });
  };

  ocultarNuevo = () => {
    this.setState({
      tabVisible: "lista",
    });
  };

  renderizarNuevo = () => {
    if (
      Global.UsuarioService.getPermisos().Administracion.Servicios.Alquiler.canAdd()
    ) {
      return (
        <Tab eventKey="nuevo" title="Nuevo" width="100%">
          {this.state.tabVisible === "nuevo" ? (
            <AlquilerNew
              onExit={this.ocultarNuevo}
              onSaveSuccess={async (idAlquiler: number) => {
                this.setState({ recienCreado: true, idAlquiler: idAlquiler });
                this.actualizar(idAlquiler);
              }}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarLista = () => {
    if (
      Global.UsuarioService.getPermisos().Administracion.Servicios.Alquiler.canView()
    ) {
      return (
        <Tab eventKey="lista" title="Lista" width="100%">
          <AlquileresShow
            version={Version.TABLE}
            onView={this.ver}
            onUpdate={this.actualizar}
          />
        </Tab>
      );
    }
  };

  renderizarActualizar = () => {
    if (
      Global.UsuarioService.getPermisos().Administracion.Servicios.Alquiler.canChange()
    ) {
      return (
        <Tab
          width="100%"
          eventKey="actualizar"
          title="Actualizar"
          disabled={this.state.disabledActualizar}
        >
          {this.state.idAlquiler && this.state.tabVisible === "actualizar" ? (
            <AlquilerUpdate
              id={this.state.idAlquiler}
              //recienCreado={this.state.recienCreado}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarVerDetalle = () => {
    if (
      Global.UsuarioService.getPermisos().Administracion.Servicios.Alquiler.canView()
    ) {
      return (
        <Tab
          width="100%"
          eventKey="ver"
          title="Ver Detalle"
          disabled={this.state.disabledVer}
        >
          {this.state.idAlquiler && this.state.tabVisible === "ver" ? (
            <AlquilerShow id={this.state.idAlquiler} />
          ) : null}
        </Tab>
      );
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey="lista"
      transition={false}
      activeKey={this.state.tabVisible}
      onSelect={this.onSelect}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "flex-start" : "",
      }}
      unmountOnExit={true}
    >
      {this.renderizarNuevo()}
      {this.renderizarLista()}
      {this.renderizarActualizar()}
      {this.renderizarVerDetalle()}
    </Tabs>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <div style={{ display: "block", width: "100%" }}>
          {this.renderizar(this.state.responsive)}
        </div>
      </Flex>
    );
  }
}
