import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import BultosProductoShow, {
  Version,
} from "../../../../elementos/productos/BultoProducto/components/BultosProductoShow";
import { excluirIds } from "../../../../compartido/utils/filtros";
import { CargaBultoProductoLectura, DatosBultoProducto } from "serviciossaintmichel";

export interface Props {
  onSubmitCallback?: Function;
  excluirBultosProducto?: Array<number>;
}

interface State {
  nuevaCargaBultoProducto: CargaBultoProductoLectura;
  bultoProductoSeleccionado: DatosBultoProducto | null;
}

export default class CargaBultoProductoLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getEstadoInicial();
  }

  bultoProductoNuevo = () => {
    return { id: -1, nombre: "" };
  };

  getEstadoInicial = () => ({
    nuevaCargaBultoProducto: {
      bulto_producto: this.bultoProductoNuevo(),
      cantidad: 0,
    },
    bultoProductoSeleccionado: null,
  });

  setBultoProducto = (bultoProd: DatosBultoProducto | null) => {
    const bultoProducto =
      bultoProd !== null ? { id: bultoProd.id, nombre: bultoProd.nombre } : this.bultoProductoNuevo();
    this.setState((prevState) => ({
      nuevaCargaBultoProducto: {
        ...prevState.nuevaCargaBultoProducto,
        bulto_producto: bultoProd ? bultoProducto : this.state.nuevaCargaBultoProducto.bulto_producto,
      },
      bultoProductoSeleccionado: bultoProd,
    }));
  };

  onSubmit = () => {
    const { onSubmitCallback = () => {} } = this.props;
    onSubmitCallback(this.state.nuevaCargaBultoProducto);
    const estadoInicial = this.getEstadoInicial();
    this.setState(estadoInicial);
  };

  validarCarga = (cargaBultoProducto: CargaBultoProductoLectura) => {
    return cargaBultoProducto.bulto_producto.id > 0 && cargaBultoProducto.cantidad > 0;
  };

  render() {
    const { nuevaCargaBultoProducto, bultoProductoSeleccionado } = this.state;
    const filtrosShow = this.props.excluirBultosProducto ? [excluirIds(this.props.excluirBultosProducto)] : undefined;

    return (
      <Flex borderRadius="5px" border="1px solid black" gap="1rem" padding="1rem" alignItems="center">
        <BultosProductoShow
          version={Version.SELECT}
          filtros={filtrosShow}
          propsSelect={{
            callbackParent: this.setBultoProducto,
            seleccionado: bultoProductoSeleccionado,
            label: "Bulto de producto",
            placeholder: "Seleccine un bulto de producto",
          }}
        />
        <div>
          <span>Cantidad</span>
          <Input
            type={InputTypes.Number}
            data={nuevaCargaBultoProducto.cantidad}
            onChange={(data: number) => {
              this.setState((prevState) => ({
                nuevaCargaBultoProducto: { ...prevState.nuevaCargaBultoProducto, cantidad: data },
              }));
            }}
          />
        </div>

        <Button onClick={this.onSubmit} disabled={!this.validarCarga(nuevaCargaBultoProducto)}>
          Añadir
        </Button>
      </Flex>
    );
  }
}
