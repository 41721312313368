import React, { Component } from "react";
import { Timeline } from "@mantine/core";
import FechaDisplay from "../../../../compartido/components/fecha-display/FechaDisplay";
import { CANALES_WEB_SOCKET,  EstadoRepartoDiarioLectura, RepartoDiarioService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";





export interface Props {
  idRepartoDiario: number;
}

interface State {
  estadosRepartoDiario: Array<EstadoRepartoDiarioLectura> | null;
}

export default class RepartoDiarioEstadosShow extends Component<Props, State> {
  private webSockets: Array<WebSocket>;
  private repartoDiarioService:RepartoDiarioService;

  constructor(props: Props) {
    super(props);
    this.state = {
      estadosRepartoDiario: null,
    };
    this.webSockets = [];
    this.repartoDiarioService =  new RepartoDiarioService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    this.setEstadosRepartoDiario();
    this.webSockets.push(
      WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
        funcionCallback: this.onChannelMessage,
      })
    );
  };

  componentWillUnmount = () => {
    this.webSockets.forEach((ws: WebSocket) => ws.close());
  };

  onChannelMessage = async (message: MessageEvent) => {
    const dato = JSON.parse(message.data);
    if (dato.message.idRepartoDiario !== this.props.idRepartoDiario) {
      return;
    }
    this.setEstadosRepartoDiario();
  };

  async setEstadosRepartoDiario() {
    const estados = await this.repartoDiarioService.getEstados(this.props.idRepartoDiario);
    this.setState({ estadosRepartoDiario: estados });
  }

  render() {
    const { estadosRepartoDiario } = this.state;

    if (estadosRepartoDiario === null) {
      return <div>Cargando...</div>;
    }

    if (estadosRepartoDiario.length === 0) {
      return <div>Sin estados registrados</div>;
    }

    return (
      <Timeline bulletSize={20} lineWidth={4} active={estadosRepartoDiario.length}>
        {estadosRepartoDiario.map((estado) => (
          <Timeline.Item title={estado.estado} active>
            <FechaDisplay fecha={estado.fecha_reporte} incluirTiempo />
          </Timeline.Item>
        ))}
      </Timeline>
    );
  }
}
