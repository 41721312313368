import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
import { MaquinaServicioFisicaGet } from "serviciossaintmichel";

export interface Props {
  maquinas: Array<MaquinaServicioFisicaGet>;
}

interface State {}

export default class ListMaquinaServicioFisica extends Component<Props, State> {
  render() {
    const { maquinas } = this.props;
    if (maquinas.length < 1) {
      return <div>Sin máquinas para mostrar</div>;
    }

    return (
      <ListGroup style={{ width: "100%" }}>
        {maquinas.map((maquina) => (
          <ListGroup.Item style={{ textAlign: "center", width: "100%" }}>
            {`${maquina.marca} - ${maquina.numero_serie}`}
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  }
}
