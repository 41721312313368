import { Badge } from "@mantine/core";
import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../libreria/appearance/margin/Margin";
import { Box } from "../../../../../libreria/containers/box/Box";
import Text from "../../../../../libreria/message/text/Text";
import H from "../../../../../libreria/message/title/H";
import EntregasPedidoShow from "./entregas/EntregasPedidoShow";
import FacturaPedidoShow from "./facturas/FacturaPedidoShow";
import FuentePedidoShow from "./fuentes/FuentePedidoShow";
import PagosPedidoShow from "./pagos/PagosPedidoShow";
import ProgramacionesEntregaPedidoShow from "./programaciones/ProgramacionesEntregaPedidoShow";
import VentasPedidoShow from "./ventas/VentaPedidoShow";


import { CANALES_WEB_SOCKET,   WebSocketService,    Pedido, PedidoService, getColorEstado } from "serviciossaintmichel";
import { Global } from "../../../../../Global";




export interface Props {
  idPedido: number;
}

interface State {
  responsive: boolean;
  pedido: Pedido | null;
  cargando: boolean;
}

export default class PedidoShow extends Component<Props, State> {

  private pedidoService:PedidoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      responsive: window.innerWidth < 700 ? true : false,
      pedido: null,
      cargando: true,
    };

    this.pedidoService =  new PedidoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    await this.cargarPedido();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PEDIDOS,
      funcionCallback: this.onChannelMesagge,
    });
    window.addEventListener("resize", this.actualizarDimensiones);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.actualizarDimensiones);
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.pedidoId == this.props.idPedido) {
      await this.cargarPedido();
    }
  };

  cargarPedido = async () => {
    let pedido = await this.pedidoService.get(this.props.idPedido).catch((error) => {
      this.pedidoService.manejarErrorHTTP(error, "Pedido");
      return null;
    });
    this.setState({ pedido: pedido, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      await this.setState({ cargando: true });
      await this.cargarPedido();
    }
  };

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  renderPedido = (responsive: boolean) =>
    this.state.pedido ? (
      <>
        <H
          alineacion={"center"}
          size={2}
          texto={
            "Pedido de " +
            this.state.pedido.cliente.razon_social +
            " de la fecha " +
            this.state.pedido.fuente.fecha
          }
        />
        <Tabs
          defaultActiveKey={"datosGenerales"}
          transition={false}
          variant={responsive ? "pills" : "tabs"}
          style={{
            marginBottom: "1rem",
            flexDirection: responsive ? "column" : "row",
            alignItems: responsive ? "center" : "",
          }}
          unmountOnExit={true}
        >
          <Tab eventKey="datosGenerales" title="Datos Generales">
            <Flex
              container
              flexDirection="column"
              width="100%"
              alignItems="flex-start"
              justifyContent="center"
              borderRadius="5px"
              padding="2px"
              marginLeft="2px"
            >
              <Text>
                <b>Cliente</b>: {this.state.pedido.cliente.razon_social}
              </Text>
              <Text>
                <b>Monto</b>: {this.state.pedido.monto + " $"}
              </Text>
              <Flex>
                <Text>
                  <b>Estados</b>:
                  {this.state.pedido.estados_pedido.map((estado, idx) => (
                    <Badge
                      color={getColorEstado(estado.estado)}
                      variant={"filled"}
                    >
                      {estado.estado}
                    </Badge>
                  ))}
                </Text>
              </Flex>
              <FuentePedidoShow idFuente={this.state.pedido.fuente.id} />
            </Flex>
          </Tab>
          <Tab
            eventKey="factura"
            title="Factura"
            disabled={!(this.state.pedido.factura != null)}
          >
            <FacturaPedidoShow
              idFactura={
                this.state.pedido.factura ? this.state.pedido.factura.id : -1
              }
            />
          </Tab>
          <Tab
            eventKey="pagos"
            title="Pagos"
            disabled={!this.state.pedido.tiene_pagos}
          >
            <PagosPedidoShow idPedido={this.props.idPedido} />
          </Tab>
          <Tab
            eventKey="entregas"
            title="Entregas"
            disabled={
              this.state.pedido.entregas_producto.length == 0 &&
              this.state.pedido.entregas_bulto_producto.length == 0 &&
              this.state.pedido.entregas_maquina.length == 0
            }
          >
            <EntregasPedidoShow
              entregasProducto={this.state.pedido.entregas_producto}
              entregasBultoProducto={this.state.pedido.entregas_bulto_producto}
              entregasMaquina={this.state.pedido.entregas_maquina}
            />
          </Tab>
          <Tab
            eventKey="ventas"
            title="Ventas"
            disabled={
              this.state.pedido.ventas_producto.length == 0 &&
              this.state.pedido.ventas_bulto_producto.length == 0 &&
              this.state.pedido.ventas_maquina.length == 0 &&
              this.state.pedido.ventas_promocion.length == 0
            }
          >
            <VentasPedidoShow
              ventasProducto={this.state.pedido.ventas_producto}
              ventasBultoProducto={this.state.pedido.ventas_bulto_producto}
              ventasMaquina={this.state.pedido.ventas_maquina}
              ventasPromocion={this.state.pedido.ventas_promocion}
            />
          </Tab>
          <Tab
            eventKey="programaciones"
            title="Programaciones Entrega"
            disabled={!this.state.pedido.tiene_programaciones_entrega}
          >
            <ProgramacionesEntregaPedidoShow idPedido={this.props.idPedido} />
          </Tab>
        </Tabs>
      </>
    ) : null;

  renderLoading = () => "Cargando Datos";

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Margin top="20px" />
        <Box padding="15px">
          {this.state.cargando
            ? this.renderLoading()
            : this.renderPedido(this.state.responsive)}
        </Box>
      </Flex>
    );
  }
}
