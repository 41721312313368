import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import Visibilizador from "../../../../../libreria/action/visibilizador/Visibilizador";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import LocationsMap from "../../../../../mapas/libreria/locationsmap/LocationsMap";
import { ItemPoint } from "../../../../../mapas/libreria/models";



import { CANALES_WEB_SOCKET, DatosRecorrido, Direccion, DireccionRecorridoService, Recorrido, RecorridoGet, RecorridoService,  WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";





export interface Props {
  idRecorrido: number;
}
interface State {
  direcciones: Array<Direccion>;
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
}

export default class DireccionesRecorridoShow extends Component<Props, State> {

  private direccionRecorridoService:DireccionRecorridoService;
  private recorridoService:RecorridoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      direcciones: [],
      visible: false,
      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.direccionRecorridoService =  new DireccionRecorridoService(Global.UsuarioService.getToken()!);
    this.recorridoService =  new RecorridoService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {
    await this.cargarDirecciones();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS,
      funcionCallback: this.onChannelMesagge,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.COMPARTIDO_DIRECCION,
      funcionCallback: this.onChannelMesaggeDireccion,
    });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.recorridoId == this.props.idRecorrido) {
      await this.cargarDirecciones();
    }
  };

  onChannelMesaggeDireccion = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (
      this.state.direcciones.find(
        (direccion) => direccion.id == dato.message.direccionId
      )
    ) {
      await this.cargarDirecciones();
    }
  };

  cargarDirecciones = async () => {
    let respuesta = await this.direccionRecorridoService.getDireccionesDeRecorrido(
      this.props.idRecorrido
    ).catch((error) => {
      this.recorridoService.manejarErrorHTTP(error, "Recorrido");
      return null;
    });
    let direcciones = respuesta?.map((objeto: any) => {
      return objeto.direccion;
    });
    this.setState({
      direcciones: direcciones ? direcciones : [],
      cargando: false,
    });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({ cargando: true });
      await this.cargarDirecciones();
    }
  };

  renderDirecciones = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      {this.state.direcciones.length > 0
        ? this.renderizarTabla()
        : this.renderSinDirecciones()}
    </Flex>
  );

  renderSinDirecciones = () => {
    return "No posee Direcciones";
  };

  direccionToString = (direccion: Direccion): string => {
    let cadena = direccion.calle;
    if (direccion.entre_1 && direccion.entre_2) {
      cadena =
        cadena + " Entre " + direccion.entre_1 + " y " + direccion.entre_2;
    }
    cadena = cadena + " N° " + direccion.numero;
    if (direccion.piso) {
      cadena = cadena + " Piso " + direccion.piso;
    }
    if (direccion.departamento) {
      cadena = cadena + " Departamenento " + direccion.departamento;
    }
    cadena =
      cadena +
      " " +
      direccion.localidad.nombre +
      ", " +
      direccion.localidad.partido.nombre;

    return cadena;
  };

  renderizarFilas = () => {
    return this.state.direcciones.map((direccion, index) => {
      const cadena = this.direccionToString(direccion);
      return (
        <tr>
          <td className="text-center">{index + 1}</td>
          <td className="text-center">{cadena}</td>
        </tr>
      );
    });
  };

  armarItemsMap(direcciones: Array<Direccion>) {
    let items: Array<ItemPoint> = [];
    direcciones.forEach((direccion) => {
      let item = { id: direccion.id, point: { lat: 0, lng: 0 } };
      if (direccion.localizacion)
        item = {
          id: direccion.id,
          point: {
            lat: direccion.localizacion.coordinates[0],
            lng: direccion.localizacion.coordinates[1],
          },
        };
      items.push(item);
    });
    return items;
  }

  onSelect = (item: ItemPoint) => {
    //No se utiliza
    if (item) {
      console.log({ selected: item });
      console.log(
        this.state.direcciones.find((direccion) => direccion.id == item.id)
      );
    } else {
      console.log("Nada Seleccionado");
    }
  };

  renderizarTabla = () => {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="20px"
      >
        <Table bordered={true} responsive>
          <thead>
            <tr>
              <th className="text-center">Orden</th>
              <th className="text-center">Direccion</th>
            </tr>
          </thead>
          <tbody>{this.renderizarFilas()}</tbody>
        </Table>
        <LocationsMap
          height={"800px"}
          items={this.armarItemsMap(this.state.direcciones)}
          clickable={false}
          onSelect={this.onSelect}
        />
      </Flex>
    );
  };

  renderLoading = () => "Cargando Direcciones";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="20px"
      >
        <Visibilizador
          onChange={this.onChangeVisibilizador}
          label={"Direcciones"}
          labelAlignment={"flex-start"}
        >
          {this.state.cargando
            ? this.renderLoading()
            : this.renderDirecciones()}
        </Visibilizador>
      </Flex>
    );
  }
}
