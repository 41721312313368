import React, { Component } from "react";
import { Item } from "../../../../../../libreria/data/checkboxlist/Item";
import CheckBoxList from "../../../../../../libreria/data/checkboxlist/CheckBoxList";
import { MotivoVinculacionService, TipoMotivo } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";

interface Props {
  callbackParent: Function;
  motivosVinculacionIDs: Array<number>;
  tipoMotivo: TipoMotivo;
}

interface State {
  items: Array<Item>;
}

export default class CheckBoxListMotivo extends Component<Props, State> {

  private motivoVinculacionService:MotivoVinculacionService;

  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
    };

    this.motivoVinculacionService = new MotivoVinculacionService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    const tipoMotivo = TipoMotivo.ALTA.toString();
    const queryParams = new URLSearchParams({ tipo: tipoMotivo });
    let motivos = await this.motivoVinculacionService.listAll(queryParams).catch((error) => {
      this.motivoVinculacionService.manejarErrorHTTP(error, "Motivo Vinculacion");
      return [];
    });
    let list: Array<Item> = [];
    motivos.forEach((motivo) => {
      list.push({
        item: { id: motivo.id, text: motivo.motivo },
        checked: this.props.motivosVinculacionIDs.includes(motivo.id),
      });
    });
    this.setState({ items: list });
  };

  onChange = (item: Item) => {
    let motivos: Array<number> = [];
    this.state.items.forEach((item) => {
      if (item.checked) motivos.push(item.item.id);
    });
    this.props.callbackParent(motivos);
  };

  render() {
    return <CheckBoxList fontFamily={"Courier"} items={this.state.items} onChange={this.onChange} />;
  }
}
