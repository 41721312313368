import React, { Component, ReactElement } from "react";
import Flex from "../../../../libreria/appearance/flex/Flex";
import { CANALES_WEB_SOCKET, CargamentoLectura, RepartoDiarioService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  idRepartoDiario: number;
  renderMethod: (c: CargamentoLectura) => ReactElement;
}

interface State {
  descargas: Array<CargamentoLectura> | null;
}

export default class RepartoDiarioDescargasShow extends Component<Props, State> {
  private webSockets: Array<WebSocket>;
  private repartoDiarioService:RepartoDiarioService;

  constructor(props: Props) {
    super(props);
    this.webSockets = [];
    this.state = {
      descargas: null,
    };
    this.repartoDiarioService =  new RepartoDiarioService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    this.setDescargas();
    this.webSockets.push(
      WebSocketService.subscribirseACanal({
        nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS_DIARIOS,
        funcionCallback: this.onChannelMessage,
      })
    );
  };

  componentWillUnmount = () => {
    this.webSockets.forEach((ws: WebSocket) => ws.close());
  };

  setDescargas = async () => {
    const descargas = await this.repartoDiarioService.getDescargas(this.props.idRepartoDiario);
    this.setState({ descargas: descargas });
  };

  onChannelMessage = (message: MessageEvent) => {
    const dato = JSON.parse(message.data);
    if (dato.message.idRepartoDiario !== this.props.idRepartoDiario) {
      return;
    }
    this.setDescargas();
  };

  render() {
    const { descargas } = this.state;
    const { renderMethod } = this.props;

    if (descargas === null) {
      return <div>Cargando...</div>;
    }

    return (
      <Flex gap="1rem" flexWrap="wrap" marginBottom="1rem">
        {descargas.length > 0 ? (
          descargas.map((carga) => {
            return renderMethod(carga);
          })
        ) : (
          <span>No posee descargas asociadas</span>
        )}
      </Flex>
    );
  }
}
