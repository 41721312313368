import React, { Component } from "react";
import { CANALES_WEB_SOCKET, CuponEscritura, CuponService, WebSocketService, CuponLectura } from "serviciossaintmichel";
import Swal from "sweetalert2";
import RequestButton from "../../../../../compartido/components/request-button/RequestButton";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import { Global } from "../../../../../Global";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import { Loader } from "@mantine/core";
import CuponLoad from "../load/CuponLoad";
import FechaUtils from "../../../../../compartido/utils/FechaUtils";

export interface Props {
  onUpdateSuccess?: Function;
  cuponId: number;
}
interface State {
  cupon: CuponEscritura | null;
  screen: ScreenType;
  valid: boolean;
}

interface Message {
  type: string;
  idCupon: number;
}

export default class CuponUpdate extends Component<Props, State> {
  private cuponService: CuponService;

  constructor(props: Props) {
    super(props);
    this.state = {
      cupon: null,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
      valid: false,
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_DESCUENTOS,
      funcionCallback: this.onChannelMesagge,
    });

    this.cuponService = new CuponService(Global.UsuarioService.getToken()!);
  }

  toCuponEscritura = (cuponLectura: CuponLectura): CuponEscritura => {
    return {
      tipo: cuponLectura.tipo,
      descuento: cuponLectura.descuento.id,
      automatico: cuponLectura.automatico,
      fecha_inicio:
        cuponLectura.fecha_inicio !== null
          ? FechaUtils.normalizarFecha(cuponLectura.fecha_inicio)
          : cuponLectura.fecha_inicio,
      fecha_fin:
        cuponLectura.fecha_fin !== null ? FechaUtils.normalizarFecha(cuponLectura.fecha_fin) : cuponLectura.fecha_fin,
    };
  };

  async componentDidMount() {
    const cuponLectura = await this.cuponService.get(this.props.cuponId);
    const cupon = this.toCuponEscritura(cuponLectura);
    this.setState({
      cupon: cupon,
    });
  }

  onChannelMesagge = async (e: MessageEvent) => {
    const message: Message = JSON.parse(e.data).message;

    if (message.idCupon === this.props.cuponId) {
      const cuponLectura = await this.cuponService.get(this.props.cuponId);
      const cupon = this.toCuponEscritura(cuponLectura);
      this.setState({
        cupon: cupon,
      });
    }
  };

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  onUpdate = async () => {
    const { cupon } = this.state;
    const { cuponId } = this.props;

    if (cupon === null) {
      return;
    }

    try {
      await this.cuponService.update(cupon, cuponId);

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.VENTAS_CUPONES, {
        type: "update",
        idCupon: cuponId,
      });

      await Swal.fire({
        icon: "success",
        text: "Cupon modificado",
      });
    } catch (error) {
      this.cuponService.manejarErrorHTTP(error, "Cupon");
    }
  };

  renderizarPhone = () => (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex flexDirection="column" width="60%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => (
    <Flex flexDirection="column" width="50%" alignItems="center" justifyContent="center" marginTop="20px">
      {this.renderizar()}
    </Flex>
  );

  onChange = (cupon: CuponEscritura) => {
    this.setState({ cupon: cupon });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizar = () => {
    const { cupon } = this.state;

    if (cupon === null) {
      return null;
    }

    return (
      <Box padding="20px">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
            <H size={3} texto={`Modificar cupon (id ${this.props.cuponId})`} />
          </Flex>
          <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="10px">
            <CuponLoad cupon={cupon} onChange={this.onChange} onValidationChange={this.onValidationChange} />
          </Flex>
          <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
            <RequestButton propsBoton={{ disabled: !this.state.valid }} texto={"Actualizar"} onClick={this.onUpdate} />
          </Flex>
        </Flex>
      </Box>
    );
  };

  render() {
    if (this.state.cupon === null) {
      return <Loader />;
    }

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        {this.state.screen === ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen === ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen === ScreenType.TABLET ? this.renderizarTablet() : null}
      </Flex>
    );
  }
}
