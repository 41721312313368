import React, { Component } from "react";
import CuponesShow, { VersionCuponesShow } from "./components/show/CuponesShow";
import { CuponLectura, CuponService, GrupoTipoCliente, TipoCliente } from "serviciossaintmichel";
import H from "../../../libreria/message/title/H";
import RequestButton from "../../../libreria/action/request-button/RequestButton";
import ClientesShow, { VersionClientesShow } from "../../seccion-clientes/clientes/components/ClientesShow";
import { Box } from "../../../libreria/containers/box/Box";
import Flex from "../../../libreria/appearance/flex/Flex";
import TiposClienteShow, {
  VersionTiposClientesShow,
} from "../../seccion-clientes/clientes/components/actualizar/datosGenerales/tipoDeCliente/TiposClienteShow";
import GruposTipoClienteShow, {
  VersionGruposTipoClientesShow,
} from "../../seccion-clientes/clientes/components/actualizar/datosGenerales/grupo-tipo-cliente/GruposTipoClienteShow";
import { Global } from "../../../Global";
import Swal from "sweetalert2";

export interface Props {}
interface State {
  cuponSeleccionado: CuponLectura | null;
  clientesSeleccionados: Array<string>;
  tipoClienteSeleccionado: TipoCliente | null;
  grupoTipoClienteSeleccionado: GrupoTipoCliente | null;
  asignacionHabilitada: boolean;
}

export default class AsignacionCupones extends Component<Props, State> {
  private cuponService: CuponService;
  constructor(props: Props) {
    super(props);
    this.state = {
      cuponSeleccionado: null,
      clientesSeleccionados: [],
      tipoClienteSeleccionado: null,
      grupoTipoClienteSeleccionado: null,
      asignacionHabilitada: false,
    };
    this.cuponService = new CuponService(Global.UsuarioService.getToken()!);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevState !== this.state) {
      this.setAsignacionHabilitada();
    }
  }

  setEstadoInicial = () => {
    this.setState({
      cuponSeleccionado: null,
      clientesSeleccionados: [],
      tipoClienteSeleccionado: null,
      grupoTipoClienteSeleccionado: null,
      asignacionHabilitada: false,
    });
  };

  setAsignacionHabilitada = () => {
    const entidadSeleccionada =
      this.state.clientesSeleccionados.length > 0 ||
      this.state.tipoClienteSeleccionado !== null ||
      this.state.grupoTipoClienteSeleccionado !== null;
    const habilitada = entidadSeleccionada && this.state.cuponSeleccionado !== null;

    if (habilitada !== this.state.asignacionHabilitada) {
      this.setState({ asignacionHabilitada: habilitada });
    }
  };

  setCupon = (cupon: CuponLectura | null) => {
    this.setState({ cuponSeleccionado: cupon });
  };

  guardarAsignacion = async () => {
    const { cuponSeleccionado, clientesSeleccionados, tipoClienteSeleccionado, grupoTipoClienteSeleccionado } =
      this.state;

    if (cuponSeleccionado === null) {
      return;
    }

    const clientesId = clientesSeleccionados.map((id: string) => parseInt(id));
    const payload = {
      grupo_cliente: grupoTipoClienteSeleccionado ? grupoTipoClienteSeleccionado.id : null,
      tipo_cliente: tipoClienteSeleccionado ? tipoClienteSeleccionado.id : null,
      clientes: clientesId,
    };

    try {
      await this.cuponService.asignarCupon(cuponSeleccionado.id, payload);
      this.setEstadoInicial();
      await Swal.fire({
        icon: "success",
        text: "Cupón asignado",
      });
    } catch (error) {
      this.cuponService.manejarErrorHTTP(error, "Cupon");
    }
  };

  render(): React.ReactNode {
    const {
      cuponSeleccionado,
      clientesSeleccionados,
      tipoClienteSeleccionado,
      grupoTipoClienteSeleccionado,
      asignacionHabilitada,
    } = this.state;

    return (
      <Box paddingVertical={"2rem"} paddingHorizontal={"2rem"}>
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" gap="1rem">
          <H size={2}>Asignación de cupón</H>
          <CuponesShow
            version={VersionCuponesShow.SELECT}
            propsSelect={{
              callbackParent: this.setCupon,
              seleccionado: cuponSeleccionado,
              label: "Cupón a asignar",
              clearable: true,
              placeholder: "Seleccione un cupón",
              style: { width: "auto" },
            }}
            filtros={{ limite: 0, criterioOrden: { campo: "id", modo: "ASC" } }}
          />
          <ClientesShow
            version={VersionClientesShow.MULTI_SELECT}
            propsMultiSelect={{
              label: "Clientes seleccionados",
              value: clientesSeleccionados,
              maxDropdownHeight: 160,
              onChange: (seleccionados) => {
                this.setState({ clientesSeleccionados: seleccionados });
              },
              searchable: true,
              placeholder: "Seleccione clientes",
              labelProps: { style: { fontSize: "16px" } },
            }}
          />
          <TiposClienteShow
            version={VersionTiposClientesShow.SELECT}
            propsSelect={{
              callbackParent: (seleccionado) => {
                this.setState({ tipoClienteSeleccionado: seleccionado });
              },
              seleccionado: tipoClienteSeleccionado,
              clearable: true,
              placeholder: "Seleccione tipo de cliente",
              label: "Tipo de cliente",
              style: { width: "auto" },
            }}
          />
          <GruposTipoClienteShow
            version={VersionGruposTipoClientesShow.SELECT}
            propsSelect={{
              callbackParent: (seleccionado) => {
                this.setState({ grupoTipoClienteSeleccionado: seleccionado });
              },
              seleccionado: grupoTipoClienteSeleccionado,
              clearable: true,
              placeholder: "Seleccione grupo de tipo de cliente",
              label: "Grupo de tipo de cliente",
              style: { width: "auto" },
            }}
          />

          <RequestButton
            texto="Asignar"
            onClick={this.guardarAsignacion}
            propsBoton={{ disabled: !asignacionHabilitada }}
          />
        </Flex>
      </Box>
    );
  }
}
