import React from "react";
import { CANALES_WEB_SOCKET, ClienteService, DireccionDTO, MaquinaServicioFisicaGet, MaquinaServicioFisicaService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";
import TablaMaquinasCliente from "./TablaMaquinasCliente";

export enum Version {
  TABLE,
}

interface Props {
  clienteId: number;
  version: Version;
  onDelete?: Function;
}

interface State {
  maquinasFisicas: Array<MaquinaServicioFisicaGet> | null;
  direcciones: Array<DireccionDTO> | null;
}

export default class MaquinasClienteShow extends React.Component<Props, State> {
  private webSocket: WebSocket | null;
  private maquinaServicioFisicaService:MaquinaServicioFisicaService;
  private clienteService:ClienteService;


  constructor(props: Props) {
    super(props);
    this.state = {
      maquinasFisicas: null,
      direcciones: null,
    };
    this.webSocket = null;
    this.maquinaServicioFisicaService = new MaquinaServicioFisicaService(Global.UsuarioService.getToken()!);
    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_MAQUINAS_SERVICIO_FISICA,
      funcionCallback: this.callbackWS,
    });

    PubSub.subscribe("MAQUINAS_CLIENTE", (canal: string, data: any) => {
      if (data.cliente_id === this.props.clienteId) {
        this.setData();
      }
    });

    this.setData();
  }

  componentWillUnmount() {
    this.webSocket?.close();
    PubSub.clearAllSubscriptions();
  }

  callbackWS = async (e: MessageEvent) => {
    const { maquinasFisicas } = this.state;

    if (maquinasFisicas === null) {
      return;
    }

    const dato = JSON.parse(e.data);
    const maquinasConCambios = maquinasFisicas.filter((maq) => maq.id === dato.maquinaServicioFisicaId);

    if (maquinasConCambios.length > 0) {
      this.setData();
    }
  };

  async setData() {
    const { clienteId } = this.props;

    try {
      const direccionesCliente = await this.clienteService.getDirecciones(clienteId);
      const direccionesId = direccionesCliente.map((dirCliente) => dirCliente.id);
      const maquinasEnPosesion = await this.maquinaServicioFisicaService.getMaquinasEnDirecciones(direccionesId);

      this.setState({
        maquinasFisicas: maquinasEnPosesion,
        direcciones: direccionesCliente,
      });
    } catch (error) {
      this.clienteService.manejarErrorHTTP(error, "MaquinasFisicas");
    }
  }

  render = () => {
    const { maquinasFisicas, direcciones } = this.state;
    const { version, onDelete = () => {} } = this.props;

    if (maquinasFisicas === null || direcciones === null) {
      return <div>Cargando...</div>;
    }

    switch (version) {
      case Version.TABLE: {
        return <TablaMaquinasCliente direcciones={direcciones} maquinasFisicas={maquinasFisicas} onDelete={onDelete} />;
      }
      default:
        return null;
    }
  };
}
