import React, { Component, FormEvent } from "react";
import { Col, Form } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

import Flex from "../../../../../libreria/appearance/flex/Flex";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import { Select } from "@mantine/core";
import ReactDatePicker from "react-datepicker";
import { FiltrosCupones } from "./CuponesShow";

export interface Props {
  busquedaHandler: Function;
  setFiltros: Function;
  filtros: FiltrosCupones;
}

interface State {}

export default class CuponesFilter extends Component<Props, State> {
  submitHandler = (event: FormEvent<HTMLFormElement>) => {
    this.props.busquedaHandler();
    event.preventDefault();
  };
  render = () => {
    const { filtros } = this.props;
    const minColWidth = "250px";

    return (
      <Form
        onSubmit={this.submitHandler}
        style={{
          padding: "1rem",
          backgroundColor: "gainsboro",
          borderRadius: "5px",
          marginBottom: "1rem",
        }}
      >
        <Form.Row className="align-items-center" style={{ rowGap: "1rem" }}>
          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <Select
                label="Tipo de cupon"
                placeholder="Seleccione tipo"
                data={[
                  { value: "Unico", label: "Unico" },
                  { value: "Perpetuo", label: "Perpetuo" },
                ]}
                value={filtros.tipo}
                onChange={(tipo: string) => this.props.setFiltros({ tipo: tipo })}
                clearable
              />
            </Flex>
          </Col>
          <Col style={{ minWidth: minColWidth }}>
            <div>Vigente desde</div>
            <ReactDatePicker
              placeholderText="Ingrese inicio de vigencia"
              selected={filtros.vigenciaDesde}
              isClearable
              onChange={(fecha: Date | null) => this.props.setFiltros({ vigenciaDesde: fecha })}
              filterDate={(date: Date) => (!filtros.vigenciaHasta ? true : date <= filtros.vigenciaHasta)}
            />
          </Col>
          <Col style={{ minWidth: minColWidth }}>
            <div>Vigente hasta</div>
            <ReactDatePicker
              placeholderText="Ingrese fin de vigencia"
              selected={filtros.vigenciaHasta}
              isClearable
              onChange={(fecha: Date | null) => this.props.setFiltros({ vigenciaHasta: fecha })}
              filterDate={(date: Date) => (!filtros.vigenciaDesde ? true : date >= filtros.vigenciaDesde)}
            />
          </Col>
          <Col style={{ minWidth: minColWidth }}>
            <Flex flexDirection="column" alignItems="center">
              <RequestButton
                onClick={() => {
                  this.props.busquedaHandler();
                }}
                texto="Buscar"
                icon={<Icon.Search />}
              />
            </Flex>
          </Col>
        </Form.Row>
      </Form>
    );
  };
}
