import React, { Component } from "react";
import EmpleadoActualizarDatosPersonales from "./EmpleadoActualizarDatosPersonales";
import EmpleadoActualizarAreas from "./EmpleadoActualizarAreas";
import Flex from "../../../../../compartido/components/flex/Flex";
import { Tabs, Tab } from "react-bootstrap";

import EmpleadoActualizarPermisos from "./EmpleadoActualizarPermisos";
import DireccionUpdate from "../../../../../compartido/components/direccion/DireccionUpdate";


import { CANALES_WEB_SOCKET, EmpleadoGet, EmpleadoPost, EmpleadoService, RelacionEmpleadoArea, RelacionEmpleadoSubArea, TipoResponsabilidad, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";




interface Props {
  empleado: EmpleadoGet;
  recienCreado: boolean;
}

interface State {
  responsive: boolean;
  empleado: EmpleadoGet;
}

export default class Empleados extends Component<Props, State> {

  private empleadoService:EmpleadoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      responsive: window.innerWidth < 700 ? true : false,
      empleado: this.props.empleado,
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_PERSONAL_EMPLEADOS,
      funcionCallback: this.refrescarEmpleado,
    });

    window.addEventListener("resize", this.actualizarDimensiones);

    this.empleadoService = new EmpleadoService(Global.UsuarioService.getToken()!);

  }

  refrescarEmpleado = async (e: MessageEvent) => {
    let id: number = JSON.parse(e.data).message.empleadoId;

    if (id == this.state.empleado.id) {
      let aux: EmpleadoGet = await this.empleadoService.get(id);
      this.setState({ empleado: aux });
    }
  };

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey={this.props.recienCreado ? "insumos" : "empleado"}
      transition={false}
      variant="pills"
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "center" : "",
      }}
      unmountOnExit={true}
    >
      <Tab eventKey="empleado" title="Datos Personales">
        <EmpleadoActualizarDatosPersonales empleado={this.state.empleado} />
      </Tab>
      <Tab eventKey="direccion" title="Dirección">
        <DireccionUpdate direccionId={this.state.empleado.direccion} />
      </Tab>
      <Tab eventKey="areas" title="Areas">
        <EmpleadoActualizarAreas empleado={this.state.empleado} />
      </Tab>
      <Tab eventKey="permisos" title="Permisos">
        <EmpleadoActualizarPermisos empleado={this.state.empleado} />
      </Tab>
    </Tabs>
  );

  render() {
    return (
      <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        {this.renderizar(this.state.responsive)}
      </Flex>
    );
  }
}
