import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { ComboRetornable, DatosComboRetornable, VentaComboRetornableLectura } from "serviciossaintmichel";
import { excluirIds } from "../../../../compartido/utils/filtros";
import Flex from "../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../libreria/data/input/Input";
import ComboRetornablesShow, {
  Version,
} from "../../../../ventas/seccion-otros/ComboRetornable/components/show/ComboRetornablesShow";


export interface Props {
  onSubmitCallback?: Function;
  excluirCombos?: Array<number>;
}

interface State {
  nuevaVentaCombo: VentaComboRetornableLectura;
  comboSeleccionado: DatosComboRetornable | null;
}

export default class VentaComboRetornableLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getEstadoInicial();
  }

  comboNuevo = (): ComboRetornable => {
    return { id: -1, nombre: "", productos: [] };
  };

  getEstadoInicial = () => ({
    nuevaVentaCombo: {
      combo_retornable: this.comboNuevo(),
      cantidad: 0,
    },
    comboSeleccionado: null,
  });

  setCombo = (combo: DatosComboRetornable | null) => {
    const comboRetornable: ComboRetornable = combo !== null ? { ...combo, productos: [] } : this.comboNuevo();
    this.setState((prevState) => ({
      nuevaVentaCombo: {
        ...prevState.nuevaVentaCombo,
        combo_retornable: combo ? comboRetornable : this.state.nuevaVentaCombo.combo_retornable,
      },
      comboSeleccionado: combo,
    }));
  };

  onSubmit = () => {
    const { onSubmitCallback = () => {} } = this.props;
    onSubmitCallback(this.state.nuevaVentaCombo);
    const estadoInicial = this.getEstadoInicial();
    this.setState(estadoInicial);
  };

  validarVenta = (ventaCombo: VentaComboRetornableLectura) => {
    return ventaCombo.combo_retornable.id > 0 && ventaCombo.cantidad > 0;
  };

  render() {
    const { nuevaVentaCombo, comboSeleccionado } = this.state;
    const filtrosShow = this.props.excluirCombos ? [excluirIds(this.props.excluirCombos)] : undefined;

    return (
      <Flex borderRadius="5px" border="1px solid black" gap="1rem" padding="1rem" alignItems="center">
        <ComboRetornablesShow
          version={Version.SELECT}
          propsSelect={{
            callbackParent: this.setCombo,
            seleccionado: comboSeleccionado,
            placeholder: "Seleccione un combo retornable",
            label: "Combos retornables",
          }}
          filtros={filtrosShow}
        />
        <div>
          <span>Cantidad</span>
          <Input
            type={InputTypes.Number}
            data={nuevaVentaCombo.cantidad}
            onChange={(data: number) => {
              this.setState((prevState) => ({
                nuevaVentaCombo: { ...prevState.nuevaVentaCombo, cantidad: data },
              }));
            }}
            min={1}
          />
        </div>

        <Button onClick={this.onSubmit} disabled={!this.validarVenta(nuevaVentaCombo)}>
          Añadir
        </Button>
      </Flex>
    );
  }
}
