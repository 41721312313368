import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import ElementosComputacion from "./ElementoComputacion/components/ElementosComputacion";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];
  
  if (Global.UsuarioService.getPermisos().Elementos.ElementosComputacion.hasAccess()) {
    aux.push({
      name: "ElementosComputacion",
      path: SeccionElementosComputacion.pathBase + "/elementos-computacion",
      component: ElementosComputacion,
    })
  }
  return aux;
}

export default class SeccionElementosComputacion extends Component<Props> {
  static pathBase = "/elementos/seccion-elementos-computacion";
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Elementos de Computacion"}
      />
    );
  }
}
