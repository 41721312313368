import React, { Component } from "react";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import RequestButton from "../../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import H from "../../../../../../libreria/message/title/H";
import ProductoConsumoLoad from "./ProductoConsumoLoad";





import { CANALES_WEB_SOCKET,  ConsumoGet,  ConsumoService,   MaquinaSimbolicaConsumo,   MaquinaSimbolicaConsumoGet,   ProductoConsumo,   WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";



export interface Props {
  onSaveSuccess?: Function;
  onExit?: Function;
  consumo: ConsumoGet;
}

interface State {
  producto: ProductoConsumo;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class ProductoConsumoNew extends Component<Props, State> {

  private consumoService:ConsumoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      producto: this.createEmptyProducto(),
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.consumoService =  new ConsumoService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  createEmptyProducto = (): ProductoConsumo => {
    return { producto: -1, cantidad_minima: 0 };
  };

  onSave = async () => {
    try {
      debugger;

      let x = await this.consumoService.agregarProducto(
        this.props.consumo.id,
        this.state.producto
      );

      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CONSUMOS_PRODUCTOS,
        {
          type: "new",
          idConsumo: this.props.consumo.id,
          idProducto: x.producto,
        }
      );

      await Swal.fire({
        icon: "success",
        text: "Producto Actualizado",
      });
    } catch (error) {
      this.consumoService.manejarErrorHTTP(error, "Nuevo Producto Consumo");
    }
  };

  onExit = () => {
    if (this.props.onExit != undefined) this.props.onExit();
  };

  isValid = (nombre: string): boolean => {
    return nombre.length > 0 ? true : false;
  };

  onChangeProducto = (producto: ProductoConsumo) => {
    this.setState({ producto: producto });
  };

  onChangeValidation = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border="1px solid grey"
        borderRadius="5px"
        padding="10px"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="10px"
        >
          <H size={4} texto={"Agregar Producto"} />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <ProductoConsumoLoad
            producto={this.state.producto}
            onChange={this.onChangeProducto}
            onValidationChange={this.onChangeValidation}
          />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Agregar"}
            onClick={this.onSave}
          />
        </Flex>
      </Flex>
    );
  }
}
