import React, { Component } from "react";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import RequestButton from "../../../../../../../compartido/components/request-button/RequestButton";
import OrdenCompraLoad, { Inputs } from "./OrdenCompraLoad";

import { CANALES_WEB_SOCKET, OrdenCompra, OrdenCompraService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../../Global";




export interface Props {
  ordenCompraId: number;
  fechaFinMax?: Date;
}

interface State {
  ordenCompra: OrdenCompra | null;
  ordenCompraValida: boolean;
}

export default class OrdenCompraUpdate extends Component<Props, State> {

  private ordenCompraService:OrdenCompraService;

  constructor(props: Props) {
    super(props);
    this.state = {
      ordenCompra: null,
      ordenCompraValida: true,
    };

    this.ordenCompraService =  new OrdenCompraService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = async () => {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_SERVICIO_ACTIVO,
      funcionCallback: this.servicioActivoWSHandler,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_ORDENES_COMPRA,
      funcionCallback: this.ordenCompraWSHandler,
    });
    this.getOrdenCompra();
  };

  getOrdenCompra = async () => {
    try {
      const [ordenCompra, productosOrdenCompra, maquinasOrdenCompra] =
        await Promise.all([
          this.ordenCompraService.get(this.props.ordenCompraId),
          this.ordenCompraService.getProductosOrdenCompra(this.props.ordenCompraId),
          this.ordenCompraService.getMaquinasOrdenCompra(this.props.ordenCompraId),
        ]);
      this.setState({
        ordenCompra: {
          ...ordenCompra,
          productos: productosOrdenCompra,
          maquinas_simbolicas: maquinasOrdenCompra,
        },
      });
    } catch (error) {
      this.ordenCompraService.manejarErrorHTTP(error, "OrdenCompra");
    }
  };

  ordenCompraWSHandler = async (e: MessageEvent) => {
    const ordenCompraId = JSON.parse(e.data).message.ordenCompraId;
    if (ordenCompraId === this.state.ordenCompra?.id) {
      this.getOrdenCompra();
    }
  };

  servicioActivoWSHandler = async (e: MessageEvent) => {
    const clienteId = JSON.parse(e.data).message.clienteId;
    if (clienteId === this.state.ordenCompra?.cliente) {
      this.getOrdenCompra();
    }
  };

  actualizarOrdenCompra = async (ordenCompra: OrdenCompra) => {
    try {
      await this.ordenCompraService.actualizar(ordenCompra.id, ordenCompra);
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_SERVICIO_ACTIVO,
        {
          clienteId: ordenCompra.cliente,
        }
      );
    } catch (error) {
      this.ordenCompraService.manejarErrorHTTP(error, "OrdenCompra");
    }
  };

  onChange = (ordenCompra: OrdenCompra) => {
    this.setState({ ordenCompra: ordenCompra });
  };

  onValidationChange = (valido: boolean) => {
    this.setState({ ordenCompraValida: valido });
  };

  render = () => {
    const { fechaFinMax } = this.props;
    const { ordenCompra, ordenCompraValida } = this.state;

    if (ordenCompra === null) {
      return null;
    }

    return (
      <>
        <OrdenCompraLoad
          ordenCompra={ordenCompra}
          clienteId={ordenCompra.cliente}
          onChange={this.onChange}
          onValidationChange={this.onValidationChange}
          fechaFinMax={fechaFinMax}
          inputsDeshabilitados={[Inputs.FechaInicio]}
        />

        <Flex container justifyContent="space-around" marginTop="1rem">
          <RequestButton
            onClick={() => {
              this.actualizarOrdenCompra(ordenCompra);
            }}
            propsBoton={{ variant: "info", disabled: !ordenCompraValida }}
            texto="Actualizar"
            confirmacion={true}
          />
        </Flex>
      </>
    );
  };
}
