import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Input, { InputTypes } from "../../../../../../libreria/data/input/Input";
import ProductosShow, {
  Version,
} from "../../../../../../elementos/productos/Producto/components/show/ProductosShow";
import { DatosProducto, ProductoComboRetornable } from "serviciossaintmichel";



export interface Props {
  onChange: Function;
  onValidationChange: Function;
  producto: ProductoComboRetornable;
  productosExcluidos?: Array<number>;
  productoFijo?: boolean;
}

interface State {
  producto: ProductoComboRetornable;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class ProductoComboRetornableLoad extends Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      producto: this.props.producto,
      error: "",
      valid: this.isValid(this.props.producto),
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  isValid = (producto: ProductoComboRetornable): boolean => {
    return producto.producto.id > 0 &&
      (producto.cantidad_cobrable > 0 || producto.cantidad_vacio > 0)
      ? true
      : false;
  };

  componentDidUpdate(props: Props, state: State) {
    if (
      this.props.producto.producto.id !== props.producto.producto.id ||
      this.props.producto.cantidad_cobrable !==
        props.producto.cantidad_cobrable ||
      this.props.producto.cantidad_vacio !== props.producto.cantidad_vacio
    ) {
      let valid = this.isValid(this.props.producto);
      this.setState({ producto: this.props.producto, valid: valid });
    }
    if (this.state.valid != state.valid) {
      this.props.onValidationChange(this.state.valid);
    }
  }

  onChangeProducto = (x: DatosProducto | null) => {
    let producto = this.state.producto;
    producto.producto.id = x ? x.id : -1;
    let valid = this.isValid(producto);
    this.setState({ producto: producto, valid: valid });
    this.props.onChange(producto);
    if (this.props.onValidationChange) this.props.onValidationChange(valid);
  };

  setearCantidadCobrable = (cantidad: number) => {
    let producto = this.state.producto;
    producto.cantidad_cobrable = cantidad ? cantidad : 0;
    let valid = this.isValid(producto);
    this.setState({ producto: producto });
    this.props.onChange(producto);
    if (this.props.onValidationChange) this.props.onValidationChange(valid);
  };
  setearCantidadVacio = (cantidad: number) => {
    let producto = this.state.producto;
    producto.cantidad_vacio = cantidad ? cantidad : 0;
    let valid = this.isValid(producto);
    this.setState({ producto: producto });
    this.props.onChange(producto);
    if (this.props.onValidationChange) this.props.onValidationChange(valid);
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Form.Label> Seleccione Producto</Form.Label>
          <ProductosShow
            version={Version.SELECT}
            propsSelect={{
              seleccionado: this.state.producto.producto,
              callbackParent: this.onChangeProducto,
              sinTitulo: true,
              disabled: this.props.productoFijo,
            }}
            productosExcluidos={this.props.productosExcluidos}
          />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="flex-start"
          justifyContent="center"
          marginTop="20px"
        >
          <Input
            data={this.state.producto.cantidad_cobrable}
            label={"Cantidad Cobrable"}
            placeholder={"Cantidad Cobrable"}
            onChange={this.setearCantidadCobrable}
            type={InputTypes.Number}
          />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Input
            data={this.state.producto.cantidad_vacio}
            label={"Cantidad Vacio"}
            placeholder={"Cantidad Vacio"}
            onChange={this.setearCantidadVacio}
            type={InputTypes.Number}
          />
        </Flex>
      </Flex>
    );
  }
}
