import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import SelectTipoInsumo from "../../TipoInsumo/components/SelectTipoInsumo";
import SelectUnidadInsumo from "../../UnidadInsumo/components/SelectUnidadInsumo";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";

import { CANALES_WEB_SOCKET, InsumoGet, InsumoPost, InsumoService, TipoInsumoGet, TipoInsumoService, UnidadInsumoGet, UnidadInsumoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  ocultar: Function;
}

interface State {
  insumo: InsumoPost;
  tiposInsumo: Array<TipoInsumoGet>;
  unidadesInsumo: Array<UnidadInsumoGet>;
  alerta: any;
}

export default class InsumoNuevo extends Component<Props, State> {


  private tipoInsumoService:TipoInsumoService;
  private unidadInsumoService:UnidadInsumoService;
  private insumoService:InsumoService;


  constructor(props: Props) {
    super(props);
    this.state = {
      insumo: this.insumoCrear(),
      tiposInsumo: [],
      unidadesInsumo: [],
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_TIPO_INSUMOS,
      funcionCallback: this.cargarTiposInsumo,
    });
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_UNIDAD_INSUMOS,
      funcionCallback: this.cargarUnidadesInsumo,
    });


    this.tipoInsumoService =  new TipoInsumoService(Global.UsuarioService.getToken()!);
    this.unidadInsumoService =  new UnidadInsumoService(Global.UsuarioService.getToken()!);
    this.insumoService =  new InsumoService(Global.UsuarioService.getToken()!);


  }

  async componentDidMount() {
    await this.cargarTiposInsumo();
    await this.cargarUnidadesInsumo();
    const insumoCrear: InsumoPost = this.state.insumo;
    if (this.state.tiposInsumo.length > 0) {
      insumoCrear.tipo_insumo = this.state.tiposInsumo[0].id;
    } else {
      insumoCrear.tipo_insumo = 0;
    }
    if (this.state.unidadesInsumo.length > 0) {
      insumoCrear.unidad_insumo = this.state.unidadesInsumo[0].id;
    } else {
      insumoCrear.unidad_insumo = 0;
    }
    this.setState({
      insumo: insumoCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  cargarTiposInsumo = async () => {
    const resTiposInsumo = await this.tipoInsumoService.listAll().catch((error) => {
      this.tipoInsumoService.manejarErrorHTTP(error, "Tipo Insumo");
      return null;
    });
    if (resTiposInsumo != null) {
      this.setState({
        tiposInsumo: resTiposInsumo,
      });
    }
  };

  cargarUnidadesInsumo = async () => {
    const resUnidadesInsumo = await this.unidadInsumoService.listAll().catch(
      (error) => {
        this.unidadInsumoService.manejarErrorHTTP(error, "Unidad Insumo");
        return null;
      }
    );
    if (resUnidadesInsumo != null) {
      this.setState({
        unidadesInsumo: resUnidadesInsumo,
      });
    }
  };

  insumoCrear(): InsumoPost {
    return {
      nombre: "",
      tipo_insumo: 0,
      unidad_insumo: 0,
    };
  }
  setearNombre = (nombre: string) => {
    let insumo = this.state.insumo;
    insumo.nombre = nombre;
    this.setState({ insumo: insumo });
  };
  setearTipoInsumo = (tipoInsumo: TipoInsumoGet) => {
    let insumo = this.state.insumo;
    insumo.tipo_insumo = tipoInsumo.id;
    this.setState({ insumo: insumo });
  };
  setearUnidadInsumo = (unidadInsumo: UnidadInsumoGet) => {
    let insumo = this.state.insumo;
    insumo.unidad_insumo = unidadInsumo.id;
    this.setState({ insumo: insumo });
  };

  handleCreate() {
    this.insumoService.create(this.state.insumo)
      .then((result) => {
        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_INSUMOS);
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Insumo Creado!",
            titulo: "Atención",
            estado: "block",
          },
        });
        const insumoCrear: InsumoPost = this.insumoCrear();
        insumoCrear.tipo_insumo = this.state.insumo.tipo_insumo;
        insumoCrear.unidad_insumo = this.state.insumo.unidad_insumo;
        this.setState({
          insumo: insumoCrear,
        });
      })
      .catch((error) => {
        this.insumoService.manejarErrorHTTP(error, "Insumo");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.insumo.nombre &&
      this.state.insumo.tipo_insumo &&
      this.state.insumo.tipo_insumo != 0 &&
      this.state.insumo.unidad_insumo &&
      this.state.insumo.unidad_insumo != 0
      ? false
      : true;
  };

  renderizarFormulario() {
    if (
      this.state.tiposInsumo.length == 0 ||
      this.state.unidadesInsumo.length == 0
    ) {
      return (
        <div>
          {"Aún no se poseen tipos o unidades de insumo, vaya y cree uno."}
          <button
            className={"btn btn-outline-secondary"}
            onClick={this.ocultar}
          >
            type={"reset"}
            {"Ocultar"}
          </button>
        </div>
      );
    }
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="nombre">Nombre</label>
                <InputCaracteres
                  id="nombre"
                  name="nombre"
                  onChange={this.setearNombre}
                  value={this.state.insumo.nombre}
                  placeholder="Ingrese Nombre de Insumo"
                />
              </div>

              <div className={"form-group"}>
                <SelectTipoInsumo
                  tiposInsumos={this.state.tiposInsumo}
                  seleccionado={this.state.tiposInsumo[0]}
                  callbackParent={this.setearTipoInsumo}
                />
              </div>
              <div className={"form-group"}>
                <SelectUnidadInsumo
                  unidadesInsumos={this.state.unidadesInsumo}
                  seleccionado={this.state.unidadesInsumo[0]}
                  callbackParent={this.setearUnidadInsumo}
                />
              </div>
              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  ref="boton"
                  type="submit"
                  className={"btn btn-info"}
                  disabled={this.disableButton()}
                >
                  {"Crear"}
                </button>
                <button
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
              <div className={"form-group"}>
                <Alerta
                  tipo={this.state.alerta.tipo}
                  titulo={this.state.alerta.titulo}
                  mensaje={this.state.alerta.mensaje}
                  estado={this.state.alerta.estado}
                />
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
