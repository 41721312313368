import React, { Component } from "react";
import ActualizarEmailsCliente from "../emails/ActualizarEmailsCliente";
import TelefonosClienteActualizar from "../telefonos/TelefonosClienteActualizar";

interface Props {
  clienteId: number;
}

interface State {}

export default class DatosDeContacto extends Component<Props, State> {
  render() {
    const { clienteId } = this.props;
    return (
      <>
        <TelefonosClienteActualizar clienteId={clienteId} />
        <hr style={{ width: "100%" }} />
        <ActualizarEmailsCliente clienteId={clienteId} />
      </>
    );
  }
}
