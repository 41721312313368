import React, { Component } from "react";
import { Tab, Table, Tabs } from "react-bootstrap";
import Box from "../../../../../../libreria/containers/box/Box";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../../libreria/appearance/margin/Margin";
import H from "../../../../../../libreria/message/title/H";
import RequestButton from "../../../../../../compartido/components/request-button/RequestButton";
import * as Icon from "react-bootstrap-icons";
import Input, { InputTypes } from "../../../../../../libreria/data/input/Input";
import Habilitador from "../../../../../../libreria/action/habilitador/Habilitador";
import Swal from "sweetalert2";
import { AlquilerGet, AlquilerService, CANALES_WEB_SOCKET,  MaquinaSimbolicaAlquiler, MaquinaSimbolicaAlquilerGet, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";


export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  alquiler: AlquilerGet;
}

interface State {
  maquinasOld: Array<MaquinaSimbolicaAlquilerGet>;
  maquinasNew: Array<MaquinaSimbolicaAlquilerGet>;
  cargando: boolean;
  screen: ScreenType;
}

interface Message {
  type: string;
  idAlquiler: number;
  idMaquina: number;
}

export default class MaquinasAlquilerUpdate extends Component<Props, State> {

  private alquilerService:AlquilerService;

  constructor(props: Props) {
    super(props);
    this.state = {
      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
      maquinasOld: [],
      maquinasNew: [],
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES_MAQUINAS,
      funcionCallback: this.onChannelMesagge,
    });

    this.alquilerService =  new AlquilerService(Global.UsuarioService.getToken()!);


    window.addEventListener("resize", this.actualizarDimensiones);
  }

  componentDidMount = async () => {
    const maquinas = await this.alquilerService.getMaquinas(this.props.alquiler.id);
    this.setState({
      maquinasOld: maquinas,
      maquinasNew: this.getCopy(maquinas),
      cargando: false,
    });
  };

  getCopy = (
    maquinas: Array<MaquinaSimbolicaAlquilerGet>
  ): Array<MaquinaSimbolicaAlquilerGet> => {
    let maquinasCopy: Array<MaquinaSimbolicaAlquilerGet> = [];
    maquinas.map((x) =>
      maquinasCopy.push({
        maquina_simbolica: x.maquina_simbolica,
        cantidad: x.cantidad,
      })
    );
    return maquinasCopy;
  };

  onChannelMesagge = async (e: MessageEvent) => {
    let message: Message = JSON.parse(e.data).message;

    if (
      message.idAlquiler == this.props.alquiler.id &&
      (message.type == "new" || message.type == "update")
    ) {
      const maquinas = await this.alquilerService.getMaquinas(
        this.props.alquiler.id
      );
      this.setState({
        maquinasOld: maquinas,
        maquinasNew: this.getCopy(maquinas),
        cargando: false,
      });
    }
    if (
      message.idAlquiler == this.props.alquiler.id &&
      message.type == "delete"
    ) {
      let id: number = message.idMaquina;
      const maquinas = this.state.maquinasNew.filter(
        (x) => x.maquina_simbolica.id != id
      );
      this.setState({
        maquinasOld: maquinas,
        maquinasNew: this.getCopy(maquinas),
        cargando: false,
      });
    }
  };

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      //this.setState({x:X})
    }
  };

  eliminarMaquina = async (id: number) => {
    try {
      await Swal.fire({
        icon: "question",
        title: "¿Está seguro en eliminar una maquina ?",
        showDenyButton: true,
        confirmButtonText: "Confirmar",
        denyButtonText: "Cancelar",
      }).then(async (result) => {
        if (!result.isDenied) {
          debugger;

          let response = await this.alquilerService.eliminarMaquina(
            this.props.alquiler.id,
            id
          );

          WebSocketService.enviarMensaje(
            CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES_MAQUINAS,
            {
              type: "delete",
              idAlquiler: this.props.alquiler.id,
              idMaquina: id,
            }
          );

          const maquinas = this.state.maquinasNew.filter(
            (x) => x.maquina_simbolica.id != id
          );
          this.setState({
            maquinasOld: maquinas,
            maquinasNew: this.getCopy(maquinas),
            cargando: false,
          });

          await Swal.fire({
            icon: "success",
            text: "Maquina Eliminado",
          });
        }
      });
    } catch (error) {
      this.alquilerService.manejarErrorHTTP(
        error,
        "Eliminación de Maquina Alquiler"
      );
    }
  };

  actualizarMaquina = async (id: number) => {
    try {
      await this.alquilerService.actualizarMaquina(
        this.props.alquiler.id,
        id,
        this.state.maquinasNew.filter((x) => x.maquina_simbolica.id == id)[0]
          .cantidad
      );

      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES_MAQUINAS,
        {
          type: "update",
          idAlquiler: this.props.alquiler.id,
          idMaquina: id,
        }
      );

      await Swal.fire({
        icon: "success",
        text: "Maquina Actualizada",
      });
    } catch (error) {
      this.alquilerService.manejarErrorHTTP(error, "Actualización Maquina Alquiler");
    }
  };

  onChange = (id: number, cantidad: number) => {
    let maquinasNew = this.state.maquinasNew;
    try {
      debugger;
      maquinasNew.filter(
        (x: MaquinaSimbolicaAlquilerGet) => x.maquina_simbolica.id == id
      )[0].cantidad = cantidad;
      this.setState({ maquinasNew: maquinasNew });
    } catch (e) {}
  };

  renderizarFilas = () => {
    return this.state.maquinasNew.map((maquina, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{maquina.maquina_simbolica.nombre}</td>
          <td className="text-center">
            <Habilitador>
              <Input
                type={InputTypes.Number}
                data={maquina.cantidad}
                placeholder="Cantidad"
                onChange={(cantidad: number) =>
                  this.onChange(maquina.maquina_simbolica.id, cantidad)
                }
              />
            </Habilitador>
          </td>
          <td className="text-center">
            <RequestButton
              texto="Actualizar"
              propsBoton={{ variant: "light" }}
              icon={<Icon.ArrowRepeat />}
              onClick={() =>
                this.actualizarMaquina(maquina.maquina_simbolica.id)
              }
            />
          </td>
          <td className="text-center">
            <RequestButton
              texto="Eliminar"
              propsBoton={{ variant: "light" }}
              icon={<Icon.ArrowRepeat />}
              onClick={() => this.eliminarMaquina(maquina.maquina_simbolica.id)}
            />
          </td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Maquina</th>
            <th className="text-center">Cantidad</th>
            <th className="text-center">Actualizar</th>
            <th className="text-center">Eliminar</th>
          </tr>
        </thead>

        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <H size={4} texto={"Maquinas Actuales"} />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="15px"
        >
          {this.renderizarTabla()}
        </Flex>
      </Flex>
    );
  }
}
