import React, { Component } from "react";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import Visibilizador from "../../../../../libreria/action/visibilizador/Visibilizador";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Text from "../../../../../libreria/message/text/Text";


import { CANALES_WEB_SOCKET, DatosRecorrido, Recorrido, RecorridoGet, RecorridoService,  WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";



export interface Props {
  idRecorrido: number;
}
interface State {
  recorrido: RecorridoGet | null;
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
}

export default class DatosRecorridoShow extends Component<Props, State> {

  private recorridoService:RecorridoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      recorrido: null,
      cargando: true,
      visible: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.recorridoService =  new RecorridoService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {
    await this.cargarRecorrido();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS,
      funcionCallback: this.onChannelMesagge,
    });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.recorridoId == this.props.idRecorrido) {
      await this.cargarRecorrido();
    }
  };

  cargarRecorrido = async () => {
    let recorrido = await this.recorridoService.get(this.props.idRecorrido).catch(
      (error) => {
        this.recorridoService.manejarErrorHTTP(error, "Recorrido");
        return null;
      }
    );
    this.setState({ recorrido: recorrido, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      await this.setState({ cargando: true });
      await this.cargarRecorrido();
    }
  };

  renderDatos = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      <Text>Nombre: {this.state.recorrido!.nombre}</Text>
    </Flex>
  );

  renderLoading = () => "Cargando Datos";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border={this.state.visible ? "1px solid grey" : undefined}
        borderRadius="5px"
        padding="20px"
      >
        <Visibilizador
          onChange={this.onChangeVisibilizador}
          label={"Datos"}
          labelAlignment={"flex-start"}
        >
          {this.state.cargando ? this.renderLoading() : this.renderDatos()}
        </Visibilizador>
      </Flex>
    );
  }
}
