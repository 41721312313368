import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import { CANALES_WEB_SOCKET, ElementoComputacionGet, ElementoComputacionPost, ElementoComputacionService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";

import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";

export interface Props {
  ocultar: Function;
}

interface State {
  elementoComputacion: ElementoComputacionPost;
  alerta: any;
}

export default class ElementoComputacionNuevo extends Component<Props, State> {
  private elementoComputacionService:ElementoComputacionService;

  constructor(props: Props) {
    super(props);
    this.state = {
      elementoComputacion: this.elementoComputacionCrear(),
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };
    this.elementoComputacionService =  new ElementoComputacionService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    const elementoComputacionCrear: ElementoComputacionPost =
      this.state.elementoComputacion;
    this.setState({
      elementoComputacion: elementoComputacionCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  elementoComputacionCrear(): ElementoComputacionPost {
    return {
      nombre: "",
      marca: "",
      descripcion: "",
    };
  }

  setearNombre = (nombre: string) => {
    let elementoComputacion = this.state.elementoComputacion;
    elementoComputacion.nombre = nombre;
    this.setState({ elementoComputacion: elementoComputacion });
  };
  setearMarca = (marca: string) => {
    let elementoComputacion = this.state.elementoComputacion;
    elementoComputacion.marca = marca;
    this.setState({ elementoComputacion: elementoComputacion });
  };
  setearDescripcion = (descripcion: string) => {
    let elementoComputacion = this.state.elementoComputacion;
    elementoComputacion.descripcion = descripcion;
    this.setState({ elementoComputacion: elementoComputacion });
  };

  handleCreate() {
    this.elementoComputacionService.create(this.state.elementoComputacion)
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_ELEMENTOS_COMPUTACION
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Elemento de Computacion Creado!",
            titulo: "Atención",
            estado: "block",
          },
        });
        const elementoComputacionCrear: ElementoComputacionPost =
          this.elementoComputacionCrear();
        this.setState({
          elementoComputacion: elementoComputacionCrear,
        });
      })
      .catch((error) => {
        this.elementoComputacionService.manejarErrorHTTP(
          error,
          "Elemento Computación"
        );
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.elementoComputacion.nombre &&
      this.state.elementoComputacion.marca &&
      this.state.elementoComputacion.descripcion
      ? false
      : true;
  };

  renderizarFormulario() {
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="nombre">Nombre</label>
                <InputCaracteres
                  id="nombre"
                  name="nombre"
                  onChange={this.setearNombre}
                  value={this.state.elementoComputacion.nombre}
                  placeholder="Ingrese Nombre del Elemento de Computacion"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="marca">Marca</label>
                <InputCaracteres
                  id="marca"
                  name="marca"
                  onChange={this.setearMarca}
                  value={this.state.elementoComputacion.marca}
                  placeholder="Ingrese Marca"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="descripcion">Descripcion</label>
                <InputCaracteres
                  id="descripcion"
                  name="descripcion"
                  onChange={this.setearDescripcion}
                  value={this.state.elementoComputacion.descripcion}
                  placeholder="Ingrese Descripcion"
                />
              </div>
              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  ref="boton"
                  type="submit"
                  className={"btn btn-info"}
                  disabled={this.disableButton()}
                >
                  {"Crear"}
                </button>
                <button
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
              <div className={"form-group"}>
                <Alerta
                  tipo={this.state.alerta.tipo}
                  titulo={this.state.alerta.titulo}
                  mensaje={this.state.alerta.mensaje}
                  estado={this.state.alerta.estado}
                />
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
