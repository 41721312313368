import React, { Component } from "react";
import { EmpleadoGet, EmpleadoService } from "serviciossaintmichel";
import { Global } from "../../../../Global";

export interface Props {
  empleadoId: number;
}

interface State {
  empleado: EmpleadoGet | null;
}

export default class EmpleadoShow extends Component<Props, State> {

  private empleadoService:EmpleadoService;

  constructor(props: Props) {
    super(props);
    this.state = { empleado: null };
    this.empleadoService = new EmpleadoService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    const empleado = await this.empleadoService.get(this.props.empleadoId);
    this.setState({ empleado: empleado });
  }

  render() {
    const { empleado } = this.state;

    if (empleado === null) {
      return null;
    }

    return <span>{`${empleado.persona.nombre} ${empleado.persona.apellido}`}</span>;
  }
}
