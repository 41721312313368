import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { DireccionDTO } from "serviciossaintmichel";
import Flex from "../../../../../../compartido/components/flex/Flex";
import ClienteDireccionesShow, { Version } from "./ClienteDireccionesShow";
import ClienteDireccionNew from "./ClienteDireccionNew";
import ClienteDireccionUpdate from "./ClienteDireccionUpdate";

interface Props {
  clienteId: number;
}

interface State {
  direccionToUpdate: DireccionDTO | null;
  renderNew: boolean;
}

export default class ClienteActualizarDireccion extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      renderNew: false,
      direccionToUpdate: null,
    };
  }

  onDireccionUpdateRequest = (direccion: DireccionDTO) => {
    this.setState({ direccionToUpdate: direccion, renderNew: false });
  };

  onDireccionNewClose = () => {
    this.setState({ renderNew: false });
  };

  closeDireccionUpdate = () => {
    this.setState({ direccionToUpdate: null });
  };

  render = () => {
    const { clienteId } = this.props;
    const { renderNew, direccionToUpdate } = this.state;

    return (
      <Flex container justifyContent={"center"}>
        <Flex container maxWidth={"50%"} flexDirection={"column"} marginBottom={"2rem"}>
          <ClienteDireccionesShow
            clienteId={clienteId}
            showUpdate
            showDelete
            onUpdate={this.onDireccionUpdateRequest}
            onDelete={this.closeDireccionUpdate}
            version={Version.LIST}
          />
          {!renderNew && (
            <Flex container justifyContent={"center"} marginTop={"1rem"}>
              <Button onClick={() => this.setState({ renderNew: true, direccionToUpdate: null })}>
                Agregar dirección
              </Button>
            </Flex>
          )}
        </Flex>
        <Flex container justifyContent={"center"}>
          {renderNew && <ClienteDireccionNew clienteId={clienteId} onClose={this.onDireccionNewClose} />}
          {direccionToUpdate && (
            <ClienteDireccionUpdate
              clienteId={clienteId}
              direccionId={direccionToUpdate.id}
              onClose={this.closeDireccionUpdate}
            />
          )}
        </Flex>
      </Flex>
    );
  };
}
