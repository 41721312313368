import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Box from "../../../../../libreria/containers/box/Box";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../libreria/appearance/margin/Margin";
import H from "../../../../../libreria/message/title/H";
import DatosConsumoUpdate from "./datos/DatosConsumoUpdate";
import ProductosConsumo from "./productos/ProductosConsumo";
import MaquinasConsumo from "./maquinas/MaquinasConsumo";

import { CANALES_WEB_SOCKET,  ConsumoGet,  ConsumoService,               ProductoConsumoGet,               WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";

export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  id: number;
}
interface State {
  consumo: ConsumoGet | null;
  cargando: boolean;
  screen: ScreenType;
}

export default class ConsumoUpdate extends Component<Props, State> {

  private consumoService:ConsumoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      consumo: null,
      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CONSUMOS,
      funcionCallback: this.onChannelMesagge,
    });
    this.consumoService =  new ConsumoService(Global.UsuarioService.getToken()!);


    window.addEventListener("resize", this.actualizarDimensiones);
  }

  componentDidMount = async () => {
    const consumo = await this.consumoService.get(this.props.id);
    this.setState({ consumo: consumo, cargando: false });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    const consumo = await this.consumoService.get(this.props.id);
    this.setState({ consumo: consumo, cargando: false });
  };

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      //this.setState({x:X})
    }
  };

  renderizar = () =>
    this.state.consumo != null ? (
      <Margin top="20px">
        <Box padding="15px">
          <H alineacion={"center"} size={3} texto={this.state.consumo.nombre} />
          <Margin top="20px" />
          <Tabs
            defaultActiveKey={"datos"}
            transition={false}
            variant={this.state.screen == ScreenType.PHONE ? "pills" : "tabs"}
            style={{
              marginBottom: "1rem",
              flexDirection:
                this.state.screen == ScreenType.PHONE ? "column" : "row",
              alignItems: this.state.screen == ScreenType.PHONE ? "center" : "",
            }}
            unmountOnExit={true}
          >
            <Tab eventKey="datos" title="Datos">
              {<DatosConsumoUpdate datosConsumo={this.state.consumo} />}
            </Tab>
            <Tab eventKey="productos" title="Productos">
              {<ProductosConsumo consumo={this.state.consumo} />}
            </Tab>
            <Tab eventKey="maquinas" title="Maquinas">
              {<MaquinasConsumo consumo={this.state.consumo} />}
            </Tab>
          </Tabs>
          <Margin top="20px" />
        </Box>
      </Margin>
    ) : null;

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <div style={{ display: "block", width: "100%" }}>
          {this.renderizar()}
        </div>
      </Flex>
    );
  }
}
