import React, { Component } from "react";
import InputCharacter from "./character/InputCharacter";
import InputDate from "./date/InputDate";
import InputEmail from "./email/InputEmail";
import InputNumber from "./number/InputNumber";
import InputPhone from "./phone/InputPhone";
import InputText from "./text/InputText";

export enum InputTypes {
  Number,
  Text,
  Character,
  Email,
  Date,
  Phone,
}

interface Props {
  data?: any;
  onChange: Function;
  type: InputTypes;
  onValidationChange?: Function;

  // General Properties
  fontFamily?: string;
  placeholder?: string;
  label?: string;
  error?: string;
  disabled?: boolean;

  // Properties of Number Type

  min?: number;
  max?: number;
  decimal?: number;
  integers?: number;
}

interface State {
  data?: any;
  error: string;
  disabled: boolean;
}

class Input extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: this.props.data,
      error: this.props.error ? this.props.error : "",
      disabled: this.props.disabled ? this.props.disabled : false,
    };
  }

  componentDidMount() {}

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps != this.props) {
      let data: any = this.props.data;
      let error: string = this.props.error ? this.props.error : "";
      let disabled: boolean = this.props.disabled ? this.props.disabled : false;

      this.setState({ data: data, error: error, disabled: disabled });
    }
    /*
    if (prevProps.data !=  this.props.data) {
      this.setState({ data: this.props.data });
    }
*/
  };

  onChange = (data: any) => {
    this.setState({ data: data });
    this.props.onChange(data);
  };

  onValidationChange = (valid: boolean) => {
    if (this.props.onValidationChange != undefined) this.props.onValidationChange(valid);
  };

  renderCharacter = () => {
    return (
      <InputCharacter
        disabled={this.state.disabled}
        error={this.state.error}
        fontFamily={this.props.fontFamily}
        placeholder={this.props.placeholder}
        label={this.props.label}
        onValidationChange={this.props.onValidationChange}
        data={this.state.data}
        onChange={this.onChange}
      />
    );
  };
  renderText = () => {
    return (
      <InputText
        disabled={this.state.disabled}
        error={this.state.error}
        fontFamily={this.props.fontFamily}
        placeholder={this.props.placeholder}
        label={this.props.label}
        data={this.state.data}
        onChange={this.onChange}
      />
    );
  };
  renderEmail = () => {
    return (
      <InputEmail
        disabled={this.state.disabled}
        error={this.state.error}
        fontFamily={this.props.fontFamily}
        placeholder={this.props.placeholder}
        label={this.props.label}
        data={this.state.data}
        onValidationChange={this.props.onValidationChange}
        onChange={this.onChange}
      />
    );
  };
  renderNumber = () => {
    return (
      <InputNumber
        disabled={this.state.disabled}
        error={this.state.error}
        max={this.props.max}
        integers={this.props.integers}
        decimal={this.props.decimal}
        min={this.props.min}
        fontFamily={this.props.fontFamily}
        placeholder={this.props.placeholder}
        label={this.props.label}
        data={this.state.data}
        onValidationChange={this.props.onValidationChange}
        onChange={this.onChange}
      />
    );
  };
  renderDate = () => {
    return (
      <InputDate
        disabled={this.state.disabled}
        error={this.state.error}
        fontFamily={this.props.fontFamily}
        placeholder={this.props.placeholder}
        label={this.props.label}
        data={this.state.data}
        onChange={this.onChange}
      />
    );
  };
  renderPhone = () => {
    return (
      <InputPhone
        disabled={this.state.disabled}
        error={this.state.error}
        fontFamily={this.props.fontFamily}
        placeholder={this.props.placeholder}
        label={this.props.label}
        data={this.state.data}
        onChange={this.onChange}
      />
    );
  };

  render() {
    if (this.props.type == InputTypes.Number) return this.renderNumber();
    else if (this.props.type == InputTypes.Text) return this.renderText();
    else if (this.props.type == InputTypes.Character) return this.renderCharacter();
    else if (this.props.type == InputTypes.Email) return this.renderEmail();
    else if (this.props.type == InputTypes.Date) return this.renderDate();
    else if (this.props.type == InputTypes.Phone) return this.renderPhone();
    else return null;
  }
}

export default Input;
