import React, { Component } from "react";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../libreria/appearance/margin/Margin";
import Box from "../../../../../libreria/containers/box/Box";
import H from "../../../../../libreria/message/title/H";
import DatosConsumoShow from "./datos/DatosConsumoShow";
import MaquinasConsumoShow from "./maquinas/MaquinasConsumoShow";
import ProductosConsumoShow from "./productos/ProductosConsumosShow";


import { CANALES_WEB_SOCKET,  ConsumoGet,  ConsumoService,   WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";



export interface Props {
  id: number;
}
interface State {
  consumo: ConsumoGet | null;
  cargando: boolean;
}

export default class ConsumoShow extends Component<Props, State> {

  private consumoService:ConsumoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      consumo: null,
      cargando: true,
    };

    this.consumoService =  new ConsumoService(Global.UsuarioService.getToken()!);


    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CONSUMOS,
      funcionCallback: this.onChannelMesagge,
    });
  }

  componentDidMount = async () => {
    const consumo = await this.consumoService.get(this.props.id);
    this.setState({ consumo: consumo, cargando: false });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    const consumo = await this.consumoService.get(this.props.id);
    this.setState({ consumo: consumo, cargando: false });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      this.setState({ cargando: true });
      const consumo = await this.consumoService.get(this.props.id);
      this.setState({ consumo: consumo, cargando: false });
    }
  };

  renderConsumo = () => (
    <Flex
      container
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <H alineacion={"center"} size={3} texto={this.state.consumo!.nombre} />
      <Margin top="30px" />
      <DatosConsumoShow id={this.state.consumo!.id} />
      <Margin top="25px" />
      <ProductosConsumoShow id={this.state.consumo!.id} />
      <Margin bottom="50px" />
      <MaquinasConsumoShow id={this.state.consumo!.id} />
      <Margin bottom="50px" />
    </Flex>
  );

  renderLoading = () => "Cargando Datos";

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Margin top="20px" />
        <Box padding="15px">
          {this.state.cargando ? this.renderLoading() : this.renderConsumo()}
        </Box>
      </Flex>
    );
  }
}
