import React, { Component } from "react";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import { Fuentes } from "../../../../../../../compartido/estilos/estilos";
import FechaUtils from "../../../../../../../compartido/utils/FechaUtils";
import RequestButton from "../../../../../../../libreria/action/request-button/RequestButton";
import H from "../../../../../../../libreria/message/title/H";
import MaquinasSimbolicasOrdenCompraShow from "./ordenes-compra-maquinas/MaquinasSimbolicasOrdenCompraShow";
import OrdenesCompraProductoShow from "./ordenes-compra-productos/OrdenesCompraProductoShow";

import { CANALES_WEB_SOCKET, OrdenCompra, OrdenCompraService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../../Global";



export interface Props {
  ordenCompraId: number;
  showDeleteBtn: boolean;
}

interface State {
  ordenCompra: OrdenCompra | null;
}

export default class OrdenCompraShow extends Component<Props, State> {
  private webSocket: WebSocket | null;

  private ordenCompraService:OrdenCompraService;

  constructor(props: Props) {
    super(props);
    this.state = {
      ordenCompra: null,
    };
    this.webSocket = null;

    this.ordenCompraService =  new OrdenCompraService(Global.UsuarioService.getToken()!);

  }

  componentWillUnmount = () => {
    this.webSocket?.close();
  };

  componentDidMount = async () => {
    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_ORDENES_COMPRA,
      funcionCallback: this.ordenCompraWSHandler,
    });

    this.setOrdenCompra(this.props.ordenCompraId);
  };

  setOrdenCompra = async (ordenCompraId: number) => {
    try {
      const [ordenCompra, productosOrdenCompra, maquinasOrdenCompra] =
        await Promise.all([
          this.ordenCompraService.get(this.props.ordenCompraId),
          this.ordenCompraService.getProductosOrdenCompra(this.props.ordenCompraId),
          this.ordenCompraService.getMaquinasOrdenCompra(this.props.ordenCompraId),
        ]);

      this.setState({
        ordenCompra: {
          ...ordenCompra,
          productos: productosOrdenCompra,
          maquinas_simbolicas: maquinasOrdenCompra,
        },
      });
    } catch (error) {
      this.ordenCompraService.manejarErrorHTTP("OrdenCompra");
    }
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevProps.ordenCompraId !== this.props.ordenCompraId) {
      this.setOrdenCompra(this.props.ordenCompraId);
    }
  };

  ordenCompraWSHandler = async () => {
    const ordenCompra = await this.ordenCompraService.get(this.props.ordenCompraId);
    this.setState({ ordenCompra: ordenCompra });
  };

  eliminarOrdenCompra = async (ordenCompraId: number) => {
    try {
      this.ordenCompraService.delete(ordenCompraId);
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_SERVICIO_ACTIVO,
        {
          clienteId: this.state.ordenCompra?.cliente,
        }
      );
    } catch (error) {
      this.ordenCompraService.manejarErrorHTTP("OrdenCompra");
    }
  };

  render = () => {
    const { ordenCompra } = this.state;
    const { showDeleteBtn } = this.props;

    if (ordenCompra === null) {
      return <div>Cargando datos...</div>;
    }

    const fechaInicio = FechaUtils.normalizarFecha(ordenCompra.fecha_inicio);
    const fechaFin = FechaUtils.normalizarFecha(ordenCompra.fecha_fin);

    let fechaFinEfectiva = ordenCompra.fecha_fin_efectiva;
    if (fechaFinEfectiva) {
      fechaFinEfectiva = FechaUtils.normalizarFecha(fechaFinEfectiva);
    }

    return (
      <>
        <Flex container flexDirection="column" marginTop="1rem">
          <span>Fecha de inicio</span>
          <div>{fechaInicio.toLocaleDateString("es-AR")}</div>
        </Flex>
        <Flex container flexDirection="column" marginTop="1rem">
          <span>Fecha de finalización</span>
          <div>{fechaFin.toLocaleDateString("es-AR")}</div>
        </Flex>
        <Flex container flexDirection="column" marginTop="1rem">
          <span>Fecha de finalización efectiva</span>
          <div>
            {fechaFinEfectiva === null
              ? "-"
              : fechaFinEfectiva.toLocaleDateString("es-AR")}
          </div>
        </Flex>
        <Flex container flexDirection="column" marginTop="1rem">
          <H size={4} fuente={Fuentes.Titulos}>
            Productos
          </H>
          <OrdenesCompraProductoShow
            ordenesCompraProducto={ordenCompra.productos}
          />
        </Flex>
        <Flex container flexDirection="column" marginTop="1rem">
          <H size={4} fuente={Fuentes.Titulos}>
            Máquinas
          </H>
          <MaquinasSimbolicasOrdenCompraShow
            maquinasOrdenCompra={ordenCompra.maquinas_simbolicas}
          />
        </Flex>
        <Flex
          container
          flexDirection="row"
          justifyContent="center"
          marginTop="1rem"
        >
          {showDeleteBtn && (
            <RequestButton
              texto="Eliminar"
              propsBoton={{
                variant: "danger",
              }}
              onClick={() => {
                this.eliminarOrdenCompra(ordenCompra.id);
              }}
              confirmacion={true}
            />
          )}
        </Flex>
      </>
    );
  };
}
