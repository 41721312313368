import React, { Component } from "react";
import { InsumoGet } from "serviciossaintmichel";
import SelectGenerico from "../../../../compartido/components/select/SelectGenerico";

interface Props {
  callbackParent: Function;
  seleccionado: InsumoGet;
  insumos: Array<InsumoGet>;
}
interface State {}

export default class SelectInsumo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seleccionado: this.props.seleccionado,
    };
  }

  getPosicion = (insumo: InsumoGet): number => {
    return this.props.insumos.findIndex((e) => e.id === insumo.id);
  };
  getSeleccionado = (indice: number) => {
    this.props.callbackParent(this.props.insumos[indice]);
  };

  render() {
    return (
      <SelectGenerico
        titulo="Insumos"
        seleccionado={this.getPosicion(this.props.seleccionado)}
        elementos={this.props.insumos.map(
          (t) => "" + t.nombre + " - " + t.tipo_insumo.tipo
        )}
        callback={this.getSeleccionado}
      />
    );
  }
}
