import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import TipoMateriales from "./TipoMaterial/components/TipoMateriales";
import Materiales from "./Material/components/Materiales";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
export interface Props {}


export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];

  if (Global.UsuarioService.getPermisos().Elementos.Materiales.TipoMaterial.hasAccess()) {
    aux.push({name: "Tipo Materiales",
    path: SeccionMateriales.pathBase + "/tipo-materiales",
    component: TipoMateriales,})
  }
  if (Global.UsuarioService.getPermisos().Elementos.Materiales.Material.hasAccess()) {
    aux.push({
      name: "Materiales",
      path: SeccionMateriales.pathBase + "/materiales",
      component: Materiales,
    })
  }
  return aux;
}



export default class SeccionMateriales extends Component<Props> {
  static pathBase = "/elementos/seccion-materiales";
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Materiales"}
      />
    );
  }
}
