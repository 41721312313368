import React, { Component } from "react";
import Ruta from "../../compartido/models/Ruta";
import Seccion from "../../compartido/components/seccion/Seccion";
import { Global } from "../../Global";
import Facturacion from "./facturacion/Facturacion";

export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  if (!Global.UsuarioService.getPermisos().Administracion.Facturacion.Facturacion.hasAccess()) {
    return [];
  }

  return [
    {
      name: "Facturación",
      path: SeccionFacturacion.pathBase + "/facturacion",
      component: Facturacion,
    },
  ];
}

export default class SeccionFacturacion extends Component<Props> {
  static pathBase = "/administracion/seccion-facturacion";

  render() {
    return <Seccion rutasNavbar={getRutasAccesibles()} tituloNavbar={"Sección Facturación"} />;
  }
}
