import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
import LogisticaPedidos from "./components/LogisticaPedidos";

export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];

  if (Global.UsuarioService.getPermisos().Distribucion.LogisticaPedidos.LogisticaPedido.hasAccess()) {
    aux.push({
      name: "LogisticaPedido",
      path: SeccionLogisticaPedidos.pathBase + "/logistica-pedidos",
      component: LogisticaPedidos,
    });
  }
  return aux;
}

export default class SeccionLogisticaPedidos extends Component<Props> {
  static pathBase = "/distribucion/seccion-logistica-pedidos";
  render() {
    return <Seccion rutasNavbar={getRutasAccesibles()} tituloNavbar={"Sección Logistica de Pedidos"} />;
  }
}
