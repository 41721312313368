import React, { Component } from "react";
import TipoInsumoActualizar from "./TipoInsumoActualizar";
import TipoInsumoLista from "./TipoInsumoLista";
import TipoInsumoNuevo from "./TipoInsumoNuevo";
import Flex from "../../../../compartido/components/flex/Flex";
import { Tabs, Tab } from "react-bootstrap";
import { Global } from "../../../../Global";

export interface Props {}

interface State {
  tabVisible: any;
  disabledActualizar: boolean;
  disabledVer: boolean;
  responsive: boolean;
  tipoInsumoId: number | null;
}

export default class TipoInsumos extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabVisible: "lista",
      disabledActualizar: true,
      disabledVer: true,
      responsive: window.innerWidth < 700 ? true : false,
      tipoInsumoId: null,
    };

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  onSelect = (eventKey: any, x?: any) => {
    this.setState({ tabVisible: eventKey });
  };

  actualizar = (tipoInsumoId: number) => {
    this.setState({
      tabVisible: "actualizar",
      disabledActualizar: false,
      tipoInsumoId: tipoInsumoId,
    });
  };

  ver = (tipoInsumoId: number) => {
    this.setState({
      tabVisible: "ver",
      disabledVer: false,
      tipoInsumoId: tipoInsumoId,
    });
  };

  ocultarActualizar = () => {
    this.setState({
      tabVisible: "lista",
      disabledActualizar: true,
      tipoInsumoId: null,
    });
  };

  ocultarNuevo = () => {
    this.setState({
      tabVisible: "lista",
    });
  };

  renderizarNuevo = () => {
    if (Global.UsuarioService.getPermisos().Elementos.Insumos.TipoInsumo.canAdd()) {
      return (
        <Tab eventKey="nuevo" title="Nuevo">
          {this.state.tabVisible === "nuevo" ? (
            <TipoInsumoNuevo ocultar={this.ocultarNuevo} />
          ) : null}
        </Tab>
      );
    }
  };

  renderizarLista = () => {
    if (Global.UsuarioService.getPermisos().Elementos.Insumos.TipoInsumo.canView()) {
      return (
        <Tab eventKey="lista" title="Lista">
          <TipoInsumoLista ver={this.ver} actualizar={this.actualizar} />
        </Tab>
      );
    }
  };

  renderizarActualizar = () => {
    if (Global.UsuarioService.getPermisos().Elementos.Insumos.TipoInsumo.canChange()) {
      return (
        <Tab
          eventKey="actualizar"
          title="Actualizar"
          disabled={this.state.disabledActualizar}
        >
          {this.state.tipoInsumoId && this.state.tabVisible === "actualizar" ? (
            <TipoInsumoActualizar
              tipoInsumoId={this.state.tipoInsumoId}
              ocultar={this.ocultarActualizar}
            />
          ) : null}
        </Tab>
      );
    }
  };

  renderizar = (responsive: boolean) => (
    <Tabs
      defaultActiveKey="lista"
      transition={false}
      activeKey={this.state.tabVisible}
      onSelect={this.onSelect}
      variant={responsive ? "pills" : "tabs"}
      style={{
        marginBottom: "1rem",
        flexDirection: responsive ? "column" : "row",
        alignItems: responsive ? "center" : "",
      }}
      unmountOnExit={true}
    >
      {this.renderizarNuevo()}
      {this.renderizarLista()}
      {this.renderizarActualizar()}
    </Tabs>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {this.renderizar(this.state.responsive)}
      </Flex>
    );
  }
}
