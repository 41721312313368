import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Flex from "../../../appearance/flex/Flex";
import { Font } from "../../models/models";
import InputNumber from "../number/InputNumber";
import Text from "../../../message/text/Text";

export interface Phone {
  prefix: string | null;
  phone: string | null;
}

interface Props {
  data?: Phone;
  onChange: Function;
  fontFamily?: string;
  label?: string;
  placeholder?: string;
  error?: string;
  onValidationChange?: Function;
  disabled?: boolean;
}

interface State {
  data: Phone;
  fontFamily: string;
  error: string;
  phoneValidation: boolean;
  prefixValidation: boolean;
  disabled: boolean;
}

class InputPhone extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let data: Phone = this.props.data ? this.props.data : { prefix: null, phone: null };

    this.state = {
      data: data,
      disabled: this.props.disabled ? this.props.disabled : false,
      error: this.props.error ? this.props.error : "",
      fontFamily: this.props.fontFamily ? this.props.fontFamily : Font.family,
      phoneValidation: data.phone != null ? true : false,
      prefixValidation: data.prefix != null ? true : false,
    };
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.data != undefined && this.props.data == undefined) {
      this.setState({ data: { prefix: null, phone: null } });
    } else if (prevProps.data == undefined && this.props.data != undefined) {
      this.setState({ data: this.props.data });
    } else if (prevProps.data != undefined && this.props.data != undefined) {
      if (prevProps.data.prefix != this.props.data.prefix || prevProps.data.phone != this.props.data.phone)
        this.setState({ data: this.props.data });
    } else {
    }

    if (prevProps.error != undefined && this.props.error == undefined) {
      this.setState({ error: "" });
    } else if (prevProps.error == undefined && this.props.error != undefined) {
      this.setState({ error: this.props.error });
    } else if (prevProps.error != undefined && this.props.error != undefined) {
      if (prevProps.error != this.props.error) this.setState({ error: this.props.error });
    } else {
    }
  };

  onPrefixChange = (prefix: string) => {
    let data: Phone = this.state.data;
    data.prefix = prefix;
    this.setState({ data: data });
    this.props.onChange(data);
  };

  onPhoneChange = (phone: string) => {
    let data: Phone = this.state.data;
    data.phone = phone;
    this.setState({ data: data });
    this.props.onChange(data);
  };

  onPrefixValidationChange = (validation: boolean) => {
    this.setState({ prefixValidation: validation });
    if (this.props.onValidationChange != undefined)
      this.props.onValidationChange(this.state.phoneValidation && validation);
  };

  onPhoneValidationChange = (validation: boolean) => {
    this.setState({ phoneValidation: validation });
    if (this.props.onValidationChange != undefined)
      this.props.onValidationChange(this.state.prefixValidation && validation);
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        border={this.state.error != "" ? "1px solid red" : ""}
        borderRadius={this.state.error != "" ? "3px" : ""}
        padding="5%"
      >
        <Flex flexDirection="row" alignItems="flex-start" justifyContent="flex-start" width="100%">
          <Flex flexDirection="column" alignItems="center" justifyContent="center" width="18%" marginRigth="4%">
            <InputNumber
              data={this.state.data.prefix || undefined}
              disabled={this.state.disabled}
              onValidationChange={this.onPrefixValidationChange}
              onChange={this.onPrefixChange}
              label={"Prefijo"}
            />
          </Flex>

          <Flex flexDirection="column" alignItems="center" justifyContent="center" width="78%">
            <InputNumber
              data={this.state.data.phone || undefined}
              disabled={this.state.disabled}
              onValidationChange={this.onPhoneValidationChange}
              onChange={this.onPhoneChange}
              label={"Teléfono"}
            />
          </Flex>
        </Flex>

        {this.state.error != "" ? (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" width="100%">
            <Text color="red" size="16" align={"left"}>
              {this.state.error}
            </Text>
          </Flex>
        ) : null}
      </Flex>
    );
  }
}

export default InputPhone;
