import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";


import { CANALES_WEB_SOCKET, ProductoSeguridadHigieneGet, ProductoSeguridadHigienePost, ProductoSeguridadHigieneService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  ocultar: Function;
}

interface State {
  productoSeguridadHigiene: ProductoSeguridadHigienePost;
  alerta: any;
}

export default class ProductoSeguridadHigieneNuevo extends Component<
  Props,
  State
> {

  private productoSeguridadHigieneService:ProductoSeguridadHigieneService;

  constructor(props: Props) {
    super(props);
    this.state = {
      productoSeguridadHigiene: this.productoSeguridadHigieneCrear(),
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };

    this.productoSeguridadHigieneService = new ProductoSeguridadHigieneService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    const productoSeguridadHigieneCrear: ProductoSeguridadHigienePost =
      this.state.productoSeguridadHigiene;
    this.setState({
      productoSeguridadHigiene: productoSeguridadHigieneCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  productoSeguridadHigieneCrear(): ProductoSeguridadHigienePost {
    return {
      nombre: "",
      marca: "",
      descripcion: "",
    };
  }

  setearNombre = (nombre: string) => {
    let productoSeguridadHigiene = this.state.productoSeguridadHigiene;
    productoSeguridadHigiene.nombre = nombre;
    this.setState({ productoSeguridadHigiene: productoSeguridadHigiene });
  };
  setearMarca = (marca: string) => {
    let productoSeguridadHigiene = this.state.productoSeguridadHigiene;
    productoSeguridadHigiene.marca = marca;
    this.setState({ productoSeguridadHigiene: productoSeguridadHigiene });
  };
  setearDescripcion = (descripcion: string) => {
    let productoSeguridadHigiene = this.state.productoSeguridadHigiene;
    productoSeguridadHigiene.descripcion = descripcion;
    this.setState({ productoSeguridadHigiene: productoSeguridadHigiene });
  };

  handleCreate() {
    this.productoSeguridadHigieneService.create(this.state.productoSeguridadHigiene)
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_PROODUCTOS_SEGURIDAD_HIGIENE
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "ProductoSeguridadHigiene Creado!",
            titulo: "Atención",
            estado: "block",
          },
        });
        const productoSeguridadHigieneCrear: ProductoSeguridadHigienePost =
          this.productoSeguridadHigieneCrear();
        this.setState({
          productoSeguridadHigiene: productoSeguridadHigieneCrear,
        });
      })
      .catch((error) => {
        this.productoSeguridadHigieneService.manejarErrorHTTP(
          error,
          "Producto Seguridad Higiene"
        );
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.productoSeguridadHigiene.nombre &&
      this.state.productoSeguridadHigiene.marca &&
      this.state.productoSeguridadHigiene.descripcion
      ? false
      : true;
  };

  renderizarFormulario() {
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="nombre">Nombre</label>
                <InputCaracteres
                  id="nombre"
                  name="nombre"
                  onChange={this.setearNombre}
                  value={this.state.productoSeguridadHigiene.nombre}
                  placeholder="Ingrese Nombre de ProductoSeguridadHigiene"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="marca">Marca</label>
                <InputCaracteres
                  id="marca"
                  name="marca"
                  onChange={this.setearMarca}
                  value={this.state.productoSeguridadHigiene.marca}
                  placeholder="Ingrese Marca"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="descripcion">Descripcion</label>
                <InputCaracteres
                  id="descripcion"
                  name="descripcion"
                  onChange={this.setearDescripcion}
                  value={this.state.productoSeguridadHigiene.descripcion}
                  placeholder="Ingrese Descripcion"
                />
              </div>
              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  ref="boton"
                  type="submit"
                  className={"btn btn-info"}
                  disabled={this.disableButton()}
                >
                  {"Crear"}
                </button>
                <button
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
              <div className={"form-group"}>
                <Alerta
                  tipo={this.state.alerta.tipo}
                  titulo={this.state.alerta.titulo}
                  mensaje={this.state.alerta.mensaje}
                  estado={this.state.alerta.estado}
                />
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
