import React, { Component } from "react";
import { AlquilerCliente, AlquilerClienteService, CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import Flex from "../../../../../../../compartido/components/flex/Flex";
import RequestButton from "../../../../../../../compartido/components/request-button/RequestButton";

import { Global } from "../../../../../../../Global";
import ClienteAlquilerLoad from "./AlquilerClienteLoad";




export interface Props {
  clienteId: number;
  fechaInicioMin?: Date;
}

interface State {
  alquilerCliente: AlquilerCliente | null;
  alquilerClienteValido: boolean;
}

export default class AlquilerClienteNew extends Component<Props, State> {

  private alquilerClienteService:AlquilerClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      alquilerCliente: null,
      alquilerClienteValido: false,
    };

    this.alquilerClienteService = new AlquilerClienteService(Global.UsuarioService.getToken()!);

  }

  handleSubmit = async () => {
    const payload = this.state.alquilerCliente;

    if (payload === null) {
      return;
    }

    try {
      await this.alquilerClienteService.create(payload);
      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_SERVICIO_ACTIVO,
        {
          clienteId: this.props.clienteId,
        }
      );
    } catch (error) {
      this.alquilerClienteService.manejarErrorHTTP(error, "AlquilerCliente");
    }
  };

  onChange = (alquilerCliente: AlquilerCliente) => {
    this.setState({ alquilerCliente: alquilerCliente });
  };

  onValidationChange = (alquilerClienteValido: boolean) => {
    this.setState({ alquilerClienteValido: alquilerClienteValido });
  };

  render = () => {
    const { alquilerClienteValido } = this.state;
    const { clienteId, fechaInicioMin } = this.props;

    return (
      <>
        <ClienteAlquilerLoad
          alquilerCliente={null}
          alquiler={null}
          clienteId={clienteId}
          onChange={this.onChange}
          onValidationChange={this.onValidationChange}
          fechaInicioMin={fechaInicioMin}
        />
        <Flex container justifyContent="center" marginTop="1rem">
          <RequestButton
            propsBoton={{
              disabled: !alquilerClienteValido,
              variant: "success",
            }}
            onClick={this.handleSubmit}
            texto={"Guardar"}
          />
        </Flex>
      </>
    );
  };
}
