import React, { Component } from "react";
import { CANALES_WEB_SOCKET, ComboRetornableService, ProductoComboRetornable, WebSocketService } from "serviciossaintmichel";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import { Global } from "../../../../../../Global";
import RequestButton from "../../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import H from "../../../../../../libreria/message/title/H";
import ProductoComboRetornableLoad from "./ProductoComboRetornableLoad";




export interface Props {
  onSaveSuccess?: Function;
  onExit?: Function;
  idComboRetornable: number;
}

interface State {
  producto: ProductoComboRetornable;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class ProductoComboRetornableNew extends Component<
  Props,
  State
> {

  private comboRetornableService:ComboRetornableService;

  constructor(props: Props) {
    super(props);
    this.state = {
      producto: this.createEmptyProducto(),
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.comboRetornableService =  new ComboRetornableService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  createEmptyProducto = (): ProductoComboRetornable => {
    return {
      producto: {
        id: -1,
        volumen: 0,
        nombre: "",
        tipo_producto: { id: -1, tipo: "" },
      },
      cantidad_cobrable: 0,
      cantidad_vacio: 0,
      activo: true,
    };
  };

  componentDidUpdate(props: Props, state: State) {
    if (this.state.producto.producto.id !== state.producto.producto.id) {
      this.setState({ producto: this.state.producto });
    }
  }
  onSave = async () => {
    try {
      await this.comboRetornableService.relacionarConProducto(
        this.props.idComboRetornable,
        this.state.producto.producto.id,
        this.state.producto.cantidad_vacio,
        this.state.producto.cantidad_cobrable
      );

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.ELEMENTOS_PRODUCTOS, {
        type: "update",
        productoId: this.state.producto.producto.id,
      });

      WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET.VENTAS_COMBOS_RETORNABLES,
        {
          type: "update",
          comboRetornableId: this.props.idComboRetornable,
        }
      );
      this.setState({ producto: this.createEmptyProducto(), valid: false });
      await Swal.fire({
        icon: "success",
        text: "Producto Actualizado",
      });
    } catch (error) {
      this.comboRetornableService.manejarErrorHTTP(
        error,
        "Nuevo Producto del Combo Retornable"
      );
    }
  };

  onExit = () => {
    if (this.props.onExit != undefined) this.props.onExit();
  };

  onChangeProducto = (producto: ProductoComboRetornable) => {
    this.setState({ producto: producto });
  };

  onChangeValidation = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border="1px solid grey"
        borderRadius="5px"
        padding="10px"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="10px"
        >
          <H size={4} texto={"Agregar Producto"} />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <ProductoComboRetornableLoad
            producto={this.state.producto}
            onChange={this.onChangeProducto}
            onValidationChange={this.onChangeValidation}
          />
        </Flex>

        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="20px"
        >
          <RequestButton
            propsBoton={{ disabled: !this.state.valid }}
            texto={"Agregar"}
            onClick={this.onSave}
          />
        </Flex>
      </Flex>
    );
  }
}
