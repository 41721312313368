import React, { Component } from "react";
import { LIMITES } from "../../../../../../compartido/components/select/SelectLimiteTabla";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import { Table } from "react-bootstrap";
import { Modal } from "@mantine/core";
import ComprobanteShow from "../../../../../seccion-pagos/pagos/components/show/ComprobanteShow";
import RequestButton from "../../../../../../libreria/action/request-button/RequestButton";
import { Box } from "../../../../../../libreria/containers/box/Box";
import FechaUtils from "../../../../../../compartido/utils/FechaUtils";

import { CANALES_WEB_SOCKET, WebSocketService, Pago, PagoService } from "serviciossaintmichel";
import { Global } from "../../../../../../Global";
import ImagenesURLs from "../../../../../../compartido/utils/ImagenesURLs";

export interface Props {
  idPedido: number;
}

interface State {
  pagos: Array<Pago>;
  pago: Pago | null;
}

export default class PagosPedidoShow extends Component<Props, State> {
  private pagoService: PagoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      pagos: [],
      pago: null,
    };

    this.pagoService = new PagoService(Global.UsuarioService.getToken()!);
  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PAGOS,
      funcionCallback: this.getPagos,
    });
    this.pagoService.paramsURL.setearLimite(LIMITES[0]);
    this.getPagos();
  }

  getPagos = () => {
    const filtros = {
      desde: null,
      hasta: null,
      tipo: null,
      facturaId: null,
      clienteId: null,
      pedidoId: this.props.idPedido,
      tipoPagoId: null,
      fuente: null,
    };

    this.pagoService
      .getPagos(filtros)
      .then((result) => {
        this.setState({ pagos: result });
      })
      .catch((error) => {
        this.pagoService.manejarErrorHTTP(error, "Pago");
      });
  };

  botonCompobrante = (pago: Pago) => {
    return (
      <RequestButton
        texto={"Ver Comprobante"}
        onClick={() => {
          this.setOpened(pago);
        }}
      />
    );
  };

  setOpened = (pago: Pago | null) => {
    this.setState({ pago: pago });
  };

  renderizarFilas = () => {
    return this.state.pagos.map((pago, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{FechaUtils.normalizarFecha(pago.fecha).toLocaleDateString("es-AR")}</td>
          <td className="text-center">{pago.monto}</td>
          <td className="text-center">{pago.tipo_pago.tipo}</td>
          <td className="text-center">{pago.comprobante ? this.botonCompobrante(pago) : "No tiene"}</td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Table bordered={true} responsive>
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th className="text-center">Fecha</th>
              <th className="text-center">Monto</th>
              <th className="text-center">Tipo de Pago</th>
              <th className="text-center">Comprobante</th>
            </tr>
          </thead>
          <tbody>{this.renderizarFilas()}</tbody>
        </Table>
        <Modal
          styles={{
            title: {
              fontSize: "larger",
            },
            modal: {
              backgroundImage: ImagenesURLs.urlFondoModal(),
            },
          }}
          opened={this.state.pago ? true : false}
          onClose={() => this.setOpened(null)}
          title="Comprobante"
        >
          {this.state.pago ? (
            <Box>
              <ComprobanteShow idPago={this.state.pago.id} comprobante={this.state.pago.comprobante} />
            </Box>
          ) : null}
        </Modal>
      </Flex>
    );
  };

  render() {
    if (this.state.pagos.length > 0)
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          borderRadius="5px"
          padding="2px"
        >
          {this.state.pagos.length > 0 ? this.renderizarTabla() : <>No tiene</>}
        </Flex>
      );
    else return null;
  }
}
