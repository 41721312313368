import React, { Component } from "react";
import Ruta from "../../compartido/models/Ruta";
import "./menu.css";

import "../template.css";
import { Navbar, Nav } from "react-bootstrap";
import MenuItem from "./MenuItem";
import Flex from "../../libreria/appearance/flex/Flex";

interface RutasMenu {
  nombre?: string;
  links: Array<Ruta>;
}

export interface Props {
  rutas: Array<RutasMenu>;
}

interface State {}

export default class Menu extends Component<Props, State> {
  renderizarRutas = (rutas: Array<RutasMenu>) => {
    return rutas.map((ruta) => {
      return <MenuItem key={ruta.nombre} links={ruta.links} nombre={ruta.nombre} />;
    });
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="85%"
        marginTop="20px"
        marginBottom="20px"
      >
        <Flex flexDirection="column" width="100%">
          <Navbar style={{ width: "100%", padding: "0px" }} className="navbarMenu" collapseOnSelect expand="lg">
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
              height="55px"
              width="200px"
              padding="10px"
            >
              <Navbar.Brand className={"font"} style={{ color: "#204399", fontSize: "20px", fontWeight: "bold" }}>
                {"Saint Michel App"}
              </Navbar.Brand>
            </Flex>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav style={{}} className="mr-auto">
                {this.renderizarRutas(this.props.rutas)}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Flex>
      </Flex>
    );
  }
}
