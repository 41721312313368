import React, { Component, FormEvent } from "react";
import Alerta from "../../../../compartido/components/alerta/Alerta";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";
import { CANALES_WEB_SOCKET, UnidadInsumoGet, UnidadInsumoPost, UnidadInsumoService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";





export interface Props {
  unidadInsumoId: number;
  ocultar: Function;
}

interface State {
  unidadInsumo: UnidadInsumoGet;
  alerta: any;
}

export default class UnidadInsumoActualizar extends Component<Props, State> {

  private unidadInsumoService:UnidadInsumoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      unidadInsumo: this.crearUnidadInsumoVacio(),
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };

    this.unidadInsumoService =  new UnidadInsumoService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    this.cargarUnidadInsumo(this.props.unidadInsumoId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      this.cargarUnidadInsumo(this.props.unidadInsumoId);
    }
  }
  crearUnidadInsumoVacio(): UnidadInsumoGet {
    const unidadInsumo: UnidadInsumoGet = {
      id: 0,
      unidad: "",
    };
    return unidadInsumo;
  }

  cargarUnidadInsumo = async (id: number) => {
    const resUnidadInsumo = await this.unidadInsumoService.get(id).catch((error) => {
      this.unidadInsumoService.manejarErrorHTTP(error, "Unidad Insumo");
      return null;
    });
    if (resUnidadInsumo != null) {
      this.setState({
        unidadInsumo: resUnidadInsumo,
      });
    }
  };
  ocultar = () => {
    this.props.ocultar();
  };

  setearUnidad = (unidad: string) => {
    let unidadInsumo = this.state.unidadInsumo;
    unidadInsumo.unidad = unidad;
    this.setState({ unidadInsumo: unidadInsumo });
  };

  handleUpdate() {
    const unidadInsumoActualizar: UnidadInsumoPost = {
      unidad: this.state.unidadInsumo.unidad,
    };
    this.unidadInsumoService.update(
      unidadInsumoActualizar,
      this.state.unidadInsumo.id
    )
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_UNIDAD_INSUMOS,
          { unidadInsumoId: this.state.unidadInsumo.id }
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Unidad Insumo Actualizado!",
            titulo: "Atención",
            estado: "block",
          },
        });
      })
      .catch((error) => {
        this.unidadInsumoService.manejarErrorHTTP(error, "Unidad Insumo");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleUpdate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.unidadInsumo.unidad ? false : true;
  };

  render() {
    if (this.state.unidadInsumo.id != 0) {
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingHorizontal="4rem" paddingVertical="2rem">
            <div className={"row"}>
              <div className={"column"}>
                <form
                  className={"form-horizontal"}
                  onSubmit={this.handleSubmit}
                >
                  <div className={"form-group"}>
                    <label htmlFor="unidad">Nombre de Unidad Insumo</label>
                    <InputCaracteres
                      id="unidad"
                      name="unidad"
                      onChange={this.setearUnidad}
                      value={this.state.unidadInsumo.unidad}
                      placeholder="Ingrese Nombre de UnidadInsumo"
                    />
                  </div>

                  <div
                    className={"form-group"}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button
                      ref="boton"
                      type="submit"
                      className={"btn btn-info"}
                      disabled={this.disableButton()}
                    >
                      {"Actualizar"}
                    </button>
                    <button
                      className={"btn btn-outline-secondary"}
                      onClick={this.ocultar}
                    >
                      Ocultar
                    </button>
                  </div>
                  <div className={"form-group"}>
                    <Alerta
                      tipo={this.state.alerta.tipo}
                      titulo={this.state.alerta.titulo}
                      mensaje={this.state.alerta.mensaje}
                      estado={this.state.alerta.estado}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Flex>
      );
    } else return null;
  }
}
