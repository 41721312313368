import React, { Component } from "react";
import Seccion from "../../compartido/components/seccion/Seccion";
import TipoInsumos from "./TipoInsumo/components/TipoInsumos";
import UnidadInsumos from "./UnidadInsumo/components/UnidadInsumos";
import Insumos from "./Insumo/components/Insumos";
import BultoInsumos from "./BultoInsumo/components/BultoInsumos";
import Ruta from "../../compartido/models/Ruta";
import { Global } from "../../Global";
export interface Props {}

export function getRutasAccesibles(): Array<Ruta> {
  let aux: Array<Ruta> = [];

  if (Global.UsuarioService.getPermisos().Elementos.Insumos.TipoInsumo.hasAccess()) {
    aux.push({
      name: "Tipo Insumos",
      path: SeccionInsumos.pathBase + "/tipo-insumos",
      component: TipoInsumos,
    });
  }
  if (Global.UsuarioService.getPermisos().Elementos.Insumos.UnidadInsumo.hasAccess()) {
    aux.push({
      name: "Unidad Insumos",
      path: SeccionInsumos.pathBase + "/unidad-insumos",
      component: UnidadInsumos,
    });
  }
  if (Global.UsuarioService.getPermisos().Elementos.Insumos.Insumo.hasAccess()) {
    aux.push({
      name: "Insumos",
      path: SeccionInsumos.pathBase + "/insumos",
      component: Insumos,
    });
  }
  if (Global.UsuarioService.getPermisos().Elementos.Insumos.BultoInsumo.hasAccess()) {
    aux.push({
      name: "Bulto Insumos",
      path: SeccionInsumos.pathBase + "/bulto-insumos",
      component: BultoInsumos,
    });
  }
  return aux;
}

export default class SeccionInsumos extends Component<Props> {
  static pathBase = "/elementos/seccion-insumos";
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Seccion
        rutasNavbar={getRutasAccesibles()}
        tituloNavbar={"Sección Insumos"}
      />
    );
  }
}
