import React, { Component } from "react";
import { Table } from "react-bootstrap";
import VentaProductoProgramacionEntregaLoad from "./VentaProductoProgramacionEntregaLoad";
import { EstadoLogisticaPaquete, VentaProgramacionBultoProducto } from "serviciossaintmichel";
import { Select } from "@mantine/core";

interface ConfigCampo {
  disabled: Array<string>;
  hidden: Array<string>;
}

export interface Props {
  crearNuevos?: boolean;
  configCampos?: ConfigCampo;
  ventasBultoProducto: Array<VentaProgramacionBultoProducto>;
  onChange?: Function;
}

interface State {
  ventasBultoProducto: Array<VentaProgramacionBultoProducto>;
}

export default class VentasBultoProductoProgramacionEntregaLoad extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ventasBultoProducto: props.ventasBultoProducto,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (
      prevProps.ventasBultoProducto !== this.props.ventasBultoProducto &&
      this.state.ventasBultoProducto !== this.props.ventasBultoProducto
    ) {
      this.setState({ ventasBultoProducto: this.props.ventasBultoProducto });
    }

    if (prevState.ventasBultoProducto !== this.state.ventasBultoProducto && this.props.onChange) {
      this.props.onChange(this.state.ventasBultoProducto);
    }
  }

  eliminarVentaBultoProducto = (idBultoProducto: number) => {
    const ventasBultoProducto = this.state.ventasBultoProducto.filter(
      (vbp: VentaProgramacionBultoProducto) => vbp.paquete.bulto_producto.id !== idBultoProducto
    );
    this.setState({
      ventasBultoProducto: [...ventasBultoProducto],
    });
  };

  agregarVentaBultoProducto = (nuevaVentaBultoProducto: VentaProgramacionBultoProducto): void => {
    this.setState((prevState) => ({
      ventasBultoProducto: [...prevState.ventasBultoProducto, nuevaVentaBultoProducto],
    }));
  };

  actualizarVentaBultoProducto = (infoVenta: VentaProgramacionBultoProducto) => {
    const ventas = this.state.ventasBultoProducto.map((vbp) =>
      vbp.paquete.id === infoVenta.paquete.id ? infoVenta : vbp
    );
    this.setState({
      ventasBultoProducto: ventas,
    });
  };

  estaDeshabilitado = (id: string): boolean => {
    if (!this.props.configCampos) {
      return false;
    }

    return this.props.configCampos.disabled.indexOf(id) !== -1;
  };

  render() {
    const { ventasBultoProducto } = this.state;
    const { crearNuevos } = this.props;

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Bulto de Producto</th>
              <th>Cantidad</th>
              <th>Bonificados</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {ventasBultoProducto.map((ventaBultoProducto, idx) => (
              <tr key={ventaBultoProducto.paquete.bulto_producto.id}>
                <td>{ventaBultoProducto.paquete.bulto_producto.nombre}</td>
                <td>{ventaBultoProducto.paquete.cantidad}</td>
                <td>{ventaBultoProducto.paquete.bonificados}</td>
                <td>
                  <Select
                    data={[
                      EstadoLogisticaPaquete.ENTREGADO,
                      EstadoLogisticaPaquete.NO_ENTREGADO,
                      EstadoLogisticaPaquete.RECHAZADO,
                    ]}
                    value={ventaBultoProducto.estado}
                    onChange={(seleccionado: EstadoLogisticaPaquete) => {
                      this.actualizarVentaBultoProducto({
                        ...ventaBultoProducto,
                        estado: seleccionado,
                      });
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {crearNuevos ? (
          <VentaProductoProgramacionEntregaLoad
            onSubmitCallback={this.agregarVentaBultoProducto}
            excluirProductos={ventasBultoProducto.map((vbp) => vbp.paquete.bulto_producto.id)}
          />
        ) : null}
      </>
    );
  }
}
