import React, { Component, FormEvent } from "react";
import InputCaracteres from "../../../../compartido/components/input/InputCaracteres";
import Alerta from "../../../../compartido/components/alerta/Alerta";

import Box from "../../../../compartido/components/box/Box";
import Flex from "../../../../compartido/components/flex/Flex";

import { ArticuloOficinaGet, ArticuloOficinaPost, ArticuloOficinaService, CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";



export interface Props {
  ocultar: Function;
}

interface State {
  articuloOficina: ArticuloOficinaPost;
  alerta: any;
}

export default class ArticuloOficinaNuevo extends Component<Props, State> {
  private articuloOficinaService:ArticuloOficinaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      articuloOficina: this.articuloOficinaCrear(),
      alerta: { tipo: "", mensaje: "", titulo: "", estado: "none" },
    };

    this.articuloOficinaService = new ArticuloOficinaService(Global.UsuarioService.getToken()!);

  }

  async componentDidMount() {
    const articuloOficinaCrear: ArticuloOficinaPost =
      this.state.articuloOficina;
    this.setState({
      articuloOficina: articuloOficinaCrear,
    });
  }

  ocultar = () => {
    this.props.ocultar();
  };

  articuloOficinaCrear(): ArticuloOficinaPost {
    return {
      nombre: "",
      marca: "",
      descripcion: "",
    };
  }

  setearNombre = (nombre: string) => {
    let articuloOficina = this.state.articuloOficina;
    articuloOficina.nombre = nombre;
    this.setState({ articuloOficina: articuloOficina });
  };
  setearMarca = (marca: string) => {
    let articuloOficina = this.state.articuloOficina;
    articuloOficina.marca = marca;
    this.setState({ articuloOficina: articuloOficina });
  };
  setearDescripcion = (descripcion: string) => {
    let articuloOficina = this.state.articuloOficina;
    articuloOficina.descripcion = descripcion;
    this.setState({ articuloOficina: articuloOficina });
  };

  handleCreate() {
    this.articuloOficinaService.create(this.state.articuloOficina)
      .then((result) => {
        WebSocketService.enviarMensaje(
          CANALES_WEB_SOCKET.ELEMENTOS_ARTICULOS_OFICINA
        );
        this.setState({
          alerta: {
            tipo: "alert-success",
            mensaje: "Articulo de Oficina Creado!",
            titulo: "Atención",
            estado: "block",
          },
        });
        const articuloOficinaCrear: ArticuloOficinaPost =
          this.articuloOficinaCrear();
        this.setState({
          articuloOficina: articuloOficinaCrear,
        });
      })
      .catch((error) => {
        this.articuloOficinaService.manejarErrorHTTP(error, "Artículo Oficina");
      });
  }

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    this.handleCreate();
    event.preventDefault();
  };

  //Devuelve si debe o no deshabilitar
  disableButton = (): boolean => {
    return this.state.articuloOficina.nombre &&
      this.state.articuloOficina.marca &&
      this.state.articuloOficina.descripcion
      ? false
      : true;
  };

  renderizarFormulario() {
    return (
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <div className={"row"}>
          <div className={"column"}>
            <form className={"form-horizontal"} onSubmit={this.handleSubmit}>
              <div className={"form-group"}>
                <label htmlFor="nombre">Nombre</label>
                <InputCaracteres
                  id="nombre"
                  name="nombre"
                  onChange={this.setearNombre}
                  value={this.state.articuloOficina.nombre}
                  placeholder="Ingrese Nombre de Articulo de Oficina"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="marca">Marca</label>
                <InputCaracteres
                  id="marca"
                  name="marca"
                  onChange={this.setearMarca}
                  value={this.state.articuloOficina.marca}
                  placeholder="Ingrese Marca"
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="descripcion">Descripcion</label>
                <InputCaracteres
                  id="descripcion"
                  name="descripcion"
                  onChange={this.setearDescripcion}
                  value={this.state.articuloOficina.descripcion}
                  placeholder="Ingrese Descripcion"
                />
              </div>
              <div
                className={"form-group"}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  ref="boton"
                  type="submit"
                  className={"btn btn-info"}
                  disabled={this.disableButton()}
                >
                  {"Crear"}
                </button>
                <button
                  className={"btn btn-outline-secondary"}
                  onClick={this.ocultar}
                >
                  {"Ocultar"}
                </button>
              </div>
              <div className={"form-group"}>
                <Alerta
                  tipo={this.state.alerta.tipo}
                  titulo={this.state.alerta.titulo}
                  mensaje={this.state.alerta.mensaje}
                  estado={this.state.alerta.estado}
                />
              </div>
            </form>
          </div>
        </div>
      </Flex>
    );
  }

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box paddingHorizontal="4rem" paddingVertical="2rem">
          {this.renderizarFormulario()}
        </Box>
      </Flex>
    );
  }
}
