import React, { Component } from "react";
import SelectGenerico from "./SelectGenerico";
import utilidades from "../../utils/utilidades";

interface Props {
  callbackParent: Function;
  criterios: Array<string>;
}
export default class SelectCriteriosBusqueda extends Component<Props> {
  private criterios: Array<string>;
  constructor(props: Props) {
    super(props);
    this.criterios = ["Ninguno", ...this.props.criterios];
  }

  getSeleccionado = (indice: number) =>
    this.props.callbackParent(this.criterios[indice]);

  armarNombresCriterios(nombresCriterios: Array<string>): Array<string> {
    return nombresCriterios.map((nombre) => {
      return utilidades.normalizarCadenas(nombre, "_");
    });
  }

  render() {
    if (this.criterios.length < 3) return null;
    return (
      <SelectGenerico
        titulo="Filtrar por:"
        seleccionado={0}
        elementos={this.armarNombresCriterios(this.criterios)}
        callback={this.getSeleccionado}
      />
    );
  }
}
