import React, { Component } from "react";

import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";

import { CANALES_WEB_SOCKET, ElementoComputacionGet, ElementoComputacionPost, ElementoComputacionService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";

export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  elementosComputacion: Array<ElementoComputacionGet>;
}

export default class ElementoComputacionLista extends Component<Props, State> {
  private elementoComputacionService:ElementoComputacionService;

  constructor(props: Props) {
    super(props);
    this.state = {
      elementosComputacion: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_ELEMENTOS_COMPUTACION,
      funcionCallback: this.getElementos,
    });
    this.elementoComputacionService =  new ElementoComputacionService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.elementoComputacionService.list()
      .then((result) => {
        this.setState({ elementosComputacion: result });
      })
      .catch((error) => {
        this.elementoComputacionService.manejarErrorHTTP(
          error,
          "Elemento Computación"
        );
      });
  };

  componentDidMount() {
    this.elementoComputacionService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }
  armarFilas(
    elementosComputacion: Array<ElementoComputacionGet>
  ): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (elementosComputacion.length > 0) {
      for (let i = 0; i < elementosComputacion.length; i++) {
        let fila: Array<string> = [];
        fila.push(elementosComputacion[i].nombre);
        fila.push(elementosComputacion[i].marca);
        fila.push(elementosComputacion[i].descripcion);
        let filaResult: FilaTablaGenerica = {
          id: elementosComputacion[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Elementos.ElementosComputacion.ElementoComputacion.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Elementos.ElementosComputacion.ElementoComputacion.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.elementoComputacionService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.elementosComputacion)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.elementoComputacionService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaElementosComputacion"
      />
    );
  }
}
