import React, { Component } from "react";
import { LIMITES } from "../../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {AccionTabla,FilaTablaGenerica,} from "../../../../../compartido/components/tabla/TablaGenerica";
import { Global } from "../../../../../Global";
import { CANALES_WEB_SOCKET, Cliente, ClienteService, WebSocketService } from "serviciossaintmichel";

export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  clientes: Array<Cliente>;
}

export default class ClienteLista extends Component<Props, State> {

  private clienteService:ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      clientes: [],
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES,
      funcionCallback: this.getClientes,
    });

    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);

  }

  getClientes = () => {
    this.clienteService.list()
      .then((result) => {
        this.setState({ clientes: result });
      })
      .catch((error) => {
        this.clienteService.manejarErrorHTTP(error, "Cliente");
      });
  };

  componentDidMount() {
    this.clienteService.paramsURL.setearLimite(LIMITES[0]);
    this.getClientes();
  }

  armarFilas(clientes: Array<Cliente>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (clientes.length > 0) {
      for (let i = 0; i < clientes.length; i++) {
        let fila: Array<string> = [];
        fila.push(clientes[i].razon_social);
        let filaResult: FilaTablaGenerica = {
          id: clientes[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Administracion.Clientes.Cliente.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Administracion.Clientes.Cliente.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.clienteService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.clientes)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.clienteService.paramsURL}
        listarElementos={this.getClientes}
        id="TablaClientes"
      />
    );
  }
}
