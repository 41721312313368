import React, { Component, createRef } from "react";
import { GoogleMap, LoadScript, DrawingManager } from "@react-google-maps/api";
import Flex from "../../../compartido/components/flex/Flex";
import CuadroCrearFigura from "./CuadroCrearFigura";

import {  CANALES_WEB_SOCKET,   WebSocketService,  ZonaService } from "serviciossaintmichel";
import { Global } from "../../../Global";



/* global google */
/*  importante para poder usar google*/

interface Props {}

interface State {
  poligono: any;
  drawing: boolean;
  cuadro: boolean;
  posicionCuadro: any;
  displayCuadro: string;
}

/* eslint-disable no-undef */ class ZonaCrear extends Component<Props, State> {
  mapa: any;
  cuadro: any;

  private zonaService:ZonaService;


  constructor(props: Props) {
    super(props);

    this.state = {
      poligono: null,
      drawing: true,
      cuadro: false,
      posicionCuadro: null,
      displayCuadro: "none",
    };
    this.zonaService = new ZonaService(Global.UsuarioService.getToken()!);


    this.cuadro = React.createRef();
  }

  onPolygonComplete = (polygon: any) => {
    this.setState({
      poligono: polygon,
      drawing: false,
      cuadro: true,
      displayCuadro: "block",
      posicionCuadro: polygon.getPath().getArray()[0],
    });

    this.cuadro.current.mostrar(polygon.getPath().getArray()[0]);
  };

  salir = () => {
    this.state.poligono.setVisible(false);
    this.setState({
      poligono: null,
      drawing: true,
      displayCuadro: "none",
      posicionCuadro: null,
    });
  };

  descartar = () => {
    this.state.poligono.setVisible(false);
    this.setState({
      poligono: null,
      drawing: true,
      displayCuadro: "none",
      posicionCuadro: null,
    });
  };

  guardar = (nombre: string) => {
    this.zonaService.create({
      poligono: this.state.poligono.getPath().getArray(),
      nombre: nombre,
    })
      .then((result) => {
        this.cuadro.current.alertar(true, "Zona Creada");

        WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.MAPAS_ZONAS, "new");
      })
      .catch((error) => {
        this.cuadro.current.alertar(false, "No fue posible crear la zona");
        this.zonaService.manejarErrorHTTP(error, "Zona");
      });
  };

  componentDidMount() {}

  load = (map: any) => {
    this.mapa = map;
  };

  render() {
    return (
      <Flex container flexDirection="column" width="100%">
        <Flex
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <h3>Crear Zona </h3>
          </div>
        </Flex>

        <br />
        <br />
        <Flex container flexDirection="column" height="800px" width="100%">
          <LoadScript
            id="script-loader"
            googleMapsApiKey="AIzaSyCDsnMxHafUo0xkHuJfTxoAHcinkNDSi9c"
            libraries={["drawing"]}
          >
            <GoogleMap
              id="example-map"
              mapContainerStyle={{
                height: "100%",
                width: "100%",
                margin: "auto",
              }}
              zoom={12}
              center={{ lat: -34.912185, lng: -57.957313 }}
              onLoad={this.load}
            >
              {this.state.drawing ? (
                <DrawingManager onPolygonComplete={this.onPolygonComplete} />
              ) : null}

              <CuadroCrearFigura
                titulo={"Nueva Zona"}
                callbackSalir={this.salir}
                callbackGuardar={this.guardar}
                callbackRetornar={this.descartar}
                ref={this.cuadro}
              />
            </GoogleMap>
          </LoadScript>
        </Flex>
      </Flex>
    );
  }
}
export default ZonaCrear;
