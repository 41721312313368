import React, { Component } from "react";
import Flex from "../../../../../compartido/components/flex/Flex";
import { Tabs, Tab } from "react-bootstrap";
import ClienteDatosGenerales from "./datosGenerales/ClienteDatosGenerales";
import Margin from "../../../../../compartido/components/margin/Margin";
import H from "../../../../../compartido/components/titulos/H";
import ClienteActualizarDireccion from "./direcciones/ClienteActualizarDireccion";
import ServicioClienteActualizar from "./servicios/ServicioClienteActualizar";
import MaquinaClienteActualizar from "./maquinas/MaquinaClienteActualizar";

import { Global } from "../../../../../Global";
import { CANALES_WEB_SOCKET, Cliente, ClienteService, WebSocketService } from "serviciossaintmichel";
import CuentaBancariaClienteActualizar from "./cuentas-bancarias/CuentaBancariaClienteActualizar";

interface Props {
  id: number;
  recienCreado: boolean;
}

interface State {
  responsive: boolean;
  cliente: Cliente | null;
  cargando: boolean;
}

export default class ClienteActualizar extends Component<Props, State> {
  private clienteService: ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      responsive: window.innerWidth < 700 ? true : false,
      cliente: null,
      cargando: true,
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES,
      funcionCallback: this.refrescarCliente,
    });
    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);

    window.addEventListener("resize", this.actualizarDimensiones);
  }

  componentDidMount = async () => {
    const cliente = await this.clienteService.get(this.props.id);
    this.setState({ cliente: cliente, cargando: false });
  };

  refrescarCliente = async () => {
    let cliente: Cliente = await this.clienteService.get(this.props.id);
    this.setState({ cliente: cliente, cargando: false });
  };

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  renderizar = (responsive: boolean, cliente: Cliente) => (
    <Margin top="20px">
      <Tabs
        defaultActiveKey={"datosGenerales"}
        transition={false}
        variant={responsive ? "pills" : "tabs"}
        style={{
          marginBottom: "1rem",
          flexDirection: responsive ? "column" : "row",
          alignItems: responsive ? "center" : "",
        }}
        unmountOnExit={true}
      >
        <Tab eventKey="datosGenerales" title="Datos Generales">
          <ClienteDatosGenerales cliente={cliente} />
        </Tab>
        <Tab eventKey="direcciones" title="Direcciones">
          <ClienteActualizarDireccion clienteId={cliente.id} />
        </Tab>
        <Tab eventKey="servicios" title="Servicios">
          <ServicioClienteActualizar clienteId={cliente.id} />
        </Tab>
        <Tab eventKey="maquinas" title="Máquinas">
          <MaquinaClienteActualizar clienteId={cliente.id} />
        </Tab>
        <Tab eventKey="cuenta-bancarias" title="Cuentas bancarias">
          <CuentaBancariaClienteActualizar clienteId={cliente.id} />
        </Tab>
      </Tabs>
    </Margin>
  );

  render() {
    const { cargando, responsive, cliente } = this.state;

    if (cargando || cliente === null) {
      return null;
    }

    return (
      <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
          <Margin top="40px" bottom="30px">
            <H size={3} texto={cliente.razon_social} />
          </Margin>
        </Flex>
        <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
          {this.renderizar(responsive, cliente)}
        </Flex>
      </Flex>
    );
  }
}
