import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import { Box } from "../../../../../libreria/containers/box/Box";
import Swal from "sweetalert2";
import PedidoLoad from "../load/PedidoLoad";
import { Global } from "../../../../../Global";
import {
  CANALES_WEB_SOCKET,
  Cliente,
  CrearClienteVacio,
  CrearPedidoEmpty,
  Pedido,
  PedidoPost,
  PedidoService,
  WebSocketService,
} from "serviciossaintmichel";

export interface Props {
  onSaveSuccess: Function;
  onExit?: Function;
  cliente?: Cliente;
}

interface State {
  pedido: Pedido;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class PedidoNew extends Component<Props, State> {
  private pedidoService: PedidoService;
  constructor(props: Props) {
    super(props);
    this.state = {
      pedido: {
        ...CrearPedidoEmpty(),
        cliente: props.cliente ? props.cliente : CrearClienteVacio(),
      },
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    this.pedidoService = new PedidoService(Global.UsuarioService.getToken()!);
    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidUpdate = async (prevProps: Props, prevState: State) => {
    if (prevState.pedido !== this.state.pedido) {
      console.log("cambio Pedido del NEW");
    }
  };

  onSave = async () => {
    const { pedido } = this.state;

    //TODO revisar funcionamiento
    const pedidoPost: PedidoPost = {
      monto: pedido.monto,
      cliente: pedido.cliente ? pedido.cliente.id : -1,
      fuente: {
        fecha: pedido.fuente.fecha,
        hora: pedido.fuente.hora,
        medio_comunicacion: pedido.fuente.medio_comunicacion.id,
        medio_ingreso: pedido.fuente.medio_ingreso.id,
        cliente: pedido.fuente.cliente ? pedido.fuente.cliente.id : undefined,
        empleado: pedido.fuente.empleado
          ? pedido.fuente.empleado.id
          : undefined,
        propietario: pedido.fuente.propietario
          ? pedido.fuente.propietario.id
          : undefined,
      },
      factura: pedido.factura ? true : false,
      entregas_producto: pedido.entregas_producto.map((ep) => ({
        ...ep,
        producto: ep.producto.id,
      })),
      entregas_bulto_producto: pedido.entregas_bulto_producto.map((ebp) => ({
        ...ebp,
        bulto_producto: ebp.bulto_producto.id,
      })),
      entregas_maquina: pedido.entregas_maquina.map((em) => ({
        ...em,
        maquina: em.maquina.id,
      })),
      ventas_producto: pedido.ventas_producto.map((vp) => ({
        ...vp,
        producto: vp.producto.id,
      })),
      ventas_bulto_producto: pedido.ventas_bulto_producto.map((vbp) => ({
        ...vbp,
        bulto_producto: vbp.bulto_producto.id,
      })),
      ventas_maquina: pedido.ventas_maquina.map((vm) => ({
        ...vm,
        maquina: vm.maquina.id,
      })),
      ventas_promocion: pedido.ventas_promocion.map((vp) => ({
        ...vp,
        promocion: vp.promocion.id,
      })),
      programaciones: [],
      lista_precio: 1, // TODO implementar
    };
    try {
      const pedido = await this.pedidoService.creacionPedido(pedidoPost);
      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.VENTAS_PEDIDOS, {
        type: "NEW",
        idPedido: pedido.id,
      });
      Swal.fire({
        icon: "success",
        text: "Pedido creado",
      });
      this.props.onSaveSuccess(pedido.id);
    } catch (error) {
      this.pedidoService.manejarErrorHTTP(error, "Pedido");
    }
  };

  onExit = () => {
    if (this.props.onExit !== undefined) this.props.onExit();
  };

  onChange = (pedido: Pedido) => {
    this.setState({ pedido: pedido });
  };

  onValidationChange = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  renderizarPhone = () => (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarTablet = () => (
    <Flex
      flexDirection="column"
      width="60%"
      alignItems="center"
      justifyContent="center"
      marginTop="20px"
    >
      {this.renderizar()}
    </Flex>
  );

  renderizarPC = () => this.renderizar();

  renderizar = () => (
    <Box padding="20px">
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <PedidoLoad
            pedido={this.state.pedido}
            onChange={this.onChange}
            onValidationChange={this.onValidationChange}
            onSave={this.onSave}
            tituloOnSave={"Crear"}
          />
        </Flex>
      </Flex>
    </Box>
  );

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this.state.screen === ScreenType.PC ? this.renderizarPC() : null}
        {this.state.screen === ScreenType.PHONE ? this.renderizarPhone() : null}
        {this.state.screen === ScreenType.TABLET
          ? this.renderizarTablet()
          : null}
      </Flex>
    );
  }
}
