import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../../compartido/models/Screen";
import Flex from "../../../../../../../libreria/appearance/flex/Flex";
import H from "../../../../../../../libreria/message/title/H";
import { Fuentes } from "../../../../../../../compartido/estilos/estilos";



import { CANALES_WEB_SOCKET, ClienteService, Email, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../../../Global";



export interface Props {
  clienteId: number;
}

interface State {
  screen: ScreenType;
  emails: Array<Email>;
  emailId: number | null;
}

export default class EmailsClienteShow extends Component<Props, State> {
  private webSocket: WebSocket | null;
  private clienteService:ClienteService;

  constructor(props: Props) {
    super(props);
    this.state = {
      emailId: null,
      emails: [],
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    this.webSocket = null;
    this.clienteService = new ClienteService(Global.UsuarioService.getToken()!);

  }

  componentDidMount = () => {
    window.addEventListener("resize", this.actualizarDimensiones);

    this.getEmails();

    this.webSocket = WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_CLIENTES_EMAILS,
      funcionCallback: this.onChannelMessage,
    });
  };

  componentWillUnmount = () => {
    this.webSocket?.close();
  };

  onChannelMessage = async (e: MessageEvent) => {
    let message = JSON.parse(e.data).message;
    const { clienteId } = this.props;

    if (message.clienteId === clienteId) {
      this.getEmails();
    }
  };

  getEmails = async () => {
    const emails = await this.clienteService.getEmails(this.props.clienteId);
    this.setState({ emails: emails });
  };

  actualizarDimensiones = () => {
    this.setState({ screen: Screen.getType(window.innerWidth, window.innerHeight) });
  };

  renderizarFilas = () => {
    return this.state.emails.map((email, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{email.email}</td>
          <td className="text-center">{email.descripcion}</td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Email</th>
            <th className="text-center">Descripción</th>
          </tr>
        </thead>

        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  render() {
    const { emails } = this.state;

    if (emails.length === 0) {
      return <div>El cliente no posee emails asociados</div>;
    }

    return (
      <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
          <H size={3} fuente={Fuentes.Titulos} texto={"Emails"} />
        </Flex>
        {this.renderizarTabla()}
      </Flex>
    );
  }
}
