import { Badge } from "@mantine/core";
import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../libreria/appearance/margin/Margin";
import { Box } from "../../../../../libreria/containers/box/Box";
import Text from "../../../../../libreria/message/text/Text";
import H from "../../../../../libreria/message/title/H";
import LocalizacionShow from "../../../../../compartido/components/direccion/LocalizacionShow";
import DireccionShow from "../../../../../compartido/components/direccion/DireccionShow";
import EntregasProgramacionEntregaShow from "./entregas/EntregasProgramacionEntregaShow";
import PagoProgramacionEntregaShow from "./pago/PagoProgramacionEntregaShow";
import VentasProgramacionEntregaShow from "./ventas/VentaProgramacionEntregaShow";
import RemitoProgramacionEntregaShow from "./remito/RemitoProgramacionEntregaShow";
import VisitaProgramacionEntregaShow from "./visita/VisitaProgramacionEntregaShow";

import {
  CANALES_WEB_SOCKET,
  Pago,
  ProgramacionEntregaService,
  ProgramacionEntrega,
  WebSocketService,
  getColorEstadoProgramacion,
  getColorPrioridad,
} from "serviciossaintmichel";
import { Global } from "../../../../../Global";
import FechaUtils from "../../../../../compartido/utils/FechaUtils";

export interface Props {
  idProgramacionEntrega: number;
}

interface State {
  responsive: boolean;
  programacionEntrega: ProgramacionEntrega | null;
  cargando: boolean;
}

export default class ProgramacionEntregaShow extends Component<Props, State> {
  private programacionEntregaService: ProgramacionEntregaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      responsive: window.innerWidth < 700 ? true : false,
      programacionEntrega: null,
      cargando: true,
    };

    this.programacionEntregaService = new ProgramacionEntregaService(Global.UsuarioService.getToken()!);
  }

  componentDidMount = async () => {
    await this.cargarProgramacionEntrega();
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.VENTAS_PROGRAMACIONES_ENTREGA,
      funcionCallback: this.onChannelMesagge,
    });
    window.addEventListener("resize", this.actualizarDimensiones);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.actualizarDimensiones);
  };

  onChannelMesagge = async (message: MessageEvent) => {
    var dato = JSON.parse(message.data);
    if (dato.message.programacionEntregaId == this.props.idProgramacionEntrega) {
      await this.cargarProgramacionEntrega();
    }
  };

  cargarProgramacionEntrega = async () => {
    let programacionEntrega = await this.programacionEntregaService
      .get(this.props.idProgramacionEntrega)
      .catch((error) => {
        this.programacionEntregaService.manejarErrorHTTP(error, "Programacion Entrega");
        return null;
      });
    this.setState({
      programacionEntrega: programacionEntrega,
      cargando: false,
    });
  };

  componentDidUpdate = async (oldProps: Props) => {
    if (this.props != oldProps) {
      await this.setState({ cargando: true });
      await this.cargarProgramacionEntrega();
    }
  };

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  renderProgramacionEntrega = (responsive: boolean) => {
    const { programacionEntrega } = this.state;

    if (programacionEntrega === null) {
      return null;
    }

    let idRepartoDiario = null;

    if (programacionEntrega.visita && programacionEntrega.visita.reparto_diario.fecha_realizacion) {
      const fechaRealizacion = FechaUtils.normalizarFecha(
        programacionEntrega.visita.reparto_diario.fecha_realizacion
      ).toLocaleDateString("es-AR");

      idRepartoDiario = `${programacionEntrega.visita.reparto_diario.reparto.nombre} - ${fechaRealizacion} - Viaje ${programacionEntrega.visita.reparto_diario.viaje_numero}`;
    }

    return (
      <>
        <H
          alineacion={"center"}
          size={2}
          texto={
            "Programacion entrega de la fecha " +
            programacionEntrega.fecha +
            " " +
            (programacionEntrega.reparto ? "del reparto " + programacionEntrega.reparto.nombre : "sin reparto")
          }
        />
        <Tabs
          defaultActiveKey={"datosGenerales"}
          transition={false}
          variant={responsive ? "pills" : "tabs"}
          style={{
            marginBottom: "1rem",
            flexDirection: responsive ? "column" : "row",
            alignItems: responsive ? "center" : "",
          }}
          unmountOnExit={true}
        >
          <Tab eventKey="datosGenerales" title="Datos Generales">
            <Flex
              container
              flexDirection="column"
              width="100%"
              alignItems="flex-start"
              justifyContent="center"
              borderRadius="5px"
              padding="2px"
              marginLeft="2px"
            >
              <Text>
                <b>Reparto diario</b>: {programacionEntrega.visita && idRepartoDiario ? idRepartoDiario : "Sin asignar"}
              </Text>
              <Text>
                <b>Reparto</b>: {programacionEntrega.reparto ? programacionEntrega.reparto.nombre : "No tiene"}
              </Text>
              <Flex>
                <Text>
                  <b>Estado</b>:
                  <Badge
                    color={getColorEstadoProgramacion(programacionEntrega.estado_programacion.estado)}
                    variant={"filled"}
                  >
                    {programacionEntrega.estado_programacion.estado}
                  </Badge>
                </Text>
              </Flex>
              <Text>
                <b>Observacion</b>: {programacionEntrega.observacion ? programacionEntrega.observacion : "-"}
              </Text>
              <Flex>
                <Text>
                  <b>Prioridad</b>:
                  <Badge color={getColorPrioridad(programacionEntrega.prioridad)} variant={"filled"}>
                    {programacionEntrega.prioridad}
                  </Badge>
                </Text>
              </Flex>
              {programacionEntrega.direccion.localizacion ? (
                <LocalizacionShow localizacion={programacionEntrega.direccion.localizacion} />
              ) : null}
            </Flex>
          </Tab>
          <Tab eventKey="direccion" title="Direccion">
            <DireccionShow direccionId={programacionEntrega.direccion.id} />
          </Tab>
          <Tab eventKey="pago" title="Pago" disabled={programacionEntrega.pago ? false : true}>
            <PagoProgramacionEntregaShow idPago={programacionEntrega.pago} />
          </Tab>
          <Tab eventKey="remito" title="Remito" disabled={programacionEntrega.remito ? false : true}>
            <RemitoProgramacionEntregaShow idRemito={programacionEntrega.remito} />
          </Tab>
          <Tab
            eventKey="entregas"
            title="Entregas"
            disabled={
              programacionEntrega.entregas_producto.length == 0 &&
              programacionEntrega.entregas_bulto_producto.length == 0 &&
              programacionEntrega.entregas_maquina.length == 0
            }
          >
            <EntregasProgramacionEntregaShow
              entregasProducto={programacionEntrega.entregas_producto}
              entregasBultoProducto={programacionEntrega.entregas_bulto_producto}
              entregasMaquina={programacionEntrega.entregas_maquina}
            />
          </Tab>
          <Tab
            eventKey="ventas"
            title="Ventas"
            disabled={
              programacionEntrega.ventas_producto.length == 0 &&
              programacionEntrega.ventas_bulto_producto.length == 0 &&
              programacionEntrega.ventas_maquina.length == 0 &&
              programacionEntrega.ventas_promocion.length == 0
            }
          >
            <VentasProgramacionEntregaShow
              ventasProducto={programacionEntrega.ventas_producto}
              ventasBultoProducto={programacionEntrega.ventas_bulto_producto}
              ventasMaquina={programacionEntrega.ventas_maquina}
              ventasPromocion={programacionEntrega.ventas_promocion}
            />
          </Tab>
          <Tab eventKey="visita" title="Visita" disabled={programacionEntrega.visita ? false : true}>
            {programacionEntrega.visita !== null ? (
              <VisitaProgramacionEntregaShow idVisita={programacionEntrega.visita!.id} />
            ) : null}
          </Tab>
        </Tabs>
      </>
    );
  };

  renderLoading = () => "Cargando Datos";

  render() {
    return (
      <Flex container flexDirection="column" width="100%" alignItems="center" justifyContent="center">
        <Margin top="20px" />
        <Box padding="15px">
          {this.state.cargando ? this.renderLoading() : this.renderProgramacionEntrega(this.state.responsive)}
        </Box>
      </Flex>
    );
  }
}
