import React, { Component } from "react";
import { Item, TipoItem } from "./Item";
import Flex from "../../appearance/flex/Flex";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import Text from "../../message/text/Text";

interface Props {
  item: Item;
  onClick?(id: number): void;
  onView?(id: number): void;
  onDelete?(id: number): void;
  tipo: TipoItem;
  selected: boolean;
  waiting: boolean;
}

interface State {
  item: Item;
  waiting: boolean;
  selected: boolean;
}

class ListBoxItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      item: this.props.item,
      waiting: this.props.waiting,
      selected: this.props.selected,
    };

    if (this.props.tipo.delete && this.props.tipo.view) {
      this.widthText = "80%";
      this.widthDelete = "10%";
      this.widthView = "10%";
    } else if (this.props.tipo.delete) {
      this.widthText = "90%";
      this.widthDelete = "10%";
      this.widthView = "0%";
    } else if (this.props.tipo.view) {
      this.widthText = "90%";
      this.widthDelete = "0%";
      this.widthView = "10%";
    } else {
      this.widthText = "100%";
      this.widthDelete = "0%";
      this.widthView = "0%";
    }
  }

  widthText: string;
  widthDelete: string;
  widthView: string;

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.item.id !== this.props.item.id ||
      prevProps.selected !== this.props.selected ||
      prevProps.waiting !== this.props.waiting
    ) {
      this.setState({
        item: this.props.item,
        selected: this.props.selected,
        waiting: this.props.waiting,
      });
    }

    if (prevProps.item !== this.props.item && this.props.item !== this.state.item) {
      this.setState({ item: this.props.item });
    }
  }

  onClick = () => {
    if (this.props.onClick) this.props.onClick(this.state.item.id);
  };

  onView = () => {
    if (this.props.onView) this.props.onView(this.state.item.id);
  };

  onDelete = () => {
    if (this.props.onDelete && this.props.tipo.delete) this.props.onDelete(this.state.item.id);
  };

  render() {
    return (
      <Flex flexDirection="row" alignItems="center" justifyContent="center" width="100%" height="50px">
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={this.widthText}
          height="100%"
          borderTop="1px solid grey"
          borderLeft="1px solid grey"
          borderRight="1px solid grey"
          backgroundColor={this.state.selected ? "#68B3EE" : ""}
          onClick={this.onClick}
        >
          <Text color={this.state.selected ? "white" : "black"}>{this.state.item.text}</Text>
        </Flex>

        {this.props.tipo.view ? (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width={this.widthView}
            height="100%"
            borderTop="1px solid grey"
            borderRight="1px solid grey"
          >
            <Button onClick={this.onView} variant="light">
              <Icon.PlusCircle size={18} />
            </Button>
          </Flex>
        ) : null}

        {this.props.tipo.delete ? (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width={this.widthDelete}
            height="100%"
            borderTop="1px solid grey"
            borderRight="1px solid grey"
          >
            <Button onClick={this.onDelete} variant="light" disabled={this.state.waiting}>
              <Icon.XCircle size={"18"} />
            </Button>
          </Flex>
        ) : null}
      </Flex>
    );
  }
}

export default ListBoxItem;
