import React from "react";
import Flex from "../../../libreria/appearance/flex/Flex";

import "./options.css";

interface Props {}

interface State {
  option: string;
}

export default class Background extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    var ancho = window.innerWidth;
    var alto = window.innerHeight;

    console.log(ancho);
    console.log(alto);

    this.state = {
      option: "option1_1",
    };

    var dimensiones = [
      [1920, 1080],
      [1536, 864],
      [1440, 1990],
      [1366, 768],
      [1024, 768],
    ];

    var d = Math.abs(ancho - dimensiones[0][0]) + Math.abs(alto - dimensiones[0][1]);
    var k = 0;
    for (var i = 1; i < 5; i++) {
      let aux = Math.pow(
        Math.pow(Math.abs(ancho - dimensiones[i][0]), 2) + Math.pow(Math.abs(alto - dimensiones[i][1]), 2),
        0.5
      );
      if (aux < d) {
        k = i;
        d = aux;
      }
    }

    /*
    switch (k) {
      case 0:
        this.state = {
          option:"option1_1"
        };
        break;
      case 1:
        this.state = {
          option:"option1_2"
        };
        break;
      case 2:
        this.state = {
          option:"option1_3"
        };
        break;
      case 3:
        this.state = {
          option:"option1_4"
        };
        break;
      case 4:
        this.state = {
          option:"option1_5"
        };
        break;
      default:
        this.state = {
          option:"option1_1"
        };
        break;
    }
*/

    switch (k) {
      case 0:
        this.state = {
          option: "option2_1",
        };
        break;
      case 1:
        this.state = {
          option: "option2_2",
        };
        break;
      case 2:
        this.state = {
          option: "option2_3",
        };
        break;
      case 3:
        this.state = {
          option: "option2_4",
        };
        break;
      case 4:
        this.state = {
          option: "option2_5",
        };
        break;
      default:
        this.state = {
          option: "option2_1",
        };
        break;
    }
  }

  /* className={this.state.option} */

  render() {
    return (
      <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
        <div style={{ width: "100%", height: "100%" }} className={this.state.option}>
          <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%" height="100%">
            {" "}
            {this.props.children}
          </Flex>
        </div>
      </Flex>
    );
  }
}
