import React, { Component } from "react";
import { LIMITES } from "../../../../compartido/components/select/SelectLimiteTabla";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../compartido/components/tabla/TablaGenerica";


import { BultoProductoGet,  BultoProductoService, CANALES_WEB_SOCKET, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../Global";




export interface Props {
  actualizar: Function;
  ver: Function;
}

interface State {
  bultoProductos: Array<BultoProductoGet>;
}
export default class BultoProductoLista extends Component<Props, State> {
  private bultoProductoService:BultoProductoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      bultoProductos: [],
    };
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ELEMENTOS_BULTO_PRODUCTOS,
      funcionCallback: this.getElementos,
    });

    this.bultoProductoService =  new BultoProductoService(Global.UsuarioService.getToken()!);

  }

  getElementos = () => {
    this.bultoProductoService.list()
      .then((result) => {
        this.setState({ bultoProductos: result });
      })
      .catch((error) => {
        this.bultoProductoService.manejarErrorHTTP(error, "Bulto Producto");
      });
  };

  componentDidMount() {
    this.bultoProductoService.paramsURL.setearLimite(LIMITES[0]);
    this.getElementos();
  }

  armarFilas(productos: Array<BultoProductoGet>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (productos.length > 0) {
      for (let i = 0; i < productos.length; i++) {
        let fila: Array<string> = [];
        fila.push(productos[i].nombre);
        let filaResult: FilaTablaGenerica = {
          id: productos[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.actualizar(id);
  };

  handleVer = (id: number) => {
    this.props.ver(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
    if (
      Global.UsuarioService.getPermisos().Elementos.Productos.BultoProducto.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      Global.UsuarioService.getPermisos().Elementos.Productos.BultoProducto.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.bultoProductoService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.bultoProductos)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={this.bultoProductoService.paramsURL}
        listarElementos={this.getElementos}
        id="TablaProductos"
      />
    );
  }
}
