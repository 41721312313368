import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Box from "../../../../../libreria/containers/box/Box";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import Margin from "../../../../../libreria/appearance/margin/Margin";
import H from "../../../../../libreria/message/title/H";
import DatosAlquilerUpdate from "./datos/DatosAlquilerUpdate";
import PrecioAlquilerUpdate from "./precios/PrecioAlquilerUpdate";
import ProductosAlquiler from "./productos/ProductosAlquiler";
import MaquinasAlquiler from "./maquinas/MaquinasAlquiler";
import { AlquilerGet, CANALES_WEB_SOCKET, WebSocketService,AlquilerService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";

export interface Props {
  onUpdateSuccess?: Function;
  onDeleteSuccess?: Function;
  id: number;
}
interface State {
  alquiler: AlquilerGet | null;
  cargando: boolean;
  screen: ScreenType;
}

export default class AlquilerUpdate extends Component<Props, State> {

  private alquilerService:AlquilerService;

  constructor(props: Props) {
    super(props);
    this.state = {
      alquiler: null,
      cargando: true,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };

    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.ADMINISTRACION_ALQUILERES,
      funcionCallback: this.onChannelMesagge,
    });

    this.alquilerService =  new AlquilerService(Global.UsuarioService.getToken()!);


    window.addEventListener("resize", this.actualizarDimensiones);
  }

  componentDidMount = async () => {
    const alquiler = await this.alquilerService.get(this.props.id);
    this.setState({ alquiler: alquiler, cargando: false });
  };

  onChannelMesagge = async (message: MessageEvent) => {
    debugger;
    const alquiler = await this.alquilerService.get(this.props.id);
    this.setState({ alquiler: alquiler, cargando: false });
  };

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.props != oldProps) {
      //this.setState({x:X})
    }
  };

  onUpdate = () => {
    /*
    AlquilerService
    .update(this.state.x)
    .then((x) => {

        WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET...
        );

        this.props.onUpdateSuccess(x);
    })
    */
  };

  onDelete = () => {
    /*
    AlquilerService
    .delete(this.state.x.id)
    .then((x) => {

        WebSocketService.enviarMensaje(
        CANALES_WEB_SOCKET...
        );

        this.props.onUpdateSuccess(x);
    })
    */
  };

  renderizar = () =>
    this.state.alquiler != null ? (
      <Margin top="20px">
        <Box padding="15px">
          <H
            alineacion={"center"}
            size={3}
            texto={this.state.alquiler.nombre}
          />
          <Margin top="20px" />
          <Tabs
            defaultActiveKey={"datos"}
            transition={false}
            variant={this.state.screen == ScreenType.PHONE ? "pills" : "tabs"}
            style={{
              marginBottom: "1rem",
              flexDirection:
                this.state.screen == ScreenType.PHONE ? "column" : "row",
              alignItems: this.state.screen == ScreenType.PHONE ? "center" : "",
            }}
            unmountOnExit={true}
          >
            <Tab eventKey="datos" title="Datos">
              {<DatosAlquilerUpdate datosAlquiler={this.state.alquiler} />}
            </Tab>
            <Tab eventKey="precio" title="Precio">
              {
                <PrecioAlquilerUpdate
                  precioAlquiler={{
                    alquiler: this.state.alquiler.id,
                    precio: this.state.alquiler.precio,
                  }}
                />
              }
            </Tab>
            <Tab eventKey="productos" title="Productos">
              {<ProductosAlquiler alquiler={this.state.alquiler} />}
            </Tab>
            <Tab eventKey="maquinas" title="Maquinas">
              {<MaquinasAlquiler alquiler={this.state.alquiler} />}
            </Tab>
          </Tabs>
          <Margin top="20px" />
        </Box>
      </Margin>
    ) : null;

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <div style={{ display: "block", width: "100%" }}>
          {this.renderizar()}
        </div>
      </Flex>
    );
  }
}
