import React, { Component } from "react";
import Swal from "sweetalert2";
import Screen, { ScreenType } from "../../../../../compartido/models/Screen";
import RequestButton from "../../../../../libreria/action/request-button/RequestButton";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import H from "../../../../../libreria/message/title/H";
import RecorridoRepartoLoad from "./RecorridoRepartoLoad";

import {
  CANALES_WEB_SOCKET,
  TIPOS_REPARTO,
  RecorridoReparto,
  RepartoService,
  WebSocketService,
} from "serviciossaintmichel";
import { Global } from "../../../../../Global";

export interface Props {
  onSaveSuccess?: Function;
  onExit?: Function;
  idReparto: number;
}

interface State {
  recorrido: RecorridoReparto;
  error: string;
  valid: boolean;
  screen: ScreenType;
}

export default class RecorridoRepartoNew extends Component<Props, State> {
  private repartoService: RepartoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      recorrido: this.createEmptyRecorrido(),
      error: "",
      valid: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);

    this.repartoService = new RepartoService(Global.UsuarioService.getToken()!);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  createEmptyRecorrido = (): RecorridoReparto => {
    return {
      recorrido: { id: -1, nombre: "", reparto: -1 },
      dia_reparto: null,
      reparto: { id: this.props.idReparto, nombre: "", tipo: TIPOS_REPARTO.DOMICILIO },
    };
  };

  componentDidUpdate(props: Props, state: State) {
    if (
      this.state.recorrido.recorrido.id !== state.recorrido.recorrido.id ||
      this.state.recorrido.dia_reparto !== state.recorrido.dia_reparto
    ) {
      this.setState({ recorrido: this.state.recorrido });
    }
  }
  onSave = async () => {
    try {
      await this.repartoService.putRecorridoReparto(
        this.props.idReparto,
        this.state.recorrido.recorrido.id,
        this.state.recorrido.dia_reparto
      );

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_RECORRIDOS, {
        type: "update",
        idRecorrido: this.state.recorrido.recorrido.id,
      });

      WebSocketService.enviarMensaje(CANALES_WEB_SOCKET.DISTRIBUCION_REPARTOS, {
        type: "update",
        idReparto: this.props.idReparto,
      });
      this.setState({ recorrido: this.createEmptyRecorrido() });
      await Swal.fire({
        icon: "success",
        text: "Recorrido Actualizado",
      });
    } catch (error) {
      this.repartoService.manejarErrorHTTP(error, "Nuevo Recorrido Reparto");
    }
  };

  onExit = () => {
    if (this.props.onExit !== undefined) this.props.onExit();
  };

  onChangeRecorrido = (recorrido: RecorridoReparto) => {
    this.setState({ recorrido: recorrido });
  };

  onChangeValidation = (valid: boolean) => {
    this.setState({ valid: valid });
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        border="1px solid grey"
        borderRadius="5px"
        padding="10px"
      >
        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="10px">
          <H size={4} texto={"Agregar Recorrido"} />
        </Flex>

        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
          <RecorridoRepartoLoad
            recorrido={this.state.recorrido}
            onChange={this.onChangeRecorrido}
            onValidationChange={this.onChangeValidation}
          />
        </Flex>

        <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" marginTop="20px">
          <RequestButton propsBoton={{ disabled: !this.state.valid }} texto={"Agregar"} onClick={this.onSave} />
        </Flex>
      </Flex>
    );
  }
}
