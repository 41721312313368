import React, { Component } from "react";
import { CANALES_WEB_SOCKET, DireccionDTO, DireccionService, WebSocketService } from "serviciossaintmichel";
import { Global } from "../../../../../Global";
import Flex from "../../../../../libreria/appearance/flex/Flex";
import { Item } from "../../../../../libreria/data/listbox/Item";
import ListBox from "../../../../../libreria/data/listbox/ListBox";
import LocationsMap from "../../../../../mapas/libreria/locationsmap/LocationsMap";
import { ItemPoint } from "../../../../../mapas/libreria/models";

export interface Props {
  onUpdate?: Function;
  onView?: Function;
  onSelect?: Function;
  version: Version;
  direccionesMostrar?: Array<number>;
  seleccionado?: number;
}
export enum Version {
  SELECT,
  TABLE,
  LISTBOX,
  LOCATIONMAP,
}

interface State {}

export default class DireccionesShow extends Component<Props, State> {


  constructor(props: Props) {
    super(props);
    this.state = {};
  
  }

  onUpdate = (id: number) => {
    if (this.props.onUpdate) this.props.onUpdate(id);
  };
  onView = (id: number) => {
    if (this.props.onView) this.props.onView(id);
  };
  onSelect = (id: number) => {
    if (this.props.onSelect) this.props.onSelect(id);
  };

  render() {
    return (
      <Flex
        container
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        {/* {this.props.version == Version.SELECT ? (
          <DireccionesSelect
            onChange={this.onSelect}
            seleccionado={
              this.props.seleccionado ? this.props.seleccionado : null
            }
          />
        ) : null} */}
        {/* {this.props.version == Version.TABLE ? (
          <DireccionesTable onUpdate={this.onUpdate} onView={this.onView} />
        ) : null} */}
        {this.props.version == Version.LISTBOX ? (
          <DireccionesListBox
            onSelect={this.onSelect}
            direccionesMostrar={this.props.direccionesMostrar}
            seleccionado={this.props.seleccionado}
          />
        ) : null}
        {this.props.version == Version.LOCATIONMAP ? (
          <DireccionesLocationMap
            onSelect={this.onSelect}
            direccionesMostrar={this.props.direccionesMostrar}
            seleccionado={this.props.seleccionado}
          />
        ) : null}
      </Flex>
    );
  }
}
// ------------------------------------------

// Version Table
/*
interface PropsTable {
  onUpdate: Function;
  onView: Function;
}

interface StateTable {
  direcciones: Array<Direccion>;
}

class DireccionesTable extends Component<PropsTable, StateTable> {
  constructor(props: PropsTable) {
    super(props);
    this.state = {
      direcciones: [],
    };
  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.COMPARTIDO_DIRECCION,
      funcionCallback: this.getDirecciones,
    });
    DireccionService.paramsURL.setearLimite(LIMITES[0]);
    this.getDirecciones();
  }

  getDirecciones = () => {
    DireccionService.list()
      .then((result) => {
        this.setState({ direcciones: result });
      })
      .catch((error:any) => {
        DireccionService.manejarErrorHTTP(error, "Direccion");
      });
  };

  armarFilas(direcciones: Array<Direccion>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (direcciones.length > 0) {
      for (let i = 0; i < direcciones.length; i++) {
        let fila: Array<string> = [];
        fila.push(direcciones[i].nombre);
        let filaResult: FilaTablaGenerica = {
          id: direcciones[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  handleActualizar = (id: number) => {
    this.props.onUpdate(id);
  };

  handleVer = (id: number) => {
    this.props.onView(id);
  };

  armarAccionesBoton(): Array<AccionTabla> {
    let acciones: Array<AccionTabla> = [];
     if (
      UsuarioService.getPermisos().Compartido.Direcciones.Direccion.canChange()
    ) {
      let accion: AccionTabla = {
        nombre: "Actualizar",
        evento: this.handleActualizar,
      };
      acciones.push(accion);
    }
    if (
      UsuarioService.getPermisos().Compartido.Direcciones.Direccion.canView()
    ) {
      let accionVer: AccionTabla = {
        nombre: "Ver Detalle",
        evento: this.handleVer,
      };
      acciones.push(accionVer);
    }
    return acciones;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={DireccionService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.state.direcciones)}
        accionesBoton={this.armarAccionesBoton()}
        parametrosURL={DireccionService.paramsURL}
        listarElementos={this.getDirecciones}
        id="TablaDirecciones"
      />
    );
  }
}
 */
// ------------------------------------------

// Version Select
/*
interface PropsSelect {
  onChange: Function;
  seleccionado: Direccion | null;
  direccionesLibres?: boolean;
}

interface StateSelect {
  direcciones: Array<Direccion>;
}

class DireccionesSelect extends Component<PropsSelect, StateSelect> {
  constructor(props: PropsSelect) {
    super(props);
    this.state = {
      direcciones: [],
    };
  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.COMPARTIDO_DIRECCION,
      funcionCallback: this.getDirecciones,
    });
    this.getDirecciones();
  }

  getDirecciones = () => {
    DireccionService.listAll()
      .then((result:Array<Direccion>) => {
        if (this.props.direccionesLibres)
          result = result.filter((r) => r.id == null);
        this.setState({ direcciones: result });
      })
      .catch((error:any) => {
        DireccionService.manejarErrorHTTP(error, "Direccion");
      });
  };

  getPosicion = (valor: Direccion): number => {
    return this.state.direcciones.findIndex((i) => i.id === valor.id);
  };

  getSeleccionado = (indice: number) => {
    if (indice >= 0) this.props.onChange(this.state.direcciones[indice]);
    else this.props.onChange(null);
  };

  render() {
    return (
      <SelectGenericoNull
        seleccionado={
          this.props.seleccionado
            ? this.getPosicion(this.props.seleccionado)
            : null
        }
        elementos={this.state.direcciones.map((i) => i.calle+" N° "+i.numero)}
        callback={this.getSeleccionado}
      />
    );
  }
}
 */
// ------------------------------------------

// Version ListBox

interface PropsListBox {
  onSelect: Function;
  seleccionado?: number;
  direccionesMostrar?: Array<number>;
}

interface StateListBox {
  direcciones: Array<DireccionDTO>;
}

class DireccionesListBox extends Component<PropsListBox, StateListBox> {



  private direccionService:DireccionService;



  constructor(props: PropsListBox) {
    super(props);
    this.state = {
      direcciones: [],
    };
    this.direccionService =  new DireccionService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.COMPARTIDO_DIRECCION,
      funcionCallback: this.getDirecciones,
    });
    this.getDirecciones();
  }
  componentDidUpdate(oldProps: PropsListBox) {
    if (
      JSON.stringify(this.props.direccionesMostrar) !=
      JSON.stringify(oldProps.direccionesMostrar)
    ) {
      this.getDirecciones();
    }
  }

  getDirecciones = async () => {
    await this.direccionService.listAll()
      .then((result) => {
        if (this.props.direccionesMostrar) {
          result = result.filter((r) =>
            this.props.direccionesMostrar?.includes(r.id)
          );
          const resultado: DireccionDTO[] = [];
          this.props.direccionesMostrar?.forEach((idDir) => {
            const busqueda = result.find((d) => d.id == idDir);
            if (busqueda) resultado.push(busqueda);
          });
          result = resultado;
        }
        this.setState({ direcciones: result });
      })
      .catch((error: any) => {
        this.direccionService.manejarErrorHTTP(error, "Direccion");
      });
  };

  armarListBox = (direcciones: Array<DireccionDTO>) => {
    let list: Array<Item> = direcciones.map((i) => {
      return { id: i.id, text: i.calle + " N° " + i.numero };
    });
    return list;
  };

  onSelect = (id: number | null) => {
    if (this.props.onSelect) this.props.onSelect(id);
  };

  render() {
    return (
      <ListBox
        height="auto"
        width="100%"
        items={this.armarListBox(this.state.direcciones)}
        tipo={{ select: true, view: false, delete: false }}
        onSelect={this.onSelect}
        selected={this.props.seleccionado}
      />
    );
  }
}

// ------------------------------------------

// Version LocationMap

interface PropsLocationMap {
  onSelect: Function;
  seleccionado?: number;
  direccionesMostrar?: Array<number>;
}

interface StateLocationMap {
  direcciones: Array<DireccionDTO>;
}

class DireccionesLocationMap extends Component<
  PropsLocationMap,
  StateLocationMap
> {

  private direccionService:DireccionService;

  constructor(props: PropsLocationMap) {


    super(props);
    this.state = {
      direcciones: [],
    };
    this.direccionService =  new DireccionService(Global.UsuarioService.getToken()!);

  }

  componentDidMount() {
    WebSocketService.subscribirseACanal({
      nombreCanal: CANALES_WEB_SOCKET.COMPARTIDO_DIRECCION,
      funcionCallback: this.getDirecciones,
    });
    this.getDirecciones();
  }

  componentDidUpdate(oldProps: PropsLocationMap) {
    if (
      JSON.stringify(this.props.direccionesMostrar) !=
      JSON.stringify(oldProps.direccionesMostrar)
    ) {
      this.getDirecciones();
    }
  }

  getDirecciones = async () => {
    await this.direccionService.listAll()
      .then((result) => {
        if (this.props.direccionesMostrar) {
          result = result.filter((r) =>
            this.props.direccionesMostrar?.includes(r.id)
          );
          const resultado: DireccionDTO[] = [];
          this.props.direccionesMostrar?.forEach((idDir) => {
            const busqueda = result.find((d) => d.id == idDir);
            if (busqueda) resultado.push(busqueda);
          });
          result = resultado;
        }
        this.setState({ direcciones: result });
      })
      .catch((error: any) => {
        this.direccionService.manejarErrorHTTP(error, "Direccion");
      });
  };

  armarItemsMap(direcciones: Array<DireccionDTO>) {
    let items: Array<ItemPoint> = [];
    direcciones.forEach((direccion) => {
      let item = { id: direccion.id, point: { lat: 0, lng: 0 } };
      if (direccion.localizacion)
        item = {
          id: direccion.id,
          point: {
            lat: direccion.localizacion.coordinates[0],
            lng: direccion.localizacion.coordinates[1],
          },
        };
      items.push(item);
    });
    return items;
  }

  onSelect = (item: ItemPoint | null) => {
    if (this.props.onSelect) {
      item ? this.props.onSelect(item.id) : this.props.onSelect(item);
    }
  };

  armarItemPoint(direccion?: number) {
    let item: ItemPoint | undefined = undefined;
    if (direccion) {
      const direccionSeleccionada = this.state.direcciones.find(
        (dire) => dire.id == direccion
      );
      if (direccionSeleccionada?.localizacion) {
        item = {
          id: direccion,
          point: {
            lat: direccionSeleccionada.localizacion.coordinates[0],
            lng: direccionSeleccionada.localizacion.coordinates[1],
          },
        };
      }
    }
    return item;
  }
  render() {
    return (
      <LocationsMap
        height={"500px"}
        items={this.armarItemsMap(this.state.direcciones)}
        clickable={true}
        onSelect={this.onSelect}
        selected={this.armarItemPoint(this.props.seleccionado)}
      />
    );
  }
}
