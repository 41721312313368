import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Localidad, LocalidadService, Partido, PartidoService, Provincia, ProvinciaService } from "serviciossaintmichel";
import { Global } from "../../../Global";
import Flex from "../flex/Flex";
import SelectLocalidad from "./SelectLocalidad";
import SelectPartido from "./SelectPartido";
import SelectProvincia from "./SelectProvincia";

interface Props {
  localidadId?: number;
  onChange: Function;
}

interface State {
  localidad: Localidad | null;
  provincias: Array<Provincia>;
  partidos: Array<Partido>;
  localidades: Array<Localidad>;
  responsive: boolean;
}

export default class ComponenteDatoGeografico extends Component<Props, State> {

  private localidadService:LocalidadService;
  private partidoService:PartidoService;
  private provinciaService:ProvinciaService;

  constructor(props: Props) {
    super(props);
    this.state = {
      localidad: null,
      provincias: [],
      partidos: [],
      localidades: [],
      responsive: window.innerWidth < 700 ? true : false,
    };
    this.localidadService = new LocalidadService(Global.UsuarioService.getToken()!);
    this.partidoService = new PartidoService(Global.UsuarioService.getToken()!);
    this.provinciaService = new ProvinciaService(Global.UsuarioService.getToken()!);

  }

  actualizarDimensiones = () => {
    if (window.innerWidth < 700) {
      this.setState({ responsive: true });
    } else {
      this.setState({ responsive: false });
    }
  };

  componentDidMount = async () => {
    window.addEventListener("resize", this.actualizarDimensiones);
    const provincias = await this.cargarProvincias();

    let partidos: Array<Partido>;
    let localidades: Array<Localidad>;
    let localidad = null;
    if (this.props.localidadId && this.props.localidadId > 0) {
      localidad = await this.cargarLocalidad(this.props.localidadId);
    }

    if (localidad !== null) {
      partidos = await this.partidoService.getPartidosDeProvincia(localidad.partido.provincia.id).catch((error) => {
        this.partidoService.manejarErrorHTTP(error, "Partido");
        return [];
      });
      localidades = await this.localidadService.getLocalidadesDePartido(localidad.partido.id).catch((error) => {
        this.localidadService.manejarErrorHTTP(error, "Localidad");
        return [];
      });
    } else {
      partidos = await this.partidoService.getPartidosDeProvincia(provincias[0].id).catch((error) => {
        this.partidoService.manejarErrorHTTP(error, "Partido");
        return [];
      });
      localidades = await this.localidadService.getLocalidadesDePartido(partidos[0].id).catch((error) => {
        this.localidadService.manejarErrorHTTP(error, "Localidad");
        return [];
      });
      localidad = localidades[0];
    }
    this.setState({
      provincias: provincias,
      partidos: partidos,
      localidades: localidades,
      localidad: localidad,
    });
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevState.localidad !== this.state.localidad) {
      this.props.onChange(this.state.localidad);
    }
  };

  cargarProvincias = async () => {
    let provincias: Array<Provincia> = await this.provinciaService.listAll().catch((error) => {
      this.provinciaService.manejarErrorHTTP(error, "Provincia");
      return [];
    });

    return provincias;
  };

  cargarLocalidad = async (localidadId: number) => {
    return await this.localidadService.get(localidadId).catch((error) => {
      this.localidadService.manejarErrorHTTP(error, "Localidad");
      return null;
    });
  };

  setearProvincia = async (valor: Provincia) => {
    let partidos: Array<Partido> | null = await this.partidoService.getPartidosDeProvincia(valor.id).catch((error) => {
      this.partidoService.manejarErrorHTTP(error, "Partido");
      return null;
    });
    if (partidos != null) {
      let localidades: Array<Localidad> | null = await this.localidadService.getLocalidadesDePartido(partidos[0].id).catch(
        (error) => {
          this.localidadService.manejarErrorHTTP(error, "Localidad");
          return null;
        }
      );
      if (localidades != null) {
        let localidad: Localidad = localidades[0];
        this.setState({
          partidos: partidos,
          localidades: localidades,
          localidad: localidad,
        });
        this.props.onChange(localidad);
      }
    }
  };

  setearPartido = async (valor: Partido) => {
    let localidades: Array<Localidad> | null = await this.localidadService.getLocalidadesDePartido(valor.id).catch(
      (error) => {
        this.localidadService.manejarErrorHTTP(error, "Localidad");
        return null;
      }
    );
    if (localidades != null) {
      let localidad: Localidad = localidades[0];
      this.setState({ localidades: localidades, localidad: localidad });
      this.props.onChange(localidad);
    }
  };

  setearLocalidad = (valor: Localidad) => {
    this.setState({ localidad: valor });
    this.props.onChange(valor);
  };

  render() {
    const { provincias, partidos, localidades, localidad, responsive } = this.state;

    if (provincias.length < 1 || localidad === null) {
      return null;
    }

    return (
      <Flex
        container
        flexDirection={responsive ? "column" : "row"}
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          container
          flexDirection="column"
          width={responsive ? "100%" : "30%"}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Form.Group style={{ width: "100%" }}>
            <Form.Label>Provincia</Form.Label>
            <SelectProvincia
              provincias={provincias}
              seleccionado={localidad ? localidad.partido.provincia : undefined}
              callbackParent={(provincia: Provincia) => {
                this.setearProvincia(provincia);
              }}
            />
          </Form.Group>
        </Flex>

        <Flex
          container
          flexDirection="column"
          width={responsive ? "100%" : "30%"}
          alignItems="center"
          marginLeft={responsive ? "0%" : "5%"}
          justifyContent="flex-start"
        >
          <Form.Group style={{ width: "100%" }}>
            <Form.Label>Partido</Form.Label>
            <SelectPartido
              seleccionado={localidad ? localidad.partido : undefined}
              partidos={partidos}
              callbackParent={(partido: Partido) => {
                this.setearPartido(partido);
              }}
            />
          </Form.Group>
        </Flex>

        <Flex
          container
          flexDirection="column"
          width={responsive ? "100%" : "30%"}
          alignItems="center"
          marginLeft={responsive ? "0%" : "5%"}
          justifyContent="flex-start"
        >
          <Form.Group style={{ width: "100%" }}>
            <Form.Label>Localidad</Form.Label>
            <SelectLocalidad
              seleccionado={localidad}
              localidades={localidades}
              callbackParent={(localidad: Localidad) => {
                this.setearLocalidad(localidad);
              }}
            />
          </Form.Group>
        </Flex>
      </Flex>
    );
  }
}
