import React, { Component } from "react";
import { DatosProducto, ProductoService } from "serviciossaintmichel";
import TablaGenerica, {
  AccionTabla,
  FilaTablaGenerica,
} from "../../../../../compartido/components/tabla/TablaGenerica";
import { Global } from "../../../../../Global";




export interface Props {
  productos: Array<DatosProducto>;
  productosFuncion: Function;
  accionesBotones?: Array<AccionTabla>;
}

interface State {}

export default class ProductosShowTable extends Component<Props, State> {

  private productoService:ProductoService;

  constructor(props: Props) {
    super(props);
    this.state = {
      productos: [],
    };

    this.productoService =  new ProductoService(Global.UsuarioService.getToken()!);

  }

  armarFilas(productos: Array<DatosProducto>): Array<FilaTablaGenerica> {
    let filas: Array<FilaTablaGenerica> = [];
    if (productos.length > 0) {
      for (let i = 0; i < productos.length; i++) {
        let fila: Array<string> = [];
        fila.push(productos[i].nombre);
        let filaResult: FilaTablaGenerica = {
          id: productos[i].id,
          celdas: fila,
        };
        filas.push(filaResult);
      }
    }
    return filas;
  }

  render() {
    return (
      <TablaGenerica
        nombresColumnas={this.productoService.paramsURL.getCriteriosFiltro()}
        filas={this.armarFilas(this.props.productos)}
        accionesBoton={this.props.accionesBotones}
        parametrosURL={this.productoService.paramsURL}
        listarElementos={this.props.productosFuncion}
        id="TablaProductos"
      />
    );
  }
}
