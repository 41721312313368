class FechaUtils {
  normalizarFecha = (fecha: string | Date): Date => {
    if (typeof fecha === "string") {
      if (!fecha.includes("T")) {
        fecha = `${fecha}T00:00:00`;
      }
      fecha = new Date(fecha);
    }
    return fecha;
  };
}

export default new FechaUtils();
