import React, { Component } from "react";


interface Props {
  children?: any;
  width?: string;
  height?: string;
  margin?: string;
  rigth?: string;
  top?: string;
  left?: string;
  bottom?: string;
}

interface State {}

class Margin extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <div
        style={{
          display:"block",
          margin: this.props.margin || "0",
          width: this.props.width || "auto",
          height: this.props.height || "auto",
          marginRight: this.props.rigth || "0",
          marginTop: this.props.top || "0",
          marginLeft: this.props.left || "0",
          marginBottom: this.props.bottom || "0",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
export default Margin;
