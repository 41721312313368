import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Screen, { ScreenType } from "../../../../../../compartido/models/Screen";
import Flex from "../../../../../../libreria/appearance/flex/Flex";
import Visibilizador from "../../../../../../libreria/action/visibilizador/Visibilizador";
import { EntregaProgramacionBultoProducto } from "serviciossaintmichel";

export interface Props {
  entregaBultoProductos: Array<EntregaProgramacionBultoProducto>;
}
interface State {
  cargando: boolean;
  screen: ScreenType;
  visible: boolean;
}

export default class EntregaBultoProductosProgramacionEntregaShow extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: true,
      cargando: false,
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    };
    window.addEventListener("resize", this.actualizarDimensiones);
  }

  actualizarDimensiones = () => {
    this.setState({
      screen: Screen.getType(window.innerWidth, window.innerHeight),
    });
  };

  componentDidMount = async () => {};

  renderEntregaBultoProductos = () => (
    <Flex container flexDirection="column" width="100%" alignItems="flex-start" justifyContent="center">
      {this.props.entregaBultoProductos.length > 0 ? this.renderizarTabla() : this.renderSinEntregaBultoProductos()}
    </Flex>
  );

  renderSinEntregaBultoProductos = () => {
    return "No posee Entregas de BultoProductos";
  };

  renderizarFilas = () => {
    return this.props.entregaBultoProductos.map(({ paquete }, index) => {
      return (
        <tr>
          <td className="text-center">{index}</td>
          <td className="text-center">{paquete.bulto_producto.nombre}</td>
          <td className="text-center">{paquete.cantidad}</td>
        </tr>
      );
    });
  };

  renderizarTabla = () => {
    return (
      <Table bordered={true} responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Bulto Producto</th>
            <th className="text-center">Cantidad</th>
          </tr>
        </thead>
        <tbody>{this.renderizarFilas()}</tbody>
      </Table>
    );
  };

  renderLoading = () => "Cargando Entrega de BultoProductos";

  onChangeVisibilizador = (visible: boolean) => {
    this.setState({ visible: visible });
  };

  render() {
    if (this.props.entregaBultoProductos.length > 0)
      return (
        <Flex
          container
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          padding="20px"
          paddingBottom="0px"
        >
          <Visibilizador
            onChange={this.onChangeVisibilizador}
            label={"Entrega de BultoProductos"}
            labelAlignment={"flex-start"}
            visible={this.state.visible}
          >
            {this.state.cargando ? this.renderLoading() : this.renderEntregaBultoProductos()}
          </Visibilizador>
        </Flex>
      );
    else return null;
  }
}
